import React from 'react';

function AmmoniteWillIcon() {
	return (
		<svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.8944 52.2111C24.2963 51.9121 23.5883 51.9317 23.0077 52.2635L5 62.5536V19.1606L24.0629 8.26754L47.1056 19.7889C47.7037 20.0879 48.4117 20.0683 48.9923 19.7365L67 9.44636V52.8394L47.9371 63.7325L24.8944 52.2111Z" stroke="#F25F5C" strokeWidth="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M24 12L24 50" stroke="#F25F5C" strokeWidth="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M48 22L48 60" stroke="#F25F5C" strokeWidth="4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
	);
}

export default AmmoniteWillIcon;
