import React, { useState, useEffect, useContext } from 'react';
import AmmoniteMenuCircleEmpty from '../../Assets/SVG/menuCircleEmpty';
import AmmoniteMenuCircleFilledTicked from '../../Assets/SVG/menuCircleFilledTicked';
import './personCardAdditional.css';

import SuitabilityReportContext from '../../Utils/contexts/suitabilityReportContext';
import { 
    changeMTR,
    changeAdditionalPersonField
} from '../../Utils/reducers/suitabilityReportActions';

import styles from '../../Styles/srbstyles.module.css'

const PersonCardAdditional = ({ 
    customerID,
    customer,
}) => {

    const context = useContext(SuitabilityReportContext);
    const dispatch = context.dispatch;
    
    const mtrHandler = (rate) => {
        dispatch({ type: changeMTR, payload: {
            customerID: customerID,
            rate: rate,
        }})
    }

    const handleChange = (field, value) => {
        dispatch({ type: changeAdditionalPersonField, payload: {
            customerID: customerID,
            field: field,
            value: value
        }})
    }

    useEffect(() => {
     
    }, [])


	const filled = (
        <div className="circleSVG">
            < AmmoniteMenuCircleFilledTicked />
        </div>
    );

    const empty = (
        <div className="circleSVG">
            <AmmoniteMenuCircleEmpty colour="#3BB9C4" width="36px" height="36px" cx="18px" cy="18px" r="14px" />			
        </div>
    );

	return (
		<div className="personCardAdditional">
			<div className="personCardAdditionalName">
                {`${customer.customerFirstName} ${customer.customerLastName}`}
            </div>
            <div className="personCardAdditionalFieldsCols">
                <div className="personCardAdditionalFields">
                    <div className="personCardAdditionalFields">
                        <label  className="personCardAdditionalFieldLabel">Starting cash balance</label>
                        <div className="cashSignLabel">£</div>
                        <input
                            type="number"
                            placeholder={0}
                            value={customer.customerStartingCashBalance}
                            className={[styles.whyInput, "personCardAdditionalInput"].join(" ")}
                            onChange={(event) => handleChange('customerStartingCashBalance', event.target.value)}
                        />
                    </div>
                    <div className="personCardAdditionalFields">
                        <label  className="personCardAdditionalFieldLabel">Gross Monthly Income</label>
                        <div className="cashSignLabel">£</div>
                        <input
                            type="number"
                            placeholder={0}
                            value={customer.customerGrossMonthlyIncome}
                            className={[styles.whyInput, "personCardAdditionalInput"].join(" ")}
                            onChange={(event) => handleChange('customerGrossMonthlyIncome', event.target.value)}
                        />
                    </div>
                    <div className="personCardAdditionalFields">
                        <label  className="personCardAdditionalFieldLabel">Agreed Monthly Budget</label>
                        <div className="cashSignLabel">£</div>
                        <input
                            type="number"
                            placeholder={0}
                            value={customer.customerAgreedMonthlyBudget}
                            className={[styles.whyInput, "personCardAdditionalInput"].join(" ")}
                            onChange={(event) => handleChange('customerAgreedMonthlyBudget', event.target.value)}
                        />
                    </div>
                </div>
                <div className="personCardAdditionalFields">
                    <div className="personCardAdditionalFields">
                        <label  className="personCardAdditionalFieldLabel">Monthly Expenditure</label>
                        <div className="cashSignLabel">£</div>
                        <input
                            type="number"
                            placeholder={0}
                            value={customer.customerMonthlyExpenditure}
                            className={[styles.whyInput, "personCardAdditionalInput"].join(" ")}
                            onChange={(event) => handleChange('customerMonthlyExpenditure', event.target.value)}
                        />
                    </div>
                    <div className="personCardAdditionalFields">
                        <label  className="personCardAdditionalFieldLabel">Net Monthly Income</label>
                        <div className="cashSignLabel">£</div>
                        <input
                            type="number"
                            placeholder={0}
                            value={customer.customerNetMonthlyIncome}
                            className={[styles.whyInput, "personCardAdditionalInput"].join(" ")}
                            onChange={(event) => handleChange('customerNetMonthlyIncome', event.target.value)}
                        />
                    </div>
                    <div className="personCardAdditionalFields">
                        <label  className="personCardAdditionalFieldLabel">Agreed Lump Sum</label>
                        <div className="cashSignLabel">£</div>
                        <input
                            type="number"
                            placeholder={0}
                            value={customer.customerAgreedLumpSum}
                            className={[styles.whyInput, "personCardAdditionalInput"].join(" ")}
                            onChange={(event) => handleChange('customerAgreedLumpSum', event.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className="marginalTax">    
                <label  className="personCardAdditionalFieldLabel">Marginal Tax Rate</label>
                <div className={styles.multipleChoiceValues}>
                    <div className={styles.emptyCardButtonHandlerContainerSR}>
                        <div  onClick={() => mtrHandler(0)}>
                            {customer.customerMarginalTaxRate !== 0 ? <div className={styles.buttonHandlerContainer} onClick={() => mtrHandler(0)}>
                                {empty}			
                            </div> : <div className={styles.buttonHandlerContainer}>{filled}</div> }
                        </div>
                        <label className={styles.multipleChoiceButtonLabel}>{`0%`}</label>
                    </div>
                    <div className={styles.emptyCardButtonHandlerContainerSR}>
                        <div  onClick={() => mtrHandler(20)}>
                            {customer.customerMarginalTaxRate !== 20 ? <div className={styles.buttonHandlerContainer} onClick={() => mtrHandler(20)}>
                            {empty}				
                            </div> :
                            <div className={styles.buttonHandlerContainer}>{filled}</div> }
                        </div>
                        <label className={styles.multipleChoiceButtonLabel}>{`20%`}</label>
                    </div>
                    <div className={styles.emptyCardButtonHandlerContainerSR}>
                        <div  onClick={() => mtrHandler(40)}>
                            {customer.customerMarginalTaxRate !== 40 ? <div className={styles.buttonHandlerContainer} onClick={() => mtrHandler(40)}>
                            {empty}				
                            </div> :
                            <div className={styles.buttonHandlerContainer}>{filled}</div> }
                        </div>
                        <label className={styles.multipleChoiceButtonLabel}>{`40%`}</label>
                    </div>
                    <div className={styles.emptyCardButtonHandlerContainerSR}>
                        <div  onClick={() => mtrHandler(45)}>
                            {customer.customerMarginalTaxRate !== 45 ? <div className={styles.buttonHandlerContainer} onClick={() => mtrHandler(45)}>
                            {empty}				
                            </div> : 
                            <div className={styles.buttonHandlerContainer}>{filled}</div> }
                        </div>
                        <label className={styles.multipleChoiceButtonLabel}>{`45%`}</label>
                    </div>
                </div>
            </div>
		</div>
	);
}

export default PersonCardAdditional;
