import React from "react";

function AmmoniteShowIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13.5C11.173 13.5 10.5 12.827 10.5 12C10.5 11.173 11.173 10.5 12 10.5C12.827 10.5 13.5 11.173 13.5 12C13.5 12.827 12.827 13.5 12 13.5ZM12 8.50002C10.07 8.50002 8.5 10.07 8.5 12C8.5 13.93 10.07 15.5 12 15.5C13.93 15.5 15.5 13.93 15.5 12C15.5 10.07 13.93 8.50002 12 8.50002ZM12.2197 16.9976C7.9137 17.0976 5.1047 13.4146 4.1727 11.9956C5.1987 10.3906 7.7827 7.10462 11.7807 7.00262C16.0697 6.89362 18.8947 10.5856 19.8267 12.0046C18.8017 13.6096 16.2167 16.8956 12.2197 16.9976ZM21.8677 11.5026C21.2297 10.3906 17.7057 4.81662 11.7297 5.00362C6.2017 5.14362 2.9867 10.0136 2.1327 11.5026C1.9557 11.8106 1.9557 12.1896 2.1327 12.4976C2.7617 13.5946 6.1617 18.9996 12.0247 18.9996C12.1067 18.9996 12.1887 18.9986 12.2707 18.9966C17.7977 18.8556 21.0137 13.9866 21.8677 12.4976C22.0437 12.1896 22.0437 11.8106 21.8677 11.5026Z"
        fill="#232323"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="2"
        y="4"
        width="20"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 13.5C11.173 13.5 10.5 12.827 10.5 12C10.5 11.173 11.173 10.5 12 10.5C12.827 10.5 13.5 11.173 13.5 12C13.5 12.827 12.827 13.5 12 13.5ZM12 8.50002C10.07 8.50002 8.5 10.07 8.5 12C8.5 13.93 10.07 15.5 12 15.5C13.93 15.5 15.5 13.93 15.5 12C15.5 10.07 13.93 8.50002 12 8.50002ZM12.2197 16.9976C7.9137 17.0976 5.1047 13.4146 4.1727 11.9956C5.1987 10.3906 7.7827 7.10462 11.7807 7.00262C16.0697 6.89362 18.8947 10.5856 19.8267 12.0046C18.8017 13.6096 16.2167 16.8956 12.2197 16.9976ZM21.8677 11.5026C21.2297 10.3906 17.7057 4.81662 11.7297 5.00362C6.2017 5.14362 2.9867 10.0136 2.1327 11.5026C1.9557 11.8106 1.9557 12.1896 2.1327 12.4976C2.7617 13.5946 6.1617 18.9996 12.0247 18.9996C12.1067 18.9996 12.1887 18.9986 12.2707 18.9966C17.7977 18.8556 21.0137 13.9866 21.8677 12.4976C22.0437 12.1896 22.0437 11.8106 21.8677 11.5026Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)"></g>
    </svg>
  );
}

export default AmmoniteShowIcon;
