//React
import React, { useState, useContext } from 'react'

import AmmoniteSBRetirementTab from '../../../Assets/SVG/SBReportPDF/SBReportGoalTypes/goalTypeTab'

import './investmentGoalRiskLevels.css'

function AmmoniteInvestmentGoalRiskLevels({ investment, equity, goals }) {
  const getGoalColour = (goalType) => {
    switch (goalType) {
      case 'Retirement pot':
        return '#BF802F'
      case 'Education':
        return '#69306D'
      case 'Property':
        return '#247BA0'
      case 'Family':
        return '#3F762C'
      case 'Long Term Investment':
        return '#373986'
      case 'Care Fees':
        return '#0C3957'
      case 'Other':
        return '#F25F5C'
      default:
        return '#69306D'
    }
  }

  return (
    <div>
      {/* {investment && goals && <div className="sbrBlueSubSubHeading">How do the investments relate to my goals?</div>} */}
      {investment && (
        <div>
          <div className='recommendationsParagraph recommendationsParagraph1 '>
            The strategic equity content of the {investment} is{' '}
            {equity
              ? typeof equity === 'number'
                ? equity * 100 + '%'
                : equity
              : 'n/a'}{' '}
            (though the investment manager may increase or decrease the equity
            content above or below this level depending on their views at the
            time). It is therefore appropriate for the following goals, which
            based on your risk profile and the years to the goal have the
            following risk levels:
          </div>
        </div>
      )}
      {goals &&
        goals.map((goal) => (
          <div className='investmentSummaryRow'>
            <div className='riskLevelGoal'>
              <div className='riskLevelGoalTab'>
                <AmmoniteSBRetirementTab
                  colour={getGoalColour(goal.goalType)}
                />
              </div>
              <div className='investmentSummaryContainer'>
                <div className='investmentField goalInvestment'>
                  <div className='investmentTitle'>Goal:</div>
                  <div className='investmentItem'>{goal.goalName}</div>
                </div>
                <div className='investmentField riskInvestment'>
                  <div className='investmentTitle investmentTitleRisk'>
                    Risk Level:
                  </div>
                  <div className='investmentItem'>{goal.goalRisk} / 10</div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}

export default AmmoniteInvestmentGoalRiskLevels
