import React, { Fragment, useEffect } from 'react';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

import './mortarboard.css';

function AmmoniteMortar({ colour, width, height, cx, cy, r }) {
	return (
		<Fragment>
			<svg
				id="mortarBoard"
				width="313"
				height="224"
				viewBox="0 0 313 224"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					id="sparkle1"
					d="M102.828 221.195C103.464 221.195 103.979 220.684 103.979 220.054C103.979 219.423 103.464 218.912 102.828 218.912C102.192 218.912 101.676 219.423 101.676 220.054C101.676 220.684 102.192 221.195 102.828 221.195Z"
					fill="#3BB9C4"
				/>
				<path
					id="sparkle2"
					d="M155.159 221.432C154.834 221.292 154.572 221.037 154.423 220.718C154.275 220.398 154.25 220.036 154.353 219.699C154.37 219.647 154.381 219.594 154.385 219.54C154.394 219.386 154.353 219.233 154.268 219.103C154.183 218.974 154.058 218.875 153.912 218.822C153.766 218.768 153.606 218.763 153.457 218.806C153.307 218.85 153.176 218.94 153.082 219.063C153.05 219.107 153.023 219.155 153.002 219.205C152.86 219.527 152.604 219.787 152.281 219.934C151.959 220.082 151.594 220.106 151.254 220.004C151.202 219.987 151.148 219.977 151.094 219.972C150.938 219.963 150.784 220.004 150.653 220.088C150.523 220.173 150.423 220.296 150.369 220.441C150.316 220.586 150.31 220.745 150.354 220.893C150.398 221.041 150.489 221.171 150.613 221.264C150.657 221.296 150.705 221.323 150.756 221.344C151.081 221.484 151.343 221.739 151.491 222.058C151.64 222.378 151.665 222.74 151.561 223.077C151.545 223.128 151.534 223.182 151.53 223.236C151.52 223.39 151.561 223.543 151.646 223.673C151.732 223.802 151.857 223.901 152.003 223.954C152.149 224.008 152.308 224.013 152.458 223.969C152.607 223.926 152.739 223.836 152.833 223.712C152.865 223.669 152.892 223.621 152.913 223.571C153.055 223.249 153.311 222.989 153.633 222.842C153.955 222.694 154.321 222.67 154.66 222.772C154.712 222.789 154.766 222.799 154.821 222.803C154.976 222.813 155.131 222.772 155.261 222.688C155.392 222.603 155.491 222.48 155.545 222.335C155.599 222.19 155.604 222.031 155.561 221.883C155.517 221.735 155.426 221.605 155.301 221.512C155.257 221.48 155.209 221.453 155.159 221.432Z"
					fill="#3BB9C4"
				/>
				<path
					d="M185.534 197.631C188.924 190.856 191.108 185.086 190.412 184.743C189.715 184.401 186.402 189.615 183.012 196.391C179.622 203.166 177.439 208.936 178.135 209.279C178.831 209.621 182.144 204.407 185.534 197.631Z"
					fill="#E0E0E0"
				/>
				<path
					opacity="0.05"
					d="M78.4292 186.186L87.5051 188.302L93.8269 161.64L84.7511 159.524L78.4292 186.186Z"
					fill="black"
				/>
				<path
					d="M79.4389 186.421L87.5051 188.302L93.8269 161.64L85.7607 159.759L79.4389 186.421Z"
					fill="#BF802F"
				/>
				<path
					d="M93.1226 163.381L86.7331 158.149L69.1466 179.269L75.5361 184.501L93.1226 163.381Z"
					fill="#BF802F"
				/>
				<path
					d="M13.5647 112.94C16.9547 106.165 18.8721 100.264 17.8472 99.7597C16.8223 99.2555 13.2433 104.339 9.85329 111.114C6.46324 117.889 4.54588 123.791 5.57074 124.295C6.59561 124.799 10.1746 119.716 13.5647 112.94Z"
					fill="#E0E0E0"
				/>
				<path
					d="M18.1542 99.1463L5.26389 124.908L177.843 209.819L190.733 184.057L18.1542 99.1463Z"
					fill="url(#paint0_linear)"
				/>
				<path
					d="M178.149 209.206C174.771 207.599 3.09646 123.078 5.57071 124.295C15.0992 109.873 17.8472 99.7598 17.8472 99.7598L190.426 184.671C190.426 184.671 184.365 195.218 178.149 209.206Z"
					fill="#F5F5F5"
				/>
				<path
					id="sparkle3"
					d="M40.6872 213.449C41.9912 213.449 43.0483 212.401 43.0483 211.108C43.0483 209.815 41.9912 208.767 40.6872 208.767C39.3832 208.767 38.3261 209.815 38.3261 211.108C38.3261 212.401 39.3832 213.449 40.6872 213.449Z"
					fill="#3BB9C4"
				/>
				<path
					id="sparkle4"
					opacity="0.5"
					d="M15.5024 191.987V190.816H8.81268V191.987H15.5024Z"
					fill="#3BB9C4"
				/>
				<path
					id="sparkle5"
					opacity="0.5"
					d="M12.7478 188.085H11.5673V194.719H12.7478V188.085Z"
					fill="#3BB9C4"
				/>
				<path
					id="sparkle6"
					d="M246.889 190.427C248.193 190.427 249.25 189.379 249.25 188.085C249.25 186.792 248.193 185.744 246.889 185.744C245.585 185.744 244.528 186.792 244.528 188.085C244.528 189.379 245.585 190.427 246.889 190.427Z"
					fill="#3BB9C4"
				/>
				<path
					id="sparkle7"
					d="M298.833 175.598C300.137 175.598 301.194 174.549 301.194 173.256C301.194 171.963 300.137 170.915 298.833 170.915C297.529 170.915 296.472 171.963 296.472 173.256C296.472 174.549 297.529 175.598 298.833 175.598Z"
					fill="#3BB9C4"
				/>
				<path
					opacity="0.05"
					d="M107.539 143.888L93.3118 136.889L81.0353 161.424L95.2622 168.424L107.539 143.888Z"
					fill="black"
				/>
				<path
					d="M106.92 143.584L93.9304 137.193L81.6539 161.728L94.6437 168.12L106.92 143.584Z"
					fill="#BF802F"
				/>
				<path
					opacity="0.05"
					d="M104.446 142.367L95.786 138.106L83.5095 162.642L92.1694 166.902L104.446 142.367Z"
					fill="black"
				/>
				<path
					d="M103.827 142.062L96.4046 138.41L84.1281 162.945L91.5509 166.597L103.827 142.062Z"
					fill="#BF802F"
				/>
				<path
					id="mortarHeadBandDark"
					d="M80.629 139.503L71.1846 153.551C71.1846 153.551 74.3327 167.989 143.985 167.599C213.637 167.209 219.146 153.551 219.146 153.551L206.16 134.821L166.415 107.896L80.629 139.503Z"
					fill="#BF802F"
				/>
				<path
					id="mortarHeadBandDark"
					d="M80.629 139.503L71.1846 153.551C71.1846 153.551 74.3327 167.989 143.985 167.599C213.637 167.209 219.146 153.551 219.146 153.551L206.16 134.821L166.415 107.896L80.629 139.503Z"
					fill="#69306D"
				/>
				<path id="sparkle8" opacity="0.5" d="M313 109.26V108.09H306.31V109.26H313Z" fill="#3BB9C4" />
				<path opacity="0.5" d="M310.245 105.358H309.065V111.992H310.245V105.358Z" fill="#3BB9C4" />
				<path
					d="M4.8361 98.9027C4.51077 98.7622 4.24889 98.508 4.10032 98.1885C3.95175 97.8689 3.92686 97.5063 4.03038 97.1698C4.04714 97.1182 4.05769 97.0648 4.06182 97.0107C4.07129 96.8565 4.03042 96.7034 3.94526 96.574C3.86011 96.4447 3.7352 96.3459 3.58903 96.2925C3.44286 96.239 3.28322 96.2337 3.13374 96.2772C2.98426 96.3207 2.8529 96.4109 2.75911 96.5342C2.72686 96.578 2.69993 96.6254 2.67887 96.6754C2.53719 96.998 2.28082 97.2577 1.95857 97.405C1.63632 97.5523 1.27069 97.577 0.931275 97.4744C0.87924 97.4577 0.825407 97.4473 0.770899 97.4432C0.615394 97.4338 0.460976 97.4743 0.330517 97.5588C0.200058 97.6432 0.100498 97.7671 0.0465791 97.912C-0.00733937 98.057 -0.0127453 98.2153 0.0311594 98.3635C0.0750641 98.5117 0.165943 98.642 0.290341 98.735C0.334478 98.767 0.382267 98.7937 0.432715 98.8145C0.758048 98.955 1.01993 99.2093 1.1685 99.5288C1.31707 99.8484 1.34196 100.211 1.23843 100.547C1.22168 100.599 1.21112 100.652 1.20699 100.707C1.19752 100.861 1.2384 101.014 1.32355 101.143C1.40871 101.273 1.53362 101.371 1.67979 101.425C1.82595 101.478 1.9856 101.484 2.13508 101.44C2.28456 101.397 2.41591 101.306 2.5097 101.183C2.54195 101.139 2.56889 101.092 2.58994 101.042C2.73163 100.719 2.988 100.46 3.31025 100.312C3.6325 100.165 3.99813 100.14 4.33754 100.243C4.38958 100.26 4.44341 100.27 4.49792 100.274C4.65342 100.283 4.80784 100.243 4.9383 100.159C5.06877 100.074 5.16833 99.9502 5.22225 99.8053C5.27617 99.6603 5.28158 99.502 5.23767 99.3538C5.19376 99.2056 5.10288 99.0753 4.97848 98.9823C4.93434 98.9503 4.88655 98.9236 4.8361 98.9027Z"
					fill="#3BB9C4"
				/>
				<path opacity="0.5" d="M22.5854 26.9246V25.7539H15.8957V26.9246H22.5854Z" fill="#3BB9C4" />
				<path opacity="0.5" d="M19.8308 23.0225H18.6503V29.6562H19.8308V23.0225Z" fill="#3BB9C4" />
				<path
					id="mortarHeadBoard"
					d="M281.519 87.4085L157.561 141.649L6.45126 87.4085L144.969 24.9736L212.964 56.0623L215.4 57.1783L281.519 87.4085Z"
					fill="#BF802F"
				/>
				<path
					id="mortarHeadBoard2"
					d="M281.519 87.4085L157.561 141.649L6.45126 87.4085L144.969 24.9736L212.964 56.0623L215.4 57.1783L281.519 87.4085Z"
					fill="#69306D"
				/>
				<path
					id="mortarHeadBand"
					d="M82.2031 87.2137L71.1846 153.551C71.1846 153.551 150.281 128.187 219.146 153.551L208.915 83.3115C208.915 83.3115 116.439 71.9952 82.2031 87.2137Z"
					fill="#884E8C"
				/>
				<path
					d="M217.966 100.411L215.605 100.551L212.964 56.0625L215.4 57.1785L217.966 100.411Z"
					fill="#F5F5F5"
				/>
				<path
					d="M217.769 113.944C224.072 113.944 229.181 110.45 229.181 106.139C229.181 101.829 224.072 98.335 217.769 98.335C211.466 98.335 206.357 101.829 206.357 106.139C206.357 110.45 211.466 113.944 217.769 113.944Z"
					fill="#F5F5F5"
				/>
				<path
					d="M72.5207 22.0307C72.1954 21.8902 71.9335 21.6359 71.785 21.3164C71.6364 20.9968 71.6115 20.6343 71.715 20.2977C71.7318 20.2461 71.7423 20.1927 71.7465 20.1387C71.7559 19.9845 71.7151 19.8313 71.6299 19.702C71.5447 19.5726 71.4198 19.4739 71.2737 19.4204C71.1275 19.3669 70.9679 19.3616 70.8184 19.4051C70.6689 19.4487 70.5375 19.5388 70.4437 19.6621C70.4115 19.7059 70.3846 19.7533 70.3635 19.8033C70.2218 20.1259 69.9654 20.3856 69.6432 20.5329C69.3209 20.6803 68.9553 20.7049 68.6159 20.6023C68.5639 20.5857 68.51 20.5752 68.4555 20.5711C68.3 20.5617 68.1456 20.6023 68.0152 20.6867C67.8847 20.7711 67.7851 20.895 67.7312 21.0399C67.6773 21.1849 67.6719 21.3432 67.7158 21.4914C67.7597 21.6396 67.8506 21.7699 67.975 21.8629C68.0191 21.8949 68.0669 21.9216 68.1174 21.9425C68.4427 22.083 68.7046 22.3372 68.8531 22.6567C69.0017 22.9763 69.0266 23.3389 68.9231 23.6754C68.9063 23.727 68.8958 23.7804 68.8916 23.8345C68.8822 23.9887 68.923 24.1418 69.0082 24.2712C69.0933 24.4005 69.2183 24.4993 69.3644 24.5527C69.5106 24.6062 69.6702 24.6115 69.8197 24.568C69.9692 24.5245 70.1006 24.4343 70.1943 24.311C70.2266 24.2672 70.2535 24.2198 70.2746 24.1698C70.4163 23.8472 70.6726 23.5875 70.9949 23.4402C71.3171 23.2929 71.6828 23.2682 72.0222 23.3708C72.0742 23.3875 72.128 23.3979 72.1826 23.402C72.3381 23.4114 72.4925 23.3709 72.6229 23.2864C72.7534 23.202 72.853 23.0781 72.9069 22.9332C72.9608 22.7882 72.9662 22.6299 72.9223 22.4817C72.8784 22.3335 72.7875 22.2032 72.6631 22.1102C72.619 22.0782 72.5712 22.0515 72.5207 22.0307Z"
					fill="#3BB9C4"
				/>
				<path opacity="0.5" d="M277.583 16.7791V15.6084H270.894V16.7791H277.583Z" fill="#3BB9C4" />
				<path opacity="0.5" d="M274.829 12.877H273.648V19.5107H274.829V12.877Z" fill="#3BB9C4" />
				<path
					d="M161.89 4.68262C163.194 4.68262 164.251 3.63438 164.251 2.34131C164.251 1.04824 163.194 0 161.89 0C160.586 0 159.529 1.04824 159.529 2.34131C159.529 3.63438 160.586 4.68262 161.89 4.68262Z"
					fill="#3BB9C4"
				/>
				<defs>
					<linearGradient
						id="paint0_linear"
						x1="91.5532"
						y1="167.364"
						x2="104.27"
						y2="141.517"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#808080" stop-opacity="0.25" />
						<stop offset="0.53514" stop-color="#808080" stop-opacity="0.12" />
						<stop offset="1" stop-color="#808080" stop-opacity="0.1" />
					</linearGradient>
				</defs>
			</svg>
		</Fragment>
	);
}

export default AmmoniteMortar;
