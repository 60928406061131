// External libraries
import { createMuiTheme } from '@material-ui/core/styles';

// Utilities
import { backColour, nextColour, secondaryMain} from '../constants';

export const theme = createMuiTheme({
	palette: {
		primary: nextColour,
		secondary: backColour
	},
	background: {
		secondaryMain
	},
	shadows: [ 'none' ],
	status: {
		danger: 'orange'
	},
	typography: {
		// Use the system font instead of the default Roboto font.
		fontFamily: [ 'Brother 1816' ].join(','),

		h4: {
			fontWeight: 700,
			fontSize: '2rem',
			color: '#0C3957'
		},
		button: {
			fontSize: '0.8rem'
		}
	}
});