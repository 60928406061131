import React from 'react';

function AmmoniteInformation({  }) {
	return (

<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M16.2 10.8C16.2 9.8064 17.0064 9 18 9C18.9936 9 19.8 9.8064 19.8 10.8C19.8 11.7936 18.9936 12.6 18 12.6C17.0064 12.6 16.2 11.7936 16.2 10.8ZM16.1998 16.2C16.1998 15.2064 17.0062 14.4 17.9998 14.4C18.9934 14.4 19.7998 15.2064 19.7998 16.2V25.2C19.7998 26.1936 18.9934 27 17.9998 27C17.0062 27 16.1998 26.1936 16.1998 25.2V16.2ZM18 32.4C10.0602 32.4 3.6 25.9398 3.6 18C3.6 10.0602 10.0602 3.6 18 3.6C25.9398 3.6 32.4 10.0602 32.4 18C32.4 25.9398 25.9398 32.4 18 32.4M18 0C8.0586 0 0 8.0586 0 18C0 27.9414 8.0586 36 18 36C27.9396 36 36 27.9414 36 18C36 8.0586 27.9396 0 18 0" fill="#7A7A7A"/>
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="36">
<path fillRule="evenodd" clipRule="evenodd" d="M16.2 10.8C16.2 9.8064 17.0064 9 18 9C18.9936 9 19.8 9.8064 19.8 10.8C19.8 11.7936 18.9936 12.6 18 12.6C17.0064 12.6 16.2 11.7936 16.2 10.8ZM16.1998 16.2C16.1998 15.2064 17.0062 14.4 17.9998 14.4C18.9934 14.4 19.7998 15.2064 19.7998 16.2V25.2C19.7998 26.1936 18.9934 27 17.9998 27C17.0062 27 16.1998 26.1936 16.1998 25.2V16.2ZM18 32.4C10.0602 32.4 3.6 25.9398 3.6 18C3.6 10.0602 10.0602 3.6 18 3.6C25.9398 3.6 32.4 10.0602 32.4 18C32.4 25.9398 25.9398 32.4 18 32.4M18 0C8.0586 0 0 8.0586 0 18C0 27.9414 8.0586 36 18 36C27.9396 36 36 27.9414 36 18C36 8.0586 27.9396 0 18 0" fill="white"/>
</mask>
<g mask="url(#mask0)">
</g>
</svg>


	);
}

export default AmmoniteInformation;
