import React from 'react';

function AmmoniteCareFeesSummaryIcon({colour}) {
	let color = "#3F762C"
	if (colour) {
		color = colour
	}
	return (
		<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M10.8295 9C9.53354 9 8.31854 9.501 7.40954 10.4115C5.52254 12.3015 5.52254 15.378 7.41104 17.271L17.9995 27.8775L28.5895 17.271C30.478 15.378 30.478 12.3015 28.5895 10.4115C26.7715 8.589 23.5675 8.592 21.7495 10.4115L19.0615 13.104C18.4975 13.6695 17.5015 13.6695 16.9375 13.104L14.2495 10.41C13.3405 9.501 12.127 9 10.8295 9ZM17.9996 31.5C17.6021 31.5 17.2196 31.3425 16.9391 31.059L5.28708 19.389C2.23308 16.329 2.23308 11.3505 5.28708 8.2905C6.76308 6.8145 8.73108 6 10.8296 6C12.9281 6 14.8976 6.8145 16.3721 8.2905L17.9996 9.921L19.6271 8.292C21.1031 6.8145 23.0711 6 25.1711 6C27.2681 6 29.2376 6.8145 30.7121 8.2905C33.7676 11.3505 33.7676 16.329 30.7136 19.389L19.0616 31.0605C18.7796 31.3425 18.3986 31.5 17.9996 31.5Z" fill={color}/>
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="6" width="32" height="26">
<path fillRule="evenodd" clipRule="evenodd" d="M10.8295 9C9.53354 9 8.31854 9.501 7.40954 10.4115C5.52254 12.3015 5.52254 15.378 7.41104 17.271L17.9995 27.8775L28.5895 17.271C30.478 15.378 30.478 12.3015 28.5895 10.4115C26.7715 8.589 23.5675 8.592 21.7495 10.4115L19.0615 13.104C18.4975 13.6695 17.5015 13.6695 16.9375 13.104L14.2495 10.41C13.3405 9.501 12.127 9 10.8295 9ZM17.9996 31.5C17.6021 31.5 17.2196 31.3425 16.9391 31.059L5.28708 19.389C2.23308 16.329 2.23308 11.3505 5.28708 8.2905C6.76308 6.8145 8.73108 6 10.8296 6C12.9281 6 14.8976 6.8145 16.3721 8.2905L17.9996 9.921L19.6271 8.292C21.1031 6.8145 23.0711 6 25.1711 6C27.2681 6 29.2376 6.8145 30.7121 8.2905C33.7676 11.3505 33.7676 16.329 30.7136 19.389L19.0616 31.0605C18.7796 31.3425 18.3986 31.5 17.9996 31.5Z" fill={color}/>
</mask>
<g mask="url(#mask0)">
</g>
</svg>

	);
}

export default AmmoniteCareFeesSummaryIcon;
