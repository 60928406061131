// Core React dependencies
import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router';

import SuitabilityReportNavigation from './../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation';
import PersonCardAdditional from './../../../containers/PersonCardAdditional/personCardAdditional';
import { saveContextToFirebase } from '../../../Utils/data/saveSuitabilityContext'
import SuitabilityReportContext from '../../../Utils/contexts/suitabilityReportContext';
// Component imports


// Styling
import './furtherCustomerInfo.css';
import styles from '../../../Styles/srbstyles.module.css'


function FurtherCustomerInformation() {
    const { push } = useHistory();
    const { state, status, error, dispatch } = useContext(SuitabilityReportContext);
    
    const exitHandler = () => {
        push('/suitabilitydashboard');
    }


    const saveHandler = async () => {
        await saveContextToFirebase(state).then(() => console.log("SAVED"))
    }

    const backHandler = () => {
        push('/additionalcustomerinformation')
    }

    const nextHandler = () => {
        push('/childrendetails')
    }


    useEffect(() => {

    }, [])

	return <div className={styles.suitabilityPanel}>
        <div className={styles.pageTitleText}>Additional Customer Information</div>
        <div className="additionalPersonCards">
            {!!status !== "success" && state && <PersonCardAdditional
                customerID={1}
                customer={state.customerInfo.customers.customer1}
            />}
            {!!status !== "success" && state.customerInfo.couple && <PersonCardAdditional
                customerID={2}
                customer={state.customerInfo.customers.customer2}
            />}
        </div>

        <div className={styles.menuBar}>
            <div className={styles.menuChips}>
                <SuitabilityReportNavigation
                    showBack
                    showSave
                    showNext
                    handleExit={exitHandler}
                    handleBack={backHandler}
                    handleSave={saveHandler}
                    handleNext={nextHandler}
                />
            </div>
        </div>
    </div>;
}

export default FurtherCustomerInformation;

