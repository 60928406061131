import React from "react";

function NewAmmoniteLoginLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="269"
      height="124.302"
      viewBox="0 0 269 124.302"
    >
      <g transform="translate(-489.323 -322.506)">
        <path
          fill="#232323"
          d="M803.625,341.042l-4.546-17.611a29.342,29.342,0,1,1,8.374,57.735q-.516.026-1.038.026a20.172,20.172,0,0,1-2.79-40.15Zm51.35,39.953-4.546,17.612a29.343,29.343,0,1,0,8.374-57.735q-.516-.026-1.038-.026a20.172,20.172,0,0,0-2.79,40.15Z"
          transform="translate(-212.072)"
        />
        <g transform="translate(489.323 399.531)">
          <circle
            fill="#212121"
            cx="6.112"
            cy="6.112"
            r="6.112"
            transform="translate(203.482)"
          />
          <path
            fill="#212121"
            d="M505.335,647.453c-3.558,0-5.276,1.35-5.583,4.356h-9.2c.307-6.012,4.6-11.1,14.846-11.1,8.834,0,13.742,3.436,13.742,13.313V662.8c0,4.525,1.4,4.892,4.05,4.892v5.991c-3.573,0-4.653-.015-7.774-.026-3.515-.012-5.23-1.613-5.785-3.878h-.123a13.414,13.414,0,0,1-9.877,4.294c-6.626,0-10.306-3.742-10.306-9.018,0-6.258,4.6-9.141,11.963-10.613,4.049-.8,8.037-1.411,8.037-4.049C509.323,648.558,508.034,647.453,505.335,647.453Zm3.988,14.171v-3.8a31.693,31.693,0,0,1-5.031,1.411c-3.865.859-5.153,2.454-5.153,4.417,0,2.577,1.534,3.681,3.926,3.681C507.3,667.33,509.323,664.631,509.323,661.625Z"
            transform="translate(-489.323 -626.802)"
          />
          <path
            fill="#212121"
            d="M652.4,651.87v21.818h-9.938V655c0-4.724-.982-6.319-4.479-6.319-3.129,0-5.092,1.9-5.092,6.258v18.751H623.08V655c0-4.724-.982-6.319-4.478-6.319-3.068,0-5.092,1.9-5.092,6.258v18.751h-9.816v-32h9.816v4.54h.123a11.214,11.214,0,0,1,9.816-5.521,8.386,8.386,0,0,1,8.221,5.521h.123c2.7-3.129,5.46-5.521,10.552-5.521C648.662,640.705,652.4,644.693,652.4,651.87Z"
            transform="translate(-571.011 -626.802)"
          />
          <path
            fill="#212121"
            d="M833.391,651.87v21.818h-9.938V655c0-4.724-.982-6.319-4.478-6.319-3.129,0-5.092,1.9-5.092,6.258v18.751h-9.816V655c0-4.724-.982-6.319-4.478-6.319-3.067,0-5.092,1.9-5.092,6.258v18.751h-9.815v-32H794.5v4.54h.123a11.214,11.214,0,0,1,9.816-5.521,8.386,8.386,0,0,1,8.221,5.521h.123c2.7-3.129,5.46-5.521,10.552-5.521C829.649,640.705,833.391,644.693,833.391,651.87Z"
            transform="translate(-700.28 -626.802)"
          />
          <path
            fill="#212121"
            d="M979.538,674.078c-10.49,0-16.809-6.932-16.809-16.687s6.319-16.687,16.809-16.687c10.429,0,16.748,6.932,16.748,16.687S989.968,674.078,979.538,674.078Zm0-7.362c4.294,0,6.932-3.251,6.932-9.325s-2.638-9.325-6.932-9.325c-4.356,0-6.994,3.252-6.994,9.325S975.183,666.717,979.538,666.717Z"
            transform="translate(-827.449 -626.802)"
          />
          <path
            fill="#212121"
            d="M1119.036,651.87v21.779h-9.816V655c0-4.724-1.656-6.319-4.908-6.319-3.742,0-5.89,1.9-5.89,6.258v18.711h-9.816V641.687h9.816v4.54h.123c1.656-2.883,4.724-5.521,10.307-5.521C1114.925,640.705,1119.036,644.693,1119.036,651.87Z"
            transform="translate(-917.357 -626.802)"
          />
          <path
            fill="#212121"
            d="M1215.43,644.14V676.1h-9.816V644.14Z"
            transform="translate(-1000.928 -629.255)"
          />
          <path
            fill="#212121"
            d="M1339.469,663.588h9.755c-1.718,6.319-6.625,10.49-15.153,10.49-10.429,0-16.748-6.871-16.748-16.687,0-9.57,6.2-16.687,16.2-16.687,10.736,0,16.2,7.853,16.2,19.264h-22.576a6.465,6.465,0,0,0,6.748,6.748C1337.261,666.717,1338.856,665.122,1339.469,663.588Zm-12.331-9.57h12.76c0-4.356-2.638-6.564-6.38-6.564S1327.139,649.662,1327.139,654.018Z"
            transform="translate(-1080.715 -626.802)"
          />
          <path
            fill="#232323"
            d="M1255.669,629.574v13.006c0,3.558,1.289,3.558,5.46,3.558V653.5a26,26,0,0,1-6.073.675c-7.178,0-9.2-3.8-9.2-10.858V629.574h-5.889v-7.362h5.889V613.44h9.816v8.773h5.46v7.362Z"
            transform="translate(-1025.462 -607.328)"
          />
          <path
            fill="#212121"
            d="M1245.292,622.751a6.112,6.112,0,0,1-6.108,6.112h6.112v-6.112Z"
            transform="translate(-1024.905 -613.978)"
          />
        </g>
      </g>
    </svg>
  );
}

export default NewAmmoniteLoginLogo;
