//React
import React from 'react';

//React router dom
import { useHistory } from 'react-router';

//external libraries
import Button from '@material-ui/core/Button';

//components
import './validationWarning.css';

//assets

function ValidationWarning() {
    const history = useHistory();

	const noDOBHandler = () => {
		history.push('/basicinformation');
	};

	return (
		<div className="validationWarningContainer">
			<div className="validationMessage">
                <div className=" ">
                    <h1 className="warningText">Please provide valid dates of birth</h1>
                    <div className="warningButtonContainer">
                    <Button
                        variant="contained"
                        onClick={() => noDOBHandler()}
                        style={{
                            width: '123px',
                            height: '46px',
                            borderRadius: '8px',
                            fontFamily: 'Brother 1816',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: '18px',
                            lineHeight: '26px',
                            letterSpacing: '0.02em',
                            color: 'white',
                            backgroundColor: '#BF802F',
                            textTransform: 'none'
                        }}
                    >
                        Set DOBs
                    </Button>
                    </div>
                </div>
            </div>
		</div>
	);
}

export default ValidationWarning;
