import React from 'react';

function EducationAllocationButton({ colour }) {
	return (
<svg width="70" height="30" viewBox="0 0 70 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<rect width="70" height="30" rx="15" fill="#69306D"/>
<path d="M47.3753 10.6679V10.5952V10.5225C47.3143 10.38 47.3143 10.3073 47.1946 10.1648V10.0921L47.1336 10.0194C47.0726 9.94678 46.9529 9.87691 46.8331 9.80424L35.3577 3.44016C35.1182 3.29762 34.8178 3.29762 34.576 3.44016L23.0398 9.80703C22.6784 10.0222 22.5 10.38 22.5 10.8076C22.5 11.238 22.7394 11.6656 23.0398 11.8082L27.0066 13.9547V20.8247C27.0066 23.6867 30.4311 25.8333 34.9988 25.8333C39.5665 25.8333 42.991 23.6867 42.991 20.8247V13.9547L45.5753 12.5237V22.11C45.5753 22.7529 45.9954 23.256 46.5376 23.256C47.0775 23.256 47.5 22.7557 47.5 22.11V10.6619C47.3756 10.7374 47.3756 10.7374 47.3756 10.6675L47.3753 10.6679ZM34.9378 23.6894C31.2106 23.6894 28.8678 22.0432 28.8678 20.9699V15.1033L34.5151 18.2504C34.6348 18.3231 34.7545 18.3231 34.9352 18.3231C35.0549 18.3231 35.2357 18.3231 35.3554 18.2504L41.0026 15.1033V20.9699C41.005 22.0432 38.6015 23.6894 34.9374 23.6894H34.9378ZM34.9378 15.9612L25.6856 10.8799L34.9378 5.7987L44.1901 10.8799L34.9378 15.9612Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="70" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>


	);
}

export default EducationAllocationButton;
