export const investmentProviderData = {
  investmentStrategy: {
    Cash: {
      "Fund Manager": null,
      "Equity Content": null,
      "Charges per annum": 0,
    },
    "Vanguard LifeStrategy 20% Equity fund": {
      "Fund Manager": "Vanguard LifeStrategy",
      "Equity Content": 0.2,
      "Charges per annum": 0.0022,
    },
    "BlackRock MyMap 3 fund": {
      "Fund Manager": "BlackRock MyMap",
      "Equity Content": 0.27,
      "Charges per annum": 0.0017,
    },
    "BlackRock MyMap 4 fund": {
      "Fund Manager": "BlackRock MyMap",
      "Equity Content": 0.47,
      "Charges per annum": 0.0017,
    },
    "BlackRock MyMap 5 fund": {
      "Fund Manager": "BlackRock MyMap",
      "Equity Content": 0.64,
      "Charges per annum": 0.0017,
    },
    "BlackRock MyMap 6 fund": {
      "Fund Manager": "BlackRock MyMap",
      "Equity Content": 0.8,
      "Charges per annum": 0.0017,
    },
    "Vanguard LifeStrategy 100% Equity fund": {
      "Fund Manager": "Vanguard LifeStrategy",
      "Equity Content": 1,
      "Charges per annum": 0.0022,
    },
    "Tatton Global Core Defensive portfolio": {
      "Fund Manager": "Tatton Global Core",
      "Equity Content": 0.25,
      "Charges per annum": 0.0047,
    },
    "Tatton Global Core Cautious portfolio": {
      "Fund Manager": "Tatton Global Core",
      "Equity Content": 0.45,
      "Charges per annum": 0.0049,
    },
    "Tatton Global Core Balanced portfolio": {
      "Fund Manager": "Tatton Global Core",
      "Equity Content": 0.6,
      "Charges per annum": 0.0051,
    },
    "Tatton Global Core Active portfolio": {
      "Fund Manager": "Tatton Global Core",
      "Equity Content": 0.75,
      "Charges per annum": 0.0052,
    },
    "Tatton Global Core Aggressive portfolio": {
      "Fund Manager": "Tatton Global Core",
      "Equity Content": 0.9,
      "Charges per annum": 0.0048,
    },
    "Tatton Global Core Equity portfolio": {
      "Fund Manager": "Tatton Global Core",
      "Equity Content": 0.98,
      "Charges per annum": 0.0048,
    },
    "EQ Future Leaders Defensive portfolio": {
      "Fund Manager": "EQ Future Leaders",
      "Equity Content": 0.3,
      "Charges per annum": 0.0044,
    },
    "EQ Future Leaders Defensive portfolio": {
      "Fund Manager": "EQ Future Leaders",
      "Equity Content": 0.3,
      "Charges per annum": 0.0044,
    },
    "EQ Future Leaders Cautious portfolio": {
      "Fund Manager": "EQ Future Leaders",
      "Equity Content": 0.4,
      "Charges per annum": 0.0045,
    },
    "BlackRock MyMap 5 ESG fund": {
      "Fund Manager": "BlackRock MyMap ESG",
      "Equity Content": 0.64,
      "Charges per annum": 0.0017,
    },
    "EQ Future Leaders Balanced Plus portfolio": {
      "Fund Manager": "EQ Future Leaders",
      "Equity Content": 0.7,
      "Charges per annum": 0.0049,
    },
    "EQ Future Leaders Adventurous portfolio": {
      "Fund Manager": "EQ Future Leaders",
      "Equity Content": 0.82,
      "Charges per annum": 0.005,
    },
    "EQ Future Leaders Adventurous Plus portfolio": {
      "Fund Manager": "EQ Future Leaders",
      "Equity Content": 0.93,
      "Charges per annum": 0.0051,
    },
    "Cazenove Discretionary Fund Management bespoke portfolio": {
      "Fund Manager": "Cazenove Discretionary Fund Management",
      "Equity Content": "shown in your investment proposal",
      "Charges per annum": null,
    },
  },
};
