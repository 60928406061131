// Core React dependencies
import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';

import SuitabilityReportNavigation from './../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation';
import PensionCard from '../../../containers/PensionCard/pensionCard';
import Loader from 'react-loader-spinner';
import AmmoniteArrowButton from '../../../Assets/SVG/arrow';
import AmmonitePlusButton from '../../../Assets/SVG/plusButton';
import firebase from '../../../Utils/firebase';
import { AmmoniteInitialiseSuitabilityContext } from '../../../Utils/data/initialiseSuitabilityContext'
import { saveContextToFirebase } from '../../../Utils/data/saveSuitabilityContext'

import SuitabilityReportContext from '../../../Utils/contexts/suitabilityReportContext';
import { 
    initialiseSBReport,
    setNames,
    addPension,
    deletePension,
} from '../../../Utils/reducers/suitabilityReportActions';

// Styling
import './existingPensionsDetails.css';
import styles from '../../../Styles/srbstyles.module.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


function ExistingPensionsDetails() {
    const { push } = useHistory();
    const { state, status, error, dispatch } = useContext(SuitabilityReportContext);
    const [ currentPensionIndex, setCurrentPensionIndex ] = useState(0);
    
    const exitHandler = () => {
        push('/suitabilitydashboard');
    }

    const forwardPension = (event) => {
        event.preventDefault();
        let _newPensionIndex = currentPensionIndex + 1;
        if (_newPensionIndex < state.pensions.length) {
            setCurrentPensionIndex(_newPensionIndex)
        }
    }

    const backPension = (event) => {
        event.preventDefault();
        let _newPensionIndex = currentPensionIndex - 1;
        if (_newPensionIndex >= 0) {
            setCurrentPensionIndex(_newPensionIndex)
        }
    }

    const handleAddPension = (event) => {
        event.preventDefault();
        dispatch({ type: addPension});
    }

    const handleDeletePension = (event) => {
        event.preventDefault();
        dispatch({ type: deletePension, payload: {index: currentPensionIndex} })
        saveHandler()
        backPension(event)
    }

    const saveHandler = async () => {
        await saveContextToFirebase(state).then(() => console.log("SAVED"))
    }

    useEffect(() => {
        
    }, [])


	return <div className={styles.suitabilityPanel}>
        {!status !== "success" && state.pensions.length > 0 && currentPensionIndex !== undefined && <div className={styles.pensionOfPensions}>Pension {currentPensionIndex + 1} of {state.pensions.length}</div>}
        <div className={styles.pageTitleText}>Existing Pension Information</div>
            {state.pensions.length > 0 && <div className={styles.prevPension} onClick={(event) => backPension(event)}>
                <AmmoniteArrowButton disabled={currentPensionIndex === 0}/>
            </div>}
            {!status !== "success" && currentPensionIndex !== undefined && state.pensions.length > 0 ? <div className={styles.currentPension}>
              <PensionCard
                    currentPensionIndex={currentPensionIndex}
                    pension={state.pensions[currentPensionIndex]}
                    handleAddPension={(event) => handleAddPension(event)}
                    handleDeletePension={handleDeletePension}
                    couple={state.customerInfo.couple}
                    customer1={state.customerInfo.customers.customer1.customerFirstName}
                    customer2={state.customerInfo.customers.customer2.customerFirstName}
        />
            </div> : ''}
            {status !== "success" && <div className="loaderAnimation"><Loader/></div>}
            {state.pensions.length > 0 && <div className={styles.nextPension} onClick={(event) => forwardPension(event)}>
                <AmmoniteArrowButton disabled={currentPensionIndex === state.pensions.length - 1}/>
            </div>}
            


        <div className={styles.menuBar}>
            <div className={styles.addPension}>
                <div onClick={(event) => handleAddPension(event)}>
                    <AmmonitePlusButton/>
                </div>
                <div className={styles.addPensionText}>Add Pension</div>
            </div>
            <div className={styles.menuChips}>
                <SuitabilityReportNavigation
                    showSave
                    handleExit={exitHandler}
                    handleSave={saveHandler}
                />
            </div>
        </div>
    </div>;
}

export default ExistingPensionsDetails;

