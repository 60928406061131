import React from 'react';

function CareFeesAllocationButton({ colour }) {
	return (
<svg width="70" height="30" viewBox="0 0 70 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<rect width="70" height="30" rx="15" fill="#0C3957"/>
<path fillRule="evenodd" clipRule="evenodd" d="M27.8295 6C26.5335 6 25.3185 6.501 24.4095 7.4115C22.5225 9.3015 22.5225 12.378 24.411 14.271L34.9995 24.8775L45.5895 14.271C47.478 12.378 47.478 9.3015 45.5895 7.4115C43.7715 5.589 40.5675 5.592 38.7495 7.4115L36.0615 10.104C35.4975 10.6695 34.5015 10.6695 33.9375 10.104L31.2495 7.41C30.3405 6.501 29.127 6 27.8295 6ZM34.9996 28.5C34.6021 28.5 34.2196 28.3425 33.9391 28.059L22.2871 16.389C19.2331 13.329 19.2331 8.3505 22.2871 5.2905C23.7631 3.8145 25.7311 3 27.8296 3C29.9281 3 31.8976 3.8145 33.3721 5.2905L34.9996 6.921L36.6271 5.292C38.1031 3.8145 40.0711 3 42.1711 3C44.2681 3 46.2376 3.8145 47.7121 5.2905C50.7676 8.3505 50.7676 13.329 47.7136 16.389L36.0616 28.0605C35.7796 28.3425 35.3986 28.5 34.9996 28.5Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="70" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>



	);
}

export default CareFeesAllocationButton;
