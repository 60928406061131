export const providerInvestmentSolutions = {
    "investmentStrategy":{
       "passive":{
          "riskCategory":{
             "1":{
                "provider":"Aviva",
                "investment":"Vanguard LifeStrategy 20% Equity fund"
             },
             "2":{
                "provider":"Aviva",
                "investment":"BlackRock MyMap 3 fund"
             },
             "3":{
                "provider":"Aviva",
                "investment":"BlackRock MyMap 3 fund"
             },
             "4":{
                "provider":"Aviva",
                "investment":"BlackRock MyMap 4 fund"
             },
             "5":{
                "provider":"Aviva",
                "investment":"BlackRock MyMap 4 fund"
             },
             "6":{
                "provider":"Aviva",
                "investment":"BlackRock MyMap 5 fund"
             },
             "7":{
                "provider":"Aviva",
                "investment":"BlackRock MyMap 5 fund"
             },
             "8":{
                "provider":"Aviva",
                "investment":"BlackRock MyMap 6 fund"
             },
             "9":{
                "provider":"Aviva",
                "investment":"BlackRock MyMap 6 fund"
             },
             "10":{
                "provider":"Aviva",
                "investment":"Vanguard LifeStrategy 100% Equity fund"
             }
          },
       },
          "income":{
             "riskCategory":{
                "1":{
                   "provider":"Aviva",
                   "investment":"Tatton Global Core Defensive portfolio"
                },
                "2":{
                   "provider":"Aviva",
                   "investment":"Tatton Global Core Defensive portfolio"
                },
                "3":{
                   "provider":"Aviva",
                   "investment":"Tatton Global Core Defensive portfolio"
                },
                "4":{
                   "provider":"Aviva",
                   "investment":"Tatton Global Core Cautious portfolio"
                },
                "5":{
                   "provider":"Aviva",
                   "investment":"Tatton Global Core Balanced portfolio"
                },
                "6":{
                   "provider":"Aviva",
                   "investment":"Tatton Global Core Balanced portfolio"
                },
                "7":{
                   "provider":"Aviva",
                   "investment":"Tatton Global Core Active portfolio"
                },
                "8":{
                   "provider":"Aviva",
                   "investment":"Tatton Global Core Active portfolio"
                },
                "9":{
                   "provider":"Aviva",
                   "investment":"Tatton Global Core Aggressive portfolio"
                },
                "10":{
                   "provider":"Aviva",
                   "investment":"Tatton Global Core Equity portfolio"
                }
             }
          },
          "ethical":{
             "riskCategory":{
                "1":{
                   "provider":"Aviva",
                   "investment":"EQ Future Leaders Defensive portfolio"
                },
                "2":{
                   "provider":"Aviva",
                   "investment":"EQ Future Leaders Defensive portfolio"
                },
                "3":{
                   "provider":"Aviva",
                   "investment":"EQ Future Leaders Defensive portfolio"
                },
                "4":{
                   "provider":"Aviva",
                   "investment":"EQ Future Leaders Cautious portfolio"
                },
                "5":{
                   "provider":"Aviva",
                   "investment":"BlackRock MyMap 5 ESG fund"
                },
                "6":{
                   "provider":"Aviva",
                   "investment":"BlackRock MyMap 5 ESG fund"
                },
                "7":{
                   "provider":"Aviva",
                   "investment":"BlackRock MyMap 5 ESG fund"
                },
                "8":{
                   "provider":"Aviva",
                   "investment":"EQ Future Leaders Balanced Plus portfolio"
                },
                "9":{
                   "provider":"Aviva",
                   "investment":"EQ Future Leaders Adventurous portfolio"
                },
                "10":{
                   "provider":"Aviva",
                   "investment":"EQ Future Leaders Adventurous Plus portfolio"
                }
             },
          },
             "dfm":{
                "riskCategory":{
                   "1":{
                     "provider":{
                        "pension":"Curtis Banks",
                        "investment":"Cazenove"
                     },
                      "investment":"Cazenove Discretionary Fund Management bespoke portfolio"
                   },
                   "2":{
                     "provider":{
                        "pension":"Curtis Banks",
                        "investment":"Cazenove"
                     },
                      "investment":"Cazenove Discretionary Fund Management bespoke portfolio"
                   },
                   "3":{
                     "provider":{
                        "pension":"Curtis Banks",
                        "investment":"Cazenove"
                     },
                      "investment":"Cazenove Discretionary Fund Management bespoke portfolio"
                   },
                   "4":{
                     "provider":{
                        "pension":"Curtis Banks",
                        "investment":"Cazenove"
                     },
                      "investment":"Cazenove Discretionary Fund Management bespoke portfolio"
                   },
                   "5":{
                     "provider":{
                        "pension":"Curtis Banks",
                        "investment":"Cazenove"
                     },
                      "investment":"Cazenove Discretionary Fund Management bespoke portfolio"
                   },
                   "6":{
                     "provider":{
                        "pension":"Curtis Banks",
                        "investment":"Cazenove"
                     },
                      "investment":"Cazenove Discretionary Fund Management bespoke portfolio"
                   },
                   "7":{
                     "provider":{
                        "pension":"Curtis Banks",
                        "investment":"Cazenove"
                     },
                      "investment":"Cazenove Discretionary Fund Management bespoke portfolio"
                   },
                   "8":{
                     "provider":{
                        "pension":"Curtis Banks",
                        "investment":"Cazenove"
                     },
                      "investment":"Cazenove Discretionary Fund Management bespoke portfolio"
                   },
                   "9":{
                      "provider":{
                        "pension":"Curtis Banks",
                        "investment":"Cazenove"
                     },
                      "investment":"Cazenove Discretionary Fund Management bespoke portfolio"
                   },
                   "10":{
                     "provider":{
                        "pension":"Curtis Banks",
                        "investment":"Cazenove"
                     },
                      "investment":"Cazenove Discretionary Fund Management bespoke portfolio"
                   }
                }
                
                
          }
       }
    }