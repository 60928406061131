import React, { useState, useEffect, useContext } from 'react';

import AmmonitePencilSVG from '../../Assets/SVG/pencil';
import AmmoniteTrashcan from '../../Assets/SVG/trashcan';

import { saveContextToFirebase } from '../../Utils/data/saveSuitabilityContext'
import SuitabilityReportContext from '../../Utils/contexts/suitabilityReportContext';
import './newPolicyCard.css';

import { 
    deleteNewPensionAdvice,
    deleteNewISAAdvice,
    deleteNewGIAAdvice,
} from '../../Utils/reducers/suitabilityReportActions';



const NewPolicyCard = ({ 
    type,
    owner,
    totalTransfersIn,
    grossMonthlyContributions,
    grossLumpSumContributions,
    policyId,
   
}) => {
    const { state, status, error, dispatch } = useContext(SuitabilityReportContext);

    const saveHandler = async () => {
        await saveContextToFirebase(state).then(() => console.log("SAVED"))
    }

    const handleDeleteNewPolicyCard = (event) => {
        let _deleteType = ""
        let _index = -1
        switch(type){
            case "pension":
                _deleteType = deleteNewPensionAdvice
                _index = state.newPensionsAdvice.findIndex(x => x.policyID === policyId)
                break
            case "isa":
                _deleteType = deleteNewISAAdvice
                _index = state.newISAsAdvice.findIndex(x => x.policyID === policyId)
                break
            case "gia":
                _deleteType = deleteNewGIAAdvice
                _index = state.newGIAsAdvice.findIndex(x => x.policyID === policyId)
                break
        }
        if (_index !== -1 && _deleteType !== ""){
            event.preventDefault();
            dispatch({ type: _deleteType, payload: {index: _index} })
            saveHandler()
        }  
    }
 
	useEffect(() => {

	}, [])

	return (
		<div className="personCard newPolicyCard">
            <div className="newPolicyCardFieldContainer">
                <div className="newPolicyField">
                    <div className="newPolicyFieldTitle">Type:</div>
                <div className="newPolicyFieldTitle">{type}</div>
                </div>
                <div className="newPolicyField">
                    <div className="newPolicyFieldTitle">Owner:</div>
                    <div className="newPolicyFieldTitle">{owner}</div>
                </div>
                <div className="newPolicyField">
                    <div className="newPolicyFieldTitle">Transfer in:</div>
                    <div className="newPolicyFieldTitle">£{totalTransfersIn}</div>
                </div>
                <div className="newPolicyField">
                    <div className="newPolicyFieldTitle">Gross Monthly Contributions:</div>
                    <div className="newPolicyFieldTitle">£{grossMonthlyContributions}</div>
                </div>
                <div className="newPolicyField">
                    <div className="newPolicyFieldTitle">Gross Lump Sum Contributions:</div>
                    <div className="newPolicyFieldTitle">£{grossLumpSumContributions}</div>
                </div>
            </div>
            <div className="newPolicyCardIcons">
                {/* <div className="newPolicyCardEdit">
                    <AmmonitePencilSVG colour="#7A7A7A" scale={0.8}/>
                </div> */}
                <div className="newPolicyCardTrashcan" onClick={(event) => handleDeleteNewPolicyCard(event)}>
                    <AmmoniteTrashcan colour="#7A7A7A"/>
                </div>
            </div>
		</div>
	);
}

export default NewPolicyCard;
