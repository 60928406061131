//React
import React from 'react';

//external libraries

//components
import './reportAvatar.css';

//assets

function AmmoniteReportAvatar({ avatarPhoto }) {
	return (
		<img className="reportAvatar" src={avatarPhoto}/>
	);
}

export default AmmoniteReportAvatar;
