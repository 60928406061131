import React from 'react';

import './navigationArrow.css';

function AmmoniteNavigationArrow({ back }) {
	return (
		<svg
			className={`arrowContainer ${back ? 'isBack' : ''}`}
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1 8H10.865L7.232 12.36C6.878 12.784 6.936 13.415 7.36 13.768C7.785 14.122 8.415 14.064 8.769 13.64L13.769 7.64C13.808 7.593 13.827 7.538 13.856 7.486C13.88 7.444 13.909 7.408 13.927 7.362C13.972 7.247 13.999 7.126 13.999 7.004L14 7L13.999 6.996C13.999 6.874 13.972 6.753 13.927 6.638C13.909 6.592 13.88 6.556 13.856 6.514C13.827 6.462 13.808 6.407 13.769 6.36L8.769 0.36C8.57 0.123 8.286 0 8 0C7.774 0 7.547 0.076 7.36 0.232C6.936 0.585 6.878 1.216 7.232 1.64L10.865 6H1C0.448 6 0 6.448 0 7C0 7.552 0.448 8 1 8Z"
				fill="white"
			/>
		</svg>
	);
}

export default AmmoniteNavigationArrow;
