import React from "react";

function AmmoniteMenuCircleFilledCross() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="16"
        fill="#3BB9C4"
        stroke="#3BB9C4"
        stroke-width="4"
      />
      <path
        d="M22.5962 11.9897C22.4009 11.7944 22.0843 11.7944 21.8891 11.9897L18 15.8788L14.1109 11.9897C13.9157 11.7944 13.5991 11.7944 13.4038 11.9897L11.9896 13.4039C11.7943 13.5991 11.7943 13.9157 11.9896 14.111L15.8787 18.0001L11.9896 21.8892C11.7943 22.0844 11.7943 22.401 11.9896 22.5963L13.4038 24.0105C13.5991 24.2058 13.9157 24.2058 14.1109 24.0105L18 20.1214L21.8891 24.0105C22.0843 24.2058 22.4009 24.2058 22.5962 24.0105L24.0104 22.5963C24.2057 22.401 24.2057 22.0844 24.0104 21.8892L20.1213 18.0001L24.0104 14.111C24.2057 13.9157 24.2057 13.5991 24.0104 13.4039L22.5962 11.9897Z"
        fill="white"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default AmmoniteMenuCircleFilledCross;
