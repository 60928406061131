// Core React dependencies
// Core React dependencies
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

// Component imports
import SuitabilityReportNavigation from './../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation';

import Loader from 'react-loader-spinner';
import NewAdviceCard from '../../../containers/NewAdviceCard/newAdviceCard';
import AmmoniteArrowButton from '../../../Assets/SVG/arrow';

import { saveContextToFirebase } from '../../../Utils/data/saveSuitabilityContext'

import AmmonitePlusButton from '../../../Assets/SVG/plusButton';

import SuitabilityReportContext from '../../../Utils/contexts/suitabilityReportContext';
import { 
    initialiseSBReport,
    deleteNewGIAAdvice,
    addNewGIAAdvice
} from '../../../Utils/reducers/suitabilityReportActions';

// Styling
import './newGIAAdvice.css';
import styles from '../../../Styles/srbstyles.module.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { functions } from 'firebase';

function NewGIAAdvice() {
    const { push } = useHistory();
    const { state, status, error, dispatch } = useContext(SuitabilityReportContext);
    const [ currentGIAAdviceIndex, setcurrentGIAAdviceIndex ] = useState(0);

    const forwardGIA = (event) => {
        event.preventDefault();
        let _newGIAIndexforward = currentGIAAdviceIndex + 1;
        if (_newGIAIndexforward < state.newGIAsAdvice.length) {
            setcurrentGIAAdviceIndex(_newGIAIndexforward)
        }
    }

    const backGIA = (event) => {
        event.preventDefault();
        let _newGIAIndexbackward = currentGIAAdviceIndex - 1;
        if (_newGIAIndexbackward >= 0) {
            setcurrentGIAAdviceIndex(_newGIAIndexbackward)
        }
    }

    const handleDeleteNewGIA = (event) => {
        event.preventDefault();
        dispatch({ type: deleteNewGIAAdvice, payload: {index: currentGIAAdviceIndex} })
        saveHandler()
        backGIA(event)
    }

    const exitHandler = () => {
        push('/suitabilityreportbuilder');
    }

    const handleAddGIA = (event) => {
        event.preventDefault();
        dispatch({ type: addNewGIAAdvice});
    }

    const saveHandler = async () => {
        await saveContextToFirebase(state).then(() => console.log("SAVED"))
    }

    useEffect(() => {

    }, [])

	return <div className={styles.suitabilityPanel}>
        {!status !== "success" && state.newGIAsAdvice.length > 0 && currentGIAAdviceIndex !== undefined && <div className={styles.pensionOfPensions}>GIA {currentGIAAdviceIndex + 1} of {state.newGIAsAdvice.length}</div>}

        <div className={styles.pageTitleText}>New GIA Advice</div>
            {!status !== "success" && state.newGIAsAdvice.length > 0 && <div className={styles.prevPension} onClick={(event) => backGIA(event)}>
                <AmmoniteArrowButton disabled={currentGIAAdviceIndex === 0}/>
            </div>}
            {!status !== "success" && currentGIAAdviceIndex !== undefined && state.newGIAsAdvice.length > 0 ? <div className={styles.currentPension}>
                <NewAdviceCard
                    cardType="New GIA"
                    newAdviceIndex={currentGIAAdviceIndex}
                    handleDeleteNewCard={handleDeleteNewGIA}
                    newCard={state.newGIAsAdvice[currentGIAAdviceIndex]}
                    goals={state.newGIAsAdvice[currentGIAAdviceIndex].goalAllocations}
                    couple={state.customerInfo.couple}
                    customer1={state.customerInfo.customers.customer1.customerFirstName}
                    customer2={state.customerInfo.customers.customer2.customerFirstName}
                />
            </div> : ""}
            {status !== "success" && <div className="loaderAnimation"><Loader/></div>}
            {!status !== "success" && state.newGIAsAdvice.length > 0 && <div className={styles.nextPension} onClick={(event) => forwardGIA(event)}>
                <AmmoniteArrowButton disabled={currentGIAAdviceIndex === state.newGIAsAdvice.length - 1}/>
            </div>}
        

        <div className={styles.menuBar}>
        <div className={styles.addPension}>
                <div onClick={(event) => {handleAddGIA(event)}}>
                    <AmmonitePlusButton/>
                </div>
                <div className={styles.addPensionText}>Add New GIA</div>
            </div>
            <div className={styles.menuChips}>
                <SuitabilityReportNavigation
                    showSave
                    handleExit={exitHandler}
                    handleSave={saveHandler}
                />
            </div>
        </div>
    </div>;
}

export default NewGIAAdvice;

