// Core React dependencies
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import path from "path";

// External libraries
import { useToasts } from "react-toast-notifications";

// Component imports
import Loader from "react-loader-spinner";
import AmmoniteButton from "../../../components/Button/button";
import AmmoniteSBContextBar from "../../../containers/SuitabilityContextBar/suitabilityContextBar";
import AmmoniteCreateContext from "../../../Assets/SVG/SBContextBarIcons/CreateNewContext/createNewContext";
import AmmoniteHome from "../../../Assets/SVG/SBContextBarIcons/Home/home";
import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import { setCurrentSession } from "../../../Utils/reducers/suitabilityReportActions";

import firebase from "../../../Utils/firebase";
import docxCreator from "../../../Utils/docx/docxCreator";

// Styling
import "./documentLibrary.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import styles from "../../../Styles/srbstyles.module.css";

function DocumentLibrary() {
  const { push } = useHistory();
  const location = useLocation();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [uid, setUID] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [session, setSession] = useState();
  const [sessionRef, setSessionRef] = useState(0);
  const [showTakeToPreview, setShowTakeToPreview] = useState(false);
  const [showEditPopUp, setShowEditPopUp] = useState(false);
  const { addToast } = useToasts();
  const navigateToDashboard = () => {
    push("/dashboard");
  };

  const getSuitabilityContexts = async () => {
    let data;
    await sessionRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          data = doc.data();
          if (!doc.data().suitabilityReports) {
            sessionRef
              .update({
                suitabilityReports: [],
              })
              .then(function () {
                console.log("Document successfully updated!");
              })
              .catch(function (error) {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
              });
          }
        } else {
          console.log("ERROR DOC DOES NOT EXIST");
        }
      })
      .then(() => setLoading(false));
    return setData(data);
  };

  const implement = (index) => {
    data.suitabilityReports.map((x) => {
      x.current = false;
      return x;
    });
    data.suitabilityReports[index].current = true;
    setData(data);
    return setCurrentIndex(index);
  };

  const createNewContext = async () => {
    setLoading(true);
    await sessionRef.get().then(function (doc) {
      let _suitabilityReports = doc.data().suitabilityReports;
      console.log(
        "SUITABILITY REPORTS FOR THIS USER IN DOC LIBRARY",
        _suitabilityReports
      );

      _suitabilityReports.map((x) => {
        x.current = false;
        return x;
      });

      _suitabilityReports.push({ current: true });

      return sessionRef
        .update({
          suitabilityReports: [..._suitabilityReports],
        })
        .then(function () {
          console.log("Document successfully updated!");
          getSuitabilityContexts();
        })
        .then(() => {
          getCurrentSuitabilityContext();
        })
        .catch(function (error) {
          // The document doesn't exist.
          console.error("Error updating document: ", error);
        });
    });
  };

  const getCurrentSuitabilityContext = () => {
    let _currentIndex = data.suitabilityReports.findIndex(
      (d) => d.current === true
    );
    if (_currentIndex === -1) {
      return 0;
    }
    return _currentIndex;
  };

  const getUncurrentSuitabilityContexts = () => {
    let _data = [...data.suitabilityReports];
    _data.map((d, i) => (d.index = i));
    _data.splice(currentIndex, 1);
    return _data;
  };

  const edit = () => {
    const currentSession = localStorage.getItem("currentSession");
    dispatch({ type: setCurrentSession, payload: currentSession });
    push("suitabilitydashboard");
  };

  const createNewDocX = async (index) => {
    if (data.suitabilityReports[index].customerInfo === undefined) {
      setCurrentIndex(index);
      setShowEditPopUp(true);
    } else if (
      data.suitabilityReports[index].customerInfo.couple === true &&
      data.suitabilityReports[index].customerInfo.customers.customer2
        .customerDoB === null
    ) {
      addToast("Invalid couple DoB", {
        appearance: "error2",
        autoDismiss: false,
      });
    } else if (
      !data.suitabilityReports[index].previewed ||
      data.suitabilityReports[index].previewed === undefined
    ) {
      setCurrentIndex(index);
      setShowTakeToPreview(true);
    } else {
      try {
        docxCreator(
          data.suitabilityReports[index],
          `${data.suitabilityReports[index].suitabilityReportID}.docx`
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getSessionRef = () => {
    const _sessionRef = firebase
      .firestore()
      .collection("sessions")
      .doc(session);
    return setSessionRef(_sessionRef);
  };

  useEffect(() => {
    if (!session) {
      const _session = localStorage.getItem("currentSession");
      setSession(_session);
    }
    if (session && !sessionRef) {
      getSessionRef();
    }
    if (!data && session && sessionRef) {
      getSuitabilityContexts();
    }
    if (data && data.length > 1 && session && sessionRef) {
      const _currentIndex = getCurrentSuitabilityContext();
      setCurrentIndex(_currentIndex);
    }

    if (currentIndex !== undefined) {
      console.log("SETTING CURRENT INDEX", currentIndex);
      localStorage.setItem("currentIndex", currentIndex);
    }

    return;
  }, [session, sessionRef, data, uid, currentIndex]);

  return (
    <div className={styles.suitabilityPanel}>
      <div className={styles.pageTitleText}>Document Library</div>
      <div className="documentLibraryPanel">
        {data &&
        data.suitabilityReports &&
        data.suitabilityReports.length > 0 ? (
          <div className="contextBars">
            <div className="currentPanel">
              <div className="currentTitle">Current</div>
              <div className="historyInnerPanel currentInnerPanel">
                <AmmoniteSBContextBar
                  index={currentIndex}
                  currentIndex={currentIndex}
                  contextDate={
                    data.suitabilityReports[currentIndex].sessionDate
                  }
                  createDocX={async (index) => await createNewDocX(index)}
                  edit={() => edit()}
                />
              </div>

              <div className="currentTitle">History</div>
              <div className="historyInnerPanel">
                {data.suitabilityReports.length > 1 &&
                  getUncurrentSuitabilityContexts().map((item) => {
                    return (
                      <AmmoniteSBContextBar
                        key={item.index}
                        index={item.index}
                        currentIndex={currentIndex}
                        contextDate={item.sessionDate}
                        createDocX={(index) => createNewDocX(index)}
                        implement={(index) => implement(index)}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {loading && (
          <div className="loaderAnimation">
            <Loader />
          </div>
        )}
      </div>
      <div className="bottomBar">
        <div onClick={() => createNewContext()}>
          <AmmoniteCreateContext />
        </div>
        <div className="home" onClick={() => navigateToDashboard()}>
          <AmmoniteHome />
        </div>
      </div>
      {showTakeToPreview && (
        <div className="blockInputPane">
          <div className="docPopUpBox">
            <div
              className="closeButton"
              onClick={() => setShowTakeToPreview(false)}
            >
              <div className="closeText">Close</div>
              <div>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 3C15.0333 3 12.1332 3.87973 9.66645 5.52796C7.19972 7.17618 5.27713 9.51886 4.14181 12.2597C3.0065 15.0006 2.70945 18.0166 3.28823 20.9264C3.86701 23.8361 5.29562 26.5088 7.3934 28.6066C9.49119 30.7044 12.1639 32.133 15.0737 32.7118C17.9834 33.2906 20.9994 32.9935 23.7403 31.8582C26.4811 30.7229 28.8238 28.8003 30.472 26.3336C32.1203 23.8668 33 20.9667 33 18C33 16.0302 32.612 14.0796 31.8582 12.2597C31.1044 10.4399 29.9995 8.78628 28.6066 7.3934C27.2137 6.00052 25.5601 4.89563 23.7403 4.14181C21.9204 3.38799 19.9698 3 18 3ZM18 30C15.6266 30 13.3066 29.2962 11.3332 27.9776C9.35977 26.6591 7.8217 24.7849 6.91345 22.5922C6.0052 20.3995 5.76756 17.9867 6.23058 15.6589C6.69361 13.3311 7.83649 11.1929 9.51472 9.51472C11.193 7.83649 13.3312 6.6936 15.6589 6.23058C17.9867 5.76755 20.3995 6.00519 22.5922 6.91345C24.7849 7.8217 26.6591 9.35977 27.9776 11.3332C29.2962 13.3065 30 15.6266 30 18C30 21.1826 28.7357 24.2348 26.4853 26.4853C24.2349 28.7357 21.1826 30 18 30Z"
                    fill="#BDBDBD"
                  />
                  <path
                    d="M22.065 13.9349C21.9255 13.7943 21.7596 13.6827 21.5768 13.6066C21.394 13.5304 21.198 13.4912 21 13.4912C20.802 13.4912 20.6059 13.5304 20.4231 13.6066C20.2403 13.6827 20.0744 13.7943 19.935 13.9349L18 15.8849L16.065 13.9349C15.7825 13.6525 15.3994 13.4938 15 13.4938C14.6005 13.4938 14.2174 13.6525 13.935 13.9349C13.6525 14.2174 13.4938 14.6005 13.4938 14.9999C13.4938 15.3994 13.6525 15.7825 13.935 16.0649L15.885 17.9999L13.935 19.9349C13.7944 20.0744 13.6828 20.2403 13.6066 20.423C13.5305 20.6058 13.4913 20.8019 13.4913 20.9999C13.4913 21.1979 13.5305 21.394 13.6066 21.5768C13.6828 21.7596 13.7944 21.9255 13.935 22.0649C14.0744 22.2055 14.2403 22.3171 14.4231 22.3932C14.6059 22.4694 14.802 22.5086 15 22.5086C15.198 22.5086 15.394 22.4694 15.5768 22.3932C15.7596 22.3171 15.9255 22.2055 16.065 22.0649L18 20.1149L19.935 22.0649C20.0744 22.2055 20.2403 22.3171 20.4231 22.3932C20.6059 22.4694 20.802 22.5086 21 22.5086C21.198 22.5086 21.394 22.4694 21.5768 22.3932C21.7596 22.3171 21.9255 22.2055 22.065 22.0649C22.2056 21.9255 22.3172 21.7596 22.3933 21.5768C22.4695 21.394 22.5087 21.1979 22.5087 20.9999C22.5087 20.8019 22.4695 20.6058 22.3933 20.423C22.3172 20.2403 22.2056 20.0744 22.065 19.9349L20.115 17.9999L22.065 16.0649C22.2056 15.9255 22.3172 15.7596 22.3933 15.5768C22.4695 15.394 22.5087 15.1979 22.5087 14.9999C22.5087 14.8019 22.4695 14.6058 22.3933 14.423C22.3172 14.2403 22.2056 14.0744 22.065 13.9349Z"
                    fill="#BDBDBD"
                  />
                </svg>
              </div>
            </div>
            <div className="takeToPreviewInfo">
              <div className="takeToPreviewText">
                Please review document before downloading
              </div>
              <div>
                <AmmoniteButton
                  buttonText={"Preview"}
                  fontWeight="bold"
                  color="primary"
                  width={215}
                  height={46}
                  buttonLink="/suitabilityreportpdf"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showEditPopUp && (
        <div className="blockInputPane">
          <div className="docPopUpBox">
            <div
              className="closeButton"
              onClick={() => setShowEditPopUp(false)}
            >
              <div className="closeText">Close</div>
              <div>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 3C15.0333 3 12.1332 3.87973 9.66645 5.52796C7.19972 7.17618 5.27713 9.51886 4.14181 12.2597C3.0065 15.0006 2.70945 18.0166 3.28823 20.9264C3.86701 23.8361 5.29562 26.5088 7.3934 28.6066C9.49119 30.7044 12.1639 32.133 15.0737 32.7118C17.9834 33.2906 20.9994 32.9935 23.7403 31.8582C26.4811 30.7229 28.8238 28.8003 30.472 26.3336C32.1203 23.8668 33 20.9667 33 18C33 16.0302 32.612 14.0796 31.8582 12.2597C31.1044 10.4399 29.9995 8.78628 28.6066 7.3934C27.2137 6.00052 25.5601 4.89563 23.7403 4.14181C21.9204 3.38799 19.9698 3 18 3ZM18 30C15.6266 30 13.3066 29.2962 11.3332 27.9776C9.35977 26.6591 7.8217 24.7849 6.91345 22.5922C6.0052 20.3995 5.76756 17.9867 6.23058 15.6589C6.69361 13.3311 7.83649 11.1929 9.51472 9.51472C11.193 7.83649 13.3312 6.6936 15.6589 6.23058C17.9867 5.76755 20.3995 6.00519 22.5922 6.91345C24.7849 7.8217 26.6591 9.35977 27.9776 11.3332C29.2962 13.3065 30 15.6266 30 18C30 21.1826 28.7357 24.2348 26.4853 26.4853C24.2349 28.7357 21.1826 30 18 30Z"
                    fill="#BDBDBD"
                  />
                  <path
                    d="M22.065 13.9349C21.9255 13.7943 21.7596 13.6827 21.5768 13.6066C21.394 13.5304 21.198 13.4912 21 13.4912C20.802 13.4912 20.6059 13.5304 20.4231 13.6066C20.2403 13.6827 20.0744 13.7943 19.935 13.9349L18 15.8849L16.065 13.9349C15.7825 13.6525 15.3994 13.4938 15 13.4938C14.6005 13.4938 14.2174 13.6525 13.935 13.9349C13.6525 14.2174 13.4938 14.6005 13.4938 14.9999C13.4938 15.3994 13.6525 15.7825 13.935 16.0649L15.885 17.9999L13.935 19.9349C13.7944 20.0744 13.6828 20.2403 13.6066 20.423C13.5305 20.6058 13.4913 20.8019 13.4913 20.9999C13.4913 21.1979 13.5305 21.394 13.6066 21.5768C13.6828 21.7596 13.7944 21.9255 13.935 22.0649C14.0744 22.2055 14.2403 22.3171 14.4231 22.3932C14.6059 22.4694 14.802 22.5086 15 22.5086C15.198 22.5086 15.394 22.4694 15.5768 22.3932C15.7596 22.3171 15.9255 22.2055 16.065 22.0649L18 20.1149L19.935 22.0649C20.0744 22.2055 20.2403 22.3171 20.4231 22.3932C20.6059 22.4694 20.802 22.5086 21 22.5086C21.198 22.5086 21.394 22.4694 21.5768 22.3932C21.7596 22.3171 21.9255 22.2055 22.065 22.0649C22.2056 21.9255 22.3172 21.7596 22.3933 21.5768C22.4695 21.394 22.5087 21.1979 22.5087 20.9999C22.5087 20.8019 22.4695 20.6058 22.3933 20.423C22.3172 20.2403 22.2056 20.0744 22.065 19.9349L20.115 17.9999L22.065 16.0649C22.2056 15.9255 22.3172 15.7596 22.3933 15.5768C22.4695 15.394 22.5087 15.1979 22.5087 14.9999C22.5087 14.8019 22.4695 14.6058 22.3933 14.423C22.3172 14.2403 22.2056 14.0744 22.065 13.9349Z"
                    fill="#BDBDBD"
                  />
                </svg>
              </div>
            </div>
            <div className="takeToPreviewInfo">
              <div className="takeToPreviewText">
                This is a new suitability context, please edit the data
              </div>
              <div>
                <AmmoniteButton
                  buttonText={"Edit"}
                  fontWeight="bold"
                  color="primary"
                  width={215}
                  height={46}
                  buttonLink="/suitabilitydashboard"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DocumentLibrary;
