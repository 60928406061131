// Core React dependencies
import React, { useState, useEffect, useCallback, useRef } from "react";

// External libraries
import firebase from "firebase";
import { isAndroid, isIOS } from "react-device-detect";

// Component imports
import Report from "../../containers/reportContainers/Report/report";

// Styling
import "./myReport.css";

const updateSessionsCustomerName = firebase
  .functions()
  .httpsCallable("updateSessionsCustomerName");

function useSession(customerUID) {
  const [session, setSession] = useState([]);

  const logVisits = async () => {
    let _newReportVisits = await session.reportVisits;
    if (isNaN(_newReportVisits) || _newReportVisits === 0) {
      _newReportVisits = 1;
    } else {
      _newReportVisits += 1;
    }

    await firebase
      .firestore()
      .collection("sessions")
      .doc(customerUID)
      .update({
        reportVisits: _newReportVisits,
      })
      .then(() => {
        console.log(`UPDATED FIREBASE VALUE ${_newReportVisits}`);
      })
      .catch((err) => {
        console.log("Error updating firebase", err);
      });

    await updateSessionsCustomerName({
      dataUid: customerUID,
      reportVisits: _newReportVisits,
    }).catch((err) => {
      console.log("Error updating index", err);
    });
  };

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("sessions")
      .doc(customerUID)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          setSession(doc.data());
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (session.sessionDate) {
      logVisits();
    }
  }, [session]);

  if (customerUID) {
    return session;
  }
}

function MyReport({ customerUID }) {
  const myReportContainer = useRef();
  const [reportLoaded, setReportLoaded] = useState(false);
  let UID = null;
  if (customerUID) {
    UID = customerUID;
  } else {
    UID = firebase.auth().currentUser.uid;
  }

  const session = useSession(UID);

  const reportIsLoaded = useCallback(
    (sigLoaded) => {
      setReportLoaded(sigLoaded);
      if (isAndroid) {
        myReportContainer.current.scrollTo(0, 200);
      }
    },
    [reportLoaded]
  );

  useEffect(() => {
    //to start at the top of the report for ios
    if (isIOS) {
      window.scrollTo(0, 0);
    }
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User logged in already or has just logged in.
      }
    });

    return () => {};
  }, [session]);

  return (
    <div ref={myReportContainer} className="customerReportContainer">
      {session.hasOwnProperty("annualRetirementSpending") && (
        <Report
          advisor={session.reportAdviser ? session.reportAdviser : "Caroline"}
          sessionData={session}
          reportIsLoaded={(sigLoaded) => reportIsLoaded(sigLoaded)}
        />
      )}
    </div>
  );
}

export default MyReport;
