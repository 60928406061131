import React from 'react';

function AmmoniteWarningIcon() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" fill="#B40125" stroke="#B40125" strokeWidth="4"/>
            <path d="M10.7723 16.128H13.2443L13.4603 3.72H10.5323L10.7723 16.128ZM10.2923 19.68C10.2923 20.664 10.9643 21.312 11.9963 21.312C13.0043 21.312 13.7003 20.664 13.7003 19.68C13.7003 18.672 13.0043 18.024 11.9963 18.024C10.9643 18.024 10.2923 18.672 10.2923 19.68Z" fill="white"/>
        </svg>
	);
}

export default AmmoniteWarningIcon;
