// Core React dependencies
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';

// Component imports
import SuitabilityReportNavigation from './../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation';

import NewPensionAdviceChip from '../../../Assets/SVG/Chips/SuitabilityChips/newPensionAdviceChip';
import NewISAAdviceChip from '../../../Assets/SVG/Chips/SuitabilityChips/newISAAdviceChip';
import NewGIAAdviceChip from '../../../Assets/SVG/Chips/SuitabilityChips/newGIAAdviceChip';
import NewCashAdviceChip from '../../../Assets/SVG/Chips/SuitabilityChips/newCashAdviceChip';
import CreateSuitabilityReportChip from '../../../Assets/SVG/Chips/SuitabilityChips/createSuitabilityReportChip';
import RetirementFocusButton from '../../../Assets/SVG/GoalFocusButtons/retirementFocusButton';
import EducationFocusButton from '../../../Assets/SVG/GoalFocusButtons/educationFocusButton';
import CareFeesFocusButton from '../../../Assets/SVG/GoalFocusButtons/careFeesFocusButton';
import FamilyFocusButton from '../../../Assets/SVG/GoalFocusButtons/familyFocusButton';
import PropertyFocusButton from '../../../Assets/SVG/GoalFocusButtons/propertyFocusButton';
import InvestmentFocusButton from '../../../Assets/SVG/GoalFocusButtons/investmentFocusButton';
import OtherFocusButton from '../../../Assets/SVG/GoalFocusButtons/otherFocusButton';
import AmmoniteMenuCircleEmpty from '../../../Assets/SVG/menuCircleEmpty';
import AmmoniteMenuCircleFilledTicked from '../../../Assets/SVG/menuCircleFilledTicked';
import AmmoniteDropdown from '../../../components/Dropdown/Dropdown';
import Loader from 'react-loader-spinner';

import NewPolicyCard from '../../../containers/NewPolicyCard/newPolicyCard';
import { saveContextToFirebase } from '../../../Utils/data/saveSuitabilityContext'

import SuitabilityReportContext from '../../../Utils/contexts/suitabilityReportContext';
import { 
    setAdviser,
    changeGoalFocus,
    changeSRBuilder,
    createPdfDocxData,
    deleteNewPensionAdvice,
    deleteNewISAAdvice,
    deleteNewGIAAdvice,
    deleteNewCashAdvice
} from '../../../Utils/reducers/suitabilityReportActions';

// Styling
import './suitabilityReportBuilder.css';
import styles from '../../../Styles/srbstyles.module.css'

function SuitabilityReportBuilder() {
    const { push } = useHistory();
    const { state, status, error, dispatch } = useContext(SuitabilityReportContext);

    const adviserOptions = [
		{ value: 1, text: 'Caroline' },
		{ value: 2, text: 'Rob' },
		{ value: 3, text: 'Michael' }
	];

    const handleDropdownChange = (event) => {
        event.preventDefault();
        dispatch({ type: setAdviser, payload:  event.target.value });
	};

   const handleAmendSBRBuilder = (event, field, value) => {
        event.preventDefault();
        dispatch({ type: changeSRBuilder, payload: {
            field: field,
            value: value
        }});
        saveHandler()
   }
   
   const focusHandler = (index) => {
        dispatch({ type: changeGoalFocus, payload: {
            index: index
        }})
        saveHandler()
    }

    const getPolicies = () => {
        let _policies = [...state.newPensionsAdvice, ...state.newISAsAdvice, ...state.newGIAsAdvice]
        return _policies
    }

    const getButton = (type, index) => {
        switch (type) {
            case 'Retirement pot':
                if (state.customerGoals[index].focused === true) {
                    return <RetirementFocusButton colour="#2B9BA5"/>;
                } else {
                    return <RetirementFocusButton colour="#FFFFFF"/>
                }
            case 'Long Term Investment':
                if (state.customerGoals[index].focused === true) {
                    return <InvestmentFocusButton colour="#2B9BA5"/>;
                } else {
                    return <InvestmentFocusButton colour="#FFFFFF"/>
                }
            case 'Property':
                if (state.customerGoals[index].focused === true) {
                    return <PropertyFocusButton colour="#2B9BA5"/>;
                } else {
                    return <PropertyFocusButton colour="#FFFFFF"/>
                }
            case 'Other':
                if (state.customerGoals[index].focused === true) {
                    return <OtherFocusButton colour="#2B9BA5"/>;
                } else {
                    return <OtherFocusButton colour="#FFFFFF"/>
                }
            case 'Education':
                if (state.customerGoals[index].focused === true) {
                    return <EducationFocusButton colour="#2B9BA5"/>;
                } else {
                    return <EducationFocusButton colour="#FFFFFF"/>
                }
            case 'Care Fees':
                if (state.customerGoals[index].focused === true) {
                    return <CareFeesFocusButton colour="#2B9BA5"/>;
                } else {
                    return <CareFeesFocusButton colour="#FFFFFF"/>
                }
            case 'Family':
                if (state.customerGoals[index].focused === true) {
                    return <FamilyFocusButton colour="#2B9BA5"/>;
                } else {
                    return <FamilyFocusButton colour="#FFFFFF"/>
                }
        }
        
    }

    const filled = (
        <div className="circleSVG">
            < AmmoniteMenuCircleFilledTicked />
        </div>
    );

    const empty = (
        <div className="circleSVG">
            <AmmoniteMenuCircleEmpty colour="#3BB9C4" width="36px" height="36px" cx="18px" cy="18px" r="14px" />			
        </div>
    );

    const mapAttitudeToRisk = (num) => {
        switch (num) {
            case 0:
                return "Low"
            case 1:
                return "Low-Medium"
            case 2:
                return "Medium"
            case 3:
                return "Medium-High"
            case 4:
                return "High"
            default:
                return "Low"
        }
    }

    const mapCapacityForLoss = (num) => {
        switch (num) {
            case 0:
                return "Low"
            case 1:
                return "Medium"
            case 2:
                return "High"
            default:
                return "Low"
        }
    }

    const handleAdditionalClick = (chipLink) => {
        dispatch({type: createPdfDocxData})
        saveHandler().then(push(chipLink));
    }

    const exitHandler = () => {
        push('/suitabilitydashboard');
    }

    const saveHandler = async () => {
        await saveContextToFirebase(state).then(() => console.log("SAVED"))
    }

    useEffect(() => {
 
    }, [])


	return <div className={styles.suitabilityPanel}>
       <div className={styles.pageTitleText}>Suitability Report Builder</div>
       {!status !== "success" ? <div><div className="reportForContainer">
           {/* amended part */}
                    <label className={`${styles.pageMainText} ${styles.labelOwner}`}>Who is the report for?</label>
                    <div className={styles.pensionFieldContainer}>
                    <div className={styles.ownerPension}>
                        <div className={styles.ownerPensionButton} onClick={(event) => handleAmendSBRBuilder(event, "owner", "customer1")}>
                            {!status !== "success" && state.customerInfo.customers.customer1.isSuitabilityReportOwner? filled : empty}
                        </div>
                        <label className={styles.customerLabel}>{!status !== "success" && state.customerInfo.customers.customer1.customerFirstName}</label>
                    </div>
                    {!status !== "success" && state.customerInfo.couple && <div className={styles.ownerPension}>
                        <div className={styles.ownerPensionButton} onClick={(event) => handleAmendSBRBuilder(event, "owner", "customer2")}>
                            {!status !== "success" && state.customerInfo.customers.customer2.isSuitabilityReportOwner ? filled : empty}
                        </div>
                        <label className={styles.customerLabel}>{state.customerInfo.customers.customer2.customerFirstName}</label>
                    </div>}
                </div>
                </div>
        <div className={[styles.pageTitleText, "keyInformationTitle"].join(" ")}>Key Information</div>
        <div className="keyInformationPanel">
            <div className="suitabilityRBField">
                <div className="suitabilityRBFieldText">
                    Is environmental investing a priority?
                </div>
                <div className="suitabilityRBFieldText">
                    {!status !== "success" && state.environmentPriority ? "Y" : "N"}
                </div>
            </div>
            <div className="suitabilityRBField">
                <div className="suitabilityRBFieldText">
                    Attitude to Risk:
                </div>
                <div className="suitabilityRBFieldText">
                    {!status !== "success" && mapAttitudeToRisk(state.clientAttitudeToRisk)}
                </div>
            </div>
            <div className="suitabilityRBField">
                <div className="suitabilityRBFieldText">
                    Capacity for Loss:
                </div>
                <div className="suitabilityRBFieldText">
                    {!status !== "success" && mapCapacityForLoss(state.clientCapacityForLoss)}
                </div>
            </div>
            <div className="suitabilityRBField">
                <div className="suitabilityRBFieldText">
                    Agreed total monthly budget (customer 1):
                </div>
                <div className="suitabilityRBFieldText">
                    {!status !== "success" && state.customerInfo.customers.customer1.customerAgreedMonthlyBudget}
                </div>
            </div>
            <div className="suitabilityRBField">
                <div className="suitabilityRBFieldText">
                    Agreed total lump sum (customer 1):
                </div>
                <div className="suitabilityRBFieldText">
                    {!status !== "success" && state.customerInfo.customers.customer1.customerAgreedLumpSum}
                </div>
            </div>
            <div className="suitabilityRBField">
                <div className="suitabilityRBFieldText">
                    What goals will be the focus of this report?
                </div>
            </div>
            <div className="suitabilityRBGoalFocus">
                <div className="suitabilityRBGoalFocusTopRow">
                    {!status !== "success" && state.customerGoals.slice(0, 3).map((srGoal, index) => <div className="focusButton" onClick={() => focusHandler(index)}>
                        {getButton(srGoal.goalType, index)}
                    </div>)}
                </div>
                <div className="suitabilityRBGoalFocusTopRow">
                    {!status !== "success" && state.customerGoals.slice(3, 6).map((srGoal, index) => <div className="focusButton" onClick={() => focusHandler(index + 3)}>
                        {getButton(srGoal.goalType, index + 3)}
                    </div>)}
                </div>
            </div>

        </div>
        <div className={[styles.pageTitleText, "newPoliciesTitle"].join(" ")}>New Policies</div>
        <div className="newPoliciesContainer">
            <div className="newPoliciesCol">
                {!status !== "success" && getPolicies().slice(0, 3).map((policy, index) => 
                    <div className="newPolicy">
                        <NewPolicyCard
                            type={policy.type}
                            owner={policy.owner === "customer2" ? state.customerInfo.customers.customer2.customerFirstName : state.customerInfo.customers.customer1.customerFirstName}
                            totalTransfersIn={policy.totalTransfersIn}
                            grossMonthlyContributions = {policy.type === "pension" ? parseInt(policy.grossMonthlyPersonalContribution) + parseInt(policy.grossMonthlyEmployerContribution) : policy.monthlyContribution}
                            grossLumpSumContributions = {policy.type === "pension" ? parseInt(policy.grossLumpSumPersonalContribution) + parseInt(policy.grossLumpSumEmployerContribution) : policy.lumpSumContribution}
                            policyId = {policy.policyID}
                        />
                    </div>
                )}
            </div>
            <div className="newPoliciesCol">
                {!status !== "success" && getPolicies().slice(3, 6).map((policy, index) => 
                    <div className="newPolicy">
                        <NewPolicyCard
                        type={policy.type}
                        owner={policy.owner === "customer2" ? state.customerInfo.customers.customer2.customerFirstName : state.customerInfo.customers.customer1.customerFirstName}
                        totalTransfersIn={policy.totalTransfersIn}
                        grossMonthlyContributions = {policy.type === "pension" ? parseInt(policy.grossMonthlyPersonalContribution) + parseInt(policy.grossMonthlyEmployerContribution) : policy.monthlyContribution}
                        grossLumpSumContributions = {policy.type === "pension" ? parseInt(policy.grossLumpSumPersonalContribution) + parseInt(policy.grossLumpSumEmployerContribution) : policy.lumpSumContribution}
                        policyId = {policy.policyID}
                        />
                    </div>
                )}
            </div>
        </div></div> : <div className="srbLoader"><Loader/></div>}
        {!status !== "success" && <div className="chipPanel">
                <div className={[styles.menuChips, "sRmenuChips"].join(" ")}>
                    <div className={styles.menuChip} onClick={() => handleAdditionalClick('/newpensionadvice')}>
                        <NewPensionAdviceChip/>
                    </div>
                    <div className={styles.menuChip} onClick={() => handleAdditionalClick('newisaadvice')}>
                        <NewISAAdviceChip/>
                    </div>
                    <div className={styles.menuChip} onClick={() => handleAdditionalClick('newgiaadvice')}>
                        <NewGIAAdviceChip/>
                    </div>
                    <div className={styles.menuChip} onClick={() => handleAdditionalClick('newcashadvice')}>
                        <NewCashAdviceChip/>
                    </div>
                    
                </div>
            </div>}

            <div className={styles.menuBar}>
                {!status !== "success" && state.adviser && <div className="adviserSelect">
                    <div>Select adviser: </div>
                    <AmmoniteDropdown
                        isDown={true}
                        options={adviserOptions}
                        selected={state.adviser}
                        handleDropdownChange={handleDropdownChange}
                    />
                </div>}
                {!status !== "success" && <div className={styles.menuChip} onClick={() => handleAdditionalClick('/suitabilityreportpdf')}>
                    <CreateSuitabilityReportChip/>
                </div>}
                {!status !== "success" && <SuitabilityReportNavigation
                    handleExit={exitHandler}
                />}
            </div>
    </div>;
}

export default SuitabilityReportBuilder;

