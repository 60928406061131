import React from 'react';

function AmmoniteFemaleAvatar() {
	return (
		<svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M84 42C84 56.9523 76.1865 70.0804 64.4191 77.5223C63.3206 78.2182 62.1882 78.8623 61.0218 79.4546C60.7583 79.59 60.4937 79.7205 60.2265 79.8485C60.0066 79.9553 59.7841 80.0597 59.5617 80.1628C57.2513 81.2285 54.848 82.0799 52.382 82.7065C52.2217 82.7475 52.0601 82.7872 51.8986 82.8257C51.778 82.8556 51.6563 82.8841 51.5345 82.9115C51.5195 82.9164 51.5041 82.9201 51.4885 82.9227C51.2388 82.9811 50.989 83.037 50.7368 83.0879C50.5951 83.1178 50.4534 83.1476 50.3118 83.1762C50.226 83.1948 50.1391 83.211 50.0533 83.2271C49.9452 83.2482 49.8384 83.2681 49.7302 83.2892C49.6706 83.3017 49.611 83.3116 49.5501 83.3215C49.4134 83.3464 49.2767 83.3712 49.1412 83.3949C48.9101 83.4346 48.6765 83.4731 48.4441 83.5079C48.2752 83.534 48.1074 83.5589 47.9384 83.5825C47.7396 83.6111 47.5408 83.6372 47.3407 83.662C47.286 83.6707 47.2314 83.6769 47.1767 83.6831C47.1059 83.6918 47.035 83.7005 46.9642 83.7092C46.748 83.7353 46.5293 83.7589 46.3118 83.7813C46.1702 83.7962 46.0285 83.8099 45.8869 83.8223C45.7042 83.8397 45.5215 83.8546 45.3376 83.8695C45.114 83.8869 44.8915 83.9031 44.6666 83.9167C44.5225 83.9254 44.3783 83.9342 44.233 83.9416C43.8627 83.9615 43.4924 83.9752 43.1196 83.9851C42.9344 83.9901 42.748 83.9938 42.5617 83.9963H42.5579C42.3728 83.9988 42.1864 84 42 84C36.4837 84.0028 31.0209 82.9184 25.924 80.8087C20.827 78.699 16.1959 75.6054 12.2952 71.7048C8.39461 67.8042 5.30102 63.173 3.19133 58.076C1.08163 52.9791 -0.00281245 47.5163 5.4777e-06 42C5.4777e-06 18.8043 18.8043 0 42 0C65.1957 0 84 18.8043 84 42Z"
				fill="#3BB9C4"
			/>
			<path
				opacity="0.2"
				d="M68.1492 58.7194C64.9172 61.0878 63.1067 66.3141 62.0952 71.4051C61.58 74.0649 61.2216 76.7527 61.0216 79.4546C60.7582 79.5901 60.4935 79.7206 60.2263 79.8486C60.4257 76.772 60.8347 73.7127 61.4503 70.6918C62.514 65.6493 64.3928 60.5447 67.7068 58.1167L68.1492 58.7194Z"
				fill="#0C3957"
			/>
			<path
				opacity="0.2"
				d="M72.7842 25.6063C74.8491 25.6063 76.523 23.9324 76.523 21.8675C76.523 19.8026 74.8491 18.1287 72.7842 18.1287C70.7193 18.1287 69.0454 19.8026 69.0454 21.8675C69.0454 23.9324 70.7193 25.6063 72.7842 25.6063Z"
				fill="#0C3957"
			/>
			<path
				opacity="0.2"
				d="M71.5801 57.7909C73.645 57.7909 75.3189 56.1169 75.3189 54.052C75.3189 51.9872 73.645 50.3132 71.5801 50.3132C69.5152 50.3132 67.8413 51.9872 67.8413 54.052C67.8413 56.1169 69.5152 57.7909 71.5801 57.7909Z"
				fill="#0C3957"
			/>
			<path
				opacity="0.2"
				d="M70.3664 38.7656C66.1 40.3257 61.8127 39.3245 61.8127 39.3245C61.8127 39.3245 64.4429 35.794 68.7093 34.2339C72.9758 32.6738 77.263 33.6749 77.263 33.6749C77.263 33.6749 74.6329 37.2055 70.3664 38.7656Z"
				fill="#0C3957"
			/>
			<path
				opacity="0.2"
				d="M71.09 67.2661C68.0996 68.3596 65.0903 67.6462 65.0903 67.6462C65.0903 67.6462 66.9296 65.16 69.92 64.0665C72.9105 62.973 75.9197 63.6864 75.9197 63.6864C75.9197 63.6864 74.0805 66.1726 71.09 67.2661Z"
				fill="#0C3957"
			/>
			<path
				d="M22.5969 22.389C28.6702 12.1271 40.6857 11.6488 40.6857 11.6488C40.6857 11.6488 52.394 10.1516 59.9049 25.7806C66.9056 40.3481 76.5675 54.4134 61.4604 57.8235L58.7316 49.3304L57.0417 58.4558C54.8907 58.6105 52.7329 58.6474 50.5779 58.5663C34.3994 58.044 18.9918 58.7191 19.4879 52.9136C20.1474 45.196 16.7532 32.263 22.5969 22.389Z"
				fill="#0C3957"
			/>
			<path
				d="M41.8688 50.4226C50.3527 50.4226 57.2303 43.5451 57.2303 35.0612C57.2303 26.5773 50.3527 19.6997 41.8688 19.6997C33.3849 19.6997 26.5073 26.5773 26.5073 35.0612C26.5073 43.5451 33.3849 50.4226 41.8688 50.4226Z"
				fill="#F5F8FA"
			/>
			<path
				d="M49.2652 43.5954C49.2652 43.5954 43.0068 61.8016 50.403 66.922C57.7993 72.0425 38.4553 75.4562 33.3348 72.0425C28.2143 68.6289 24.2317 62.9395 24.2317 62.9395C24.2317 62.9395 37.3174 49.8538 34.4727 42.4575L49.2652 43.5954Z"
				fill="#F5F8FA"
			/>
			<path
				d="M60.644 70.9041L59.5617 80.1627C57.2513 81.2284 54.8479 82.0798 52.382 82.7064C52.2217 82.7474 52.0601 82.7871 51.8986 82.8256C51.778 82.8555 51.6563 82.884 51.5345 82.9114C51.5195 82.9163 51.5041 82.92 51.4885 82.9226C51.2388 82.981 50.989 83.0369 50.7367 83.0878C50.5951 83.1177 50.4534 83.1475 50.3118 83.1761C50.226 83.1947 50.139 83.2109 50.0533 83.227C49.9452 83.2481 49.8383 83.268 49.7302 83.2891C49.6706 83.3016 49.6109 83.3115 49.55 83.3214C49.4133 83.3463 49.2767 83.3711 49.1412 83.3948C48.9101 83.4345 48.6765 83.473 48.4441 83.5078C48.2751 83.5339 48.1074 83.5588 47.9384 83.5824C47.7396 83.611 47.5408 83.6371 47.3407 83.6619C47.286 83.6706 47.2313 83.6768 47.1767 83.683C47.1059 83.6917 47.035 83.7004 46.9642 83.7091C46.748 83.7352 46.5293 83.7588 46.3118 83.7812C46.1702 83.7961 46.0285 83.8098 45.8868 83.8222C45.7042 83.8396 45.5215 83.8545 45.3376 83.8694C45.114 83.8868 44.8915 83.903 44.6666 83.9167C44.5225 83.9254 44.3783 83.9341 44.2329 83.9415C43.8626 83.9614 43.4923 83.9751 43.1196 83.985C42.9344 83.99 42.748 83.9937 42.5616 83.9962H42.5579C42.3728 83.9987 42.1864 83.9999 42 83.9999C34.5199 83.9976 27.1758 81.9994 20.7257 78.2115C14.2756 74.4237 8.95299 68.9834 5.30713 62.4519C7.18008 61.1684 9.13009 60.001 11.1461 58.9565C11.7886 58.6322 12.6596 58.4334 13.655 58.3315C15.9028 58.0979 18.8155 58.3588 21.3417 58.8372C23.6455 59.2696 25.6312 59.8847 26.5072 60.465C28.7837 61.9723 37.887 66.9216 37.887 66.9216C37.887 66.9216 45.8484 68.0598 49.0642 65.6889C49.0655 65.6889 49.0655 65.6877 49.0667 65.6877C49.8247 65.126 50.8734 65.1223 52.0303 65.4441C52.2751 65.5125 52.5245 65.5941 52.7784 65.6889C53.4844 65.9623 54.169 66.2884 54.8262 66.6644C54.8411 66.6731 54.8548 66.6805 54.8697 66.6892C55.102 66.8197 55.3332 66.9564 55.5618 67.0968C58.2694 68.747 60.644 70.9041 60.644 70.9041Z"
				fill="#0C3957"
			/>
			<path
				d="M26.9891 21.3999L38.8598 15.1819L46.3075 16.3376C48.9458 16.7469 51.4 17.94 53.3516 19.7619C55.3033 21.5838 56.6621 23.9504 57.2516 26.5543L58.6443 32.7053L50.2015 32.3806L47.8429 26.8772V32.2898L43.9472 32.14L41.6862 23.3783L40.273 32.7053L26.4238 32.4226L26.9891 21.3999Z"
				fill="#0C3957"
			/>
		</svg>
	);
}

export default AmmoniteFemaleAvatar;
