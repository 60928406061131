import React from "react";

import carolineSignature from "../../Assets/Signatures/signatureSVGs/Caroline-Signature.svg";
import robSignature from "../../Assets/Signatures/signatureSVGs/Rob-Signature.svg";
import michaelSignature from "../../Assets/Signatures/signatureSVGs/Michael-Signature.svg";
import { riskMatrix } from "./riskMatrix";
import { providerInvestmentSolutions } from "../../Utils/data/providerInvestmentSolutions";
import { investmentProviderData } from "../../Utils/data/investmentProviderData";
import { providerParagraphs } from "../../Utils/data/providerParagraphs";
import AmmoniteRiskTable from "../../components/suitabilityReportComponents/riskTable/riskTable";
import {
  getCurrentValueNewPension,
  getCurrentValueISAGIACash,
} from "./suitabilityReportCharges";

import moment from "moment";

const dateNow = Date.now();

const fundProviders = new Set();
const investmentProviders = new Set();

export const getSignature = (adviser) => {
  if (adviser === 2) {
    return (
      <div className="sbrAdvisorSignature">
        <img className="sbreportSignature" src={robSignature} alt="signature" />
        <div>ROB HARRADINE</div>
        <div>FINANCIAL ADVISER MSCI</div>
        <div>
          {moment(dateNow).format("D")} {moment(dateNow).format("MMMM")}{" "}
          {moment(dateNow).format("YYYY")}
        </div>
      </div>
    );
  } else if (adviser === 3) {
    return (
      <div className="sbrAdvisorSignature">
        <img
          className="sbreportSignature"
          src={michaelSignature}
          alt="signature"
        />
        <div>MICHAEL HUGHES</div>
        <div>FINANCIAL ADVISER DipPFS</div>
        <div>
          {moment(dateNow).format("D")} {moment(dateNow).format("MMMM")}{" "}
          {moment(dateNow).format("YYYY")}
        </div>
      </div>
    );
  }
  return (
    <div className="sbrAdvisorSignature">
      <img
        className="sbreportSignature"
        src={carolineSignature}
        alt="signature"
      />
      <div>CAROLINE DUFF</div>
      <div>CHARTERED FINANCIAL PLANNER</div>
      <div>
        {moment(dateNow).format("D")} {moment(dateNow).format("MMMM")}{" "}
        {moment(dateNow).format("YYYY")}
      </div>
    </div>
  );
};

export const getGoalRiskProfile = (timeHorizon, clientAttitudeToRisk) => {
  let _timeHorizon = parseInt(timeHorizon);
  if (timeHorizon > 50) {
    _timeHorizon = 50;
  }
  return riskMatrix["YEARS TO GOAL"][_timeHorizon][clientAttitudeToRisk];
};

const addMissingCustomerFinanceFields = (customerInfo) => {
  const customerFinanceFields = [
    "customerStartingCashBalance",
    "customerMonthlyExpenditure",
    "customerGrossMonthlyIncome",
    "customerNetMonthlyIncome",
    "customerAgreedMonthlyBudget",
    "customerAgreedLumpSum",
  ];

  for (let field of customerFinanceFields) {
    if (field in customerInfo.customers["customer1"] === false) {
      customerInfo.customers["customer1"] = {
        ...customerInfo.customers["customer1"],
        [field]: 0,
      };
    } else {
      customerInfo.customers["customer1"] = {
        ...customerInfo.customers["customer1"],
      };
    }

    if (field in customerInfo.customers["customer2"] === false) {
      customerInfo.customers["customer2"] = {
        ...customerInfo.customers["customer2"],
        [field]: 0,
      };
    } else {
      customerInfo.customers["customer2"] = {
        ...customerInfo.customers["customer2"],
      };
    }
  }

  return customerInfo;
};

export const budgetAndAffordabilityValues = (type, customerInfo) => {
  let _newCustomerInfo = { ...customerInfo };
  _newCustomerInfo = addMissingCustomerFinanceFields(_newCustomerInfo);

  switch (type) {
    case "customerExpenditureRange":
      const expenditure = budgetAndAffordibilityCalculator(
        "customerMonthlyExpenditure",
        _newCustomerInfo
      );
      return "£" + 3 * expenditure + " - £" + 6 * expenditure;
    case "customerAgreedLumpSum":
      return budgetAndAffordibilityCalculator(type, _newCustomerInfo);
    case "customerStartingCashBalance":
      return (
        budgetAndAffordibilityCalculator(type, _newCustomerInfo) -
        budgetAndAffordabilityValues("customerAgreedLumpSum", _newCustomerInfo)
      );
    case "customerNetMonthlyIncome":
      return budgetAndAffordibilityCalculator(type, _newCustomerInfo);
    case "customerMonthlyExpenditure":
      return budgetAndAffordibilityCalculator(type, _newCustomerInfo);
    case "customerAgreedMonthlyBudget":
      return budgetAndAffordibilityCalculator(type, _newCustomerInfo);
  }
};

export const budgetAndAffordibilityCalculator = (field, customerInfo) => {
  let value = parseInt(customerInfo.customers.customer1[field]);
  if (customerInfo.couple) {
    value += parseInt(customerInfo.customers.customer2[field]);
  }
  if (!value || isNaN(value)) {
    value = 0;
  }
  return value;
};

export const getRiskMatrix = (isDocX = false, clientAttitudeToRisk) => {
  let riskLevel = "";
  let aboutRiskType = "";
  let percentageRanges = [];
  let riskValues = [];
  switch (clientAttitudeToRisk) {
    case 0:
      riskLevel = "lowRisk";
      aboutRiskType =
        "People in this category are conservative with their investments. They prefer taking a small amount of risk to achieve modest or relatively stable returns. They accept that there may be some short-term periods of fluctuation in value.";
      percentageRanges.push("0%-25%", "5%-35%");
      riskValues.push("1/10", "1/10", "2/10");
      break;
    case 1:
      riskLevel = "lowMediumRisk";
      aboutRiskType =
        "People in this category are relatively cautious with their investments. They want to try to achieve a reasonable return and are prepared to accept some risk in doing so. Typically these portfolios will exhibit relatively modest yet frequent fluctuations in value.";
      percentageRanges.push("5%-35%", "15%-45%", "25%-55%");
      riskValues.push("2/10", "2/10", "3/10", "4/10");
      break;
    case 2:
      riskLevel = "mediumRisk";
      aboutRiskType =
        "People in this category are balanced in their attitude towards risk. They don’t seek risky investments but don’t avoid them either. They are prepared to accept fluctuations in the value of their investments to try and achieve better long-term returns. These portfolios will be subject to frequent and at times significant fluctuations in value. ";
      percentageRanges.push("35%-65%", "45%-75%", "55%-85%");
      riskValues.push("5/10", "5/10", "6/10", "7/10");
      break;
    case 3:
      riskLevel = "mediumHighRisk";
      aboutRiskType =
        "People in this category are relatively comfortable with investment risk. They aim for higher long-term returns and understand that this can also mean some sustained periods of poorer performance. They are prepared to accept significant fluctuation in value to try and achieve better long-term returns.";
      percentageRanges.push("55%-85%", "65%-95%", "75%-100%");
      riskValues.push("7/10", "7/10", "8/10", "9/10");
      break;
    case 4:
      riskLevel = "highRisk";
      aboutRiskType =
        "People in this category are very comfortable with investment risk. They aim for high long-term investment returns and do not overly worry about periods of poorer performance in the short to medium term. Ordinarily, these portfolios can be subject to the full extent and frequency of stock market fluctuations.";
      percentageRanges.push("65%-95%", "75%-100%", "85%-100%");
      riskValues.push("8/10", "8/10", "9/10", "10/10");
      break;
  }
  return riskText(
    riskLevel,
    aboutRiskType,
    percentageRanges,
    riskValues,
    isDocX
  );
};

export const riskText = (
  riskLevel,
  aboutRisk,
  equityPercentages,
  riskDegrees,
  isDocX = false
) => {
  const introText =
    "Depending on the timeframe of a goal, the appropriate level of investment risk will differ, as follows (with 1/10 being lowest risk, and 10/10 being highest risk).  Investments with a higher risk category will have more stocks and shares (equities) - equities have the opportunity for higher long-term returns, but potential for more ups and downs along the way.";
  const toTwoYears =
    "For timeframes of 1-2 years, we recommend keeping money in cash. ";
  const toFourYears = `For timeframes of 3-4 years, where money is coming from cash, we recommend keeping it in cash.  Where money is coming from investments, a portfolio would be invested in line with a risk ${riskDegrees[0]}.  Equity exposure for such a portfolio is therefore likely to range from 0-25%.`;
  let toNineYears = "";
  let toFourteenYears = "";
  let fifteenPlusYears = "";

  if (riskLevel === "lowRisk") {
    toFourteenYears = riskInfo("5-14", riskDegrees[1], equityPercentages[0]);
    fifteenPlusYears = riskInfo("15+", riskDegrees[2], equityPercentages[1]);
  } else {
    if (riskLevel === "mediumRisk") {
      toNineYears = riskInfo("3-9", riskDegrees[1], equityPercentages[0]);
    } else {
      toNineYears = riskInfo("5-9", riskDegrees[1], equityPercentages[0]);
    }
    toFourteenYears = riskInfo("10-14", riskDegrees[2], equityPercentages[1]);
    fifteenPlusYears = riskInfo("15+", riskDegrees[3], equityPercentages[2]);
  }

  if (isDocX) {
    const allRiskTexts = [
      aboutRisk,
      introText,
      toTwoYears,
      toFourYears,
      toNineYears,
      toFourteenYears,
      fifteenPlusYears,
    ];
    return allRiskTexts;
  } else {
    return (
      <div>
        <div className="recommendationsParagraph recommendationsParagraph1">
          {aboutRisk}
        </div>
        <div className="recommendationsParagraph recommendationsParagraph1">
          {introText}
        </div>
        <div className="riskTablePanel">
          <AmmoniteRiskTable riskLevel={riskLevel} />
        </div>
        <div className="recommendationsParagraph recommendationsParagraph1">
          {toTwoYears}
        </div>
        <div className="recommendationsParagraph recommendationsParagraph1">
          {toFourYears}
        </div>
        {riskLevel !== "lowRisk" && (
          <div className="recommendationsParagraph recommendationsParagraph1">
            {toNineYears}
          </div>
        )}
        <div className="recommendationsParagraph recommendationsParagraph1">
          {toFourteenYears}
        </div>
        <div className="recommendationsParagraph recommendationsParagraph1">
          {fifteenPlusYears}
        </div>
      </div>
    );
  }
};

export const riskInfo = (yearRange, riskDegree, equityPercentage) => {
  const opening = yearRange[2] === "9" ? "This means that for" : "For";
  return `${opening} a timeframe of ${yearRange} years, a portfolio would be invested in line with a risk ${riskDegree}.  Equity exposure for such a portfolio is therefore likely to range from ${equityPercentage}.`;
};

export const getCustomerAge = (dob) => {
  return moment().diff(dob.seconds * 1000, "years");
};

export const getEmploymentSituation = (workSituation) => {
  switch (workSituation) {
    case 0:
      return "Employed";
    case 1:
      return "Self-employed";
    case 2:
      return "Other";
  }
};

export const getNoChildren = (numberChildren) => {
  if (numberChildren === 1) {
    return "1 child";
  } else {
    return numberChildren + " children";
  }
};

export const getCustomerAttitudeToRisk = (clientAttitudeToRisk) => {
  switch (clientAttitudeToRisk) {
    case 0:
      return "Low";
    case 1:
      return "Low to Medium";
    case 2:
      return "Medium";
    case 3:
      return "Medium to High";
    case 4:
      return "High";
  }
};

export const getCapacityForLoss = (clientCapacityForLoss) => {
  switch (clientCapacityForLoss) {
    case 0:
      return "Low";
    case 1:
      return "Medium";
    case 2:
      return "High";
  }
};

export const getCapacityForLossStatement = (clientCapacityForLoss) => {
  switch (clientCapacityForLoss) {
    case 0:
      return "You may not afford for the investments to not meet your objectives without significant consequences on your standard of living. It is unlikely that you would have other alternatives to turn to if faced with an immediate requirement for capital. ";
    case 1:
      return "You may be able to afford some under-performance of the investment though substantial losses could affect your standards of living. It is likely that you will have other alternatives to turn to if faced with an immediate requirement for capital.";
    case 2:
      return "You can afford significant under-performance of the investment though substantial losses may still affect your standards of living. Yet it is likely you will have other alternatives to turn to if faced with an immediate requirement for capital.";
  }
};

export const getDeficitSurplus = (customerInfo) => {
  let monthTotal =
    budgetAndAffordabilityValues("customerNetMonthlyIncome", customerInfo) -
    budgetAndAffordabilityValues("customerMonthlyExpenditure", customerInfo);
  let surplus = true;

  if (monthTotal < 1) {
    surplus = false;
  }

  let surplusText = surplus ? "surplus" : "deficit";
  return surplusText + " of £" + Math.abs(monthTotal);
};

export const getDoesGoalFocusExist = (customerGoals) => {
  return customerGoals.some((obj) => obj.focused === true);
};

export const getIsNotEveryGoalFocused = (goals) => {
  return goals.some(
    (obj) => obj.focused === false || obj.focused === undefined
  );
};

export const getGoalRankedByImportance = (customerGoals) => {
  let _goalsExceptRetirement = customerGoals.slice(1);
  return _goalsExceptRetirement
    .sort((a, b) => a.goalImportance - b.goalImportance)
    .reverse();
};

export const getGoalImportance = (importance) => {
  switch (importance) {
    case 0:
      return "Nice to have";
    case 1:
      return "Important";
    case 2:
      return "Primary";
  }
};

export const getContributionTypeExists = (
  type,
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  newCashAdvice
) => {
  switch (type) {
    case "getIsInvestmentLumpSum":
      return getContributionExists(
        "lumpSumContribution",
        "grossLumpSumPersonalContribution",
        "",
        true,
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        newCashAdvice
      );
    case "getIsMonthly":
      return getContributionExists(
        "grossMonthlyEmployerContribution",
        "grossMonthlyPersonalContribution",
        "monthlyContribution",
        true,
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        newCashAdvice
      );
    case "getInvestmentsWithLumpSum":
      return getContributionExists(
        "lumpSumContribution",
        "grossLumpSumPersonalContribution",
        "grossLumpSumEmployerContribution",
        false,
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        newCashAdvice
      );
    case "getInvestmentsWithMonthly":
      return getContributionExists(
        "monthlyContribution",
        "grossMonthlyPersonalContribution",
        "grossMonthlyEmployerContribution",
        false,
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        newCashAdvice
      );
  }
};

export const getContributionExists = (
  contribution1,
  contribution2,
  contribution3 = "",
  boolResults = false,
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  newCashAdvice
) => {
  let _Arr = newPensionsAdvice
    .concat(newISAsAdvice)
    .concat(newGIAsAdvice)
    .concat(newCashAdvice);
  let _InvestmentsArr = _Arr.filter(
    (investment) =>
      investment[contribution1] > 0 ||
      investment[contribution2] > 0 ||
      (contribution3 === "" ? false : investment[contribution3] > 0)
  );
  if (_InvestmentsArr.length > 0 && boolResults) {
    return true;
  } else if (_InvestmentsArr.length > 0) {
    return _InvestmentsArr;
  } else if (boolResults) {
    return false;
  } else {
    return [];
  }
};

export const getRetirementDate = (retirementAge, dob) => {
  return moment(dob.seconds).add(retirementAge, "y").format("DD-MM-YYYY");
};

export const getCapitalisedType = (type) => {
  if (type.toLowerCase() === "isa" || type.toLowerCase() === "gia") {
    return type.toUpperCase();
  } else return type.charAt(0).toUpperCase() + type.slice(1);
};

export const getWrapperContributionAmount = (investment, wrapperType) => {
  if (investment.type === "pension") {
    if (
      investment["gross" + wrapperType + "PersonalContribution"] > 0 ||
      investment["gross" + wrapperType + "EmployerContribution"] > 0
    ) {
      if (
        investment["gross" + wrapperType + "PersonalContribution"] > 0 &&
        investment["gross" + wrapperType + "EmployerContribution"] > 0
      ) {
        return [
          "£" +
            (
              parseInt(
                investment["gross" + wrapperType + "PersonalContribution"]
              ) * 0.8
            ).toFixed() +
            " net (£" +
            parseInt(
              investment["gross" + wrapperType + "PersonalContribution"]
            ).toFixed() +
            " gross)",
          "£" +
            parseInt(
              investment["gross" + wrapperType + "EmployerContribution"]
            ).toFixed() +
            " gross employer contribution",
        ];
      } else if (
        investment["gross" + wrapperType + "PersonalContribution"] > 0
      ) {
        return (
          "£" +
          (
            parseInt(
              investment["gross" + wrapperType + "PersonalContribution"]
            ) * 0.8
          ).toFixed() +
          " net (£" +
          parseInt(
            investment["gross" + wrapperType + "PersonalContribution"]
          ).toFixed() +
          " gross)"
        );
      } else if (
        investment["gross" + wrapperType + "EmployerContribution"] > 0
      ) {
        return (
          "£" +
          parseInt(
            investment["gross" + wrapperType + "EmployerContribution"]
          ).toFixed() +
          " gross employer contribution"
        );
      } else return "£NaN";
    }
  } else if (investment.type === "isa") {
    return (
      "£" +
      parseInt(
        investment[
          wrapperType.charAt(0).toLowerCase() +
            wrapperType.substring(1) +
            "Contribution"
        ]
      ).toFixed()
    );
  } else if (investment.type === "gia") {
    return (
      "£" +
      parseInt(
        investment[
          wrapperType.charAt(0).toLowerCase() +
            wrapperType.substring(1) +
            "Contribution"
        ]
      ).toFixed()
    );
  } else {
    //must be cash
    return (
      "£" +
      parseInt(
        investment[
          wrapperType.charAt(0).toLowerCase() +
            wrapperType.substring(1) +
            "Contribution"
        ]
      ).toFixed()
    );
  }
};

export const getSolution = (strategy, yearsToGoal, clientAttitudeToRisk) => {
  if (yearsToGoal > 49) {
    yearsToGoal = 50;
  }

  let riskLevel =
    riskMatrix["YEARS TO GOAL"][yearsToGoal][clientAttitudeToRisk];

  return providerInvestmentSolutions["investmentStrategy"][strategy][
    "riskCategory"
  ][riskLevel];
};

export const getInvestmentHeldIn = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  cashAdvice,
  index
) => {
  let investmentTypes = [];
  for (let pension of pensionsAdvice) {
    if (pension.goalAllocations[index].goalAllocationPercent > 0) {
      investmentTypes.push("Pension ");
      break;
    }
  }
  for (let isa of isasAdvice) {
    if (isa.goalAllocations[index].goalAllocationPercent > 0) {
      investmentTypes.push("ISA ");
      break;
    }
  }
  for (let gia of giasAdvice) {
    if (gia.goalAllocations[index].goalAllocationPercent > 0) {
      investmentTypes.push("GIA ");
      break;
    }
  }
  for (let cash of cashAdvice) {
    if (cash.goalAllocations[index].goalAllocationPercent > 0) {
      investmentTypes.push("Cash");
      break;
    }
  }

  return [...new Set(investmentTypes)].join(", ");
};

export const getInvestmentSolutions = (
  pensionsAdvice,
  type,
  customerGoals,
  clientAttitudeToRisk
) => {
  let investmentSolutions = [];
  for (let advice of pensionsAdvice) {
    let goalIndex = 0;
    let typeIndex = pensionsAdvice.indexOf(advice);
    let _totalUnderAdvice = 0;
    if (type === "pension") {
      _totalUnderAdvice = getCurrentValueNewPension(advice);
    } else {
      _totalUnderAdvice = getCurrentValueISAGIACash(advice);
    }
    for (let goal of advice.goalAllocations) {
      let solution = {
        investment: "Cazenove Discretionary Fund Management bespoke portfolio",
      };

      if (advice.investmentStrategyActive === "dfm") {
        if (type === "pension") {
          solution.provider = "Curtis Banks";
        } else {
          solution.provider = "Cazenove";
        }
      }

      if (advice.investmentStrategyActive !== "dfm") {
        solution = getSolution(
          advice.investmentStrategyActive,
          goal.goalTimeHorizon,
          clientAttitudeToRisk
        );
      }

      let totalInitialAmount = 0;
      let monthlyContributions = 0;
      switch (type) {
        case "pension":
          totalInitialAmount =
            parseInt(advice.totalTransfersIn) +
            parseInt(advice.grossLumpSumPersonalContribution) +
            parseInt(advice.grossLumpSumEmployerContribution);
          monthlyContributions =
            parseInt(advice.grossMonthlyPersonalContribution) +
            parseInt(advice.grossMonthlyEmployerContribution);
          break;
        case "isa":
          totalInitialAmount =
            parseInt(advice.totalTransfersIn) +
            parseInt(advice.lumpSumContribution);
          monthlyContributions = parseInt(advice.monthlyContribution);
          break;
        case "gia":
          totalInitialAmount =
            parseInt(advice.totalTransfersIn) +
            parseInt(advice.lumpSumContribution);
          monthlyContributions = parseInt(advice.monthlyContribution);
          break;
        default:
          break;
      }

      if (solution) {
        fundProviders.add(solution.provider);
        investmentProviders.add(solution.investment);
        if (goal.goalAllocationPercent > 0) {
          let goalAmountUnderAdvice =
            (_totalUnderAdvice / goal.goalAllocationPercent) * 100;
          investmentSolutions.push({
            type: type,
            typeIndex: typeIndex,
            owner: advice.owner,
            provider: solution.provider,
            investmentSolution: solution.investment,
            investmentSolutionEquity:
              investmentProviderData["investmentStrategy"][solution.investment][
                "Equity Content"
              ],
            totalInitialAmount: totalInitialAmount,
            totalUnderAdvice: _totalUnderAdvice,
            monthlyContributions: monthlyContributions,
            goalIndex: goalIndex,
            goalName: goal.goalName,
            goalType: goal.goalType,
            goalRisk: getGoalRiskProfile(
              goal.goalTimeHorizon,
              clientAttitudeToRisk
            ),
            goalAmountUnderAdvice: goalAmountUnderAdvice,
            goalAllocationPercent: goal.goalAllocationPercent,
          });
        }
      }
      goalIndex += 1;
    }
  }

  return investmentSolutions;
};

export const getInvestmentSolutionsGroupedByGoals = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  customerGoals,
  clientAttitudeToRisk
) => {
  let solutions = getAllInvestmentSolutions(
    pensionsAdvice,
    isasAdvice,
    giasAdvice,
    customerGoals,
    clientAttitudeToRisk
  );
  let _solutionsGoalsAttributed = [];

  for (let solution of solutions) {
    let _inGoalsAttributed = false;

    for (const goalsAttributedSolution of _solutionsGoalsAttributed) {
      if (
        solution.provider === goalsAttributedSolution.provider &&
        solution.type === goalsAttributedSolution.type &&
        solution.owner === goalsAttributedSolution.owner &&
        solution.typeIndex === goalsAttributedSolution.typeIndex
      ) {
        goalsAttributedSolution.goalsAttributed.push({
          goalIndex: solution.goalIndex,
          goalAllocationPercent: solution.goalAllocationPercent,
          goalName: solution.goalName,
          goalType: solution.goalType,
          goalRisk: solution.goalRisk,
        });
        if (solution.goalAmountUnderAdvice) {
          goalsAttributedSolution.investmentAmountUnderAdvice +=
            solution.investmentAmountUnderAdvice;
        }
        if (solution.investmentSolution) {
          goalsAttributedSolution.investmentSolutions.push({
            solution: solution.investmentSolution,
            goalAllocationPercent: solution.goalAllocationPercent,
          });
        }
        _inGoalsAttributed = true;
      }
    }
    if (!_inGoalsAttributed) {
      let _newSolution = {
        type: solution.type,
        typeIndex: solution.typeIndex,
        owner: solution.owner,
        provider: solution.provider,
        investmentSolutions: [
          {
            solution: solution.investmentSolution,
            goalAllocationPercent: solution.goalAllocationPercent,
          },
        ],
        totalInitialAmount: solution.totalInitialAmount,
        totalUnderAdvice: solution.totalUnderAdvice,
        investmentAmountUnderAdvice: solution.goalAmountUnderAdvice,
        monthlyContributions: solution.monthlyContributions,
        goalsAttributed: [
          {
            goalIndex: solution.goalIndex,
            goalAllocationPercent: solution.goalAllocationPercent,
            goalName: solution.goalName,
            goalType: solution.goalType,
            goalRisk: solution.goalRisk,
          },
        ],
      };
      _solutionsGoalsAttributed.push(_newSolution);
    }
  }

  for (let gas of _solutionsGoalsAttributed) {
    let _goalsAttributedProperties = [];
    for (let goal of gas.goalsAttributed) {
      _goalsAttributedProperties.push({
        goalName: customerGoals[goal.goalIndex].goalName,
        goalAllocationPercent: goal.goalAllocationPercent,
        goalAllocatedAmount:
          (gas.totalUnderAdvice * goal.goalAllocationPercent) / 100,
      });
    }

    let _solutions = [];
    for (let invsolution of gas.investmentSolutions) {
      _solutions.push(invsolution.solution);
    }
    gas.goalsAttributedProperties = [
      ...new Set(
        _goalsAttributedProperties.map((goal) => {
          return goal.goalName + " (" + goal.goalAllocationPercent + "%)";
        })
      ),
    ].join(", ");
    gas.investmentSolutions = [...new Set(_solutions)].join(", ");
  }

  return _solutionsGoalsAttributed;
};

export const getInvestmentSolutionsGroupedByGoalsRisk = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  customerGoals,
  clientAttitudeToRisk
) => {
  let solutions = getAllInvestmentSolutions(
    pensionsAdvice,
    isasAdvice,
    giasAdvice,
    customerGoals,
    clientAttitudeToRisk
  );

  let _solutionsGoalsAttributed = [];

  for (let solution of solutions) {
    let _inGoalsAttributed = false;

    for (const goalsAttributedSolution of _solutionsGoalsAttributed) {
      if (
        solution.investmentSolution ===
          goalsAttributedSolution.investmentSolution &&
        solution.type === goalsAttributedSolution.type
      ) {
        if (
          !goalsAttributedSolution.goalsAttributed.reduce(
            (accumulator, goal) =>
              accumulator ||
              (goal.goalType === solution.goalType &&
                goal.goalRisk === solution.goalRisk),
            false
          )
        ) {
          goalsAttributedSolution.goalsAttributed.push({
            goalIndex: solution.goalIndex,
            goalAllocationPercent: solution.goalAllocationPercent,
            goalName: solution.goalName,
            goalType: solution.goalType,
            goalRisk: solution.goalRisk,
          });
        }

        if (solution.goalAmountUnderAdvice) {
          goalsAttributedSolution.investmentAmountUnderAdvice +=
            solution.investmentAmountUnderAdvice;
        }
        if (solution.investmentSolution) {
          goalsAttributedSolution.investmentSolution =
            solution.investmentSolution;
        }
        _inGoalsAttributed = true;
      }
    }
    if (!_inGoalsAttributed) {
      let _newSolution = {
        type: solution.type,
        owner: solution.owner,
        provider: solution.provider,
        investmentSolution: solution.investmentSolution,
        equity:
          investmentProviderData["investmentStrategy"][
            solution.investmentSolution
          ]["Equity Content"],
        totalInitialAmount: solution.totalInitialAmount,
        totalUnderAdvice: solution.totalUnderAdvice,
        investmentAmountUnderAdvice: solution.goalAmountUnderAdvice,
        monthlyContributions: solution.monthlyContributions,
        goalsAttributed: [
          {
            goalIndex: solution.goalIndex,
            goalAllocationPercent: solution.goalAllocationPercent,
            goalName: solution.goalName,
            goalType: solution.goalType,
            goalRisk: solution.goalRisk,
          },
        ],
      };
      _solutionsGoalsAttributed.push(_newSolution);
    }
  }

  let uniqueSolutions = [
    ..._solutionsGoalsAttributed
      .reduce((map, obj) => map.set(obj.investmentSolution, obj), new Map())
      .values(),
  ];

  if (uniqueSolutions.length === 0) {
    uniqueSolutions = ["no solutions"];
  }

  return uniqueSolutions;
};

export const getAllInvestmentSolutions = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  customerGoals,
  clientAttitudeToRisk
) => {
  let solutions = [
    ...getInvestmentSolutions(
      pensionsAdvice,
      "pension",
      customerGoals,
      clientAttitudeToRisk
    ),
    ...getInvestmentSolutions(
      isasAdvice,
      "isa",
      customerGoals,
      clientAttitudeToRisk
    ),
    ...getInvestmentSolutions(
      giasAdvice,
      "gia",
      customerGoals,
      clientAttitudeToRisk
    ),
  ];
  return solutions;
};

export const getAllInvestmentSolutionsDeduped = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  customerGoals,
  clientAttitudeToRisk
) => {
  let solutions = getAllInvestmentSolutions(
    pensionsAdvice,
    isasAdvice,
    giasAdvice,
    customerGoals,
    clientAttitudeToRisk
  );
  let _deDupedSolutions = [];
  for (let solution of solutions) {
    if (
      !_deDupedSolutions.reduce(
        (accumulator, sol) =>
          accumulator ||
          (sol.provider === solution.provider &&
            sol.investmentSolution === solution.investmentSolution),
        false
      )
    ) {
      _deDupedSolutions.push(solution);
    }
  }

  return _deDupedSolutions;
};

export const fundProviderRegex = (provider) => {
  let BLACKROCK = new RegExp(/^(?!.*ESG).*BlackRock MyMap.*$/, "i");
  let BLACKROCK_ESG = new RegExp(/BlackRock MyMap \d ESG/, "i");
  let VANGUARD = new RegExp(/Vanguard LifeStrategy/, "i");
  let TATTON = new RegExp(/Tatton Global Core/, "i");
  let EQ = new RegExp(/EQ Future Leaders/, "i");
  let CAZENOVE = new RegExp(/Cazenove/, "i");

  if (BLACKROCK.test(provider)) {
    return "BlackRock MyMap";
  } else if (BLACKROCK_ESG.test(provider)) {
    return "BlackRock MyMap ESG";
  } else if (VANGUARD.test(provider)) {
    return "Vanguard LifeStrategy";
  } else if (TATTON.test(provider)) {
    return "Tatton Global Core";
  } else if (EQ.test(provider)) {
    return "EQ Future Leaders";
  } else if (CAZENOVE.test(provider)) {
    return "Cazenove Discretionary Fund Management";
  }
};

export const getFundProviders = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  customerGoals,
  clientAttitudeToRisk
) => {
  const solutions = getAllInvestmentSolutions(
    pensionsAdvice,
    isasAdvice,
    giasAdvice,
    customerGoals,
    clientAttitudeToRisk
  );
  const _uniqueFundProviders = [
    ...new Set(solutions.map((item) => item.provider)),
    ...new Set(
      solutions.map((item) => fundProviderRegex(item.investmentSolution))
    ),
  ];

  let _fundProviders = [];

  for (let provider of _uniqueFundProviders) {
    _fundProviders.push({
      provider: provider,
      pParagraphs: providerParagraphs[provider],
    });
  }

  return _fundProviders;
};

export const getInvestmentSolutionsByGoal = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  index,
  provider,
  customerGoals,
  clientAttitudeToRisk
) => {
  let solutions = [
    ...getInvestmentSolutions(
      pensionsAdvice,
      "pension",
      customerGoals,
      clientAttitudeToRisk
    ),
    ...getInvestmentSolutions(
      isasAdvice,
      "isa",
      customerGoals,
      clientAttitudeToRisk
    ),
    ...getInvestmentSolutions(
      giasAdvice,
      "gia",
      customerGoals,
      clientAttitudeToRisk
    ),
  ];
  let solutionProvidersForThisGoal = [];
  for (let solution of solutions) {
    if (solution.goalIndex === index) {
      if (provider === "provider") {
        solutionProvidersForThisGoal.push(solution.provider);
      } else {
        solutionProvidersForThisGoal.push(solution.investmentSolution);
      }
    }
  }

  return [...new Set(solutionProvidersForThisGoal)];
};

export const areTherePensionContributions = (newPensionsAdvice) => {
  if (newPensionsAdvice) {
    return newPensionsAdvice.reduce(
      (accumulator, pension) =>
        accumulator ||
        pension.grossMonthlyPersonalContribution > 0 ||
        pension.grossMonthlyEmployerContribution > 0 ||
        pension.grossLumpSumPersonalContribution > 0 ||
        pension.grossLumpSumEmployerContribution > 0,
      false
    );
  }
  return false;
};

export const areTherePensionContributionsCustomer = (
  customer,
  newPensionsAdvice
) => {
  let _pensionsCustomer = newPensionsAdvice.filter((x) => x.owner === customer);

  return _pensionsCustomer.reduce(
    (accumulator, pension) =>
      accumulator ||
      pension.grossMonthlyEmployerContribution > 0 ||
      pension.grossLumpSumEmployerContribution > 0,
    false
  );
};

export const getWhosePensions = (customerInfo, newPensionsAdvice) => {
  let _customer1Contributions;
  let _customer2Contributions;
  if (newPensionsAdvice) {
    _customer1Contributions = areTherePensionContributionsCustomer(
      "customer1",
      newPensionsAdvice
    );
    _customer2Contributions = areTherePensionContributionsCustomer(
      "customer2",
      newPensionsAdvice
    );
  }

  if (_customer1Contributions && _customer2Contributions) {
    return (
      customerInfo.customers.customer1.customerFirstName +
      " and " +
      customerInfo.customers.customer2.customerFirstName
    );
  } else if (_customer1Contributions && !_customer2Contributions) {
    return customerInfo.customers.customer1.customerFirstName;
  } else if (!_customer1Contributions && _customer2Contributions) {
    return customerInfo.customers.customer2.customerFirstName;
  } else {
    return false;
  }
};

export const areThereOtherInvestmentContributions = (
  newISAsAdvice,
  newGIAsAdvice
) => {
  let _isas = newISAsAdvice;
  let _gias = newGIAsAdvice;

  const isaLumpSumOrContributionExists = _isas.reduce(
    (accumulator, isa) =>
      accumulator || isa.monthlyContribution > 0 || isa.lumpSumContribution > 0,
    false
  );

  const giaLumpSumOrContributionExists = _gias.reduce(
    (accumulator, gia) =>
      accumulator || gia.monthlyContribution > 0 || gia.lumpSumContribution > 0,
    false
  );

  if (isaLumpSumOrContributionExists || giaLumpSumOrContributionExists) {
    return true;
  }
  return false;
};

export const checkInFundProviders = (fundProvider) => {
  let _containsProvider = false;

  for (let provider of [...fundProviders]) {
    if (provider.includes(fundProvider)) {
      _containsProvider = true;
    }
  }

  return _containsProvider;
};

export const getLifeCover = (lifeCover, incomeProtection) => {
  if (lifeCover && incomeProtection) {
    return "You have both life cover and income protection in place. ";
  } else if (lifeCover && !incomeProtection) {
    return "You have life cover, but you don't have any income protection in place. ";
  } else if (!lifeCover && incomeProtection) {
    return "You don't have any life cover in place, but you do have income protection.  ";
  } else {
    return "You don't have any life cover or income protection in place. ";
  }
};

export const getMortgageTerm = (termIndex) => {
  switch (termIndex) {
    case 1:
      return "Less than 6 months";
    case 2:
      return "6 to 12 months";
    case 3:
      return "More than 12 months";
  }
};

export const getIsPensionRetained = (pensions) => {
  return pensions.filter((pension) => pension.transferPension === false);
};

export const getIsISARetained = (isas) => {
  return isas.filter((isa) => isa.transferISA === false);
};

export const getHasGoalBeenAllocated = (
  goal,
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  newCashAdvice
) => {
  let _goalIndex = 0;
  if (goal.realIndex !== undefined) {
    _goalIndex = goal.realIndex;
  }
  const pensionsAdvice = newPensionsAdvice;
  const isasAdvice = newISAsAdvice;
  const giasAdvice = newGIAsAdvice;
  const cashAdvice = newCashAdvice;
  const _advice = [
    ...pensionsAdvice,
    ...isasAdvice,
    ...giasAdvice,
    ...cashAdvice,
  ];
  for (let investment of _advice) {
    if (investment.goalAllocations && investment.goalAllocations[_goalIndex]) {
      let _goalPercent = parseInt(
        investment.goalAllocations[_goalIndex].goalAllocationPercent
      );
      if (_goalPercent > 0) {
        return true;
      }
    }
  }

  return false;
};

export const getAnyEmployerContributions = (newPensionsAdvice) => {
  if (newPensionsAdvice) {
    return newPensionsAdvice.reduce(
      (accumulator, pension) =>
        accumulator ||
        pension.grossMonthlyEmployerContribution > 0 ||
        pension.grossLumpSumEmployerContribution > 0,
      false
    );
  }
  return false;
};
