// Core React dependencies
import React, { useState, useEffect, useRef, useContext } from 'react';

// Component imports
import Page from '../../containers/Page/page';
import AmmoniteTopBar from '../../containers/TopBar/TopBar';
import AmmoniteBar from '../../containers/Bar/Bar';

// Utilities
import AmmoniteContext from '../../Utils/contexts/AmmoniteContext';
import { annualSpending } from '../../Utils/data/annualSpending'

// Actions
import { refreshContextFromCache, setRetirementAge, resetRetirement, setNewComforts, setNewLuxuries } from '../../Utils/reducers/actions';

// Styling
import './retirement.css';


function Retirement() {
	const context = useContext(AmmoniteContext);

	let retirementGraphPanel = useRef();

	const [ essentials, setEssentials ] = useState(false);
	const [ comforts, setComforts ] = useState(context.state.retirementComforts);
	const [ luxuries, setLuxuries ] = useState(context.state.retirementLuxuries);
	const [ lastChecked, setlastChecked ] = useState(false);

	const [essentialsValues, setEssentialsValues] = useState(false)
	const [comfortsValues, setComfortsValues] = useState(false)
	const [luxuriesValues, setLuxuriesValues] = useState(false)

	const getAnnualSpending = () => {
		let _retirementSpending = context.state.retirementSpending
		let _couple = context.state.customerInfo.couple
		let _annualSpending = annualSpending(
			_retirementSpending, 
			comforts, 
			luxuries, 
			_couple
		)
		return _annualSpending;
	};

	const [ topBarPosition, setTopBarPosition ] = useState(false);
	const [ currentBarRef, setCurrentBarRef ] = useState(null);
	const [ annualSpend, setAnnualSpend ] = useState(getAnnualSpending());

	const dispatch = context.dispatch;

	const comfortsHandler = () => {
		setComforts(!comforts);
		setlastChecked('comforts')
	};

	const luxuriesHandler = () => {
		setLuxuries(!luxuries);
		setlastChecked('luxuries')
	};

	const resetRetirementSpending = () => {
		dispatch({ type: resetRetirement });
		setEssentials(false);
		setComforts(false);
		setLuxuries(false);
	};

	const changeRetirementAgeHandler = (plus) => {
		let newAge = context.state.customerGoals[0].goalTimeHorizon;
		if (plus === true) {
			newAge = newAge + 1;
		} else {
			newAge -= 1;
		}
		dispatch({ type: setRetirementAge, payload: { retirementAge: newAge } });
	};

	const handleScroll = (e) => {
		if (e.target.scrollTop !== 0) {
			setTopBarPosition(true);
		} else {
			setTopBarPosition(false);
		}
	};

	const handleScrollTo = (y) => {
		if (luxuries === true && lastChecked === 'luxuries') {
			retirementGraphPanel.current.scrollTo(y, 860)
		}  
		if (comforts === true && lastChecked === 'comforts') {
			retirementGraphPanel.current.scrollTo(y, 450)
		}
	}

	const resetEssentials = () => {
		setEssentialsValues(context.state.retirementSpending.essentials);
		setComfortsValues(sortStoL(context.state.retirementSpending.comforts));
		setLuxuriesValues(context.state.retirementSpending.luxuries);
		setEssentials(true);
	};

	const sortStoL = (sortThis) => {
		let newArray = sortThis;

		return newArray;
	}

	const getFormattedNumber = (val) => {
		return  val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	const handleYmove = (value) => {
		const { y } = retirementGraphPanel.current.getBoundingClientRect()
		const Y = retirementGraphPanel.current.getBoundingClientRect().bottom
			retirementGraphPanel.current.scrollTo(y, (Y - value) * 1.2)
			
	}

	useEffect(
		() => {
			const { y } = retirementGraphPanel.current.getBoundingClientRect()
			if (context.state.clean === true && localStorage.ammoniteContext) {
				dispatch({ type: refreshContextFromCache, payload: JSON.parse(localStorage.getItem('ammoniteContext')) });
				setEssentialsValues(JSON.parse(localStorage.getItem('ammoniteContext')).state.retirementSpending.essentials)
			}
			if (essentials === false) {
				resetEssentials();
			}
			if (comforts !== null) {
					dispatch({ type: setNewComforts, payload: comforts })
			}
			if (luxuries !== null) {
				dispatch({ type: setNewLuxuries, payload: luxuries });
				
			}
			if (comforts === true || luxuries === true) {
				handleScrollTo(y)
			}
		},
		[ essentials, comforts, luxuries, currentBarRef, annualSpend, essentialsValues, comfortsValues]
	);

	return (
		<Page
			headerText={'Your Retirement Spending'}
			introText={
				'Below shows average annual spending in retirement, based on thousands of survey responses from actual retirees.  Play around with the bars and tick boxes to find out your retirement spending.'
			}
			progress={0.625}
			backLink="/tellusaboutyourgoals"
			forwardLink="/savingforretirement"
			isValid={true}
			startOver
			reset={resetRetirementSpending}
			howWeWorkedThisOutScrollable={false}
			howWeWorkedThisOut="We've taken the figures above from a survey undertaken by Which? magazine.  Which? has surveyed more than 6,300 real retirees to find out what they spend in retirement.  



			We've fixed the retirement age at 68.  If you retire before this time, you might need to save more to fill the gap before your state pension is likely to start.  If you want to target an earlier retirement age, you can discuss this with our advisor hub so they can factor this into their advice. "
		>
			<div className="mainRetirementContainer">
				{topBarPosition && <div className="whiteScreen">&nbsp;</div>}
				<div className={`retirementTopBar ${topBarPosition === true ? 'topBarToTop' : ''}`}>
					<AmmoniteTopBar
						annualSpending={getFormattedNumber(getAnnualSpending())}
						fullStatePension={getFormattedNumber(context.state.statePension)}
						retirementAge={context.state.customerGoals[0].goalTimeHorizon}
						ageHandler={(plus) => changeRetirementAgeHandler(plus)}
						comfortsHandler={() => comfortsHandler()}
						luxuriesHandler={() => luxuriesHandler()}
						comforts={comforts}
						luxuries={luxuries}
					/>
				</div>
				<div className="retirementGraph" ref={retirementGraphPanel} onScroll={(e) => handleScroll(e)}>
					{essentials && essentialsValues && (
						<div className="essentials">
							<div className="essentialsHeader">Essentials</div>
							{context.state.customerInfo.couple === false ? (
								essentialsValues.map((item, index) => {
									const key = Object.keys(item);
									const amount = item[key]['amount'];
									const colour = item[key]['colour'];
									return (
										<AmmoniteBar
											itemType={'essentials'}
											index={index}
											barName={key}
											barAmount={amount}
											colour={colour}
											handleYmove ={(value) => handleYmove(value)}
										/>
									);
								})
							) : (
								essentialsValues.map((item, index) => {
									const key = Object.keys(item);
									const amount = item[key]['amountCouple'];
									const colour = item[key]['colour'];
									return (
										<AmmoniteBar
											itemType={'essentials'}
											index={index}
											barName={key}
											barAmount={amount}
											colour={colour}
											handleYmove ={(value) => handleYmove(value)}
										/>
									);
								})
							)}
						</div>
					)}
					{comforts && comfortsValues && (
						<div className="essentials">
							<div className="essentialsHeader">Comforts</div>
							{context.state.customerInfo.couple === false ? (
								comfortsValues.map((item, index) => {
									const key = Object.keys(item);
									const amount = item[key]['amount'];
									const colour = item[key]['colour'];
									return (
										<AmmoniteBar
											itemType={'comforts'}
											index={index}
											barName={key}
											barAmount={amount}
											colour={colour}
											handleYmove ={(value) => handleYmove(value)}
										/>
									);
								})
							) : (
								comfortsValues.map((item, index) => {
									const key = Object.keys(item);
									const amount = item[key]['amountCouple'];
									const colour = item[key]['colour'];
									return (
										<AmmoniteBar
											itemType={'comforts'}
											index={index}
											barName={key}
											barAmount={amount}
											colour={colour}
											handleYmove ={(value) => handleYmove(value)}
										/>
									);
								})
							)}
						</div>
					)}
					{luxuries && luxuriesValues && (
						<div className="essentials">
							<div className="essentialsHeader">Luxuries</div>
							{context.state.customerInfo.couple === false ? (
								luxuriesValues.map((item, index) => {
									const key = Object.keys(item);
									const amount = item[key]['amount'];
									const colour = item[key]['colour'];
									return (
										<AmmoniteBar
											itemType={'luxuries'}
											index={index}
											barName={key}
											barAmount={amount}
											colour={colour}
											handleYmove ={(value) => handleYmove(value)}
										/>
									);
								})
							) : (
								luxuriesValues.map((item, index) => {
									const key = Object.keys(item);
									const amount = item[key]['amountCouple'];
									const colour = item[key]['colour'];
									return (
										<AmmoniteBar
											itemType={'luxuries'}
											index={index}
											barName={key}
											barAmount={amount}
											colour={colour}
											handleYmove ={(value) => handleYmove(value)}
										/>
									);
								})
							)}
						</div>
					)}
				</div>
			</div>
		</Page>
	);
}

export default Retirement;
