// Core React dependencies
import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router";

// Component imports
import AdditionalCustomerInfoChip from "../../../Assets/SVG/Chips/SuitabilityChips/additionalCustomerInfoChip";
import AttitudeToRiskChip from "../../../Assets/SVG/Chips/SuitabilityChips/attitudeToRiskChip";
import AdditionalGoalDetailsChip from "../../../Assets/SVG/Chips/SuitabilityChips/additionalGoalDetailsChip";
import ExistingPensionDetailsChip from "../../../Assets/SVG/Chips/SuitabilityChips/existingPensionDetailsChip";
import ExistingInvestmentDetailsChip from "../../../Assets/SVG/Chips/SuitabilityChips/existingInvestmentDetailsChip";
import SuitabilityReportBuilderChip from "../../../Assets/SVG/Chips/SuitabilityChips/suitabilityReportBuilderChip";
import LOABuilderChip from "../../../Assets/SVG/Chips/SuitabilityChips/loaBuilderChip";
import FactFinderBuilderChip from "../../../Assets/SVG/Chips/SuitabilityChips/factFinderBuilderChip";
import AmmoniteCheckbox from "../../../components/Checkbox/Checkbox";
import { saveContextToFirebase } from "../../../Utils/data/saveSuitabilityContext";
import SuitabilityReportContext from "../../../Utils/contexts/suitabilityReportContext";
import AmmoniteHome from "../../../Assets/SVG/SBContextBarIcons/Home/home";
import { changeCheckListItem } from "../../../Utils/reducers/suitabilityReportActions";

import Loader from "react-loader-spinner";

// Styling
import "./suitabilityDashboard.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import styles from "../../../Styles/srbstyles.module.css";

function usePreviousSession(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function SuitabilityDashboard() {
  const { push } = useHistory();
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const handleAdditionalClick = (chipLink) => {
    push(chipLink);
  };
  const navigateToDashboard = () => {
    push("/dashboard");
  };

  const setChecklistItem = (item) => {
    dispatch({ type: changeCheckListItem, payload: item });
    return saveHandler();
  };

  const saveHandler = async () => {
    await saveContextToFirebase(state);
  };

  useEffect(() => {}, []);

  return (
    <div className={styles.suitabilityPanel}>
      {status !== "success" ? (
        <div></div>
      ) : (
        <div className={styles.pageTitleText}>{state.names}</div>
      )}
      {status !== "success" ? (
        <div></div>
      ) : (
        <div className="sidePanelCheckboxes">
          <div className="checklistTitle">New Client Checklist</div>
          <div className="checklistItem">
            <AmmoniteCheckbox
              checked={state.checklist.sentAdviceFrontLink}
              checkedHandler={() => setChecklistItem("sentAdviceFrontLink")}
            />
            <div className="clientCheckboxText">Sent AdviceFront Link</div>
          </div>
          <div className="checklistItem">
            <AmmoniteCheckbox
              checked={state.checklist.recentID}
              checkedHandler={() => setChecklistItem("recentID")}
            />
            <div className="clientCheckboxText">Recent ID</div>
          </div>
          <div className="checklistItem">
            <AmmoniteCheckbox
              checked={state.checklist.signedLOAs}
              checkedHandler={() => setChecklistItem("signedLOAs")}
            />
            <div className="clientCheckboxText">Signed LOAs</div>
          </div>
          <div className="checklistItem">
            <AmmoniteCheckbox
              checked={state.checklist.signedCIDD}
              checkedHandler={() => setChecklistItem("signedCIDD")}
            />
            <div className="clientCheckboxText">Signed CIDD</div>
          </div>
          <div className="checklistItem">
            <AmmoniteCheckbox
              checked={state.checklist.completedFactFind}
              checkedHandler={() => setChecklistItem("completedFactFind")}
            />
            <div className="clientCheckboxText">Completed Fact Find</div>
          </div>
          <div className="checklistItem">
            <AmmoniteCheckbox
              checked={state.checklist.completedATR}
              checkedHandler={() => setChecklistItem("completedATR")}
            />
            <div className="clientCheckboxText">Completed ATR</div>
          </div>
          <div className="checklistItem">
            <AmmoniteCheckbox
              checked={state.checklist.initialPayment}
              checkedHandler={() => setChecklistItem("initialPayment")}
            />
            <div className="clientCheckboxText">Initial Payment</div>
          </div>
          <div className="checklistItem">
            <AmmoniteCheckbox
              checked={state.checklist.callback}
              checkedHandler={() => setChecklistItem("callback")}
            />
            <div className="clientCheckboxText">
              Callback to discuss goals/FF
            </div>
          </div>
          <div className="checklistItem">
            <AmmoniteCheckbox
              checked={state.checklist.suitabilityReport}
              checkedHandler={() => setChecklistItem("suitabilityReport")}
            />
            <div className="clientCheckboxText">Suitability Report</div>
          </div>
          <div className="checklistItem">
            <AmmoniteCheckbox
              checked={state.checklist.clientAgreement}
              checkedHandler={() => setChecklistItem("clientAgreement")}
            />
            <div className="clientCheckboxText">Client Agreement</div>
          </div>
          <div className="checklistItem">
            <AmmoniteCheckbox
              checked={state.checklist.implementation}
              checkedHandler={() => setChecklistItem("implementation")}
            />
            <div className="clientCheckboxText">Implementation</div>
          </div>
          <div className="checklistItem">
            <AmmoniteCheckbox
              checked={state.checklist.addedToJHSystem}
              checkedHandler={() => setChecklistItem("addedToJHSystem")}
            />
            <div className="clientCheckboxText">Added to JH system</div>
          </div>
          <div className="checklistItem">
            <AmmoniteCheckbox
              checked={state.checklist.transactionsComplete}
              checkedHandler={() => setChecklistItem("transactionsComplete")}
            />
            <div className="clientCheckboxText">Transactions complete</div>
          </div>
        </div>
      )}
      {status !== "success" ? (
        <div className="sessionLoading">
          <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        </div>
      ) : (
        <div>
          <div className={styles.pageInfoText}>
            <div className="tableRowTitles">
              <p>Coach name:</p>
              <p>Date of goals session:</p>
              <p>Phone number:</p>
              <p>Email:</p>
            </div>
            <div className="tableRowValues">
              <p>{state.reportAdviser ? state.reportAdviser : " - "}</p>
              <p>{state.sessionDate ? state.sessionDate : " - "}</p>
              <p>{state.customerTelephone ? state.customerTelephone : " - "}</p>
              <p>{state.customerEmail ? state.customerEmail : " - "}</p>
            </div>
          </div>
          <div className={[styles.pageMainText, "linkLabels"].join(" ")}>
            {" "}
            Add additional information about the client(s) here:{" "}
          </div>
          <div className="linkChips">
            <div className="additionalInformationLinks">
              <div
                className="linkChip"
                onClick={() =>
                  handleAdditionalClick("/additionalcustomerinformation")
                }
              >
                <AdditionalCustomerInfoChip />
              </div>
              <div
                className="linkChip"
                onClick={() => handleAdditionalClick("/attitudetorisk")}
              >
                <AttitudeToRiskChip />
              </div>
              <div
                className="linkChip"
                onClick={() => handleAdditionalClick("additionalgoaldetails")}
              >
                <AdditionalGoalDetailsChip />
              </div>
              <div
                className="linkChip"
                onClick={() => handleAdditionalClick("existingpensionsdetails")}
              >
                <ExistingPensionDetailsChip />
              </div>
              <div
                className="linkChip"
                onClick={() =>
                  handleAdditionalClick("existinginvestmentdetails")
                }
              >
                <ExistingInvestmentDetailsChip />
              </div>
            </div>
          </div>
          <div className={styles.menuBar}>
            <div className={styles.menuChips}>
              <div className="home" onClick={() => navigateToDashboard()}>
                <AmmoniteHome />
              </div>
              <div
                className={styles.menuChip}
                onClick={() =>
                  handleAdditionalClick("suitabilityreportbuilder")
                }
              >
                <SuitabilityReportBuilderChip />
              </div>
              <div
                className={styles.menuChip}
                onClick={() => handleAdditionalClick("newloaadvice")}
              >
                <LOABuilderChip />
              </div>
              <div
                className={styles.menuChip}
                onClick={() => handleAdditionalClick("factfinderbuilder")}
              >
                <FactFinderBuilderChip />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SuitabilityDashboard;
