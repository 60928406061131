import React from 'react';
import moment from 'moment';
import AmmoniteEditContext from '../../Assets/SVG/SBContextBarIcons/Edit/edit'
import AmmoniteCreateDoc from '../../Assets/SVG/SBContextBarIcons/CreateDoc/createDoc'
import AmmoniteCreatePdf from '../../Assets/SVG/SBContextBarIcons/CreatePdf/createPdf'
import AmmoniteImplement from '../../Assets/SVG/SBContextBarIcons/Implement/implement'
import './suitabilityContextBar.css';

function AmmoniteSBContextBar({ index, currentIndex, contextDate, edit, createDocX, implement }) {
	return <div className="contextBar">
        <div className={`tabColour ${index === currentIndex ? '' : 'isBarCurrent'}`}/>
        <div className="barInfo">
            <div className="barTitle">Suitability Context</div>
            <div className="barTitle barDate">{moment(contextDate).format('D')} {moment(contextDate).format('MMMM')} {moment(contextDate).format('YYYY')}</div>
            <div>{index}</div>
            
        </div>
        <div className="contextButtons">
            {index === currentIndex && <div className="contextButton" onClick={edit}><AmmoniteEditContext/></div>}
            <div className="contextButton" onClick={() => createDocX(index)}><AmmoniteCreateDoc/></div>
            <div className="contextButton"><AmmoniteCreatePdf/></div>
            {index !== currentIndex && <div className="contextButton" onClick={() => implement(index)}><AmmoniteImplement/></div>}
        </div>
    </div>;
}

export default AmmoniteSBContextBar;
