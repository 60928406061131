import React from 'react';

function AmmoniteTickSVG({ colour, width, height, transx, transy, scale }) {
	const scaleFactor = 'translate(' + transx + ' ' + transy + ')' + 'scale(' + scale + ')';

	return (
		<svg width={width} height={height}>
			<g transform={scaleFactor}>
				<path
					d="
						M2.60741 
						6.37779C2.41214 
						6.18253 2.09556 
						6.18253 
						1.9003 
						6.37779L0.646569 
						7.63152C0.552801 
						7.72529 0.500122 
						7.85247 0.500122 
						7.98507C0.500122 
						8.11768 0.552801 
						8.24486 0.646569 
						8.33863L5.66149 
						13.3536C5.85676 
						13.5488 6.17334 
						13.5488 6.3686 
						13.3536L17.1149 
						2.60728C17.2086 
						2.51352 
						17.2613 
						2.38634 
						17.2613 
						2.25373C17.2613 
						2.12112 17.2086 
						1.99395 17.1149 
						1.90018L15.8611 
						0.646447C15.6659 
						0.451184 
						15.3493 
						0.451185 
						15.154 
						0.646447L6.01505 
						9.78543L2.60741 6.37779Z
					"
					fill={colour}
					stroke={colour}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
}

export default AmmoniteTickSVG;
