import React from 'react';

function AmmoniteExistingPensions() {
	return (
<svg width="213" height="222" viewBox="0 0 213 222" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.7">
<path opacity="0.7" d="M176.581 106.337L106.317 89.4164V221.851L176.581 203.458V106.337Z" fill="url(#paint0_linear)"/>
<g opacity="0.1">
<path opacity="0.1" d="M176.581 106.337L106.317 89.4164V221.851L176.581 203.458V106.337Z" fill="url(#paint1_linear)"/>
</g>
<path opacity="0.7" d="M36.0503 106.337L106.317 89.4164V221.851L36.0503 203.824V106.337Z" fill="url(#paint2_linear)"/>
<g opacity="0.17">
<path opacity="0.17" d="M36.0503 106.337L106.317 89.4164V221.851L36.0503 203.824V106.337Z" fill="url(#paint3_linear)"/>
</g>
<path opacity="0.7" d="M106.317 89.4164L142.736 59.2511L213 77.2777L176.581 106.337L106.317 89.4164Z" fill="url(#paint4_linear)"/>
<path opacity="0.7" d="M0 77.6434L36.0504 106.337L106.317 89.4164L70.6324 59.2511L0 77.6434Z" fill="url(#paint5_linear)"/>
<g opacity="0.05">
<path opacity="0.05" d="M0 77.6434L36.0504 106.337L106.317 89.4164L70.6324 59.2511L0 77.6434Z" fill="url(#paint6_linear)"/>
</g>
<g opacity="0.5">
<path opacity="0.5" d="M105.049 74.1089H55.5078C54.7866 74.1089 54.2019 74.6935 54.2019 75.4147V120.922C54.2019 121.644 54.7866 122.228 55.5078 122.228H105.049C105.77 122.228 106.355 121.644 106.355 120.922V75.4147C106.355 74.6935 105.77 74.1089 105.049 74.1089Z" fill="url(#paint7_linear)"/>
</g>
<path opacity="0.7" d="M104.483 74.4803H56.1433C55.4221 74.4803 54.8374 75.065 54.8374 75.7862V120.127C54.8374 120.849 55.4221 121.433 56.1433 121.433H104.483C105.204 121.433 105.789 120.849 105.789 120.127V75.7862C105.789 75.065 105.204 74.4803 104.483 74.4803Z" fill="url(#paint8_linear)"/>
<path opacity="0.7" d="M66.0504 82.214H60.0695C59.3483 82.214 58.7637 82.7986 58.7637 83.5198V88.6417C58.7637 89.3629 59.3483 89.9476 60.0695 89.9476H66.0504C66.7716 89.9476 67.3562 89.3629 67.3562 88.6417V83.5198C67.3562 82.7986 66.7716 82.214 66.0504 82.214Z" fill="url(#paint9_linear)"/>
<path opacity="0.7" d="M103.86 82.214H70.1655C70.0373 82.214 69.9333 82.3179 69.9333 82.4461V82.8408C69.9333 82.969 70.0373 83.073 70.1655 83.073H103.86C103.988 83.073 104.092 82.969 104.092 82.8408V82.4461C104.092 82.3179 103.988 82.214 103.86 82.214Z" fill="url(#paint10_linear)"/>
<path opacity="0.7" d="M103.86 84.7908H70.1655C70.0373 84.7908 69.9333 84.8947 69.9333 85.0229V85.4176C69.9333 85.5458 70.0373 85.6497 70.1655 85.6497H103.86C103.988 85.6497 104.092 85.5458 104.092 85.4176V85.0229C104.092 84.8947 103.988 84.7908 103.86 84.7908Z" fill="url(#paint11_linear)"/>
<path opacity="0.7" d="M103.859 94.1494H58.1369C58.0087 94.1494 57.9048 94.2533 57.9048 94.3816V94.7762C57.9048 94.9044 58.0087 95.0084 58.1369 95.0084H103.859C103.988 95.0084 104.092 94.9044 104.092 94.7762V94.3816C104.092 94.2533 103.988 94.1494 103.859 94.1494Z" fill="url(#paint12_linear)"/>
<path opacity="0.7" d="M103.859 96.7263H58.1369C58.0087 96.7263 57.9048 96.8303 57.9048 96.9585V97.3531C57.9048 97.4813 58.0087 97.5853 58.1369 97.5853H103.859C103.988 97.5853 104.092 97.4813 104.092 97.3531V96.9585C104.092 96.8303 103.988 96.7263 103.859 96.7263Z" fill="url(#paint13_linear)"/>
<path opacity="0.7" d="M103.859 100.162H58.1369C58.0087 100.162 57.9048 100.266 57.9048 100.394V100.789C57.9048 100.917 58.0087 101.021 58.1369 101.021H103.859C103.988 101.021 104.092 100.917 104.092 100.789V100.394C104.092 100.266 103.988 100.162 103.859 100.162Z" fill="url(#paint14_linear)"/>
<path opacity="0.7" d="M84.3092 102.742H58.1369C58.0087 102.742 57.9048 102.846 57.9048 102.974V103.369C57.9048 103.497 58.0087 103.601 58.1369 103.601H84.3092C84.4375 103.601 84.5414 103.497 84.5414 103.369V102.974C84.5414 102.846 84.4375 102.742 84.3092 102.742Z" fill="url(#paint15_linear)"/>
<path opacity="0.7" d="M103.859 106.169H58.1369C58.0087 106.169 57.9048 106.273 57.9048 106.401V106.796C57.9048 106.924 58.0087 107.028 58.1369 107.028H103.859C103.988 107.028 104.092 106.924 104.092 106.796V106.401C104.092 106.273 103.988 106.169 103.859 106.169Z" fill="url(#paint16_linear)"/>
<path opacity="0.7" d="M103.859 108.746H58.1369C58.0087 108.746 57.9048 108.85 57.9048 108.978V109.373C57.9048 109.501 58.0087 109.605 58.1369 109.605H103.859C103.988 109.605 104.092 109.501 104.092 109.373V108.978C104.092 108.85 103.988 108.746 103.859 108.746Z" fill="url(#paint17_linear)"/>
<path opacity="0.7" d="M103.859 112.185H58.1369C58.0087 112.185 57.9048 112.289 57.9048 112.417V112.812C57.9048 112.94 58.0087 113.044 58.1369 113.044H103.859C103.988 113.044 104.092 112.94 104.092 112.812V112.417C104.092 112.289 103.988 112.185 103.859 112.185Z" fill="url(#paint18_linear)"/>
<path opacity="0.7" d="M84.3092 114.762H58.1369C58.0087 114.762 57.9048 114.866 57.9048 114.994V115.389C57.9048 115.517 58.0087 115.621 58.1369 115.621H84.3092C84.4375 115.621 84.5414 115.517 84.5414 115.389V114.994C84.5414 114.866 84.4375 114.762 84.3092 114.762Z" fill="url(#paint19_linear)"/>
<g opacity="0.5">
<path opacity="0.5" d="M112.919 85.2261H63.3776C62.6564 85.2261 62.0718 85.8107 62.0718 86.5319V132.04C62.0718 132.761 62.6564 133.346 63.3776 133.346H112.919C113.64 133.346 114.225 132.761 114.225 132.04V86.5319C114.225 85.8107 113.64 85.2261 112.919 85.2261Z" fill="url(#paint20_linear)"/>
</g>
<path opacity="0.7" d="M112.353 85.6005H64.0131C63.2919 85.6005 62.7073 86.1851 62.7073 86.9063V131.247C62.7073 131.969 63.2919 132.553 64.0131 132.553H112.353C113.074 132.553 113.659 131.969 113.659 131.247V86.9063C113.659 86.1851 113.074 85.6005 112.353 85.6005Z" fill="url(#paint21_linear)"/>
<path opacity="0.7" d="M73.9202 93.334H67.9394C67.2182 93.334 66.6335 93.9186 66.6335 94.6398V99.7617C66.6335 100.483 67.2182 101.068 67.9394 101.068H73.9202C74.6414 101.068 75.2261 100.483 75.2261 99.7617V94.6398C75.2261 93.9186 74.6414 93.334 73.9202 93.334Z" fill="url(#paint22_linear)"/>
<path opacity="0.7" d="M111.732 93.334H78.0381C77.9099 93.334 77.8059 93.4379 77.8059 93.5661V93.9608C77.8059 94.089 77.9099 94.1929 78.0381 94.1929H111.732C111.86 94.1929 111.964 94.089 111.964 93.9608V93.5661C111.964 93.4379 111.86 93.334 111.732 93.334Z" fill="url(#paint23_linear)"/>
<path opacity="0.7" d="M111.732 95.911H78.0381C77.9099 95.911 77.8059 96.0149 77.8059 96.1432V96.5378C77.8059 96.666 77.9099 96.77 78.0381 96.77H111.732C111.86 96.77 111.964 96.666 111.964 96.5378V96.1432C111.964 96.0149 111.86 95.911 111.732 95.911Z" fill="url(#paint24_linear)"/>
<path opacity="0.7" d="M111.729 105.267H66.0068C65.8786 105.267 65.7747 105.371 65.7747 105.499V105.894C65.7747 106.022 65.8786 106.126 66.0068 106.126H111.729C111.857 106.126 111.961 106.022 111.961 105.894V105.499C111.961 105.371 111.857 105.267 111.729 105.267Z" fill="url(#paint25_linear)"/>
<path opacity="0.7" d="M111.729 107.846H66.0068C65.8786 107.846 65.7747 107.95 65.7747 108.079V108.473C65.7747 108.601 65.8786 108.705 66.0068 108.705H111.729C111.857 108.705 111.961 108.601 111.961 108.473V108.079C111.961 107.95 111.857 107.846 111.729 107.846Z" fill="url(#paint26_linear)"/>
<path opacity="0.7" d="M111.729 111.282H66.0068C65.8786 111.282 65.7747 111.386 65.7747 111.514V111.909C65.7747 112.037 65.8786 112.141 66.0068 112.141H111.729C111.857 112.141 111.961 112.037 111.961 111.909V111.514C111.961 111.386 111.857 111.282 111.729 111.282Z" fill="url(#paint27_linear)"/>
<path opacity="0.7" d="M92.1791 113.859H66.0068C65.8786 113.859 65.7747 113.963 65.7747 114.091V114.486C65.7747 114.614 65.8786 114.718 66.0068 114.718H92.1791C92.3073 114.718 92.4113 114.614 92.4113 114.486V114.091C92.4113 113.963 92.3073 113.859 92.1791 113.859Z" fill="url(#paint28_linear)"/>
<path opacity="0.7" d="M111.729 117.289H66.0068C65.8786 117.289 65.7747 117.393 65.7747 117.521V117.916C65.7747 118.044 65.8786 118.148 66.0068 118.148H111.729C111.857 118.148 111.961 118.044 111.961 117.916V117.521C111.961 117.393 111.857 117.289 111.729 117.289Z" fill="url(#paint29_linear)"/>
<path opacity="0.7" d="M111.729 119.866H66.0068C65.8786 119.866 65.7747 119.97 65.7747 120.098V120.493C65.7747 120.621 65.8786 120.725 66.0068 120.725H111.729C111.857 120.725 111.961 120.621 111.961 120.493V120.098C111.961 119.97 111.857 119.866 111.729 119.866Z" fill="url(#paint30_linear)"/>
<path opacity="0.7" d="M111.729 123.305H66.0068C65.8786 123.305 65.7747 123.409 65.7747 123.537V123.932C65.7747 124.06 65.8786 124.164 66.0068 124.164H111.729C111.857 124.164 111.961 124.06 111.961 123.932V123.537C111.961 123.409 111.857 123.305 111.729 123.305Z" fill="url(#paint31_linear)"/>
<path opacity="0.7" d="M92.1791 125.882H66.0068C65.8786 125.882 65.7747 125.986 65.7747 126.114V126.509C65.7747 126.637 65.8786 126.741 66.0068 126.741H92.1791C92.3073 126.741 92.4113 126.637 92.4113 126.509V126.114C92.4113 125.986 92.3073 125.882 92.1791 125.882Z" fill="url(#paint32_linear)"/>
<g opacity="0.5">
<path opacity="0.5" d="M137.588 91.4536H92.3997V144.547H137.588V91.4536Z" fill="url(#paint33_linear)"/>
</g>
<path opacity="0.7" d="M136.628 92.3097H93.7898V143.375H136.628V92.3097Z" fill="url(#paint34_linear)"/>
<g opacity="0.5">
<path opacity="0.5" d="M117.542 100.429V99.1465H112.884V100.429H109.608V106.729H120.809V100.429H117.542ZM113.465 99.6079H116.947V100.423H113.465V99.6079Z" fill="url(#paint35_linear)"/>
</g>
<path opacity="0.7" d="M120.336 100.641H110.081V106.41H120.336V100.641Z" fill="url(#paint36_linear)"/>
<path opacity="0.7" d="M113.073 99.4657V100.963H113.607V99.8952H116.81V100.963H117.344V99.4657H113.073Z" fill="url(#paint37_linear)"/>
<path opacity="0.7" d="M130.325 114.712H100.2V115.995H130.325V114.712Z" fill="url(#paint38_linear)"/>
<path opacity="0.7" d="M130.325 123.151H100.2V124.434H130.325V123.151Z" fill="url(#paint39_linear)"/>
<path opacity="0.7" d="M130.325 125.394H100.2V126.677H130.325V125.394Z" fill="url(#paint40_linear)"/>
<path opacity="0.7" d="M112.913 120.908H100.2V122.191H112.913V120.908Z" fill="url(#paint41_linear)"/>
<g opacity="0.6">
<path opacity="0.6" d="M130.325 120.908H120.818V122.191H130.325V120.908Z" fill="url(#paint42_linear)"/>
</g>
<path opacity="0.7" d="M120.281 130.629H110.774V131.912H120.281V130.629Z" fill="url(#paint43_linear)"/>
<path opacity="0.7" d="M36.0503 106.337V203.824L106.317 221.851V125.101L36.0503 106.337Z" fill="url(#paint44_linear)"/>
<path opacity="0.7" d="M176.581 203.458L106.317 221.851V125.101L176.581 106.337V203.458Z" fill="url(#paint45_linear)"/>
<g opacity="0.07">
<path opacity="0.07" d="M176.581 203.458L106.317 221.851V125.101L176.581 106.337V203.458Z" fill="url(#paint46_linear)"/>
</g>
<g opacity="0.07">
<path opacity="0.07" d="M148.621 171.085V203.09L176.581 195.365V163.36L148.621 171.085Z" fill="url(#paint47_linear)"/>
</g>
<path opacity="0.7" d="M160.531 98.2064H139.808V118.116H160.531V98.2064Z" fill="url(#paint48_linear)"/>
<path opacity="0.7" d="M160.206 98.6938H140.133V117.71H160.206V98.6938Z" fill="url(#paint49_linear)"/>
<g opacity="0.5">
<path opacity="0.5" d="M151.944 102.028V101.059H148.395V102.034H145.908V106.828H154.431V102.028H151.944ZM148.839 101.407H151.503V102.028H148.839V101.407Z" fill="url(#paint50_linear)"/>
</g>
<g opacity="0.6">
<path opacity="0.6" d="M154.068 102.191H146.268V106.578H154.068V102.191Z" fill="url(#paint51_linear)"/>
</g>
<g opacity="0.6">
<path opacity="0.6" d="M148.543 101.294V102.434H148.952V101.622H151.389V102.434H151.796V101.294H148.543Z" fill="url(#paint52_linear)"/>
</g>
<g opacity="0.6">
<path opacity="0.6" d="M153.946 109.991H146.715V110.966H153.946V109.991Z" fill="url(#paint53_linear)"/>
</g>
<path opacity="0.7" d="M156.346 111.779H143.993V112.754H156.346V111.779Z" fill="url(#paint54_linear)"/>
<g opacity="0.5">
<path opacity="0.5" d="M117.53 37.3446H90.6819V59.3991H117.53V37.3446Z" fill="url(#paint55_linear)"/>
</g>
<path opacity="0.7" d="M117.167 37.7625H90.9951V59.0334H117.167V37.7625Z" fill="url(#paint56_linear)"/>
<path opacity="0.7" d="M117.167 37.7625H90.9951V42.5071H117.167V37.7625Z" fill="url(#paint57_linear)"/>
<g opacity="0.1">
<path opacity="0.1" d="M117.167 37.7625H90.9951V42.5071H117.167V37.7625Z" fill="url(#paint58_linear)"/>
</g>
<path opacity="0.7" d="M115.084 44.2279H93.0815V46.9905H115.084V44.2279Z" fill="url(#paint59_linear)"/>
<path opacity="0.7" d="M109.141 49.077H93.0815V50.0143H109.141V49.077Z" fill="url(#paint60_linear)"/>
<path opacity="0.7" d="M103.508 50.6935H93.0815V51.6308H103.508V50.6935Z" fill="url(#paint61_linear)"/>
<path opacity="0.7" d="M106.845 52.3097H93.0815V53.247H106.845V52.3097Z" fill="url(#paint62_linear)"/>
<path opacity="0.7" d="M109.141 53.9232H93.0815V54.8605H109.141V53.9232Z" fill="url(#paint63_linear)"/>
<path opacity="0.7" d="M98.4501 55.5396H93.0815V56.4769H98.4501V55.5396Z" fill="url(#paint64_linear)"/>
<g opacity="0.5">
<path opacity="0.5" d="M163.435 53.9058H130.156V81.2388H163.435V53.9058Z" fill="url(#paint65_linear)"/>
</g>
<path opacity="0.7" d="M162.986 54.4224H130.545V80.7862H162.986V54.4224Z" fill="url(#paint66_linear)"/>
<path opacity="0.7" d="M162.986 54.4224H130.545V60.3016H162.986V54.4224Z" fill="url(#paint67_linear)"/>
<path opacity="0.7" d="M160.397 62.4346H133.128V65.8588H160.397V62.4346Z" fill="url(#paint68_linear)"/>
<path opacity="0.7" d="M153.032 68.4445H133.128V69.6081H153.032V68.4445Z" fill="url(#paint69_linear)"/>
<path opacity="0.7" d="M146.053 70.4468H133.128V71.6104H146.053V70.4468Z" fill="url(#paint70_linear)"/>
<path opacity="0.7" d="M150.188 72.4519H133.128V73.6156H150.188V72.4519Z" fill="url(#paint71_linear)"/>
<path opacity="0.7" d="M153.032 74.4542H133.128V75.6179H153.032V74.4542Z" fill="url(#paint72_linear)"/>
<path opacity="0.7" d="M139.785 76.4565H133.128V77.6202H139.785V76.4565Z" fill="url(#paint73_linear)"/>
<path opacity="0.7" d="M142.304 63.5981H134.55V64.6312H142.304V63.5981Z" fill="url(#paint74_linear)"/>
<g opacity="0.1">
<path opacity="0.1" d="M35.1333 156.921L74.4948 166.854L106.131 141.102V125.284L35.8675 107.626L35.1333 156.921Z" fill="url(#paint75_linear)"/>
</g>
<g opacity="0.1">
<path opacity="0.1" d="M177.132 156.921L137.768 166.854L106.131 141.102V125.284L176.395 107.626L177.132 156.921Z" fill="url(#paint76_linear)"/>
</g>
<path opacity="0.7" d="M36.0504 106.337L0 136.137L70.2639 153.795L106.317 125.101L36.0504 106.337Z" fill="url(#paint77_linear)"/>
<path opacity="0.7" d="M176.581 106.337L213 136.137L143.105 153.795L106.317 125.101L176.581 106.337Z" fill="url(#paint78_linear)"/>
<g opacity="0.07">
<path opacity="0.07" d="M176.581 106.337L213 136.137L143.105 153.795L106.317 125.101L176.581 106.337Z" fill="url(#paint79_linear)"/>
</g>
</g>
<path d="M176.709 105.444L107.045 88.6649V219.968L176.709 201.732V105.444Z" fill="#BF802F"/>
<path opacity="0.1" d="M176.709 105.444L107.045 88.6649V219.968L176.709 201.732V105.444Z" fill="black"/>
<path d="M37.3853 105.444L107.046 88.6649V219.968L37.3853 202.095V105.444Z" fill="#BF802F"/>
<path opacity="0.17" d="M37.3853 104.863L107.046 88.0846V219.968L37.3853 202.095V104.863Z" fill="black"/>
<path d="M106.465 89.2453L143.154 58.7578L212.817 76.6307L176.128 106.024L106.465 89.2453Z" fill="#BF802F"/>
<path d="M2.2229 77.5738L37.9657 106.024L107.626 89.2453L72.2488 59.3383L2.2229 77.5738Z" fill="#BF802F"/>
<path opacity="0.05" d="M1.64258 76.9934L38.2559 106.024L107.916 89.2453L71.6685 58.7578L1.64258 76.9934Z" fill="black"/>
<g opacity="0.5">
<path opacity="0.5" d="M105.777 73.4879H56.6828C55.9616 73.4879 55.377 74.0726 55.377 74.7938V119.889C55.377 120.611 55.9616 121.195 56.6828 121.195H105.777C106.498 121.195 107.083 120.611 107.083 119.889V74.7938C107.083 74.0726 106.498 73.4879 105.777 73.4879Z" fill="url(#paint80_linear)"/>
</g>
<path d="M105.217 73.8594H57.3154C56.5942 73.8594 56.0095 74.444 56.0095 75.1652V119.103C56.0095 119.824 56.5942 120.409 57.3154 120.409H105.217C105.938 120.409 106.523 119.824 106.523 119.103V75.1652C106.523 74.444 105.938 73.8594 105.217 73.8594Z" fill="#F5F5F5"/>
<path d="M67.1153 81.5261H61.207C60.4858 81.5261 59.9011 82.1108 59.9011 82.832V87.8871C59.9011 88.6083 60.4858 89.193 61.207 89.193H67.1153C67.8365 89.193 68.4211 88.6083 68.4211 87.8871V82.832C68.4211 82.1108 67.8365 81.5261 67.1153 81.5261Z" fill="#247BA0"/>
<path d="M104.611 81.5261H71.2097C71.0815 81.5261 70.9775 81.6301 70.9775 81.7583V82.1471C70.9775 82.2753 71.0815 82.3793 71.2097 82.3793H104.611C104.739 82.3793 104.843 82.2753 104.843 82.1471V81.7583C104.843 81.6301 104.739 81.5261 104.611 81.5261Z" fill="#E0E0E0"/>
<path d="M104.611 84.0798H71.2097C71.0815 84.0798 70.9775 84.1838 70.9775 84.312V84.7008C70.9775 84.8291 71.0815 84.933 71.2097 84.933H104.611C104.739 84.933 104.843 84.8291 104.843 84.7008V84.312C104.843 84.1838 104.739 84.0798 104.611 84.0798Z" fill="#E0E0E0"/>
<path d="M104.611 93.3573H59.2832C59.155 93.3573 59.051 93.4612 59.051 93.5895V93.9783C59.051 94.1065 59.155 94.2105 59.2832 94.2105H104.611C104.739 94.2105 104.843 94.1065 104.843 93.9783V93.5895C104.843 93.4612 104.739 93.3573 104.611 93.3573Z" fill="#E0E0E0"/>
<path d="M104.611 95.9138H59.2832C59.155 95.9138 59.051 96.0178 59.051 96.146V96.5348C59.051 96.663 59.155 96.767 59.2832 96.767H104.611C104.739 96.767 104.843 96.663 104.843 96.5348V96.146C104.843 96.0178 104.739 95.9138 104.611 95.9138Z" fill="#E0E0E0"/>
<path d="M104.611 99.3207H59.2832C59.155 99.3207 59.051 99.4246 59.051 99.5528V99.9417C59.051 100.07 59.155 100.174 59.2832 100.174H104.611C104.739 100.174 104.843 100.07 104.843 99.9417V99.5528C104.843 99.4246 104.739 99.3207 104.611 99.3207Z" fill="#E0E0E0"/>
<path d="M85.2291 101.877H59.2832C59.155 101.877 59.051 101.981 59.051 102.109V102.498C59.051 102.627 59.155 102.73 59.2832 102.73H85.2291C85.3573 102.73 85.4613 102.627 85.4613 102.498V102.109C85.4613 101.981 85.3573 101.877 85.2291 101.877Z" fill="#E0E0E0"/>
<path d="M104.611 105.275H59.2832C59.155 105.275 59.051 105.379 59.051 105.508V105.896C59.051 106.025 59.155 106.129 59.2832 106.129H104.611C104.739 106.129 104.843 106.025 104.843 105.896V105.508C104.843 105.379 104.739 105.275 104.611 105.275Z" fill="#E0E0E0"/>
<path d="M104.611 107.832H59.2832C59.155 107.832 59.051 107.936 59.051 108.064V108.453C59.051 108.581 59.155 108.685 59.2832 108.685H104.611C104.739 108.685 104.843 108.581 104.843 108.453V108.064C104.843 107.936 104.739 107.832 104.611 107.832Z" fill="#E0E0E0"/>
<path d="M104.611 111.239H59.2832C59.155 111.239 59.051 111.343 59.051 111.471V111.86C59.051 111.988 59.155 112.092 59.2832 112.092H104.611C104.739 112.092 104.843 111.988 104.843 111.86V111.471C104.843 111.343 104.739 111.239 104.611 111.239Z" fill="#E0E0E0"/>
<path d="M85.2291 113.795H59.2832C59.155 113.795 59.051 113.899 59.051 114.028V114.416C59.051 114.545 59.155 114.649 59.2832 114.649H85.2291C85.3573 114.649 85.4613 114.545 85.4613 114.416V114.028C85.4613 113.899 85.3573 113.795 85.2291 113.795Z" fill="#E0E0E0"/>
<g opacity="0.5">
<path opacity="0.5" d="M113.581 84.5122H64.4863C63.7651 84.5122 63.1804 85.0969 63.1804 85.8181V130.914C63.1804 131.635 63.7651 132.22 64.4863 132.22H113.581C114.302 132.22 114.887 131.635 114.887 130.914V85.8181C114.887 85.0969 114.302 84.5122 113.581 84.5122Z" fill="url(#paint81_linear)"/>
</g>
<path d="M113.02 84.8837H65.1186C64.3974 84.8837 63.8127 85.4683 63.8127 86.1895V130.127C63.8127 130.849 64.3974 131.433 65.1186 131.433H113.02C113.742 131.433 114.326 130.849 114.326 130.127V86.1895C114.326 85.4683 113.742 84.8837 113.02 84.8837Z" fill="white"/>
<path d="M74.9187 92.5505H69.0104C68.2892 92.5505 67.7046 93.1352 67.7046 93.8564V98.9115C67.7046 99.6327 68.2892 100.217 69.0104 100.217H74.9187C75.6399 100.217 76.2246 99.6327 76.2246 98.9115V93.8564C76.2246 93.1352 75.6399 92.5505 74.9187 92.5505Z" fill="#247BA0"/>
<path d="M112.414 92.5505H79.0132C78.8849 92.5505 78.781 92.6545 78.781 92.7827V93.1715C78.781 93.2998 78.8849 93.4037 79.0132 93.4037H112.414C112.542 93.4037 112.646 93.2998 112.646 93.1715V92.7827C112.646 92.6545 112.542 92.5505 112.414 92.5505Z" fill="#E0E0E0"/>
<path d="M112.414 95.1042H79.0132C78.8849 95.1042 78.781 95.2082 78.781 95.3364V95.7253C78.781 95.8535 78.8849 95.9574 79.0132 95.9574H112.414C112.542 95.9574 112.646 95.8535 112.646 95.7253V95.3364C112.646 95.2082 112.542 95.1042 112.414 95.1042Z" fill="#E0E0E0"/>
<path d="M112.414 104.382H67.0862C66.9579 104.382 66.854 104.486 66.854 104.614V105.003C66.854 105.131 66.9579 105.235 67.0862 105.235H112.414C112.542 105.235 112.646 105.131 112.646 105.003V104.614C112.646 104.486 112.542 104.382 112.414 104.382Z" fill="#E0E0E0"/>
<path d="M112.414 106.938H67.0862C66.9579 106.938 66.854 107.042 66.854 107.17V107.559C66.854 107.687 66.9579 107.791 67.0862 107.791H112.414C112.542 107.791 112.646 107.687 112.646 107.559V107.17C112.646 107.042 112.542 106.938 112.414 106.938Z" fill="#E0E0E0"/>
<path d="M112.414 110.345H67.0862C66.9579 110.345 66.854 110.449 66.854 110.577V110.966C66.854 111.094 66.9579 111.198 67.0862 111.198H112.414C112.542 111.198 112.646 111.094 112.646 110.966V110.577C112.646 110.449 112.542 110.345 112.414 110.345Z" fill="#E0E0E0"/>
<path d="M93.0321 112.902H67.0862C66.9579 112.902 66.854 113.006 66.854 113.134V113.523C66.854 113.651 66.9579 113.755 67.0862 113.755H93.0321C93.1603 113.755 93.2643 113.651 93.2643 113.523V113.134C93.2643 113.006 93.1603 112.902 93.0321 112.902Z" fill="#E0E0E0"/>
<path d="M112.414 116.3H67.0862C66.9579 116.3 66.854 116.404 66.854 116.532V116.921C66.854 117.049 66.9579 117.153 67.0862 117.153H112.414C112.542 117.153 112.646 117.049 112.646 116.921V116.532C112.646 116.404 112.542 116.3 112.414 116.3Z" fill="#E0E0E0"/>
<path d="M112.414 118.856H67.0862C66.9579 118.856 66.854 118.96 66.854 119.088V119.477C66.854 119.606 66.9579 119.709 67.0862 119.709H112.414C112.542 119.709 112.646 119.606 112.646 119.477V119.088C112.646 118.96 112.542 118.856 112.414 118.856Z" fill="#E0E0E0"/>
<path d="M112.414 122.263H67.0862C66.9579 122.263 66.854 122.367 66.854 122.495V122.884C66.854 123.012 66.9579 123.116 67.0862 123.116H112.414C112.542 123.116 112.646 123.012 112.646 122.884V122.495C112.646 122.367 112.542 122.263 112.414 122.263Z" fill="#E0E0E0"/>
<path d="M93.0321 124.82H67.0862C66.9579 124.82 66.854 124.924 66.854 125.052V125.441C66.854 125.569 66.9579 125.673 67.0862 125.673H93.0321C93.1603 125.673 93.2643 125.569 93.2643 125.441V125.052C93.2643 124.924 93.1603 124.82 93.0321 124.82Z" fill="#E0E0E0"/>
<g opacity="0.5">
<path opacity="0.5" d="M138.052 90.6875H93.2498V143.325H138.052V90.6875Z" fill="url(#paint82_linear)"/>
</g>
<path d="M137.1 91.5349H94.6282V142.162H137.1V91.5349Z" fill="white"/>
<g opacity="0.5">
<path opacity="0.5" d="M118.177 99.5847V98.3108H113.549V99.5847H110.31V105.833H121.416V99.5847H118.177ZM114.114 98.7751H117.597V99.5847H114.114V98.7751Z" fill="url(#paint83_linear)"/>
</g>
<path d="M120.949 99.7965H110.78V105.516H120.949V99.7965Z" fill="#247BA0"/>
<path d="M113.746 98.6301V100.113H114.274V99.0538H117.452V100.113H117.983V98.6301H113.746Z" fill="#247BA0"/>
<path d="M130.85 113.746H100.983V115.017H130.85V113.746Z" fill="#E0E0E0"/>
<path d="M130.85 122.112H100.983V123.383H130.85V122.112Z" fill="#E0E0E0"/>
<path d="M130.85 124.338H100.983V125.609H130.85V124.338Z" fill="#E0E0E0"/>
<path d="M113.586 119.889H100.983V121.16H113.586V119.889Z" fill="#E0E0E0"/>
<path opacity="0.6" d="M130.85 119.889H121.425V121.16H130.85V119.889Z" fill="#3AD29F"/>
<path d="M120.893 129.527H111.468V130.798H120.893V129.527Z" fill="#247BA0"/>
<path d="M37.3853 105.444V202.095L107.046 219.968L107.986 124.045L37.3853 105.444Z" fill="#BF802F"/>
<path d="M176.709 201.732L107.045 219.968V124.045L176.709 105.444V201.732Z" fill="#BF802F"/>
<path opacity="0.07" d="M176.709 201.732L107.045 219.968V124.045L176.709 105.444V201.732Z" fill="black"/>
<path opacity="0.07" d="M148.99 169.637V201.366L176.709 193.708V161.976L148.99 169.637Z" fill="black"/>
<path d="M160.797 97.3822H140.252V117.121H160.797V97.3822Z" fill="url(#paint84_linear)"/>
<path d="M160.475 97.8639H140.574V116.718H160.475V97.8639Z" fill="#F5F5F5"/>
<g opacity="0.5">
<path opacity="0.5" d="M152.286 101.169V100.188H148.766V101.157H146.302V105.911H154.75V101.169H152.286ZM149.207 100.554H151.848V101.169H149.207V100.554Z" fill="url(#paint85_linear)"/>
</g>
<path opacity="0.6" d="M154.39 101.329H146.656V105.679H154.39V101.329Z" fill="#247BA0"/>
<path opacity="0.6" d="M148.914 100.444V101.573H149.318V100.766H151.735V101.573H152.135V100.444H148.914Z" fill="#247BA0"/>
<g opacity="0.6">
<path opacity="0.6" d="M154.271 109.065H147.101V110.032H154.271V109.065Z" fill="#247BA0"/>
</g>
<path d="M156.648 110.838H144.402V111.805H156.648V110.838Z" fill="#BDBDBD"/>
<path d="M141.102 0H120.557V19.7388H141.102V0Z" fill="url(#paint86_linear)"/>
<path d="M140.78 0.484619H120.879V19.3383H140.78V0.484619Z" fill="#F5F5F5"/>
<g opacity="0.5">
<path opacity="0.5" d="M132.591 3.78689V2.82056H129.071V3.78689H126.607V8.54022H135.055V3.78689H132.591ZM129.512 3.17169H132.153V3.78689H129.512V3.17169Z" fill="url(#paint87_linear)"/>
</g>
<path opacity="0.6" d="M134.695 3.94653H126.961V8.29649H134.695V3.94653Z" fill="#247BA0"/>
<path opacity="0.6" d="M129.219 3.06152V4.19037H129.623V3.38364H132.04V4.19037H132.44V3.06152H129.219Z" fill="#247BA0"/>
<g opacity="0.6">
<path opacity="0.6" d="M134.576 11.683H127.405V12.6493H134.576V11.683Z" fill="#247BA0"/>
</g>
<path d="M136.953 13.4562H124.707V14.4225H136.953V13.4562Z" fill="#BDBDBD"/>
<g opacity="0.5">
<path opacity="0.5" d="M118.166 37.0399H91.5466V58.9058H118.166V37.0399Z" fill="url(#paint88_linear)"/>
</g>
<path d="M117.806 37.455H91.8569V58.5431H117.806V37.455Z" fill="#F2F2F2"/>
<path d="M117.806 37.455H91.8569V42.1589H117.806V37.455Z" fill="#247BA0"/>
<path opacity="0.1" d="M117.806 37.455H91.8569V42.1589H117.806V37.455Z" fill="black"/>
<path d="M115.74 43.8652H93.926V46.6046H115.74V43.8652Z" fill="#E0E0E0"/>
<path d="M109.846 48.6708H93.926V49.6023H109.846V48.6708Z" fill="#E0E0E0"/>
<path d="M104.263 50.2726H93.926V51.2041H104.263V50.2726Z" fill="#E0E0E0"/>
<path d="M107.571 51.8745H93.926V52.806H107.571V51.8745Z" fill="#E0E0E0"/>
<path d="M109.846 53.4794H93.926V54.4109H109.846V53.4794Z" fill="#E0E0E0"/>
<path d="M99.251 55.0811H93.926V56.0126H99.251V55.0811Z" fill="#E0E0E0"/>
<g opacity="0.5">
<path opacity="0.5" d="M163.676 53.459H130.682V80.5599H163.676V53.459Z" fill="url(#paint89_linear)"/>
</g>
<path d="M163.229 53.9697H131.068V80.1101H163.229V53.9697Z" fill="white"/>
<path d="M163.229 53.9697H131.068V59.7997H163.229V53.9697Z" fill="#247BA0"/>
<path d="M160.667 61.915H133.63V65.3103H160.667V61.915Z" fill="#F5F5F5"/>
<path d="M153.363 67.8728H133.63V69.0249H153.363V67.8728Z" fill="#E0E0E0"/>
<path d="M146.445 69.8605H133.63V71.0125H146.445V69.8605Z" fill="#E0E0E0"/>
<path d="M150.542 71.8455H133.63V72.9975H150.542V71.8455Z" fill="#E0E0E0"/>
<path d="M153.363 73.8331H133.63V74.9852H153.363V73.8331Z" fill="#E0E0E0"/>
<path d="M140.229 75.8181H133.63V76.9702H140.229V75.8181Z" fill="#E0E0E0"/>
<path d="M142.727 63.0671H135.04V64.0915H142.727V63.0671Z" fill="#3BB9C4"/>
<path opacity="0.1" d="M36.4739 155.594L75.4987 165.441L106.865 139.91V124.228L37.2023 106.721L36.4739 155.594Z" fill="black"/>
<path opacity="0.1" d="M177.257 155.594L138.232 165.441L106.866 139.91V124.228L176.529 106.721L177.257 155.594Z" fill="black"/>
<path d="M37.3854 105.444L1.64258 134.985L71.3029 152.492L107.046 124.045L37.3854 105.444Z" fill="#BF802F"/>
<path d="M176.709 105.444L212.817 134.985L143.519 152.492L107.045 124.045L176.709 105.444Z" fill="#BF802F"/>
<path opacity="0.07" d="M176.709 105.444L212.817 134.985L143.519 152.492L107.045 124.045L176.709 105.444Z" fill="black"/>
<defs>
<linearGradient id="paint0_linear" x1="106.317" y1="155.635" x2="176.581" y2="155.635" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="106.317" y1="155.635" x2="176.581" y2="155.635" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint2_linear" x1="8765.29" y1="71116.6" x2="25779.7" y2="71116.6" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint3_linear" x1="8765.29" y1="71116.6" x2="25779.7" y2="71116.6" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint4_linear" x1="39191.7" y1="13493.5" x2="78411.5" y2="13493.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint5_linear" x1="0" y1="13493.5" x2="38951.4" y2="13493.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint6_linear" x1="0" y1="13493.5" x2="38951.4" y2="13493.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint7_linear" x1="14481.8" y1="20341.5" x2="14481.8" y2="12362.8" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint8_linear" x1="9683.18" y1="15923.9" x2="18629.3" y2="15923.9" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint9_linear" x1="1798.75" y1="2376.23" x2="2053.18" y2="2376.23" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint10_linear" x1="8301.75" y1="326.838" x2="12322.5" y2="326.838" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint11_linear" x1="8301.75" y1="337.042" x2="12322.5" y2="337.042" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint12_linear" x1="9274.01" y1="374.102" x2="16625.1" y2="374.102" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint13_linear" x1="9274.01" y1="384.307" x2="16625.1" y2="384.307" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint14_linear" x1="9274.01" y1="397.913" x2="16625.1" y2="397.913" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint15_linear" x1="5372.97" y1="408.132" x2="7817.95" y2="408.132" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint16_linear" x1="9274.01" y1="421.704" x2="16625.1" y2="421.704" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint17_linear" x1="9274.01" y1="431.908" x2="16625.1" y2="431.908" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint18_linear" x1="9274.01" y1="445.522" x2="16625.1" y2="445.522" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint19_linear" x1="5372.97" y1="455.727" x2="7817.95" y2="455.727" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint20_linear" x1="15904.1" y1="22196.6" x2="15904.1" y2="14217.4" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint21_linear" x1="11072.9" y1="17734.2" x2="20019" y2="17734.2" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint22_linear" x1="2039.66" y1="2683.7" x2="2294.08" y2="2683.7" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint23_linear" x1="9236.34" y1="370.873" x2="13257.1" y2="370.873" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint24_linear" x1="9236.34" y1="381.078" x2="13257.1" y2="381.078" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint25_linear" x1="10534.5" y1="418.127" x2="17886" y2="418.127" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint26_linear" x1="10534.5" y1="428.342" x2="17886" y2="428.342" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint27_linear" x1="10534.5" y1="441.948" x2="17886" y2="441.948" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint28_linear" x1="6103.23" y1="452.156" x2="8548.2" y2="452.156" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint29_linear" x1="10534.5" y1="465.739" x2="17886" y2="465.739" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint30_linear" x1="10534.5" y1="475.94" x2="17886" y2="475.94" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint31_linear" x1="10534.5" y1="489.558" x2="17886" y2="489.558" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint32_linear" x1="6103.23" y1="499.762" x2="8548.2" y2="499.762" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint33_linear" x1="17999.2" y1="26537.8" x2="17999.2" y2="16823.8" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint34_linear" x1="13939" y1="20828.7" x2="20262.8" y2="20828.7" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint35_linear" x1="7166.57" y1="3401.71" x2="7166.57" y2="3203.57" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint36_linear" x1="4000.34" y1="2158.73" x2="4362.77" y2="2158.73" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint37_linear" x1="1777.5" y1="616.573" x2="1840.38" y2="616.573" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint38_linear" x1="10502" y1="624.577" x2="13629.2" y2="624.577" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint39_linear" x1="10502" y1="670.312" x2="13629.2" y2="670.312" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint40_linear" x1="10502" y1="682.474" x2="13629.2" y2="682.474" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint41_linear" x1="4489.96" y1="658.157" x2="5046.93" y2="658.157" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint42_linear" x1="4078.82" y1="658.157" x2="4390.25" y2="658.157" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint43_linear" x1="3739.75" y1="710.848" x2="4051.28" y2="710.848" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint44_linear" x1="8765.29" y1="65425.7" x2="25779.7" y2="65425.7" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint45_linear" x1="25848.9" y1="65425.7" x2="42861.9" y2="65425.7" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint46_linear" x1="25848.9" y1="65425.7" x2="42861.9" y2="65425.7" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint47_linear" x1="14468.3" y1="25248.5" x2="17162.2" y2="25248.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint48_linear" x1="10863.5" y1="8202.17" x2="10863.5" y2="6836.14" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint49_linear" x1="9833.14" y1="7189.26" x2="11221.6" y2="7189.26" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint50_linear" x1="6542.26" y1="2615.53" x2="6542.26" y2="2500.79" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint51_linear" x1="4077.94" y1="1680.48" x2="4287.69" y2="1680.48" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint52_linear" x1="1813.74" y1="501.625" x2="1850.18" y2="501.625" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint53_linear" x1="3802.85" y1="481.201" x2="3983.13" y2="481.201" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint54_linear" x1="6273.76" y1="488.991" x2="6799.65" y2="488.991" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint55_linear" x1="9722.83" y1="4551.68" x2="9722.83" y2="2875.54" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint56_linear" x1="8297.84" y1="3585.34" x2="10658.3" y2="3585.34" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint57_linear" x1="8297.84" y1="693.943" x2="10658.3" y2="693.943" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint58_linear" x1="8297.84" y1="693.943" x2="10658.3" y2="693.943" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint59_linear" x1="7150.52" y1="478.428" x2="8818.52" y2="478.428" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint60_linear" x1="5244.22" y1="209.106" x2="6132.77" y2="209.106" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint61_linear" x1="3437.5" y1="215.942" x2="3812.13" y2="215.942" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint62_linear" x1="4507.94" y1="222.78" x2="5160.75" y2="222.78" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint63_linear" x1="5244.22" y1="229.614" x2="6132.77" y2="229.614" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint64_linear" x1="1815.09" y1="236.451" x2="1914.41" y2="236.451" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint65_linear" x1="16964.7" y1="7705.8" x2="16964.7" y2="5131.3" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint66_linear" x1="14724.2" y1="6196.14" x2="18350.4" y2="6196.14" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint67_linear" x1="14724.2" y1="1216.58" x2="18350.4" y2="1216.58" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint68_linear" x1="12643.2" y1="819.365" x2="15205.6" y2="819.365" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint69_linear" x1="9264.37" y1="345.233" x2="10629.6" y2="345.233" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint70_linear" x1="6062.64" y1="355.278" x2="6638.33" y2="355.278" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint71_linear" x1="7959.71" y1="365.311" x2="8962.69" y2="365.311" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint72_linear" x1="9264.37" y1="375.342" x2="10629.6" y2="375.342" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint73_linear" x1="3187.08" y1="385.387" x2="3339.79" y2="385.387" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint74_linear" x1="3729.72" y1="291.846" x2="3936.91" y2="291.846" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint75_linear" x1="8630.87" y1="28118.3" x2="26001.3" y2="28118.3" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint76_linear" x1="26073.3" y1="28118.3" x2="43445.1" y2="28118.3" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint77_linear" x1="0" y1="21377.4" x2="38951.4" y2="21377.4" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint78_linear" x1="39191.7" y1="21377.4" x2="78411.5" y2="21377.4" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint79_linear" x1="39191.7" y1="21377.4" x2="78411.5" y2="21377.4" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint80_linear" x1="14529" y1="19998" x2="14529" y2="12154.9" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint81_linear" x1="15927.7" y1="21821.4" x2="15927.7" y2="13978.3" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint82_linear" x1="17948.9" y1="26088.4" x2="17948.9" y2="16540.5" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint83_linear" x1="7132.05" y1="3351.09" x2="7132.05" y2="3156.13" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint84_linear" x1="10797.4" y1="8063.95" x2="10797.4" y2="6721.32" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint85_linear" x1="6496.35" y1="2576.68" x2="6496.35" y2="2463.89" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint86_linear" x1="9383.29" y1="1342.63" x2="9383.29" y2="0" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint87_linear" x1="5903.32" y1="558.657" x2="5903.32" y2="445.923" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint88_linear" x1="9710.13" y1="4475.59" x2="9710.13" y2="2828" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint89_linear" x1="16864.9" y1="7576.94" x2="16864.9" y2="5045.99" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
</defs>
</svg>

	);
}

export default AmmoniteExistingPensions;
