import React from 'react';

function AmmoniteCross({}) {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="12" cy="12" r="10" fill="#BF802F" stroke="#BF802F" strokeWidth="4" />
			<path
				d="M15.182 7.87461C14.9867 7.67935 14.6701 7.67935 14.4749 7.87461L12 10.3495L9.52513 7.87461C9.32986 7.67935 9.01328 7.67935 8.81802 7.87461L7.87521 8.81742C7.67995 9.01268 7.67995 9.32926 7.87521 9.52452L10.3501 11.9994L7.87521 14.4743C7.67995 14.6695 7.67995 14.9861 7.87521 15.1814L8.81802 16.1242C9.01328 16.3194 9.32986 16.3194 9.52513 16.1242L12 13.6493L14.4749 16.1242C14.6701 16.3194 14.9867 16.3194 15.182 16.1242L16.1248 15.1814C16.3201 14.9861 16.3201 14.6695 16.1248 14.4743L13.6499 11.9994L16.1248 9.52452C16.3201 9.32926 16.3201 9.01268 16.1248 8.81742L15.182 7.87461Z"
				fill="white"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default AmmoniteCross;
