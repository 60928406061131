//Contains all functions that write extra html as an email parameter

export const importantThingsToNote = (
  likelihood,
  anticipatedAssets,
  areasOfInterest,
  interestComments,
  recommend,
  referralDetails,
  coachName
) => {
  let htmlString = "";
  if (likelihood !== undefined) {
    let _likelihood;
    switch (likelihood) {
      case 0:
        _likelihood = "Very Likely";
        break;
      case 1:
        _likelihood = "Likely";
        break;
      case 2:
        _likelihood = "Unlikely";
        break;
      case 3:
        _likelihood = "Very Unlikely";
        break;
      case 4:
        _likelihood = "Not Sure";
        break;
    }
    htmlString += ` <tr>
        <td style="padding: 0px 0px 0px 15px;"><img src="https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/personIcon.png?alt=media&amp;token=6d51c5ed-3b8f-480a-9772-675823fa3735" alt="person icon" /></td>
        <td style="padding: 15px;">This customer is <strong>${_likelihood}</strong> to proceed to advice</td>
        </tr>`;
  }
  if (anticipatedAssets !== undefined) {
    let _anticipatedAssets;
    switch (anticipatedAssets) {
      case 0:
        _anticipatedAssets = "<£50,000";
        break;
      case 1:
        _anticipatedAssets = "£50,000-£100,000";
        break;
      case 2:
        _anticipatedAssets = "£100,000-£250,000";
        break;
      case 3:
        _anticipatedAssets = ">£250,000";
        break;
    }
    htmlString += `<tr>
        <td style="padding: 0px 0px 0px 15px;"><img src="https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/personIcon.png?alt=media&amp;token=6d51c5ed-3b8f-480a-9772-675823fa3735" alt="person icon" /></td>
        <td style="padding: 15px;">This customer is likely to have <strong>${_anticipatedAssets}</strong> to invest</td>
        </tr>`;
  }
  if (areasOfInterest !== undefined) {
    let _areasOfInterest = [];
    if (areasOfInterest[0] === true) {
      _areasOfInterest.push("Pensions");
    }
    if (areasOfInterest[1] === true) {
      _areasOfInterest.push("Investments");
    }
    if (areasOfInterest[2] === true) {
      _areasOfInterest.push("Regular Contributions");
    }
    if (areasOfInterest[3] === true) {
      _areasOfInterest.push("Protection");
    }
    if (areasOfInterest[4] === true) {
      _areasOfInterest.push("Mortgage");
    }
    if (areasOfInterest[5] === true) {
      _areasOfInterest.push("Will");
    }
    if (areasOfInterest[6] === true) {
      _areasOfInterest.push("Inheritance Planning");
    }
    if (_areasOfInterest.length > 0) {
      let areasOfInterestString = "";
      _areasOfInterest.forEach((interest, index) => {
        if (index === 0) {
          areasOfInterestString += interest;
        } else if (index + 1 === _areasOfInterest.length) {
          areasOfInterestString += `, and ${interest}`;
        } else {
          areasOfInterestString += `, ${interest}`;
        }
      });
      htmlString += `<tr>
               <td style="padding: 0px 0px 0px 15px;"><img src="https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/personIcon.png?alt=media&amp;token=6d51c5ed-3b8f-480a-9772-675823fa3735" alt="person icon" /></td>
               <td style="padding: 15px;">This customer is likely to be most interested in <strong>${areasOfInterestString}</strong></td>
               </tr>`;
    }
  }
  if (interestComments !== undefined) {
    htmlString += `<tr>
           <td>&nbsp;</td>
           <td style="padding: 15px;"><strong>Comments on areas of interest</strong></td>
           </tr>
           <tr>
           <td>&nbsp;</td>
           <td style="padding: 15px;">${coachName} noted the following about areas of interest:</td>
           </tr>
           <tr>
           <td>&nbsp;</td>
           <td style="padding: 15px;">${interestComments}</td>
           </tr>`;
  }
  if (recommend !== undefined) {
    let recommendText;
    switch (recommend) {
      case 0:
        recommendText = "would recommend to friends and family";
        break;
      case 1:
        recommendText = "would not recommend to friends and family";
        break;
      case 2:
        recommendText =
          "is not sure if they would recommend to friends and family";
        break;
    }
    htmlString += ` <tr>
           <td style="padding: 0px 0px 0px 15px;"><img src="https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/personIcon.png?alt=media&amp;token=6d51c5ed-3b8f-480a-9772-675823fa3735" alt="person icon" /></td>
           <td style="padding: 15px;">This customer <strong>${recommendText}</strong></td>
           </tr>`;
  }
  if (referralDetails !== undefined) {
    htmlString += ` <tr>
           <td>&nbsp;</td>
           <td style="padding: 15px;"><strong>Details of any referrals</strong></td>
           </tr>
           <tr>
           <td>&nbsp;</td>
           <td style="padding: 15px;">${referralDetails}</td>
           </tr>`;
  }
  return `<table style="font-family: Arial; font-style: normal; font-weight: normal; font-size: 16px; line-height: 22px; color: #0c3957;">${htmlString}</table>`;
};

export const areasForAdvice = (financialCheck, hasChildren, workSituation) => {
  let htmlString = "";
  if (!financialCheck.will) {
    htmlString += `<tr>
        <td>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/starIcon.png?alt=media&token=63db188d-2f7b-40f9-b5cc-35b7fa9c45c1"
          alt="star icon"
        />
        </td>
        <td style="padding: 15px; font-family: Arial; font-style: normal; font-weight: normal; font-size: 16px; line-height: 22px; color: #0c3957;">
       This customer <strong> does not have a Will </strong>
       </td>
      </tr>`;
  }
  if (!financialCheck.emergencyCash) {
    htmlString += `<tr>
        <td>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/starIcon.png?alt=media&token=63db188d-2f7b-40f9-b5cc-35b7fa9c45c1"
          alt="star icon"
        />
        </td>
        <td style="padding: 15px; font-family: Arial; font-style: normal; font-weight: normal; font-size: 16px; line-height: 22px; color: #0c3957;">
        This customer <strong> does not have Emergency Cash </strong>
        </td>
      </tr>`;
  }
  if (financialCheck.shortTermDebt) {
    htmlString += `<tr>
        <td>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/starIcon.png?alt=media&token=63db188d-2f7b-40f9-b5cc-35b7fa9c45c1"
          alt="star icon"
        />
        </td>
        <td style="padding: 15px; font-family: Arial; font-style: normal; font-weight: normal; font-size: 16px; line-height: 22px; color: #0c3957;">
        This customer <strong> has issues paying their monthly bills </strong>
        </td>
      </tr>`;
  }
  if (
    financialCheck.hasMortgage &&
    financialCheck.timeUntilMortgageDealEnds < 2
  ) {
    htmlString += `<tr>
        <td>
    <img
      src="https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/starIcon.png?alt=media&token=63db188d-2f7b-40f9-b5cc-35b7fa9c45c1"
      alt="star icon"
    />
    </td>
    <td style="padding: 15px; font-family: Arial; font-style: normal; font-weight: normal; font-size: 16px; line-height: 22px; color: #0c3957;">
    This customer's <strong> mortgage deal ends in the next 6 months </strong>
    </td>
    </tr>`;
  }
  if (!financialCheck.lifeCover) {
    htmlString += `<tr>
        <td>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/starIcon.png?alt=media&token=63db188d-2f7b-40f9-b5cc-35b7fa9c45c1"
          alt="star icon"
        />
        </td>
        <td style="padding: 15px; font-family: Arial; font-style: normal; font-weight: normal; font-size: 16px; line-height: 22px; color: #0c3957;">
        This customer has <strong> no life cover${
          workSituation === 1 ? ", but is self-employed" : ""
        }${financialCheck.hasMortgage ? ", has a mortgage" : ""}${
      hasChildren ? " and has children" : ""
    } </strong>
     </td>
        </tr>`;
  }
  if (!financialCheck.incomeProtection) {
    htmlString += `<tr>
        <td>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/starIcon.png?alt=media&token=63db188d-2f7b-40f9-b5cc-35b7fa9c45c1"
              alt="star icon"
            />
            </td>
            <td style="padding: 15px; font-family: Arial; font-style: normal; font-weight: normal; font-size: 16px; line-height: 22px; color: #0c3957;">
            This customer has <strong> no income protection${
              workSituation === 1 ? ", but is self-employed" : ""
            }${financialCheck.hasMortgage ? ", has a mortgage" : ""}${
      hasChildren ? "and has children" : ""
    }</strong>
         </td>
            </tr>`;
  }
  if (financialCheck.prevPensions) {
    htmlString += `<tr>
        <td>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/starIcon.png?alt=media&token=63db188d-2f7b-40f9-b5cc-35b7fa9c45c1"
          alt="star icon"
        />
        </td>
        <td style="padding: 15px; font-family: Arial; font-style: normal; font-weight: normal; font-size: 16px; line-height: 22px; color: #0c3957;">
        This customer has <strong> ${financialCheck.noOfPrevPensions} previous pensions </strong>
       </td>
        </tr>`;
  }
  if (financialCheck.environmentPriority) {
    htmlString += `<tr>
        <td>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/starIcon.png?alt=media&token=63db188d-2f7b-40f9-b5cc-35b7fa9c45c1"
          alt="star icon"
        />
        </td>
        <td style=" padding: 15px; font-family: Arial; font-style: normal; font-weight: normal; font-size: 16px; line-height: 22px; color: #0c3957;">
        This customer <strong> wants to invest environmentally </strong>
        </td>
        </tr>`;
  }
  return `<table>${htmlString}</table>`;
};

export const financialHealthCheckResults = (financialCheck) => {
  const tickLink =
    "https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/circleTick.png?alt=media&token=5160b286-0e0a-4002-8ccb-330b056973a9";
  const crossLink =
    "https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/circleCross.png?alt=media&token=5da9343f-74d0-4cfa-b390-a16d595e2b5f";

  let mortgageTerm;
  switch (financialCheck.timeUntilMortgageDealEnds) {
    case 1:
      mortgageTerm = "Less than 6 months";
      break;
    case 2:
      mortgageTerm = "6 to 12 months";
      break;
    case 3:
      mortgageTerm = "More than 12 months";
      break;
  }

  return `<table style="font-family: Arial; font-style: normal; font-weight: normal; font-size: 16px; line-height: 22px; color: #0c3957;">
  <tr>
  <td style="padding: 0px 0px 0px 15px">
  <img
  src=${financialCheck.will ? tickLink : crossLink}
  alt="circle icon"
  />
  </td>
  <td style="padding: 15px">
  <strong>Will - ${financialCheck.will ? "Yes" : "No"}</strong>
  </td>
  </tr>
<tr>
<td style="padding: 0px 0px 0px 15px">
<img
src=${financialCheck.emergencyCash ? tickLink : crossLink}
alt="circle icon"
/>
</td>
<td style="padding: 15px">
<strong>Emergency Cash - ${financialCheck.emergencyCash ? "Yes" : "No"}</strong>
</td>
</tr>
<tr>
<td style="padding: 0px 0px 0px 15px">
<img
  src=${financialCheck.shortTermDebt ? tickLink : crossLink}
  alt="circle icon"
/>
</td>
<td style="padding: 15px">
<strong>Issues paying monthly bills - ${
    financialCheck.shortTermDebt ? "Yes" : "No"
  }</strong>
</td>
</tr>
<tr>
<td style="padding: 0px 0px 0px 15px">
<img
    src=${financialCheck.hasMortgage ? tickLink : crossLink}
    alt="circle icon"
  />
</td>
<td style="padding: 15px">
<strong>Mortgage - ${
    financialCheck.hasMortgage ? `Yes - Term: ${mortgageTerm}` : "No"
  }</strong>
</td>
</tr>
<tr>
<td style="padding: 0px 0px 0px 15px">
<img
  src=${financialCheck.lifeCover ? tickLink : crossLink}
  alt="circle icon"
/>
</td>
<td style="padding: 15px">
<strong>Life cover - ${financialCheck.lifeCover ? "Yes" : "No"}</strong>
</td>
</tr>
<tr>
<td style="padding: 0px 0px 0px 15px">
<img
  
    src=${financialCheck.incomeProtection ? tickLink : crossLink}
    alt="circle icon"
  />
</td>
<td style="padding: 15px">
<strong>Income Protection - ${
    financialCheck.incomeProtection ? "Yes" : "No"
  }</strong>
</td>
</tr>
<tr>
<td style="padding: 0px 0px 0px 15px">
<img

  src=${financialCheck.prevPensions ? tickLink : crossLink}
  alt="circle icon"
/>
</td>
<td style="padding: 15px">
<strong>Previous Pensions - ${
    financialCheck.prevPensions
      ? `Yes - Number: ${financialCheck.noOfPrevPensions}`
      : "No"
  }</strong>
</td>
</tr>
<tr>
<td style="padding: 0px 0px 0px 15px">
<img
  
    src=${financialCheck.knowsStatePensionAge ? tickLink : crossLink}
    alt="circle icon"
  />
</td>
<td style="padding: 15px">
<strong>Knows State Pension Age - ${
    financialCheck.knowsStatePensionAge ? "Yes" : "No"
  }</strong>
</td>
</tr>
<tr>
<td style="padding: 0px 0px 0px 15px">
<img

  src=${financialCheck.environmentPriority ? tickLink : crossLink}
  alt="circle icon"
/>
</td>
<td style="padding: 15px">
<strong>Environmental Investor - ${
    financialCheck.environmentPriority ? "Yes" : "No"
  }</strong>
</td>
</tr>
</table>
`;
};

const getGoalHtml = (goal) => {
  let goalColour;
  let goalIconSource;
  let goalPriority;
  switch (goal.goalType) {
    case "Retirement pot":
      goalColour = "#BF802F";
      break;
    case "Education":
      goalColour = "#69306D";
      break;
    case "Property":
      goalColour = "#247BA0";
      break;
    case "Family":
      goalColour = "#3F762C";
      break;
    case "Long Term Investment":
      goalColour = "#373986";
      break;
    case "Care Fees":
      goalColour = "#0C3957";
      break;
    case "Other":
      goalColour = "#F25F5C";
      break;
    default:
      goalColour = "#69306D";
  }

  switch (goal.goalType) {
    case "Retirement pot":
      goalIconSource =
        "https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/retirementIcon.png?alt=media&token=92f61a32-a7b3-4883-92a9-61263fb27b01";
      break;
    case "Education":
      goalIconSource =
        "https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/educationIcon.png?alt=media&token=9dc5ad48-465f-4c0b-99fb-d05571f0ab4c";
      break;
    case "Property":
      goalIconSource =
        "https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/propertyIcon.png?alt=media&token=afc7786c-539d-43e9-81f5-64c47695465b";
      break;
    case "Family":
      goalIconSource =
        "https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/familyIcon.png?alt=media&token=5ece33b5-e2d2-4842-b82c-77a695efcc4d";
      break;
    case "Long Term Investment":
      goalIconSource =
        "https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/investingIcon.png?alt=media&token=31e89a65-a0bd-43ee-8584-5fdcfcc1bd63";
      break;
    case "Care Fees":
      goalIconSource =
        "https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/careIcon.png?alt=media&token=91b1600d-8fa3-483b-9d8e-237534ab621e";
      break;
    case "Other":
      goalIconSource =
        "https://firebasestorage.googleapis.com/v0/b/ammonite-testing-images/o/otherIcon.png?alt=media&token=8079913f-2a71-46bc-92d5-181be96199b2";
      break;
  }

  switch (goal.goalImportance) {
    case 0:
      goalPriority = "Nice to have";
      break;
    case 1:
      goalPriority = "Important";
      break;
    case 2:
      goalPriority = "Primary";
      break;
  }

  let _goalHtml = `<table style="border: 2px outset; border-spacing: 0; background: #ffffff; border-radius: 10px; width: 400px; height: 40px;">
      <tr>
      <td style=" background: ${goalColour}; border-radius: 8px 0px 0px 8px; width: 55px;">
      &ensp;
      <img
          src=${goalIconSource}
          alt="goal icon"
        />
        </td>
        <td style="padding: 10px; font-family: Arial; font-style: normal; font-weight: bold; font-size: 16px; line-height: 22px; color: #0c3957;">
        ${goal.goalType}
      </td>
      </tr>
      </table>
      <p style="padding: 15px 30px 0px 0px; font-family: Arial; font-style: normal; font-weight: normal; font-size: 16px; line-height: 22px; color: #0c3957;">
      <strong>${goal.goalType} - ${goal.goalName}</strong>
      </p>
      <p style="padding: 15px 30px 15px 0px; font-family: Arial; font-style: normal; font-weight: normal; font-size: 16px; line-height: 22px; color: #0c3957;">
      Goal Amount (Today's Terms): <strong>£${goal.amountRequiredToday}</strong><br />
      Goal Amount (Tomorrow's Terms): <strong>£${goal.goalAmount}</strong><br />
      Years to Goal: <strong>${goal.goalTimeHorizon} years</strong><br />
      Priority: <strong>${goalPriority}</strong>
      </p>
      `;
  if (goal.softFacts) {
    _goalHtml += `   <p style="padding: 15px 30px 15px 0px; font-family: Arial; font-style: normal; font-weight: normal; font-size: 16px; line-height: 22px; color: #0c3957;">
          ${goal.softFacts}
          </p>`;
  }
  return _goalHtml;
};

export const financialGoals = (customerGoals) => {
  let goalsHtml = "";
  customerGoals.forEach((goal) => {
    goalsHtml += getGoalHtml(goal);
  });
  return goalsHtml;
};
