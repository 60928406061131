import React, { useState, useEffect, useContext } from 'react';

import AmmoniteMenuCircleEmpty from '../../Assets/SVG/menuCircleEmpty';
import AmmoniteMenuCircleFilledTicked from '../../Assets/SVG/menuCircleFilledTicked';
import AmmoniteCheckbox from '../../components/Checkbox/Checkbox';
import AmmoniteTrashcan from '../../Assets/SVG/trashcan';
import SuitabilityReportContext from '../../Utils/contexts/suitabilityReportContext';
import { changePension } from '../../Utils/reducers/suitabilityReportActions';


import './pensionCard.css';
import styles from '../../Styles/srbstyles.module.css'


const PensionCard = ({ 
    currentPensionIndex,
    pension,
    handleDeletePension,
    couple,
    customer1,
    customer2
}) => {

    const context = useContext(SuitabilityReportContext);
    const dispatch = context.dispatch;

   

    const filled = (
        <div className="circleSVG">
            < AmmoniteMenuCircleFilledTicked />
        </div>
    );

    const empty = (
        <div className="circleSVG">
            <AmmoniteMenuCircleEmpty colour="#3BB9C4" width="36px" height="36px" cx="18px" cy="18px" r="14px" />			
        </div>
    );

    const handleAmendPension = (event, index, field, value) => {
        if (event !== undefined) {
            event.preventDefault()};
            let _pensions = context.state.pensions;
            _pensions[index][field] = value;
        dispatch({ type: changePension, payload: {pensions: _pensions} });
    }

	useEffect(() => {

	}, [])

	return (
    <div className={styles.pensionCard}>
            {pension && <div className={styles.pensionCol}>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={`${styles.pageMainText} ${styles.labelOwner}`}>Owner</label>
                    <div className={styles.ownerPension}>
                        <div className={styles.ownerPensionButton} onClick={(event) => handleAmendPension(event, currentPensionIndex, "owner", "customer1")}>
                            {pension.owner === "customer1" ? filled : empty}
                        </div>
                        <label className={styles.customerLabel}>{customer1}</label>
                    </div>
                    {couple && <div className={styles.ownerPension}>
                        <div className={styles.ownerPensionButton} onClick={(event) => handleAmendPension(event, currentPensionIndex, "owner", "customer2")}>
                            {pension.owner === "customer2" ? filled : empty}
                        </div>
                        <label className={styles.customerLabel}>{customer2}</label>
                    </div>}
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Policy Number</label>
                    <input
                        type="text"
                        placeholder={0}
                        value={pension.policyNumber}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendPension(event, currentPensionIndex, "policyNumber", event.target.value)}
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Provider</label>
                    <input
                        type="text"
                        placeholder=""
                        value={pension.provider}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendPension(event, currentPensionIndex, "provider", event.target.value)}
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Plan Type</label>
                    <input
                        type="text"
                        placeholder=""
                        value={pension.planType}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendPension(event, currentPensionIndex, "planType", event.target.value)}
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Contributions</label>
                    <input
                        type="number"
                        placeholder={0}
                        value={pension.contributions}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendPension(event, currentPensionIndex, "contributions", event.target.value)}
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>SRD/NRD</label>
                    <input
                        type="text"
                        placeholder=""
                        value={pension.srdNrd}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendPension(event, currentPensionIndex, "srdNrd", event.target.value)}
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Investments</label>
                    <input
                        type="text"
                        placeholder=""
                        value={pension.investments}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendPension(event, currentPensionIndex, "investments", event.target.value)}
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Fund Value</label>
                    <input
                        type="number"
                        placeholder={0}
                        value={pension.fundValue}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendPension(event, currentPensionIndex, "fundValue", event.target.value)}
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Transfer Value</label>
                    <input
                        type="number"
                        placeholder={0}
                        value={pension.transferValue}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendPension(event, currentPensionIndex, "transferValue", event.target.value)}
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Penalties on Transfer</label>
                    <input
                        type="number"
                        placeholder={0}
                        value={pension.penaltiesOnTransfer}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendPension(event, currentPensionIndex, "penaltiesOnTransfer", event.target.value)}
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Charges</label>
                    <input
                        type="number"
                        placeholder={0}
                        value={pension.charges}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendPension(event, currentPensionIndex, "charges", event.target.value)}
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Expression of Wish</label>
                    <input
                        type="text"
                        placeholder=""
                        value={pension.expressionOfWish}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendPension(event, currentPensionIndex, "expressionOfWish", event.target.value)}
                    />
                </div>
            </div>}
            {pension && <div className={styles.pensionCol}>
                <div className={styles.pensionTrashcan} onClick={(event) => handleDeletePension(event, currentPensionIndex)}>
                    <AmmoniteTrashcan colour="#7A7A7A"/>
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={`${styles.pageMainText} ${styles.labelOwner}`}>Protected Tax-Free Cash?</label>
                    <div className={styles.ownerPension}>
                        <div className={[styles.ownerPensionButton, "ownerPensionButtonRightCol"].join(" ")} onClick={(event) => handleAmendPension(event, currentPensionIndex, "protectedTaxFreeCash", !pension.protectedTaxFreeCash)}>
                            {pension.protectedTaxFreeCash ? filled : empty}
                        </div>
                    </div>
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={`${styles.pageMainText} ${styles.labelOwner}`}>Written into Trust?</label>
                    <div className={styles.ownerPension}>
                        <div className={[styles.ownerPensionButton, "ownerPensionButtonRightCol"].join(" ")} onClick={(event) => handleAmendPension(event, currentPensionIndex, "writtenIntoTrust", !pension.writtenIntoTrust)}>
                            {pension.writtenIntoTrust ? filled : empty}
                        </div>
                    </div>
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={`${styles.pageMainText} ${styles.labelOwner}`}>Any Guarantees?</label>
                    <div className={styles.ownerPension}>
                        <div className={[styles.ownerPensionButton, "ownerPensionButtonRightCol"].join(" ")} onClick={(event) => handleAmendPension(event, currentPensionIndex, "anyGuarantees", !pension.anyGuarantees)}>
                            {pension.anyGuarantees ? filled : empty}
                        </div>
                    </div>
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={`${styles.pageMainText} ${styles.subFieldContainerMargin}`}>Details</label>
                    <input
                        type="text"
                        placeholder=""
                        value={pension.guaranteeDetails}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendPension(event, currentPensionIndex, "guaranteeDetails", event.target.value)}
                    />
                </div>
                <label className="pensionFieldLabel optionsOnRetirement">Options on Retirement</label>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={`${styles.pageMainText} ${styles.labelOwner} ${styles.subFieldContainerMargin}`}>Annuity Purchase?</label>
                    <div className={styles.ownerPension}>
                        <div className={[styles.ownerPensionButton, "ownerPensionButtonRightCol"].join(" ")} onClick={(event) => handleAmendPension(event, currentPensionIndex, "annuityPurchase", !pension.annuityPurchase)}>
                            {pension.annuityPurchase ? filled : empty}
                        </div>
                    </div>
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={`${styles.pageMainText} ${styles.labelOwner} ${styles.subFieldContainerMargin}`}>UFPLS</label>
                    <div className={styles.ownerPension}>
                        <div className={[styles.ownerPensionButton, "ownerPensionButtonRightCol"].join(" ")} onClick={(event) => handleAmendPension(event, currentPensionIndex, "uFPLS", !pension.uFPLS)}>
                            {pension.uFPLS ? filled : empty}
                        </div>
                    </div>
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={`${styles.pageMainText} ${styles.labelOwner} ${styles.subFieldContainerMargin}`}>Flexi-Access</label>
                    <div className={styles.ownerPension}>
                        <div className={[styles.ownerPensionButton, "ownerPensionButtonRightCol"].join(" ")} onClick={(event) => handleAmendPension(event, currentPensionIndex, "flexiAccess", !pension.flexiAccess)}>
                            {pension.flexiAccess ? filled : empty}
                        </div>
                    </div>
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={`${styles.pageMainText} ${styles.labelOwner} ${styles.subFieldContainerMargin}`}>Drawdown</label>
                    <div className={styles.ownerPension}>
                        <div className={[styles.ownerPensionButton, "ownerPensionButtonRightCol"].join(" ")} onClick={(event) => handleAmendPension(event, currentPensionIndex, "drawdown", !pension.drawdown)}>
                            {pension.drawdown ? filled : empty}
                        </div>
                    </div>
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={`${styles.pageMainText} ${styles.labelOwner} ${styles.subFieldContainerMargin}`}>Guarantees</label>
                    <div className={styles.ownerPension}>
                        <div className={[styles.ownerPensionButton, "ownerPensionButtonRightCol"].join(" ")} onClick={(event) => handleAmendPension(event, currentPensionIndex, "oorGuarantees", !pension.oorGuarantees)}>
                            {pension.oorGuarantees ? filled : empty}
                        </div>
                    </div>
                </div>
                <div className={`${styles.financiallyDependent} ${styles.transferPension}`}>
                    <div className={styles.transferPensionText}>Transfer Pension?</div>
                    <AmmoniteCheckbox
                        isBold
                        checked={pension.transferPension}
                        checkedHandler={(event) => handleAmendPension(event, currentPensionIndex, "transferPension", !pension.transferPension)}
                    />
                </div>
            </div>}
		</div>
	);
}

export default PensionCard;
