import React from 'react';

function AmmoniteResetIcon({ back }) {
	return (
		<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 0.5C3.6 0.5 0 4.1 0 8.5C0 12.9 3.6 16.5 8 16.5C11.7 16.5 14.8 13.9 16 10.5H14C12.8 12.8 10.6 14.5 8 14.5C4.7 14.5 2 11.8 2 8.5C2 5.2 4.7 2.5 8 2.5C9.7 2.5 11.1 3.2 12 4.5L9 7.5H16V0.5L14 2.5C12.2 1.5 10.2 0.5 8 0.5Z"
				fill="#7A7A7A"
			/>
		</svg>
	);
}

export default AmmoniteResetIcon;
