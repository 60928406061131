import React from 'react';

function EducationFocusButton({ colour }) {
	return (
<svg width="87" height="72" viewBox="0 0 87 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f)">
<path d="M4 36C4 18.3269 18.1478 4 35.6 4H51.4C68.8522 4 83 18.3269 83 36C83 53.6731 68.8522 68 51.4 68H35.6C18.1478 68 4 53.6731 4 36Z" fill={colour}/>
</g>
<path d="M6 35C6 18.4315 19.4315 5 36 5H51C67.5685 5 81 18.4315 81 35C81 51.5685 67.5685 65 51 65H36C19.4315 65 6 51.5685 6 35Z" fill="#69306D"/>
<path d="M55.3753 31.6681V31.5954V31.5228C55.3143 31.3802 55.3143 31.3076 55.1946 31.165V31.0924L55.1336 31.0197C55.0726 30.947 54.9529 30.8772 54.8331 30.8045L43.3577 24.4404C43.1182 24.2979 42.8178 24.2979 42.576 24.4404L31.0398 30.8073C30.6784 31.0225 30.5 31.3802 30.5 31.8078C30.5 32.2383 30.7394 32.6659 31.0398 32.8084L35.0066 34.9549V41.8249C35.0066 44.687 38.4311 46.8335 42.9988 46.8335C47.5665 46.8335 50.991 44.687 50.991 41.8249V34.9549L53.5753 33.5239V43.1103C53.5753 43.7531 53.9954 44.2562 54.5376 44.2562C55.0775 44.2562 55.5 43.7559 55.5 43.1103V31.6622C55.3756 31.7376 55.3756 31.7376 55.3756 31.6678L55.3753 31.6681ZM42.9378 44.6896C39.2106 44.6896 36.8678 43.0434 36.8678 41.9701V36.1035L42.5151 39.2506C42.6348 39.3233 42.7545 39.3233 42.9352 39.3233C43.0549 39.3233 43.2357 39.3233 43.3554 39.2506L49.0026 36.1035V41.9701C49.005 43.0434 46.6015 44.6896 42.9374 44.6896H42.9378ZM42.9378 36.9614L33.6856 31.8802L42.9378 26.7989L52.1901 31.8802L42.9378 36.9614Z" fill="white"/>
<defs>
<filter id="filter0_f" x="0" y="0" width="87" height="72" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur"/>
</filter>
</defs>
</svg>




	);
}

export default EducationFocusButton;
