//React
import React, { useRef, useEffect } from 'react';

//external libraries

//components
import './yourAdvisor.css';

//assets

function AmmoniteAdvisor({advisorAvatar, advisorName, advisorQualification, advisorAbout, setAdvisorRef}) {
    const yourAdvisorRef = useRef()

    useEffect(() => {
    
    }, [yourAdvisorRef])

	return (
        <div ref={yourAdvisorRef} >
<div className="advisorCard">
            <div className="advisorHeaderContainer">
            <div className="advisorAvatarContainer">{advisorAvatar}
                <div className="avatarTitleContainer">
                    <h2 className="advisorName">
                        {advisorName}
                    </h2>
                    <div className="advisorQualification">
                        {advisorQualification}
                    </div>
                </div>
            </div>  
            </div>
            <div className="advisorAboutHeader">
                About me
            </div>
            <div className="advisorAbout">
                    {advisorAbout}
                </div>
		</div>
        </div>
		
	);
}

export default AmmoniteAdvisor;
