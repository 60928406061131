// This is the CSS equivalent for the docx creation

const docxStyles = {
    paragraphStyles: [
        {
            id: "Heading1",
            name: "Heading 1",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
                size: 28,
                color: "BF802F",
                font: "Roboto"
            },
            paragraph: {
                spacing: {
                    before: 1400,
                    line: 320,
                    after: 10,
                },
            },
        },
        {
            id: "Heading2",
            name: "Heading 2",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
                size: 26,
                color: "BF802F",
                font: "Roboto",
            },
            paragraph: {
                spacing: {
                    after: 300,
                },
                indent: {
                    left: 400,
                },
            },
        },
        {
            id: "BlueHeading",
            basedOn: "MainText",
            next: "Normal",
            run: {
                color: "3BB9C4",
                bold: true,
            },
            paragraph: {
                spacing: {
                    after: 250,
                },
            },
        }, 
        {
            id: "MainText",
            name: "MainText",
            basedOn: "Normal",
            next: "Normal",
            run: {
                color: "000000",
                size: 19,
                font: "Roboto Light",
            },
            paragraph: {
                spacing: {
                    line: 350,
                    after: 200,
                },
                indent: {
                    left: 400,
                    right: 550,
                },
            },
        }, 
        {
            id: "RecommendationTables",
            name: "Recommendation Tables",
            basedOn: "MainText",
            next: "Normal",
            paragraph: {
                spacing: {
                    line: 650,
                    before: 295,
                },
                indent: {
                    right: 400,
                },
            },
        }, 
        {
            id: "tableText",
            name: "Table Text",
            basedOn: "Normal",
            next: "Normal",
            run: {
                color: "0C3957",
                size: 15,
                font: "Roboto Light",
            },
            paragraph: {
                spacing: {
                    before: 500,
                    line: 350,
                    after: 500,
                },
                indent: {
                    left: 1450,
                },
            },
        }, 
        {
            id: "GoalCardsTitle",
            name: "Goal Cards Title",
            basedOn: "Normal",
            next: "Normal",
            run: {
                color: "0C3957",
                size: 18,
                font: "Roboto",
            },
            paragraph: {
                spacing: {
                    before: 50,
                    line: 350,
                },
                indent: {
                    left: 750,
                },
            },
        }, 
        {
            id: "GoalCardText",
            name: "Table Text",
            basedOn: "Normal",
            next: "Normal",
            run: {
                color: "0C3957",
                size: 16,
                font: "Roboto Light",
            },
            paragraph: {
                spacing: {
                    before: 50,
                    line: 220,
                },
                indent: {
                    left: 750,
                },
            },
        },
        {
            id: "FinancialHealthText",
            name: "FinancialHealthText",
            basedOn: "Normal",
            next: "Normal",
            run: {
                color: "000000",
                size: 19,
                font: "Roboto Light",
            },
            paragraph: {
                spacing: {
                    line: 350,
                    after: 200,
                },
                indent: {
                    left: 1500,
                    right: 400,
                },
            },
        }, 
        {
            id: "AdvisorSignature",
            name: "Advisor Signature",
            basedOn: "Normal",
            next: "Normal",
            run: {
                color: "000000",
                size: 15,
                font: "Roboto",
            },
            paragraph: {
                spacing: {
                    line: 300,
                    after: 200,
                },
                indent: {
                    left: 400,
                    right: 400,
                },
            },
        },  
    ],
}

export default docxStyles