//React
import React, { useState, useContext } from "react";
import moment from "moment";

import AmmoniteCharge from "../../../components/suitabilityReportComponents/charge/charge";

import "./existingPension.css";

function AmmoniteExistingPension({
  owner,
  provider,
  policyNumber,
  fundValue,
  transferValue,
  retirementDate,
  investmentChoice,
  penaltiesOnTransfer,
  protectedTaxFreeCash,
  guarantees,
  oorGuarantees,
  recommendation,
  annuityPurchase,
  uFPLS,
  flexiAccess,
  drawdown,
  charges,
  expressionOfWish,
  recommendedCharges,
}) {
  return (
    <div className="existingPensionContainer">
      <div className="sbrBlueSubSubHeading recommendationSubHeading">
        {provider} - {policyNumber}
      </div>
      <div className="existingPensionTable existingPensionTableDimensions">
        {owner && (
          <div className="existingPensionField">
            <div className="investmentTitle">Owner:</div>
            <div className="investmentItem existingPensionSummaryItem">
              {owner}
            </div>
          </div>
        )}
        {provider && (
          <div className="existingPensionField">
            <div className="investmentTitle">Provider:</div>
            <div className="investmentItem existingPensionSummaryItem">
              {provider}
            </div>
          </div>
        )}
        {policyNumber && (
          <div className="existingPensionField">
            <div className="investmentTitle">Policy Number:</div>
            <div className="investmentItem existingPensionSummaryItem">
              {policyNumber}
            </div>
          </div>
        )}
        {fundValue && (
          <div className="existingPensionField">
            <div className="investmentTitle">Fund Value:</div>
            <div className="investmentItem existingPensionSummaryItem">
              £{fundValue}
            </div>
          </div>
        )}
        {transferValue && (
          <div className="existingPensionField">
            <div className="investmentTitle">Transfer Value:</div>
            <div className="investmentItem existingPensionSummaryItem">
              £{transferValue}
            </div>
          </div>
        )}
        {retirementDate && (
          <div className="existingPensionField">
            <div className="investmentTitle">Retirement Date:</div>
            <div className="investmentItem existingPensionSummaryItem">
              {retirementDate}
            </div>
          </div>
        )}
        {investmentChoice && (
          <div className="existingPensionField">
            <div className="investmentTitle">Investment Choice:</div>
            <div className="investmentItem existingPensionSummaryItem">
              {investmentChoice}
            </div>
          </div>
        )}
        {penaltiesOnTransfer && (
          <div className="existingPensionField">
            <div className="investmentTitle">Penalties on Transfer:</div>
            <div className="investmentItem existingPensionSummaryItem">
              £{penaltiesOnTransfer}
            </div>
          </div>
        )}
        {guarantees && (
          <div className="existingPensionField">
            <div className="investmentTitle">Guarantees:</div>
            <div className="investmentItem existingPensionSummaryItem">
              {guarantees}
            </div>
          </div>
        )}
        {charges && (
          <div className="existingPensionField">
            <div className="investmentTitle">Charges:</div>
            <div className="investmentItem existingPensionSummaryItem">
              {charges}% per annum
            </div>
          </div>
        )}
        {expressionOfWish && (
          <div className="existingPensionField">
            <div className="investmentTitle">Expression of Wish:</div>
            <div className="investmentItem existingPensionSummaryItem">
              {expressionOfWish}
            </div>
          </div>
        )}
        {protectedTaxFreeCash && (
          <div className="existingPensionField">
            <div className="investmentTitle">Protected Tax-free Cash:</div>
            <div className="investmentItem existingPensionSummaryItem">
              {protectedTaxFreeCash === "-" ? "No" : "Yes"}
            </div>
          </div>
        )}
      </div>
      <div className="existingPensionRecommendation existingPensionTable recommendationTable">
        {recommendation && (
          <div className="existingPensionField">
            <div className="investmentTitle">RECOMMENDATION:</div>
            <div className="investmentItem existingPensionSummaryItem sbrBlueSubSubHeading">
              {recommendation.toUpperCase()}
            </div>
          </div>
        )}
      </div>
      <div className="sbrBlueSubSubHeading recommendationSubHeading">
        Why {recommendation.toLowerCase()}?
      </div>

      {guarantees !== "-" && (
        <div className="sbrSubSubHeadingItem recommendationText">
          Your pension has guarantees that would be lost on transfer. These
          guarantees provide valuable security in retirement. We therefore
          recommend that you retain the policy.
        </div>
      )}
      {protectedTaxFreeCash === true && (
        <div className="sbrSubSubHeadingItem recommendationText">
          Your pension benefits from a protected tax-free cash amount that
          exceeds the normal 25%. This benefit would be lost on transfer, and we
          therefore recommend that you retain the policy.
        </div>
      )}
      {recommendation === "RETAIN" && (
        <div>
          <div className="sbrSubSubHeadingItem recommendationHeaderText">
            <strong>Things to note:</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            Your pension offers the following on retirement:
          </div>
        </div>
      )}
      {recommendation === "TRANSFER" && (
        <div>
          <div className="sbrSubSubHeadingItem recommendationHeaderText">
            <strong>Options on retirement:</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            Your pension offers the following on retirement:
          </div>
        </div>
      )}
      {annuityPurchase === true && (
        <div className="sbrSubSubHeadingItem recommendationText">
          Annuity purchase – Annuities offer a guaranteed income for life,
          however this is subject to rates at the time (currently these are very
          low). There is little opportunity to be strategic with respect to tax-
          efficiency, and you cannot vary the amount that you receive year on
          year.
        </div>
      )}
      {uFPLS === true ? (
        <div className="sbrSubSubHeadingItem recommendationText">
          Uncrystallised Fund Pension Lump Sum (UFPLS) – UFPLS means that you
          withdraw the entire pension as a single lump sum at retirement. You
          will therefore be subject to income tax on 75% of the pension at that
          time (after 25% tax-free cash), which is usually not tax-efficient.
        </div>
      ) : (
        ""
      )}
      {flexiAccess === true ? (
        <div className="sbrSubSubHeadingItem recommendationText">
          Flexi-access drawdown - Flexi-access drawdown allows you to withdraw
          as much or as little retirement income as you wish, while choosing how
          the remainder of your fund is invested. With flexi-access drawdown you
          can take up to 25% of your pension tax-free, as a lump sum or in
          portions.
        </div>
      ) : (
        ""
      )}
      {drawdown === true ? (
        <div className="sbrSubSubHeadingItem recommendationText">
          Drawdown – Under the type of drawdown offered by the pension, you are
          able to vary the amount of income you take, however there are limits
          to the income levels and there may be a requirement for a minimum
          income level.
        </div>
      ) : (
        ""
      )}
      {oorGuarantees === true && (
        <div className="sbrSubSubHeadingItem recommendationText">
          Your pension is inflexible in retirement, since there are rules
          associated in order to benefit from the guarantees.
        </div>
      )}
      {recommendation === "RETAIN" && (
        <div>
          <div className="sbrSubSubHeadingItem recommendationText">
            We believe the benefits detailed above outweigh any downsides of the
            pension.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            We can only provide ongoing advice on those new policies that we
            have recommended. So this policy won’t form part of our ongoing
            advice, and we won’t monitor it going forward. It also won’t form
            part of your goals plan. If you’d prefer to transfer, to consolidate
            all of your policies into your goals plan, let us know and we can
            help you implement this.
          </div>
        </div>
      )}
      {recommendation === "TRANSFER" && (
        <div>
          <div className="sbrSubSubHeadingItem recommendationText">
            We recommend that you transfer to a pension that offers full
            flexibility in retirement, and the ability to maximise
            tax-efficiency with your retirement income.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            <strong>Death benefits:</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            Your pension offers the following death benefits:
          </div>

          {annuityPurchase === true && (
            <div className="sbrSubSubHeadingItem recommendationText">
              Annuity purchase – Annuities have much more restrictive death
              benefits than new flexi-access personal pensions, offering only a
              spouse’s pension and/or a guarantee period. This means that it is
              hard to use annuities to plan around inheritance tax, and if you
              do not have a spouse on death, there are no benefits that can be
              paid out (unless within the guarantee period).
            </div>
          )}
          {uFPLS === true && (
            <div className="sbrSubSubHeadingItem recommendationText">
              UFPLS - With UFPLS you draw the entire pension at one time as a
              lump sum, of which 25% is tax free and the remainder is taxable.
              There would therefore be no death benefits after retirement.
            </div>
          )}
          {flexiAccess === true && (
            <div className="sbrSubSubHeadingItem recommendationText">
              Flexi-access drawdown - With flexi-access drawdown, the pension
              will be paid out tax-free to your nominated beneficiaries on death
              prior to age 75. After 75, the pension will be paid out subject to
              tax at your beneficiaries’ marginal rate of income tax.
            </div>
          )}
          {drawdown === true && (
            <div className="sbrSubSubHeadingItem recommendationText">
              Drawdown – The death benefits are the same as with a flexi-access
              pension, however they must be paid to a dependent or spouse.
            </div>
          )}
          <div className="sbrSubSubHeadingItem recommendationText">
            We think that the flexibility of the death benefits of new
            flexi-access pensions is really valuable, and gives you the security
            that you can have control over who will benefit from your pension
            and in a way that suits them (since they can usually choose between
            recieving the benefits as a lump sum or income). Also, if you die
            before age 75, then with a new flexi-access pension the death
            benefits will be paid out tax-free, whereas an inherited annuity in
            payment will always be subject to income tax at your spouse’s
            marginal rate of tax.
          </div>
          <div className="sbrSubSubHeadingItem recommendationHeaderText">
            <strong>Investments</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            By transferring to our recommended pension, we can make sure that
            your pension investments are in line with your goals and with your
            risk score, on an ongoing basis. We can also use our recommended
            investment providers, who we’ve chosen because of their strong
            investment performance, diversification, and low cost.
          </div>
          <div className="sbrSubSubHeadingItem recommendationHeaderText">
            <strong>Access to our ongoing service</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            By transferring to a new pension, we can provide our ongoing advice
            on it and make sure that its working hard for you towards your
            financial goals, in the most tax-efficient way.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            <strong>Consolidation</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            Having everything in the same place makes it much easier to keep
            track of the progress you’re making towards your goals, and makes it
            easier for you to manage. We think consolidation is really important
            to help you keep on top of your financial planning.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            <strong>Guarantees</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            There are no enhancements, guarantees or any other form of
            safeguarded rights attached to this pension plan’s fund value or
            retirement benefits.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            <strong>Penalties</strong>
          </div>
          {typeof penaltiesOnTransfer !== "number" && (
            <div className="sbrSubSubHeadingItem recommendationText">
              There are no penalties for making the transfer.
            </div>
          )}
          {penaltiesOnTransfer > 0 && (
            <div className="sbrSubSubHeadingItem recommendationText">
              There is a penalty of £{penaltiesOnTransfer} for making the
              transfer, however we believe that this cost is outweighed by the
              benefits of making the transfer.
            </div>
          )}
          <div className="sbrSubSubHeadingItem recommendationText">
            <strong>Charges</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            A comparison of the annual product and investment charges of the
            policy and the recommended policy is as follows:
          </div>
          <div>
            <AmmoniteCharge
              chargeName={"Total product / investment charge:"}
              existingPolicy={charges}
              recommendedPolicy={recommendedCharges}
            />
          </div>
          {charges > recommendedCharges && (
            <div className="sbrSubSubHeadingItem recommendationText">
              Therefore comparing on a like for like basis, the product and
              investment charges on your pension will reduce by making the
              recommended transfer.
            </div>
          )}
          {charges < recommendedCharges && (
            <div className="sbrSubSubHeadingItem recommendationText">
              Therefore comparing on a like for like basis, the product and
              investment charges on your pension will increase by making the
              recommended transfer, however we believe that this increase in
              charges is merited by the additional benefits of transfer as
              detailed above.
            </div>
          )}
          {charges == recommendedCharges && (
            <div className="sbrSubSubHeadingItem recommendationText">
              Therefore comparing on a like for like basis, the product and
              investment charges on your pension will remain the same by making
              the recommended transfer.
            </div>
          )}
          <div className="sbrSubSubHeadingItem recommendationText">
            By transferring you will also benefit from our ongoing advice on the
            policy and your goals. Your pension is with you all your life and
            you may wish to change the amount you contribute into it for regular
            monthly contributions and also how and when you wish to draw down
            from it. We will be by your side to help make those adjustments when
            you seek advice. Our charges are 0.75% per annum, which are added to
            the product/investment charges detailed above.
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            <strong>Other things to note</strong>
          </div>
          <div className="sbrSubSubHeadingItem recommendationText">
            Transfers usually complete in a few weeks, but depending on the
            providers may be longer than this. There is a potential for a loss
            of growth if the market rose while a transfer was pending.{" "}
          </div>
        </div>
      )}
    </div>
  );
}

export default AmmoniteExistingPension;
