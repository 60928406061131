import React, { useState, useEffect } from 'react';
import AmmonitePlusButton from '../../Assets/SVG/plusButton';
import AmmoniteMinusButton from '../../Assets/SVG/minusButton';
import Typography from '@material-ui/core/Typography';

import './incrementor.css';

function AmmoniteIncrementor({ minValue, incrementorValue, incrementorText, increment, decrement }) {
	const [ disableMinus, setDisableMinus ] = useState(null);
	const [ minimum, setMinimum ] = useState(minValue);
	const plusHandler = () => {
		increment();
	};

	const minusHandler = () => {
		if (incrementorValue >= minimum + 1) {
			decrement();
		}
	};

	const checkIsDisabled = () => {
		if (incrementorValue === minValue) {
			return true;
		}
		return false;
	};

	useEffect(
		() => {
			
		},
		[ disableMinus ]
	);

	return (
		<div className="incrementorContainer">
			<div className="button" onClick={minusHandler}>
				{checkIsDisabled() ? (
					<AmmoniteMinusButton colour="#7A7A7A" />
				) : (
					<AmmoniteMinusButton colour="#3BB9C4" />
				)}
			</div>

			<div className="incrementorValue">
				<Typography align="inherit" fontWeight="fontWeightBold" className="text">
					{/* {incrementorValue} */}
					{incrementorText}
				</Typography>
			</div>

			<div className="button" onClick={plusHandler}>
				<AmmonitePlusButton />
			</div>
		</div>
	);
}

export default AmmoniteIncrementor;
