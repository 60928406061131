//React
import React, { useState, useEffect } from "react";
import firebase from "../../../Utils/firebase";

// Component imports
import AmmoniteRoundSwitch from "../../../components/RoundSwitch/RoundSwitch";
import Loader from "react-loader-spinner";
import { getEnvironmentURL } from "../../../Utils/constants";

//external libraries
import Button from "@material-ui/core/Button";
import emailjs from "emailjs-com";
import { useToasts } from "react-toast-notifications";

//styling
import "../CreateUser/createUser.css";
import "./editUser.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function AmmoniteEditUser({ user, closeEditUser }) {
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [isCoach, setIsCoach] = useState(user.role === "coach" ? true : false);
  const [invalidFirstName, setInvalidFirstName] = useState(false);
  const [invalidLastName, setInvalidLastName] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const changeUserEmail = firebase.functions().httpsCallable("changeUserEmail");
  const changeUserRole = firebase.functions().httpsCallable("changeUserRole");
  const updateSessionsCustomerName = firebase
    .functions()
    .httpsCallable("updateSessionsCustomerName");
  const updateAlgoliaUser = firebase
    .functions()
    .httpsCallable("updateAlgoliaUser");
  const getResetPasswordLink = firebase
    .functions()
    .httpsCallable("getResetPasswordLink");
  const { addToast } = useToasts();

  const resetPassword = async (event) => {
    let hostName = window.location.hostname;
    if (hostName !== "localhost") {
      hostName = getEnvironmentURL[process.env.REACT_APP_ENV];
    }
    await getResetPasswordLink({
      email: email,
      url: hostName,
    }).then((resetLink) => {
      const templateParams = {
        to: email,
        resetPasswordURL: resetLink.data,
      };
      if (resetLink) {
        addToast("Emailing reset password link", {
          appearance: "success2",
          autoDismiss: true,
        });
        emailjs
          .send(
            "outlook",
            "template_m2a4srx",
            templateParams,
            "user_qSZvdg7MBAMXbZIPsDdmJ"
          )
          .then(
            (result) => {
              addToast("Reset password email sent", {
                appearance: "success2",
                autoDismiss: true,
              });
            },
            (error) => {
              addToast("Error sending email", {
                appearance: "error",
                autoDismiss: false,
              });
              console.log(error.text);
            }
          );
        closeEditUser(event);
      }
    });
  };

  const checkSendData = async (event) => {
    setInvalidFirstName(false);
    setInvalidLastName(false);
    setInvalidEmail(false);
    setEmailTaken(false);
    let canDataBeSent = true;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (firstName.trim().length === 0) {
      setInvalidFirstName(true);
      canDataBeSent = false;
    }

    if (lastName.trim().length === 0) {
      setInvalidLastName(true);
      canDataBeSent = false;
    }

    if (!re.test(String(email).toLowerCase())) {
      setInvalidEmail(true);
      canDataBeSent = false;
    } else if (email !== user.email) {
      const usersRef = firebase.firestore().collection("users");
      const querySnapshot = await usersRef
        .where("email", "==", email.toLowerCase())
        .get();

      if (!querySnapshot.empty) {
        setEmailTaken(true);
        canDataBeSent = false;
      }
    }

    if (canDataBeSent) {
      setIsLoading(true);
      if (user.firstName !== firstName || user.lastName !== lastName) {
        await firebase
          .firestore()
          .collection("users")
          .doc(user.id)
          .update({ firstName: firstName, lastName: lastName })
          .then(console.log("Names update on users collection."))
          .catch((error) => console.log("Name not updated", error));
        if (user.role === "customer") {
          await updateSessionsCustomerName({
            dataUid: user.id,
            firstName: firstName,
            lastName: lastName,
          });
        }
      }
      if (user.email !== email) {
        await changeUserEmail({
          uid: user.id,
          newEmail: email,
        });
      }
      if (
        (user.role === "coach" && !isCoach) ||
        (user.role === "admin" && isCoach)
      ) {
        await changeUserRole({
          uid: user.id,
          newRole: isCoach ? "coach" : "admin",
        });
      }
      console.log(
        "Data sent:",
        `\n${firstName}\n${lastName}\n${email}\n${isCoach ? "coach" : "admin"}`
      );
      await updateAlgoliaUser({
        dataUid: user.id,
        userEmail: email,
        userFirstName: firstName,
        userLastName: lastName,
        userRole:
          user.role === "customer" ? "customer" : isCoach ? "coach" : "admin",
      });
      closeEditUser(event);
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="blockInputPanel">
      {isLoading && (
        <div className="dashboardLoaderAnimation">
          <Loader type="Grid" color="#3bb9c4" height={80} width={80} />
        </div>
      )}
      {!isLoading && (
        <div className="createUserContainer">
          <div className="x" onClick={(event) => closeEditUser(event)}></div>
          <div className="createUserTitle">
            {user.role === "customer" ? "Edit Customer" : "Edit Coach / Admin"}
          </div>
          <div className="createUserNameInputs">
            <div className="createUserNameInput">
              First Name
              <input
                autoComplete="off"
                type="text"
                name="fname"
                aria-label="first name"
                placeholder={user.firstName ? user.firstName : ""}
                className="createUserNameInputBox"
                onChange={(event) => setFirstName(event.target.value)}
              />
              <div className="createUserErrorMessage">
                {invalidFirstName ? "Enter first name" : ""}
              </div>
            </div>
            <div className="createUserNameInput">
              Last Name
              <input
                autoComplete="off"
                type="text"
                name="lname"
                aria-label="last name"
                placeholder={user.lastName ? user.lastName : ""}
                className="createUserNameInputBox"
                onChange={(event) => setLastName(event.target.value)}
              />
              <div className="createUserErrorMessage">
                {invalidLastName ? "Enter last name" : ""}
              </div>
            </div>
          </div>
          <div className="createUserNameInput createUserInputTitle">
            Email
            <input
              autoComplete="off"
              type="email"
              name="email"
              placeholder={user.email}
              className="createUserInputBox"
              onChange={(event) => setEmail(event.target.value)}
            />
            <div className="createUserErrorMessage">
              {invalidEmail
                ? "Sorry, email not valid"
                : emailTaken
                ? "There is already an user with this email. Try another or edit the existing user."
                : ""}
            </div>
          </div>
          {user.role !== "customer" && (
            <div className="createUserNameInput createUserInputTitle createUserType">
              User Type
              <div className="createUserRoleButtons">
                <AmmoniteRoundSwitch
                  colour="#3BB9C4"
                  on={isCoach === true}
                  switchHandler={() => setIsCoach(true)}
                  switchText="Coach"
                />

                <AmmoniteRoundSwitch
                  colour="#3BB9C4"
                  on={isCoach === false}
                  switchHandler={() => setIsCoach(false)}
                  switchText="Admin"
                />
              </div>
            </div>
          )}
          {user.role === "customer" && (
            <div className="editUserPasswordField">
              <div className="createUserNameInput createUserInputTitle createUserPassword">
                This user's password will be:
              </div>
              <div className="createUserNameInput createUserPasswordInput">
                ammonite
              </div>
            </div>
          )}
          <div className="editUserButtons">
            {user.role !== "customer" && (
              <Button
                variant="contained"
                onClick={(event) => resetPassword(event)}
                style={{
                  position: "relative",
                  top: "130px",
                  alignSelf: "center",
                  width: "273px",
                  height: "46px",
                  borderRadius: "20px",
                  fontFamily: "Brother 1816",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "18px",
                  lineHeight: "26px",
                  letterSpacing: "0.02em",
                  color: "white",
                  backgroundColor: "#575A89",
                  textTransform: "none",
                }}
              >
                Send password reset?
              </Button>
            )}
            <Button
              variant="contained"
              onClick={(event) => checkSendData(event)}
              style={{
                position: "relative",
                top: "130px",
                alignSelf: "center",
                width: "273px",
                height: "46px",
                borderRadius: "20px",
                fontFamily: "Brother 1816",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "18px",
                lineHeight: "26px",
                letterSpacing: "0.02em",
                color: "white",
                backgroundColor: "#3BB9C4",
                textTransform: "none",
              }}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AmmoniteEditUser;
