import React from 'react';

function AmmoniteDownArrow({ colour }) {
	return (
		<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="arrow-down">
				<path
					id="Mask"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12 14.25C12.384 14.25 12.768 14.397 13.0605 14.6895L18.018 19.647L22.9575 14.877C23.5545 14.301 24.5025 14.316 25.0785 14.9145C25.6545 15.51 25.638 16.461 25.0425 17.0355L19.0425 22.8285C18.453 23.397 17.517 23.3895 16.9395 22.8105L10.9395 16.8105C10.353 16.224 10.353 15.276 10.9395 14.6895C11.232 14.397 11.616 14.25 12 14.25"
					fill={colour}
				/>
				<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="10" y="14" width="16" height="10">
					<path
						id="Mask_2"
						fillRule="evenodd"
						clipRule="evenodd"
						d="M12 14.25C12.384 14.25 12.768 14.397 13.0605 14.6895L18.018 19.647L22.9575 14.877C23.5545 14.301 24.5025 14.316 25.0785 14.9145C25.6545 15.51 25.638 16.461 25.0425 17.0355L19.0425 22.8285C18.453 23.397 17.517 23.3895 16.9395 22.8105L10.9395 16.8105C10.353 16.224 10.353 15.276 10.9395 14.6895C11.232 14.397 11.616 14.25 12 14.25"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask0)">
					<g id="&#240;&#159;&#142;&#168; Color">
						<rect
							id="Base"
							x="36"
							y="36"
							width="36"
							height="36"
							transform="rotate(-180 36 36)"
							fill={colour}
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default AmmoniteDownArrow;
