import React from 'react';

function AmmoniteMortgage({ back }) {
	return (
<svg width="95" height="55" viewBox="0 0 95 55" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M94.4304 54.3851C94.4306 54.3315 94.4308 54.2779 94.4308 54.2242C94.4308 33.3412 73.9718 16.4121 48.7344 16.4121C23.497 16.4121 3.03809 33.3412 3.03809 54.2242C3.03809 54.2779 3.03822 54.3315 3.03849 54.3851H94.4304Z" fill="#373986" fill-opacity="0.2"/>
<path d="M25.2418 18.1907H24.3291V54.2432H25.2418V18.1907Z" fill="#3F3D56"/>
<path d="M33.6654 22.7853C33.7235 35.3919 24.8541 45.6528 24.8541 45.6528C24.8541 45.6528 15.8904 35.4741 15.8323 22.8675C15.7742 10.2608 24.6437 0 24.6437 0C24.6437 0 33.6073 10.1786 33.6654 22.7853Z" fill="#BF802F"/>
<path d="M88.7505 39.3174H18.1123V54.2857H88.7505V39.3174Z" fill="#F2F2F2"/>
<path d="M70.1421 43.4185H63.9395V54.2859H70.1421V43.4185Z" fill="#1A5A75"/>
<path d="M32.8751 42.7007H20.8799V54.2858H32.8751V42.7007Z" fill="#1A5A75"/>
<path d="M60.0438 42.2903H37.54V54.2855H60.0438V42.2903Z" fill="#1A5A75"/>
<path d="M60.0951 52.7993H37.54V53.0044H60.0951V52.7993Z" fill="#F2F2F2"/>
<path d="M32.8751 52.7993H20.8799V53.0044H32.8751V52.7993Z" fill="#F2F2F2"/>
<path d="M60.0951 51.8767H37.54V52.0818H60.0951V51.8767Z" fill="#F2F2F2"/>
<path d="M32.8751 51.8767H20.8799V52.0818H32.8751V51.8767Z" fill="#F2F2F2"/>
<path d="M60.0951 50.9539H37.54V51.1589H60.0951V50.9539Z" fill="#F2F2F2"/>
<path d="M32.8751 50.9539H20.8799V51.1589H32.8751V50.9539Z" fill="#F2F2F2"/>
<path d="M60.0951 50.0312H37.54V50.2363H60.0951V50.0312Z" fill="#F2F2F2"/>
<path d="M32.8751 50.0312H20.8799V50.2363H32.8751V50.0312Z" fill="#F2F2F2"/>
<path d="M69.3222 50.0311C69.5487 50.0311 69.7323 49.8475 69.7323 49.621C69.7323 49.3945 69.5487 49.2109 69.3222 49.2109C69.0957 49.2109 68.9121 49.3945 68.9121 49.621C68.9121 49.8475 69.0957 50.0311 69.3222 50.0311Z" fill="#F2F2F2"/>
<path d="M60.0951 49.1086H37.54V49.3137H60.0951V49.1086Z" fill="#F2F2F2"/>
<path d="M32.8751 49.1086H20.8799V49.3137H32.8751V49.1086Z" fill="#F2F2F2"/>
<path d="M60.0951 48.186H37.54V48.3911H60.0951V48.186Z" fill="#F2F2F2"/>
<path d="M32.8751 48.186H20.8799V48.3911H32.8751V48.186Z" fill="#F2F2F2"/>
<path d="M83.9316 47.8787H80.9072V50.8006H83.9316V47.8787Z" fill="#247BA0"/>
<path d="M79.8818 47.8787H76.8574V50.8006H79.8818V47.8787Z" fill="#247BA0"/>
<path d="M60.0951 47.2627H37.54V47.4677H60.0951V47.2627Z" fill="#F2F2F2"/>
<path d="M32.8751 47.2627H20.8799V47.4677H32.8751V47.2627Z" fill="#F2F2F2"/>
<path d="M68.8096 46.4141H67.3193V47.8538H68.8096V46.4141Z" fill="#F2F2F2"/>
<path d="M66.8135 46.4141H65.3232V47.8538H66.8135V46.4141Z" fill="#F2F2F2"/>
<path d="M60.0951 46.3398H37.54V46.5449H60.0951V46.3398Z" fill="#F2F2F2"/>
<path d="M32.8751 46.3398H20.8799V46.5449H32.8751V46.3398Z" fill="#F2F2F2"/>
<path d="M60.0951 45.4172H37.54V45.6223H60.0951V45.4172Z" fill="#F2F2F2"/>
<path d="M32.8751 45.4172H20.8799V45.6223H32.8751V45.4172Z" fill="#F2F2F2"/>
<path d="M68.8096 44.5198H67.3193V45.909H68.8096V44.5198Z" fill="#F2F2F2"/>
<path d="M66.8135 44.5198H65.3232V45.909H66.8135V44.5198Z" fill="#F2F2F2"/>
<path d="M60.0951 44.4946H37.54V44.6997H60.0951V44.4946Z" fill="#F2F2F2"/>
<path d="M32.8751 44.4946H20.8799V44.6997H32.8751V44.4946Z" fill="#F2F2F2"/>
<path d="M83.9316 44.0332H80.9072V46.8526H83.9316V44.0332Z" fill="#247BA0"/>
<path d="M79.8818 44.0332H76.8574V46.8526H79.8818V44.0332Z" fill="#247BA0"/>
<path d="M60.0951 43.572H37.54V43.7771H60.0951V43.572Z" fill="#F2F2F2"/>
<path d="M32.8751 43.572H20.8799V43.7771H32.8751V43.572Z" fill="#F2F2F2"/>
<path d="M14.0398 30.7771H13.4375V54.5679H14.0398V30.7771Z" fill="#3F762C"/>
<path d="M6.20972 30.7771H5.60742V54.5679H6.20972V30.7771Z" fill="#3F762C"/>
<path d="M15.8564 40.9581L23.5457 24.8621H84.9568L90.6981 40.9581H15.8564Z" fill="#1A5A75"/>
<path d="M63.5297 25.8872H35.0283V35.3193H63.5297V25.8872Z" fill="#F2F2F2"/>
<path d="M60.9148 31.4766H58.5479V33.7633H60.9148V31.4766Z" fill="#247BA0"/>
<path d="M57.7458 31.4766H55.3789V33.7633H57.7458V31.4766Z" fill="#247BA0"/>
<path d="M52.0984 31.4766H49.7314V33.7633H52.0984V31.4766Z" fill="#247BA0"/>
<path d="M48.9285 31.4766H46.5615V33.7633H48.9285V31.4766Z" fill="#247BA0"/>
<path d="M43.1785 31.4766H40.8115V33.7633H43.1785V31.4766Z" fill="#247BA0"/>
<path d="M40.0095 31.4766H37.6426V33.7633H40.0095V31.4766Z" fill="#247BA0"/>
<path d="M60.9148 28.4678H58.5479V30.6742H60.9148V28.4678Z" fill="#247BA0"/>
<path d="M57.7458 28.4678H55.3789V30.6742H57.7458V28.4678Z" fill="#247BA0"/>
<path d="M52.0984 28.4678H49.7314V30.6742H52.0984V28.4678Z" fill="#247BA0"/>
<path d="M48.9285 28.4678H46.5615V30.6742H48.9285V28.4678Z" fill="#247BA0"/>
<path d="M43.1785 28.4678H40.8115V30.6742H43.1785V28.4678Z" fill="#247BA0"/>
<path d="M40.0095 28.4678H37.6426V30.6742H40.0095V28.4678Z" fill="#247BA0"/>
<path d="M19.5982 33.4868C19.6365 41.8058 13.7837 48.5768 13.7837 48.5768C13.7837 48.5768 7.8686 41.86 7.83027 33.541C7.79193 25.222 13.6448 18.4509 13.6448 18.4509C13.6448 18.4509 19.5599 25.1677 19.5982 33.4868Z" fill="#BF802F"/>
<path d="M11.7681 33.4868C11.8065 41.8058 5.95358 48.5768 5.95358 48.5768C5.95358 48.5768 0.0385249 41.86 0.000187518 33.541C-0.0381499 25.222 5.81473 18.4509 5.81473 18.4509C5.81473 18.4509 11.7298 25.1677 11.7681 33.4868Z" fill="#BF802F"/>
<path d="M36.8733 18.1978L32.3623 27.6299H66.1948L63.5292 18.1978H36.8733Z" fill="#1A5A75"/>
</svg>

	);
}

export default AmmoniteMortgage;
