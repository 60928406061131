import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router';

import AmmoniteCard from '../../containers/Card/Card';
import AmmoniteStepPicker from '../../containers/StepPicker/StepPicker';
import Card1 from '../../Assets/SVG/Cards/financial-planning.png';
import Card2 from '../../Assets/SVG/Cards/help.png';
import Card3 from '../../Assets/SVG/Cards/take-control.png';
import AmmoniteContext from '../../Utils/contexts/AmmoniteContext';

import './stepper.css';

function AmmoniteStepper({ forwardLink }) {
	const context = useContext(AmmoniteContext);
	const { push } = useHistory();
	const ammoniteSteps = [
		{
			card: Card1,
			header: 'Hello there',
			text:
				'Everyone has goals and with a clear plan you are more likely to achieve them. Smart tax planning and low-cost investing can help.'
		},
		{
			card: Card2,
			header: 'About this session',
			text:
				'This session will help you map out your financial goals. No personal recommendations or advice will be given today - we leave that to our expert team of financial advisers back at base.'
		},
		{
			card: Card3,
			header: "Let's start",
			text:
				'Setting your goals sooner rather than later could make a big difference to achieving them. So let’s start now!'
		}
	];

	const stepsMap = ammoniteSteps.map((item, index) => (
		<AmmoniteCard image={item.card} cardHeader={item.header} cardText={item.text} />
	));

	const [ currentStep, setCurrentStep ] = useState(0);

	const changeCurrentStep = (step) => {
		setCurrentStep(step);
	};

	const skipIntroHandler = () => {
		// dispatch({ type: setSessionDate });
		push(forwardLink);
	};

	return (
		<div className="stepperContainer">
			<div className="stepperCard">{stepsMap[currentStep]}</div>
			<div className="stepperStep">
				<AmmoniteStepPicker
					currentStep={currentStep}
					steps={3}
					changeCurrentStep={(step) => changeCurrentStep(step)}
					skipIntroHandler={skipIntroHandler}
				/>
			</div>
		</div>
	);
}

export default AmmoniteStepper;
