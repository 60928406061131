// Core React dependencies
import React, {useState, useEffect} from 'react';

// Component imports
import AmmoniteWarning2Icon from '../../Assets/SVG/Warning/warning2';

// Styling
import './validationToasts.css';


function AmmoniteToast ({ appearance, children }) {

    const [ showToast, setShowToast ] = useState(true);

    const getToastColour = (appearance) => {
        switch (appearance) {
            case 'error':
                return 'rgb(180, 1, 37)';
            case 'error2':
                return 'rgb(180, 1, 37)';
            case 'success':
                return 'rgb(59, 185, 196)';
            default:
                return 'rgb(59, 185, 196)';
        }
    }

    useEffect(() => {},[showToast])

	return (
        showToast && <div style={{ "background-color": getToastColour(appearance), opacity: 1, width: 300, height: 100, 'border-radius': 10, "margin-bottom": 5}}>
            {appearance === 'success' ||  appearance === 'success2'
                ?<div className="successLoadingBar">
                    <div className="successIconPosition">
                        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.01493 9.78543L2.60728 6.37779C2.51352 6.28402 2.38634 6.23134 2.25373 6.23134C2.12112 6.23134 1.99395 6.28402 1.90018 6.37779L0.646447 7.63152C0.552678 7.72529 0.5 7.85247 0.5 7.98507C0.5 8.11768 0.552678 8.24486 0.646447 8.33863L5.66137 13.3536C5.85663 13.5488 6.17322 13.5488 6.36848 13.3536L17.1147 2.60728C17.31 2.41202 17.31 2.09544 17.1147 1.90018L15.861 0.646447C15.7672 0.552678 15.6401 0.5 15.5075 0.5C15.3749 0.5 15.2477 0.552678 15.1539 0.646447L6.01493 9.78543Z" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div className="successLoadingBarInFront"></div>
                </div>
                :<div className="errorBar">
                    <div className="errorIconPosition">
                        {AmmoniteWarning2Icon("white")}
                    </div>
            </div>}
            <div className="close" onClick={() => setShowToast(false)}></div>
        {appearance === 'success'?<div className="successToastText">{children}</div>:appearance === 'success2'? <div className="success2ToastText">{children}</div> 
        : appearance === 'error' ? <div className="errorToastText">{children}</div> : <div className="error2ToastText">{children}</div>}
        </div>)
};

export default AmmoniteToast