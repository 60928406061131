import React from 'react'

function AmmoniteUserTab({ role }) {
  let colour = '#3F762C'
  if (role === 'coach') {
    colour = '#69306D'
  } else if (role === 'admin') {
    colour = '#247BA0'
  }
  return (
    <svg
      width='1204'
      height='113'
      viewBox='0 0 1204 113'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <mask
        id='mask0'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='3'
        width='1204'
        height='104'>
        <rect
          x='0.5'
          y='3.5'
          width='1202.89'
          height='103'
          rx='7.5'
          fill='white'
          stroke='white'
        />
      </mask>
      <g mask='url(#mask0)'>
        <rect
          x='0.5'
          y='1.17163'
          width='45.6624'
          height='111.149'
          fill={colour}
          stroke='white'
        />
      </g>
    </svg>
  )
}

export default AmmoniteUserTab
