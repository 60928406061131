// Core React dependencies
import React, { useState, useEffect, useContext, useRef } from "react";

// Extra React dependencies
import { useHistory } from "react-router";

// External libraries
// import generator from 'generate-password'
import { Typography } from "@material-ui/core";

import { useToasts } from "react-toast-notifications";

// Component imports
import AmmoniteButton from "../../components/Button/button";
import ValidationWarning from "../../components/ValidationWarning/ValidationWarning";
import AmmoniteCareFeesSummaryIcon from "../../Assets/SVG/SummaryIcons/careFeesSummary";
import AmmoniteOtherSummaryIcon from "../../Assets/SVG/SummaryIcons/otherSummary";
import AmmoniteEducationSummaryIcon from "../../Assets/SVG/SummaryIcons/educationSummary";
import AmmoniteLTISummaryIcon from "../../Assets/SVG/SummaryIcons/ltiSummary";
import AmmonitePropertySummaryIcon from "../../Assets/SVG/SummaryIcons/propertySummary";
import AmmoniteRetirementSummaryIcon from "../../Assets/SVG/SummaryIcons/retirementSummary";
import AmmoniteFamilySummaryIcon from "../../Assets/SVG/SummaryIcons/familySummary";
import AmmonitePrioritiseSummaryIcon from "../../Assets/SVG/SummaryIcons/prioritiseSummary";
import AmmonitePlanSummaryIcon from "../../Assets/SVG/SummaryIcons/planSummary";
import AmmoniteConfirmation from "../../Assets/SVG/Illustrations/Confirmation/Confirmation";
import AmmoniteCheckbox from "../../components/Checkbox/Checkbox";

// Utilities
import firebase from "../../Utils/firebase";
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import Loader from "react-loader-spinner";
import sizeof from "firestore-size";

// Actions
import {
  refreshContextFromCache,
  setCustomerEmail,
  setCustomerTelephone,
  setCoachUid,
  setCoachName,
  setStatePensionAge,
} from "../../Utils/reducers/actions";

// Styling
import "./summary.css";

function Summary() {
  const context = useContext(AmmoniteContext);
  const dispatch = context.dispatch;
  const { push } = useHistory();
  const [goals, setGoals] = useState(context.state.customerGoals);
  const [pagevalid, setPageValid] = useState(false);
  const [emailvalid, setEmailValid] = useState(false);
  const [telephonevalid, setTelephoneValid] = useState(false);
  const [showValidationWarning, setShowValidationWarning] = useState(true);
  const [showEmailAlreadyExistsWarning, setShowEmailAlreadyExistsWarning] =
    useState(false);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const customerEmail = useRef(null);
  const [informationConsent, setInformationConsent] = useState(false);
  const [noAdvice, setNoAdvice] = useState(false);
  const [loading, setLoading] = useState(false);
  const createUser = firebase.functions().httpsCallable("createUser");
  const createResumeSessionToken = firebase
    .functions()
    .httpsCallable("createResumeSessionToken");
  const getAccessToken = firebase.functions().httpsCallable("getAccessToken");

  const { addToast } = useToasts();

  let name = "";
  if (context.state.customerInfo.couple === true) {
    name =
      context.state.customerInfo.customers.customer1.customerFirstName +
      " and " +
      context.state.customerInfo.customers.customer2.customerFirstName;
  } else {
    name = context.state.customerInfo.customers.customer1.customerFirstName;
  }

  const getGoalSummaryIcon = (goalType) => {
    switch (goalType) {
      case "Retirement pot":
        return <AmmoniteRetirementSummaryIcon />;
      case "Education":
        return <AmmoniteEducationSummaryIcon />;
      case "Property":
        return <AmmonitePropertySummaryIcon />;
      case "Family":
        return <AmmoniteFamilySummaryIcon />;
      case "Long Term Investment":
        return <AmmoniteLTISummaryIcon colour="#3F762C" />;
      case "Care Fees":
        return <AmmoniteCareFeesSummaryIcon />;
      case "Other":
        return <AmmoniteOtherSummaryIcon />;
      default:
        return <AmmoniteOtherSummaryIcon />;
    }
  };

  const getGoals = () => {
    let _tempSummaryGoals = [...goals];
    _tempSummaryGoals = _tempSummaryGoals
      .splice(1)
      .sort((a, b) => {
        return a.goalImportance - b.goalImportance;
      })
      .reverse();
    _tempSummaryGoals.unshift(context.state.customerGoals[0]);
    return _tempSummaryGoals.map((item, index) => (
      <div className="goalSummaryItem">
        {getGoalSummaryIcon(item.goalType)}{" "}
        <div className="summaryGoalText">{item.goalName}</div>
      </div>
    ));
  };

  const getStatePensionAge = () => {
    const customerDoB =
      context.state.customerInfo.customers.customer1.customerDoB;
    if (customerDoB < new Date("1961-3-6")) {
      return 66;
    } else if (customerDoB < new Date("1978-4-6")) {
      return 67;
    }
    return 68;
  };

  const resumeSession = async () => {
    const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        addToast("User is not signed in", {
          appearance: "error2",
          autoDismiss: false,
        });
      }
    });

    unsubscribe();

    const _token = firebase.auth().currentUser; // synchronous but also not. Returns null if not ready.
    const refreshToken = await _token.getIdToken();

    const accessToken = await getAccessToken(refreshToken);

    const customToken = await createResumeSessionToken(accessToken.data);

    await firebase.auth().signInWithCustomToken(customToken.data);

    return;
  };

  const addCreatorsDataToContext = async () => {
    try {
      await firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          const staffRef = firebase
            .firestore()
            .collection("users")
            .doc(idTokenResult.claims.user_id);

          dispatch({
            type: setCoachUid,
            payload: idTokenResult.claims.user_id,
          });
          dispatch({
            type: setStatePensionAge,
            payload: getStatePensionAge(),
          });

          let data = [];
          staffRef.get().then((doc) => {
            data = doc.data();
            if (data) {
              dispatch({
                type: setCoachName,
                payload: data.firstName + " " + data.lastName,
              });
            }
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const sendInformationHandler = async () => {
    setLoading(true);
    const customerEmailCurrent = customerEmail.current.value;
    const customerPassword = "ammonite";

    let newUserId;

    await createUser({
      email: customerEmailCurrent,
      password: customerPassword,
      firstName:
        context.state.customerInfo.customers.customer1.customerFirstName,
      lastName: context.state.customerInfo.customers.customer1.customerLastName,
    }).then((userRecord) => {
      newUserId = userRecord.data.uid;
    });

    await firebase
      .firestore()
      .collection("sessions")
      .doc(newUserId)
      .set({ ...context.state });

    const snapshot = await firebase
      .firestore()
      .collection("sessions")
      .doc(newUserId)
      .get();
    const data = snapshot.data();

    addToast("Data saved to Firebase", {
      appearance: "success2",
      autoDismiss: true,
    });
  };

  const informationConsentHandler = () => {
    setInformationConsent(!informationConsent);
  };

  const confirmNoAdviceHandler = () => {
    setNoAdvice(!noAdvice);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validateTelephone = (telephone) => {
    const reTel =
      /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/;
    return reTel.test(String(telephone).toLowerCase());
  };

  const emailChangeHandler = (value) => {
    if (validateEmail(value)) {
      setEmailValid(true);
    }
    dispatch({ type: setCustomerEmail, payload: value });
  };

  const checkPageValid = () => {
    if (emailvalid && telephonevalid && informationConsent && noAdvice) {
      setPageValid(true);
    } else setPageValid(false);
  };

  const telephoneChangeHandler = (value) => {
    if (validateTelephone(value)) {
      setTelephoneValid(true);
    }
    dispatch({ type: setCustomerTelephone, payload: value });
  };

  const getPageValid = () => {
    return pagevalid;
  };

  const resetPage = () => {
    setShowEmailAlreadyExistsWarning(false);
    // return dispatch({ type: setCustomerEmail, payload: "" })
  };

  useEffect(() => {
    if (context.state.customerInfo.customers.customer1.customerDoB !== null) {
      setShowValidationWarning(false);
    } else if (
      context.state.customerInfo.couple &&
      context.state.customerInfo.customers.customer2.customerDoB !== null
    ) {
      setShowValidationWarning(false);
    }
    if (context.state.clean === true && localStorage.ammoniteContext) {
      dispatch({
        type: refreshContextFromCache,
        payload: JSON.parse(localStorage.getItem("ammoniteContext")),
      });
      setGoals(
        JSON.parse(localStorage.getItem("ammoniteContext")).state.customerGoals
      );
    }

    if (emailvalid || telephonevalid || informationConsent || noAdvice) {
      checkPageValid();
    }
  }, [
    informationConsent,
    noAdvice,
    customerEmail,
    goals,
    pagevalid,
    emailvalid,
    telephonevalid,
    showConfirmBox,
    showEmailAlreadyExistsWarning,
  ]);

  return (
    <div>
      {!loading ? (
        <div className="finalScreen">
          {showValidationWarning && <ValidationWarning />}
          <div className="summaryHeaderContainer">
            <Typography
              color="primary"
              variant="h4"
              align="inherit"
              fontWeight="fontWeightBold"
            >
              Thank you, {name}
            </Typography>
          </div>
          <div className="summaryContainer">
            <div className="summaryTopContainer">
              <div className="summaryGoalsLeftPanel">
                <div className="summaryLeftPanelHeading">
                  Your financial goals
                </div>
                <div className="summaryYourGoals">{getGoals()}</div>
              </div>
              <div className="summaryGoalsRightPanel">
                <div className="summaryLeftPanelHeading">Next steps</div>
                <div className="summaryNextStepsIntroText">
                  Thank you for your time today and for sharing your goals. We'd
                  like to send you an email with a summary of today's session
                  and with next steps.
                </div>
                <div className="summaryNextSteps">
                  <div className="summaryStep">
                    <div className="summaryNextStepsIcon">
                      <AmmonitePrioritiseSummaryIcon />
                    </div>
                    <div className="summaryNextStepsText">
                      We'll include your (free!) goals report with the email,
                      with ways you could improve your financial health.
                    </div>
                  </div>
                  <div className="summaryStep">
                    <div className="summaryNextStepsIcon">
                      <AmmonitePlanSummaryIcon />
                    </div>
                    <div className="summaryNextStepsText">
                      We'll follow up with a call to see if you have any
                      questions about the report.
                    </div>
                  </div>
                  <div className="summaryStep">
                    <div className="summaryNextStepsIcon">
                      <AmmoniteLTISummaryIcon colour="#BF802F" />
                    </div>
                    <div className="summaryNextStepsText">
                      We'll offer expert pension and investment advice at low
                      cost, if you seek it.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="summaryMiddleContainer">
              <div className="summaryMiddlePanelHeading">
                I would like to receive a summary of all of this valuable
                information
              </div>
              <div className="summaryMiddlePanelCheckboxes">
                <div className="summaryCheckboxLeft">
                  <div className="summaryCheckbox">
                    <AmmoniteCheckbox
                      inlineText
                      checked={informationConsent}
                      checkedHandler={informationConsentHandler}
                      checkboxText="To help prepare your free goals report, we will need to pass this information on to our Central Team. By ticking this box you agree to the storage and handling of your data by Ammonite Wealth"
                    />
                  </div>
                </div>
                <div className="summaryCheckboxRight">
                  <div className="summaryCheckbox">
                    <AmmoniteCheckbox
                      inlineText
                      checked={noAdvice}
                      checkedHandler={confirmNoAdviceHandler}
                      checkboxText="This session is intended to provide financial guidance only. By ticking this box you confirm you have not received any financial advice today (i.e. personal recommendations specific to you)"
                    />
                  </div>
                </div>
              </div>
              <div className="summaryBottomContainer">
                <div className="fieldBox">
                  <div className="fieldTitle">Email Address</div>
                  <div className="fieldInputBox emailInputBox">
                    <input
                      ref={customerEmail}
                      className="fieldInput emailInput"
                      type="text"
                      value={context.customerEmail}
                      onChange={(event) =>
                        emailChangeHandler(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="fieldBox">
                  <div className="fieldTitle">Telephone number</div>
                  <div className="fieldInputBox">
                    <input
                      className="fieldInput"
                      type="text"
                      onChange={(event) =>
                        telephoneChangeHandler(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="fieldBox sendButton">
                  <AmmoniteButton
                    buttonText={"Send information"}
                    fontWeight="bold"
                    handlerFunction={() => {
                      addCreatorsDataToContext();
                      setShowConfirmBox(true);
                    }}
                    color="primary"
                    width={215}
                    height={46}
                    disabled={!getPageValid()}
                    // buttonLink="/"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="ammoniteFCANumber">
            <i>
              Ammonite Wealth is authorised and regulated by the Financial
              Conduct Authority no. 843433
            </i>
          </div>
          {showConfirmBox && (
            <div className="blockInputPane">
              <div className="confirmBox">
                <div
                  className="closeButton"
                  onClick={() => setShowConfirmBox(false)}
                >
                  <div className="confirmationBalloons">
                    <AmmoniteConfirmation />
                  </div>
                  <div className="closeText">Close</div>
                  <div className="closeIcon">
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 3C15.0333 3 12.1332 3.87973 9.66645 5.52796C7.19972 7.17618 5.27713 9.51886 4.14181 12.2597C3.0065 15.0006 2.70945 18.0166 3.28823 20.9264C3.86701 23.8361 5.29562 26.5088 7.3934 28.6066C9.49119 30.7044 12.1639 32.133 15.0737 32.7118C17.9834 33.2906 20.9994 32.9935 23.7403 31.8582C26.4811 30.7229 28.8238 28.8003 30.472 26.3336C32.1203 23.8668 33 20.9667 33 18C33 16.0302 32.612 14.0796 31.8582 12.2597C31.1044 10.4399 29.9995 8.78628 28.6066 7.3934C27.2137 6.00052 25.5601 4.89563 23.7403 4.14181C21.9204 3.38799 19.9698 3 18 3ZM18 30C15.6266 30 13.3066 29.2962 11.3332 27.9776C9.35977 26.6591 7.8217 24.7849 6.91345 22.5922C6.0052 20.3995 5.76756 17.9867 6.23058 15.6589C6.69361 13.3311 7.83649 11.1929 9.51472 9.51472C11.193 7.83649 13.3312 6.6936 15.6589 6.23058C17.9867 5.76755 20.3995 6.00519 22.5922 6.91345C24.7849 7.8217 26.6591 9.35977 27.9776 11.3332C29.2962 13.3065 30 15.6266 30 18C30 21.1826 28.7357 24.2348 26.4853 26.4853C24.2349 28.7357 21.1826 30 18 30Z"
                        fill="#BDBDBD"
                      />
                      <path
                        d="M22.065 13.9349C21.9255 13.7943 21.7596 13.6827 21.5768 13.6066C21.394 13.5304 21.198 13.4912 21 13.4912C20.802 13.4912 20.6059 13.5304 20.4231 13.6066C20.2403 13.6827 20.0744 13.7943 19.935 13.9349L18 15.8849L16.065 13.9349C15.7825 13.6525 15.3994 13.4938 15 13.4938C14.6005 13.4938 14.2174 13.6525 13.935 13.9349C13.6525 14.2174 13.4938 14.6005 13.4938 14.9999C13.4938 15.3994 13.6525 15.7825 13.935 16.0649L15.885 17.9999L13.935 19.9349C13.7944 20.0744 13.6828 20.2403 13.6066 20.423C13.5305 20.6058 13.4913 20.8019 13.4913 20.9999C13.4913 21.1979 13.5305 21.394 13.6066 21.5768C13.6828 21.7596 13.7944 21.9255 13.935 22.0649C14.0744 22.2055 14.2403 22.3171 14.4231 22.3932C14.6059 22.4694 14.802 22.5086 15 22.5086C15.198 22.5086 15.394 22.4694 15.5768 22.3932C15.7596 22.3171 15.9255 22.2055 16.065 22.0649L18 20.1149L19.935 22.0649C20.0744 22.2055 20.2403 22.3171 20.4231 22.3932C20.6059 22.4694 20.802 22.5086 21 22.5086C21.198 22.5086 21.394 22.4694 21.5768 22.3932C21.7596 22.3171 21.9255 22.2055 22.065 22.0649C22.2056 21.9255 22.3172 21.7596 22.3933 21.5768C22.4695 21.394 22.5087 21.1979 22.5087 20.9999C22.5087 20.8019 22.4695 20.6058 22.3933 20.423C22.3172 20.2403 22.2056 20.0744 22.065 19.9349L20.115 17.9999L22.065 16.0649C22.2056 15.9255 22.3172 15.7596 22.3933 15.5768C22.4695 15.394 22.5087 15.1979 22.5087 14.9999C22.5087 14.8019 22.4695 14.6058 22.3933 14.423C22.3172 14.2403 22.2056 14.0744 22.065 13.9349Z"
                        fill="#BDBDBD"
                      />
                    </svg>
                  </div>
                </div>
                <div className="confirmBoxText">
                  <div className="confirmBoxBoldText">
                    Thanks for telling us about your goals
                  </div>
                  <div className="confirmBoxNormalText">
                    We’ll send you a free report within a couple of days, with
                    tips and tricks on how to improve your financial health.
                  </div>
                  <div className="fieldBox sendButton finishButton">
                    <AmmoniteButton
                      buttonText={"Finish"}
                      fontWeight="bold"
                      handlerFunction={() =>
                        sendInformationHandler()
                          .then(() => {
                            console.log("SIGNING OUT");
                            firebase.auth().signOut();
                          })
                          .then(() => {
                            push("/");
                          })
                          .catch(async (err) => {
                            console.log("ERROR-4", err);
                            addToast("SESSION NOT CREATED", {
                              appearance: "error",
                              autoDismiss: false,
                            });
                            try {
                              await resumeSession();
                            } catch (err) {
                              console.log("ERROR", err);
                            }
                            addToast("Session resumed", {
                              appearance: "success",
                              autoDismiss: true,
                            });
                            setLoading(false);
                            setShowConfirmBox(false);
                          })
                      }
                      color="primary"
                      width={215}
                      height={46}
                      buttonLink="/"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {showEmailAlreadyExistsWarning && (
            <div className="blockInputPane">
              <div className="confirmBox">
                <div className="confirmBoxText emailCollisionWarning">
                  <div className="confirmBoxBoldText emailCollisionText">
                    This email is already is use! If you want to keep this
                    session, please use an alternative email. If you want to
                    change the session associated with this email, you will need
                    to contact your administrator.
                  </div>
                  <div className="fieldBox sendButton finishButton emailCollisionCloseButton">
                    <AmmoniteButton
                      buttonText={"Close"}
                      fontWeight="bold"
                      handlerFunction={() => resetPage()}
                      color="danger"
                      width={215}
                      height={46}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="centeredLoader">
          <Loader />
        </div>
      )}
    </div>
  );
}

export default Summary;
