//React
import React, { useState, useContext } from "react";

import "./charge.css";

function AmmoniteCharge({
  chargeName,
  discretionaryCharge,
  paRate,
  totalPoundCharge,
  totalPot,
  totalCharge,
  existingPolicy,
  recommendedPolicy,
}) {
  return (
    <div className="investmentSummaryContainer">
      {chargeName && discretionaryCharge && (
        <div className="investmentField whoInvestment">
          <div className="investmentTitle">{chargeName}</div>
          <div className="investmentItem">{discretionaryCharge}0% p.a.</div>
        </div>
      )}
      {chargeName && paRate !== undefined && (
        <div className="investmentField whoInvestment">
          <div className="investmentTitle">{chargeName}</div>
          <div className="investmentItem">
            {!isNaN(paRate) ? paRate : 0}% p.a.
          </div>
        </div>
      )}
      {chargeName && totalPoundCharge && (
        <div className="investmentField whoInvestment">
          <div className="investmentTitle">{chargeName}</div>
          <div className="investmentItem">£{totalPoundCharge}</div>
        </div>
      )}
      {chargeName &&
      existingPolicy !== undefined &&
      recommendedPolicy !== undefined ? (
        <div className="investmentField whoInvestment">
          <div className="investmentTitle">{chargeName}</div>
          <div className="investmentItem">
            Existing Policy: {existingPolicy}% p.a. Recommended Policy{" "}
            {recommendedPolicy}% p.a.
          </div>
        </div>
      ) : (
        ""
      )}
      {totalPot && totalCharge && (
        <div className="investmentField investmentCharges">
          <div className="investmentTitle">
            On a pot of £{totalPot} this equals
          </div>
          <div className="investmentItem">£{totalCharge}</div>
        </div>
      )}
    </div>
  );
}

export default AmmoniteCharge;
