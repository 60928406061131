import React, { useEffect, useContext } from 'react'
import AmmoniteMaleAvatar from '../../Assets/SVG/maleAvatar'
import AmmoniteFemaleAvatar from '../../Assets/SVG/femaleAvatar'
import AmmoniteMenuCircleEmpty from '../../Assets/SVG/menuCircleEmpty'
import AmmoniteMenuCircleFilledTicked from '../../Assets/SVG/menuCircleFilledTicked'
import AmmoniteTickSVG from '../../Assets/SVG/tick'
import AmmoniteContext from '../../Utils/contexts/AmmoniteContext'
import AmmoniteTrashcan from '../../Assets/SVG/trashcan'

import { makeStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker } from '@material-ui/pickers'

import moment from 'moment'

import './personCard.css'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

const PersonCard = ({
  customer,
  handleCustomerChange,
  firstName,
  lastName,
  gender,
  employment,
  dob,
  customerFirstNameValidCheck,
  customerSecondNameValidCheck,
  customerWorkSituationValidCheck,
  customerOneDoBValidCheck,
  showDelete,
  handleDelete,
}) => {
  const context = useContext(AmmoniteContext)

  const classes = useStyles()

  const handleDobChange = (date) => {
    let _date = moment(date).toDate()
    handleCustomerChange('dob', customer, _date)
  }

  const genderHandler = () => {
    handleCustomerChange('gender', customer, !gender)
  }

  const employmentHandler = (index) => {
    handleCustomerChange('employment', customer, index)
  }

  const handleFirstNameChange = (event) => {
    handleCustomerChange('firstName', customer, event.target.value)
  }

  const handleLastNameChange = (event) => {
    handleCustomerChange('lastName', customer, event.target.value)
  }

  const filled = (
    <div className='filledButtonCardHandlerContainer'>
      <div className='tickSVG'>
        <AmmoniteTickSVG
          colour='white'
          width='22'
          height='18'
          transx='0'
          transy='0'
          scale='1'
        />
      </div>
      <div className='circleSVG'>
        <AmmoniteMenuCircleFilledTicked
          width='36px'
          height='36px'
          cx='18px'
          cy='18px'
          r='14px'
        />
      </div>
    </div>
  )

  const emptyGender = (
    <div className='emptyCardButtonHandlerContainer' onClick={genderHandler}>
      <AmmoniteMenuCircleEmpty
        colour='#3BB9C4'
        width='36px'
        height='36px'
        cx='18px'
        cy='18px'
        r='14px'
      />
    </div>
  )

  const emptyUnemployed = (
    <div
      className='emptyButtonHandlerContainer'
      onClick={() => employmentHandler(0)}>
      <AmmoniteMenuCircleEmpty
        colour='#3BB9C4'
        width='36px'
        height='36px'
        cx='18px'
        cy='18px'
        r='14px'
      />
    </div>
  )

  const emptySelfEmployed = (
    <div
      className='emptyButtonHandlerContainer'
      onClick={() => employmentHandler(1)}>
      <AmmoniteMenuCircleEmpty
        colour='#3BB9C4'
        width='36px'
        height='36px'
        cx='18px'
        cy='18px'
        r='14px'
      />
    </div>
  )

  const emptyEmployed = (
    <div
      className='emptyButtonHandlerContainer'
      onClick={() => employmentHandler(2)}>
      <AmmoniteMenuCircleEmpty
        colour='#3BB9C4'
        width='36px'
        height='36px'
        cx='18px'
        cy='18px'
        r='14px'
      />
    </div>
  )

  const handleDeleteClick = () => {
    handleDelete()
  }

  useEffect(() => {
    if (context.state.clean === true && localStorage.ammoniteContext) {
      // setFirstName(JSON.parse(localStorage.getItem('ammoniteContext')).state.customerInfo.customers[customer].customerFirstName)
      // setLastName(JSON.parse(localStorage.getItem('ammoniteContext')).state.customerInfo.customers[customer].customerLastName)
    }
    if (customerFirstNameValidCheck) {
    } else {
      console.log(
        'CUSTOMER FIRST NAME NOT VALID',
        customer,
        customerFirstNameValidCheck()
      )
    }
  }, [customerFirstNameValidCheck])

  return (
    <div className='personCard'>
      <div className='personHeader'>
        <div className='personalDetails'>Personal details</div>
        <div data-testid='test-svg-avatar' className='avatar'>
          {gender === true ? (
            <div data-testid='male-avatar'>
              <AmmoniteMaleAvatar className='avatar-icon' />
            </div>
          ) : (
            <div data-testid='female-avatar'>
              <AmmoniteFemaleAvatar className='avatar-icon' />
            </div>
          )}
        </div>
      </div>
      <div className={'nameFields'}>
        <div className={`nameContainer`}>
          <div className='nameLabel'>First name</div>
          <form
            autoComplete='off'
            onSubmit={(e) => {
              e.preventDefault()
            }}>
            <input
              type='text'
              name='fname'
              aria-label='fname'
              value={firstName}
              className={`field name ${
                customerFirstNameValidCheck() ? 'valid' : ''
              }`}
              onChange={(event) => handleFirstNameChange(event)}
            />
          </form>
        </div>
        <div className='nameContainer'>
          <div className='nameLabel'>Last name</div>
          <form
            autoComplete='off'
            onSubmit={(e) => {
              e.preventDefault()
            }}>
            <input
              type='text'
              name='fname'
              htmlFor='fname'
              aria-label='fname'
              value={lastName}
              onChange={(event) => handleLastNameChange(event)}
              className={`field name ${
                customerSecondNameValidCheck() ? 'valid' : ''
              }`}
            />
          </form>
        </div>
        {showDelete && (
          <div className='customerDeleteContainer'>
            <div
              className='customerDeleteIcon'
              onClick={() => handleDeleteClick()}>
              <AmmoniteTrashcan colour='#7A7A7A' />
              <div className='customerDeleteText'>Delete</div>
            </div>
          </div>
        )}
      </div>
      <div className='genderContainer'>
        <div className='genderLabel'>Gender</div>
        <div className='genderButtons'>
          {/* Gender Switches start */}
          <div className='gender'>
            {gender === true ? filled : emptyGender}{' '}
            <div className='genderText'>Male</div>
          </div>
          <div className='gender'>
            {gender !== true ? filled : emptyGender}{' '}
            <div className='genderText'>Female</div>
          </div>
          {/* Gender Switches end */}
        </div>
      </div>
      <div className='dobContainer'>
        <div className='nameLabel'>Date of birth</div>
        <div
          className={`field dateName ${
            customerOneDoBValidCheck() ? 'valid' : ''
          }`}>
          <form className={classes.container} noValidate>
            <KeyboardDatePicker
              title='KeyboardDatePicker'
              clearable
              value={dob}
              onChange={(date) => handleDobChange(date)}
              minDate={new Date(1, 1, 1975)}
              format='DD/MM/YYYY'
            />
          </form>
        </div>
      </div>
      <div className={`genderContainer bottomMargin`}>
        <div className='genderLabel workLabel'>Work situation</div>
        <div
          className={`genderButtons ${
            customerWorkSituationValidCheck() ? 'valid' : ''
          }`}>
          {/* Work Switches start */}
          <div className='gender work'>
            {employment === 0 ? filled : emptyUnemployed}{' '}
            <div className='genderText employedText'>Employed</div>
          </div>
          <div className='gender work'>
            {employment === 1 ? filled : emptySelfEmployed}{' '}
            <div className='genderText selfEmployedText'>Self-employed</div>
          </div>
          <div className='gender work'>
            {employment === 2 ? filled : emptyEmployed}{' '}
            <div className='genderText otherText'>Other</div>
          </div>
          {/* Work Switches end */}
        </div>
      </div>
    </div>
  )
}

export default PersonCard
