import React from 'react';

function AmmoniteSBRetirementTab({colour}) {
	return (
		<svg width="50" height="33" viewBox="0 0 50 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H49.5V32.5H8C3.85787 32.5 0.5 29.1421 0.5 25V8Z" fill={colour} stroke={colour}/>
</svg>
	);
}

export default AmmoniteSBRetirementTab;
