export const AmmoniteCashData = (
  startValue,
  cpi,
  cashGrowthRate,
  yearsToRetirement,
  netAnnualContributions
) => {
  let cashData = [startValue];
  let cashNetGrowthRate = (cashGrowthRate - cpi) / 100;
  if (netAnnualContributions === undefined) {
    netAnnualContributions = 0;
  }
  for (let i = 0; i < yearsToRetirement; i++) {
    let cashAa = startValue + netAnnualContributions / cashNetGrowthRate;
    let cashBb = Math.pow(1 + cashNetGrowthRate, i + 1);
    let cashValue =
      cashAa * cashBb - netAnnualContributions / cashNetGrowthRate;
    if (cashValue > 0) {
      cashData.push(Math.round(cashValue * 100) / 100);
    } else {
      cashData.push(0);
    }
  }
  return cashData;
};
