import * as React from 'react';
import useRequest from '../hooks/useRequest';

let SuitabilityReportContext = React.createContext();

const SuitabilityReportContextProvider = ({ children }) => {
	const context = useRequest()

	return <SuitabilityReportContext.Provider value={context}>{children}</SuitabilityReportContext.Provider>;
};

const SuitabilityReportContextConsumer = SuitabilityReportContext.Consumer;

export { SuitabilityReportContextProvider, SuitabilityReportContextConsumer };

export default SuitabilityReportContext;