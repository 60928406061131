import React from 'react';
import './graphHeader.css';

function GraphHeader({ pensionsAmount, investmentAmount, cashAmount, retirementGoalAmount }) {
	return <div className="graphInfo">
        <div className="graphHeaderItem pensionsGraphHeader">Pensions</div>
        <div className="graphHeaderItem pensionsGraphAmount">£{!isNaN(pensionsAmount) ? Math.floor(pensionsAmount) : 0}</div>
        <div className="graphHeaderItem pensionsGraphHeader">Investment</div>
        <div className="graphHeaderItem investmentGraphAmount">£{!isNaN(investmentAmount) ? Math.floor(investmentAmount) : 0}</div>
        <div className="graphHeaderItem pensionsGraphHeader">Cash</div>
        <div className="graphHeaderItem cashGraphAmount">£{!isNaN(cashAmount) ? Math.floor(cashAmount) : 0}</div>
        <div className="retirementGoalGraphHeader">
            <div className="pensionsGraphHeader retirementGraphHeader">Retirement Goal</div>
            <div className="graphHeaderItem">£{Math.floor(retirementGoalAmount)}</div>
        </div>
    </div>;
}

export default GraphHeader;