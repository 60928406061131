//React
import React, { useState, useEffect, Fragment } from 'react';

//external libraries
import Typography from '@material-ui/core/Typography';

//components
import AmmonitePiggy from '../../Assets/SVG/Illustrations/Piggy/Piggy';
import AmmoniteMortgage from '../../Assets/SVG/Illustrations/Mortgage/Mortgage';
import './toast.css';

function AmmoniteToast({ toastType }) {
    const [ showToast, setShowToast ] = useState(true)

    const typeText = {
        pensionAndInvestment : {
            text1: "That's great!",
            text2: "It's important to take control of your financial planning.",
            illustration: <AmmonitePiggy/>,
            class: "piggy"
        },
        mortgageAndProtection : {
            text1: "Well done!",
            text2: "Competitive deals will save you money in the long run.",
            illustration: <AmmoniteMortgage/>,
            class: "house"
        },
    }

    useEffect(() => {
        clearTimeout()
        setTimeout(() => {
          setShowToast(false);
        }, 3000);
      }, []);

	return (
        <Fragment>
            {showToast && <div className="toastContainer">
                <div className='toastIllustration'>
                    {typeText[toastType].illustration}
                </div>
                <div className="toastTextContainer">
                    <Typography color="textColor" align="inherit" fontWeight="fontWeightBold" className={typeText[toastType].class}>
                        {typeText[toastType].text1}
                    </Typography>
                    <Typography color="textColor" align="inherit" fontWeight="fontWeightBold" className={typeText[toastType].class}>
                        {typeText[toastType].text2}
                    </Typography>
                </div>
            </div>}
        </Fragment>
	);
}

export default AmmoniteToast;
