import uuid from "react-uuid";
import moment from "moment";
import { annualSpending } from "../../Utils/data/annualSpending";
import { getSetSBReportIDCurrentIndex } from "../../Utils/data/getSetSBReportIDCurrentIndex";
import firebase from "../firebase";

export const AmmoniteInitialiseSuitabilityContext = async (currentSession) => {
  const sessionRef = firebase
    .firestore()
    .collection("sessions")
    .doc(currentSession);

  //If there is sbcontext data at this index
  const getAnnualSpending = (
    _retirementSpending,
    comforts,
    luxuries,
    _couple
  ) => {
    let _annualSpending = annualSpending(
      _retirementSpending,
      comforts,
      luxuries,
      _couple
    );
    return _annualSpending;
  };

  const getNames = (doc) => {
    let _names;
    if (doc.data().customerInfo.couple === true) {
      _names = `${
        doc.data().customerInfo.customers.customer1.customerFirstName
      } and ${doc.data().customerInfo.customers.customer2.customerFirstName} ${
        doc.data().customerInfo.customers.customer1.customerLastName
      }`;
    } else {
      _names = `${
        doc.data().customerInfo.customers.customer1.customerFirstName
      } ${doc.data().customerInfo.customers.customer1.customerLastName}`;
    }
    return _names;
  };

  let _suitabilityReportContext = await sessionRef
    .get()
    .then(function (doc) {
      //If there is a document
      if (doc.exists) {
        let _currentSuitabilityReport;
        const _names = getNames(doc);

        const contextVars = getSetSBReportIDCurrentIndex(doc);

        if (
          doc.data().suitabilityReports &&
          doc.data().suitabilityReports.length > 0
        ) {
          if (
            contextVars.currentIndex !== -1 &&
            Object.keys(doc.data().suitabilityReports[contextVars.currentIndex])
              .length > 1
          ) {
            // If an index was found with data get that suitability context
            _currentSuitabilityReport =
              doc.data().suitabilityReports[contextVars.currentIndex];
          } else {
            // Create a new suitability context from the current session
            _currentSuitabilityReport = {
              suitabilityReportID: contextVars.suitabilityReportID,
              financialCheck: doc.data().financialCheck,
              annualRetirementSpending: getAnnualSpending(
                doc.data().retirementSpending,
                doc.data().retirementComforts,
                doc.data().retirementLuxuries,
                doc.data().customerInfo.couple
              ),
              sessionDate: moment(doc.data().sessionDate.seconds * 1000).format(
                "YYYY-MM-DD hh:mm:ss"
              ),
              reportAdviser: doc.data().reportAdviser,
              environmentPriority: doc.data().environmentPriority,
              checklist: {
                sentAdviceFrontLink: false,
                recentID: false,
                signedLOAs: false,
                signedCIDD: false,
                completedFactFind: false,
                completedATR: false,
                initialPayment: false,
                callback: false,
                suitabilityReport: false,
                clientAgreement: false,
                implementation: false,
                addedToJHSystem: false,
                transactionsComplete: false,
              },
              customerEmail: doc.data().customerEmail,
              customerTelephone: doc.data().customerTelephone,
              customerInfo: { ...doc.data().customerInfo },
              customerGoals: [...doc.data().customerGoals],
              pensions: [],
              investments: [],
              newPensionsAdvice: [],
              newISAsAdvice: [],
              newGIAsAdvice: [],
              newCashAdvice: [],
              newPolicies: [],
              reportAdviser: doc.data().reportAdviser,
              additionalCustomerInformation: {
                why: " ",
                what: " ",
                notes: " ",
              },
              clientAttitudeToRisk: 0,
              clientCapacityForLoss: 0,
              clientAttitudeToRiskComment: " ",
              clientCapacityForLossComment: " ",
              whyRatingAppropriate: " ",
              factFindNotes: " ",
              names: _names,
              adviser: doc.data().adviser ? doc.data().adviser : 1,
              previewed: false,
            };
          }
        }
        return _currentSuitabilityReport;
      } else {
        //If there is no such document - should show toast warning and push back to dashboard
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });

  return _suitabilityReportContext;
};
