//This document contains those functions used to populate the tables,
//those that required mapping and those that had a significant number
//of conditional appearances.

//images imports
import {
  recommendationsTable,
  portfolioRecommendationsTable,
  pensionsPlanTable,
  pensionsPlanTable2,
  chargesTable,
  goalCardImage,
  goalCardImage2,
  goalCardImage3,
} from "./imageRunFunctions";

//docx imports
import { Paragraph, TextRun, TabStopType } from "docx";

//other imports
import moment from "moment";
import * as tools from "./tools";

const dateNow = Date.now();

export const goalRecommendationTables = (
  customerGoals,
  newPensionsAdvice,
  newISAsAdvice,
  newGIAsAdvice,
  newCashAdvice,
  clientAttitudeToRisk
) => {
  let _goalTables = [];
  customerGoals.forEach(
    (goal, index) =>
      tools.getHasGoalBeenAllocated(
        goal,
        newPensionsAdvice,
        newISAsAdvice,
        newGIAsAdvice,
        newCashAdvice
      ) &&
      _goalTables.push(
        goal !== customerGoals[0]
          ? goal === customerGoals[3]
            ? new TextRun({ break: 2 })
            : new TextRun({ break: 3 })
          : new TextRun({}),
        goalRecommendationImage(goal.goalType),
        new TextRun({
          text:
            goal.goalName +
            ` \tGoal Amount \tAmount under Advice \tYour monthly contributions`,
          bold: true,
        }),
        new TextRun({
          text: `${goal.goalType}\t£${
            goal.goalAmount ? goal.goalAmount : 0
          }\t£${Math.round(
            (tools.getAmount(
              newPensionsAdvice,
              newISAsAdvice,
              newGIAsAdvice,
              newCashAdvice,
              index,
              true
            ) *
              100) /
              100
          ).toFixed()}
        \t£${Math.round(
          (tools.getAmount(
            newPensionsAdvice,
            newISAsAdvice,
            newGIAsAdvice,
            newCashAdvice,
            index,
            false
          ) *
            100) /
            100
        ).toFixed()}`,
          break: 1,
        }),
        new TextRun({
          text: "Investment Platform \tInvestment Solution \tRisk Profile \tHeld in",
          bold: true,
          break: 2,
        }),
        new TextRun({
          text:
            tools.getInvestmentSolutionsByGoal(
              newPensionsAdvice,
              newISAsAdvice,
              newGIAsAdvice,
              index,
              "provider",
              customerGoals,
              clientAttitudeToRisk
            ) +
            "\t" +
            tools.getInvestmentSolutionsByGoal(
              newPensionsAdvice,
              newISAsAdvice,
              newGIAsAdvice,
              index,
              "investmentSolution",
              customerGoals,
              clientAttitudeToRisk
            ) +
            "\t" +
            tools.getGoalRiskProfile(
              goal.goalTimeHorizon,
              clientAttitudeToRisk
            ) +
            " / 10" +
            "\t" +
            tools.getInvestmentHeldIn(
              newPensionsAdvice,
              newISAsAdvice,
              newGIAsAdvice,
              newCashAdvice,
              index
            ),
          break: 1,
        })
      )
  );
  return new Paragraph({
    style: "tableText",
    tabStops: [
      {
        type: TabStopType.LEFT,
        position: 3700,
      },
      {
        type: TabStopType.LEFT,
        position: 6200,
      },
      {
        type: TabStopType.LEFT,
        position: 8600,
      },
    ],
    children: _goalTables,
  });
};

export const goalRecommendationImage = (goalType) => {
  return tools.getGoalTable(goalType);
};

export const showGoalCardRows = (
  firstCardIndex,
  customerGoals,
  customer1DoB
) => {
  return new Paragraph({
    keepLines: true,
    style: "GoalCardText",
    tabStops: [
      {
        type: TabStopType.LEFT,
        position: 3750,
      },
      {
        type: TabStopType.LEFT,
        position: 6750,
      },
    ],
    children: [
      firstCardIndex > 0 ? new TextRun({ break: 2 }) : new TextRun({}),
      goalCardImage(customerGoals[firstCardIndex].goalType),
      customerGoals[firstCardIndex + 1]
        ? goalCardImage2(customerGoals[firstCardIndex + 1].goalType)
        : new TextRun({}),
      customerGoals[firstCardIndex + 2]
        ? goalCardImage3(customerGoals[firstCardIndex + 2].goalType)
        : new TextRun({}),
      new TextRun({ break: 2 }),
      new TextRun({
        text: `${customerGoals[firstCardIndex].goalName}\t${
          customerGoals[firstCardIndex + 1]
            ? customerGoals[firstCardIndex + 1].goalName
            : ""
        }\t${
          customerGoals[firstCardIndex + 2]
            ? customerGoals[firstCardIndex + 2].goalName
            : ""
        }`,
        bold: true,
        size: 18,
      }),
      new TextRun({
        text: `${customerGoals[firstCardIndex].goalType}\t${
          customerGoals[firstCardIndex + 1]
            ? customerGoals[firstCardIndex + 1].goalType
            : ""
        }\t${
          customerGoals[firstCardIndex + 2]
            ? customerGoals[firstCardIndex + 2].goalType
            : ""
        }`,
        break: 2,
      }),
      new TextRun({
        text: `In today's money\t${
          customerGoals[firstCardIndex + 1] ? "In today's money" : ""
        }\t${customerGoals[firstCardIndex + 2] ? "In today's money" : ""}`,
        bold: true,
        break: 3,
      }),
      new TextRun({
        text: `£${customerGoals[firstCardIndex].amountRequiredToday}\t${
          customerGoals[firstCardIndex + 1]
            ? `£${customerGoals[firstCardIndex + 1].amountRequiredToday}`
            : ""
        }\t${
          customerGoals[firstCardIndex + 2]
            ? `£${customerGoals[firstCardIndex + 2].amountRequiredToday}`
            : ""
        }`,
        break: 2,
      }),
      new TextRun({
        text: `In tomorrow's money*\t${
          customerGoals[firstCardIndex + 1] ? "In tomorrow's money*" : ""
        }\t${customerGoals[firstCardIndex + 2] ? "In tomorrow's money*" : ""}`,
        bold: true,
        break: 2,
      }),
      new TextRun({
        text: `£${customerGoals[firstCardIndex].goalAmount}\t${
          customerGoals[firstCardIndex + 1]
            ? `£${customerGoals[firstCardIndex + 1].goalAmount}`
            : ""
        }\t${
          customerGoals[firstCardIndex + 2]
            ? `£${customerGoals[firstCardIndex + 2].goalAmount}`
            : ""
        }`,
        break: 2,
      }),
      new TextRun({
        text: `Years to Goal\t${
          customerGoals[firstCardIndex + 1] ? "Years to Goal" : ""
        }\t${customerGoals[firstCardIndex + 2] ? "Years to Goal" : ""}`,
        bold: true,
        break: 2,
      }),
      new TextRun({
        text: `${
          customerGoals[firstCardIndex] === customerGoals[0]
            ? tools.yearsToRetirement(
                customerGoals[0].goalTimeHorizon,
                customer1DoB
              )
            : customerGoals[firstCardIndex].goalTimeHorizon
        } years\t${
          customerGoals[firstCardIndex + 1]
            ? customerGoals[firstCardIndex + 1].goalTimeHorizon + " years"
            : ""
        }\t${
          customerGoals[firstCardIndex + 2]
            ? customerGoals[firstCardIndex + 2].goalTimeHorizon + " years"
            : ""
        }`,
        break: 2,
      }),
    ],
  });
};

export const goalsRankedByImportance = (customerGoals) => {
  let _goalsRankedText = [];
  tools.getGoalRankedByImportance(customerGoals).forEach((goal) =>
    _goalsRankedText.push(
      _goalsRankedText.length > 1 && goal.focused
        ? new TextRun({ break: 2 })
        : new TextRun({}),
      goal.focused === true
        ? new TextRun({
            text: `${goal.goalName} - ${tools.getGoalImportance(
              parseInt(goal.goalImportance)
            )}`,
            color: "3BB9C4",
            bold: true,
          })
        : new TextRun({}),
      goal.focused === true && goal.softFacts
        ? new TextRun({ text: goal.softFacts, break: 2 })
        : new TextRun({})
    )
  );
  if (
    tools.getDoesGoalFocusExist(customerGoals) &&
    tools.getIsNotEveryGoalFocused(customerGoals)
  ) {
    tools
      .getGoalRankedByImportance(customerGoals)
      .filter((obj) => obj.focused === false || obj.focused === undefined)
      .forEach((goal, index) =>
        _goalsRankedText.push(
          index === 0
            ? new TextRun({
                text: "Goal focus for later",
                break: 2,
                bold: true,
                color: "BF802F",
                size: 26,
              })
            : new TextRun({}),
          new TextRun({
            text: `${goal.goalName} - ${tools.getGoalImportance(
              parseInt(goal.goalImportance)
            )}`,
            color: "3BB9C4",
            bold: true,
            break: 2,
          }),
          goal.focused === true && goal.softFacts
            ? new TextRun({ text: goal.softFacts, break: 2 })
            : new TextRun({})
        )
      );
  }
  return new Paragraph({
    style: "MainText",
    children: _goalsRankedText,
  });
};

export const showInvestmentSummary = (
  investmentsArray,
  adviceType,
  customer1FirstName,
  customer2FirstName
) => {
  let _investmentTables = "";
  let _childrenArray = [];
  switch (adviceType) {
    case "WithLumpSum":
      investmentsArray.forEach((investment) =>
        _childrenArray.push(
          recommendationsTable,
          investment !== investmentsArray[0]
            ? new TextRun({ text: "Who: ", bold: true, break: 1 })
            : new TextRun({ text: "Who: ", bold: true }),
          new TextRun({
            text:
              investment.who === "customer2"
                ? customer2FirstName
                : customer1FirstName,
          }),
          new TextRun({ text: "\tWrapper: ", bold: true }),
          new TextRun({
            text:
              investment.type !== "pension" && investment.type !== "cash"
                ? investment.type.toUpperCase()
                : investment.type.charAt(0).toUpperCase() +
                  investment.type.slice(1),
          }),
          new TextRun({ text: "\tContribution Amount: ", bold: true }),
          new TextRun({
            text:
              tools.getWrapperContributionAmount(investment, "lumpSum") !==
              "£NaN"
                ? tools.getWrapperContributionAmount(investment, "lumpSum")
                : "£0",
          })
        )
      );
      break;
    case "WithMonthly":
      investmentsArray.forEach((investment) =>
        _childrenArray.push(
          recommendationsTable,
          investment !== investmentsArray[0]
            ? new TextRun({ text: "Who: ", bold: true, break: 1 })
            : new TextRun({ text: "Who: ", bold: true }),
          new TextRun({
            text:
              investment.who === "customer2"
                ? customer2FirstName
                : customer1FirstName,
          }),
          new TextRun({ text: "\tWrapper: ", bold: true }),
          new TextRun({
            text:
              investment.type !== "pension" && investment.type !== "cash"
                ? investment.type.toUpperCase()
                : investment.type.charAt(0).toUpperCase() +
                  investment.type.slice(1),
          }),
          new TextRun({ text: "\tContribution Amount: ", bold: true }),
          new TextRun({
            text:
              tools.getWrapperContributionAmount(investment, "monthly") !==
              "£NaN"
                ? tools.getWrapperContributionAmount(investment, "monthly")
                : "£0",
          })
        )
      );
      break;
    case "ExistingPensions":
      investmentsArray.forEach((investment) =>
        _childrenArray.push(
          recommendationsTable,
          investment !== investmentsArray[0]
            ? new TextRun({ text: "Who: ", bold: true, break: 1 })
            : new TextRun({ text: "Who: ", bold: true }),
          new TextRun({
            text:
              investment.owner === "customer2"
                ? customer2FirstName
                : customer1FirstName,
          }),
          new TextRun({ text: "\tPension: ", bold: true }),
          new TextRun({
            text: investment.provider + " - " + investment.policyNumber,
          }),
          new TextRun({ text: "\tRecommendation: ", bold: true }),
          new TextRun({
            text: investment.transferPension ? "Transfer" : "Retain",
          })
        )
      );
      break;
    case "ExistingInvestments":
      investmentsArray.forEach((investment) =>
        _childrenArray.push(
          recommendationsTable,
          investment !== investmentsArray[0]
            ? new TextRun({ text: "Who: ", bold: true, break: 1 })
            : new TextRun({ text: "Who: ", bold: true }),
          new TextRun({
            text:
              investment.owner === "customer2"
                ? customer2FirstName
                : customer1FirstName,
          }),
          new TextRun({ text: "\tInvestment: ", bold: true }),
          new TextRun({
            text: investment.provider + " - " + investment.policyNumber,
          }),
          new TextRun({ text: "\tRecommendation: ", bold: true }),
          new TextRun({
            text: investment.transferISA
              ? "Transfer"
              : investment.sell
              ? "Sell"
              : "Retain",
          })
        )
      );
      break;
    case "NewInvestments":
      investmentsArray.forEach((investment) =>
        _childrenArray.push(
          recommendationsTable,
          investment !== investmentsArray[0]
            ? new TextRun({ text: "Provider: ", bold: true, break: 1 })
            : new TextRun({ text: "Provider: ", bold: true }),
          new TextRun({ text: investment.provider }),
          new TextRun({ text: "\tInvestment Solution: ", bold: true }),
          investment.investmentSolution !== "pension" &&
            investment.investmentSolution !== "cash"
            ? new TextRun({ text: investment.investmentSolution.toUpperCase() })
            : new TextRun({ text: investment.investmentSolution })
        )
      );
      break;
    default:
      new TextRun({ text: " " });
  }
  _investmentTables = new Paragraph({
    tabStops: [
      {
        type: TabStopType.LEFT,
        position: 3250,
      },
      adviceType !== "ExistingPensions" && adviceType !== "ExistingInvestments"
        ? {
            type: TabStopType.LEFT,
            position: 6200,
          }
        : {
            type: TabStopType.LEFT,
            position: 8700,
          },
    ],
    style: "RecommendationTables",
    children: _childrenArray,
  });
  return _investmentTables;
};

export const investmentAfterRecommendations = (
  investments,
  customer1FirstName,
  customer2FirstName,
  retainedType = ""
) => {
  let _childrenArray = [];
  let investmentSolutions = "investmentSolutions";
  let totalInitialAmount = "totalInitialAmount";
  let monthlyContributions = "monthlyContributions";
  if (retainedType === "Pension") {
    investmentSolutions = "investmentSolution";
    totalInitialAmount = "transferValue";
    monthlyContributions = "contributions";
  } else if (retainedType === "Retained Investments") {
    investmentSolutions = "investments";
    totalInitialAmount = "value";
    monthlyContributions = "contributions";
  }
  try {
    investments.forEach((investment) =>
      _childrenArray.push(
        investment.goalsAttributedProperties
          ? investment.goalsAttributedProperties.length > 90
            ? portfolioRecommendationsTable(203)
            : portfolioRecommendationsTable(174)
          : portfolioRecommendationsTable(145),
        investment === investments[0]
          ? new TextRun({ text: "Owner:", bold: true })
          : new TextRun({ text: "Owner:", bold: true, break: 2 }),
        new TextRun({
          text:
            investment.owner === "customer1"
              ? "\t" + customer1FirstName
              : "\t" + customer2FirstName,
        }),
        new TextRun({ text: "Wrapper:", bold: true, break: 1 }),
        retainedType === ""
          ? new TextRun({
              text: "\t" + tools.getCapitalisedType(investment.type),
            })
          : new TextRun({ text: "\t" + investment.planType }),
        investment.provider
          ? new TextRun({ text: "Provider:", bold: true, break: 1 })
          : new TextRun({ text: "" }),
        investment.provider
          ? new TextRun({
              text: `\t${investment.provider}`,
            })
          : new TextRun({ text: "" }),
        investment[investmentSolutions]
          ? new TextRun({ text: "Investment(s):", bold: true, break: 1 })
          : new TextRun({ text: "" }),
        investment[investmentSolutions]
          ? new TextRun({ text: "\t" + investment[investmentSolutions] })
          : new TextRun({ text: "" }),
        new TextRun({ text: "Total Initial Amount:", bold: true, break: 1 }),
        new TextRun({
          text: investment[totalInitialAmount]
            ? "\t£" + investment[totalInitialAmount]
            : "\t-",
        }),
        new TextRun({ text: "Monthly contributions:", bold: true, break: 1 }),
        new TextRun({
          text: investment[monthlyContributions]
            ? "\t£" + investment[monthlyContributions]
            : "\t-",
        }),
        investment.goalsAttributedProperties
          ? new TextRun({ text: "Goals attributed:", bold: true, break: 1 })
          : new TextRun({}),
        investment.goalsAttributedProperties
          ? new TextRun({ text: "\t" + investment.goalsAttributedProperties })
          : "",
        !investment[investmentSolutions] && !investment.provider
          ? new TextRun({ break: 2 })
          : new TextRun({ text: "" }),
        !investment[investmentSolutions] ^ !investment.provider
          ? new TextRun({ break: 1 })
          : new TextRun({ text: "" })
      )
    );
  } catch (err) {
    console.log("ERROR @ investmentAfterRecommendations", err);
  }

  return new Paragraph({
    keepLines: true,
    style: "MainText",
    tabStops: [
      {
        type: TabStopType.LEFT,
        position: 3250,
      },
    ],
    children: _childrenArray,
  });
};

export const showFundProviders = (fundProviders) => {
  let _fundProvidersSection = [];
  try {
    fundProviders.forEach((fundProvider) =>
      _fundProvidersSection.push(
        fundProvider["pParagraphs"].forEach((sectionParagraph) =>
          _fundProvidersSection.push(
            fundProvider["pParagraphs"][0] === sectionParagraph
              ? fundProviders[0] === fundProvider
                ? new TextRun({
                    text: "Why " + fundProvider["provider"] + "?",
                    bold: true,
                    color: "3BB9C4",
                  })
                : new TextRun({
                    text: "Why " + fundProvider["provider"] + "?",
                    bold: true,
                    color: "3BB9C4",
                    break: 2,
                  })
              : new TextRun({}),
            new TextRun({ text: sectionParagraph, break: 2 })
          )
        )
      )
    );
  } catch (err) {
    console.log("ERROR @ showFundProviders", err);
  }

  return new Paragraph({
    style: "MainText",
    children: _fundProvidersSection,
  });
};

export const investmentGoalRiskLevels = (investmentSolutions) => {
  let _investmentSolutions = [];
  let _previousSolution = "";
  try {
    investmentSolutions.forEach((solution) =>
      _investmentSolutions.push(
        solution.goalsAttributed.forEach((goal) =>
          _investmentSolutions.push(
            solution !== investmentSolutions[0] &&
              _previousSolution !== solution.investmentSolution
              ? new TextRun({ break: 2 })
              : new TextRun({}),
            _previousSolution !== solution.investmentSolution
              ? new TextRun({
                  text: `The strategic equity content of the ${
                    solution.investmentSolution
                  } is ${
                    solution.equity
                      ? typeof solution.equity === "number"
                        ? solution.equity * 100 + "%"
                        : solution.equity
                      : "n/a"
                  } (though the investment manager may increase or decrease the equity content above or below this level depending on their views at the time). It is therefore appropriate for the following goals, which based on your risk profile and the years to the goal have the following risk levels:`,
                })
              : new TextRun({}),
            new TextRun({ break: 2 }),
            tools.getGoalRiskTable(goal.goalType),
            new TextRun({ text: "\tGoal:", bold: true }),
            new TextRun({ text: "\t" + goal.goalName }),
            new TextRun({ text: "\tRisk Level:", bold: true }),
            new TextRun({ text: "\t\t" + goal.goalRisk + " / 10" }),
            new TextRun({}),
            (_previousSolution = solution.investmentSolution)
          )
        )
      )
    );
  } catch (err) {
    console.log("ERROR @ investmentGoalRiskLevels", err);
  }
  return new Paragraph({
    style: "MainText",
    tabStops: [
      {
        type: TabStopType.LEFT,
        position: 1300,
      },
      {
        type: TabStopType.LEFT,
        position: 1900,
      },
      {
        type: TabStopType.LEFT,
        position: 8400,
      },
    ],
    children: _investmentSolutions,
  });
};

export const showYourExistingPensionPlans = (
  pensions,
  customers,
  customerGoals,
  clientAttitudeToRisk
) => {
  let _existingPensionPlans = [];

  try {
    pensions.forEach((pension, index) =>
      _existingPensionPlans.push(
        pension !== pensions[0] ? new TextRun({ break: 2 }) : new TextRun({}),
        new TextRun({
          text: `${pension.provider} - ${pension.policyNumber}`,
          color: "3BB9C4",
          bold: true,
        }),
        new TextRun({ break: 2 }),
        pensionsPlanTable,
        new TextRun({ text: "Owner:", bold: true }),
        pension.owner === "customer2"
          ? new TextRun({ text: "\t" + customers.customer2.customerFirstName })
          : new TextRun({ text: "\t" + customers.customer1.customerFirstName }),
        new TextRun({ text: "Provider:", bold: true, break: 1 }),
        pension.provider
          ? new TextRun({ text: "\t" + pension.provider })
          : new TextRun({ text: "\t-" }),
        new TextRun({ text: "Policy Number:", bold: true, break: 1 }),
        pension.policyNumber
          ? new TextRun({ text: "\t" + pension.policyNumber })
          : new TextRun({ text: "\t-" }),
        new TextRun({ text: "Fund Value:", bold: true, break: 1 }),
        pension.fundValue
          ? new TextRun({ text: "\t£" + pension.fundValue })
          : new TextRun({ text: "\t£-" }),
        new TextRun({ text: "Transfer Value:", bold: true, break: 1 }),
        pension.transferValue
          ? new TextRun({ text: "\t£" + pension.transferValue })
          : new TextRun({ text: "\t£-" }),
        new TextRun({ text: "Retirement Date:", bold: true, break: 1 }),
        pension.srdNrd
          ? new TextRun({ text: "\t" + pension.srdNrd })
          : new TextRun({ text: "\t-" }),
        new TextRun({ text: "Investment Choice:", bold: true, break: 1 }),
        pension.investments
          ? new TextRun({ text: "\t" + pension.investments })
          : new TextRun({ text: "\t-" }),
        new TextRun({ text: "Penalties on Transfer", bold: true, break: 1 }),
        parseInt(pension.penaltiesOnTransfer)
          ? new TextRun({ text: "\t£" + parseInt(pension.penaltiesOnTransfer) })
          : new TextRun({ text: "\t£-" }),
        new TextRun({ text: "Guarantees:", bold: true, break: 1 }),
        pension.guaranteeDetails
          ? new TextRun({ text: "\t" + pension.guaranteeDetails })
          : new TextRun({ text: "\t-" }),
        new TextRun({ text: "Charges:", bold: true, break: 1 }),
        pension.charges
          ? new TextRun({ text: "\t" + pension.charges + "% per annum" })
          : new TextRun({ text: "\t-% per annum" }),
        new TextRun({ text: "Expression of Wish:", bold: true, break: 1 }),
        pension.expressionOfWish
          ? new TextRun({ text: "\t" + pension.expressionOfWish })
          : new TextRun({ text: "\t-" }),
        new TextRun({ text: "Protected Tax-free Cash:", bold: true, break: 1 }),
        pension.protectedTaxFreeCash
          ? new TextRun({
              text: `${"\tYes"}`,
            })
          : new TextRun({ text: "\tNo" }),
        new TextRun({ break: 2 }),
        new TextRun({ text: "RECOMMENDATION:", bold: true }),
        new TextRun({
          text: pension.transferPension ? "\tTRANSFER" : "\tRETAIN",
          color: "3BB9C4",
          bold: true,
        }),
        pensionsPlanTable2,
        new TextRun({
          text: `Why ${pension.transferPension ? "transfer" : "retain"}?`,
          color: "3BB9C4",
          bold: true,
          break: 2,
        }),
        pension.guaranteeDetails
          ? new TextRun({
              text: "Your pension has guarantees that would be lost on transfer. These guarantees provide valuable security in retirement.  We therefore recommend that you retain the policy.",
              break: 2,
            })
          : new TextRun({}),
        pension.protectedTaxFreeCash
          ? new TextRun({
              text: "Your pension benefits from a protected tax-free cash amount that exceeds the normal 25%.  This benefit would be lost on transfer, and we therefore recommend that you retain the policy.",
              break: 2,
            })
          : new TextRun({}),
        new TextRun({
          text: !pension.transferPension
            ? "Things to note:"
            : "Options on retirement:",
          break: 2,
          bold: true,
        }),
        new TextRun({
          text: "Your pension offers the following on retirement:",
          break: 2,
        }),
        pension.annuityPurchase
          ? new TextRun({
              text: "Annuity purchase – Annuities offer a guaranteed income for life, however this is subject to rates at the time (currently these are very low). There is little opportunity to be strategic with respect to tax- efficiency, and you cannot vary the amount that you receive year on year.",
              break: 2,
            })
          : new TextRun({}),
        pension.uFPLS
          ? new TextRun({
              text: "Uncrystallised Fund Pension Lump Sum (UFPLS) – UFPLS means that you withdraw the entire pension as a single lump sum at retirement.  You will therefore be subject to income tax on 75% of the pension at that time (after 25% tax-free cash), which is usually not tax-efficient.",
              break: 2,
            })
          : new TextRun({}),
        pension.flexiAccess
          ? new TextRun({
              text: "Flexi-access drawdown -  Flexi-access drawdown allows you to withdraw as much or as little retirement income as you wish, while choosing how the remainder of your fund is invested.  With flexi-access drawdown you can take up to 25% of your pension tax-free, as a lump sum or in portions. ",
              break: 2,
            })
          : new TextRun({}),
        pension.drawdown
          ? new TextRun({
              text: "Drawdown – Under the type of drawdown offered by the pension, you are able to vary the amount of income you take, however there are limits to the income levels and there may be a requirement for a minimum income level.",
              break: 2,
            })
          : new TextRun({}),
        pension.oorGuarantees
          ? new TextRun({
              text: "Your pension is inflexible in retirement, since there are rules associated in order to benefit from the guarantees.",
              break: 2,
            })
          : new TextRun({}),
        new TextRun({
          text: pension.transferPension
            ? "We recommend that you transfer to a pension that offers full flexibility in retirement, and the ability to maximise tax-efficiency with your retirement income."
            : "We believe the benefits detailed above outweigh any downsides of the pension.",
          break: 2,
        }),
        pension.transferPension
          ? new TextRun({ text: "Death benefits:", break: 2, bold: true })
          : new TextRun({}),
        new TextRun({
          text: pension.transferPension
            ? "Your pension offers the following death benefits:"
            : "We can only provide ongoing advice on those new policies that we have recommended. So this policy won’t form part of our ongoing advice, and we won’t monitor it going forward. It also won’t form part of your goals plan. If you’d prefer to transfer, to consolidate all of your policies into your goals plan, let us know and we can help you implement this.",
          break: 2,
        }),
        pension.transferPension && pension.annuityPurchase
          ? new TextRun({
              text: "Annuity purchase – Annuities have much more restrictive death benefits than new flexi-access personal pensions, offering only a spouse’s pension and/or a guarantee period. This means that it is hard to use annuities to plan around inheritance tax, and if you do not have a spouse on death, there are no benefits that can be paid out (unless within the guarantee period).",
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension && pension.uFPLS
          ? new TextRun({
              text: "UFPLS - With UFPLS you draw the entire pension at one time as a lump sum, of which 25% is tax free and the remainder is taxable.  There would therefore be no death benefits after retirement.",
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension && pension.flexiAccess
          ? new TextRun({
              text: "Flexi-access drawdown -  With flexi-access drawdown, the pension will be paid out tax-free to your nominated beneficiaries on death prior to age 75.  After 75, the pension will be paid out subject to tax at your beneficiaries’ marginal rate of income tax.",
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension && pension.drawdown
          ? new TextRun({
              text: "Drawdown – The death benefits are the same as with a flexi-access pension, however they must be paid to a dependent or spouse.",
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({
              text: "We think that the flexibility of the death benefits of new flexi-access pensions is really valuable, and gives you the security that you can have control over who will benefit from your pension and in a way that suits them (since they can usually choose between receiving the benefits as a lump sum or income).  Also, if you die before age 75, then with a new flexi-access pension the death benefits will be paid out tax-free, whereas an inherited annuity in payment will always be subject to income tax at your spouse’s marginal rate of tax.",
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({ text: "Investments", break: 2, bold: true })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({
              text: "By transferring to our recommended pension, we can make sure that your pension investments are in line with your goals and with your risk score, on an ongoing basis. We can also use our recommended investment providers, who we’ve chosen because of their strong investment performance, diversification, and low cost.",
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({
              text: "Access to our ongoing service",
              break: 2,
              bold: true,
            })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({
              text: "By transferring to a new pension, we can provide our ongoing advice on it and make sure that its working hard for you towards your financial goals, in the most tax-efficient way.",
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({ text: "Consolidation", break: 2, bold: true })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({
              text: "Having everything in the same place makes it much easier to keep track of the progress you’re making towards your goals, and makes it easier for you to manage. We think consolidation is really important to help you keep on top of your financial planning.",
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({ text: "Guarantees", break: 2, bold: true })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({
              text: "There are no enhancements, guarantees or any other form of safeguarded rights attached to this pension plan’s fund value or retirement benefits.",
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({ text: "Penalties", break: 2, bold: true })
          : new TextRun({}),
        pension.transferPension && !pension.penaltiesOnTransfer
          ? new TextRun({
              text: "There are no penalties for making the transfer.",
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension && pension.penaltiesOnTransfer > 0
          ? new TextRun({
              text: `There is a penalty of £${pension.penaltiesOnTransfer} for making the transfer, however we believe that this cost is outweighed by the benefits of making the transfer.`,
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({ text: "Charges", break: 2, bold: true })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({
              text: "A comparison of the annual product and investment charges of the policy and the recommended policy is as follows:",
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({
              text: "Total product / investment charge: ",
              bold: true,
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({
              text: `Existing Policy: ${
                pension.charges
              }% p.a. Recommended Policy ${
                tools.getRecommendedPolicy(
                  pensions,
                  pension.owner,
                  customerGoals,
                  clientAttitudeToRisk
                ) !== "NaN"
                  ? tools.getRecommendedPolicy(
                      pensions,
                      pension.owner,
                      customerGoals,
                      clientAttitudeToRisk
                    )
                  : "-"
              }% p.a.`,
            })
          : new TextRun({}),
        pension.transferPension ? chargesTable : new TextRun({}),
        pension.transferPension &&
          pension.charges >
            tools.getRecommendedPolicy(
              pensions,
              pension.owner,
              customerGoals,
              clientAttitudeToRisk
            )
          ? new TextRun({
              text: "Therefore comparing on a like for like basis, the product and investment charges on your pension will reduce by making the recommended transfer.",
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension &&
          pension.charges <
            tools.getRecommendedPolicy(
              pensions,
              pension.owner,
              customerGoals,
              clientAttitudeToRisk
            )
          ? new TextRun({
              text: "Therefore comparing on a like for like basis, the product and investment charges on your pension will increase by making the recommended transfer, however we believe that this increase in charges is merited by the additional benefits of transfer as detailed above.",
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension &&
          pension.charges ===
            tools.getRecommendedPolicy(
              pensions,
              pension.owner,
              customerGoals,
              clientAttitudeToRisk
            )
          ? new TextRun({
              text: "Therefore comparing on a like for like basis, the product and investment charges on your pension will remain the same by making the recommended transfer.",
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({
              text: "By transferring you will also benefit from our ongoing advice on the policy and your goals. Your pension is with you all your life and you may wish to change the amount you contribute into it for regular monthly contributions and also how and when you wish to draw down from it. We will be by your side to help make those adjustments when you seek advice. Our charges are 0.75% per annum, which are added to the product/investment charges detailed above.",
              break: 2,
            })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({ text: "Other things to note", break: 2, bold: true })
          : new TextRun({}),
        pension.transferPension
          ? new TextRun({
              text: "Transfers usually complete in a few weeks, but depending on the providers may be longer than this.  There is a potential for a loss of growth if the market rose while a transfer was pending.",
              break: 2,
            })
          : new TextRun({})
      )
    );
  } catch (err) {
    console.log("ERROR @ showYourExistingPensionPlans", err);
  }

  return new Paragraph({
    keepLines: true,
    tabStops: [
      {
        type: TabStopType.LEFT,
        position: 3250,
      },
    ],
    style: "MainText",
    children: _existingPensionPlans,
  });
};

export const showAdvisor = (adviser) => {
  let _advisorText = [tools.getAdvisor(adviser)];
  if (adviser === 2) {
    _advisorText.push(
      new TextRun({ text: "ROB HARRADINE", break: 7 }),
      new TextRun({ text: "FINANCIAL ADVISER MSCI", break: 1 })
    );
  } else if (adviser === 3) {
    _advisorText.push(
      new TextRun({ text: "MICHAEL HUGHES", break: 7 }),
      new TextRun({ text: "FINANCIAL ADVISER DipPFS", break: 1 })
    );
  } else {
    _advisorText.push(
      new TextRun({ text: "CAROLINE DUFF", break: 7 }),
      new TextRun({ text: "CHARTERED FINANCIAL PLANNER", break: 1 })
    );
  }
  try {
    _advisorText.push(
      new TextRun({
        text:
          moment(dateNow).format("D") +
          " " +
          moment(dateNow).format("MMMM") +
          " " +
          moment(dateNow).format("YYYY"),
        break: 1,
      })
    );
  } catch (err) {
    console.log("ERROR @ showAdvisor", err);
  }
  return new Paragraph({
    keepLines: true,
    style: "AdvisorSignature",
    children: _advisorText,
  });
};
