//React
import React from "react";

//External Libraries
import { Grid } from "@material-ui/core";

//Component imports
import AmmoniteDropdown from "../../../components/Dropdown/Dropdown";

//Styles
import "./sessionCardContents.css";

function SessionDataCardSendReport({
  email,
  adviser,
  handleDropdownChange,
  customerEmail,
  handleChangeEmail,
}) {
  const adviserOptions = [
    { value: "None", text: "Please select..." },
    { value: "Caroline", text: "Caroline Duff" },
    { value: "Rob", text: "Robert Harradine" },
    { value: "Michael", text: "Michael Hughes" },
  ];

  return (
    <Grid className="sessionCardText">
      <Grid container spacing={8}>
        <Grid item xs={6}>
          Customer email address:
        </Grid>
        <Grid item xs={6}>
          {email}
        </Grid>

        <Grid item xs={12} md={6}>
          Send report to email address:
        </Grid>
        <Grid item xs={12} md={6}>
          <input
            type="text"
            placeholder={email}
            value={customerEmail}
            className="sessionCardInput sessionCardSmallInput"
            onChange={(event) => handleChangeEmail(event.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          Selected Adviser:
        </Grid>
        <Grid item xs={12} md={6} className="sessionCardDropdown">
          <AmmoniteDropdown
            isDown={true}
            options={adviserOptions}
            selected={adviser}
            handleDropdownChange={(event) =>
              handleDropdownChange(event.target.value)
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SessionDataCardSendReport;
