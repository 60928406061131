import uuid from "react-uuid";
import firebase from "../firebase";

export const getSetSBReportIDCurrentIndex = (doc) => {
  const currentIndex = localStorage.getItem("currentIndex");

  let suitabilityReportID = doc.data().suitabilityReports[currentIndex]
    .suitabilityReportID;

  const getUniqueSBReportID = (existingID) => {
    let _existingID = existingID;
    if (
      firebase.auth().currentUser &&
      (existingID === "none" || existingID === null || existingID === undefined)
    ) {
      _existingID = (
        firebase.auth().currentUser.uid.substring(0, 3) + uuid()
      ).replace(/ /g, "");
    }
    return _existingID;
  };

  if (!suitabilityReportID || suitabilityReportID === undefined) {
    suitabilityReportID = getUniqueSBReportID(suitabilityReportID);
  }

  const contextVars = {
    suitabilityReportID: suitabilityReportID,
    currentIndex: currentIndex,
  };

  return contextVars;
};
