// Core React dependencies
import React from 'react';

// Component imports
import AmmoniteStepper from '../../containers/Stepper/Stepper';

// Styling
import './about.css';


function About() {
	return <AmmoniteStepper progress={0} headerText="" forwardLink="/basicinformation" />;
}

export default About;
