import React from "react";

function AmmoniteMenuCircleTick() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="16"
        fill="#3BB9C4"
        stroke="#3BB9C4"
        stroke-width="4"
      />
      <path
        d="M14.3636 21.3388L9.94446 16.9197C9.85069 16.8259 9.72352 16.7732 9.59091 16.7732C9.4583 16.7732 9.33112 16.8259 9.23736 16.9197L7.64645 18.5106C7.55268 18.6043 7.5 18.7315 7.5 18.8641C7.5 18.9967 7.55268 19.1239 7.64645 19.2177L14.0101 25.5813C14.2053 25.7766 14.5219 25.7766 14.7172 25.5813L28.3536 11.945C28.5488 11.7497 28.5488 11.4331 28.3536 11.2378L26.7626 9.64693C26.6689 9.55317 26.5417 9.50049 26.4091 9.50049C26.2765 9.50049 26.1493 9.55317 26.0555 9.64693L14.3636 21.3388Z"
        fill="white"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default AmmoniteMenuCircleTick;
