import React from 'react'

//images import
import {
  ammoniteHeader,
  riskToleranceTable,
  chargesTable,
} from './imageRunFunctions'

//docx import
import {
  Document,
  Packer,
  Paragraph,
  AlignmentType,
  TextRun,
  TabStopType,
} from 'docx'

//other imports
import { saveAs } from 'file-saver'
import moment from 'moment'
import * as tools from './tools'
import * as formatting from './formatting'

//styling
import docXStyles from './DocxStyling'

function docxCreator(state, fileName) {
  const context = { state: state }
  const dateNow = Date.now()
  const riskTolerance = tools.getRiskMatrix(
    true,
    context.state.clientAttitudeToRisk
  )
  const riskCategories = tools.riskCategories(
    context.state.clientAttitudeToRisk
  )

  const doc = new Document({
    styles: docXStyles,
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            },
          },
        },
        children: [
          new Paragraph({
            children: [ammoniteHeader],
          }),
          new Paragraph({
            style: 'Heading1',
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({ text: 'FINANCIAL PLAN AND RECOMMENDATIONS' }),
              new TextRun({ text: 'for', break: 1 }),
              new TextRun({
                text: context.state.customerInfo.couple
                  ? context.state.customerInfo.customers.customer1.customerFirstName.toUpperCase() +
                    ' ' +
                    context.state.customerInfo.customers.customer1.customerLastName.toUpperCase() +
                    (' AND ' +
                      context.state.customerInfo.customers.customer2.customerFirstName.toUpperCase() +
                      ' ' +
                      context.state.customerInfo.customers.customer2.customerLastName.toUpperCase())
                  : context.state.customerInfo.customers.customer1.customerFirstName.toUpperCase() +
                    ' ' +
                    context.state.customerInfo.customers.customer1.customerLastName.toUpperCase(),
                break: 1,
              }),
              new TextRun({
                text: `${moment(dateNow).format('D')} ${moment(dateNow).format(
                  'MMMM'
                )} ${moment(dateNow).format('YYYY')}`,
                break: 1,
              }),
              new TextRun({
                text: '________________________________________________________________________________________',
                break: 1,
              }),
            ],
          }),
          new Paragraph({
            text: 'Introduction',
            style: 'Heading2',
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              context.state.customerInfo.couple
                ? new TextRun({
                    text:
                      context.state.customerInfo.customers.customer1
                        .customerFirstName +
                      ' and ' +
                      context.state.customerInfo.customers.customer2
                        .customerFirstName,
                  })
                : new TextRun({
                    text: context.state.customerInfo.customers.customer1
                      .customerFirstName,
                  }),
              new TextRun({
                text: ', we recently took the time to establish your financial goals. Based on these goals and your financial situation, we’ve put together our financial plan and recommendations for you. These are designed to make sure your hard earned money is working as hard for you as possible, and empower you to feel more in control of your financial planning.',
              }),
              new TextRun({
                text: 'It’s important for you to read this report carefully, together with all the supplementary information we’ve provided (including more details about the recommended funds, providers, and wrappers, together with illustrations).',
                break: 2,
              }),
              new TextRun({
                text: 'If you’re happy with our recommendations, and want to proceed, please sign the forms that we’ve provided, or drop us an email at adviser-hub@ammonitewealth.com and we can walk you through the steps.',
                break: 2,
              }),
            ],
          }),
          new Paragraph({
            text: 'Timing',
            style: 'BlueHeading',
          }),
          new Paragraph({
            text: 'Once we’ve received your signed forms, we’ll implement our recommendations.',
            style: 'MainText',
          }),
          new Paragraph({
            text: 'Recommendations Summary',
            style: 'Heading2',
          }),
          new Paragraph({
            text: 'We’ve based our recommendations for you on your financial goals, to give you the best chance of reaching them.',
            style: 'MainText',
          }),
          formatting.goalRecommendationTables(
            context.state.customerGoals,
            context.state.newPensionsAdvice,
            context.state.newISAsAdvice,
            context.state.newGIAsAdvice,
            context.state.newCashAdvice,
            context.state.clientAttitudeToRisk
          ),
          new Paragraph({
            keepNext: true,
            text: 'Our ongoing service',
            style: 'Heading2',
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              new TextRun({
                text: 'We keep a close eye on our recommended platforms and investments, to make sure that they’re doing a good job for our clients. We compare them to the whole market, looking at things like performance, charges and volatility. We are always monitoring the market for enhanced products, so should we recommend any changes we’ll let you know.',
              }),
              new TextRun({
                text: 'We also stay on top of changes in regulation and taxation, which might have an impact on your financial planning. We send regular updates to our clients alerting them to things that might be important for them.',
                break: 2,
              }),
              new TextRun({
                text: 'Our adviser hub is available at any time (subject to working hours) for you to contact. You can get in touch via email, either to your designated qualified adviser, or into the hub at adviser-hub@ammonitewealth.com. You can also chat with us using the chatbox on our website.',
                break: 2,
              }),
              new TextRun({
                text: 'We’ll also be in touch during the year, at important times for financial planning - like before the end of the tax year.',
                break: 2,
              }),
              new TextRun({
                text: 'We’ll formally review your situation annually. Every year we’ll get in touch to check that your goals are still current, and discuss how you’re progressing towards your goals. We’ll also use that time to find out from you if there have been any changes to your circumstances that might impact your financial planning, and see whether your attitude to risk has changed.',
                break: 2,
              }),
              new TextRun({
                text: 'We’ll also provide you with a login that you can use see your portfolio online.',
                break: 2,
              }),
            ],
          }),
          new Paragraph({
            keepNext: true,
            text: 'Your current situation',
            style: 'Heading2',
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              new TextRun({
                text:
                  context.state.customerInfo.customers.customer1
                    .customerFirstName +
                  ' you are aged ' +
                  tools.getCustomerAge(
                    context.state.customerInfo.customers.customer1.customerDoB
                  ) +
                  '. You are ' +
                  tools
                    .getEmploymentSituation(
                      context.state.customerInfo.customers.customer1
                        .customerWorkSituation
                    )
                    .toLowerCase() +
                  '.',
              }),
              context.state.customerInfo.couple
                ? new TextRun({
                    text:
                      ' ' +
                      context.state.customerInfo.customers.customer2
                        .customerFirstName +
                      ' you are aged ' +
                      tools.getCustomerAge(
                        context.state.customerInfo.customers.customer2
                          .customerDoB
                      ) +
                      '. You are ' +
                      tools
                        .getEmploymentSituation(
                          context.state.customerInfo.customers.customer2
                            .customerWorkSituation
                        )
                        .toLowerCase() +
                      '.',
                    break: 2,
                  })
                : new TextRun({ text: ' ' }),
              context.state.customerInfo.children !== undefined &&
              context.state.customerInfo.children.length > 0
                ? new TextRun({
                    text: tools.showChildren(
                      context.state.customerInfo.children
                    ),
                    break: 2,
                  })
                : new TextRun({ text: ' ' }),
              new TextRun({
                text: context.state.additionalCustomerInformation.why,
                break: 2,
              }),
              new TextRun({
                text: context.state.additionalCustomerInformation.what,
                break: 2,
              }),
              new TextRun({
                text: context.state.additionalCustomerInformation.notes,
                break: 2,
              }),
            ],
          }),
          new Paragraph({
            keepNext: true,
            text: 'Your budget and affordability',
            style: 'Heading2',
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              new TextRun({
                text:
                  "It's important to keep enough money in cash for emergencies, in an 'emergency pot'.  For your emergency pot, we recommend that you keep enough in a current account to cover expenditure for 3-6 months.  For you, this means keeping " +
                  tools.budgetAndAffordabilityValues(
                    'customerExpenditureRange',
                    context.state.customerInfo
                  ) +
                  ' in a current account.',
              }),
              tools.budgetAndAffordabilityValues(
                'customerAgreedLumpSum',
                state.customerInfo
              ) > 0
                ? new TextRun({
                    text:
                      'We’ve agreed to provide recommendations for a total lump sum contribution of £' +
                      tools.budgetAndAffordabilityValues(
                        'customerAgreedLumpSum',
                        context.state.customerInfo
                      ) +
                      '. This will leave you with £' +
                      tools.budgetAndAffordabilityValues(
                        'customerStartingCashBalance',
                        context.state.customerInfo
                      ) +
                      " in accessible cash.  You've confirmed that you feel this is affordable.",
                    break: 2,
                  })
                : new TextRun({ text: '' }),
              new TextRun({
                text:
                  'Your total net monthly income is £' +
                  tools.budgetAndAffordabilityValues(
                    'customerNetMonthlyIncome',
                    context.state.customerInfo
                  ) +
                  ' per month.',
                break: 2,
              }),
              new TextRun({
                text:
                  'Your total monthly expenditure is £' +
                  tools.budgetAndAffordabilityValues(
                    'customerMonthlyExpenditure',
                    context.state.customerInfo
                  ) +
                  ' per month.',
                break: 2,
              }),
              new TextRun({
                text:
                  'You therefore have a ' +
                  tools.getDeficitSurplus(context.state.customerInfo) +
                  ' per month.',
                break: 2,
              }),
              context.state.customerInfo.customers.customer1
                .customerAgreedMonthlyBudget > 0 ||
              context.state.customerInfo.customers.customer2
                .customerAgreedMonthlyBudget > 0
                ? new TextRun({
                    text:
                      'We have agreed a budget of £' +
                      tools.budgetAndAffordabilityValues(
                        'customerAgreedMonthlyBudget',
                        context.state.customerInfo
                      ) +
                      " per month, towards your financial goals.  You've confirmed that this is affordable for now and for the foreseeable future, but if this changes please let us know.",
                    break: 2,
                  })
                : new TextRun({ text: '' }),
            ],
          }),
          new Paragraph({
            keepNext: true,
            text: 'Risk tolerance and capacity',
            style: 'Heading2',
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              new TextRun({
                text: 'Each of your goals is different, and because of that we make our investment recommendations on a goal-by-goal basis. To do this, we look at the timeframe for each goal, since you’ll be able to sustain more ‘ups and downs’ for longer term goals than shorter term goals.',
              }),
              new TextRun({
                text: 'We also use what’s known as your ‘risk tolerance’ and ‘capacity for risk’ to make our recommendations.  ‘Risk tolerance’ means how you feel about risk, and how comfortable you are with fluctuations in the value of your investments.  ‘Risk capacity’ is determined by your financial situation, and how much risk you can afford to take.  Things like whether you’re still earning, the level of your income / savings compared to your expenditure, and your level of debt will have an impact on your ‘risk capacity’.',
                break: 2,
              }),
            ],
          }),
          new Paragraph({
            keepNext: true,
            text: 'Your risk tolerance',
            style: 'Heading2',
          }),
          new Paragraph({
            text: 'You completed our online risk tolerance questionnaire, and based on your answers your risk tolerance is as follows:',
            style: 'MainText',
          }),
          new Paragraph({
            text: tools.getCustomerAttitudeToRisk(
              context.state.clientAttitudeToRisk
            ),
            style: 'BlueHeading',
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              new TextRun({ text: riskTolerance[0] }),
              new TextRun({ text: riskTolerance[1], break: 2 }),
            ],
          }),
          new Paragraph({
            keepNext: true,
            children: [riskToleranceTable],
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              new TextRun({
                text:
                  'Timeframe:' +
                  ' '.repeat(33) +
                  '3-4 years' +
                  ' '.repeat(33) +
                  '5-9 years' +
                  ' '.repeat(30) +
                  '10-14 years' +
                  ' '.repeat(33) +
                  '15+ years',
              }),
              new TextRun({
                text:
                  'Risk category:' +
                  ' '.repeat(29) +
                  riskCategories[0] +
                  ' '.repeat(26) +
                  riskCategories[1] +
                  ' '.repeat(39) +
                  riskCategories[2] +
                  ' '.repeat(46) +
                  riskCategories[3],
                break: 1,
              }),
            ],
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              new TextRun({ text: riskTolerance[2], break: 1 }),
              new TextRun({ text: riskTolerance[3], break: 2 }),
              new TextRun({ text: riskTolerance[4], break: 2 }),
              new TextRun({ text: riskTolerance[5], break: 2 }),
              new TextRun({ text: riskTolerance[6], break: 2 }),
            ],
          }),
          new Paragraph({
            text: 'Your comments',
            style: 'BlueHeading',
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              new TextRun({
                text: `We discussed your risk tolerance, and you confirmed that ${tools.getCustomerAttitudeToRisk(
                  context.state.clientAttitudeToRisk
                )} was appropriate.`,
              }),
              new TextRun({
                text: `You said that '${context.state.clientAttitudeToRiskComment}'.`,
                break: 2,
              }),
            ],
          }),
          new Paragraph({
            keepNext: true,
            text: 'Your risk capacity',
            style: 'Heading2',
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              new TextRun({ text: context.state.whyRatingAppropriate }),
              new TextRun({
                text: 'We therefore believe that the most appropriate risk capacity category for you is:',
                break: 2,
              }),
            ],
          }),
          new Paragraph({
            text: tools.getCapacityForLoss(context.state.clientCapacityForLoss),
            style: 'BlueHeading',
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              new TextRun({
                text: tools.getCapacityForLossStatement(
                  context.state.clientCapacityForLoss
                ),
              }),
            ],
          }),
          new Paragraph({
            text: 'Your comments',
            style: 'BlueHeading',
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              new TextRun({
                text: `We discussed your risk capacity, and you confirmed that ${tools
                  .getCapacityForLoss(context.state.clientCapacityForLoss)
                  .toLowerCase()} was appropriate.`,
              }),
              new TextRun({
                text: `You said that '${context.state.clientCapacityForLossComment}'.`,
                break: 2,
              }),
            ],
          }),
          new Paragraph({
            keepNext: true,
            text: 'Your financial plan and recommendations',
            style: 'Heading2',
          }),
          new Paragraph({
            text: 'We’ve based our recommendations for you on your financial goals, to give you the best chance of reaching them.',
            style: 'MainText',
          }),
          new Paragraph({
            keepNext: true,
            text: 'Your goals',
            style: 'Heading2',
          }),
          new Paragraph({
            text: 'We established that you have the following financial goals:',
            style: 'MainText',
          }),
          formatting.showGoalCardRows(
            0,
            context.state.customerGoals,
            context.state.customerInfo.customers.customer1.customerDoB
          ),
          context.state.customerGoals.length > 3
            ? formatting.showGoalCardRows(3, context.state.customerGoals)
            : new Paragraph({}),
          new Paragraph({
            keepNext: true,
            style: 'Heading2',
            children: [new TextRun({ text: 'Goal focus for now', break: 3 })],
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              tools.getIsNotEveryGoalFocused(context.state.customerGoals) &&
              context.state.customerGoals.length > 1
                ? new TextRun({
                    text: "We think it's important to get you on your way towards your most important goals first. Because of that, we’ve focused on these goals in this report. Once you’re on your way towards these goals, we’ll help you on your way to achieving your other goals too.",
                  })
                : new TextRun({ text: '' }),
            ],
          }),
          new Paragraph({
            text: 'Retirement Spending - Primary',
            style: 'BlueHeading',
          }),
          new Paragraph({
            text:
              "You're targeting annual spending in retirement of £" +
              (context.state.annualRetirementSpending
                ? context.state.annualRetirementSpending
                : 0) +
              '. That means you might need a retirement pot of around £' +
              (context.state.customerGoals[0].goalAmount
                ? context.state.customerGoals[0].goalAmount
                : 0) +
              " in tomorrow's money (i.e. after inflation), assuming a withdrawal rate of 3.5% and inflation at 2%.",
            style: 'MainText',
          }),
          context.state.customerGoals[0].softFacts
            ? new Paragraph({
                style: 'MainText',
                text: context.state.customerGoals[0].softFacts,
              })
            : new Paragraph({}),
          formatting.goalsRankedByImportance(context.state.customerGoals),
          new Paragraph({
            keepNext: true,
            style: 'Heading2',
            text: 'How we recommend you reach your goals',
          }),
          tools.getContributionTypeExists(
            'getIsInvestmentLumpSum',
            context.state.newPensionsAdvice,
            context.state.newISAsAdvice,
            context.state.newGIAsAdvice,
            context.state.newCashAdvice
          )
            ? new Paragraph({
                text: 'Invest for long term goals',
                style: 'BlueHeading',
                keepNext: true,
              })
            : new Paragraph({}),
          tools.getContributionTypeExists(
            'getIsInvestmentLumpSum',
            context.state.newPensionsAdvice,
            context.state.newISAsAdvice,
            context.state.newGIAsAdvice,
            context.state.newCashAdvice
          )
            ? new Paragraph({
                style: 'MainText',
                children: [
                  new TextRun({
                    text: 'Current low interest rates mean that cash holdings may lose value against inflation over the long term.',
                  }),
                  new TextRun({
                    text: "We think it's important to benefit from investment growth, to grow your pots and help you reach your goals. It’s also important to put your money in the right ‘tax wrapper’, so that you can benefit from tax reliefs available. We recommend the following lump sum contributions:",
                    break: 2,
                  }),
                ],
              })
            : '',
          tools.getContributionTypeExists(
            'getInvestmentsWithLumpSum',
            context.state.newPensionsAdvice,
            context.state.newISAsAdvice,
            context.state.newGIAsAdvice,
            context.state.newCashAdvice
          ).length > 0
            ? formatting.showInvestmentSummary(
                tools.getContributionTypeExists(
                  'getInvestmentsWithLumpSum',
                  context.state.newPensionsAdvice,
                  context.state.newISAsAdvice,
                  context.state.newGIAsAdvice,
                  context.state.newCashAdvice
                ),
                'WithLumpSum',
                context.state.customerInfo.customers.customer1
                  .customerFirstName,
                context.state.customerInfo.customers.customer2.customerFirstName
              )
            : '',
          tools.getContributionTypeExists(
            'getIsMonthly',
            context.state.newPensionsAdvice,
            context.state.newISAsAdvice,
            context.state.newGIAsAdvice,
            context.state.newCashAdvice
          )
            ? new Paragraph({
                text: 'Start making smart contributions',
                style: 'BlueHeading',
                keepNext: true,
              })
            : new Paragraph({}),
          tools.getContributionTypeExists(
            'getIsMonthly',
            context.state.newPensionsAdvice,
            context.state.newISAsAdvice,
            context.state.newGIAsAdvice,
            context.state.newCashAdvice
          )
            ? new Paragraph({
                style: 'MainText',
                children: [
                  new TextRun({
                    text: 'We’ve used your agreed budget to make our recommendations for your regular monthly contributions. Setting up monthly contributions is a great start. By contributing on a regular basis you can benefit from what’s called ‘pound cost averaging’, which means that you’ll benefit as investments go up and down (since when investments go down you’ll be buying at a lower cost). This can really help with long term investing.',
                  }),
                  new TextRun({
                    text: 'We recommend the following monthly contributions:',
                    break: 2,
                  }),
                ],
              })
            : '',
          tools.getContributionTypeExists(
            'getInvestmentsWithMonthly',
            context.state.newPensionsAdvice,
            context.state.newISAsAdvice,
            context.state.newGIAsAdvice,
            context.state.newCashAdvice
          ).length > 0
            ? formatting.showInvestmentSummary(
                tools.getContributionTypeExists(
                  'getInvestmentsWithMonthly',
                  context.state.newPensionsAdvice,
                  context.state.newISAsAdvice,
                  context.state.newGIAsAdvice,
                  context.state.newCashAdvice
                ),
                'WithMonthly',
                context.state.customerInfo.customers.customer1
                  .customerFirstName,
                context.state.customerInfo.customers.customer2.customerFirstName
              )
            : '',
          context.state.pensions.length > 0
            ? new Paragraph({
                text: 'Take control of your pensions',
                style: 'BlueHeading',
                keepNext: true,
              })
            : new Paragraph({}),
          context.state.pensions.length > 0
            ? new Paragraph({
                text: 'Pensions are the most powerful tool to plan for your retirement.  It’s really important to make sure that your pensions are working as hard for you as possible, and that when you come to retirement you’ll be able to use them in the way you want to.  Some pensions are restrictive in the way you can draw money from them, the investments might not be in line with your risk profile, they may have high charges etc.  We’ve looked at all of the features of those pensions you asked us to research, and recommend the following (our full analysis of the pensions is detailed later in this report).',
                style: 'MainText',
              })
            : '',
          context.state.pensions.length > 0
            ? formatting.showInvestmentSummary(
                context.state.pensions,
                'ExistingPensions',
                context.state.customerInfo.customers.customer1
                  .customerFirstName,
                context.state.customerInfo.customers.customer2.customerFirstName
              )
            : '',
          context.state.investments.length > 0
            ? new Paragraph({
                text: 'Focus on your investments',
                style: 'BlueHeading',
                keepNext: true,
              })
            : new Paragraph({}),
          context.state.investments.length > 0
            ? new Paragraph({
                text: "It's important to make sure that you invest in line with your goals, to give you the best chance of reaching them.  We've looked at your existing investment holdings, and recommend the following:",
                style: 'MainText',
              })
            : '',
          context.state.investments.length > 0
            ? formatting.showInvestmentSummary(
                context.state.investments,
                'ExistingInvestments',
                context.state.customerInfo.customers.customer1
                  .customerFirstName,
                context.state.customerInfo.customers.customer2.customerFirstName
              )
            : '',
          new Paragraph({
            text: 'Use our recommended provider and investment solution',
            style: 'BlueHeading',
            keepNext: true,
          }),
          new Paragraph({
            text: 'We’ve looked at the whole of the market, so that you don’t have to. It’s important to make sure that your providers and investment solutions work for you. That means choosing providers that have good administration and are cost-efficient, with user-friendly online tools and great reporting. The investment solutions need to be right for your goals and risk profile, with good diversification and low charges.',
            style: 'MainText',
          }),
          context.state.newPensionsAdvice !== undefined &&
          context.state.newISAsAdvice !== undefined &&
          context.state.newGIAsAdvice !== undefined &&
          context.state.newCashAdvice !== undefined &&
          (context.state.newPensionsAdvice.length > 0 ||
            context.state.newISAsAdvice.length > 0 ||
            context.state.newGIAsAdvice.length > 0 ||
            context.state.newCashAdvice.length > 0)
            ? new Paragraph({
                text: 'Based on these considerations, we’ve chosen the following solutions for you:',
                style: 'MainText',
              })
            : new Paragraph({}),
          context.state.allInvestmentSolutionsDeduped !== undefined &&
          context.state.allInvestmentSolutionsDeduped.length > 0
            ? formatting.showInvestmentSummary(
                context.state.allInvestmentSolutionsDeduped,
                'NewInvestments',
                context.state.customerInfo.customers.customer1
                  .customerFirstName,
                context.state.customerInfo.customers.customer2.customerFirstName
              )
            : '',
          new Paragraph({
            text: 'For further details on why we recommend these solutions, please see the relevant sections below.',
            style: 'MainText',
          }),
          new Paragraph({
            text: 'Will I reach my goals?',
            style: 'BlueHeading',
            keepNext: true,
          }),
          new Paragraph({
            text: 'We can’t guarantee that you’ll reach your goals, but our recommendations are designed to put you on the best path to getting there. If you’re able to increase your contributions in the future, that might help you reach your goals faster.',
            style: 'MainText',
          }),
          new Paragraph({
            text: 'Your portfolio after our recommendations',
            style: 'Heading2',
            keepNext: true,
          }),
          new Paragraph({
            text: 'After you implement our recommendations, your portfolio will be made up of the following policies (excluding any policies you didn’t ask us to research for you, since they fall outside our advice):',
            style: 'MainText',
          }),
          context.state.investmentSolutionsGroupedByGoals !== undefined
            ? formatting.investmentAfterRecommendations(
                context.state.investmentSolutionsGroupedByGoals,
                context.state.customerInfo.customers.customer1
                  .customerFirstName,
                context.state.customerInfo.customers.customer2.customerFirstName
              )
            : new Paragraph({}),
          context.state.pensions !== undefined
            ? formatting.investmentAfterRecommendations(
                tools.getIsPensionRetained(context.state.pensions),
                context.state.customerInfo.customers.customer1
                  .customerFirstName,
                context.state.customerInfo.customers.customer2
                  .customerFirstName,
                'Pension'
              )
            : new Paragraph({}),
          context.state.investments !== undefined
            ? formatting.investmentAfterRecommendations(
                tools.getIsISARetained(context.state.investments),
                context.state.customerInfo.customers.customer1
                  .customerFirstName,
                context.state.customerInfo.customers.customer2
                  .customerFirstName,
                'Retained Investments'
              )
            : new Paragraph({}),
          new Paragraph({
            text: "Why we've made these recommendations",
            style: 'Heading2',
            keepNext: true,
          }),
          tools.areTherePensionContributions(context.state.newPensionsAdvice) ||
          tools.areThereOtherInvestmentContributions(
            context.state.newISAsAdvice,
            context.state.newGIAsAdvice
          )
            ? new Paragraph({
                text: 'Why these contribution amounts?',
                style: 'BlueHeading',
                keepNext: true,
              })
            : new Paragraph({}),
          tools.areTherePensionContributions(context.state.newPensionsAdvice) ||
          tools.areThereOtherInvestmentContributions(
            context.state.newISAsAdvice,
            context.state.newGIAsAdvice
          )
            ? new Paragraph({
                style: 'MainText',
                children: [
                  new TextRun({
                    text: 'We discussed your budget and the amount you could invest as a lump sum contribution towards your goals, and based on our recommendations on this.',
                  }),
                  new TextRun({
                    text: 'In terms of the split between the different wrappers (i.e. ISA / Pension / Cash / General Investment Account), we’ve used our tools to come up with the best plan for you. We take into account the goal amounts that you’re trying to achieve, and when you’re aiming to get there. We also take into consideration your risk tolerance and time horizon for each goal. Additionally, we take government tax allowances into account, because we want you to be able to benefit from these as much as possible.',
                    break: 2,
                  }),
                ],
              })
            : '',
          tools.areTherePensionContributions(context.state.newPensionsAdvice)
            ? new Paragraph({
                text: 'Your pension contributions',
                style: 'BlueHeading',
                keepNext: true,
              })
            : new Paragraph({}),
          tools.areTherePensionContributions(context.state.newPensionsAdvice)
            ? new Paragraph({
                style: 'MainText',
                children: [
                  new TextRun({
                    text: 'Pensions are a very tax-efficient way of planning for retirement, since contributions benefit from tax relief at your marginal rate of tax. Personal contributions to a registered pension scheme receive basic rate tax relief at source. This means for every £80 paid in, the pension fund will receive an additional £20 in tax relief. Higher and additional rate taxpayers can claim further tax relief through their tax return.',
                  }),
                  new TextRun({
                    text: 'Tax relief on personal gross contributions is restricted to the higher of £3,600 or 100% of your ‘relevant UK earnings’ each year and is capped at an annual allowance of £40,000 (unused allowances can be carried forward from the previous three tax years). If your income is over £240,000, your annual allowance is restricted.',
                    break: 2,
                  }),
                  new TextRun({
                    text: 'Based on your income and contribution history, you have the scope to make the recommended contributions without exceeding your annual allowance.',
                    break: 2,
                  }),
                ],
              })
            : '',
          tools.getWhosePensions(
            context.state.customerInfo,
            context.state.newPensionsAdvice
          ) !== false
            ? new Paragraph({
                style: 'MainText',
                children: [
                  new TextRun({
                    text:
                      tools.getWhosePensions(
                        context.state.customerInfo,
                        context.state.newPensionsAdvice
                      ) +
                      ', we recommend that you make contributions as employer contributions from your limited company. Your limited company can contribute pre-taxed company income to your pension. Because an employer contribution counts as an allowable business expense, your company receives tax relief against corporation tax, so the company could save up to 19% in corporation tax.',
                  }),
                  new TextRun({
                    text: 'Another benefit is that employers don’t have to pay National Insurance on pension contributions, so by contributing directly into your pension rather than paying the equivalent in salary, you save up to 13.8%.',
                    break: 2,
                  }),
                  new TextRun({
                    text: 'This means that in total, your company can save up to 32.8% by paying money directly into your pension rather than paying money in the form of a salary. ',
                    break: 2,
                  }),
                ],
              })
            : new Paragraph({}),
          context.state.newPensionsAdvice.length > 0
            ? new Paragraph({
                text: 'Why these ‘wrappers’?',
                style: 'BlueHeading',
                keepNext: true,
              })
            : new Paragraph({}),
          context.state.newPensionsAdvice.length > 0
            ? new Paragraph({
                style: 'MainText',
                children: [
                  new TextRun({ text: 'Pension portfolio', bold: true }),
                  new TextRun({
                    text: ' - Not only do pensions benefit from tax relief on contributions, but they also benefit from tax-free growth within the pension. That means that the capital growth and dividends you may receive within the pension are tax-free. This makes pensions a great tax wrapper. However, there are rules about when you can access the money in your pension. Unless you’re retiring early because of ill health, the earliest you can currently draw money from your pension is age 55. We’ve taken this into account in our recommendations.',
                  }),
                  new TextRun({
                    text: 'We think it’s important to use a self-invested personal pension rather than transferring into an employer scheme, since you have more control over a personal pension, and to keep consistency even if you were to change employment in the future.  You will also have a wider scope of investment opportunities available to you, including our recommended investments.',
                    break: 2,
                  }),
                  new TextRun({
                    text: 'We’ve considered whether you should use a ‘Stakeholder pension’, however we’ve discounted this because our recommended pension is cheaper, and offers our recommended investment solution.',
                    break: 2,
                  }),
                  new TextRun({
                    text: "We've recommended a pension that offers 'flexi access drawdown'. Flexi-access drawdown is a pension product that lets you access your pension savings whenever you need to in retirement, while reinvesting your remaining funds in a way that’s designed to provide an ongoing retirement income. Flexi-access drawdown allows you to withdraw as much or as little retirement income as you wish, while choosing how the remainder of your fund is invested. As part of the wide-ranging 2015 pension reforms, which allow those nearing retirement to gain greater control over their pensions, flexi-access drawdown is available from your 55th birthday and provides a hassle-free way to unlock cash from your pension.  With flexi-access drawdown you can take up to 25% of your pension tax-free, as a lump sum or in portions. Whether you intend to use it to supplement your income, to help loved ones or fulfil a lifelong dream, it can be yours to spend however you wish.",
                    break: 2,
                  }),
                  new TextRun({
                    text: 'Once you’ve taken your tax-free lump sum, the rest of your pension pot can be left invested. This offers the opportunity for growth, unlike an annuity which provides a fixed income.',
                    break: 2,
                  }),
                  new TextRun({
                    text: 'If you decide to draw a regular income it’s possible to manage the amount you want, and at your desired intervals. If you wish, this can be adjusted periodically to help fit your circumstances and can save you income tax with some clever planning.',
                    break: 2,
                  }),
                  new TextRun({
                    text: "Should you pass away before releasing all of your pension, you can nominate someone to receive the remaining funds on your behalf - such as a relative or charity.  On death before age 75, they will inherit the pot tax-free.  On death after age 75, it's paid out subject to their marginal rate of income tax.",
                    break: 2,
                  }),
                ],
              })
            : new Paragraph({}),

          context.state.newISAsAdvice.length > 0
            ? new Paragraph({
                style: 'MainText',
                children: [
                  new TextRun({ text: 'ISA', bold: true, break: 2 }),

                  new TextRun({
                    text: ' - Individual Savings Accounts, or ‘ISAs’, are a good wrapper to invest in, since investments within an ISA are free from income and capital gains tax. Not only that, but subject to restrictions on the investments themselves, you can access the money within your ISA without restriction. You can therefore use your ISA for those goals that you’ll need before retirement. The ISA allowance is currently £20,000 per person, per year. Our recommendations are based on what you’ve told us about your ISA contributions, and are designed to maximise your ISA contributions while taking into account your budget and pension contributions.',
                  }),
                ],
              })
            : new Paragraph({}),

          context.state.newGIAsAdvice.length > 0
            ? new Paragraph({
                style: 'MainText',
                children: [
                  new TextRun({ text: 'GIA', bold: true, break: 2 }),

                  new TextRun({
                    text: " - A general investment account, or 'GIA' is a simple way of investing more money once you've reached your annual ISA allowance. Unlike an ISA or pension, there are no tax benefits, although you can contribute as much as you wish.  Like an ISA, you can access the money without restriction. You pay income tax on any income you receive from the GIA, and capital gains tax on any realised gain you make on your GIA.  The tax paid will depend on your personal tax situation. For further details on the taxation of GIAs, please see the 'Key Features' document.",
                  }),
                ],
              })
            : new Paragraph({}),

          context.state.newCashAdvice.length > 0
            ? new Paragraph({
                style: 'MainText',
                children: [
                  new TextRun({ text: 'Cash', bold: true, break: 2 }),

                  new TextRun({
                    text: " - For shorter term goals, we recommend keeping money in a cash account, to protect against the investment risks of your money going up and down over the short time horizon.  We don't provide advice on which cash account to use, however you can get details of savings rates available using the links on our website.",
                  }),
                ],
              })
            : new Paragraph({}),
          formatting.showFundProviders(
            tools.getFundProviders(
              context.state.newPensionsAdvice,
              context.state.newISAsAdvice,
              context.state.newGIAsAdvice,
              context.state.customerGoals,
              context.state.clientAttitudeToRisk
            )
          ),
          context.state.investmentSolutionsGroupedByGoals.length > 0
            ? new Paragraph({
                text: 'How do the investments relate to my goals?',
                style: 'BlueHeading',
                keepNext: true,
              })
            : new Paragraph({}),
          context.state.investmentSolutionsGroupedByGoalsRisk !== undefined
            ? formatting.investmentGoalRiskLevels(
                context.state.investmentSolutionsGroupedByGoalsRisk
              )
            : new Paragraph({}),
          new Paragraph({
            text: 'What are the charges?',
            style: 'Heading2',
            keepNext: true,
          }),
          new Paragraph({
            style: 'MainText',
            tabStops: [
              {
                type: TabStopType.LEFT,
                position: 6250,
              },
            ],
            children: [
              new TextRun({
                text: 'From our independent, whole of market research, we choose providers that offer excellent service and expertise, at low cost. We continuously research the market for best of breed product providers and monitor investment performance to optimise customer satisfaction.',
              }),
              new TextRun({
                text: 'Your initial implementation charges and ongoing costs will be taken from the products on an ongoing basis, so you don’t have to worry about paying these from your bank account. Taking charges from a pension is tax-efficient since it means that you’re paying from a policy that has benefited from tax relief.',
                break: 2,
              }),
              new TextRun({
                text: 'The charges are based on percentages.  This means that if the investments go up or down in value, then the charges will fluctuate too.  The charges are based on just those new policies that will be under our advice, and they’ll come from the investments so you don’t have to worry about paying these from your bank account.',
                break: 2,
              }),
              new TextRun({
                text: 'It’s important to look at the illustrations we’ve provided to see what the impact of charges will be on your portfolio.',
                break: 2,
              }),
              new TextRun({
                text: 'Based on current values (including the first years’ contributions as appropriate), the annual charges will be:',
                break: 2,
              }),
              new TextRun({
                text: 'ISA / General Investment Account:',
                bold: true,
                break: 2,
              }),
              new TextRun({
                text: `  ${
                  tools.getISAGIACharges(
                    context.state.newISAsAdvice,
                    context.state.newGIAsAdvice,
                    false,
                    context.state.costumerGoals,
                    context.state.clientAttitudeToRisk
                  )
                    ? tools
                        .getISAGIACharges(
                          context.state.newISAsAdvice,
                          context.state.newGIAsAdvice,
                          false,
                          context.state.costumerGoals,
                          context.state.clientAttitudeToRisk
                        )
                        .toFixed(2)
                    : 0
                }% p.a.`,
              }),
              chargesTable,
              new TextRun({ text: 'Pension:', bold: true, break: 2 }),
              new TextRun({
                text: `  ${
                  tools.getPensionCharges(
                    context.state.newPensionsAdvice,
                    context.state.totalWeightedCharge,
                    context.state.customerGoals,
                    context.state.clientAttitudeToRisk
                  ) !== 'NaN'
                    ? tools.getPensionCharges(
                        context.state.newPensionsAdvice,
                        context.state.totalWeightedCharge,
                        context.state.customerGoals,
                        context.state.clientAttitudeToRisk
                      )
                    : 0
                }% p.a.`,
              }),
              chargesTable,
              tools.getISAGIACharges(
                context.state.newISAsAdvice,
                context.state.newGIAsAdvice,
                'discretionary',
                context.state.customerGoals,
                context.state.clientAttitudeToRisk
              ) === true
                ? new TextRun({
                    text: 'Discretionary Fund Management:',
                    bold: true,
                    break: 2,
                  })
                : new TextRun({}),
              tools.getISAGIACharges(
                context.state.newISAsAdvice,
                context.state.newGIAsAdvice,
                'discretionary',
                context.state.customerGoals,
                context.state.clientAttitudeToRisk
              ) === true
                ? new TextRun({ text: `  0.90% p.a.` })
                : '',
              tools.getISAGIACharges(
                context.state.newISAsAdvice,
                context.state.newGIAsAdvice,
                'discretionary',
                context.state.customerGoals,
                context.state.clientAttitudeToRisk
              ) === true
                ? chargesTable
                : '',
              new TextRun({
                text: 'Underlying Investments:',
                bold: true,
                break: 2,
              }),
              new TextRun({
                text: `  ${
                  tools.getISAGIAPensionCharges(
                    context.state.newPensionsAdvice,
                    context.state.newISAsAdvice,
                    context.state.newGIAsAdvice,
                    false,
                    context.state.customerGoals,
                    context.state.clientAttitudeToRisk
                  ) !== 'NaN'
                    ? tools.getISAGIAPensionCharges(
                        context.state.newPensionsAdvice,
                        context.state.newISAsAdvice,
                        context.state.newGIAsAdvice,
                        false,
                        context.state.customerGoals,
                        context.state.clientAttitudeToRisk
                      )
                    : 0
                }% p.a.`,
              }),
              chargesTable,
              new TextRun({
                text: 'Financial Planning and Advice:',
                bold: true,
                break: 2,
              }),
              new TextRun({ text: `  0.75% p.a.` }),
              chargesTable,
              new TextRun({ text: 'Total £ charge:', bold: true, break: 2 }),
              new TextRun({
                text: `  £${tools.getTotalPoundCharge(
                  context.state.newPensionsAdvice,
                  context.state.newISAsAdvice,
                  context.state.newGIAsAdvice,
                  context.state.customerGoals,
                  context.state.clientAttitudeToRisk
                )}`,
              }),
              chargesTable,
              new TextRun({
                text: 'Total weighted charge:',
                bold: true,
                break: 2,
              }),
              new TextRun({
                text: `  ${tools
                  .getTotalWeightedCharge(
                    context.state.newPensionsAdvice,
                    context.state.newISAsAdvice,
                    context.state.newGIAsAdvice,
                    context.state.customerGoals,
                    context.state.clientAttitudeToRisk
                  )
                  .toFixed(2)}% p.a.`,
              }),
              chargesTable,
              tools.getISAGIACharges(
                context.state.newISAsAdvice,
                context.state.newGIAsAdvice,
                'discretionary',
                context.state.customerGoals,
                context.state.clientAttitudeToRisk
              ) === true
                ? new TextRun({
                    text: 'Please note that, within your Cazenove discretionary managed portfolio, there are additional charges for the underlying holdings, which will vary.  For full details of the underlying charges, please see your investment proposal.',
                    break: 2,
                  })
                : new TextRun({}),
              new TextRun({
                text: 'On a long-term basis, we target the following annual growth rates before charges (though investments go up and down):',
                break: 2,
              }),
              new TextRun({
                text: 'Low-medium risk - circa 3.0% annualised',
                break: 2,
              }),
              new TextRun({
                text: 'Medium risk - circa 5.0% annualised',
                break: 2,
              }),
              new TextRun({
                text: 'Medium-high risk - circa 6.5% annualised',
                break: 2,
              }),
              new TextRun({ text: 'High risk - 8.5% annualised', break: 2 }),
              new TextRun({
                text: '(Please note that these returns aren’t guaranteed and will vary, sometimes significantly, year on year).',
                break: 2,
              }),
              new TextRun({
                text: 'We have a one-off implementation charge, which covers the costs of the implementation of our recommendations and the regulatory fees. We take this directly from the recommended policies once they’re set up. Based on the initial value of your investments, this will be:',
                break: 2,
              }),
              new TextRun({
                text: 'Total Initial implementation charge:',
                bold: true,
                break: 2,
              }),
              new TextRun({
                text: `  ${tools
                  .getTotalImplementationCharge(
                    context.state.newPensionsAdvice,
                    context.state.newISAsAdvice,
                    context.state.newGIAsAdvice
                  )
                  .toFixed(2)}% p.a.`,
              }),
              new TextRun({
                text: `\tOn a pot of £${tools.getTotalPortfolioValue(
                  context.state.newPensionsAdvice,
                  context.state.newISAsAdvice,
                  context.state.newGIAsAdvice
                )} this equals`,
                bold: true,
              }),
              new TextRun({
                text: `  £${tools.getTotalImplementationChargeOnPot(
                  context.state.newPensionsAdvice,
                  context.state.newISAsAdvice,
                  context.state.newGIAsAdvice
                )}`,
              }),
              chargesTable,
            ],
          }),
          context.state.pensions.length > 0
            ? new Paragraph({
                text: 'Your existing pension plans',
                style: 'Heading2',
                keepNext: true,
              })
            : new Paragraph({}),
          context.state.pensions.length > 0
            ? new Paragraph({
                text: 'We looked closely at your existing policies, and have done a full analysis which you’ll find below, including our reasons for recommending that you retain or transfer the policy.',
                style: 'MainText',
              })
            : '',
          formatting.showYourExistingPensionPlans(
            context.state.pensions,
            context.state.customerInfo.customers,
            context.state.customerGoals,
            context.state.clientAttitudeToRisk
          ),
          new Paragraph({
            text: 'Ways to improve your financial health',
            style: 'Heading2',
            keepNext: true,
          }),
          new Paragraph({
            text: 'Make sure you’re well protected',
            style: 'BlueHeading',
            keepNext: true,
          }),
          new Paragraph({
            style: 'FinancialHealthText',
            children: [
              new TextRun({
                text: tools.getLifeCover(
                  context.state.financialCheck.lifeCover,
                  context.state.financialCheck.incomeProtection
                ),
              }),
              new TextRun({
                text: 'It’s important to have cover in place, to help in the events of, for example, a critical illness, an illness or disability that means you can’t work, or if you were to die - to protect you and your loved ones. This is particularly important if you have financial dependants and/or if you have a mortgage.',
                break: 2,
              }),
              tools.getFinancialHealthImage('Protection', 45, 42, -350000),
              new TextRun({
                text: 'Let us know if you would like us to put you in touch with a protection specialist who can help you with this aspect of your financial planning.',
                break: 2,
              }),
            ],
          }),
          context.state.financialCheck.hasMortgage &&
          context.state.financialCheck.timeUntilMortgageDealEnds === 1
            ? new Paragraph({
                text: 'Make sure you have the best mortgage deal',
                style: 'BlueHeading',
                keepNext: true,
              })
            : new Paragraph({}),
          context.state.financialCheck.hasMortgage &&
          context.state.financialCheck.timeUntilMortgageDealEnds === 1
            ? new Paragraph({
                style: 'FinancialHealthText',
                children: [
                  new TextRun({
                    text: 'Your mortgage deal runs out in less than 6 months.',
                  }),
                  new TextRun({
                    text: "The impact of your mortgage rate can be huge over the longer term, saving you a lot of money. When your mortgage deal comes up for renewal, it's important to secure the best deal possible.",
                    break: 2,
                  }),
                  tools.getFinancialHealthImage('Mortgage', 52, 55, -300000),
                  new TextRun({
                    text: 'Let us know if you would like us to put you in touch with a trusted mortgage broker, to help you with this.',
                    break: 2,
                  }),
                ],
              })
            : new Paragraph({}),
          new Paragraph({
            text: 'Make sure you have an up to date Will and Lasting Power of Attorney',
            style: 'BlueHeading',
            keepNext: true,
          }),
          new Paragraph({
            style: 'FinancialHealthText',
            children: [
              new TextRun({
                text: context.state.financialCheck.will
                  ? 'You have a Will.'
                  : "You don't have a Will.",
              }),
              new TextRun({
                text: 'It’s important to make sure that you have a Will and Lasting Power of Attorney in place, and that you review these regularly - particularly when there are changes in your financial or personal situation. Let us know if you would like us to put you in touch with someone that can help.',
                break: 2,
              }),
              tools.getFinancialHealthImage('Will', 40, 45, -600000),
            ],
          }),
          new Paragraph({
            text: 'Thank you',
            style: 'Heading2',
            keepNext: true,
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              new TextRun({
                text: 'Thanks for taking the time to read this report!',
              }),
              new TextRun({
                text: 'We hope you have found this detailed report helpful. We pride ourselves on offering high quality financial advice at very affordable prices and look forward to making this financial plan a reality with you.  Please drop us a line at adviser-hub@ammonitewealth.com or call us on 020 3434 2718 to get started with your financial plan.',
                break: 2,
              }),
            ],
          }),
          formatting.showAdvisor(context.state.adviser)
            ? formatting.showAdvisor(context.state.adviser)
            : '',
          new Paragraph({
            text: 'Risk factors',
            style: 'Heading2',
            keepNext: true,
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              new TextRun({
                text: 'The value of your shares/units and the income from them will rise or fall as the value of the underlying investments in which your money is invested changes.',
                italics: true,
              }),
              new TextRun({
                text: 'When you sell your investment you may get back less than you invested.',
                break: 2,
                italics: true,
              }),
              new TextRun({
                text: 'Movements in exchange rates may cause the value of your investment to go up or down.',
                break: 2,
                italics: true,
              }),
              new TextRun({
                text: 'To obtain the benefit of equity investing, a long term view is usually advised.',
                break: 2,
                italics: true,
              }),
              new TextRun({
                text: 'The fund performance is not guaranteed and therefore past performance is no guide to future performance.',
                break: 2,
                italics: true,
              }),
              new TextRun({
                text: 'Recommendations are based on our understanding of current tax legislation, which may be subject to change and review.',
                break: 2,
                italics: true,
              }),
              new TextRun({
                text: 'The value of tax relief depends on your own financial circumstances and may change in the future.',
                break: 2,
                italics: true,
              }),
              new TextRun({
                text: 'The information given is based upon our understanding of current legislation and HM Revenue and Customs’ practice, both of which are subject to change.',
                break: 2,
                italics: true,
              }),
            ],
          }),
          new Paragraph({
            text: 'Cancellation rights',
            style: 'Heading2',
            keepNext: true,
          }),
          new Paragraph({
            style: 'MainText',
            children: [
              new TextRun({
                text: 'Once your investment plan is set up you have a legal right to cancel should you change your mind, the period is generally 30 days (which may reduce to 14 days for ISA, Unit Trust and EIS investments and may increase to 60 days for annuities). The amount you get back may be reduced by a decrease in value between making your initial investment and up until your investment is sold.',
                italics: true,
              }),
            ],
          }),
        ],
      },
    ],
  })

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, fileName)
  })
}

export default docxCreator
