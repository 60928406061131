import React from 'react';

function AmmoniteWill() {
	return (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8944 25.2111C12.2963 24.9121 11.5883 24.9317 11.0077 25.2635L3.5 29.5536V10.1606L12.0629 5.26754L23.1056 10.7889C23.7037 11.0879 24.4117 11.0683 24.9923 10.7365L32.5 6.44636V25.8394L23.9371 30.7325L12.8944 25.2111Z" stroke="#F25F5C" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 6L12 25" stroke="#F25F5C" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M24 11L24 30" stroke="#F25F5C" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
</svg>



	);
}

export default AmmoniteWill;
