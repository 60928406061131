import React from 'react';

function AmmoniteScrollArrow(){
return(
<svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<path d="M55 28.5C55 42.5833 43.5833 54 29.5 54C15.4167 54 4 42.5833 4 28.5C4 14.4167 15.4167 3 29.5 3C43.5833 3 55 14.4167 55 28.5Z" fill="#3BB9C4"/>
</g>
<path d="M27.5555 43.3785C28.6294 44.4524 30.3706 44.4524 31.4445 43.3785L48.9454 25.8776C50.0194 24.8036 50.0194 23.0624 48.9454 21.9885C47.8715 20.9145 46.1303 20.9145 45.0563 21.9885L29.5 37.5448L13.9437 21.9885C12.8697 20.9145 11.1285 20.9145 10.0546 21.9885C8.98062 23.0624 8.98062 24.8036 10.0546 25.8776L27.5555 43.3785ZM26.75 41.3754V41.4339H32.25V41.3754H26.75Z" fill="white"/>
<defs>
<filter id="filter0_d" x="0" y="0" width="59" height="59" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.168627 0 0 0 0 0.607843 0 0 0 0 0.647059 0 0 0 0.2 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>
);
} 

export default AmmoniteScrollArrow
