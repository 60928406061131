// Here we define all the formatting for the images used in the docx

//image imports
import { newAmmoniteHeader as ammoniteHeaderFile } from "../../Assets/base64Images/newAmmoniteHeader";

import { riskToleranceTable as riskToleranceTableFile } from "../../Assets/base64Images/riskToleranceTable";
import { recommendationsTable as recommendationsTableFile } from "../../Assets/base64Images/recommendationsTable";
import { portfolioRecommendationsTable as portfolioRecommendationsTableFile } from "../../Assets/base64Images/portfolioRecommendationsTable";

//docx imports
import {
  ImageRun,
  HorizontalPositionRelativeFrom,
  VerticalPositionRelativeFrom,
  TextWrappingType,
  TextWrappingSide,
} from "docx";

//other imports
import { getGoalCardTable } from "./tools";

export const ammoniteHeader = new ImageRun({
  data: Uint8Array.from(atob(ammoniteHeaderFile), (c) => c.charCodeAt(0)),
  transformation: {
    width: 793,
    height: 166,
  },
  floating: {
    horizontalPosition: {
      relative: HorizontalPositionRelativeFrom.MARGIN,
      offset: 0,
    },
    verticalPosition: {
      relative: VerticalPositionRelativeFrom.TOP_MARGIN,
      offset: 0,
    },
  },
});

//This is the first table in the docX
export const goalRecommendationImage = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(goalType), (c) => c.charCodeAt(0)),
    transformation: {
      width: 735,
      height: 122,
    },
    floating: {
      horizontalPosition: {
        offset: 180000,
        relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
      },
      verticalPosition: {
        offset: 0,
        relative: VerticalPositionRelativeFrom.PARAGRAPH,
      },
      behindDocument: true,
      allowOverlap: false,
    },
  });
};

export const riskToleranceTable = new ImageRun({
  data: Uint8Array.from(atob(riskToleranceTableFile), (c) => c.charCodeAt(0)),
  transformation: {
    width: 735,
    height: 60,
  },
  floating: {
    horizontalPosition: {
      offset: 180000,
      relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
    },
    verticalPosition: {
      offset: 50000,
      relative: VerticalPositionRelativeFrom.PARAGRAPH,
    },
    behindDocument: true,
    allowOverlap: false,
  },
});

export const goalRiskTable = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(goalType), (c) => c.charCodeAt(0)),
    transformation: {
      width: 735,
      height: 25,
    },
    floating: {
      horizontalPosition: {
        offset: 250000,
        relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
      },
      verticalPosition: {
        offset: -50000,
        relative: VerticalPositionRelativeFrom.LINE,
      },
      behindDocument: true,
      allowOverlap: false,
    },
  });
};

export const recommendationsTable = new ImageRun({
  data: Uint8Array.from(atob(recommendationsTableFile), (c) => c.charCodeAt(0)),
  transformation: {
    width: 735,
    height: 40,
  },
  floating: {
    horizontalPosition: {
      offset: 180000,
      relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
    },
    verticalPosition: {
      offset: 0,
      relative: VerticalPositionRelativeFrom.PARAGRAPH,
    },
    behindDocument: true,
    allowOverlap: false,
  },
});

export const portfolioRecommendationsTable = (height) => {
  return new ImageRun({
    data: Uint8Array.from(atob(portfolioRecommendationsTableFile), (c) =>
      c.charCodeAt(0)
    ),
    transformation: {
      width: 735,
      height: height,
    },
    floating: {
      horizontalPosition: {
        offset: 180000,
        relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
      },
      verticalPosition: {
        offset: -100000,
        relative: VerticalPositionRelativeFrom.PARAGRAPH,
      },
      behindDocument: true,
      allowOverlap: false,
    },
  });
};

export const chargesTable = new ImageRun({
  data: Uint8Array.from(atob(recommendationsTableFile), (c) => c.charCodeAt(0)),
  transformation: {
    width: 735,
    height: 40,
  },
  floating: {
    horizontalPosition: {
      offset: 180000,
      relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
    },
    verticalPosition: {
      offset: -80000,
      relative: VerticalPositionRelativeFrom.LINE,
    },
    behindDocument: true,
    allowOverlap: false,
  },
});

export const pensionsPlanTable = new ImageRun({
  data: Uint8Array.from(atob(portfolioRecommendationsTableFile), (c) =>
    c.charCodeAt(0)
  ),
  transformation: {
    width: 735,
    height: 290,
  },
  floating: {
    horizontalPosition: {
      offset: 180000,
      relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
    },
    verticalPosition: {
      offset: -70000,
      relative: VerticalPositionRelativeFrom.LINE,
    },
    behindDocument: true,
    allowOverlap: false,
  },
});

export const pensionsPlanTable2 = new ImageRun({
  data: Uint8Array.from(atob(portfolioRecommendationsTableFile), (c) =>
    c.charCodeAt(0)
  ),
  transformation: {
    width: 345,
    height: 30,
  },
  floating: {
    horizontalPosition: {
      offset: 180000,
      relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
    },
    verticalPosition: {
      offset: -60000,
      relative: VerticalPositionRelativeFrom.LINE,
    },
    behindDocument: true,
    allowOverlap: false,
  },
});

export const financialHealthImage = (
  imageType,
  height,
  width,
  verticalOffset
) => {
  return new ImageRun({
    data: Uint8Array.from(atob(imageType), (c) => c.charCodeAt(0)),
    transformation: {
      width: width,
      height: height,
    },
    floating: {
      horizontalPosition: {
        offset: 250000,
        relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
      },
      verticalPosition: {
        offset: verticalOffset,
        relative: VerticalPositionRelativeFrom.LINE,
      },
      wrap: {
        type: TextWrappingType.SQUARE,
        side: TextWrappingSide.RIGHT,
      },
    },
  });
};

export const advisorSignature = (advisor) => {
  return new ImageRun({
    data: Uint8Array.from(atob(advisor), (c) => c.charCodeAt(0)),
    transformation: {
      width: 214,
      height: 95,
    },
    floating: {
      horizontalPosition: {
        offset: 250000,
        relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
      },
      verticalPosition: {
        offset: 0,
        relative: VerticalPositionRelativeFrom.LINE,
      },
    },
  });
};

export const goalCardImage = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(getGoalCardTable(goalType)), (c) =>
      c.charCodeAt(0)
    ),
    transformation: {
      width: 208,
      height: 242,
    },
    floating: {
      horizontalPosition: {
        offset: 180000,
        relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
      },
      verticalPosition: {
        offset: 0,
        relative: VerticalPositionRelativeFrom.LINE,
      },
      behindDocument: true,
    },
  });
};

export const goalCardImage2 = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(getGoalCardTable(goalType)), (c) =>
      c.charCodeAt(0)
    ),
    transformation: {
      width: 208,
      height: 242,
    },
    floating: {
      horizontalPosition: {
        offset: 2100000,
        relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
      },
      verticalPosition: {
        offset: 0,
        relative: VerticalPositionRelativeFrom.LINE,
      },
      behindDocument: true,
    },
  });
};

export const goalCardImage3 = (goalType) => {
  return new ImageRun({
    data: Uint8Array.from(atob(getGoalCardTable(goalType)), (c) =>
      c.charCodeAt(0)
    ),
    transformation: {
      width: 208,
      height: 242,
    },
    floating: {
      horizontalPosition: {
        offset: 4020000,
        relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
      },
      verticalPosition: {
        offset: 0,
        relative: VerticalPositionRelativeFrom.LINE,
      },
      behindDocument: true,
    },
  });
};
