import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import './menu.css';
import AmmoniteMenuButton from '../MenuButton/MenuButton';
import AmmoniteContext from '../../Utils/contexts/AmmoniteContext';

function AmmoniteMenu({ handleCloseMenuAndReroute }) {
	const context = useContext(AmmoniteContext);

	return (
		<div>
			<div className="menuHeaderContainer">
				<Typography variant="h4" align="inherit" fontWeight="fontWeightBold">
					Sections
				</Typography>
			</div>
			<div className="buttonsContainer">
				<AmmoniteMenuButton
					route="/basicinformation"
					handleCloseMenuAndReroute={handleCloseMenuAndReroute}
					buttonText="Basic Information"
				/>
				<AmmoniteMenuButton
					route="/yourfinancialhealthcheck"
					handleCloseMenuAndReroute={handleCloseMenuAndReroute}
					buttonText="Financial Health Check"
				/>
				<AmmoniteMenuButton
					route="/chooseyourgoals"
					handleCloseMenuAndReroute={handleCloseMenuAndReroute}
					buttonText="Please choose your goals"
				/>
				<AmmoniteMenuButton
					route="/tellusaboutyourgoals"
					handleCloseMenuAndReroute={handleCloseMenuAndReroute}
					buttonText="Please tell us about your goals"
				/>
				<AmmoniteMenuButton
					route="yourretirementspending"
					handleCloseMenuAndReroute={handleCloseMenuAndReroute}
					buttonText="Your Retirement Spending"
				/>
				<AmmoniteMenuButton
					route="/savingforretirement"
					handleCloseMenuAndReroute={handleCloseMenuAndReroute}
					buttonText="Saving for retirement"
				/>
				<AmmoniteMenuButton
					route="/yourgoalsummary"
					handleCloseMenuAndReroute={handleCloseMenuAndReroute}
					buttonText="Your goal summary"
				/>
				<AmmoniteMenuButton
					route="/results"
					handleCloseMenuAndReroute={handleCloseMenuAndReroute}
					buttonText="Your financial health check results"
				/>
				<AmmoniteMenuButton
					route="/thankyou"
					handleCloseMenuAndReroute={handleCloseMenuAndReroute}
					buttonText="Thank you"
					endButton
				/>
			</div>
		</div>
	);
}

export default AmmoniteMenu;
