import * as firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "@firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_TOKEN,
  authDomain: "ammonite-prototype-2dfa7.firebaseapp.com",
  databaseURL: "https://ammonite-prototype-2dfa7.firebaseio.com",
  projectId: "ammonite-prototype-2dfa7",
  storageBucket: "ammonite-prototype-2dfa7.appspot.com",
  messagingSenderId: "568084203232",
  appId: "1:568084203232:web:2cef00b13e9454def73285",
  measurementId: "G-5RB074B11V",
};

const app = firebase.initializeApp(firebaseConfig);

export default firebase;
