import React from 'react';

function AmmoniteLTISummaryIcon({ colour }) {
	return (
		<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M26.7961 6.54009C26.7667 6.4237 26.7078 6.32072 26.6519 6.21539C26.6064 6.1265 26.5736 6.03307 26.5137 5.95595C26.4574 5.87972 26.3787 5.82557 26.3059 5.75985C26.2154 5.67588 26.1315 5.59047 26.0255 5.52875C26.0076 5.51738 25.9967 5.49932 25.9775 5.48994C25.9077 5.45259 25.8321 5.45068 25.7596 5.42419C25.6479 5.38255 25.5374 5.33892 25.4173 5.32133C25.2996 5.30663 25.1901 5.32085 25.0742 5.32965C24.9936 5.3357 24.9157 5.31554 24.8327 5.33407L16.7 7.15051C15.8005 7.35141 15.2362 8.24251 15.4368 9.14039C15.6373 10.0383 16.5271 10.6046 17.4266 10.4037L22.0232 9.37704L17.1689 18.5013L9.28193 15.8825C8.50089 15.6197 7.6502 15.9703 7.27391 16.7015L1.32098 28.2775C0.900848 29.0954 1.2228 30.1011 2.0407 30.5213C2.40317 30.7067 2.79838 30.7482 3.16598 30.6661C3.63279 30.5619 4.05041 30.2602 4.286 29.8012L9.58663 19.4946L17.4539 22.1092C18.2266 22.3653 19.0697 22.0267 19.4509 21.3097L24.5515 11.7206L25.386 15.4569C25.5865 16.3548 26.4763 16.9211 27.3758 16.7202C28.2753 16.5193 28.8396 15.6282 28.6391 14.7303L26.8226 6.59737C26.8178 6.57622 26.8025 6.56087 26.7961 6.54009Z"
				fill={colour}
			/>
		</svg>
	);
}

export default AmmoniteLTISummaryIcon;
