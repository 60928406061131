// Core React dependencies
import React, { useState, useEffect } from 'react'

// Extra React dependencies
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

// External libraries
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

// Component imports
import Layout from './containers/Layout/Layout'
import ProtectedRoute from './containers/ProtectedRoute/protectedRoute'
import Unauthorised from './components/Unauthorised/unauthorised'
import Login from './pages/Login/Login'
import ChooseRoute from './pages/ChooseRoute/ChooseRoute'
import Dashboard from './pages/Console/Dashboard/Dashboard'
import About from './pages/About/about'
import Health from './pages/Health/health'
import Info from './pages/Info/info'
import Goals from './pages/Goals/goals'
import Retirement from './pages/Retirement/retirement'
import FurtherInfo from './pages/FurtherInfo/furtherInfo'
import GoalsReview from './pages/GoalsReview/goalsReview'
import Summary from './pages/Summary/summary'
import Charts from './pages/Charts/charts'
import Actions from './pages/Actions/actions'
import ReportViewer from './pages/ReportViewer/reportViewer'
import MyReport from './pages/myReport/myReport'
import SuitabilityDashboard from './pages/SuitabilityReport/SuitabilityDashboard/suitabilityDashboard'
import AdditionalCustomerInformation from './pages/SuitabilityReport/AdditionalCustomerInfo/additionalCustomerInfo'
import FurtherCustomerInformation from './pages/SuitabilityReport/FurtherCustomerInfo/furtherCustomerInfo'
import ChildrenDetails from './pages/SuitabilityReport/ChildrenDetails/childrenDetails'
import AdditionalGoalDetails from './pages/SuitabilityReport/AdditionalGoalDetails/additionalGoalDetails'
import AttitudeToRisk from './pages/SuitabilityReport/AttitudeToRisk/attitudeToRisk'
import ExistingPensionsDetails from './pages/SuitabilityReport/ExistingPensionsDetails/existingPensionsDetails'
import ExistingInvestmentDetails from './pages/SuitabilityReport/ExistingInvestmentDetails/existingInvestmentDetails'
import SuitabilityReportBuilder from './pages/SuitabilityReport/SuitabilityReportBuilder/suitabilityReportBuilder'
import NewPensionAdvice from './pages/SuitabilityReport/NewPensionAdvice/newPensionAdvice'
import NewISAAdvice from './pages/SuitabilityReport/NewISAAdvice/newISAAdvice'
import NewGIAAdvice from './pages/SuitabilityReport/NewGIAAdvice/newGIAAdvice'
import NewCashAdvice from './pages/SuitabilityReport/NewCashAdvice/newCashAdvice'
import NewLOAAdvice from './pages/SuitabilityReport/NewLOAAdvice/newLOAAdvice'
import FactFinderBuilder from './pages/SuitabilityReport/FactFinderBuilder/factFinderBuilder'
import SuitabilityReportPDF from './pages/SuitabilityReport/SuitabilityReportPDF/suitabilityReportPDF'
import DocumentLibrary from './pages/SuitabilityReport/DocumentLibrary/documentLibrary'
import AmmoniteToast from './components/ValidationToasts/validationToasts'

import { ToastProvider } from 'react-toast-notifications'

// Utilities
import { AuthProvider } from './Utils/contexts/AuthContext'
import { AmmoniteContextProvider } from './Utils/contexts/AmmoniteContext'
import { SuitabilityReportContextProvider } from './Utils/contexts/suitabilityReportContext'
import { theme } from './Utils/themes/theme'

// Styling
import './App.css'
import { black } from 'material-ui/styles/colors'

function App() {
  return (
    <AuthProvider>
      <ToastProvider components={{ Toast: AmmoniteToast }}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <AmmoniteContextProvider>
              <SuitabilityReportContextProvider>
                <Layout>
                  <Router>
                    <Switch>
                      <ProtectedRoute
                        exact
                        path='/chooseroute'
                        component={ChooseRoute}
                      />
                      <ProtectedRoute
                        exact
                        path='/dashboard'
                        component={Dashboard}
                      />
                      <Route exact path='/reportViewer'>
                        <ReportViewer />
                      </Route>
                      <ProtectedRoute exact path='/about' component={About} />
                      <ProtectedRoute
                        exact
                        path='/basicinformation'
                        component={Info}
                      />
                      <ProtectedRoute
                        exact
                        path='/yourfinancialhealthcheck'
                        component={Health}
                      />
                      <ProtectedRoute
                        exact
                        path='/chooseyourgoals'
                        component={Goals}
                      />
                      <ProtectedRoute
                        exact
                        path='/tellusaboutyourgoals'
                        component={FurtherInfo}
                      />
                      <ProtectedRoute
                        exact
                        path='/yourretirementspending'
                        component={Retirement}
                      />
                      <ProtectedRoute
                        exact
                        path='/savingforretirement'
                        component={Charts}
                      />
                      <ProtectedRoute
                        exact
                        path='/yourgoalsummary'
                        component={GoalsReview}
                      />
                      <ProtectedRoute
                        exact
                        path='/results'
                        component={Actions}
                      />
                      <ProtectedRoute
                        exact
                        path='/thankyou'
                        component={Summary}
                      />
                      {/* ****** */}
                      <ProtectedRoute
                        exact
                        path='/myReport'
                        component={MyReport}
                      />
                      <Route
                        exact
                        path='/unauthorized'
                        component={Unauthorised}
                      />
                      .js
                      <Route path='/documentlibrary'>
                        <DocumentLibrary />
                      </Route>
                      <Route path='/suitabilitydashboard'>
                        <SuitabilityDashboard />
                      </Route>
                      <Route path='/additionalcustomerinformation'>
                        <AdditionalCustomerInformation />
                      </Route>
                      <Route path='/furthercustomerinformation'>
                        <FurtherCustomerInformation />
                      </Route>
                      <Route path='/childrendetails'>
                        <ChildrenDetails />
                      </Route>
                      <Route path='/additionalgoaldetails'>
                        <AdditionalGoalDetails />
                      </Route>
                      <Route path='/existingpensionsdetails'>
                        <ExistingPensionsDetails />
                      </Route>
                      <Route path='/existinginvestmentdetails'>
                        <ExistingInvestmentDetails />
                      </Route>
                      <Route path='/attitudetorisk'>
                        <AttitudeToRisk />
                      </Route>
                      <Route path='/suitabilityreportbuilder'>
                        <SuitabilityReportBuilder />
                      </Route>
                      <Route path='/newpensionadvice'>
                        <NewPensionAdvice />
                      </Route>
                      <Route path='/newisaadvice'>
                        <NewISAAdvice />
                      </Route>
                      <Route path='/newgiaadvice'>
                        <NewGIAAdvice />
                      </Route>
                      <Route path='/newcashadvice'>
                        <NewCashAdvice />
                      </Route>
                      <Route path='/newloaadvice'>
                        <NewLOAAdvice />
                      </Route>
                      <Route path='/factfinderbuilder'>
                        <FactFinderBuilder />
                      </Route>
                      <Route path='/suitabilityreportpdf'>
                        <SuitabilityReportPDF />
                      </Route>
                      <Route path='/'>
                        <Login />
                      </Route>
                    </Switch>
                  </Router>
                </Layout>
              </SuitabilityReportContextProvider>
            </AmmoniteContextProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ToastProvider>
    </AuthProvider>
  )
}

export default App
