import React from 'react';

function AmmonitePiggy({ back }) {
	return (
		<svg width="67" height="47" viewBox="0 0 67 47" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M42.3518 40.8749C44.3453 40.8749 45.9614 39.285 45.9614 37.3237C45.9614 35.3625 44.3453 33.7726 42.3518 33.7726C40.3583 33.7726 38.7422 35.3625 38.7422 37.3237C38.7422 39.285 40.3583 40.8749 42.3518 40.8749Z" fill="#FBD56F"/>
<path d="M42.3518 40.8749C44.3453 40.8749 45.9614 39.285 45.9614 37.3237C45.9614 35.3625 44.3453 33.7726 42.3518 33.7726C40.3583 33.7726 38.7422 35.3625 38.7422 37.3237C38.7422 39.285 40.3583 40.8749 42.3518 40.8749Z" fill="#F7B35C"/>
<path d="M42.9748 40.8766C44.9683 40.8766 46.5844 39.3221 46.5844 37.4046C46.5844 35.487 44.9683 33.9325 42.9748 33.9325C40.9813 33.9325 39.3652 35.487 39.3652 37.4046C39.3652 39.3221 40.9813 40.8766 42.9748 40.8766Z" fill="#FBD56F"/>
<path d="M44.4368 40.5491L44.0614 40.3554C44.0614 40.3554 43.8858 41.0346 43.9432 41.5187C43.9488 41.5677 43.9706 41.6141 44.0056 41.6513C44.0405 41.6886 44.087 41.715 44.1387 41.727C45.0024 41.9258 49.1191 42.7728 51.95 41.5798C51.9911 41.5624 52.0265 41.5354 52.0529 41.5015C52.0793 41.4676 52.0957 41.4279 52.1005 41.3863C52.1352 41.0888 52.0123 40.4749 51.9632 40.3554C51.8576 40.098 44.4368 40.5491 44.4368 40.5491Z" fill="#FBD56F"/>
<path d="M44.4368 40.5491L44.0614 40.3554C44.0614 40.3554 43.8858 41.0346 43.9432 41.5187C43.9488 41.5677 43.9706 41.6141 44.0056 41.6513C44.0405 41.6886 44.087 41.715 44.1387 41.727C45.0024 41.9258 49.1191 42.7728 51.95 41.5798C51.9911 41.5624 52.0265 41.5354 52.0529 41.5015C52.0793 41.4676 52.0957 41.4279 52.1005 41.3863C52.1352 41.0888 52.0123 40.4749 51.9632 40.3554C51.8576 40.098 44.4368 40.5491 44.4368 40.5491Z" fill="#F7B35C"/>
<path d="M48.0114 41.1817C50.1935 41.1817 51.9623 40.8122 51.9623 40.3563C51.9623 39.9004 50.1935 39.5309 48.0114 39.5309C45.8294 39.5309 44.0605 39.9004 44.0605 40.3563C44.0605 40.8122 45.8294 41.1817 48.0114 41.1817Z" fill="#FBD56F"/>
<path d="M45.9456 39.3317L45.5702 39.1379C45.5702 39.1379 45.3946 39.8172 45.452 40.3012C45.4576 40.3503 45.4794 40.3966 45.5144 40.4339C45.5493 40.4712 45.5958 40.4976 45.6475 40.5095C46.5112 40.7084 50.628 41.5554 53.4589 40.3624C53.4999 40.345 53.5354 40.318 53.5618 40.2841C53.5881 40.2502 53.6045 40.2105 53.6093 40.1689C53.644 39.8714 53.5211 39.2575 53.4721 39.1379C53.3665 38.8806 45.9456 39.3317 45.9456 39.3317Z" fill="#FBD56F"/>
<path d="M45.9456 39.3317L45.5702 39.1379C45.5702 39.1379 45.3946 39.8172 45.452 40.3012C45.4576 40.3503 45.4794 40.3966 45.5144 40.4339C45.5493 40.4712 45.5958 40.4976 45.6475 40.5095C46.5112 40.7084 50.628 41.5554 53.4589 40.3624C53.4999 40.345 53.5354 40.318 53.5618 40.2841C53.5881 40.2502 53.6045 40.2105 53.6093 40.1689C53.644 39.8714 53.5211 39.2575 53.4721 39.1379C53.3665 38.8806 45.9456 39.3317 45.9456 39.3317Z" fill="#F7B35C"/>
<path d="M49.5212 39.9628C51.7033 39.9628 53.4722 39.5932 53.4722 39.1373C53.4722 38.6814 51.7033 38.3119 49.5212 38.3119C47.3392 38.3119 45.5703 38.6814 45.5703 39.1373C45.5703 39.5932 47.3392 39.9628 49.5212 39.9628Z" fill="#FBD56F"/>
<path d="M47.2806 37.7312L46.9051 37.5375C46.9051 37.5375 46.7295 38.2167 46.7869 38.7008C46.7926 38.7499 46.8144 38.7962 46.8493 38.8334C46.8843 38.8707 46.9308 38.8971 46.9824 38.9091C47.8462 39.1079 51.9629 39.9549 54.7938 38.7619C54.8349 38.7445 54.8703 38.7176 54.8967 38.6836C54.9231 38.6497 54.9395 38.61 54.9443 38.5684C54.979 38.2709 54.8561 37.6571 54.807 37.5375C54.7014 37.2801 47.2806 37.7312 47.2806 37.7312Z" fill="#FBD56F"/>
<path d="M47.2806 37.7312L46.9051 37.5375C46.9051 37.5375 46.7295 38.2167 46.7869 38.7008C46.7926 38.7499 46.8144 38.7962 46.8493 38.8334C46.8843 38.8707 46.9308 38.8971 46.9824 38.9091C47.8462 39.1079 51.9629 39.9549 54.7938 38.7619C54.8349 38.7445 54.8703 38.7176 54.8967 38.6836C54.9231 38.6497 54.9395 38.61 54.9443 38.5684C54.979 38.2709 54.8561 37.6571 54.807 37.5375C54.7014 37.2801 47.2806 37.7312 47.2806 37.7312Z" fill="#F7B35C"/>
<path d="M50.8562 38.3636C53.0382 38.3636 54.8071 37.9941 54.8071 37.5382C54.8071 37.0823 53.0382 36.7128 50.8562 36.7128C48.6742 36.7128 46.9053 37.0823 46.9053 37.5382C46.9053 37.9941 48.6742 38.3636 50.8562 38.3636Z" fill="#FBD56F"/>
<path d="M45.8031 36.0641L45.4276 35.8704C45.4276 35.8704 45.252 36.5496 45.3094 37.0337C45.315 37.0828 45.3368 37.1291 45.3718 37.1663C45.4068 37.2036 45.4532 37.23 45.5049 37.242C46.3686 37.4408 50.4853 38.2878 53.3162 37.0948C53.3573 37.0774 53.3927 37.0504 53.4191 37.0165C53.4455 36.9826 53.4619 36.9429 53.4667 36.9013C53.5014 36.6038 53.3785 35.99 53.3294 35.8704C53.2238 35.613 45.8031 36.0641 45.8031 36.0641Z" fill="#FBD56F"/>
<path d="M45.8031 36.0641L45.4276 35.8704C45.4276 35.8704 45.252 36.5496 45.3094 37.0337C45.315 37.0828 45.3368 37.1291 45.3718 37.1663C45.4068 37.2036 45.4532 37.23 45.5049 37.242C46.3686 37.4408 50.4853 38.2878 53.3162 37.0948C53.3573 37.0774 53.3927 37.0504 53.4191 37.0165C53.4455 36.9826 53.4619 36.9429 53.4667 36.9013C53.5014 36.6038 53.3785 35.99 53.3294 35.8704C53.2238 35.613 45.8031 36.0641 45.8031 36.0641Z" fill="#F7B35C"/>
<path d="M49.3777 36.6968C51.5597 36.6968 53.3286 36.3272 53.3286 35.8713C53.3286 35.4155 51.5597 35.0459 49.3777 35.0459C47.1956 35.0459 45.4268 35.4155 45.4268 35.8713C45.4268 36.3272 47.1956 36.6968 49.3777 36.6968Z" fill="#FBD56F"/>
<path d="M49.1273 34.7292L48.7518 34.5354C48.7518 34.5354 48.5762 35.2147 48.6336 35.6987C48.6393 35.7478 48.661 35.7941 48.696 35.8314C48.731 35.8686 48.7774 35.895 48.8291 35.907C49.6928 36.1058 53.8096 36.9528 56.6405 35.7598C56.6815 35.7425 56.717 35.7155 56.7433 35.6816C56.7697 35.6476 56.7861 35.6079 56.7909 35.5663C56.8256 35.2689 56.7027 34.655 56.6536 34.5354C56.548 34.278 49.1273 34.7292 49.1273 34.7292Z" fill="#FBD56F"/>
<path d="M49.1273 34.7292L48.7518 34.5354C48.7518 34.5354 48.5762 35.2147 48.6336 35.6987C48.6393 35.7478 48.661 35.7941 48.696 35.8314C48.731 35.8686 48.7774 35.895 48.8291 35.907C49.6928 36.1058 53.8096 36.9528 56.6405 35.7598C56.6815 35.7425 56.717 35.7155 56.7433 35.6816C56.7697 35.6476 56.7861 35.6079 56.7909 35.5663C56.8256 35.2689 56.7027 34.655 56.6536 34.5354C56.548 34.278 49.1273 34.7292 49.1273 34.7292Z" fill="#F7B35C"/>
<path d="M52.7029 35.3601C54.8849 35.3601 56.6538 34.9905 56.6538 34.5347C56.6538 34.0788 54.8849 33.7092 52.7029 33.7092C50.5208 33.7092 48.752 34.0788 48.752 34.5347C48.752 34.9905 50.5208 35.3601 52.7029 35.3601Z" fill="#FBD56F"/>
<path d="M46.8812 33.8285L46.5057 33.6348C46.5057 33.6348 46.3301 34.314 46.3875 34.7981C46.3932 34.8472 46.4149 34.8935 46.4499 34.9307C46.4849 34.968 46.5313 34.9944 46.583 35.0064C47.4468 35.2052 51.5635 36.0522 54.3944 34.8592C54.4355 34.8418 54.4709 34.8149 54.4973 34.7809C54.5237 34.747 54.5401 34.7073 54.5449 34.6657C54.5796 34.3682 54.4567 33.7544 54.4076 33.6348C54.302 33.3774 46.8812 33.8285 46.8812 33.8285Z" fill="#FBD56F"/>
<path d="M46.8812 33.8285L46.5057 33.6348C46.5057 33.6348 46.3301 34.314 46.3875 34.7981C46.3932 34.8472 46.4149 34.8935 46.4499 34.9307C46.4849 34.968 46.5313 34.9944 46.583 35.0064C47.4468 35.2052 51.5635 36.0522 54.3944 34.8592C54.4355 34.8418 54.4709 34.8149 54.4973 34.7809C54.5237 34.747 54.5401 34.7073 54.5449 34.6657C54.5796 34.3682 54.4567 33.7544 54.4076 33.6348C54.302 33.3774 46.8812 33.8285 46.8812 33.8285Z" fill="#F7B35C"/>
<path d="M50.4568 34.4611C52.6388 34.4611 54.4077 34.0915 54.4077 33.6356C54.4077 33.1797 52.6388 32.8102 50.4568 32.8102C48.2748 32.8102 46.5059 33.1797 46.5059 33.6356C46.5059 34.0915 48.2748 34.4611 50.4568 34.4611Z" fill="#FBD56F"/>
<path d="M48.3802 32.0953L48.0047 31.9015C48.0047 31.9015 47.8291 32.5807 47.8865 33.0648C47.8922 33.1139 47.914 33.1602 47.9489 33.1974C47.9839 33.2347 48.0304 33.2611 48.082 33.2731C48.9458 33.4719 53.0625 34.3189 55.8934 33.1259C55.9344 33.1085 55.9699 33.0816 55.9963 33.0476C56.0227 33.0137 56.039 32.974 56.0438 32.9324C56.0786 32.6349 55.9556 32.0211 55.9066 31.9015C55.801 31.6441 48.3802 32.0953 48.3802 32.0953Z" fill="#FBD56F"/>
<path d="M48.3802 32.0953L48.0047 31.9015C48.0047 31.9015 47.8291 32.5807 47.8865 33.0648C47.8922 33.1139 47.914 33.1602 47.9489 33.1974C47.9839 33.2347 48.0304 33.2611 48.082 33.2731C48.9458 33.4719 53.0625 34.3189 55.8934 33.1259C55.9344 33.1085 55.9699 33.0816 55.9963 33.0476C56.0227 33.0137 56.039 32.974 56.0438 32.9324C56.0786 32.6349 55.9556 32.0211 55.9066 31.9015C55.801 31.6441 48.3802 32.0953 48.3802 32.0953Z" fill="#F7B35C"/>
<path d="M51.9558 32.7263C54.1378 32.7263 55.9067 32.3567 55.9067 31.9009C55.9067 31.445 54.1378 31.0754 51.9558 31.0754C49.7738 31.0754 48.0049 31.445 48.0049 31.9009C48.0049 32.3567 49.7738 32.7263 51.9558 32.7263Z" fill="#FBD56F"/>
<path d="M49.1204 30.0345L48.745 29.8407C48.745 29.8407 48.5694 30.52 48.6268 31.004C48.6324 31.0531 48.6542 31.0994 48.6892 31.1367C48.7241 31.1739 48.7706 31.2003 48.8223 31.2123C49.686 31.4111 53.8027 32.2581 56.6336 31.0651C56.6747 31.0478 56.7101 31.0208 56.7365 30.9869C56.7629 30.9529 56.7793 30.9132 56.7841 30.8716C56.8188 30.5742 56.6959 29.9603 56.6468 29.8407C56.5412 29.5833 49.1204 30.0345 49.1204 30.0345Z" fill="#FBD56F"/>
<path d="M49.1204 30.0345L48.745 29.8407C48.745 29.8407 48.5694 30.52 48.6268 31.004C48.6324 31.0531 48.6542 31.0994 48.6892 31.1367C48.7241 31.1739 48.7706 31.2003 48.8223 31.2123C49.686 31.4111 53.8027 32.2581 56.6336 31.0651C56.6747 31.0478 56.7101 31.0208 56.7365 30.9869C56.7629 30.9529 56.7793 30.9132 56.7841 30.8716C56.8188 30.5742 56.6959 29.9603 56.6468 29.8407C56.5412 29.5833 49.1204 30.0345 49.1204 30.0345Z" fill="#F7B35C"/>
<path d="M52.696 30.6671C54.8781 30.6671 56.647 30.2975 56.647 29.8417C56.647 29.3858 54.8781 29.0162 52.696 29.0162C50.514 29.0162 48.7451 29.3858 48.7451 29.8417C48.7451 30.2975 50.514 30.6671 52.696 30.6671Z" fill="#FBD56F"/>
<path d="M55.7904 41.0289L55.4149 40.8351C55.4149 40.8351 55.2393 41.5143 55.2967 41.9984C55.3023 42.0475 55.3241 42.0938 55.3591 42.131C55.3941 42.1683 55.4405 42.1947 55.4922 42.2067C56.3559 42.4055 60.4727 43.2525 63.3036 42.0595C63.3446 42.0421 63.3801 42.0152 63.4065 41.9812C63.4329 41.9473 63.4493 41.9076 63.4541 41.866C63.4888 41.5685 63.3659 40.9547 63.3168 40.8351C63.2112 40.5777 55.7904 41.0289 55.7904 41.0289Z" fill="#FBD56F"/>
<path d="M55.7904 41.0289L55.4149 40.8351C55.4149 40.8351 55.2393 41.5143 55.2967 41.9984C55.3023 42.0475 55.3241 42.0938 55.3591 42.131C55.3941 42.1683 55.4405 42.1947 55.4922 42.2067C56.3559 42.4055 60.4727 43.2525 63.3036 42.0595C63.3446 42.0421 63.3801 42.0152 63.4065 41.9812C63.4329 41.9473 63.4493 41.9076 63.4541 41.866C63.4888 41.5685 63.3659 40.9547 63.3168 40.8351C63.2112 40.5777 55.7904 41.0289 55.7904 41.0289Z" fill="#F7B35C"/>
<path d="M59.365 41.6598C61.547 41.6598 63.3159 41.2902 63.3159 40.8343C63.3159 40.3785 61.547 40.0089 59.365 40.0089C57.1829 40.0089 55.4141 40.3785 55.4141 40.8343C55.4141 41.2902 57.1829 41.6598 59.365 41.6598Z" fill="#FBD56F"/>
<path d="M57.1243 39.4268L56.7489 39.233C56.7489 39.233 56.5733 39.9123 56.6307 40.3963C56.6363 40.4454 56.6581 40.4917 56.6931 40.529C56.728 40.5662 56.7745 40.5926 56.8262 40.6046C57.6899 40.8034 61.8066 41.6505 64.6375 40.4574C64.6786 40.4401 64.714 40.4131 64.7404 40.3792C64.7668 40.3453 64.7832 40.3056 64.788 40.264C64.8227 39.9665 64.6998 39.3526 64.6507 39.233C64.5451 38.9756 57.1243 39.4268 57.1243 39.4268Z" fill="#FBD56F"/>
<path d="M57.1243 39.4268L56.7489 39.233C56.7489 39.233 56.5733 39.9123 56.6307 40.3963C56.6363 40.4454 56.6581 40.4917 56.6931 40.529C56.728 40.5662 56.7745 40.5926 56.8262 40.6046C57.6899 40.8034 61.8066 41.6505 64.6375 40.4574C64.6786 40.4401 64.714 40.4131 64.7404 40.3792C64.7668 40.3453 64.7832 40.3056 64.788 40.264C64.8227 39.9665 64.6998 39.3526 64.6507 39.233C64.5451 38.9756 57.1243 39.4268 57.1243 39.4268Z" fill="#F7B35C"/>
<path d="M60.7 40.0577C62.882 40.0577 64.6509 39.6882 64.6509 39.2323C64.6509 38.7764 62.882 38.4069 60.7 38.4069C58.5179 38.4069 56.749 38.7764 56.749 39.2323C56.749 39.6882 58.5179 40.0577 60.7 40.0577Z" fill="#FBD56F"/>
<path d="M55.6468 37.7614L55.2713 37.5676C55.2713 37.5676 55.0957 38.2469 55.1531 38.7309C55.1588 38.78 55.1806 38.8263 55.2155 38.8636C55.2505 38.9008 55.297 38.9272 55.3486 38.9392C56.2124 39.138 60.3291 39.9851 63.16 38.792C63.2011 38.7747 63.2366 38.7477 63.2629 38.7138C63.2893 38.6799 63.3057 38.6402 63.3105 38.5986C63.3452 38.3011 63.2223 37.6872 63.1732 37.5676C63.0676 37.3102 55.6468 37.7614 55.6468 37.7614Z" fill="#FBD56F"/>
<path d="M55.6468 37.7614L55.2713 37.5676C55.2713 37.5676 55.0957 38.2469 55.1531 38.7309C55.1588 38.78 55.1806 38.8263 55.2155 38.8636C55.2505 38.9008 55.297 38.9272 55.3486 38.9392C56.2124 39.138 60.3291 39.9851 63.16 38.792C63.2011 38.7747 63.2366 38.7477 63.2629 38.7138C63.2893 38.6799 63.3057 38.6402 63.3105 38.5986C63.3452 38.3011 63.2223 37.6872 63.1732 37.5676C63.0676 37.3102 55.6468 37.7614 55.6468 37.7614Z" fill="#F7B35C"/>
<path d="M59.2224 38.3922C61.4044 38.3922 63.1733 38.0226 63.1733 37.5668C63.1733 37.1109 61.4044 36.7413 59.2224 36.7413C57.0404 36.7413 55.2715 37.1109 55.2715 37.5668C55.2715 38.0226 57.0404 38.3922 59.2224 38.3922Z" fill="#FBD56F"/>
<path d="M58.971 36.4233L58.5956 36.2295C58.5956 36.2295 58.4199 36.9088 58.4773 37.3928C58.483 37.4419 58.5048 37.4882 58.5398 37.5255C58.5747 37.5627 58.6212 37.5891 58.6729 37.6011C59.5366 37.7999 63.6534 38.6469 66.4843 37.4539C66.5253 37.4366 66.5608 37.4096 66.5871 37.3757C66.6135 37.3417 66.6299 37.302 66.6347 37.2604C66.6694 36.963 66.5465 36.3491 66.4974 36.2295C66.3919 35.9721 58.971 36.4233 58.971 36.4233Z" fill="#FBD56F"/>
<path d="M58.971 36.4233L58.5956 36.2295C58.5956 36.2295 58.4199 36.9088 58.4773 37.3928C58.483 37.4419 58.5048 37.4882 58.5398 37.5255C58.5747 37.5627 58.6212 37.5891 58.6729 37.6011C59.5366 37.7999 63.6534 38.6469 66.4843 37.4539C66.5253 37.4366 66.5608 37.4096 66.5871 37.3757C66.6135 37.3417 66.6299 37.302 66.6347 37.2604C66.6694 36.963 66.5465 36.3491 66.4974 36.2295C66.3919 35.9721 58.971 36.4233 58.971 36.4233Z" fill="#F7B35C"/>
<path d="M62.5466 37.0558C64.7287 37.0558 66.4976 36.6862 66.4976 36.2304C66.4976 35.7745 64.7287 35.4049 62.5466 35.4049C60.3646 35.4049 58.5957 35.7745 58.5957 36.2304C58.5957 36.6862 60.3646 37.0558 62.5466 37.0558Z" fill="#FBD56F"/>
<path d="M56.7249 35.5257L56.3495 35.3319C56.3495 35.3319 56.1739 36.0112 56.2313 36.4952C56.2369 36.5443 56.2587 36.5906 56.2937 36.6279C56.3286 36.6651 56.3751 36.6915 56.4268 36.7035C57.2905 36.9023 61.4072 37.7493 64.2381 36.5563C64.2792 36.539 64.3146 36.512 64.341 36.4781C64.3674 36.4441 64.3838 36.4044 64.3886 36.3628C64.4233 36.0654 64.3004 35.4515 64.2513 35.3319C64.1457 35.0745 56.7249 35.5257 56.7249 35.5257Z" fill="#FBD56F"/>
<path d="M56.7249 35.5257L56.3495 35.3319C56.3495 35.3319 56.1739 36.0112 56.2313 36.4952C56.2369 36.5443 56.2587 36.5906 56.2937 36.6279C56.3286 36.6651 56.3751 36.6915 56.4268 36.7035C57.2905 36.9023 61.4072 37.7493 64.2381 36.5563C64.2792 36.539 64.3146 36.512 64.341 36.4781C64.3674 36.4441 64.3838 36.4044 64.3886 36.3628C64.4233 36.0654 64.3004 35.4515 64.2513 35.3319C64.1457 35.0745 56.7249 35.5257 56.7249 35.5257Z" fill="#F7B35C"/>
<path d="M60.3005 36.1566C62.4826 36.1566 64.2515 35.787 64.2515 35.3312C64.2515 34.8753 62.4826 34.5057 60.3005 34.5057C58.1185 34.5057 56.3496 34.8753 56.3496 35.3312C56.3496 35.787 58.1185 36.1566 60.3005 36.1566Z" fill="#FBD56F"/>
<path d="M58.224 33.7894L57.8485 33.5956C57.8485 33.5956 57.6729 34.2748 57.7303 34.7589C57.7359 34.808 57.7577 34.8543 57.7927 34.8915C57.8276 34.9288 57.8741 34.9552 57.9258 34.9672C58.7895 35.166 62.9063 36.013 65.7372 34.82C65.7782 34.8026 65.8137 34.7757 65.8401 34.7417C65.8665 34.7078 65.8829 34.6681 65.8876 34.6265C65.9224 34.329 65.7994 33.7152 65.7504 33.5956C65.6448 33.3382 58.224 33.7894 58.224 33.7894Z" fill="#FBD56F"/>
<path d="M58.224 33.7894L57.8485 33.5956C57.8485 33.5956 57.6729 34.2748 57.7303 34.7589C57.7359 34.808 57.7577 34.8543 57.7927 34.8915C57.8276 34.9288 57.8741 34.9552 57.9258 34.9672C58.7895 35.166 62.9063 36.013 65.7372 34.82C65.7782 34.8026 65.8137 34.7757 65.8401 34.7417C65.8665 34.7078 65.8829 34.6681 65.8876 34.6265C65.9224 34.329 65.7994 33.7152 65.7504 33.5956C65.6448 33.3382 58.224 33.7894 58.224 33.7894Z" fill="#F7B35C"/>
<path d="M61.7996 34.4219C63.9816 34.4219 65.7505 34.0523 65.7505 33.5964C65.7505 33.1406 63.9816 32.771 61.7996 32.771C59.6175 32.771 57.8486 33.1406 57.8486 33.5964C57.8486 34.0523 59.6175 34.4219 61.7996 34.4219Z" fill="#FBD56F"/>
<path d="M58.9642 31.7303L58.5887 31.5365C58.5887 31.5365 58.4131 32.2158 58.4705 32.6998C58.4762 32.7489 58.498 32.7952 58.5329 32.8324C58.5679 32.8697 58.6144 32.8961 58.666 32.9081C59.5298 33.1069 63.6465 33.9539 66.4774 32.7609C66.5185 32.7436 66.5539 32.7166 66.5803 32.6827C66.6067 32.6487 66.6231 32.609 66.6279 32.5674C66.6626 32.27 66.5397 31.6561 66.4906 31.5365C66.385 31.2791 58.9642 31.7303 58.9642 31.7303Z" fill="#FBD56F"/>
<path d="M58.9642 31.7303L58.5887 31.5365C58.5887 31.5365 58.4131 32.2158 58.4705 32.6998C58.4762 32.7489 58.498 32.7952 58.5329 32.8324C58.5679 32.8697 58.6144 32.8961 58.666 32.9081C59.5298 33.1069 63.6465 33.9539 66.4774 32.7609C66.5185 32.7436 66.5539 32.7166 66.5803 32.6827C66.6067 32.6487 66.6231 32.609 66.6279 32.5674C66.6626 32.27 66.5397 31.6561 66.4906 31.5365C66.385 31.2791 58.9642 31.7303 58.9642 31.7303Z" fill="#F7B35C"/>
<path d="M62.5398 32.3612C64.7218 32.3612 66.4907 31.9916 66.4907 31.5358C66.4907 31.0799 64.7218 30.7103 62.5398 30.7103C60.3578 30.7103 58.5889 31.0799 58.5889 31.5358C58.5889 31.9916 60.3578 32.3612 62.5398 32.3612Z" fill="#FBD56F"/>
<path d="M50.551 45.9407C52.5445 45.9407 54.1606 44.3508 54.1606 42.3895C54.1606 40.4283 52.5445 38.8384 50.551 38.8384C48.5575 38.8384 46.9414 40.4283 46.9414 42.3895C46.9414 44.3508 48.5575 45.9407 50.551 45.9407Z" fill="#FBD56F"/>
<path d="M50.551 45.9407C52.5445 45.9407 54.1606 44.3508 54.1606 42.3895C54.1606 40.4283 52.5445 38.8384 50.551 38.8384C48.5575 38.8384 46.9414 40.4283 46.9414 42.3895C46.9414 44.3508 48.5575 45.9407 50.551 45.9407Z" fill="#F7B35C"/>
<path d="M51.1741 45.9424C53.1676 45.9424 54.7837 44.3879 54.7837 42.4703C54.7837 40.5528 53.1676 38.9983 51.1741 38.9983C49.1805 38.9983 47.5645 40.5528 47.5645 42.4703C47.5645 44.3879 49.1805 45.9424 51.1741 45.9424Z" fill="#FBD56F"/>
<path opacity="0.5" d="M39.1852 39.0899C39.3838 39.342 39.5463 39.6169 39.6684 39.9077C39.7007 39.9829 39.7323 40.0619 39.7622 40.1448C40.2425 41.4752 39.4815 43.0706 39.2639 42.8985C39.0463 42.7264 38.3683 42.4071 38.2372 42.8558C38.1061 43.3045 37.6218 43.7631 37.6218 43.7631C36.7395 44.5856 35.9409 43.697 35.4167 42.7788C35.2454 42.4758 35.0931 42.164 34.9606 41.8448C34.8442 41.5628 34.7773 41.3679 34.7773 41.3679L38.4523 38.4658C38.4523 38.4658 38.8076 38.617 39.1852 39.0899Z" fill="#F25F5C"/>
<path opacity="0.5" d="M33.3932 40.9454C33.5918 41.1975 33.7543 41.4724 33.8764 41.7632C33.9087 41.8384 33.9403 41.9174 33.9702 42.0003C34.4505 43.3307 33.6895 44.9261 33.4719 44.754C33.2543 44.5819 32.5763 44.2626 32.4452 44.7113C32.3141 45.16 31.8298 45.6186 31.8298 45.6186C30.9475 46.4411 30.1489 45.5524 29.6247 44.6342C29.4534 44.3313 29.3011 44.0194 29.1686 43.7002C29.0522 43.4182 28.9854 43.2234 28.9854 43.2234L32.6603 40.3213C32.6603 40.3213 33.0156 40.4725 33.3932 40.9454Z" fill="#F25F5C"/>
<path opacity="0.5" d="M21.7643 43.5086C21.7437 43.8202 21.6794 44.1279 21.5732 44.424C21.5463 44.501 21.5162 44.5805 21.4822 44.662C20.9358 45.9704 19.2514 46.7485 19.2024 46.4873C19.1534 46.2261 18.8513 45.5793 18.4414 45.8472C18.0315 46.115 17.3429 46.1817 17.3429 46.1817C16.0973 46.293 16.0943 45.1364 16.323 44.1195C16.3997 43.7849 16.4972 43.4548 16.615 43.1306C16.7195 42.8447 16.8023 42.6551 16.8023 42.6551L21.6298 42.5946C21.6298 42.5946 21.7992 42.9211 21.7643 43.5086Z" fill="#F25F5C"/>
<path opacity="0.5" d="M15.527 42.0499C15.5064 42.3614 15.4421 42.6691 15.3359 42.9653C15.309 43.0423 15.2789 43.1218 15.2448 43.2033C14.6985 44.5116 13.0141 45.2897 12.9651 45.0286C12.9161 44.7674 12.614 44.1205 12.2041 44.3884C11.7942 44.6563 11.1056 44.7229 11.1056 44.7229C9.85998 44.8343 9.85703 43.6777 10.0857 42.6607C10.1624 42.3262 10.2599 41.996 10.3777 41.6719C10.4822 41.386 10.565 41.1964 10.565 41.1964L15.3925 41.1359C15.3925 41.1359 15.5619 41.4624 15.527 42.0499Z" fill="#F25F5C"/>
<path opacity="0.5" d="M39.1852 39.0899C39.3838 39.342 39.5463 39.6169 39.6684 39.9077C39.7007 39.9829 39.7323 40.0619 39.7622 40.1448C40.2425 41.4752 39.4815 43.0706 39.2639 42.8985C39.0463 42.7264 38.3683 42.4071 38.2372 42.8558C38.1061 43.3045 37.6218 43.7631 37.6218 43.7631C36.7395 44.5856 35.9409 43.697 35.4167 42.7788C35.2454 42.4758 35.0931 42.164 34.9606 41.8448C34.8442 41.5628 34.7773 41.3679 34.7773 41.3679L38.4523 38.4658C38.4523 38.4658 38.8076 38.617 39.1852 39.0899Z" fill="#F25F5C"/>
<path opacity="0.5" d="M33.3932 40.9454C33.5918 41.1975 33.7543 41.4724 33.8764 41.7632C33.9087 41.8384 33.9403 41.9174 33.9702 42.0003C34.4505 43.3307 33.6895 44.9261 33.4719 44.754C33.2543 44.5819 32.5763 44.2626 32.4452 44.7113C32.3141 45.16 31.8298 45.6186 31.8298 45.6186C30.9475 46.4411 30.1489 45.5524 29.6247 44.6342C29.4534 44.3313 29.3011 44.0194 29.1686 43.7002C29.0522 43.4182 28.9854 43.2234 28.9854 43.2234L32.6603 40.3213C32.6603 40.3213 33.0156 40.4725 33.3932 40.9454Z" fill="#F25F5C"/>
<path opacity="0.5" d="M21.7643 43.5086C21.7437 43.8202 21.6794 44.1279 21.5732 44.424C21.5463 44.501 21.5162 44.5805 21.4822 44.662C20.9358 45.9704 19.2514 46.7485 19.2024 46.4873C19.1534 46.2261 18.8513 45.5793 18.4414 45.8472C18.0315 46.115 17.3429 46.1817 17.3429 46.1817C16.0973 46.293 16.0943 45.1364 16.323 44.1195C16.3997 43.7849 16.4972 43.4548 16.615 43.1306C16.7195 42.8447 16.8023 42.6551 16.8023 42.6551L21.6298 42.5946C21.6298 42.5946 21.7992 42.9211 21.7643 43.5086Z" fill="#F25F5C"/>
<path opacity="0.5" d="M15.527 42.0499C15.5064 42.3614 15.4421 42.6691 15.3359 42.9653C15.309 43.0423 15.2789 43.1218 15.2448 43.2033C14.6985 44.5116 13.0141 45.2897 12.9651 45.0286C12.9161 44.7674 12.614 44.1205 12.2041 44.3884C11.7942 44.6563 11.1056 44.7229 11.1056 44.7229C9.85998 44.8343 9.85703 43.6777 10.0857 42.6607C10.1624 42.3262 10.2599 41.996 10.3777 41.6719C10.4822 41.386 10.565 41.1964 10.565 41.1964L15.3925 41.1359C15.3925 41.1359 15.5619 41.4624 15.527 42.0499Z" fill="#F25F5C"/>
<path d="M48.9882 22.4421C48.9882 34.3927 44.6264 44.0806 24.4975 44.0806C10.9716 44.0806 0.507297 40.9657 0.00675773 22.4421C-0.316075 10.4951 10.9716 0.803467 24.4975 0.803467C38.0233 0.803467 48.9882 10.4914 48.9882 22.4421Z" fill="#EFADF0"/>
<path opacity="0.2" d="M19.9033 1.1792H19.909C19.9077 1.17996 19.9063 1.18045 19.9048 1.18062L19.9033 1.1792Z" fill="black"/>
<path opacity="0.2" d="M19.9513 1.17327C20.0135 1.16399 20.1365 1.14809 20.3153 1.1355L19.917 1.17923C19.9227 1.1779 19.9299 1.17658 19.9384 1.17525C19.9427 1.17459 19.9463 1.17393 19.9513 1.17327Z" fill="black"/>
<path opacity="0.5" d="M38.0482 10.015L36.2606 10.6115C35.3493 9.47882 34.3688 8.39545 33.3238 7.3667C32.1833 6.23924 30.939 5.20609 29.6048 4.27898C25.0277 1.13492 21.4875 1.05473 20.3184 1.13558L23.0055 0.842651C25.5453 1.24767 27.9877 2.06596 30.2198 3.25968C31.7814 4.09402 33.2358 5.08986 34.5552 6.2281C36.8948 8.24086 38.0482 10.015 38.0482 10.015Z" fill="#F25F5C"/>
<path opacity="0.5" d="M21.8879 11.1407C21.8879 11.1407 19.5282 3.85056 21.5304 1.86233C21.5304 1.86233 24.6766 0.868215 27.0363 6.50154L21.8879 11.1407Z" fill="#F25F5C"/>
<path d="M33.8359 4.33255C33.8359 4.33255 40.0936 -0.596094 42.8619 0.305788C42.8619 0.305788 45.1069 2.57786 40.4866 6.84164L33.8359 4.33255Z" fill="#EFADF0"/>
<path opacity="0.6" d="M33.8359 4.33255C33.8359 4.33255 40.0936 -0.596094 42.8619 0.305788C42.8619 0.305788 45.1069 2.57786 40.4866 6.84164L33.8359 4.33255Z" fill="#F25F5C"/>
<path d="M53.3502 27.9442C53.3502 34.3679 48.3766 39.4428 43.6969 39.4428C39.0172 39.4428 36.4033 34.3679 36.4033 27.9442C36.4033 21.5205 40.2048 16.5641 44.8767 16.313C52.2776 15.9154 53.3502 21.5205 53.3502 27.9442Z" fill="#EFADF0"/>
<path opacity="0.5" d="M53.3502 27.9442C53.3502 34.3679 48.3766 39.4428 43.6969 39.4428C39.0172 39.4428 36.4033 34.3679 36.4033 27.9442C36.4033 21.5205 40.2048 16.5641 44.8767 16.313C52.2776 15.9154 53.3502 21.5205 53.3502 27.9442Z" fill="#F25F5C"/>
<path d="M43.411 30.7591C44.2798 30.7591 44.9841 29.9283 44.9841 28.9034C44.9841 27.8785 44.2798 27.0477 43.411 27.0477C42.5422 27.0477 41.8379 27.8785 41.8379 28.9034C41.8379 29.9283 42.5422 30.7591 43.411 30.7591Z" fill="#F25F5C"/>
<path d="M48.9882 29.1675C49.857 29.1675 50.5613 28.3367 50.5613 27.3119C50.5613 26.287 49.857 25.4562 48.9882 25.4562C48.1193 25.4562 47.415 26.287 47.415 27.3119C47.415 28.3367 48.1193 29.1675 48.9882 29.1675Z" fill="#F25F5C"/>
<path d="M34.8602 5.02978C34.8612 5.44544 34.7566 5.85549 34.5548 6.2267C34.2885 6.71994 33.8563 7.11955 33.3235 7.36529C32.183 6.23783 30.9386 5.20468 29.6045 4.27757C29.7233 3.89895 29.9336 3.55037 30.2194 3.25827C30.4658 3.00367 30.7672 2.79985 31.1039 2.65999C31.4407 2.52014 31.8054 2.44738 32.1744 2.44641C33.6574 2.44641 34.8602 3.60289 34.8602 5.02978Z" fill="#FBD56F"/>
</svg>

	);
}

export default AmmonitePiggy;
