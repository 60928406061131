import React from 'react';

function InvestmentAllocationButton({ colour }) {
	return (
<svg width="70" height="30" viewBox="0 0 70 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<rect width="70" height="30" rx="15" fill="#373986"/>
<path fillRule="evenodd" clipRule="evenodd" d="M46.7963 3.54006C46.7669 3.42367 46.7081 3.32069 46.6521 3.21536C46.6066 3.12647 46.5738 3.03304 46.5139 2.95592C46.4576 2.87969 46.3789 2.82554 46.3062 2.75982C46.2157 2.67585 46.1317 2.59044 46.0257 2.52872C46.0078 2.51735 45.9969 2.49929 45.9778 2.48991C45.908 2.45256 45.8324 2.45065 45.7599 2.42416C45.6481 2.38252 45.5376 2.33889 45.4176 2.3213C45.2999 2.3066 45.1903 2.32082 45.0745 2.32962C44.9939 2.33567 44.9159 2.31551 44.833 2.33404L36.7002 4.15048C35.8008 4.35138 35.2365 5.24248 35.437 6.14036C35.6375 7.03827 36.5274 7.60457 37.4268 7.40367L42.0235 6.37701L37.1691 15.5013L29.2822 12.8825C28.5011 12.6197 27.6504 12.9703 27.2742 13.7015L21.3212 25.2775C20.9011 26.0954 21.223 27.1011 22.0409 27.5213C22.4034 27.7067 22.7986 27.7482 23.1662 27.6661C23.633 27.5619 24.0506 27.2602 24.2862 26.8012L29.5869 16.4946L37.4541 19.1092C38.2268 19.3653 39.07 19.0267 39.4511 18.3097L44.5517 8.72057L45.3862 12.4569C45.5868 13.3548 46.4766 13.9211 47.3761 13.7202C48.2755 13.5193 48.8398 12.6282 48.6393 11.7303L46.8228 3.59734C46.8181 3.57619 46.8027 3.56084 46.7963 3.54006Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="70" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>
	);
}

export default InvestmentAllocationButton;
