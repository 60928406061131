export const SBR_INITIAL_CONTEXT =  {
	adviser: 1,
	financialCheck: [],
	annualRetirementSpending: 0,
	suitabilityReportID: 'none',
	sessionDate: Date.now(),
	names: "",
	reportAdviser: "",
	environmentPriority: false,
	checklist: {
		sentAdviceFrontLink: false,
		recentID: false,
		signedLOAs: false,
		signedCIDD: false,
		completedFactFind: false,
		completedATR: false,
		initialPayment: false,
		callback: false,
		suitabilityReport: false,
		clientAgreement: false,
		implementation: false,
		addedToJHSystem: false,
		transactionsComplete: false,
	},
	customerEmail: ' ',
	customerTelephone: '',
	customerInfo: {
		couple: false,
		customers: {
			customer1: {
				customerFirstName: '',
				customerLastName: '',
				customerGender: true,
				customerDoB: null,
				customerWorkSituation: null,
				customerStartingCashBalance: 0,
				customerGrossMonthlyIncome: 0,
				customerAgreedMonthlyBudget: 0,
				customerMonthlyExpenditure: 0,
				customerNetMonthlyIncome: 0,
				customerAgreedLumpSum: 0,
				customerMarginalTaxRate: 0,
				niNumber: "",
				isSuitabilityReportOwner: false
			},
			customer2: {
				customerFirstName: '',
				customerLastName: '',
				customerGender: true,
				customerDoB: null,
				customerWorkSituation: null,
				customerStartingCashBalance: 0,
				customerGrossMonthlyIncome: 0,
				customerAgreedMonthlyBudget: 0,
				customerMonthlyExpediture: 0,
				customerNetMonthlyIncome: 0,
				customerAgreedLumpSum: 0,
				customerMarginalTaxRate: 0,
				niNumber: "",
				isSuitabilityReportOwner: false
			},
			customerTaxRate: 0.2
		},
		address: "",
		hasChildren: false,
		children: [],
	},
	customerGoals: [],
	pensions: [],
	investments: [],
	newPensionsAdvice: [],
	newISAsAdvice: [],
	newGIAsAdvice: [],
	newCashAdvice: [],
	additionalCustomerInformation: {
		why: ' ',
		what: ' ',
		notes: ' '
	},
	clientAttitudeToRisk: 0,
	clientCapacityForLoss: 0,
	clientAttitudeToRiskComment: " ",
	clientCapacityForLossComment: " ",
	whyRatingAppropriate: " ",
	factFindNotes: ' ',
	suitabilityReportFocus: 0,
	current: true,
	previewed: false,
};