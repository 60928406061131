import React from 'react';
import AmmoniteMenuCircleFilledTicked from '../../Assets/SVG/menuCircleFilledTicked';

import './covered.css';

function AmmoniteCovered({ coveredItemText }) {
	return (
		<div className="coveredContainer">
			<div className="coveredTick">
				<AmmoniteMenuCircleFilledTicked
					colour="#3F762C"
					width="36px"
					height="36px"
					cx="18px"
					cy="18px"
					r="14px"
					scale={0.85}
				/>
			</div>
			<div className="coveredText">{coveredItemText}</div>
		</div>
	);
}

export default AmmoniteCovered;
