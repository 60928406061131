import React from 'react';

function AmmoniteFamily({}) {
	return (
		<svg width="255" height="220" viewBox="0 0 255 220" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.3"
				d="M152.923 164.077C157.845 163.796 163.952 160.456 168.701 158.981C174.32 157.235 179.963 155.557 185.627 153.948C205.34 148.34 225.269 143.559 245.413 139.605C247.293 139.237 249.175 138.876 251.061 138.522C248.123 132.176 243.572 126.711 237.863 122.672C238.009 121.283 238.083 119.888 238.086 118.491C238.086 96.4112 220.44 78.5114 198.665 78.5114C191.961 78.5045 185.37 80.234 179.533 83.5315C164.121 73.3934 144.006 67.2576 121.997 67.2576C90.723 67.2576 63.2687 79.6495 47.712 98.3043L120.839 110.656L165.68 118.228C114.56 119.754 63.2821 121.271 12.3089 117.689C5.58023 124.2 1.32143 132.845 0.260369 142.148C-0.800693 151.451 1.40185 160.833 6.49159 168.692C6.49159 169.491 6.52499 170.282 6.58513 171.068C7.88356 171.442 9.13746 171.801 10.2622 172.039C28.3186 175.852 46.4907 179.044 64.7787 181.616C51.3022 189.925 34.8902 191.099 19.3446 194.618C26.8724 200.832 37.0951 204.654 48.3601 204.654H216.086C234.248 204.654 248.97 192.008 248.97 176.409C251.695 171.915 253.511 166.93 254.315 161.736C220.707 164.972 186.68 162.155 152.923 164.077Z"
				fill="#3F762C"
			/>
			<path
				d="M203.938 7.61452C203.938 7.61452 203.171 8.49429 204.486 10.1966C205.802 11.8988 204.739 15.5925 202.24 15.6866C199.741 15.7807 198.529 16.8274 199.132 18.2061C199.734 19.5849 200.458 22.861 197.737 22.3628C195.015 21.8647 194.155 22.9482 194.637 23.7809C195.119 24.6136 193.424 22.1249 193.424 22.1249C193.424 22.1249 191.907 24.3842 190.046 22.8026C188.184 21.221 187.444 6.88423 191.239 6.64123C195.034 6.39824 203.938 7.61452 203.938 7.61452Z"
				fill="#0C3957"
			/>
			<path d="M184.701 75.7942L183.836 81.8473H179.945L181.242 74.9294L184.701 75.7942Z" fill="#DBBD95" />
			<path
				d="M179.512 78.1719C179.512 78.1719 174.324 82.928 175.621 83.3604C176.918 83.7928 181.674 88.9813 182.539 89.4137C183.312 89.8004 191.619 92.4403 190.322 89.4137C189.025 86.387 185.998 84.6575 185.998 84.6575L185.133 79.469L179.512 78.1719Z"
				fill="#F2F2F2"
			/>
			<path
				opacity="0.1"
				d="M179.512 78.1719C179.512 78.1719 174.324 82.928 175.621 83.3604C176.918 83.7928 181.674 88.9813 182.539 89.4137C183.312 89.8004 191.619 92.4403 190.322 89.4137C189.025 86.387 185.998 84.6575 185.998 84.6575L185.133 79.469L179.512 78.1719Z"
				fill="black"
			/>
			<path d="M179.512 71.2534L178.647 77.3069H174.756L176.053 70.3887L179.512 71.2534Z" fill="#DBBD95" />
			<path
				d="M195.771 55.1859L190.105 36.4481C190.105 36.4481 199.617 27.8006 193.131 25.2063C186.646 22.6121 184.916 35.5833 184.916 35.5833L187.078 58.0669H195.726L195.771 55.1859Z"
				fill="#FBBEBE"
			/>
			<path
				d="M182.538 41.4194C182.538 41.4194 193.713 55.7523 192.017 57.882C190.321 60.0116 187.294 72.9829 187.294 72.9829C187.294 72.9829 187.727 78.1714 185.997 78.1714C184.268 78.1714 179.944 77.3067 179.944 76.0095C179.944 74.7124 183.835 58.2821 183.835 58.2821L172.594 43.5813L182.538 41.4194Z"
				fill="#BF802F"
			/>
			<path
				d="M181.674 42.2842L170.432 36.231C170.432 36.231 162.649 40.1223 164.811 43.5813C166.973 47.0404 176.917 56.1203 176.917 56.1203C176.917 56.1203 173.891 70.821 174.323 71.2534C174.756 71.6858 179.512 73.4153 180.809 72.9829C182.106 72.5505 189.024 55.6879 187.294 54.8231C185.565 53.9584 179.944 44.8785 179.944 44.8785L181.674 42.2842Z"
				fill="#BF802F"
			/>
			<path
				d="M212.373 200.967C212.373 200.967 204.59 205.723 203.725 205.723C202.861 205.723 191.186 208.75 196.375 210.912C201.563 213.074 221.453 210.047 221.02 208.75C220.588 207.453 221.02 199.67 220.156 199.67C219.291 199.67 212.373 200.967 212.373 200.967Z"
				fill="#0C3957"
			/>
			<path
				d="M203.724 207.021C203.724 207.021 195.942 211.777 195.077 211.777C194.212 211.777 182.538 214.803 187.727 216.965C192.915 219.127 212.804 216.1 212.372 214.803C211.94 213.506 212.372 205.723 211.507 205.723C210.642 205.723 203.724 207.021 203.724 207.021Z"
				fill="#0C3957"
			/>
			<path
				d="M211.94 115.357C211.94 115.357 210.643 121.842 208.481 126.598C206.319 131.355 208.914 153.838 210.211 157.297C211.508 160.756 224.047 201.832 220.156 202.264C216.264 202.697 208.481 202.264 208.481 202.264L195.942 162.918L198.104 110.168L211.94 115.357Z"
				fill="#BF802F"
			/>
			<path
				opacity="0.1"
				d="M211.94 115.357C211.94 115.357 210.643 121.842 208.481 126.598C206.319 131.355 208.914 153.838 210.211 157.297C211.508 160.756 224.047 201.832 220.156 202.264C216.264 202.697 208.481 202.264 208.481 202.264L195.942 162.918L198.104 110.168L211.94 115.357Z"
				fill="#BF802F"
			/>
			<path
				d="M209.346 115.789C209.346 115.789 207.617 127.463 203.725 130.057C199.834 132.651 201.131 135.246 201.131 135.246L202.861 158.162C202.861 158.162 219.723 199.67 214.535 204.858C209.346 210.047 204.59 207.885 202.428 207.452C200.266 207.02 196.375 197.94 198.537 195.346C200.699 192.752 194.213 177.186 192.484 174.592C190.754 171.998 192.484 168.539 190.322 167.674C188.16 166.809 183.404 161.188 183.404 157.297C183.404 153.405 177.35 111.897 181.242 109.303C185.133 106.709 210.077 114.657 210.077 114.657L209.346 115.789Z"
				fill="#BF802F"
			/>
			<path
				d="M217.561 55.2555C212.307 55.2555 208.049 50.9967 208.049 45.7432C208.049 40.4897 212.307 36.231 217.561 36.231C222.814 36.231 227.073 40.4897 227.073 45.7432C227.073 50.9967 222.814 55.2555 217.561 55.2555Z"
				fill="#FBBEBE"
			/>
			<path
				d="M195.943 18.9361C191.645 18.9361 188.16 15.4517 188.16 11.1534C188.16 6.85507 191.645 3.37061 195.943 3.37061C200.241 3.37061 203.726 6.85507 203.726 11.1534C203.726 15.4517 200.241 18.9361 195.943 18.9361Z"
				fill="#DBBD95"
			/>
			<path
				d="M211.075 45.7432C211.075 45.7432 205.887 55.2554 203.293 55.2554C200.698 55.2554 213.67 65.2001 213.67 65.2001C213.67 65.2001 217.993 52.6612 218.858 52.2288C219.723 51.7964 211.075 45.7432 211.075 45.7432Z"
				fill="#FBBEBE"
			/>
			<path
				d="M209.346 54.3909C209.346 54.3909 220.588 58.2823 220.156 59.5794C219.723 60.8765 223.182 63.9032 223.182 65.2003C223.182 66.4974 224.047 78.1716 218.859 87.6838C213.67 97.1961 205.455 104.114 209.346 108.438C213.238 112.762 219.291 120.112 212.805 120.112C206.32 120.112 195.943 110.6 186.43 114.059C176.918 117.518 179.08 104.547 179.08 104.547C179.08 104.547 177.783 94.6019 180.377 91.5752C182.971 88.5486 192.051 66.9298 192.051 64.3355C192.051 61.7413 200.699 51.7966 202.861 51.7966C205.022 51.7966 209.346 54.3909 209.346 54.3909Z"
				fill="#3F762C"
			/>
			<path
				d="M193.78 15.9092C193.78 15.9092 190.259 22.6034 190.074 23.7963C189.889 24.9891 183.836 15.9092 183.836 15.9092L190.754 11.1531L193.78 15.9092Z"
				fill="#DBBD95"
			/>
			<path
				d="M192.483 21.098L185.132 13.3152L176.485 17.2066C176.485 17.2066 168.42 34.934 167.912 36.2311C167.405 37.5283 180.808 43.5815 182.105 43.5815C183.403 43.5815 192.483 30.1779 192.483 30.1779C192.483 30.1779 194.644 23.6922 192.483 21.098Z"
				fill="#3F762C"
			/>
			<path d="M195.942 31.4756V39.2585H190.754L192.46 31.4756H195.942Z" fill="#DBBD95" />
			<path
				d="M192.982 37.3123C193.638 37.3034 194.28 37.4947 194.825 37.8605C195.369 38.2263 195.789 38.7493 196.028 39.3598C196.366 40.2739 196.251 41.2346 194.645 41.6361C191.186 42.5008 190.754 41.6361 190.754 41.6361C190.754 41.6361 184.749 40.7713 186.022 39.0418C187.295 37.3123 190.754 37.3123 190.754 37.3123H192.982Z"
				fill="#DBBD95"
			/>
			<path
				d="M174.324 74.7131C174.324 74.7131 169.135 79.4693 170.432 79.9016C171.73 80.334 176.486 85.5225 177.35 85.9549C178.124 86.3416 186.43 88.9815 185.133 85.9549C183.836 82.9283 180.81 81.1988 180.81 81.1988L179.945 76.0103L174.324 74.7131Z"
				fill="#F2F2F2"
			/>
			<path
				d="M220.95 38.4219C220.355 38.0732 219.652 37.6389 219.635 36.9493C219.651 36.696 219.74 36.4526 219.892 36.2492C220.044 36.0458 220.252 35.8912 220.49 35.8042C220.97 35.6387 221.478 35.5703 221.984 35.6029C222.179 35.593 222.373 35.6123 222.562 35.6601C223.054 35.8121 223.338 36.3121 223.726 36.6515C224.289 37.1442 225.065 37.2892 225.79 37.477C227.003 37.7919 228.167 38.2743 229.248 38.9103C229.462 39.0182 229.649 39.172 229.796 39.3606C229.966 39.7079 230.041 40.0939 230.015 40.4797C230.113 41.9467 230.993 43.2237 231.649 44.5392C232.306 45.8547 232.737 47.4934 231.966 48.7455C231.661 49.1351 231.375 49.5403 231.111 49.9594C230.565 51.0975 231.247 52.4989 230.852 53.698C230.676 54.17 230.392 54.5948 230.024 54.9392C229.265 55.7039 228.329 56.2704 227.3 56.5888C226.317 56.8913 225.098 57.0929 224.714 58.0462C224.548 58.4552 224.582 58.937 224.349 59.3116C224.061 59.7755 223.466 59.9254 222.929 60.0243C222.326 60.1622 221.705 60.2071 221.088 60.1575C220.78 60.1309 220.48 60.0418 220.207 59.8957C219.934 59.7496 219.694 59.5496 219.501 59.3077C219.326 58.9664 219.11 58.648 218.858 58.3598C218.232 57.8365 217.281 58.2498 216.477 58.1112C216.151 58.0497 215.85 57.8965 215.608 57.6696C215.366 57.4428 215.194 57.1516 215.112 56.8303C215.09 56.4881 214.996 56.1545 214.835 55.8515C214.336 55.2125 213.022 55.6636 212.692 54.9227C212.478 54.4413 212.926 53.9327 213.36 53.6329C214.066 53.1441 214.856 52.7682 215.49 52.188C216.124 51.6079 216.593 50.7398 216.394 49.9039C216.097 48.6502 214.46 47.9599 214.406 46.6726C214.375 45.9351 214.904 45.2879 215.498 44.8487C216.091 44.4096 216.779 44.0941 217.309 43.5807C217.697 43.2054 217.989 42.7344 218.41 42.3975C219.172 41.7882 220.215 41.7143 221.186 41.6147C221.707 41.5612 223.783 41.3207 223.702 40.5276C223.634 39.869 221.479 38.7316 220.95 38.4219Z"
				fill="#0C3957"
			/>
			<path
				d="M192.483 20.666C192.483 20.666 197.239 22.3955 197.239 24.5574C197.239 26.7193 199.401 33.6373 197.239 33.6373C195.077 33.6373 190.754 31.9776 190.754 31.9776L192.483 20.666Z"
				fill="#3F762C"
			/>
			<path
				d="M193.78 51.7974C193.78 51.7974 181.241 47.4736 180.809 47.4736C180.377 47.4736 174.323 44.0146 174.756 42.7175L176.485 31.9081C176.485 31.9081 183.403 17.6396 178.647 17.6396C173.891 17.6396 171.297 28.8814 171.297 28.8814C171.297 28.8814 160.92 48.3384 166.541 52.6621C172.162 56.9859 195.51 64.7686 196.807 63.4715C198.104 62.1744 193.78 51.7974 193.78 51.7974Z"
				fill="#FBBEBE"
			/>
			<path
				d="M181.674 23.6926L178.755 26.5051L182.539 30.1783C182.539 30.1783 187.727 28.0164 187.295 29.3135C186.862 30.6107 184.268 34.502 182.539 34.502C180.809 34.502 171.808 28.8812 171.768 27.1516C171.729 25.4221 177.35 19.3689 178.647 18.9365C179.944 18.5041 181.674 23.6926 181.674 23.6926Z"
				fill="#DBBD95"
			/>
			<path
				d="M187.162 20.6783C187.162 20.6783 180.81 26.2864 179.945 26.2864C179.08 26.2864 177.507 17.8604 177.507 17.8604C177.507 17.8604 182.633 14.5207 184.532 15.4312C186.43 16.3418 187.162 20.6783 187.162 20.6783Z"
				fill="#3F762C"
			/>
			<path
				d="M209.346 56.9851C209.346 56.9851 209.346 50.9319 196.374 50.9319H190.754L193.348 65.2003L201.995 67.7946L209.346 56.9851Z"
				fill="#3F762C"
			/>
			<path
				d="M181.012 7.99937C182.283 7.99325 183.553 7.91493 184.815 7.76481C185.806 5.36901 187.069 3.35873 188.435 2.60925C189.404 1.74182 190.9 1.11144 192.073 0.613033C194.954 -0.610881 199.75 0.152505 200.03 1.54446C205.699 4.2532 204.266 9.01021 204.266 9.01021C203.385 10.8721 201.409 10.0469 199.733 9.20375C199.305 10.5535 198.294 11.6989 196.846 11.7534C194.077 11.8577 192.735 13.0171 193.402 14.5445C194.07 16.0719 194.872 19.7012 191.857 19.1493C188.842 18.5975 187.889 19.7978 188.423 20.7202C188.957 21.6427 187.079 18.8857 187.079 18.8857C187.079 18.8857 185.399 21.3886 183.337 19.6365C183.128 19.4357 182.979 19.1809 182.907 18.9006C181.948 19.3133 181.189 19.4699 180.757 19.277C178.287 18.1719 179.821 15.5769 179.821 15.5769C179.821 15.5769 176.524 15.071 177.586 15.1549C178.649 15.2388 179.321 13.8615 177.523 11.3792C175.725 8.89687 179.346 8.05571 181.012 7.99937Z"
				fill="#0C3957"
			/>
			<path d="M197.739 6.05363L198.97 5.64404L200.198 9.33634L198.968 9.74592L197.739 6.05363Z" fill="#BF802F" />
			<path
				d="M67.3701 39.1118C67.3701 39.1118 66.854 40.8321 69.7785 42.3804C72.703 43.9287 73.5632 49.7777 70.1226 51.498C66.682 53.2183 65.6498 55.4547 67.3701 57.0029C69.0904 58.5512 72.187 62.6799 68.0582 63.7121C63.9295 64.7443 63.4134 66.8087 64.6176 67.6688C65.8218 68.529 61.8652 66.1205 61.8652 66.1205C61.8652 66.1205 61.177 70.2493 57.5644 69.2171C53.9518 68.1849 43.802 48.5735 48.9629 45.821C54.1238 43.0685 67.3701 39.1118 67.3701 39.1118Z"
				fill="#0C3957"
			/>
			<path
				d="M54.0406 184.956L55.1591 195.394L50.3125 198.377L45.8389 197.631L48.0755 184.956H54.0406Z"
				fill="#8B6A3D"
			/>
			<path
				d="M57.7689 59.6899C63.5341 59.6899 68.2077 55.0162 68.2077 49.2511C68.2077 43.4859 63.5341 38.8123 57.7689 38.8123C52.0037 38.8123 47.3301 43.4859 47.3301 49.2511C47.3301 55.0162 52.0037 59.6899 57.7689 59.6899Z"
				fill="#8B6A3D"
			/>
			<path
				d="M61.3105 54.2843C61.3105 54.2843 62.2426 70.1289 63.361 70.1289C64.4795 70.1289 50.3125 69.3833 50.3125 69.3833C50.3125 69.3833 56.2775 58.9445 53.295 55.5892C50.3125 52.2339 61.3105 54.2843 61.3105 54.2843Z"
				fill="#8B6A3D"
			/>
			<path
				d="M45.8389 137.981L50.6854 156.621L46.957 188.311L55.532 187.565L58.8872 137.981H45.8389Z"
				fill="#2F2E41"
			/>
			<path
				opacity="0.2"
				d="M45.8389 137.981L50.6854 156.621L46.957 188.311L55.532 187.565L58.8872 137.981H45.8389Z"
				fill="#0C3957"
			/>
			<path
				d="M53.2951 193.529C53.2951 193.529 53.668 191.292 57.3961 193.529C61.1242 195.766 76.7824 202.85 68.5805 204.341C60.3786 205.832 43.602 202.477 43.602 201.358C43.602 200.24 43.2292 193.157 44.7204 193.902C46.2117 194.648 51.0583 199.494 53.2951 193.529Z"
				fill="#0C3957"
			/>
			<path
				d="M48.4484 190.921C48.4484 190.921 42.4833 200.241 42.1105 202.851C41.7377 205.46 36.1455 196.886 36.1455 196.886L41.3649 187.192L48.4484 190.921Z"
				fill="#8B6A3D"
			/>
			<path
				d="M68.2073 139.472L61.4966 161.841C61.4966 161.841 49.5665 193.53 48.8209 193.157C48.0753 192.785 39.1277 190.548 38.7549 189.429C38.3821 188.311 49.9394 158.113 49.9394 158.113L51.4306 138.727L68.2073 139.472Z"
				fill="#0C3957"
			/>
			<path
				d="M35.0272 195.394C36.0451 195.324 37.0595 195.574 37.9285 196.109C38.7975 196.644 39.4776 197.437 39.8737 198.377C41.365 201.732 43.2291 202.105 43.6019 202.478C43.9747 202.851 42.4834 210.68 43.6019 211.425C44.7203 212.171 45.8388 220 42.1106 220C38.3825 220 32.4175 215.899 32.7903 215.154C33.1631 214.408 33.1631 209.188 32.0446 206.952C30.9262 204.715 29.0621 200.987 32.0446 198.75C35.0272 196.513 35.0272 195.394 35.0272 195.394Z"
				fill="#0C3957"
			/>
			<path
				d="M70.4442 104.8L71.1899 111.511C71.1899 111.511 76.7821 117.103 78.6461 123.814C80.5102 130.525 95.7956 141.709 91.3218 143.2C86.8481 144.691 81.6287 145.437 68.5802 143.2C55.5317 140.963 35.0269 145.81 35.0269 133.507C35.0269 133.507 32.4172 130.152 30.5531 134.998C29.2401 138.309 27.7466 141.545 26.0793 144.692C26.0793 144.692 21.6056 136.862 23.0968 133.507C24.5881 130.152 23.0968 130.525 24.9609 126.796C26.825 123.068 36.1453 109.274 39.8735 108.529C43.6016 107.783 50.6851 98.0897 50.3123 96.9713C49.9394 95.8528 70.4442 104.8 70.4442 104.8Z"
				fill="#3F762C"
			/>
			<path
				d="M50.3127 63.0452C50.3127 63.0452 65.5981 66.4005 66.3437 68.6374C66.7631 69.8956 68.716 72.2155 70.3445 74.0046C73.0938 76.9801 74.9215 80.6888 75.6065 84.6817C75.9953 87.0601 74.3607 89.1988 73.6136 91.9383C71.3767 100.14 69.699 98.0897 71.9359 104.055C74.1728 110.02 60.7515 112.257 55.5321 109.647C50.3127 107.037 44.5341 91.5655 44.5341 89.7014C44.5341 87.8373 39.5011 66.7733 43.602 65.282C47.703 63.7908 50.3127 63.0452 50.3127 63.0452Z"
				fill="#BF802F"
			/>
			<path
				d="M71.5632 80.9399L76.037 100.699L90.7632 107.223C90.7632 107.223 101.948 104.614 102.32 108.715C102.564 111.392 89.6447 112.443 89.6447 112.443L68.2079 104.054L63.3613 85.7865L71.5632 80.9399Z"
				fill="#8B6A3D"
			/>
			<path
				d="M66.3436 73.4841C66.3436 73.4841 75.664 79.0763 73.4271 82.0589C71.1902 85.0414 65.598 89.888 64.1068 89.888C62.6155 89.888 61.4971 80.9404 61.4971 78.3307C61.4971 75.721 66.3436 73.4841 66.3436 73.4841Z"
				fill="#BF802F"
			/>
			<path
				d="M57.0172 35.696C57.0172 35.696 56.4455 37.6018 59.6853 39.317C62.925 41.0321 63.8779 47.5117 60.0664 49.4174C56.2549 51.3232 55.1115 53.8007 57.0172 55.5158C58.923 57.231 62.3533 61.8048 57.7795 62.9483C53.2057 64.0917 52.634 66.3786 53.968 67.3315C55.3021 68.2843 50.9188 65.6163 50.9188 65.6163C50.9188 65.6163 50.1565 70.1901 46.1545 69.0467C42.1524 67.9032 38.1593 47.118 42.6205 41.7645C46.7686 36.7868 57.0172 35.696 57.0172 35.696Z"
				fill="#0C3957"
			/>
			<path
				d="M58.2349 33.7345C58.234 30.9425 49.8809 32.9487 46.2996 36.8469C43.8196 39.5464 40.0527 43.6078 43.2092 46.4706C46.3657 49.3334 37.7987 53.0863 35.501 54.2241C33.2034 55.3619 28.667 58.8415 32.7631 61.1758C36.8592 63.51 36.7931 65.8664 35.2516 66.4243C33.7101 66.9822 38.6503 65.5948 38.6503 65.5948C38.6503 65.5948 38.1512 70.2047 42.3134 70.1827C46.4755 70.1606 58.4164 47.2866 55.5639 40.9286C52.9115 35.0168 58.2349 33.7345 58.2349 33.7345Z"
				fill="#0C3957"
			/>
			<path
				d="M56.4261 33.122C67.5963 32.4494 68.7174 40.8577 68.7174 40.8577C68.6402 45.7236 60.9129 43.3526 58.7667 44.078C56.1775 42.2154 51.1866 38.6911 47.6172 36.176C50.2086 34.3513 53.2613 33.293 56.4261 33.122Z"
				fill="#0C3957"
			/>
			<path d="M143.361 130.601H139.789V213.949H143.361V130.601Z" fill="#381E1D" />
			<path
				d="M139.789 130.601C139.789 130.601 145.862 122.98 150.863 132.863C150.863 132.863 148.481 137.268 147.767 135.125C147.052 132.982 145.341 129.778 143.339 131.678"
				fill="#381E1D"
			/>
			<path
				d="M139.252 116.043C139.252 116.043 148.237 124.888 143.812 132.359C143.675 132.59 143.487 132.787 143.263 132.934C143.038 133.082 142.783 133.176 142.517 133.21C142.25 133.244 141.979 133.217 141.725 133.131C141.47 133.045 141.239 132.902 141.048 132.712C139.523 131.204 137.111 127.457 134.148 118.186L139.252 116.043Z"
				fill="#FBBEBE"
			/>
			<path
				opacity="0.1"
				d="M132.942 69.4578C132.942 69.4578 142.23 72.3154 140.444 83.508C138.658 94.7005 139.372 102.559 139.372 102.559L141.753 116.847L137.467 122.682C137.467 122.682 130.085 115.299 127.584 107.798C125.084 100.297 125.322 70.6484 132.942 69.4578Z"
				fill="black"
			/>
			<path
				d="M132.823 69.3386C132.823 69.3386 142.11 72.1963 140.324 83.3888C138.538 94.5813 139.253 102.44 139.253 102.44L141.634 116.728L137.348 122.563C137.348 122.563 129.965 115.18 127.465 107.679C124.964 100.178 125.203 70.5293 132.823 69.3386Z"
				fill="#3F762C"
			/>
			<path
				d="M121.933 196.793L120.41 208.195C120.41 208.195 124.319 209.795 125.49 209.432C126.661 209.07 128.714 210.542 128.714 210.542C128.714 210.542 137.447 213.301 139.162 210.822C140.877 208.343 136.987 207.584 133.831 205.716C130.675 203.847 129.34 196.633 129.34 196.633L121.933 196.793Z"
				fill="#0C3957"
			/>
			<path
				d="M138.598 116.312V158.463L130.501 198.709H121.928L123.238 160.13C123.238 160.13 114.308 128.934 118.237 117.265L138.598 116.312Z"
				fill="#BF802F"
			/>
			<path
				d="M131.993 121.631C131.993 121.631 144.477 123.398 145.363 132.035C145.39 132.302 145.356 132.572 145.263 132.825C145.171 133.077 145.022 133.304 144.828 133.49C144.634 133.676 144.4 133.815 144.144 133.897C143.888 133.979 143.617 134.002 143.351 133.963C141.228 133.658 137.06 132.08 129.165 126.389L131.993 121.631Z"
				fill="#FBBEBE"
			/>
			<path
				d="M133.537 67.4336C133.537 67.4336 131.513 70.8867 132.347 76.8401C132.347 76.8401 120.38 75.828 120.737 68.922C120.737 68.922 126.572 67.4932 127.941 62.1946L133.537 67.4336Z"
				fill="#FBBEBE"
			/>
			<path
				d="M113.598 199.294L112.075 210.696C112.075 210.696 115.984 212.296 117.155 211.933C118.326 211.571 120.379 213.043 120.379 213.043C120.379 213.043 129.112 215.802 130.827 213.323C132.542 210.844 128.652 210.085 125.496 208.217C122.34 206.348 121.005 199.134 121.005 199.134L113.598 199.294Z"
				fill="#0C3957"
			/>
			<path
				opacity="0.1"
				d="M129.609 119.586V161.737L121.512 201.982H112.939L114.249 163.403C114.249 163.403 105.319 132.207 109.248 120.538L129.609 119.586Z"
				fill="black"
			/>
			<path
				d="M129.251 119.586V161.737L121.155 201.982H112.582L113.891 163.403C113.891 163.403 104.961 132.207 108.891 120.538L129.251 119.586Z"
				fill="#BF802F"
			/>
			<path
				opacity="0.1"
				d="M124.964 69.5773C124.964 69.5773 117.463 64.6955 112.462 79.222C107.461 93.7484 109.485 120.539 109.485 120.539C109.485 120.539 130.799 127.445 138.419 117.8V103.393C138.419 103.393 142.467 86.9615 136.752 72.6731C136.752 72.6731 131.751 74.6973 124.964 69.5773Z"
				fill="black"
			/>
			<path
				opacity="0.1"
				d="M124.726 69.9343C124.726 69.9343 117.225 65.0524 112.224 79.5789C107.223 94.1054 109.247 120.896 109.247 120.896C109.247 120.896 130.56 127.802 138.181 118.157V103.75C138.181 103.75 142.229 87.3184 136.514 73.0301C136.514 73.0301 131.513 75.0543 124.726 69.9343Z"
				fill="black"
			/>
			<path
				d="M124.726 69.5773C124.726 69.5773 117.225 64.6955 112.224 79.222C107.223 93.7484 109.247 120.539 109.247 120.539C109.247 120.539 130.56 127.445 138.181 117.8V103.393C138.181 103.393 142.229 86.9615 136.514 72.6731C136.514 72.6731 131.513 74.6973 124.726 69.5773Z"
				fill="#3F762C"
			/>
			<path
				opacity="0.1"
				d="M117.106 76.8406C117.106 76.8406 126.394 79.6982 124.608 90.8908C122.822 102.083 123.536 109.942 123.536 109.942L135.443 121.968L131.156 127.802C131.156 127.802 114.249 122.682 111.748 115.181C109.248 107.68 109.486 78.0313 117.106 76.8406Z"
				fill="black"
			/>
			<path
				opacity="0.1"
				d="M116.511 77.1975C116.511 77.1975 125.798 80.0552 124.012 91.2477C122.226 102.44 122.94 110.299 122.94 110.299L134.847 122.325L130.561 128.159C130.561 128.159 113.653 123.039 111.152 115.538C108.652 108.036 108.89 78.3882 116.511 77.1975Z"
				fill="black"
			/>
			<path
				d="M116.749 76.8406C116.749 76.8406 126.036 79.6982 124.25 90.8908C122.464 102.083 123.179 109.942 123.179 109.942L135.086 121.968L130.799 127.802C130.799 127.802 113.891 122.682 111.391 115.181C108.89 107.68 109.128 78.0313 116.749 76.8406Z"
				fill="#3F762C"
			/>
			<path
				opacity="0.1"
				d="M127.763 62.6118L133.359 67.8509C133.268 68.0157 133.185 68.185 133.11 68.3582C132.298 68.5748 131.461 68.6845 130.621 68.6844C128.483 68.6844 126.623 67.5988 125.024 66.422C126.142 65.4122 127.101 64.4335 127.763 62.6118Z"
				fill="black"
			/>
			<path
				d="M129.192 63.8019C134.518 63.8019 138.836 59.4839 138.836 54.1573C138.836 48.8307 134.518 44.5127 129.192 44.5127C123.865 44.5127 119.547 48.8307 119.547 54.1573C119.547 59.4839 123.865 63.8019 129.192 63.8019Z"
				fill="#E0E0E0"
			/>
			<path
				d="M130.799 68.267C136.125 68.267 140.444 63.949 140.444 58.6224C140.444 53.2958 136.125 48.9778 130.799 48.9778C125.472 48.9778 121.154 53.2958 121.154 58.6224C121.154 63.949 125.472 68.267 130.799 68.267Z"
				fill="#FBBEBE"
			/>
			<path
				d="M121.214 54.1577C121.214 54.1577 117.603 63.6145 125.243 66.5066C125.879 66.1816 126.429 65.7105 126.848 65.1319C127.267 64.5533 127.543 63.8836 127.653 63.1778C127.763 62.472 127.704 61.7502 127.481 61.0715C127.258 60.3928 126.877 59.7767 126.37 59.2736L121.214 54.1577Z"
				fill="#E0E0E0"
			/>
			<path
				d="M138.603 52.0406C138.603 52.0406 141.932 52.8481 136.098 54.8723C135.138 55.1945 134.254 55.711 133.502 56.3895C132.75 57.0681 132.145 57.8942 131.726 58.8164C131.465 59.3788 131.091 59.8817 130.628 60.2939C130.164 60.7061 129.622 61.019 129.033 61.2132C128.444 61.4074 127.821 61.4788 127.204 61.423C126.586 61.3671 125.986 61.1853 125.442 60.8886L123.596 59.8732L120.381 52.3719L124.191 47.9663L130.74 47.1328L138.603 52.0406Z"
				fill="#E0E0E0"
			/>
			<path
				d="M128.239 63.445C128.765 63.445 129.191 62.6987 129.191 61.7781C129.191 60.8574 128.765 60.1111 128.239 60.1111C127.713 60.1111 127.286 60.8574 127.286 61.7781C127.286 62.6987 127.713 63.445 128.239 63.445Z"
				fill="#FBBEBE"
			/>
			<path
				d="M124.965 69.5771C124.965 69.5771 131.454 79.7576 135.145 79.7576C138.836 79.7576 132.557 70.2115 132.557 70.2115L132.407 72.8515L124.965 69.5771Z"
				fill="#0C3957"
			/>
		</svg>
	);
}

export default AmmoniteFamily;
