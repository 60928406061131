// Core React dependencies
import React, { useState, useEffect, useContext } from 'react';

// External libraries
import { makeStyles } from '@material-ui/core/styles';

// Component imports
import Page from '../../containers/Page/page';
import AmmoniteGoalCard from '../../containers/GoalCard/GoalCard';
import AmmoniteContext from '../../Utils/contexts/AmmoniteContext';
import AmmonitePropertyChip from '../../Assets/SVG/Chips/propertyChip';
import AmmoniteFamilyChip from '../../Assets/SVG/Chips/familyChip';
import AmmoniteEducationChip from '../../Assets/SVG/Chips/educationChip';
import AmmoniteCareFeesChip from '../../Assets/SVG/Chips/careFeesChip';
import AmmoniteLTIChip from '../../Assets/SVG/Chips/LTIChip';
import AmmoniteOtherChip from '../../Assets/SVG/Chips/otherChip';

// Actions
import { refreshContextFromCache, setCustomerGoals } from '../../Utils/reducers/actions';

// Styling


const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	goal: {
		padding: 10,
		width: 400,
		textAlign: 'start',
		color: theme.palette.text.secondary
	},
	goalContainer: {
		margin: 10,
		width: 700
	},
	deleteGoal: {
		position: 'relative',
		top: -50,
		left: 750
	},
	tableHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'start',
		marginTop: 55,
		marginLeft: 40,
		width: 1201,
		height: 60
	},
	tableRow: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'start',
		marginTop: 42,
		marginLeft: 40,
		width: 1201,
		maxHeight: 200
	},
	goalCardMainContainer: {
		marginRight: 20
	},
	iconContainer: {
		width: 100,
		height: 100,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'start'
	},
	chipContainer: {
		marginRight: 30,
		height: 60
	},
	iconTextContainer: {
		display: 'flex',
		justifyContent: 'center'
	},
	iconText: {
		fontSize: '0.8em'
	},
	headerIcons: {
		margin: 10
	},
	addInstructionsIcon: {
		position: 'absolute',
		top: 272,
		left: 52
	},
	addInstructionsText: {
		position: 'absolute',
		width: 437,
		height: 52,
		left: 86,
		top: 100,
		fontFamily: 'Brother 1816',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 16,
		lineHeight: 26,
		color: '#7A7A7A',
		wordBreak: 'break-all'
	},
	addInstructionsText2: {
		position: 'absolute',
		width: 437,
		height: 52,
		left: 86,
		top: 128,
		fontFamily: 'Brother 1816',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: 16,
		lineHeight: 26,
		color: '#7A7A7A',
		wordBreak: 'break-all'
	}
}));

const Goals = () => {
	const context = useContext(AmmoniteContext);
	const dispatch = context.dispatch;
	const [ goals, setGoals ] = useState(context.state.customerGoals);
	const [ goalsValid, setGoalsValid ] = useState(true);
	let name = '';
	if (context.state.customerInfo.couple === true) {
		name =
			context.state.customerInfo.customers.customer1.customerFirstName +
			' and ' +
			context.state.customerInfo.customers.customer2.customerFirstName;
	} else {
		name = context.state.customerInfo.customers.customer1.customerFirstName;
	}
	const classes = useStyles();

	const handleNameChange = (index, value) => {
		const _tempGoals = [ ...goals ];
		_tempGoals[index + 1]['goalName'] = value;

		setGoals(_tempGoals);
	};

	const handleDelete = (index) => {
		const _tempGoals = [ ...goals ];
		_tempGoals.splice(index + 1, 1);

		setGoals(_tempGoals);
	};

	const addNewGoal = (goalType) => {
		setGoalsValid(false);
		if (goals.length <= 5) {
			setGoals((prevGoals) => [
				...prevGoals,
				{
					goalType: goalType,
					goalName: '',
					goalAmount: 0,
					amountRequiredToday: 0,
					goalTimeHorizon: 5,
					goalImportance: 0,
					inflationLinked: true,
				}
			]);
		}
	};

	const resetCustomerGoals = () => {
		// Reset to initial data for one customer
		setGoalsValid(true);
		const initialGoals = [
			{
				goalType: 'Retirement pot',
				goalName: 'Retirement',
				amountRequiredToday: 3000,
				goalAmount: null,
				goalTimeHorizon: 65
			}
		];
		setGoals(initialGoals);
	};

	const checkGoalsValid = () => {
		for (let goal of goals) {
			if (goal.goalName == '') {
				setGoalsValid(false);
				break;
			} else {
				if (goals.length > 1) {
					setGoalsValid(true);
				}
			}
		}
	};

	useEffect(
		() => {
			if (context.state.clean === true && localStorage.ammoniteContext) {
				dispatch({ type: refreshContextFromCache, payload: JSON.parse(localStorage.getItem('ammoniteContext')) });
				setGoals(JSON.parse(localStorage.getItem('ammoniteContext')).state.customerGoals)
			}
			if (goals) {
				dispatch({ type: setCustomerGoals, payload: { goals: goals } });
				checkGoalsValid();
			}
		},
		[ goals ]
	);

	return (
		<Page
			headerText={name + ', please choose your goals'}
			progress={0.375}
			introText={
				'Let us know what your most important medium to long term goals are.  Each goal should be a good few years away from now.  Everybody wants to retire one day, so we automatically allocate a "retirement spending" goal - more on that later. '
			}
			startOver
			reset={resetCustomerGoals}
			backLink="/yourfinancialhealthcheck"
			forwardLink="/tellusaboutyourgoals"
			isValid={goalsValid}
		>
			<div className="table">
				<div className="table-content">
					<div className={classes.tableHeader}>
						<div className={classes.chipContainer} onClick={() => addNewGoal('Property')}>
							<AmmonitePropertyChip />
						</div>
						<div className={classes.chipContainer} onClick={() => addNewGoal('Family')}>
							<AmmoniteFamilyChip />
						</div>
						<div className={classes.chipContainer} onClick={() => addNewGoal('Education')}>
							<AmmoniteEducationChip />
						</div>
						<div className={classes.chipContainer} onClick={() => addNewGoal('Long Term Investment')}>
							<AmmoniteLTIChip />
						</div>
						<div className={classes.chipContainer} onClick={() => addNewGoal('Care Fees')}>
							<AmmoniteCareFeesChip />
						</div>
						<div className={classes.chipContainer} onClick={() => addNewGoal('Other')}>
							<AmmoniteOtherChip />
						</div>
					</div>
					<div className={classes.tableRow}>
						{goals.length < 2 && <div>
							<div className={classes.addInstructionsIcon}>
								<svg width="19" height="38" viewBox="0 0 19 38" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M16.5229 34.4853C16.8088 33.4915 16.2355 32.4504 15.238 32.1649C10.0708 30.6768 6.35523 26.1508 5.77519 20.6314C5.26239 15.7518 7.39851 11.0445 11.232 8.29375L11.0469 11.4136C11.039 11.5181 11.0423 11.6215 11.0529 11.7222C11.1472 12.6195 11.8776 13.3423 12.8056 13.3975C13.8398 13.4586 14.7301 12.671 14.7893 11.637L15.2731 3.57112C15.2875 3.34896 15.2452 3.14407 15.1901 2.94242C15.1838 2.91856 15.1885 2.89167 15.1804 2.86801C15.1112 2.6584 14.9994 2.47401 14.8629 2.30543C14.8477 2.28628 14.841 2.2587 14.8239 2.23975C14.7205 2.11672 14.5814 2.03139 14.4471 1.938C14.3798 1.89038 14.3261 1.82813 14.2524 1.79062C14.1407 1.73258 14.0072 1.71832 13.8848 1.68404C13.7723 1.65437 13.6703 1.59908 13.5523 1.58885L5.94434 0.975856C4.91149 0.892039 4.00593 1.66047 3.92398 2.69312C3.91375 2.81112 3.91471 2.92794 3.92667 3.04172C4.01939 3.924 4.72659 4.64175 5.64124 4.71348L9.39211 5.01707C4.25599 8.50825 1.37578 14.6591 2.04462 21.0234C2.78501 28.0686 7.5573 33.8565 14.2023 35.7682C15.1981 36.0559 16.2371 35.4809 16.5229 34.4853Z" fill="#BDBDBD"/>
								</svg>
							</div>
							<div className={classes.addInstructionsText}>
								Add goals by choosing a category above. You can add 
							</div>
							<div className={classes.addInstructionsText2}>
								more than one per category if you want.
							</div>
						</div>}
						{goals &&
							goals.slice(1, 4).map((item, index) => (
								<div className={classes.cardContainer}>
									<AmmoniteGoalCard
										key={index}
										goalType={item.goalType}
										goalName={item.goalName}
										showDelete={true}
										handleDelete={() => handleDelete(index)}
										handleNameChange={(value) => handleNameChange(index, value)}
									/>
								</div>
							))}
					</div>
					<div className={classes.tableRow}>
						{goals &&
							goals.slice(4, 6).map((item, index) => (
								<div className={classes.cardContainer}>
									<AmmoniteGoalCard
										key={index}
										goalType={item.goalType}
										goalName={item.goalName}
										showDelete
										handleDelete={() => handleDelete(index + 3)}
										handleNameChange={(value) => handleNameChange(index + 3, value)}
									/>
								</div>
							))}
					</div>
				</div>
			</div>
		</Page>
	);
};

export default Goals;
