//React
import React from "react";

import moment from "moment";
import AmmoniteButton from "../../../components/Button/button";
import AmmoniteTrashcan from "../../../Assets/SVG/trashcan";

import AmmoniteSessionTab from "../../../Assets/SVG/Dashboard/SessionTab/SessionTab";
import GoalsReportChip from "../../../Assets/SVG/Chips/SuitabilityChips/goalsReportChip";
import SRBuilderChip from "../../../Assets/SVG/Chips/SuitabilityChips/srBuilderChip";
import DashBoardLine from "../../../Assets/SVG/Dashboard/DashBoardLine/DashBoardLine";

//external libraries

//components
import "./session.css";

function AmmoniteSession({
  index,
  session,
  userRole,
  deleteSession,
  selectSession,
  createSuitabilityReport,
}) {
  const viewReportHandler = () => {
    selectSession(index);
  };

  const CreateSuitabilityReport = () => {
    createSuitabilityReport(index);
  };

  return (
    <div className="sessionContainer">
      <div className="tabContainer">
        <AmmoniteSessionTab
          sent={session.reportSent}
          visits={session.reportVisits ? session.reportVisits : 0}
        />
      </div>
      <div className="sessionCustomers">
        <div className="firstCustomer">
          {session.customerInfo.customers.customer1.customerFirstName + " "}
          {session.customerInfo.customers.customer1.customerLastName}
        </div>
        <div className="secondCustomer">
          {session.customerInfo.couple && " and "}
          {session.customerInfo.couple &&
            session.customerInfo.customers.customer2.customerFirstName + " "}
          {session.customerInfo.couple &&
            session.customerInfo.customers.customer2.customerLastName}
        </div>
      </div>
      <div className="sessionCustomer">
        <div className="firstCustomer">
          {moment.unix(session.sessionDate.seconds).format("DD MMM YYYY")}
        </div>
        <div className="secondCustomer">
          Coach: {session.coachName ? session.coachName : "(no coach data)"}
        </div>
      </div>
      <div className="buttonChips">
        <div className="goalReportChip" onClick={() => viewReportHandler()}>
          <GoalsReportChip />
        </div>
        {userRole !== "coach" && (
          <div
            className="goalReportChip"
            onClick={() => CreateSuitabilityReport()}
          >
            <SRBuilderChip />
          </div>
        )}
        {userRole !== "coach" && (
          <div
            className="iconContainer sessionIconContainer"
            onClick={deleteSession}
          >
            <AmmoniteTrashcan colour="#7A7A7A" scale={1} />
          </div>
        )}
      </div>
      <div className="Line">
        <DashBoardLine sent={session.reportSent} />
      </div>
    </div>
  );
}

export default AmmoniteSession;
