export const getStatePensionAge = (customerDOB) => {
  let _cDOB = new Date(customerDOB.seconds * 1000);
  if (_cDOB < new Date("1961-3-6")) {
    return 66;
  } else if (_cDOB < new Date("1978-4-6")) {
    return 67;
  }
  return 68;
};

export const yearsToRetirement = (customerDoB) => {
  let _yearsToRetirement =
    parseInt(getStatePensionAge(customerDoB)) -
    (Math.abs(new Date() - customerDoB.toDate()) / 31536000000).toFixed();
  if (_yearsToRetirement < 0) {
    _yearsToRetirement = 0;
  }
  return _yearsToRetirement;
};
