//React
import React, { useState, useContext } from 'react';

import './riskTable.css';


function AmmoniteRiskTable({ 
    riskLevel
}) {

    const riskCategories = {
        lowRisk: ["Cash or 1/10", "1/10", "1/10", "2/10"],
        lowMediumRisk: ["Cash or 2/10", "2/10", "3/10", "4/10"],
        mediumRisk: ["Cash or 5/10", "5/10", "6/10", "7/10"],
        mediumHighRisk: ["Cash or 7/10", "7/10", "8/10", "9/10"],
        highRisk: ["Cash or 8/10", "8/10", "9/10", "10/10"],
    }

	return (
		<div className="riskTableContainer">
            <div className="riskTableTopRow">
                <div className="riskTableItem">
                    Timeframe:
                </div>
                <div className="riskTableItem riskTableFirstHeading">
                    3-4 years
                </div>
                <div className="riskTableItem riskTableSecondHeading">
                    5-9 years
                </div>
                <div className="riskTableItem riskTableThirdHeading">
                    10-14 years
                </div>
                <div className="riskTableItem riskTableFourthHeading">
                    15+ years
                </div>
            </div>
            <div className="riskTableTopRow">
            <div className="riskTableItem">
                    Risk category:
                </div>
                <div className="riskTableItem riskTableFirstHeading">
                    {riskCategories[riskLevel][0]}
                </div>
                <div className="riskTableItem riskTableSecondHeading">
                    {riskCategories[riskLevel][1]}
                </div>
                <div className="riskTableItem riskTableThirdHeading">
                    {riskCategories[riskLevel][2]}
                </div>
                <div className="riskTableItem riskTableFourthHeading">
                    {riskCategories[riskLevel][3]}
                </div>
            </div>
		</div>
	);
}

export default AmmoniteRiskTable;
