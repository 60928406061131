import React, { useEffect, useContext } from "react";

import AmmoniteMenuCircleEmpty from "../../Assets/SVG/menuCircleEmpty";
import AmmoniteMenuCircleFilledTicked from "../../Assets/SVG/menuCircleFilledTicked";
import AmmoniteCheckbox from "../../components/Checkbox/Checkbox";
import AmmoniteTrashcan from "../../Assets/SVG/trashcan";

import GoalAllocateBar from "../../containers/GoalAllocate/goalAllocate";

import SuitabilityReportContext from "../../Utils/contexts/suitabilityReportContext";
import {
  allocatePensionAdviceGoalPercentage,
  allocateISAAdviceGoalPercentage,
  allocateGIAAdviceGoalPercentage,
  allocateCashAdviceGoalPercentage,
  changeNewPensionsAdvice,
  changenewISAsAdvice,
  changenewGIAsAdvice,
  changenewCashAdvice,
} from "../../Utils/reducers/suitabilityReportActions";

import styles from "../../Styles/srbstyles.module.css";
import "./newAdviceCard.css";

const NewAdviceCard = ({
  cardType,
  newAdviceIndex,
  handleDeleteNewCard,
  newCard,
  goals,
  couple,
  customer1,
  customer2,
}) => {
  const { state, status, error, dispatch } = useContext(
    SuitabilityReportContext
  );
  const isNewPension = cardType === "New Pension";
  const isNewCash = cardType === "New Cash";
  let typeToDispatch;

  const filled = (
    <div className="circleSVG">
      <AmmoniteMenuCircleFilledTicked />
    </div>
  );

  const empty = (
    <div className="circleSVG">
      <AmmoniteMenuCircleEmpty
        colour="#3BB9C4"
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const handleAmendNewCard = (event, field, value) => {
    if (event !== undefined) {
      event.preventDefault();
    }
    switch (cardType) {
      case "New Pension":
        typeToDispatch = changeNewPensionsAdvice;
        break;
      case "New ISA":
        typeToDispatch = changenewISAsAdvice;
        break;
      case "New GIA":
        typeToDispatch = changenewGIAsAdvice;
        break;
      case "New Cash":
        typeToDispatch = changenewCashAdvice;
        break;
    }
    dispatch({
      type: typeToDispatch,
      payload: {
        index: newAdviceIndex,
        field: field,
        value: value,
      },
    });
  };

  const handleAllocateGoalPercentage = (index, value) => {
    switch (cardType) {
      case "New Pension":
        typeToDispatch = allocatePensionAdviceGoalPercentage;
        break;
      case "New ISA":
        typeToDispatch = allocateISAAdviceGoalPercentage;
        break;
      case "New GIA":
        typeToDispatch = allocateGIAAdviceGoalPercentage;
        break;
      case "New Cash":
        typeToDispatch = allocateCashAdviceGoalPercentage;
        break;
    }
    dispatch({
      type: typeToDispatch,
      payload: {
        cardAdviceIndex: newAdviceIndex,
        goalIndex: index,
        value: parseInt(value),
      },
    });
  };

  const getGoalsTotal = () => {
    let _goalsTotal = 0;
    for (let goal of goals) {
      _goalsTotal += parseInt(goal.goalAllocationPercent) || 0;
    }

    return _goalsTotal;
  };

  useEffect(() => {}, []);

  return (
    <div className={styles.pensionCard}>
      <div className={styles.pensionCol}>
        <div
          className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
        >
          <label className={`${styles.pageMainText} ${styles.labelOwner}`}>
            Owner:
          </label>
          <div className={styles.ownerPension}>
            {
              <div
                className={styles.ownerPensionButton}
                onClick={(event) =>
                  handleAmendNewCard(event, "owner", "customer1")
                }
              >
                {newCard.owner === "customer1" ? filled : empty}
              </div>
            }
            {<label className={styles.customerLabel}>{customer1}</label>}
          </div>
          {couple && (
            <div className={styles.ownerPension}>
              <div
                className={styles.ownerPensionButton}
                onClick={(event) =>
                  handleAmendNewCard(event, "owner", "customer2")
                }
              >
                {newCard.owner === "customer2" ? filled : empty}
              </div>
              <label className={styles.customerLabel}>{customer2}</label>
            </div>
          )}
        </div>
        {!isNewCash && (
          <div
            className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
          >
            <label className={`${styles.pageMainText} ${styles.advice}`}>
              Total Transfers in:
            </label>
            <input
              type="number"
              placeholder={0}
              value={newCard.totalTransfersIn}
              className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
              onChange={(event) =>
                handleAmendNewCard(
                  event,
                  "totalTransfersIn",
                  event.target.value
                )
              }
            />
          </div>
        )}
        <div
          className={
            isNewCash
              ? [
                  styles.newPensionFieldContainer,
                  styles.newPensionFieldContainerMargin,
                  "allocationOfGoalsNewCash",
                ].join(" ")
              : [
                  styles.newPensionFieldContainer,
                  styles.newPensionFieldContainerMargin,
                ].join(" ")
          }
        >
          <label className={styles.pageMainText}>
            What is the allocation for each goal?{" "}
          </label>
          {!status !== "success" && (
            <div className={styles.goalsAllocationContainer}>
              {goals.map((goal, index) => (
                <GoalAllocateBar
                  goalAllocatePercentageValue={goal.goalAllocationPercent}
                  goalType={goal.goalType}
                  goalIndex={index}
                  handleAllocateGoalPercentage={(event, index, value) =>
                    handleAllocateGoalPercentage(event, index, value)
                  }
                />
              ))}
            </div>
          )}
        </div>
        <div className={styles.goalAllocationTotal}>
          <label className={`${styles.pageMainText} ${styles.advice}`}>
            Total:
          </label>
          <div className={styles.goalsTotalPercent}>{getGoalsTotal()}%</div>
        </div>
        {isNewPension && (
          <div className="newPensionsInvestmentStrategy">
            <label
              className={[
                styles.pageMainText,
                "newPensionInvestmentStrategyFieldLabel",
              ].join(" ")}
            >
              Investment Strategy:
            </label>
            <div className="newPensionInvestmentStrategyButtonsContainer">
              <div
                className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
              >
                <div
                  className={[
                    "newPensionInvestmentButtonWidth",
                    styles.ownerPension,
                    styles.investmentStrategyButton,
                  ].join(" ")}
                >
                  <div
                    className={styles.ownerPensionButton}
                    onClick={(event) =>
                      handleAmendNewCard(
                        event,
                        "investmentStrategyActive",
                        "passive"
                      )
                    }
                  >
                    {newCard.investmentStrategyActive === "passive"
                      ? filled
                      : empty}
                  </div>
                  <label
                    className={[
                      styles.customerLabel,
                      "investmentStrategyFieldLabel",
                    ].join(" ")}
                  >
                    Passive
                  </label>
                </div>
                <div
                  className={`${styles.ownerPension} ${styles.investmentStrategyButton}`}
                >
                  <div
                    className={styles.ownerPensionButton}
                    onClick={(event) =>
                      handleAmendNewCard(
                        event,
                        "investmentStrategyActive",
                        "income"
                      )
                    }
                  >
                    {newCard.investmentStrategyActive === "income"
                      ? filled
                      : empty}
                  </div>
                  <label
                    className={[
                      styles.customerLabel,
                      "investmentStrategyFieldLabel",
                    ].join(" ")}
                  >
                    Active
                  </label>
                </div>
              </div>
              <div
                className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}
              >
                <div
                  className={[
                    "newPensionInvestmentButtonWidth",
                    styles.ownerPension,
                    styles.investmentStrategyButton,
                  ].join(" ")}
                >
                  <div
                    className={styles.ownerPensionButton}
                    onClick={(event) =>
                      handleAmendNewCard(
                        event,
                        "investmentStrategyActive",
                        "ethical"
                      )
                    }
                  >
                    {newCard.investmentStrategyActive === "ethical"
                      ? filled
                      : empty}
                  </div>
                  <label
                    className={[
                      styles.customerLabel,
                      "investmentStrategyFieldLabel",
                    ].join(" ")}
                  >
                    Ethical
                  </label>
                </div>
                <div
                  className={`${styles.ownerPension} ${styles.investmentStrategyButton}`}
                >
                  <div
                    className={styles.ownerPensionButton}
                    onClick={(event) =>
                      handleAmendNewCard(
                        event,
                        "investmentStrategyActive",
                        "dfm"
                      )
                    }
                  >
                    {newCard.investmentStrategyActive === "dfm"
                      ? filled
                      : empty}
                  </div>
                  <label
                    className={[
                      styles.customerLabel,
                      "investmentStrategyFieldLabel",
                    ].join(" ")}
                  >
                    DFM
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.pensionCol}>
        <div
          className={styles.pensionTrashcan}
          onClick={(event) => handleDeleteNewCard(event)}
        >
          <AmmoniteTrashcan colour="#7A7A7A" />
        </div>
        {isNewPension && (
          <div>
            <div
              className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
            >
              <label className={`${styles.pageMainText} ${styles.advice}`}>
                Gross Monthly Personal Cont:
              </label>
              <input
                type="number"
                placeholder={0}
                value={newCard.grossMonthlyPersonalContribution}
                className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
                onChange={(event) =>
                  handleAmendNewCard(
                    event,
                    "grossMonthlyPersonalContribution",
                    event.target.value
                  )
                }
              />
            </div>
            <div
              className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
            >
              <label className={`${styles.pageMainText} ${styles.advice}`}>
                Gross Monthly Employer Cont:
              </label>
              <input
                type="number"
                placeholder={0}
                value={newCard.grossMonthlyEmployerContribution}
                className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
                onChange={(event) =>
                  handleAmendNewCard(
                    event,
                    "grossMonthlyEmployerContribution",
                    event.target.value
                  )
                }
              />
            </div>
            <div
              className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
            >
              <label className={`${styles.pageMainText} ${styles.advice}`}>
                Gross Lump Sum Personal Cont:
              </label>
              <input
                type="number"
                placeholder={0}
                value={newCard.grossLumpSumPersonalContribution}
                className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
                onChange={(event) =>
                  handleAmendNewCard(
                    event,
                    "grossLumpSumPersonalContribution",
                    event.target.value
                  )
                }
              />
            </div>
            <div
              className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
            >
              <label className={`${styles.pageMainText} ${styles.advice}`}>
                Gross Lump Sum Employer Cont:
              </label>
              <input
                type="number"
                placeholder={0}
                value={newCard.grossLumpSumEmployerContribution}
                className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
                onChange={(event) =>
                  handleAmendNewCard(
                    event,
                    "grossLumpSumEmployerContribution",
                    event.target.value
                  )
                }
              />
            </div>
            <div
              className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
            >
              <label className={`${styles.pageMainText} ${styles.advice}`}>
                Crystallise Amount (% / £):
              </label>
              <input
                type="number"
                placeholder={0}
                value={newCard.crystaliseAmount}
                className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
                onChange={(event) =>
                  handleAmendNewCard(
                    event,
                    "crystaliseAmount",
                    event.target.value
                  )
                }
              />
            </div>
            <div
              className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
            >
              <label className={`${styles.pageMainText} ${styles.advice}`}>
                Tax-Free cash to take (% / £):
              </label>
              <input
                type="number"
                placeholder={0}
                value={newCard.taxFreeCashToTake}
                className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
                onChange={(event) =>
                  handleAmendNewCard(
                    event,
                    "taxFreeCashToTake",
                    event.target.value
                  )
                }
              />
            </div>
            <div
              className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
            >
              <label className={`${styles.pageMainText} ${styles.advice}`}>
                Income to take (% / £):
              </label>
              <input
                type="number"
                placeholder={0}
                value={newCard.incomeToTake}
                className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
                onChange={(event) =>
                  handleAmendNewCard(event, "incomeToTake", event.target.value)
                }
              />
            </div>
            <div className="includeAnnualAllowanceDetailsCheckboxContainer">
              <AmmoniteCheckbox
                isBold
                checked={newCard.includeAllowanceDetails}
                checkedHandler={(event) =>
                  handleAmendNewCard(
                    event,
                    "includeAllowanceDetails",
                    !state.newPensionsAdvice[newAdviceIndex]
                      .includeAllowanceDetails
                  )
                }
              />
              <div
                className={[
                  styles.pageMainText,
                  "includeAllowanceDetailsLabel",
                ].join(" ")}
              >
                Include Allowance Details?
              </div>
            </div>
            <div
              className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
            >
              <label
                className={`${styles.pageMainText} ${styles.advice} ${styles.subFieldContainerMargin}`}
              >
                2020/21 allowance:
              </label>
              <input
                type="number"
                placeholder={0}
                value={newCard.allowance202021}
                className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
                onChange={(event) =>
                  handleAmendNewCard(
                    event,
                    "allowance202021",
                    event.target.value
                  )
                }
              />
            </div>
            <div
              className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
            >
              <label
                className={`${styles.pageMainText} ${styles.advice} ${styles.subFieldContainerMargin}`}
              >
                2020/21 existing conts:
              </label>
              <input
                type="number"
                placeholder={0}
                value={newCard.existingContributions202021}
                className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
                onChange={(event) =>
                  handleAmendNewCard(
                    event,
                    "existingContributions202021",
                    event.target.value
                  )
                }
              />
            </div>
            <div
              className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
            >
              <label
                className={`${styles.pageMainText} ${styles.advice} ${styles.subFieldContainerMargin}`}
              >
                Carry forward allowance:
              </label>
              <input
                type="number"
                placeholder={0}
                value={newCard.carryForwardAllowance}
                className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
                onChange={(event) =>
                  handleAmendNewCard(
                    event,
                    "carryForwardAllowance",
                    event.target.value
                  )
                }
              />
            </div>
          </div>
        )}

        {!isNewPension && (
          <div
            className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
          >
            <label className={`${styles.pageMainText} ${styles.advice}`}>
              Monthly Contributions:
            </label>
            <input
              type="number"
              placeholder=""
              value={newCard.monthlyContribution}
              className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
              onChange={(event) =>
                handleAmendNewCard(
                  event,
                  "monthlyContribution",
                  event.target.value
                )
              }
            />
          </div>
        )}
        {!isNewPension && (
          <div
            className={`${styles.newPensionFieldContainer} ${styles.pensionAdviceFieldContainer}`}
          >
            <label className={`${styles.pageMainText} ${styles.advice}`}>
              Lump Sum Contributions:
            </label>
            <input
              type="number"
              placeholder=""
              value={newCard.lumpSumContribution}
              className={`${styles.whyInput} ${styles.pensionFieldInput} ${styles.pensionAdviceFieldInput}`}
              onChange={(event) =>
                handleAmendNewCard(
                  event,
                  "lumpSumContribution",
                  event.target.value
                )
              }
            />
          </div>
        )}

        {!isNewPension && !isNewCash && (
          <div className={styles.pensionInvestmentStrategy}>
            <div
              className={`${styles.pensionFieldContainer} ${styles.investmentStrategyFieldContainer}`}
            >
              <label
                className={`${styles.pageMainText} ${styles.investmentStrategyLabel}`}
              >
                Investment Strategy:
              </label>
              <div
                className={`${styles.ownerPension} ${styles.investmentStrategyButton}`}
              >
                <div
                  className={styles.ownerPensionButton}
                  onClick={(event) =>
                    handleAmendNewCard(
                      event,
                      "investmentStrategyActive",
                      "passive"
                    )
                  }
                >
                  {newCard.investmentStrategyActive === "passive"
                    ? filled
                    : empty}
                </div>
                <label
                  className={`${styles.customerLabel} ${styles.investmentStrategyButtonWidth}`}
                >
                  Passive
                </label>
              </div>
              <div
                className={`${styles.ownerPension} ${styles.investmentStrategyButton}`}
              >
                <div
                  className={styles.ownerPensionButton}
                  onClick={(event) =>
                    handleAmendNewCard(
                      event,
                      "investmentStrategyActive",
                      "income"
                    )
                  }
                >
                  {newCard.investmentStrategyActive === "income"
                    ? filled
                    : empty}
                </div>
                <label
                  className={`${styles.customerLabel} ${styles.investmentStrategyButtonWidth}`}
                >
                  Income
                </label>
              </div>
              <div
                className={`${styles.ownerPension} ${styles.investmentStrategyButton}`}
              >
                <div
                  className={styles.ownerPensionButton}
                  onClick={(event) =>
                    handleAmendNewCard(
                      event,
                      "investmentStrategyActive",
                      "ethical"
                    )
                  }
                >
                  {newCard.investmentStrategyActive === "ethical"
                    ? filled
                    : empty}
                </div>
                <label
                  className={`${styles.customerLabel} ${styles.investmentStrategyButtonWidth}`}
                >
                  Ethical
                </label>
              </div>
              <div
                className={`${styles.ownerPension} ${styles.investmentStrategyButton}`}
              >
                <div
                  className={styles.ownerPensionButton}
                  onClick={(event) =>
                    handleAmendNewCard(event, "investmentStrategyActive", "dfm")
                  }
                >
                  {newCard.investmentStrategyActive === "dfm" ? filled : empty}
                </div>
                <label
                  className={`${styles.customerLabel} ${styles.investmentStrategyButtonWidth}`}
                >
                  DFM
                </label>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewAdviceCard;
