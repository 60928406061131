import React from 'react';

function AmmoniteOtherSummaryIcon({colour}) {
	let color = "#3F762C"
	if (colour) {
		color = colour
	}
	return (
		<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M28.5 13.5C27.5716 13.5026 26.6667 13.7923 25.9094 14.3294C25.1521 14.8665 24.5795 15.6247 24.27 16.5H4.5C4.10218 16.5 3.72064 16.6581 3.43934 16.9394C3.15804 17.2207 3 17.6022 3 18C3 18.3978 3.15804 18.7794 3.43934 19.0607C3.72064 19.342 4.10218 19.5 4.5 19.5H24.27C24.5452 20.2783 25.0292 20.966 25.669 21.4876C26.3088 22.0093 27.0798 22.3449 27.8975 22.4578C28.7153 22.5706 29.5484 22.4563 30.3056 22.1274C31.0627 21.7985 31.7148 21.2676 32.1905 20.5929C32.6662 19.9182 32.9471 19.1256 33.0025 18.302C33.0579 17.4783 32.8856 16.6553 32.5046 15.9229C32.1236 15.1906 31.5484 14.5772 30.8421 14.1499C30.1358 13.7225 29.3255 13.4977 28.5 13.5ZM28.5 19.5C28.2033 19.5 27.9133 19.412 27.6666 19.2472C27.42 19.0824 27.2277 18.8481 27.1142 18.574C27.0006 18.3 26.9709 17.9984 27.0288 17.7074C27.0867 17.4164 27.2296 17.1491 27.4393 16.9394C27.6491 16.7296 27.9164 16.5867 28.2074 16.5288C28.4983 16.471 28.7999 16.5007 29.074 16.6142C29.3481 16.7277 29.5824 16.92 29.7472 17.1667C29.912 17.4133 30 17.7033 30 18C30 18.3978 29.842 18.7794 29.5607 19.0607C29.2794 19.342 28.8978 19.5 28.5 19.5Z"
				fill={color}
			/>
			<path
				d="M4.5 10.5H6.27C6.58531 11.3687 7.16044 12.1193 7.91725 12.6497C8.67405 13.1802 9.57582 13.4647 10.5 13.4647C11.4242 13.4647 12.3259 13.1802 13.0828 12.6497C13.8396 12.1193 14.4147 11.3687 14.73 10.5H31.5C31.8978 10.5 32.2794 10.342 32.5607 10.0607C32.842 9.77935 33 9.39782 33 8.99999C33 8.60217 32.842 8.22064 32.5607 7.93933C32.2794 7.65803 31.8978 7.49999 31.5 7.49999H14.73C14.4147 6.63126 13.8396 5.88068 13.0828 5.35026C12.3259 4.81983 11.4242 4.53528 10.5 4.53528C9.57582 4.53528 8.67405 4.81983 7.91725 5.35026C7.16044 5.88068 6.58531 6.63126 6.27 7.49999H4.5C4.10218 7.49999 3.72064 7.65803 3.43934 7.93933C3.15804 8.22064 3 8.60217 3 8.99999C3 9.39782 3.15804 9.77935 3.43934 10.0607C3.72064 10.342 4.10218 10.5 4.5 10.5ZM10.5 7.49999C10.7967 7.49999 11.0867 7.58797 11.3334 7.75279C11.58 7.91761 11.7723 8.15188 11.8858 8.42597C11.9994 8.70006 12.0291 9.00166 11.9712 9.29263C11.9133 9.5836 11.7704 9.85087 11.5607 10.0607C11.3509 10.2704 11.0836 10.4133 10.7926 10.4712C10.5017 10.529 10.2001 10.4993 9.92597 10.3858C9.65189 10.2723 9.41762 10.08 9.2528 9.83335C9.08797 9.58667 9 9.29667 9 8.99999C9 8.60217 9.15804 8.22064 9.43934 7.93933C9.72064 7.65803 10.1022 7.49999 10.5 7.49999Z"
				fill={color}
			/>
			<path
				d="M31.5 25.5H20.73C20.4147 24.6313 19.8396 23.8807 19.0828 23.3503C18.3259 22.8198 17.4242 22.5353 16.5 22.5353C15.5758 22.5353 14.6741 22.8198 13.9172 23.3503C13.1604 23.8807 12.5853 24.6313 12.27 25.5H4.5C4.10218 25.5 3.72064 25.658 3.43934 25.9393C3.15804 26.2206 3 26.6022 3 27C3 27.3978 3.15804 27.7794 3.43934 28.0607C3.72064 28.342 4.10218 28.5 4.5 28.5H12.27C12.5853 29.3687 13.1604 30.1193 13.9172 30.6497C14.6741 31.1802 15.5758 31.4647 16.5 31.4647C17.4242 31.4647 18.3259 31.1802 19.0828 30.6497C19.8396 30.1193 20.4147 29.3687 20.73 28.5H31.5C31.8978 28.5 32.2794 28.342 32.5607 28.0607C32.842 27.7794 33 27.3978 33 27C33 26.6022 32.842 26.2206 32.5607 25.9393C32.2794 25.658 31.8978 25.5 31.5 25.5ZM16.5 28.5C16.2033 28.5 15.9133 28.412 15.6666 28.2472C15.42 28.0824 15.2277 27.8481 15.1142 27.574C15.0006 27.2999 14.9709 26.9983 15.0288 26.7074C15.0867 26.4164 15.2296 26.1491 15.4393 25.9393C15.6491 25.7296 15.9164 25.5867 16.2074 25.5288C16.4983 25.4709 16.7999 25.5006 17.074 25.6142C17.3481 25.7277 17.5824 25.92 17.7472 26.1666C17.912 26.4133 18 26.7033 18 27C18 27.3978 17.842 27.7794 17.5607 28.0607C17.2794 28.342 16.8978 28.5 16.5 28.5Z"
				fill={color}
			/>
		</svg>
	);
}

export default AmmoniteOtherSummaryIcon;
