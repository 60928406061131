import React from 'react';

function AmmoniteTrashcan({ colour, width, height, cx, cy, r }) {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 15C10 15.55 9.55 16 9 16C8.45 16 8 15.55 8 15V11C8 10.45 8.45 10 9 10C9.55 10 10 10.45 10 11V15ZM16 15C16 15.55 15.55 16 15 16C14.45 16 14 15.55 14 15V11C14 10.45 14.45 10 15 10C15.55 10 16 10.45 16 11V15ZM18 18C18 18.551 17.552 19 17 19H7C6.448 19 6 18.551 6 18V7H18V18ZM10 3.328C10 3.173 10.214 3 10.5 3H13.5C13.786 3 14 3.173 14 3.328V5H10V3.328ZM21 5H20H16V3.328C16 2.044 14.879 1 13.5 1H10.5C9.121 1 8 2.044 8 3.328V5H4H3C2.45 5 2 5.45 2 6C2 6.55 2.45 7 3 7H4V18C4 19.654 5.346 21 7 21H17C18.654 21 20 19.654 20 18V7H21C21.55 7 22 6.55 22 6C22 5.45 21.55 5 21 5Z"
				fill={colour}
			/>
		</svg>
	);
}

export default AmmoniteTrashcan;
