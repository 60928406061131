import Shortid from "shortid";
import uuid from "react-uuid";
import * as firebase from "firebase";

import {
  getMortgageTerm,
  getLifeCover,
  getRecommendedPolicy,
  getRetirementDate,
  getFundProviders,
  checkInFundProviders,
  getWhosePensions,
  areTherePensionContributions,
  areThereOtherInvestmentContributions,
  getIsISARetained,
  getIsPensionRetained,
  getCapitalisedType,
  getInvestmentSolutionsGroupedByGoals,
  getInvestmentSolutionsGroupedByGoalsRisk,
  getAllInvestmentSolutionsDeduped,
  getWrapperContributionAmount,
  getContributionTypeExists,
  getGoalImportance,
  getGoalRankedByImportance,
  getIsNotEveryGoalFocused,
  getDoesGoalFocusExist,
  getCapacityForLoss,
  getHasGoalBeenAllocated,
  getInvestmentSolutionsByGoal,
  getGoalRiskProfile,
  getInvestmentHeldIn,
  getCustomerAge,
  getEmploymentSituation,
  getNoChildren,
  budgetAndAffordabilityValues,
  getDeficitSurplus,
  getCustomerAttitudeToRisk,
  getRiskMatrix,
  getCapacityForLossStatement,
} from "../data/suitabilityReportDerivedData";

import {
  getTotalImplementationChargeOnPot,
  getTotalPortfolioValue,
  getTotalImplementationCharge,
  getTotalWeightedCharge,
  getTotalPoundCharge,
  getISAGIAPensionCharges,
  getPensionCharges,
  getISAGIACharges,
  getAmount,
} from "../data/suitabilityReportCharges";

import {
  initialiseSBReport,
  setNames,
  changeCheckListItem,
  changeAdditionalInfo,
  changeMTR,
  changeAdditionalPersonField,
  addChild,
  changeChild,
  setAttitudeToRisk,
  setCapacityForLoss,
  setRiskComments,
  changeAdditionalGoalDetail,
  addPension,
  deletePension,
  changePension,
  addInvestment,
  deleteInvestment,
  changeInvestment,
  changeFFNotes,
  changeLOA,
  changeGoalFocus,
  changeSRBuilder,
  addNewPensionAdvice,
  deleteNewPensionAdvice,
  changeNewPensionsAdvice,
  allocatePensionAdviceGoalPercentage,
  addNewISAAdvice,
  changenewISAsAdvice,
  allocateISAAdviceGoalPercentage,
  addNewGIAAdvice,
  allocateGIAAdviceGoalPercentage,
  changenewGIAsAdvice,
  addNewCashAdvice,
  allocateCashAdviceGoalPercentage,
  changenewCashAdvice,
  deleteNewISAAdvice,
  deleteNewGIAAdvice,
  deleteNewCashAdvice,
  setAdviser,
  setInvestmentSolutions,
  createPdfDocxData,
  setCurrentSession,
  setCurrentIndex,
  setPreviewed,
} from "./suitabilityReportActions";

export const REQUEST_STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

export const suitabilityReportReducer = (context, action) => {
  switch (action.type) {
    case initialiseSBReport:
      let _initialState = action.payload.report;
      if (
        !_initialState.environmentPriority ||
        _initialState.environmentPriority === undefined
      ) {
        _initialState.environmentPriority = false;
      }

      if (
        !_initialState.customerInfo.children ||
        _initialState.customerInfo.children === undefined
      ) {
        _initialState.customerInfo.children = [];
      }

      return {
        state: { ..._initialState },
        status: REQUEST_STATUS.SUCCESS,
      };
    case setCurrentSession:
      let _currentSessionState = context.state;
      _currentSessionState._currentSession = action.payload;
      return {
        state: _currentSessionState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setCurrentIndex:
      let _currentIndexState = context.state;
      _currentIndexState._currentIndex = action.payload;
      return {
        state: _currentIndexState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setNames:
      let _nameState = context.state;
      _nameState.names = action.payload;
      return {
        state: _nameState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setAdviser:
      let _adviserState = context.state;
      _adviserState.adviser = action.payload;
      return {
        state: _adviserState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setPreviewed:
      let _previewedState = context.state;
      _previewedState.previewed = action.payload;
      return {
        state: _previewedState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeCheckListItem:
      let _newCheckListItemState = context.state;
      let _currentChecklistItem =
        _newCheckListItemState["checklist"][action.payload];
      _newCheckListItemState["checklist"][
        action.payload
      ] = !_currentChecklistItem;
      return {
        state: _newCheckListItemState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeAdditionalInfo:
      let _additionalInfoState = context.state;
      _additionalInfoState["additionalCustomerInformation"][
        action.payload.item
      ] = action.payload.value;
      return {
        state: _additionalInfoState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setAttitudeToRisk:
      let _attitudeToRiskState = context.state;
      _attitudeToRiskState.clientAttitudeToRisk = action.payload;
      return {
        state: _attitudeToRiskState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setCapacityForLoss:
      let _capacityForLossState = context.state;
      _capacityForLossState.clientCapacityForLoss = action.payload;
      return {
        state: _capacityForLossState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setRiskComments:
      let _riskCommentsState = context.state;
      _riskCommentsState[action.payload.field] = action.payload.value;
      return {
        state: _riskCommentsState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeAdditionalGoalDetail:
      let _additionalGoalDetailState = context.state;
      let _customerGoals = [...context.state.customerGoals];
      _customerGoals[action.payload.index][action.payload.field] =
        action.payload.value;
      _additionalGoalDetailState.customerGoals = _customerGoals;
      return {
        state: _additionalGoalDetailState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeMTR:
      let _MTRState = context.state;
      if (action.payload.customerID === 1) {
        _MTRState.customerInfo.customers.customer1.customerMarginalTaxRate =
          action.payload.rate;
      } else if (action.payload.customerID === 2) {
        _MTRState.customerInfo.customers.customer2.customerMarginalTaxRate =
          action.payload.rate;
      }
      return {
        state: _MTRState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeAdditionalPersonField:
      let _additionalPersonFieldState = context.state;
      if (action.payload.customerID === 1) {
        _additionalPersonFieldState.customerInfo.customers.customer1[
          action.payload.field
        ] = action.payload.value;
      } else if (action.payload.customerID === 2) {
        _additionalPersonFieldState.customerInfo.customers.customer2[
          action.payload.field
        ] = action.payload.value;
      }
      return {
        state: _additionalPersonFieldState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case addChild:
      let _addChildState = context.state;
      let _children = context.state.customerInfo.children;
      _children.push({
        id: Shortid.generate(),
        name: "",
        age: "",
        financiallyDependent: false,
      });
      _addChildState.customerInfo.children = [..._children];
      return {
        state: _addChildState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeChild:
      let changeChildState = context.state;
      let _changedchildren = [...context.state.customerInfo.children];
      let _customerInfoChildren = changeChildState.customerInfo;
      let _childIndex;
      for (let child of _changedchildren) {
        if (child.id === action.payload.key) {
          _childIndex = _changedchildren.indexOf(child);
        }
      }

      if (action.payload.field === "deleteChild") {
        _changedchildren.splice(_childIndex, 1);
      } else if (action.payload.field === "financiallyDependent") {
        _changedchildren[_childIndex].financiallyDependent = !_changedchildren[
          _childIndex
        ].financiallyDependent;
      } else {
        _changedchildren[_childIndex][action.payload.field] =
          action.payload.value;
      }
      changeChildState.customerInfo.children = [..._changedchildren];
      return {
        state: changeChildState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case addPension:
      let _addPensionState = context.state;
      _addPensionState.pensions.push({
        policyNumber: "",
        owner: "customer1",
        provider: "",
        planType: "",
        contributions: 0,
        srdNrd: "",
        investments: 0,
        fundValue: 0,
        transferValue: 0,
        penaltiesOnTransfer: 0,
        charges: 0,
        expressionOfWish: "",
        protectedTaxFreeCash: 0,
        writtenIntoTrust: "",
        anyGuarantees: "",
        guaranteeDetails: "",
        annuityPurchase: false,
        uFPLS: false,
        flexiAccess: false,
        drawdown: false,
        oorGuarantees: false,
        transferPension: false,
      });
      return {
        state: _addPensionState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case addInvestment:
      let _addInvestmentState = context.state;
      _addInvestmentState.investments.push({
        owner: "customer1",
        policyNumber: "",
        provider: "",
        planType: "",
        contributions: "",
        charges: "",
        investments: "",
        value: "",
        interestRate: "",
        transferISA: false,
        sell: "",
      });
      return {
        state: _addInvestmentState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case deleteInvestment:
      let _deleteInvestmentState = context.state;
      _deleteInvestmentState.investments.splice(action.payload.index, 1);
      return {
        state: _deleteInvestmentState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case deletePension:
      let _deletePensionState = context.state;
      _deletePensionState.pensions.splice(action.payload.index, 1);
      return {
        state: _deletePensionState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changePension:
      let _changedPensionsState = context.state;
      _changedPensionsState.pensions = action.payload.pensions;
      return {
        state: _changedPensionsState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeInvestment:
      let _changedInvestmentsState = context.state;
      _changedInvestmentsState.investments = action.payload.investments;
      return {
        state: _changedInvestmentsState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeFFNotes:
      let _changedFFNotesState = context.state;
      _changedFFNotesState.factFindNotes = action.payload.notes;
      return {
        state: _changedFFNotesState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeLOA:
      let changeLOADataState = context.state;
      if (action.payload.field === "niNumber") {
        changeLOADataState.customerInfo.customers[action.payload.customer][
          action.payload.field
        ] = action.payload.value;
      } else {
        changeLOADataState.customerInfo[action.payload.field] =
          action.payload.value;
      }
      return {
        state: changeLOADataState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeGoalFocus:
      let _changeGoalFocusState = context.state;
      _changeGoalFocusState.customerGoals[
        action.payload.index
      ].focused = _changeGoalFocusState.customerGoals[action.payload.index]
        .focused
        ? !_changeGoalFocusState.customerGoals[action.payload.index].focused
        : true;
      return {
        state: _changeGoalFocusState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeSRBuilder:
      let _changeSRBuilderState = context.state;
      if (action.payload.field === "owner") {
        if (action.payload.value === "customer2") {
          _changeSRBuilderState.customerInfo.customers.customer2.isSuitabilityReportOwner = !_changeSRBuilderState
            .customerInfo.customers.customer2.isSuitabilityReportOwner;
        } else {
          _changeSRBuilderState.customerInfo.customers.customer1.isSuitabilityReportOwner = !_changeSRBuilderState
            .customerInfo.customers.customer1.isSuitabilityReportOwner;
        }
      }
      return {
        state: _changeSRBuilderState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case addNewPensionAdvice:
      let _newPensionsAdviceState = context.state;
      let _totalTransfersIn = parseInt(
        _newPensionsAdviceState.pensions.reduce(
          (a, b) => parseInt(a) + parseInt(b["transferValue"] || 0),
          0
        )
      );
      let _newPensionsAdviceGoals = [];
      _newPensionsAdviceState.customerGoals.forEach(function (goal) {
        _newPensionsAdviceGoals.push({
          ...goal,
          goal: { goalAllocationPercent: 0 },
        });
      });
      _newPensionsAdviceState.newPensionsAdvice.push({
        editDate: firebase.firestore.Timestamp.now(),
        policyID: ("pension" + uuid()).replace(/ /g, ""),
        type: "pension",
        owner: "customer1",
        totalTransfersIn: _totalTransfersIn,
        goalAllocations: _newPensionsAdviceGoals,
        totalPercentage: 0,
        investmentStrategyActive: "passive",
        SIPP: false,
        grossMonthlyPersonalContribution: 0,
        grossMonthlyEmployerContribution: 0,
        grossLumpSumPersonalContribution: 0,
        grossLumpSumEmployerContribution: 0,
        crystaliseAmount: 0,
        taxFreeCashToTake: 0,
        incomeToTake: 0,
        includeAllowanceDetails: false,
        allowance202021: 0,
        existingContributions202021: 0,
        carryForwardAllowance: 0,
      });
      return {
        state: _newPensionsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changeNewPensionsAdvice:
      let _changedPensionsAdviceState = context.state;
      _changedPensionsAdviceState.newPensionsAdvice[action.payload.index][
        action.payload.field
      ] = action.payload.value;
      return {
        state: _changedPensionsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case deleteNewPensionAdvice:
      let _deletedPensionsAdviceState = context.state;
      _deletedPensionsAdviceState.newPensionsAdvice.splice(
        action.payload.index,
        1
      );
      return {
        state: _deletedPensionsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case allocatePensionAdviceGoalPercentage:
      let _newGoalAllocationPensionAdviceState = context.state;
      _newGoalAllocationPensionAdviceState.newPensionsAdvice[
        action.payload.cardAdviceIndex
      ].goalAllocations[action.payload.goalIndex].goalAllocationPercent =
        action.payload.value;
      return {
        state: _newGoalAllocationPensionAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case deleteNewISAAdvice:
      let _deletedISAsAdviceState = context.state;
      _deletedISAsAdviceState.newISAsAdvice.splice(action.payload.index, 1);
      return {
        state: _deletedISAsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case deleteNewGIAAdvice:
      let _deletedGIAsAdviceState = context.state;
      _deletedGIAsAdviceState.newGIAsAdvice.splice(action.payload.index, 1);
      return {
        state: _deletedGIAsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case deleteNewCashAdvice:
      let _deletedCashAdviceState = context.state;
      _deletedCashAdviceState.newCashAdvice.splice(action.payload.index, 1);
      return {
        state: _deletedCashAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case addNewISAAdvice:
      let _newISAsAdviceState = context.state;
      let _totalTransfersInISAs = parseInt(
        _newISAsAdviceState.investments.reduce(
          (a, b) => a + (b["transferValue"] || 0),
          0
        )
      );
      let _newISAsAdviceGoals = [];
      _newISAsAdviceState.customerGoals.forEach(function (goal) {
        _newISAsAdviceGoals.push({
          ...goal,
          goal: { goalAllocationPercent: 0 },
        });
      });
      _newISAsAdviceState.newISAsAdvice.push({
        editDate: firebase.firestore.Timestamp.now(),
        policyID: ("isa" + uuid()).replace(/ /g, ""),
        type: "isa",
        owner: "customer1",
        totalTransfersIn: _totalTransfersInISAs,
        goalAllocations: _newISAsAdviceGoals,
        totalPercentage: 0,
        investmentStrategyActive: "passive",
        SIPP: false,
        monthlyContribution: 0,
        lumpSumContribution: 0,
      });
      return {
        state: _newISAsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changenewISAsAdvice:
      let _changedISAsAdviceState = context.state;
      _changedISAsAdviceState.newISAsAdvice[action.payload.index][
        action.payload.field
      ] = action.payload.value;
      return {
        state: _changedISAsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case allocateISAAdviceGoalPercentage:
      let _newGoalAllocationISAAdviceState = context.state;
      _newGoalAllocationISAAdviceState.newISAsAdvice[
        action.payload.cardAdviceIndex
      ].goalAllocations[action.payload.goalIndex].goalAllocationPercent =
        action.payload.value;
      return {
        state: _newGoalAllocationISAAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case addNewGIAAdvice:
      let _newGIAsAdviceState = context.state;
      let _totalTransfersInGIA = parseInt(
        _newGIAsAdviceState.investments.reduce(
          (a, b) => a + (b["transferValue"] || 0),
          0
        )
      );
      let _newGIAsAdviceGoals = [];
      _newGIAsAdviceState.customerGoals.forEach(function (goal) {
        _newGIAsAdviceGoals.push({
          ...goal,
          goal: { goalAllocationPercent: 0 },
        });
      });
      _newGIAsAdviceState.newGIAsAdvice.push({
        editDate: firebase.firestore.Timestamp.now(),
        policyID: ("gia" + uuid()).replace(/ /g, ""),
        type: "gia",
        owner: "customer1",
        totalTransfersIn: _totalTransfersInGIA,
        goalAllocations: _newGIAsAdviceGoals,
        totalPercentage: 0,
        investmentStrategyActive: "passive",
        SIPP: false,
        monthlyContribution: 0,
        lumpSumContribution: 0,
      });
      return {
        state: _newGIAsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case allocateGIAAdviceGoalPercentage:
      let _newGoalAllocationGIAAdviceState = context.state;
      _newGoalAllocationGIAAdviceState.newGIAsAdvice[
        action.payload.cardAdviceIndex
      ].goalAllocations[action.payload.goalIndex].goalAllocationPercent =
        action.payload.value;
      return {
        state: _newGoalAllocationGIAAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changenewGIAsAdvice:
      let _changedGIAsAdviceState = context.state;
      _changedGIAsAdviceState.newGIAsAdvice[action.payload.index][
        action.payload.field
      ] = action.payload.value;
      return {
        state: _changedGIAsAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case addNewCashAdvice:
      let _newCashAdviceState = context.state;
      let _totalTransfersInCash = parseInt(
        _newCashAdviceState.investments.reduce(
          (a, b) => a + (b["transferValue"] || 0),
          0
        )
      );
      let _newCashAdviceGoals = [];
      _newCashAdviceState.customerGoals.forEach(function (goal) {
        _newCashAdviceGoals.push({
          ...goal,
          goal: { goalAllocationPercent: 0 },
        });
      });
      _newCashAdviceState.newCashAdvice.push({
        editDate: firebase.firestore.Timestamp.now(),
        policyID: ("cash" + uuid()).replace(/ /g, ""),
        type: "cash",
        owner: "customer1",
        totalTransfersIn: _totalTransfersInCash,
        goalAllocations: _newCashAdviceGoals,
        totalPercentage: 0,
        investmentStrategyActive: "passive",
        SIPP: false,
        monthlyContribution: 0,
        lumpSumContribution: 0,
      });
      return {
        state: _newCashAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case allocateCashAdviceGoalPercentage:
      let _newGoalAllocationCashAdviceState = context.state;
      _newGoalAllocationCashAdviceState.newCashAdvice[
        action.payload.cardAdviceIndex
      ].goalAllocations[action.payload.goalIndex].goalAllocationPercent =
        action.payload.value;
      return {
        state: _newGoalAllocationCashAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case changenewCashAdvice:
      let _changedCashAdviceState = context.state;
      _changedCashAdviceState.newCashAdvice[action.payload.index][
        action.payload.field
      ] = action.payload.value;
      return {
        state: _changedCashAdviceState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case setInvestmentSolutions:
      let _investmentSolutionsState = context.state;
      _investmentSolutionsState.investmentSolutions = [...action.payload];
      return {
        state: _investmentSolutionsState,
        status: REQUEST_STATUS.SUCCESS,
      };
    case createPdfDocxData:
      let _pdfDocxState = context.state;

      _pdfDocxState.mortgageTerm = getMortgageTerm(
        context.state.financialCheck.timeUntilMortgageDealEnds
      );
      _pdfDocxState.lifeCover = getLifeCover(
        context.state.financialCheck.lifeCover,
        context.state.financialCheck.incomeProtection
      );

      _pdfDocxState.totalImplementationChargeOnPot = getTotalImplementationChargeOnPot(
        context.state.newPensionsAdvice,
        context.state.newISAsAdvice,
        context.state.newGIAsAdvice
      );
      _pdfDocxState.totalImplementationCharge = getTotalImplementationCharge(
        context.state.newPensionsAdvice,
        context.state.newISAsAdvice,
        context.state.newGIAsAdvice
      );
      _pdfDocxState.totalPortfolioValue = getTotalPortfolioValue(
        context.state.newPensionsAdvice,
        context.state.newISAsAdvice,
        context.state.newGIAsAdvice
      );

      _pdfDocxState.totalWeightedCharge = getTotalWeightedCharge(
        context.state.newPensionsAdvice,
        context.state.newISAsAdvice,
        context.state.newGIAsAdvice,
        context.state.customerGoals,
        context.state.clientAttitudeToRisk
      ).toFixed(2);

      _pdfDocxState.fundProviders = getFundProviders(
        context.state.newPensionsAdvice,
        context.state.newISAsAdvice,
        context.state.newGIAsAdvice,
        context.state.customerGoals,
        context.state.clientAttitudeToRisk
      );

      _pdfDocxState.investmentSolutionsGroupedByGoals = getInvestmentSolutionsGroupedByGoals(
        context.state.newPensionsAdvice,
        context.state.newISAsAdvice,
        context.state.newGIAsAdvice,
        context.state.customerGoals,
        context.state.clientAttitudeToRisk
      );
      _pdfDocxState.investmentSolutionsGroupedByGoalsRisk = getInvestmentSolutionsGroupedByGoalsRisk(
        context.state.newPensionsAdvice,
        context.state.newISAsAdvice,
        context.state.newGIAsAdvice,
        context.state.customerGoals,
        context.state.clientAttitudeToRisk
      );
      _pdfDocxState.allInvestmentSolutionsDeduped = getAllInvestmentSolutionsDeduped(
        context.state.newPensionsAdvice,
        context.state.newISAsAdvice,
        context.state.newGIAsAdvice,
        context.state.customerGoals,
        context.state.clientAttitudeToRisk
      );

      _pdfDocxState.riskMatrix = getRiskMatrix(
        false,
        context.state.clientAttitudeToRisk
      );
      _pdfDocxState.riskMatrix = getCustomerAttitudeToRisk(
        context.state.clientAttitudeToRisk
      );

      return {
        state: _pdfDocxState,
        status: REQUEST_STATUS.SUCCESS,
      };
  }
};
