import React from 'react';

import './card.css';

function AmmoniteCard({ image, cardHeader, cardText }) {
	return (
		<div className="cardContainer">
			<div className="aboutCardHeader">{cardHeader}</div>
			<div className="aboutCardText">{cardText}</div>
			<div className="imageContainer">
				<img src={image} alt="About 1" height="440" width="691" />
			</div>
		</div>
	);
}

export default AmmoniteCard;
