//React
import React from 'react';

//external libraries

//components
import './nextStep.css';

//assets

function AmmoniteNextStep({ nextStepTitle, nextStepText, nextStepIllustration, textLeft, line, lineLength}) {
    const viewBox = "0 0 4 " + lineLength
	return (
		<div className="nextStepContainer">
            {textLeft ? <div className="nextStepTextContainer">
                <div className="nextStepTitle">
                    {nextStepTitle}
                </div>
                <div className="nextStepText">
                    {nextStepText}
                </div>
            </div> : <div className="nextStepIllustration">
                {nextStepIllustration}
            </div>}
            <div className="nextStepDivider">
                <div className="dividerDot">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5" cy="5" r="4" transform="rotate(-180 5 5)" fill="white" stroke="#BF802F" strokeWidth="2"/>
                    </svg>
                </div>
                {line && <div className="dividerLine">
                    <svg width="4" height={lineLength} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="0" y1="0" x2="0" y2={lineLength} stroke="#BDBDBD" strokeWidth="4"/>
                    </svg>
                </div>}
            </div>
            {textLeft ? <div className="nextStepIllustration">
                {nextStepIllustration}
            </div> : <div className="nextStepTextContainer">
            <div className="nextStepTitle">
                    {nextStepTitle}
                </div>
                <div className="nextStepText">
                    {nextStepText}
                </div>
            </div>}
        </div>
	);
}

export default AmmoniteNextStep;
