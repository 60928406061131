import React from 'react';

function AmmoniteMortgage() {
	return (
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.5251 12.7198L15.8876 2.86978C15.7714 2.75262 15.6332 2.65962 15.4809 2.59616C15.3285 2.5327 15.1652 2.50003 15.0001 2.50003C14.8351 2.50003 14.6717 2.5327 14.5194 2.59616C14.3671 2.65962 14.2288 2.75262 14.1126 2.86978L4.47514 12.7323C4.24194 12.9674 4.05776 13.2464 3.9333 13.5533C3.80885 13.8601 3.74659 14.1887 3.75014 14.5198V24.9948C3.74918 25.6347 3.99361 26.2506 4.43311 26.7156C4.87261 27.1807 5.47372 27.4596 6.11264 27.4948H23.8876C24.5266 27.4596 25.1277 27.1807 25.5672 26.7156C26.0067 26.2506 26.2511 25.6347 26.2501 24.9948V14.5198C26.2511 13.8484 25.9912 13.203 25.5251 12.7198ZM12.5001 24.9948V17.4948H17.5001V24.9948H12.5001ZM23.7501 24.9948H20.0001V16.2448C20.0001 15.9133 19.8684 15.5953 19.634 15.3609C19.3996 15.1265 19.0817 14.9948 18.7501 14.9948H11.2501C10.9186 14.9948 10.6007 15.1265 10.3663 15.3609C10.1318 15.5953 10.0001 15.9133 10.0001 16.2448V24.9948H6.25014V14.4698L15.0001 5.53228L23.7501 14.5198V24.9948Z" fill="#247BA0"/>
</svg>


	);
}

export default AmmoniteMortgage;
