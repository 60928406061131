import React from 'react';

function FamilyFocusButton({ colour }) {
	return (
<svg width="87" height="72" viewBox="0 0 87 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f)">
<path d="M4 36C4 18.3269 18.1478 4 35.6 4H51.4C68.8522 4 83 18.3269 83 36C83 53.6731 68.8522 68 51.4 68H35.6C18.1478 68 4 53.6731 4 36Z" fill={colour}/>
</g>
<path d="M6 35C6 18.4315 19.4315 5 36 5H51C67.5685 5 81 18.4315 81 35C81 51.5685 67.5685 65 51 65H36C19.4315 65 6 51.5685 6 35Z" fill="#3F762C"/>
<path d="M39.5 33.5C40.6867 33.5 41.8467 33.1481 42.8334 32.4888C43.8201 31.8295 44.5892 30.8925 45.0433 29.7961C45.4974 28.6997 45.6162 27.4933 45.3847 26.3295C45.1532 25.1656 44.5818 24.0965 43.7426 23.2574C42.9035 22.4182 41.8344 21.8468 40.6705 21.6153C39.5067 21.3838 38.3003 21.5026 37.2039 21.9567C36.1075 22.4108 35.1705 23.1799 34.5112 24.1666C33.8519 25.1533 33.5 26.3133 33.5 27.5C33.5 29.0913 34.1321 30.6174 35.2574 31.7426C36.3826 32.8679 37.9087 33.5 39.5 33.5ZM39.5 24.5C40.0933 24.5 40.6734 24.676 41.1667 25.0056C41.6601 25.3352 42.0446 25.8038 42.2716 26.3519C42.4987 26.9001 42.5581 27.5033 42.4424 28.0853C42.3266 28.6672 42.0409 29.2018 41.6213 29.6213C41.2018 30.0409 40.6672 30.3266 40.0853 30.4424C39.5033 30.5581 38.9001 30.4987 38.352 30.2716C37.8038 30.0446 37.3352 29.6601 37.0056 29.1667C36.6759 28.6734 36.5 28.0933 36.5 27.5C36.5 26.7043 36.8161 25.9413 37.3787 25.3787C37.9413 24.8161 38.7044 24.5 39.5 24.5Z" fill="white"/>
<path d="M51.5 36.5C52.39 36.5 53.26 36.2361 54.0001 35.7416C54.7401 35.2471 55.3169 34.5443 55.6575 33.7221C55.9981 32.8998 56.0872 31.995 55.9135 31.1221C55.7399 30.2492 55.3113 29.4474 54.682 28.818C54.0526 28.1887 53.2508 27.7601 52.3779 27.5865C51.505 27.4128 50.6002 27.5019 49.7779 27.8425C48.9557 28.1831 48.2529 28.7599 47.7584 29.4999C47.2639 30.24 47 31.11 47 32C47 33.1935 47.4741 34.3381 48.318 35.182C49.1619 36.0259 50.3065 36.5 51.5 36.5ZM51.5 30.5C51.7967 30.5 52.0867 30.588 52.3334 30.7528C52.58 30.9176 52.7723 31.1519 52.8858 31.426C52.9993 31.7001 53.0291 32.0017 52.9712 32.2926C52.9133 32.5836 52.7704 32.8509 52.5607 33.0607C52.3509 33.2704 52.0836 33.4133 51.7926 33.4712C51.5017 33.5291 51.2001 33.4994 50.926 33.3858C50.6519 33.2723 50.4176 33.08 50.2528 32.8334C50.088 32.5867 50 32.2967 50 32C50 31.6022 50.158 31.2206 50.4393 30.9393C50.7206 30.658 51.1022 30.5 51.5 30.5Z" fill="white"/>
<path d="M51.5 38C49.8377 38.0018 48.2232 38.5559 46.91 39.575C45.4408 38.1115 43.5711 37.1159 41.5367 36.7136C39.5024 36.3114 37.3945 36.5205 35.4788 37.3147C33.5632 38.1089 31.9256 39.4525 30.7726 41.1762C29.6196 42.8998 29.0028 44.9263 29 47C29 47.3978 29.158 47.7794 29.4393 48.0607C29.7206 48.342 30.1022 48.5 30.5 48.5C30.8978 48.5 31.2794 48.342 31.5607 48.0607C31.842 47.7794 32 47.3978 32 47C32 45.0109 32.7902 43.1032 34.1967 41.6967C35.6032 40.2902 37.5109 39.5 39.5 39.5C41.4891 39.5 43.3968 40.2902 44.8033 41.6967C46.2098 43.1032 47 45.0109 47 47C47 47.3978 47.158 47.7794 47.4393 48.0607C47.7206 48.342 48.1022 48.5 48.5 48.5C48.8978 48.5 49.2794 48.342 49.5607 48.0607C49.842 47.7794 50 47.3978 50 47C50.0036 45.2426 49.5596 43.5133 48.71 41.975C49.3729 41.4512 50.1698 41.1246 51.0097 41.0325C51.8495 40.9404 52.6983 41.0867 53.4589 41.4545C54.2196 41.8222 54.8613 42.3967 55.3106 43.1122C55.76 43.8276 55.9989 44.6551 56 45.5C56 45.8978 56.158 46.2794 56.4393 46.5607C56.7206 46.842 57.1022 47 57.5 47C57.8978 47 58.2794 46.842 58.5607 46.5607C58.842 46.2794 59 45.8978 59 45.5C59 43.5109 58.2098 41.6032 56.8033 40.1967C55.3968 38.7902 53.4891 38 51.5 38Z" fill="white"/>
<defs>
<filter id="filter0_f" x="0" y="0" width="87" height="72" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur"/>
</filter>
</defs>
</svg>



	);
}

export default FamilyFocusButton;
