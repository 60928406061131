import React from 'react';

function AmmoniteCharges() {
	return (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M16.5 22.5H10.5C9.675 22.5 9 21.825 9 21C9 20.175 9.675 19.5 10.5 19.5H16.5C17.325 19.5 18 20.175 18 21C18 21.825 17.325 22.5 16.5 22.5ZM25.5 22.5H22.5C21.675 22.5 21 21.825 21 21C21 20.175 21.675 19.5 22.5 19.5H25.5C26.325 19.5 27 20.175 27 21C27 21.825 26.325 22.5 25.5 22.5ZM30 24C30 24.8265 29.328 25.5 28.5 25.5H7.5C6.672 25.5 6 24.8265 6 24V16.5H30V24ZM6 12C6 11.1735 6.672 10.5 7.5 10.5H28.5C29.328 10.5 30 11.1735 30 12V13.5H6V12ZM28.5 7.5H7.5C5.019 7.5 3 9.519 3 12V24C3 26.481 5.019 28.5 7.5 28.5H28.5C30.981 28.5 33 26.481 33 24V12C33 9.519 30.981 7.5 28.5 7.5V7.5Z" fill="#39B54A"/>
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="7" width="30" height="22">
<path fillRule="evenodd" clipRule="evenodd" d="M16.5 22.5H10.5C9.675 22.5 9 21.825 9 21C9 20.175 9.675 19.5 10.5 19.5H16.5C17.325 19.5 18 20.175 18 21C18 21.825 17.325 22.5 16.5 22.5ZM25.5 22.5H22.5C21.675 22.5 21 21.825 21 21C21 20.175 21.675 19.5 22.5 19.5H25.5C26.325 19.5 27 20.175 27 21C27 21.825 26.325 22.5 25.5 22.5ZM30 24C30 24.8265 29.328 25.5 28.5 25.5H7.5C6.672 25.5 6 24.8265 6 24V16.5H30V24ZM6 12C6 11.1735 6.672 10.5 7.5 10.5H28.5C29.328 10.5 30 11.1735 30 12V13.5H6V12ZM28.5 7.5H7.5C5.019 7.5 3 9.519 3 12V24C3 26.481 5.019 28.5 7.5 28.5H28.5C30.981 28.5 33 26.481 33 24V12C33 9.519 30.981 7.5 28.5 7.5V7.5Z" fill="white"/>
</mask>
<g mask="url(#mask0)">
</g>
</svg>
	);
}

export default AmmoniteCharges;
