import React from 'react';

function RetirementAllocationButton({ colour }) {
	return (
<svg width="70" height="30" viewBox="0 0 70 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<rect width="70" height="30" rx="15" fill="#BF802F"/>
<path fillRule="evenodd" clipRule="evenodd" d="M34.9994 20.0625C35.1981 20.0625 35.3969 20.11 35.5781 20.2037L40.2894 22.6662L39.3931 17.47C39.3219 17.0625 39.4581 16.6475 39.7556 16.3587L43.5506 12.6887L38.2969 11.9237C37.8906 11.865 37.5394 11.6112 37.3569 11.2437L34.9994 6.50125L32.6419 11.2437C32.4594 11.6112 32.1081 11.865 31.7019 11.9237L26.4481 12.6887L30.2431 16.3587C30.5406 16.6475 30.6769 17.0625 30.6056 17.47L29.7094 22.6662L34.4206 20.2037C34.6019 20.11 34.8006 20.0625 34.9994 20.0625M41.9519 26.1938C41.7532 26.1938 41.5544 26.1475 41.3732 26.0525L34.9994 22.7225L28.6257 26.0525C28.2032 26.2725 27.6932 26.2338 27.3119 25.9538C26.9269 25.6738 26.7357 25.2 26.8157 24.7313L28.0307 17.6963L22.8807 12.715C22.5369 12.3838 22.4144 11.8863 22.5607 11.4325C22.7057 10.9788 23.0982 10.6488 23.5694 10.58L30.6957 9.545L33.8794 3.1375C34.3019 2.2875 35.6969 2.2875 36.1194 3.1375L39.3032 9.545L46.4294 10.58C46.9007 10.6488 47.2932 10.9788 47.4382 11.4325C47.5844 11.8863 47.4619 12.3838 47.1182 12.715L41.9682 17.6963L43.1832 24.7313C43.2632 25.2 43.0719 25.6738 42.6869 25.9538C42.4707 26.1138 42.2119 26.1938 41.9519 26.1938" fill="white"/>
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="22" y="2" width="26" height="25">
<path fillRule="evenodd" clipRule="evenodd" d="M34.9994 20.0625C35.1981 20.0625 35.3969 20.11 35.5781 20.2037L40.2894 22.6662L39.3931 17.47C39.3219 17.0625 39.4581 16.6475 39.7556 16.3587L43.5506 12.6887L38.2969 11.9237C37.8906 11.865 37.5394 11.6112 37.3569 11.2437L34.9994 6.50125L32.6419 11.2437C32.4594 11.6112 32.1081 11.865 31.7019 11.9237L26.4481 12.6887L30.2431 16.3587C30.5406 16.6475 30.6769 17.0625 30.6056 17.47L29.7094 22.6662L34.4206 20.2037C34.6019 20.11 34.8006 20.0625 34.9994 20.0625M41.9519 26.1938C41.7532 26.1938 41.5544 26.1475 41.3732 26.0525L34.9994 22.7225L28.6257 26.0525C28.2032 26.2725 27.6932 26.2338 27.3119 25.9538C26.9269 25.6738 26.7357 25.2 26.8157 24.7313L28.0307 17.6963L22.8807 12.715C22.5369 12.3838 22.4144 11.8863 22.5607 11.4325C22.7057 10.9788 23.0982 10.6488 23.5694 10.58L30.6957 9.545L33.8794 3.1375C34.3019 2.2875 35.6969 2.2875 36.1194 3.1375L39.3032 9.545L46.4294 10.58C46.9007 10.6488 47.2932 10.9788 47.4382 11.4325C47.5844 11.8863 47.4619 12.3838 47.1182 12.715L41.9682 17.6963L43.1832 24.7313C43.2632 25.2 43.0719 25.6738 42.6869 25.9538C42.4707 26.1138 42.2119 26.1938 41.9519 26.1938" fill="white"/>
</mask>
<g mask="url(#mask0)">
</g>
</g>
<defs>
<clipPath id="clip0">
<rect width="70" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>

	);
}

export default RetirementAllocationButton;
