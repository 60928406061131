//React
import React, { useState, useEffect } from "react";
import firebase from "../../../Utils/firebase";

// Component imports
import AmmoniteRoundSwitch from "../../../components/RoundSwitch/RoundSwitch";
import Loader from "react-loader-spinner";

//external libraries
import Button from "@material-ui/core/Button";

//components
import "./createUser.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function AmmoniteCreateUser({ closeCreateUser, user = "" }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isCoach, setIsCoach] = useState(true);
  const [invalidFirstName, setInvalidFirstName] = useState(false);
  const [invalidLastName, setInvalidLastName] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const createUserWithSpecificRole = firebase
    .functions()
    .httpsCallable("createUserWithSpecificRole");

  const checkSendData = async (event) => {
    setInvalidFirstName(false);
    setInvalidLastName(false);
    setInvalidEmail(false);
    setEmailTaken(false);
    let canDataBeSent = true;
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (firstName.trim().length === 0) {
      setInvalidFirstName(true);
      canDataBeSent = false;
    }

    if (lastName.trim().length === 0) {
      setInvalidLastName(true);
      canDataBeSent = false;
    }

    if (!re.test(String(email).toLowerCase())) {
      setInvalidEmail(true);
      canDataBeSent = false;
    } else {
      const usersRef = firebase.firestore().collection("users");
      const querySnapshot = await usersRef
        .where("email", "==", email.toLowerCase())
        .get();

      const sessionsRef = firebase.firestore().collection("sessions");
      const querySessionsSnapshot = await sessionsRef
        .where("customerEmail", "==", email.toLowerCase())
        .get();
      if (!querySnapshot.empty || !querySessionsSnapshot.empty) {
        setEmailTaken(true);
        canDataBeSent = false;
      }
    }

    if (canDataBeSent) {
      setIsLoading(true);

      await createUserWithSpecificRole({
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: "ammonite",
        role: isCoach ? "coach" : "admin",
      })
        .then((user) => {
          console.log("SUCCESS", user);
        })
        .then(() => {
          closeCreateUser(event);
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    }
  };

  useEffect(() => {}, [email, password]);

  return (
    <div className="blockInputPanel">
      {isLoading && (
        <div className="dashboardLoaderAnimation">
          <Loader type="Grid" color="#3bb9c4" height={80} width={80} />
        </div>
      )}
      {!isLoading && (
        <div className="createUserContainer">
          <div className="x" onClick={(event) => closeCreateUser(event)}></div>
          <div className="createUserTitle">Create Coach / Admin</div>
          <div className="createUserNameInputs">
            <div className="createUserNameInput">
              First Name
              <input
                autoComplete="off"
                type="text"
                name="fname"
                aria-label="first name"
                className="createUserNameInputBox"
                onChange={(event) => setFirstName(event.target.value)}
              />
              <div className="createUserErrorMessage">
                {invalidFirstName ? "Enter first name" : ""}
              </div>
            </div>
            <div className="createUserNameInput">
              Last Name
              <input
                autoComplete="off"
                type="text"
                name="lname"
                aria-label="last name"
                className="createUserNameInputBox"
                onChange={(event) => setLastName(event.target.value)}
              />
              <div className="createUserErrorMessage">
                {invalidLastName ? "Enter last name" : ""}
              </div>
            </div>
          </div>
          <div className="createUserNameInput createUserInputTitle">
            Email
            <input
              autoComplete="off"
              type="email"
              name="email"
              className="createUserInputBox"
              onChange={(event) => setEmail(event.target.value)}
            />
            <div className="createUserErrorMessage">
              {invalidEmail
                ? "Sorry, email not valid"
                : emailTaken
                ? "There is already an user with this email. Try another or edit the existing user."
                : ""}
            </div>
          </div>
          <div className="createUserNameInput createUserInputTitle createUserType">
            User Type
            <div className="createUserRoleButtons">
              <AmmoniteRoundSwitch
                colour="#3BB9C4"
                on={isCoach === true}
                switchHandler={() => setIsCoach(true)}
                switchText="Coach"
              />

              <AmmoniteRoundSwitch
                colour="#3BB9C4"
                on={isCoach === false}
                switchHandler={() => setIsCoach(false)}
                switchText="Admin"
              />
            </div>
          </div>
          <div className="createUserPasswordField">
            <div className="createUserNameInput createUserInputTitle createUserPassword">
              This user's password will be:
            </div>
            <div className="createUserNameInput createUserPasswordInput">
              ammonite
            </div>
          </div>
          <Button
            variant="contained"
            onClick={(event) => checkSendData(event)}
            style={{
              position: "relative",
              top: "130px",
              alignSelf: "center",
              width: "273px",
              height: "46px",
              borderRadius: "20px",
              fontFamily: "Brother 1816",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "18px",
              lineHeight: "26px",
              letterSpacing: "0.02em",
              color: "white",
              backgroundColor: "#3BB9C4",
              textTransform: "none",
            }}
          >
            Create Coach / Admin
          </Button>
        </div>
      )}
    </div>
  );
}

export default AmmoniteCreateUser;
