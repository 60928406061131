import React from 'react';

function AmmoniteLifeCover() {
	return (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M18 6.21976L7.5 12.1268V12.3443C7.5 19.4543 11.349 26.0378 17.547 29.5253L18 29.7803L18.453 29.5253C24.651 26.0378 28.5 19.4543 28.5 12.3443V12.1268L18 6.21976ZM18 32.7758C17.4915 32.7758 16.983 32.6498 16.5315 32.3948L16.077 32.1383C8.9355 28.1228 4.5 20.5373 4.5 12.3443V12.1268C4.5 11.0483 5.085 10.0463 6.027 9.51376L16.53 3.60676C17.4375 3.09676 18.564 3.09526 19.4685 3.60526L29.97 9.51226C30.915 10.0463 31.5 11.0483 31.5 12.1268V12.3443C31.5 20.5373 27.0645 28.1228 19.923 32.1383L19.47 32.3933C19.017 32.6483 18.5085 32.7758 18 32.7758Z" fill="#2B9BA5"/>
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="3" width="28" height="30">
<path fillRule="evenodd" clipRule="evenodd" d="M18 6.21976L7.5 12.1268V12.3443C7.5 19.4543 11.349 26.0378 17.547 29.5253L18 29.7803L18.453 29.5253C24.651 26.0378 28.5 19.4543 28.5 12.3443V12.1268L18 6.21976ZM18 32.7758C17.4915 32.7758 16.983 32.6498 16.5315 32.3948L16.077 32.1383C8.9355 28.1228 4.5 20.5373 4.5 12.3443V12.1268C4.5 11.0483 5.085 10.0463 6.027 9.51376L16.53 3.60676C17.4375 3.09676 18.564 3.09526 19.4685 3.60526L29.97 9.51226C30.915 10.0463 31.5 11.0483 31.5 12.1268V12.3443C31.5 20.5373 27.0645 28.1228 19.923 32.1383L19.47 32.3933C19.017 32.6483 18.5085 32.7758 18 32.7758Z" fill="white"/>
</mask>
<g mask="url(#mask0)">
</g>
</svg>


	);
}

export default AmmoniteLifeCover;
