import React from 'react';

function AmmoniteImplementation() {
	return (
<svg width="242" height="158" viewBox="0 0 242 158" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M151.844 111.89C182.742 111.89 207.789 86.843 207.789 55.9452C207.789 25.0475 182.742 0 151.844 0C120.946 0 95.8989 25.0475 95.8989 55.9452C95.8989 86.843 120.946 111.89 151.844 111.89Z" fill="#3BB9C4"/>
<path d="M151.844 66.1613C149.872 66.1613 147.944 65.5764 146.304 64.4805C144.664 63.3847 143.386 61.8272 142.631 60.0049C141.876 58.1826 141.678 56.1774 142.063 54.2428C142.448 52.3083 143.398 50.5313 144.793 49.1366C146.187 47.7418 147.964 46.792 149.899 46.4072C151.833 46.0224 153.839 46.2199 155.661 46.9747C157.483 47.7295 159.041 49.0078 160.137 50.6478C161.232 52.2878 161.817 54.216 161.817 56.1884C161.814 58.8325 160.763 61.3674 158.893 63.237C157.023 65.1066 154.488 66.1583 151.844 66.1613V66.1613ZM151.844 46.7021C149.968 46.7021 148.134 47.2584 146.574 48.3008C145.014 49.3432 143.798 50.8248 143.08 52.5582C142.362 54.2916 142.174 56.199 142.54 58.0391C142.906 59.8793 143.81 61.5696 145.137 62.8963C146.463 64.223 148.154 65.1265 149.994 65.4925C151.834 65.8586 153.741 65.6707 155.475 64.9527C157.208 64.2347 158.69 63.0188 159.732 61.4588C160.774 59.8988 161.331 58.0647 161.331 56.1884C161.328 53.6734 160.328 51.2621 158.549 49.4837C156.771 47.7052 154.36 46.7049 151.844 46.7021V46.7021Z" fill="white"/>
<path d="M151.844 75.1613C148.092 75.1613 144.424 74.0486 141.304 71.9638C138.184 69.8791 135.752 66.9159 134.316 63.4491C132.88 59.9823 132.504 56.1675 133.236 52.4872C133.968 48.8068 135.775 45.4262 138.429 42.7728C141.082 40.1194 144.463 38.3125 148.143 37.5804C151.823 36.8483 155.638 37.224 159.105 38.66C162.572 40.096 165.535 42.5278 167.62 45.6479C169.704 48.7679 170.817 52.4361 170.817 56.1886C170.811 61.2187 168.811 66.0412 165.254 69.598C161.697 73.1549 156.874 75.1556 151.844 75.1613V75.1613ZM151.844 37.7023C148.188 37.7023 144.614 38.7865 141.574 40.8178C138.534 42.8491 136.164 45.7363 134.765 49.1142C133.366 52.4921 133 56.2091 133.713 59.7951C134.427 63.381 136.187 66.675 138.773 69.2603C141.358 71.8457 144.652 73.6063 148.238 74.3196C151.824 75.0329 155.541 74.6668 158.919 73.2676C162.297 71.8685 165.184 69.499 167.215 66.459C169.246 63.4189 170.331 59.8448 170.331 56.1886C170.325 51.2874 168.376 46.5886 164.91 43.1229C161.444 39.6573 156.745 37.7078 151.844 37.7023V37.7023Z" fill="white"/>
<path d="M151.844 82.945C146.552 82.945 141.379 81.3757 136.979 78.4357C132.579 75.4957 129.149 71.3169 127.124 66.4278C125.099 61.5387 124.569 56.1589 125.602 50.9686C126.634 45.7784 129.182 41.0109 132.924 37.2689C136.666 33.527 141.434 30.9787 146.624 29.9463C151.814 28.9139 157.194 29.4437 162.083 31.4689C166.972 33.494 171.151 36.9234 174.091 41.3235C177.031 45.7236 178.6 50.8966 178.6 56.1886C178.592 63.2823 175.771 70.0833 170.755 75.0994C165.739 80.1154 158.938 82.937 151.844 82.945V82.945ZM151.844 29.9186C146.648 29.9186 141.569 31.4593 137.249 34.3459C132.929 37.2325 129.562 41.3353 127.574 46.1355C125.585 50.9357 125.065 56.2177 126.079 61.3136C127.092 66.4094 129.594 71.0903 133.268 74.7642C136.942 78.4381 141.623 80.9401 146.719 81.9537C151.815 82.9674 157.097 82.4471 161.897 80.4588C166.697 78.4705 170.8 75.1034 173.686 70.7834C176.573 66.4633 178.114 61.3843 178.114 56.1886C178.106 49.2238 175.336 42.5465 170.411 37.6216C165.486 32.6967 158.809 29.9265 151.844 29.9186V29.9186Z" fill="white"/>
<path d="M151.845 93.1611C131.458 93.1611 114.872 76.5754 114.872 56.1886C114.872 35.8019 131.458 19.2161 151.845 19.2161C172.231 19.2161 188.817 35.8019 188.817 56.1886C188.817 76.5754 172.231 93.1611 151.845 93.1611ZM151.845 19.7026C131.726 19.7026 115.359 36.0701 115.359 56.1886C115.359 76.307 131.726 92.6746 151.845 92.6746C171.963 92.6746 188.331 76.307 188.331 56.1886C188.331 36.0701 171.963 19.7026 151.845 19.7026Z" fill="white"/>
<path d="M151.844 101.674C126.763 101.674 106.358 81.2696 106.358 56.1886C106.358 31.1075 126.763 10.7026 151.844 10.7026C176.925 10.7026 197.33 31.1075 197.33 56.1886C197.33 81.2696 176.925 101.674 151.844 101.674ZM151.844 11.1891C127.032 11.1891 106.845 31.3758 106.845 56.1886C106.845 81.0014 127.032 101.188 151.844 101.188C176.657 101.188 196.844 81.0014 196.844 56.1886C196.844 31.3758 176.657 11.1891 151.844 11.1891V11.1891Z" fill="white"/>
<path d="M151.844 60.5669C154.397 60.5669 156.466 58.4977 156.466 55.9453C156.466 53.3929 154.397 51.3237 151.844 51.3237C149.292 51.3237 147.223 53.3929 147.223 55.9453C147.223 58.4977 149.292 60.5669 151.844 60.5669Z" fill="white"/>
<path d="M163.763 73.945C166.316 73.945 168.385 71.8759 168.385 69.3235C168.385 66.771 166.316 64.7019 163.763 64.7019C161.211 64.7019 159.142 66.771 159.142 69.3235C159.142 71.8759 161.211 73.945 163.763 73.945Z" fill="white"/>
<path d="M131.412 71.9992C133.964 71.9992 136.033 69.9301 136.033 67.3777C136.033 64.8252 133.964 62.7561 131.412 62.7561C128.859 62.7561 126.79 64.8252 126.79 67.3777C126.79 69.9301 128.859 71.9992 131.412 71.9992Z" fill="white"/>
<path d="M151.844 58.1344C153.053 58.1344 154.033 57.1543 154.033 55.9453C154.033 54.7362 153.053 53.7561 151.844 53.7561C150.635 53.7561 149.655 54.7362 149.655 55.9453C149.655 57.1543 150.635 58.1344 151.844 58.1344Z" fill="#39B54A"/>
<path d="M131.412 69.5668C132.621 69.5668 133.601 68.5867 133.601 67.3776C133.601 66.1686 132.621 65.1885 131.412 65.1885C130.203 65.1885 129.223 66.1686 129.223 67.3776C129.223 68.5867 130.203 69.5668 131.412 69.5668Z" fill="#39B54A"/>
<path d="M163.763 71.7558C164.972 71.7558 165.952 70.7756 165.952 69.5666C165.952 68.3576 164.972 67.3774 163.763 67.3774C162.554 67.3774 161.574 68.3576 161.574 69.5666C161.574 70.7756 162.554 71.7558 163.763 71.7558Z" fill="#39B54A"/>
<path d="M228.622 33.5673C229.831 33.5673 230.811 32.5872 230.811 31.3781C230.811 30.1691 229.831 29.189 228.622 29.189C227.413 29.189 226.433 30.1691 226.433 31.3781C226.433 32.5872 227.413 33.5673 228.622 33.5673Z" fill="#39B54A"/>
<path d="M151.966 56.0668H151.479V31.0132H228.346V31.4998H151.966V56.0668Z" fill="#0C3957"/>
<path d="M228.622 37.7022C227.371 37.7022 226.148 37.3313 225.108 36.6364C224.068 35.9415 223.257 34.9538 222.779 33.7981C222.3 32.6425 222.175 31.3709 222.419 30.1442C222.663 28.9174 223.265 27.7905 224.15 26.906C225.034 26.0216 226.161 25.4193 227.388 25.1752C228.615 24.9312 229.886 25.0565 231.042 25.5351C232.197 26.0138 233.185 26.8244 233.88 27.8644C234.575 28.9044 234.946 30.1271 234.946 31.378C234.944 33.0547 234.277 34.6622 233.091 35.8478C231.906 37.0334 230.298 37.7003 228.622 37.7022V37.7022ZM228.622 25.5402C227.467 25.5402 226.338 25.8826 225.378 26.524C224.418 27.1655 223.67 28.0772 223.228 29.1439C222.786 30.2106 222.671 31.3844 222.896 32.5169C223.121 33.6493 223.677 34.6895 224.494 35.5059C225.31 36.3223 226.35 36.8783 227.483 37.1036C228.615 37.3288 229.789 37.2132 230.856 36.7713C231.922 36.3295 232.834 35.5813 233.476 34.6212C234.117 33.6612 234.459 32.5326 234.459 31.378C234.458 29.8302 233.842 28.3464 232.748 27.252C231.653 26.1576 230.169 25.542 228.622 25.5402V25.5402Z" fill="#3F762C"/>
<path d="M214.028 96.5663C215.237 96.5663 216.217 95.5862 216.217 94.3771C216.217 93.1681 215.237 92.188 214.028 92.188C212.819 92.188 211.839 93.1681 211.839 94.3771C211.839 95.5862 212.819 96.5663 214.028 96.5663Z" fill="#39B54A"/>
<path d="M214.027 100.701C212.777 100.701 211.554 100.331 210.514 99.6356C209.474 98.9407 208.663 97.953 208.185 96.7974C207.706 95.6418 207.581 94.3702 207.825 93.1434C208.069 91.9166 208.671 90.7898 209.555 89.9053C210.44 89.0208 211.567 88.4185 212.794 88.1745C214.02 87.9305 215.292 88.0557 216.448 88.5344C217.603 89.0131 218.591 89.8237 219.286 90.8637C219.981 91.9037 220.352 93.1264 220.352 94.3772C220.35 96.0539 219.683 97.6615 218.497 98.8471C217.312 100.033 215.704 100.7 214.027 100.701V100.701ZM214.027 88.5395C212.873 88.5395 211.744 88.8818 210.784 89.5233C209.824 90.1648 209.076 91.0765 208.634 92.1432C208.192 93.2099 208.077 94.3837 208.302 95.5161C208.527 96.6485 209.083 97.6887 209.899 98.5051C210.716 99.3216 211.756 99.8776 212.888 100.103C214.021 100.328 215.195 100.212 216.261 99.7706C217.328 99.3288 218.24 98.5805 218.881 97.6205C219.523 96.6605 219.865 95.5318 219.865 94.3772C219.863 92.8295 219.248 91.3457 218.153 90.2513C217.059 89.1569 215.575 88.5412 214.027 88.5395V88.5395Z" fill="#3F762C"/>
<path d="M63.5481 43.7834C64.7571 43.7834 65.7372 42.8032 65.7372 41.5942C65.7372 40.3852 64.7571 39.405 63.5481 39.405C62.339 39.405 61.3589 40.3852 61.3589 41.5942C61.3589 42.8032 62.339 43.7834 63.5481 43.7834Z" fill="#39B54A"/>
<path d="M63.5479 47.9185C62.2971 47.9185 61.0743 47.5476 60.0343 46.8527C58.9943 46.1578 58.1837 45.1701 57.705 44.0145C57.2264 42.8588 57.1011 41.5873 57.3452 40.3605C57.5892 39.1337 58.1915 38.0068 59.076 37.1224C59.9604 36.2379 61.0873 35.6356 62.3141 35.3915C63.5409 35.1475 64.8125 35.2728 65.9681 35.7514C67.1237 36.2301 68.1114 37.0407 68.8063 38.0807C69.5012 39.1207 69.8721 40.3435 69.8721 41.5943C69.8702 43.271 69.2033 44.8785 68.0177 46.0641C66.8321 47.2497 65.2246 47.9166 63.5479 47.9185V47.9185ZM63.5479 35.7565C62.3933 35.7565 61.2646 36.0989 60.3046 36.7403C59.3446 37.3818 58.5963 38.2935 58.1545 39.3603C57.7126 40.427 57.597 41.6007 57.8223 42.7332C58.0475 43.8656 58.6035 44.9058 59.42 45.7222C60.2364 46.5386 61.2766 47.0946 62.409 47.3199C63.5414 47.5451 64.7152 47.4295 65.7819 46.9877C66.8486 46.5458 67.7604 45.7976 68.4018 44.8376C69.0433 43.8775 69.3857 42.7489 69.3857 41.5943C69.3839 40.0465 68.7683 38.5627 67.6739 37.4683C66.5794 36.3739 65.0956 35.7583 63.5479 35.7565V35.7565Z" fill="#3F762C"/>
<path d="M131.655 67.3779H131.169V42.8067L69.625 41.8374L69.6325 41.3511L131.655 42.3278V67.3779Z" fill="#0C3957"/>
<path d="M213.841 94.6203H163.52V69.5667H164.006V94.134H213.841V94.6203Z" fill="#0C3957"/>
<path d="M242 42.8103H215.244V44.513H242V42.8103Z" fill="#0C3957"/>
<path d="M242 47.675H215.244V49.3777H242V47.675Z" fill="#0C3957"/>
<path d="M242 52.5398H215.244V54.2425H242V52.5398Z" fill="#0C3957"/>
<path d="M227.406 105.809H200.649V107.512H227.406V105.809Z" fill="#0C3957"/>
<path d="M227.406 110.674H200.649V112.377H227.406V110.674Z" fill="#0C3957"/>
<path d="M227.406 115.539H200.649V117.242H227.406V115.539Z" fill="#0C3957"/>
<path d="M76.9261 53.2695H50.1697V54.9722H76.9261V53.2695Z" fill="#0C3957"/>
<path d="M76.9261 58.1345H50.1697V59.8372H76.9261V58.1345Z" fill="#0C3957"/>
<path d="M76.9261 62.9993H50.1697V64.702H76.9261V62.9993Z" fill="#0C3957"/>
<path d="M30.2013 157.977C42.9316 157.977 53.2515 157.215 53.2515 156.274C53.2515 155.334 42.9316 154.572 30.2013 154.572C17.471 154.572 7.15112 155.334 7.15112 156.274C7.15112 157.215 17.471 157.977 30.2013 157.977Z" fill="#DEDEDE"/>
<path d="M14.3813 37.4782C14.3813 37.4782 16.9276 44.7252 15.3607 45.8401C13.7938 46.9551 20.649 56.0603 20.649 56.0603L28.8754 53.8304L24.3705 46.2118C24.3705 46.2118 23.7829 38.5931 23.7829 37.4782C23.7829 36.3633 14.3813 37.4782 14.3813 37.4782Z" fill="#FFB8B8"/>
<path opacity="0.1" d="M14.3813 37.4782C14.3813 37.4782 16.9276 44.7252 15.3607 45.8401C13.7938 46.9551 20.649 56.0603 20.649 56.0603L28.8754 53.8304L24.3705 46.2118C24.3705 46.2118 23.7829 38.5931 23.7829 37.4782C23.7829 36.3633 14.3813 37.4782 14.3813 37.4782Z" fill="black"/>
<path d="M10.1072 89.1362C10.1072 89.1362 9.36392 98.4273 10.1072 103.259C10.8505 108.09 11.9654 121.469 11.9654 121.469C11.9654 121.469 11.9654 146.741 16.0535 147.112C20.1415 147.484 23.1147 147.856 23.4863 146.369C23.858 144.883 21.6281 144.139 22.743 143.396C23.858 142.653 24.2296 140.051 22.743 137.078C21.2565 134.105 22.743 104.002 22.743 104.002L29.8042 122.956C29.8042 122.956 30.5475 138.565 31.2908 140.794C32.0341 143.024 30.5475 147.112 33.149 147.484C35.7505 147.856 38.7237 145.626 40.2102 144.883C41.6968 144.139 37.9804 144.139 38.7236 143.768C39.4669 143.396 41.6968 142.281 40.9535 141.909C40.2102 141.538 39.4669 123.699 39.4669 123.699C39.4669 123.699 37.4229 85.6056 34.8214 84.1191C32.2199 82.6325 30.5475 85.193 30.5475 85.193L10.1072 89.1362Z" fill="#0C3957"/>
<path d="M16.7969 145.626V148.599C16.7969 148.599 13.4521 156.681 16.7969 156.681C20.1416 156.681 22.7431 157.519 22.7431 156.404V146.369L16.7969 145.626Z" fill="#0C3957"/>
<path d="M38.7239 145.621V148.594C38.7239 148.594 42.0686 156.676 38.7239 156.676C35.3791 156.676 32.7776 157.513 32.7776 156.398V146.364L38.7239 145.621Z" fill="#0C3957"/>
<path d="M19.5841 42.8669C23.2786 42.8669 26.2736 39.8719 26.2736 36.1774C26.2736 32.4828 23.2786 29.4878 19.5841 29.4878C15.8895 29.4878 12.8945 32.4828 12.8945 36.1774C12.8945 39.8719 15.8895 42.8669 19.5841 42.8669Z" fill="#FFB8B8"/>
<path d="M17.5402 48.6274C17.5402 48.6274 19.3984 54.2021 22.3715 53.0871L25.3446 51.9722L30.5476 87.6499C30.5476 87.6499 26.4596 93.5961 20.5133 89.8797C14.567 86.1633 17.5402 48.6274 17.5402 48.6274Z" fill="white"/>
<path d="M22.7432 47.5125L24.4156 43.9819C24.4156 43.9819 34.2641 48.6275 35.7506 50.114C37.2372 51.6006 37.2372 53.8305 37.2372 53.8305L34.6357 63.1215C34.6357 63.1215 35.379 83.5618 35.379 84.3051C35.379 85.0484 37.9805 89.1365 36.1223 87.6499C34.2641 86.1633 33.8924 84.6768 32.4059 86.9066C30.9193 89.1365 27.5745 91.738 27.5745 91.738L22.7432 47.5125Z" fill="#69306D"/>
<path d="M35.7507 74.2708L34.6358 84.6767C34.6358 84.6767 27.9463 90.623 29.4328 90.9946C30.9194 91.3663 31.6627 89.8797 31.6627 89.8797C31.6627 89.8797 34.2642 92.4812 35.7507 90.9946C37.2373 89.5081 40.9537 75.3857 40.9537 75.3857L35.7507 74.2708Z" fill="#FFB8B8"/>
<path d="M27.6402 31.7477C27.6093 31.2727 27.4023 30.8255 27.0586 30.4916C27.1897 30.0198 27.241 29.5299 27.2104 29.0416C27.0628 27.607 25.9644 27.4392 25.0812 26.6201C24.6981 26.2648 24.7777 26.0452 24.6446 25.5737C24.4703 24.9555 24.1238 24.3981 23.6446 23.9647C23.0858 23.4054 22.4935 22.8256 21.7317 22.5987C20.1308 22.1218 18.5339 23.3505 16.8672 23.484C16.1326 23.5429 15.3944 23.3854 14.6588 23.4318C13.9233 23.4781 13.1244 23.8052 12.859 24.4841C12.7365 24.7976 12.7431 25.149 12.6199 25.4622C12.3751 26.0847 11.6983 26.4065 11.1924 26.8497C10.0737 27.8298 9.78712 29.5067 10.1693 30.9343C10.2509 31.2303 10.3558 31.5196 10.4829 31.7996C10.3998 32.4148 10.4393 33.0401 10.5991 33.6404C10.9812 35.0681 11.9263 36.284 12.9608 37.3504C12.959 36.9052 12.9984 36.4607 13.0785 36.0225C13.0948 35.8741 13.1477 35.732 13.2327 35.6085C13.4332 35.3592 13.8088 35.3658 14.1176 35.2742C14.7572 35.0845 15.142 34.4319 15.3072 33.793C15.4725 33.1542 15.4837 32.4794 15.7 31.8556C15.7367 31.7092 15.8041 31.5721 15.8978 31.453C15.9915 31.3339 16.1095 31.2355 16.2441 31.1642C16.4802 31.0937 16.7318 31.0914 16.9692 31.1576C18.1397 31.3729 19.3561 31.6256 20.5044 31.3152C20.8962 31.2093 21.2771 31.0383 21.6831 31.0262C22.6042 30.9988 23.3532 31.8011 23.6836 32.6505C24.0139 33.4999 24.0607 34.4325 24.3466 35.2975C24.6326 36.1625 25.2739 37.0211 26.1885 37.1329C26.2388 37.1444 26.291 37.1452 26.3416 37.1354C26.3923 37.1256 26.4403 37.1054 26.4825 37.076C26.5369 37.0163 26.5721 36.942 26.5837 36.8626L27.4152 33.501C27.5904 32.9334 27.6665 32.3405 27.6402 31.7477V31.7477Z" fill="#0C3957"/>
<path d="M19.3983 46.3976C19.3983 46.3976 16.3729 43.4329 15.2841 43.6145C14.1953 43.7961 12.7087 46.3976 12.7087 46.3976C12.7087 46.3976 3.78932 49.3707 4.16096 52.3438C4.5326 55.317 8.62067 69.811 8.62067 69.811C8.62067 69.811 11.9654 87.2782 8.99231 89.1364C6.01917 90.9946 23.1147 95.826 23.4863 93.5961C23.858 91.3663 24.6013 69.0677 23.4863 65.723C22.3714 62.3782 19.3983 46.3976 19.3983 46.3976Z" fill="#69306D"/>
<path d="M7.50586 77.6155L12.7088 79.8454C12.7088 79.8454 22.7432 81.3319 22.7432 77.2439C22.7432 73.1558 12.7088 75.3856 12.7088 75.3856L9.36385 74.3162L7.50586 77.6155Z" fill="#FFB8B8"/>
<path d="M32.4058 52.344H37.0011C37.0011 52.344 40.2102 66.4664 40.5819 67.953C40.9535 69.4395 41.6968 76.5007 41.3252 76.5007C40.9535 76.5007 33.5207 75.0142 33.5207 76.1291L32.4058 52.344Z" fill="#69306D"/>
<path d="M8.6208 50.1138L4.16109 51.2287L0.816328 70.9257C0.816328 70.9257 -0.298588 76.1287 1.55962 76.5004C3.41782 76.872 8.62081 79.8451 8.62081 79.8451C8.62081 79.8451 9.3641 74.2705 10.8507 74.2705L7.13425 71.2974L9.36409 58.2899L8.6208 50.1138Z" fill="#69306D"/>
</svg>

	);
}

export default AmmoniteImplementation;
