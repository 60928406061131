// Core React dependencies
import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';

import SuitabilityReportNavigation from './../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation';
import AmmoniteSummaryGoalCard from '../../../containers/GoalSummaryCard/GoalSummaryCard';
import firebase from '../../../Utils/firebase';
import { AmmoniteInitialiseSuitabilityContext } from '../../../Utils/data/initialiseSuitabilityContext'
import { saveContextToFirebase } from '../../../Utils/data/saveSuitabilityContext'

import SuitabilityReportContext from '../../../Utils/contexts/suitabilityReportContext';
import { 
    initialiseSBReport,
    setNames,
    changeAdditionalGoalDetail
} from '../../../Utils/reducers/suitabilityReportActions';

// Styling
import './additionalGoalDetails.css';
import styles from '../../../Styles/srbstyles.module.css'


function AdditionalGoalDetails() {
    const { push } = useHistory();
    const { state, status, error, dispatch } = useContext(SuitabilityReportContext);
    
    const exitHandler = () => {
        push('/suitabilitydashboard');
    }

    const saveHandler = async () => {
        await saveContextToFirebase(state).then(() => console.log("SAVED"))
    }

    const handleAmendGoalDetails = (event, field, suitabilityReportGoalIndex) => {
        event.preventDefault();
        dispatch({type: changeAdditionalGoalDetail, payload: {field: field, index: suitabilityReportGoalIndex, value: event.target.value}})
    }

    const yearsToRetirement = (timeHorizon) => {
        // below is set as: age of retirement - (current date - birth date)/(number of miliseconds in a year)
		let _yearsToRetirement = timeHorizon - (Math.abs(new Date() - state.customerInfo.customers.customer1.customerDoB.toDate())/(31536000000)).toFixed()
		if(_yearsToRetirement < 0){
			_yearsToRetirement = 0
		} 
		return _yearsToRetirement
	}

    useEffect(() => {

    }, [])

	return <div className={styles.suitabilityPanel}>
        <div className={styles.pageTitleText}>Additional Goal Details</div>
        <div className="addGoalInstructions">Provide additional soft facts below:</div>
        <div className="goalCardsEditDetails">
        <div className={'topRowGoalSummaries'}>
            {!status !== "success" && state.customerGoals && state.customerGoals.length > 0 && <div className={'topRowGoalSummariesFirst'}>
                <AmmoniteSummaryGoalCard
                    goalType={state.customerGoals[0].goalType}
                    goalName={state.customerGoals[0].goalName}
                    goalImportance={state.customerGoals[0].goalImportance}
                    amountRequiredToday={state.customerGoals[0].amountRequiredToday}
                    goalAmount={state.customerGoals[0].goalAmount}
                    goalTimeHorizon={yearsToRetirement(state.customerGoals[0].goalTimeHorizon)}
                    showSoftFacts
                    softFacts={state.customerGoals[0].softFacts}
                    suitabilityReportGoalIndex={0}
	                handleAmendGoalDetails={(event, field, suitabilityReportGoalIndex) => handleAmendGoalDetails(event, field, suitabilityReportGoalIndex)}
                />
            </div>}
            {
                <div className={'topRowGoalSummariesLast'}>
                    {!status !== "success" && state.customerGoals
                        .slice(1, 3)
                        .map((item, index) => (
                            <AmmoniteSummaryGoalCard
                            goalType={state.customerGoals[index + 1].goalType}
                            goalName={state.customerGoals[index + 1].goalName}
                            goalImportance={state.customerGoals[index + 1].goalImportance}
                            amountRequiredToday={state.customerGoals[index + 1].amountRequiredToday}
                            goalAmount={state.customerGoals[index + 1].goalAmount}
                            goalTimeHorizon={state.customerGoals[index + 1].goalTimeHorizon}
                            showSoftFacts
                            editGoal
                            softFacts={state.customerGoals[index + 1].softFacts}
                            suitabilityReportGoalIndex={index + 1}
                            handleAmendGoalDetails={(event, field, suitabilityReportGoalIndex) => handleAmendGoalDetails(event, field, suitabilityReportGoalIndex)}
                            />
                        ))}
                </div>
            }
        </div>
        <div className={'bottomRowGoalSummaries'}>
					{
						<div className={'topRowGoalSummariesLast'}>
							{!status !== "success" && state.customerGoals
                        .slice(3, 6)
								.map((item, index) => (
                                    <AmmoniteSummaryGoalCard
                            goalType={state.customerGoals[index + 3].goalType}
                            goalName={state.customerGoals[index + 3].goalName}
                            goalImportance={state.customerGoals[index + 3].goalImportance}
                            amountRequiredToday={state.customerGoals[index + 3].amountRequiredToday}
                            goalAmount={state.customerGoals[index + 3].goalAmount}
                            goalTimeHorizon={state.customerGoals[index + 3].goalTimeHorizon}
                            showSoftFacts
                            softFacts={state.customerGoals[index + 3].softFacts}
                            suitabilityReportGoalIndex={index + 3}
                            handleAmendGoalDetails={(event, field, suitabilityReportGoalIndex) => handleAmendGoalDetails(event, field, suitabilityReportGoalIndex)}
                            />
                                ))}
						</div>
					}
				</div>
        </div>

        <div className={styles.menuBar}>
            <div className={styles.menuChips}>
                <SuitabilityReportNavigation
                    showSave
                    handleExit={exitHandler}
                    handleSave={saveHandler}
                />
            </div>
        </div>
    </div>;
}

export default AdditionalGoalDetails;

