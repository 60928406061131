import React from "react";

import { getInvestmentSolutions } from "./suitabilityReportDerivedData";
import { investmentProviderData } from "../../Utils/data/investmentProviderData";

export const getISAGIACharges = (
  isasAdvice,
  giasAdvice,
  totalCharge,
  customerGoals,
  clientAttitudeToRisk
) => {
  let _cisas = [
    ...getInvestmentSolutions(
      isasAdvice,
      "isa",
      customerGoals,
      clientAttitudeToRisk
    ),
    ...getInvestmentSolutions(
      giasAdvice,
      "gia",
      customerGoals,
      clientAttitudeToRisk
    ),
  ];
  let _totalISAGIACharges = 0;
  let _totalISAGIAValue = 0;
  let _totalISAGIAChecked = { isa: [], gia: [] };

  for (let isa of _cisas) {
    let _providerValue = parseInt(isa.totalInitialAmount);

    if (totalCharge === "discretionary" && isa.provider === "Cazenove") {
      return true;
    } else if (
      totalCharge === "discretionary" &&
      isa === _cisas[_cisas.length - 1]
    ) {
      return false;
    }

    if (!_totalISAGIAChecked[isa.type].includes(isa.typeIndex)) {
      _totalISAGIAChecked[isa.type].push(isa.typeIndex);
      _totalISAGIAValue += _providerValue;

      if (isa.provider === "Aviva") {
        let avivaBands = {
          bandA: 0,
          bandB: 0,
        };
        if (isa.totalInitialAmount < 399999) {
          avivaBands.bandA += _providerValue * 0.0025;
        } else {
          _providerValue -= 399999;
          avivaBands.bandA = 399999 * 0.0025;
          avivaBands.bandB = _providerValue * 0.0015;
        }
        _totalISAGIACharges += parseInt(avivaBands.bandA + avivaBands.bandB);
      } else if (isa.provider === "Cazenove") {
        _totalISAGIACharges += parseInt(_providerValue * 0.009);
      }
    }
  }

  if (totalCharge) {
    return _totalISAGIACharges;
  }

  let _calculatedISAGIACHARGE = (_totalISAGIACharges / _totalISAGIAValue) * 100;

  return _calculatedISAGIACHARGE;
};

export const getISAGIAPensionCharges = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  totalCharge,
  customerGoals,
  clientAttitudeToRisk
) => {
  let _cisas = [
    ...getInvestmentSolutions(
      pensionsAdvice,
      "pension",
      customerGoals,
      clientAttitudeToRisk
    ),
    ...getInvestmentSolutions(
      isasAdvice,
      "isa",
      customerGoals,
      clientAttitudeToRisk
    ),
    ...getInvestmentSolutions(
      giasAdvice,
      "gia",
      customerGoals,
      clientAttitudeToRisk
    ),
  ];
  let _totalISAGIACharges = 0;
  let _totalISAGIAValue = 0;
  let _totalISAGIAChecked = { pension: [], isa: [], gia: [] };

  for (let isa of _cisas) {
    const _providerValue = parseInt(
      (isa.totalInitialAmount * isa.goalAllocationPercent) / 100
    );
    const _investmentCharges =
      investmentProviderData["investmentStrategy"][isa.investmentSolution][
        "Charges per annum"
      ];
    if (_investmentCharges) {
      const _totalChargeForInvestment = _providerValue * _investmentCharges;
      if (!_totalISAGIAChecked[isa.type].includes(isa.typeIndex)) {
        _totalISAGIAValue += isa.totalInitialAmount;
        _totalISAGIAChecked[isa.type].push(isa.typeIndex);
      }
      _totalISAGIACharges += _totalChargeForInvestment;
    }
  }

  if (totalCharge) {
    return _totalISAGIACharges.toFixed(2);
  }
  return ((_totalISAGIACharges / _totalISAGIAValue) * 100).toFixed(2);
};

export const getRecommendedPolicy = (
  pensionsAdvice,
  owner,
  customerGoals,
  clientAttitudeToRisk
) => {
  const pensionsAdviceForOwner = pensionsAdvice.filter(
    (pension) => pension.owner === owner
  );

  const providerCharge = getPensionCharges(
    pensionsAdviceForOwner,
    false,
    customerGoals,
    clientAttitudeToRisk
  );

  const investmentCharge = getISAGIAPensionCharges(
    pensionsAdviceForOwner,
    [],
    [],
    false,
    customerGoals,
    clientAttitudeToRisk
  );

  console.log(owner, providerCharge, isNaN(investmentCharge) ? true : 0);

  const policyAmount =
    parseFloat(providerCharge) +
    parseFloat(isNaN(investmentCharge) ? 0 : investmentCharge);

  return policyAmount;
};

export const getPensionCharges = (
  pensionsAdvice,
  totalCharge,
  customerGoals,
  clientAttitudeToRisk
) => {
  let _cpensions = getInvestmentSolutions(
    pensionsAdvice,
    "pension",
    customerGoals,
    clientAttitudeToRisk
  );
  let _pensionProviders = [];
  let _totalPensionCharges = 0;
  let _totalPensionValue = 0;
  let _totalPensionChecked = { pension: [] };
  for (let pension of _cpensions) {
    if (!_totalPensionChecked[pension.type].includes(pension.typeIndex)) {
      _totalPensionChecked[pension.type].push(pension.typeIndex);

      let _providerPensionValue = parseInt(pension.totalInitialAmount);
      _totalPensionValue += _providerPensionValue;
      if (pension.provider === "Aviva") {
        _pensionProviders.push("Aviva");
        let avivaPensionBands = {
          bandA: 0,
          bandB: 0,
          bandC: 0,
          bandD: 0,
        };
        if (pension.totalInitialAmount < 29999) {
          avivaPensionBands.bandA += _providerPensionValue * 0.004;
        } else if (pension.totalInitialAmount < 249999) {
          _providerPensionValue -= 29999;
          avivaPensionBands.bandA = 29999 * 0.004;
          avivaPensionBands.bandB = _providerPensionValue * 0.0035;
        } else if (pension.totalInitialAmount < 399999) {
          _providerPensionValue -= 399999;
          avivaPensionBands.bandA = 29999 * 0.004;
          avivaPensionBands.bandB = 219999 * 0.0035;
          avivaPensionBands.bandC = _providerPensionValue * 0.0025;
        } else {
          _providerPensionValue -= 400000;
          avivaPensionBands.bandA = 29999 * 0.004;
          avivaPensionBands.bandB = 219999 * 0.0035;
          avivaPensionBands.bandC = 149999 * 0.0025;
          avivaPensionBands.bandD = _providerPensionValue * 0.0015;
        }

        _totalPensionCharges +=
          avivaPensionBands.bandA +
          avivaPensionBands.bandB +
          avivaPensionBands.bandC +
          avivaPensionBands.bandD;
      } else if (pension.provider === "Curtis Banks") {
        _pensionProviders.push("Curtis Banks");
        _totalPensionCharges += 250;
      }
    }
  }

  let _calculatedPENSIONCHARGE = (
    (_totalPensionCharges / _totalPensionValue) *
    100
  ).toFixed(2);

  if (totalCharge === true) {
    return _totalPensionCharges.toFixed(2);
  } else if (totalCharge === "providers") {
    return {
      totalCharge: _calculatedPENSIONCHARGE,
      providers: _pensionProviders,
    };
  }
  return _calculatedPENSIONCHARGE;
};

export const getTotalPortfolioValue = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice
) => {
  let _totalPensionAdviceValue = 0;
  let _totalIsaAdviceValue = 0;
  let _totalGiaAdviceValue = 0;

  if (isasAdvice.length > 0) {
    _totalIsaAdviceValue = isasAdvice
      .map((isa) => {
        return (
          parseInt(isa.totalTransfersIn) + parseInt(isa.lumpSumContribution)
        );
      })
      .reduce((a, b) => a + b, 0);
    if (isNaN(_totalIsaAdviceValue)) {
      _totalIsaAdviceValue = 0;
    }
  }

  if (giasAdvice.length > 0) {
    _totalGiaAdviceValue = giasAdvice
      .map((gia) => {
        return (
          parseInt(gia.totalTransfersIn) + parseInt(gia.lumpSumContribution)
        );
      })
      .reduce((a, b) => a + b, 0);
    if (isNaN(_totalGiaAdviceValue)) {
      _totalGiaAdviceValue = 0;
    }
  }

  if (pensionsAdvice.length > 0) {
    _totalPensionAdviceValue = pensionsAdvice
      .map((pension) => {
        return (
          parseInt(pension.totalTransfersIn) +
          parseInt(pension.grossLumpSumEmployerContribution) +
          parseInt(pension.grossLumpSumPersonalContribution)
        );
      })
      .reduce((a, b) => a + b, 0);
    if (isNaN(_totalPensionAdviceValue)) {
      _totalPensionAdviceValue = 0;
    }
  }

  return parseInt(
    _totalPensionAdviceValue + _totalIsaAdviceValue + _totalGiaAdviceValue
  );
};

export const getTotalImplementationCharge = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice
) => {
  const _totalPortfolioValue = getTotalPortfolioValue(
    pensionsAdvice,
    isasAdvice,
    giasAdvice
  );

  if (_totalPortfolioValue < 100000) {
    return 2.0;
  } else {
    return (200000 + (_totalPortfolioValue - 100000)) / _totalPortfolioValue;
  }
};

export const getTotalImplementationChargeOnPot = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice
) => {
  const _totalImplementationCharge = getTotalImplementationCharge(
    pensionsAdvice,
    isasAdvice,
    giasAdvice
  );
  const _totalPortfolioValue = getTotalPortfolioValue(
    pensionsAdvice,
    isasAdvice,
    giasAdvice
  );

  return (_totalImplementationCharge * _totalPortfolioValue * 0.01).toFixed(2);
};

export const getTotalPoundCharge = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  customerGoals,
  clientAttitudeToRisk
) => {
  let _ammoniteCharge = 0.0075;

  let _totalAmountUnderAdvice = [
    ...getInvestmentSolutions(
      pensionsAdvice,
      "pension",
      customerGoals,
      clientAttitudeToRisk
    ),
    ...getInvestmentSolutions(
      isasAdvice,
      "isa",
      customerGoals,
      clientAttitudeToRisk
    ),
    ...getInvestmentSolutions(
      giasAdvice,
      "gia",
      customerGoals,
      clientAttitudeToRisk
    ),
  ].reduce(
    (sum, investment) =>
      sum +
      (investment.totalInitialAmount * investment.goalAllocationPercent) / 100,
    0
  );

  let _totalCharge =
    parseInt(
      getPensionCharges(
        pensionsAdvice,
        true,
        customerGoals,
        clientAttitudeToRisk
      )
    ) +
    parseInt(
      getISAGIACharges(
        isasAdvice,
        giasAdvice,
        true,
        customerGoals,
        clientAttitudeToRisk
      )
    ) +
    parseInt(
      getISAGIAPensionCharges(
        pensionsAdvice,
        isasAdvice,
        giasAdvice,
        true,
        customerGoals,
        clientAttitudeToRisk
      )
    ) +
    _ammoniteCharge * _totalAmountUnderAdvice;

  return _totalCharge.toFixed(2);
};

export const getTotalWeightedCharge = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  customerGoals,
  clientAttitudeToRisk
) => {
  const _totalPoundCharge = getTotalPoundCharge(
    pensionsAdvice,
    isasAdvice,
    giasAdvice,
    customerGoals,
    clientAttitudeToRisk
  );
  const _totalPortfolioValue = getTotalPortfolioValue(
    pensionsAdvice,
    isasAdvice,
    giasAdvice
  );
  let _totalWeightedCharge = 0;
  if (
    !isNaN(_totalPoundCharge) &&
    !isNaN(_totalPortfolioValue) &&
    _totalPoundCharge > 0 &&
    _totalPortfolioValue > 0
  ) {
    _totalWeightedCharge = (_totalPoundCharge / _totalPortfolioValue) * 100;
  }

  return _totalWeightedCharge;
};

export const getAmount = (
  pensionsAdvice,
  isasAdvice,
  giasAdvice,
  cashAdvice,
  goalIndex,
  isAmountUnder
) => {
  let _amountPensions = 0;
  if (pensionsAdvice.length > 0)
    _amountPensions = getAmountPerGoal(
      pensionsAdvice,
      goalIndex,
      isAmountUnder ? 0 : 2
    );
  let _amountISAs = 0;
  if (isasAdvice.length > 0)
    _amountISAs = getAmountPerGoal(
      isasAdvice,
      goalIndex,
      isAmountUnder ? 1 : 3
    );
  let _amountGIAs = 0;
  if (giasAdvice.length > 0)
    _amountGIAs = getAmountPerGoal(
      giasAdvice,
      goalIndex,
      isAmountUnder ? 1 : 3
    );
  let _amountAdviceCash = 0;
  if (cashAdvice.length > 0)
    _amountAdviceCash = getAmountPerGoal(
      cashAdvice,
      goalIndex,
      isAmountUnder ? 1 : 3
    );

  if (isNaN(_amountPensions)) {
    _amountPensions = 0;
  }
  if (isNaN(_amountISAs)) {
    _amountISAs = 0;
  }
  if (isNaN(_amountGIAs)) {
    _amountGIAs = 0;
  }
  if (isNaN(_amountAdviceCash)) {
    _amountAdviceCash = 0;
  }

  return _amountPensions + _amountISAs + _amountGIAs + _amountAdviceCash;
};

export const getCurrentValueNewPension = (pension) => {
  const _pensionValue =
    parseInt(pension.totalTransfersIn) +
    parseInt(pension.grossLumpSumEmployerContribution) +
    parseInt(pension.grossLumpSumPersonalContribution);
  return _pensionValue;
};

export const getCurrentValueISAGIACash = (investment) => {
  return (
    parseInt(investment.totalTransfersIn) +
    parseInt(investment.lumpSumContribution)
  );
};

export const getAmountPerGoal = (investments, goalIndex, amountType) => {
  let currentValue = 0;
  return investments
    .map((investment) => {
      const goalAllocationPercent = parseInt(
        investment.goalAllocations[goalIndex].goalAllocationPercent
      );
      switch (amountType) {
        case 0:
          currentValue = parseInt(getCurrentValueNewPension(investment));
          break;
        case 1:
          currentValue = parseInt(getCurrentValueISAGIACash(investment));
          break;
        case 2:
          currentValue =
            parseInt(investment.grossMonthlyEmployerContribution) +
            parseInt(investment.grossMonthlyPersonalContribution);
          break;
        case 3:
          currentValue = parseInt(investment.monthlyContribution);
          break;
      }
      return (currentValue * goalAllocationPercent) / 100;
    })
    .reduce((a, b) => a + b, 0);
};
