import React from "react";
import * as d3 from "d3";
import d3Tip from "d3-tip";
import RetirementIcon from "../../Assets/SVG/ReportIcons/ChartIcons/retirement/retirementSummaryIcon.svg";
import FamilyIcon from "../../Assets/SVG/ReportIcons/ChartIcons/family/familySummaryIcon.svg";
import EducationIcon from "../../Assets/SVG/ReportIcons/ChartIcons/education/educationSummaryIcon.svg";
import PropertyIcon from "../../Assets/SVG/ReportIcons/ChartIcons/property/propertySummaryIcon.svg";
import OtherIcon from "../../Assets/SVG/ReportIcons/ChartIcons/other/otherSummaryIcon.svg";
import CareFeesIcon from "../../Assets/SVG/ReportIcons/ChartIcons/care/careFeesSummaryIcon.svg";
import LongTermInvestmentIcon from "../../Assets/SVG/ReportIcons/ChartIcons/longTermInvestment/longTermInvestmentSummaryIcon.svg";

const MARGIN = { TOP: 150, BOTTOM: 0, LEFT: 50, RIGHT: 50 };
const WIDTH = 555 - MARGIN.LEFT - MARGIN.RIGHT;
const HEIGHT = 567 - MARGIN.TOP - MARGIN.BOTTOM;

const YEAR = 2021;

const getGoalColour = (goalType) => {
  switch (goalType) {
    case "Retirement pot":
      return "#BF802F";
    case "Education":
      return "#69306D";
    case "Property":
      return "#247BA0";
    case "Family":
      return "#3F762C";
    case "Long Term Investment":
      return "#373986";
    case "Care Fees":
      return "#0C3957";
    case "Other":
      return "#F25F5C";
    default:
      return "#69306D";
  }
};

const getGoalTypeIcon = (goalType) => {
  switch (goalType) {
    case "Retirement pot":
      return "url(#retirement)";
    case "Education":
      return "url(#education)";
    case "Property":
      return "url(#property)";
    case "Family":
      return "url(#family)";
    case "Long Term Investment":
      return "url(#longTermInvestment)";
    case "Care Fees":
      return "url(#careFees)";
    case "Other":
      return "url(#other)";
    default:
      return "#69306D";
  }
};

class D3Chart {
  constructor(element, data) {
    let vis = this;

    if (data === undefined) {
      data = [];
    }

    vis.data = data;

    vis.g = d3
      .select(element)
      .append("svg")
      .attr("width", WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
      .attr("height", HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
      .attr("transform", `translate(0, ${MARGIN.TOP})`);

    vis.x = d3.scaleLinear().range([MARGIN.LEFT, WIDTH + MARGIN.RIGHT]);

    vis.y = d3.scaleLinear().range([HEIGHT, 0]);

    vis.goalDotX = d3
      .scaleLinear()
      .range([MARGIN.LEFT + MARGIN.RIGHT + 120, WIDTH - 120]);

    vis.tooltipsX = d3.scaleLinear().range([MARGIN.LEFT, WIDTH - 30]);

    vis.tooltipsY = d3.scaleLinear().range([HEIGHT, 0]);

    vis.goalBoxes = d3.scaleLinear().range([MARGIN.LEFT, WIDTH - 30]);

    vis.xAxisGroup = vis.g
      .append("g")
      .attr("transform", `translate(0, ${HEIGHT - MARGIN.BOTTOM})`);

    vis.g
      .append("text")
      .attr("x", WIDTH / 2)
      .attr("y", HEIGHT - MARGIN.BOTTOM + 60)
      .text("Year")
      .attr("fill", "#BDBDBD")
      .attr("id", "xAxisTitle")
      .append("style")
      .attr("type", "text/css")
      .text(
        "@import url('https://fonts.googleapis.com/css?family=Roboto:400,300,600,700,800');"
      )
      .attr("text-anchor", "middle");

    vis.groupedByGoalTimeHorizon = data.reduce(
      (hash, obj) => ({
        ...hash,
        [obj["goalTimeHorizon"]]: (hash[obj["goalTimeHorizon"]] || []).concat(
          obj
        ),
      }),
      {}
    );
    for (let group of Object.values(vis.groupedByGoalTimeHorizon)) {
      let _cy = 0;
      for (let goal of group) {
        goal.cy = _cy;
        _cy += 15;
      }
    }

    vis.matchGoalTimeHorizonToGroup = (d) => {
      return (
        HEIGHT -
        MARGIN.BOTTOM -
        vis.groupedByGoalTimeHorizon[d.goalTimeHorizon].find(
          (el) => el.goalName === d.goalName
        ).cy
      );
    };

    vis.getTickValues = (year, maxYear) => {
      const _year = parseInt(year);
      const _maxYear = parseInt(maxYear);
      let tickValues = [];

      if (_maxYear < 10) {
        for (let i = 0; i <= _maxYear; i++) {
          tickValues.push(_year + i);
        }
        return [...new Set(tickValues)];
      }

      if (_maxYear < 20) {
        for (let i = 0; i <= _maxYear; i += 5) {
          tickValues.push(_year + i);
        }
        return [...new Set(tickValues)];
      }

      if (_year % 10 !== 0) {
        tickValues.push(Math.round(_year / 10) * 10);
      }

      // if (parseInt(_year + _maxYear) % 10 !== 0) {
      //   tickValues.push(Math.round(parseInt(_year + _maxYear) / 10) * 10);
      // }

      for (let y = _year; y < _year + _maxYear; y++) {
        if (y % 10 === 0) {
          tickValues.push(y);
        }
      }

      if (_maxYear + _year === 10 + tickValues[tickValues.length - 1]) {
        tickValues.push(tickValues[tickValues.length - 1] + 10);
      }

      return [...new Set(tickValues)];
    };

    vis.getScale = (minGoalAmount, maxGoalAmount) => {
      if (maxGoalAmount / minGoalAmount <= 1.5) {
        return d3.scaleLinear().range([0, 20]);
      } else if (maxGoalAmount / minGoalAmount < 2.1) {
        return d3.scaleSqrt().range([0, 25]);
      } else if (maxGoalAmount / minGoalAmount <= 3.8) {
        return d3.scaleSqrt().range([0, 20]);
      } else if (maxGoalAmount / minGoalAmount <= 10) {
        return d3.scaleSqrt().range([0, 15]);
        // } else if (maxGoalAmount / minGoalAmount <= 17) {
        //   return d3.scaleSqrt().range([0, 10]);
      } else if (maxGoalAmount / minGoalAmount <= 10000000) {
        return d3.scaleLog().range([0, 40]);
      }
      return d3.scaleLog().range([0, 20]);
    };

    vis.update();
  }

  update(data) {
    let vis = this;

    if (data) {
      vis.data = data;
    }

    const MAXXYEARS = d3.max(vis.data, (d) => d.goalTimeHorizon);
    const MINGOALAMOUNT = d3.min(vis.data, (d) => d.goalAmount) - 1;
    const MAXGOALAMOUNT = d3.max(vis.data, (d) => d.goalAmount);

    vis.x.domain([YEAR, MAXXYEARS + YEAR]).nice(); //.nice() makes sure start and end values on axis
    vis.y.domain([HEIGHT, 0]);

    vis.ga = vis
      .getScale(MINGOALAMOUNT, MAXGOALAMOUNT)
      .domain([MINGOALAMOUNT, MAXGOALAMOUNT])
      .nice();

    vis.goalDotX.domain([0, 70]).nice();
    vis.tooltipsX.domain([0, MAXXYEARS]);
    vis.tooltipsY.domain([0, 0]);
    vis.goalBoxes.domain([0, 3]).nice();

    const xAxisCall = d3
      .axisBottom(vis.x)
      .tickValues(vis.getTickValues(YEAR, MAXXYEARS))
      .tickFormat(d3.format("d"));

    vis.g
      .selectAll("#xAxisTitle")
      .style("font-family", "Roboto")
      .style("font-size", "32px")
      .style("font-weight", "bold")
      .attr("fill", "#7A7A7A");

    //Style axis text
    vis.xAxisGroup
      .attr("color", "#BDBDBD")
      .style("font-size", "22px")
      .style("font-weight", "bold")
      .call(xAxisCall);

    //Style axis ticks
    vis.xAxisGroup
      .selectAll("line")
      .style("stroke", "#BDBDBD")
      .style("strokeWidth", "5px");

    //Style axis line
    vis.xAxisGroup
      .selectAll("path")
      .style("stroke", "#BDBDBD")
      .style("strokeWidth", "3px");

    // JOIN
    const axisCircles = vis.g
      .selectAll("#axisDot")
      .data(vis.data, (d) => d.goalName);

    // EXIT
    axisCircles
      .exit()
      .transition(2000)
      .attr("cy", (d) => vis.matchGoalTimeHorizonToGroup(d))
      .remove();

    const defs = vis.g.selectAll("#goalGlyph").append("defs");

    defs
      .append("pattern")
      .attr("id", "retirement")
      .attr("height", "100%")
      .attr("width", "100%")
      .attr("patternContentUnits", "objectBoundingBox")
      .attr("patternContentUnits", "objectBoundingBox")
      .append("image")
      .attr("height", 1)
      .attr("width", 1)
      .attr("preserveAspectRatio", "none")
      .attr("xmlns:xlink", "http://www.w3.org/1999/xlink")
      .attr("xlink:href", RetirementIcon);

    defs
      .append("pattern")
      .attr("id", "family")
      .attr("height", "100%")
      .attr("width", "100%")
      .attr("patternContentUnits", "objectBoundingBox")
      .attr("patternContentUnits", "objectBoundingBox")
      .append("image")
      .attr("height", 1)
      .attr("width", 1)
      .attr("preserveAspectRatio", "none")
      .attr("xmlns:xlink", "http://www.w3.org/1999/xlink")
      .attr("xlink:href", FamilyIcon);

    defs
      .append("pattern")
      .attr("id", "education")
      .attr("height", "100%")
      .attr("width", "100%")
      .attr("patternContentUnits", "objectBoundingBox")
      .attr("patternContentUnits", "objectBoundingBox")
      .append("image")
      .attr("height", 1)
      .attr("width", 1)
      .attr("preserveAspectRatio", "none")
      .attr("xmlns:xlink", "http://www.w3.org/1999/xlink")
      .attr("xlink:href", EducationIcon);

    defs
      .append("pattern")
      .attr("id", "property")
      .attr("height", "100%")
      .attr("width", "100%")
      .attr("patternContentUnits", "objectBoundingBox")
      .attr("patternContentUnits", "objectBoundingBox")
      .append("image")
      .attr("height", 1)
      .attr("width", 1)
      .attr("preserveAspectRatio", "none")
      .attr("xmlns:xlink", "http://www.w3.org/1999/xlink")
      .attr("xlink:href", PropertyIcon);

    defs
      .append("pattern")
      .attr("id", "other")
      .attr("height", "100%")
      .attr("width", "100%")
      .attr("patternContentUnits", "objectBoundingBox")
      .attr("patternContentUnits", "objectBoundingBox")
      .append("image")
      .attr("height", 1)
      .attr("width", 1)
      .attr("preserveAspectRatio", "none")
      .attr("xmlns:xlink", "http://www.w3.org/1999/xlink")
      .attr("xlink:href", OtherIcon);

    defs
      .append("pattern")
      .attr("id", "careFees")
      .attr("height", "100%")
      .attr("width", "100%")
      .attr("patternContentUnits", "objectBoundingBox")
      .attr("patternContentUnits", "objectBoundingBox")
      .append("image")
      .attr("height", 1)
      .attr("width", 1)
      .attr("preserveAspectRatio", "none")
      .attr("xmlns:xlink", "http://www.w3.org/1999/xlink")
      .attr("xlink:href", CareFeesIcon);

    defs
      .append("pattern")
      .attr("id", "longTermInvestment")
      .attr("height", "100%")
      .attr("width", "100%")
      .attr("patternContentUnits", "objectBoundingBox")
      .attr("patternContentUnits", "objectBoundingBox")
      .append("image")
      .attr("height", 1)
      .attr("width", 1)
      .attr("preserveAspectRatio", "none")
      .attr("xmlns:xlink", "http://www.w3.org/1999/xlink")
      .attr("xlink:href", LongTermInvestmentIcon);

    // UPDATE
    axisCircles
      .attr("cx", (d) => vis.x(d.goalTimeHorizon + YEAR))
      .attr("cy", HEIGHT)
      .transition(1000)
      .attr("cy", (d) => vis.matchGoalTimeHorizonToGroup(d));

    // ENTER
    axisCircles
      .enter()
      .append("circle")
      .attr("id", "axisDot")
      .attr("cy", (d) => HEIGHT)
      .attr("cx", (d) => vis.x(d.goalTimeHorizon + YEAR))
      .attr("r", 6)
      .attr("stroke", (d) => getGoalColour(d.goalType))
      .attr("strokeWidth", 2)
      .attr("fill", "white")
      .transition(1000)
      .attr("cy", (d) => vis.matchGoalTimeHorizonToGroup(d));

    const tip = d3Tip()
      .attr("class", "d3-tip")
      // .style("font-family", "Roboto")
      // .style("font-size", "14px")
      .style("color", "#0C3957")
      .style("width", "100px")
      // .style("color", "#BDBDBD")
      .style("font-size", "22px")
      .style("font-weight", "bold")
      .html((d) => {
        let text = `<center>${d.goalName}</center><center>£${d.goalAmount}</center>`;
        return text;
      });

    vis.g.call(tip);

    const goalGlyphs = vis.g.selectAll("#goalGlyph").data(vis.data);

    goalGlyphs.enter().append("g").attr("id", "goalGlyph");

    const findMultiplyer = (goal) => {
      if (data) {
        return 2;
      }
      return 0.1;
    };

    const simulation = d3
      .forceSimulation(vis.data)
      .force("charge", d3.forceManyBody().strength(-400))
      .force(
        "x",
        d3.forceX().x(function (d) {
          return vis.goalDotX(d.goalTimeHorizon * findMultiplyer(d));
        })
      )
      .force(
        "y",
        d3.forceY().y(function (d) {
          return HEIGHT / 1.8;
        })
      )
      .force(
        "collision",
        d3.forceCollide().radius(function (d) {
          return vis.ga(d.goalAmount);
        })
      )
      .on("tick", ticked);

    simulation.force("center", d3.forceCenter((WIDTH + 50) / 2, HEIGHT / 1.5));

    function ticked() {
      var u = vis.g.selectAll("#goalCircle").data(vis.data);

      u.enter()
        .append("circle")
        .attr("id", "goalCircle")
        .attr("opacity", 0.5)
        .attr("r", function (d) {
          return vis.ga((MAXGOALAMOUNT / MINGOALAMOUNT) * d.goalAmount);
        })
        .attr("fill", (d) => getGoalTypeIcon(d.goalType))
        .on("mouseover.text", tip.show)
        .on("mouseout.text", tip.hide)
        .on("mouseover.focus", function (d) {
          d3.selectAll("circle").style("opacity", 0.2);
          d3.select(this).style("opacity", 1);
          d3.select(`#axisDot[stroke="${getGoalColour(d.goalType)}"]`).style(
            "opacity",
            1
          );
        })
        .on("mouseout.focus", function (d) {
          d3.selectAll("circle").style("opacity", 1);
        })
        .merge(u)
        .attr("cx", function (d) {
          return d.x;
        })
        .attr("cy", function (d) {
          return d.y;
        });

      u.exit().remove();
    }
  }
}

export default D3Chart;
