//set session date
export const refreshContextFromCache = "refreshContextFromCache";
export const setDirty = "setDirty";
export const setSessionDate = "setSessionDate";
//Financial healthcheck actions
export const reset = "reset";
export const setWill = "setWill";
export const setEmergencyCash = "setEmergencyCash";
export const setShortTermDebt = "setShortTermDebt";
export const setHasMortgage = "setHasMortgage";
export const setTimeUntilMortgageDealEnds = "setTimeUntilMortgageDealEnds";
export const setLifeCover = "setLifeCover";
export const setIncomeProtection = "setIncomeProtection";
export const setKnowsStatePensionAge = "setKnowsStatePensionAge";
export const setPrevPensions = "setPrevPensions";
export const setNoOfPrevPensions = "setNoOfPrevPensions";
export const setFinancialCheckValid = "setFinancialCheckValid";
export const setStocksAdvice = "setStocksAdvice";
export const setCashOrContribution = "setCashOrContribution";
export const setEnvironmentPriority = "setEnvironmentPriority";
export const resetFinancialHealthCheck = "resetFinancialHealthCheck";
//Covered actions
export const setPensionsCovered = "setPensionsCovered";
export const setMortgageCovered = "setMortgageCovered";
//Advisor actions
export const setSpeakToPensionsAdvisorAbout = "setSpeakToPensionsAdvisorAbout";
export const setSpeakToMortgageAndProtectionAdvisorAbout =
  "setSpeakToMortgageAndProtectionAdvisorAbout";
//Customer Info actions
export const setCustomerInfo = "setCustomerInfo";
export const setGenderType = "setGenderType";
export const setHasChildren = "setHasChildren";
export const setCouple = "setCouple";
export const setCustomerWorkSituation = "setCustomerWorkSituation";
export const setCustomerFirstName = "setCustomerFirstName";
export const setCustomerLastName = "setCustomerLastName";
export const setCustomerDoB = "setPlayersetCustomerDoBAge";
export const resetCustomerInfo = "resetCustomerInfo";
export const resetSecondCustomer = "resetSecondCustomer";
export const setStatePensionAge = "setStatePensionAge";
//PlayerType actions
export const setSelected = "setSelected";
//PlayerGoal actions
export const setSelectedGoal = "setSelectedGoal";
export const setCustomerGoals = "setCustomerGoals";
export const crementGoalTimeHorizon = "crementGoalTimeHorizon";
export const setGoalTodaysAmount = "setGoalTodaysAmount";
export const setGoalImportance = "setGoalImportance";
export const setCustomerGoalsActiveStep = "setCustomerGoalsActiveStep";
export const setCustomerGoalsActiveStepInflation =
  "setCustomerGoalsActiveStepInflation";
export const resetGoals = "resetGoals";
//Retirement actions
export const setRetirementAge = "setRetirementAge";
export const setRetirementSpending = "setRetirementSpending";
export const setAnnualRetirementSpending = "setAnnualRetirementSpending";
export const setRetirementAmountRequiredToday =
  "setRetirementAmountRequiredToday";
export const setRetirementGoalAmount = "setRetirementGoalAmount";
export const resetRetirement = "resetRetirement";
export const setAllExtras = "setAllExtras";
export const setRetirementSpendingBar = "setRetirementSpendingBar";
export const setNewComforts = "setComforts";
export const setNewLuxuries = "setLuxuries";
//Pension actions
export const setPension = "setPension";
export const setCustomerTaxRate = "setCustomerTaxRate";
export const setCustomerFees = "setCustomerFees";
//Ammonite actions
export const setCustomerEmail = "setCustomerEmail";
export const setCustomerTelephone = "setCustomerTelephone";
export const setMortgageAPriority = "setMortgageAPriority";
export const setPensionsAPriority = "setPensionsAPriority";
//Coach actions
export const setCoachUid = "setCoachUid";
export const setCoachName = "setCoachName";
