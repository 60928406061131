import React from 'react';

function AmmonitePlusButton({}) {
	return (
		<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1 18C1 8.61116 8.61116 1 18 1C27.3888 1 35 8.61116 35 18C35 27.3888 27.3888 35 18 35C8.61116 35 1 27.3888 1 18Z"
				fill="white"
				stroke="#3BB9C4"
				strokeWidth="2"
			/>
			<path
				d="M17 10.5C16.7239 10.5 16.5 10.7239 16.5 11V16.5H11C10.7239 16.5 10.5 16.7239 10.5 17V19C10.5 19.2761 10.7239 19.5 11 19.5H16.5V25C16.5 25.2761 16.7239 25.5 17 25.5H19C19.2761 25.5 19.5 25.2761 19.5 25V19.5H25C25.2761 19.5 25.5 19.2761 25.5 19V17C25.5 16.7239 25.2761 16.5 25 16.5H19.5V11C19.5 10.7239 19.2761 10.5 19 10.5H17Z"
				fill="#3BB9C4"
				stroke="#3BB9C4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default AmmonitePlusButton;
