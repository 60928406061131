import React from 'react';

function AmmonitePencilSVG({ colour, width, height, transx, transy, scale }) {
	const scaleFactor = 'translate(' + 0 + ' ' + 0 + ')' + 'scale(' + scale + ')';

	return (
		<svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g transform={scaleFactor}>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M17.2399 10.9843L9.16255 19.4098L8.78291 23.8438L13.0639 23.4373L21.1168 15.0283L17.2399 10.9843ZM25.8432 10.0933L21.9677 6.05233L19.1665 8.97433L23.0419 13.0183L25.8432 10.0933ZM7.3289 26.9938C7.28433 26.9983 7.24118 26.9998 7.19804 26.9998C6.81841 26.9998 6.45171 26.8438 6.18137 26.5603C5.87938 26.2453 5.72695 25.8073 5.76578 25.3648L6.31079 19.1083C6.37118 18.4228 6.66166 17.7763 7.12902 17.2888L20.0654 3.79334C21.0749 2.73734 22.9069 2.78984 23.9711 3.89834L27.9084 8.00534H27.9098C29.0113 9.15584 29.0545 10.9813 28.0076 12.0763L15.0697 25.5718C14.6024 26.0608 13.984 26.3623 13.3254 26.4253L7.3289 26.9938ZM7.19778 30H27.33C28.1209 30 28.768 30.675 28.768 31.5C28.768 32.325 28.1209 33 27.33 33H7.19778C6.40687 33 5.75977 32.325 5.75977 31.5C5.75977 30.675 6.40687 30 7.19778 30Z"
					fill={colour}
				/>
				<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="3" width="24" height="30">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M17.2399 10.9843L9.16255 19.4098L8.78291 23.8438L13.0639 23.4373L21.1168 15.0283L17.2399 10.9843ZM25.8432 10.0933L21.9677 6.05233L19.1665 8.97433L23.0419 13.0183L25.8432 10.0933ZM7.3289 26.9938C7.28433 26.9983 7.24118 26.9998 7.19804 26.9998C6.81841 26.9998 6.45171 26.8438 6.18137 26.5603C5.87938 26.2453 5.72695 25.8073 5.76578 25.3648L6.31079 19.1083C6.37118 18.4228 6.66166 17.7763 7.12902 17.2888L20.0654 3.79334C21.0749 2.73734 22.9069 2.78984 23.9711 3.89834L27.9084 8.00534H27.9098C29.0113 9.15584 29.0545 10.9813 28.0076 12.0763L15.0697 25.5718C14.6024 26.0608 13.984 26.3623 13.3254 26.4253L7.3289 26.9938ZM7.19778 30H27.33C28.1209 30 28.768 30.675 28.768 31.5C28.768 32.325 28.1209 33 27.33 33H7.19778C6.40687 33 5.75977 32.325 5.75977 31.5C5.75977 30.675 6.40687 30 7.19778 30Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask0)" />
			</g>
		</svg>
	);
}

export default AmmonitePencilSVG;
