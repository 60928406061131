import React from 'react';

function AmmoniteEnvironment() {
	return (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M21.9255 29.328C23.499 27.081 25.1355 23.7765 25.4625 19.5H29.895C29.3205 24.078 26.1615 27.855 21.9255 29.328ZM6.10355 19.5H10.5915C10.9005 23.793 12.516 27.084 14.1105 29.3415C9.85505 27.876 6.68105 24.0915 6.10355 19.5ZM14.157 6.64349C12.579 8.89049 10.9275 12.2055 10.5975 16.5H6.10355C6.68255 11.892 9.87905 8.09549 14.157 6.64349ZM13.6186 16.5C14.0776 11.592 16.5886 8.175 18.0391 6.5955C19.5391 8.202 22.0111 11.5875 22.4386 16.5H13.6186ZM13.62 19.5H22.443C21.984 24.4155 19.467 27.8325 18.018 29.412C16.4475 27.747 14.0385 24.408 13.62 19.5ZM29.895 16.5H25.467C25.1595 12.2265 23.562 8.946 21.975 6.6915C26.187 8.1765 29.3235 11.94 29.895 16.5ZM33 18C33 9.744 26.2935 3.027 18.042 3.003C18.036 3.003 18.03 3.0015 18.0225 3.0015C18.0165 3.0015 18.0075 3 18 3C9.729 3 3 9.7305 3 18C3 26.271 9.729 33 18 33C18.009 33 18.0165 32.9985 18.0225 32.9985C18.0255 32.9985 18.027 33 18.03 33C18.0345 33 18.039 32.997 18.042 32.997C26.2935 32.9745 33 26.256 33 18Z" fill="#3F762C"/>
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="30" height="30">
<path fillRule="evenodd" clipRule="evenodd" d="M21.9255 29.328C23.499 27.081 25.1355 23.7765 25.4625 19.5H29.895C29.3205 24.078 26.1615 27.855 21.9255 29.328ZM6.10355 19.5H10.5915C10.9005 23.793 12.516 27.084 14.1105 29.3415C9.85505 27.876 6.68105 24.0915 6.10355 19.5ZM14.157 6.64349C12.579 8.89049 10.9275 12.2055 10.5975 16.5H6.10355C6.68255 11.892 9.87905 8.09549 14.157 6.64349ZM13.6186 16.5C14.0776 11.592 16.5886 8.175 18.0391 6.5955C19.5391 8.202 22.0111 11.5875 22.4386 16.5H13.6186ZM13.62 19.5H22.443C21.984 24.4155 19.467 27.8325 18.018 29.412C16.4475 27.747 14.0385 24.408 13.62 19.5ZM29.895 16.5H25.467C25.1595 12.2265 23.562 8.946 21.975 6.6915C26.187 8.1765 29.3235 11.94 29.895 16.5ZM33 18C33 9.744 26.2935 3.027 18.042 3.003C18.036 3.003 18.03 3.0015 18.0225 3.0015C18.0165 3.0015 18.0075 3 18 3C9.729 3 3 9.7305 3 18C3 26.271 9.729 33 18 33C18.009 33 18.0165 32.9985 18.0225 32.9985C18.0255 32.9985 18.027 33 18.03 33C18.0345 33 18.039 32.997 18.042 32.997C26.2935 32.9745 33 26.256 33 18Z" fill="white"/>
</mask>
<g mask="url(#mask0)">
</g>
</svg>




	);
}

export default AmmoniteEnvironment;
