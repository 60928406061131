//React
import React from "react";

//External Libraries
import { Grid } from "@material-ui/core";
import moment from "moment";

//Component imports
import AmmoniteMenuCircleFilledCross from "../../../Assets/SVG/menuCircleFilledCross";
import AmmoniteMenuCircleTick from "../../../Assets/SVG/menuCircleTick";
import { getStatePensionAge } from "../../../Utils/data/getStatePensionAge";

//Style
import "./sessionCardContents.css";

function SessionDataCardAdditionalInfo({
  customer1Name,
  customer2Name,
  customer1DOB,
  customer2DOB,
  customer1Employment,
  customer2Employment,
  children,
  mortgagePriority,
  pensionsPriority,
  retirementSpending,
  financialCheck,
  couple,
}) {
  const tick = (
    <div className="circleSVG">
      <AmmoniteMenuCircleTick />
    </div>
  );

  const cross = (
    <div className="circleSVG">
      <AmmoniteMenuCircleFilledCross />
    </div>
  );

  const getCustomerWorkSituation = (workIndex) => {
    switch (workIndex) {
      case 0:
        return "Employed";
      case 1:
        return "Self-employed";
      case 2:
        return "Other";
    }
  };

  const getMortgageTerm = (termIndex) => {
    switch (termIndex) {
      case 1:
        return "Less than 6 months";
      case 2:
        return "6 to 12 months";
      case 3:
        return "More than 12 months";
    }
  };

  return (
    <Grid className="sessionCardText">
      <Grid container spacing={1}>
        <Grid item xs={6} md={4}>
          Customer 1 Name:
        </Grid>
        <Grid item xs={6} md={2}>
          {customer1Name ? customer1Name : "-"}
        </Grid>
        <Grid item xs={6} md={4}>
          Customer 2 Name:
        </Grid>
        <Grid item xs={6} md={2}>
          {couple ? customer2Name : "-"}
        </Grid>
        <Grid item xs={6} md={4}>
          Customer 1 DOB:
        </Grid>
        <Grid item xs={6} md={2}>
          {customer1DOB
            ? moment(customer1DOB.seconds * 1000).format("DD-MM-YYYY")
            : "-"}
        </Grid>
        <Grid item xs={6} md={4}>
          Customer 2 DOB:
        </Grid>
        <Grid item xs={6} md={2}>
          {customer2DOB
            ? moment(customer2DOB.seconds * 1000).format("DD-MM-YYYY")
            : "-"}
        </Grid>
        <Grid item xs={6} md={4}>
          Customer 1 Employment:
        </Grid>
        <Grid item xs={6} md={2}>
          {getCustomerWorkSituation(customer1Employment)}
        </Grid>
        <Grid item xs={6} md={4}>
          Customer 2 Employment:
        </Grid>
        <Grid item xs={6} md={2}>
          {couple ? getCustomerWorkSituation(customer2Employment) : "-"}
        </Grid>
        <Grid item xs={6} md={4}>
          Customer 1 SPA:
        </Grid>
        <Grid item xs={6} md={2}>
          {getStatePensionAge(customer1DOB)}
        </Grid>
        <Grid item xs={6} md={4}>
          Customer 2 SPA:
        </Grid>
        <Grid item xs={6} md={2}>
          {couple ? getStatePensionAge(customer2DOB) : "-"}
        </Grid>
        <Grid item xs={6} md={4}>
          Children?:
        </Grid>
        <Grid item xs={6} md={2}>
          {children ? "Yes" : "No"}
        </Grid>
        <Grid item xs={6} md={4}>
          Mortgage / Protection a priority?:
        </Grid>
        <Grid item xs={6} md={2}>
          {mortgagePriority ? "Yes" : "No"}
        </Grid>
        <Grid item xs={6} md={4}>
          Retirement Spending:
        </Grid>
        <Grid item xs={6} md={2}>
          {retirementSpending}
        </Grid>
        <Grid item xs={6} md={4}>
          Pensions / Investments a priority?:
        </Grid>
        <Grid item xs={6} md={2}>
          {pensionsPriority ? "Yes" : "No"}
        </Grid>
      </Grid>
      <Grid container className="sessionCardFHCTitle">
        Financial Healthcheck results
      </Grid>
      <Grid container spacing={1} className="sessionCardBetweenComponents">
        <Grid item xs={12} md={6} className="sessionCardMultipleChoice">
          {financialCheck.will ? tick : cross}
          <div className="sessionCardMultipleChoiceText">Will</div>
        </Grid>
        <Grid item xs={12} md={6} className="sessionCardMultipleChoice">
          {financialCheck.incomeProtection ? tick : cross}
          <div className="sessionCardMultipleChoiceText">Income Protection</div>
        </Grid>
        <Grid item xs={12} md={6} className="sessionCardMultipleChoice">
          {financialCheck.emergencyCash ? tick : cross}
          <div className="sessionCardMultipleChoiceText">Emergency Cash</div>
        </Grid>
        <Grid item xs={12} md={6} className="sessionCardMultipleChoice">
          {financialCheck.prevPensions ? tick : cross}
          <div className="sessionCardMultipleChoiceText">
            Previous Pensions - Number: {financialCheck.noOfPrevPensions}
          </div>
        </Grid>
        <Grid item xs={12} md={6} className="sessionCardMultipleChoice">
          {financialCheck.shortTermDebt ? tick : cross}
          <div className="sessionCardMultipleChoiceText">
            Issues paying monthly bills
          </div>
        </Grid>
        <Grid item xs={12} md={6} className="sessionCardMultipleChoice">
          {financialCheck.knowsStatePensionAge ? tick : cross}
          <div className="sessionCardMultipleChoiceText">
            Knows State Pension Age
          </div>
        </Grid>
        <Grid item xs={12} md={6} className="sessionCardMultipleChoice">
          {financialCheck.hasMortgage ? tick : cross}
          <div className="sessionCardMultipleChoiceText">
            Mortgage - Term:{" "}
            {getMortgageTerm(financialCheck.timeUntilMortgageEnds)}
          </div>
        </Grid>
        <Grid item xs={12} md={6} className="sessionCardMultipleChoice">
          {financialCheck.environmentPriority ? tick : cross}
          <div className="sessionCardMultipleChoiceText">
            Environmental Investor
          </div>
        </Grid>
        <Grid item xs={12} md={6} className="sessionCardMultipleChoice">
          {financialCheck.lifeCover ? tick : cross}
          <div className="sessionCardMultipleChoiceText">Life cover</div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SessionDataCardAdditionalInfo;
