import React from 'react';

function AmmoniteMenuCircleFilled({ colour, width, height, cx, cy, r  }) {
	return (
		<svg height={height} width={width}>
			<circle cx={cx} cy={cy} r={r} style={{ stroke: colour, strokeWidth: 4 }} fill={colour} />
		</svg>
	);
}

export default AmmoniteMenuCircleFilled;
