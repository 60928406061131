//React
import React, { useState, useEffect } from "react";

//External libraries
import { Grid } from "@material-ui/core";

//Component imports
import AmmoniteMenuCircleEmpty from "../../../Assets/SVG/menuCircleEmpty";
import AmmoniteMenuCircleFilledTicked from "../../../Assets/SVG/menuCircleFilledTicked";

//Styles
import "./sessionCardContents.css";

function SessionDataCardIntro({
  coachName,
  sessionDate,
  phoneNumber,
  email,
  recommend,
  referralDetails,
  customerSituation,
  handleRecommendation,
  handleReferralDetails,
  handleCustomerSituation,
}) {
  const filled = (
    <div className="circleSVG">
      <AmmoniteMenuCircleFilledTicked />
    </div>
  );

  const empty = (
    <div className="circleSVG">
      <AmmoniteMenuCircleEmpty
        colour="#3BB9C4"
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  useEffect(() => {}, []);

  return (
    <Grid className="sessionCardText">
      <Grid container spacing={1}>
        <Grid item xs={5}>
          Coach Name:
        </Grid>
        <Grid item xs={7}>
          {coachName}
        </Grid>
        <Grid item xs={5}>
          Date of Goal Session:
        </Grid>
        <Grid item xs={7}>
          {sessionDate}
        </Grid>
        <Grid item xs={5}>
          Phone number:
        </Grid>
        <Grid item xs={7}>
          {phoneNumber}
        </Grid>
        <Grid item xs={5}>
          Email:
        </Grid>
        <Grid item xs={7}>
          {email}
        </Grid>
      </Grid>
      <Grid container spacing={5} className="sessionCardBetweenComponents">
        <Grid item>Would the customer recommend to friends / family?</Grid>
        <Grid
          item
          className="sessionCardMultipleChoice"
          onClick={() => handleRecommendation(0)}
        >
          {recommend === 0 ? filled : empty}
          <div className="sessionCardMultipleChoiceText">Yes</div>
        </Grid>
        <Grid
          item
          className="sessionCardMultipleChoice"
          onClick={() => handleRecommendation(1)}
        >
          {recommend === 1 ? filled : empty}
          <div className="sessionCardMultipleChoiceText">No</div>
        </Grid>
        <Grid
          item
          className="sessionCardMultipleChoice"
          onClick={() => handleRecommendation(2)}
        >
          {recommend === 2 ? filled : empty}
          <div className="sessionCardMultipleChoiceText">Not sure</div>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item>Details of any potential referrals</Grid>
        <Grid item xs={12}>
          <input
            type="text"
            placeholder=""
            value={referralDetails}
            className="sessionCardInput"
            onChange={(event) => handleReferralDetails(event.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item>Summary of customer situation</Grid>
        <Grid item xs={12}>
          <textarea
            type="text"
            placeholder=""
            value={customerSituation}
            className="sessionCardInput sessionCardBiggerInput"
            onChange={(event) => handleCustomerSituation(event.target.value)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SessionDataCardIntro;
