//React
import React, { useState, useEffect } from "react";

//External libraries
import { Grid } from "@material-ui/core";

//Component imports
import AmmoniteMenuCircleEmpty from "../../../Assets/SVG/menuCircleEmpty";
import AmmoniteMenuCircleFilledTicked from "../../../Assets/SVG/menuCircleFilledTicked";

//Styles
import "./sessionCardContents.css";

function SessionDataCardFurtherIntro({
  likelihood,
  anticipatedAssets,
  areasOfInterest,
  interestComments,
  handleLikelihood,
  handleAnticipatedAssets,
  handleAreasOfInterest,
  handleInterestComments,
}) {
  const filled = (
    <div className="circleSVG">
      <AmmoniteMenuCircleFilledTicked />
    </div>
  );

  const empty = (
    <div className="circleSVG">
      <AmmoniteMenuCircleEmpty
        colour="#3BB9C4"
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  useEffect(() => {}, []);

  return (
    <Grid className="sessionCardText">
      <Grid container>
        <Grid item xs={12} className="sessionCardBetweenComponents2">
          Likelihood of proceeding to advice
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          className="sessionCardMultipleChoice"
          onClick={() => handleLikelihood(0)}
        >
          {likelihood === 0 ? filled : empty}
          <div className="sessionCardMultipleChoiceText">Very likely</div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          className="sessionCardMultipleChoice"
          onClick={() => handleLikelihood(1)}
        >
          {likelihood === 1 ? filled : empty}
          <div className="sessionCardMultipleChoiceText">Likely</div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          className="sessionCardMultipleChoice"
          onClick={() => handleLikelihood(2)}
        >
          {likelihood === 2 ? filled : empty}
          <div className="sessionCardMultipleChoiceText">Unlikely</div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          className="sessionCardMultipleChoice"
          onClick={() => handleLikelihood(3)}
        >
          {likelihood === 3 ? filled : empty}
          <div className="sessionCardMultipleChoiceText">Very Unlikely</div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          className="sessionCardMultipleChoice"
          onClick={() => handleLikelihood(4)}
        >
          {likelihood === 4 ? filled : empty}
          <div className="sessionCardMultipleChoiceText">Not sure</div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className="sessionCardBetweenComponents2">
          Anticipated investable assets
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          className="sessionCardMultipleChoice"
          onClick={() => handleAnticipatedAssets(0)}
        >
          {anticipatedAssets === 0 ? filled : empty}
          <div className="sessionCardMultipleChoiceText">{"<£50,000"}</div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          className="sessionCardMultipleChoice"
          onClick={() => handleAnticipatedAssets(1)}
        >
          {anticipatedAssets === 1 ? filled : empty}
          <div className="sessionCardMultipleChoiceText">
            {"£50,000-£100,000"}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          className="sessionCardMultipleChoice"
          onClick={() => handleAnticipatedAssets(2)}
        >
          {anticipatedAssets === 2 ? filled : empty}
          <div className="sessionCardMultipleChoiceText">
            {"£100,000-£250,000"}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          className="sessionCardMultipleChoice"
          onClick={() => handleAnticipatedAssets(3)}
        >
          {anticipatedAssets === 3 ? filled : empty}
          <div className="sessionCardMultipleChoiceText">{">£250,000"}</div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className="sessionCardBetweenComponents2">
          Areas of particular interest
        </Grid>
        <Grid
          item
          item
          xs={12}
          sm={4}
          md={2}
          className="sessionCardMultipleChoice"
          onClick={() => handleAreasOfInterest(0)}
        >
          {areasOfInterest[0] === true ? filled : empty}
          <div className="sessionCardMultipleChoiceText">Pensions</div>
        </Grid>
        <Grid
          item
          item
          xs={12}
          sm={4}
          md={2}
          className="sessionCardMultipleChoice"
          onClick={() => handleAreasOfInterest(1)}
        >
          {areasOfInterest[1] === true ? filled : empty}
          <div className="sessionCardMultipleChoiceText">Investments</div>
        </Grid>
        <Grid
          item
          item
          xs={12}
          sm={4}
          md={3}
          className="sessionCardMultipleChoice"
          onClick={() => handleAreasOfInterest(2)}
        >
          {areasOfInterest[2] === true ? filled : empty}
          <div className="sessionCardMultipleChoiceText">
            Regular Contributions
          </div>
        </Grid>
        <Grid
          item
          item
          xs={12}
          sm={4}
          md={2}
          className="sessionCardMultipleChoice"
          onClick={() => handleAreasOfInterest(3)}
        >
          {areasOfInterest[3] === true ? filled : empty}
          <div className="sessionCardMultipleChoiceText">Protection</div>
        </Grid>
        <Grid
          item
          item
          xs={12}
          sm={4}
          md={2}
          className="sessionCardMultipleChoice"
          onClick={() => handleAreasOfInterest(4)}
        >
          {areasOfInterest[4] === true ? filled : empty}
          <div className="sessionCardMultipleChoiceText">Mortgage</div>
        </Grid>
        <Grid
          item
          item
          xs={12}
          sm={4}
          md={2}
          className="sessionCardMultipleChoice"
          onClick={() => handleAreasOfInterest(5)}
        >
          {areasOfInterest[5] === true ? filled : empty}
          <div className="sessionCardMultipleChoiceText">Will</div>
        </Grid>
        <Grid
          item
          item
          xs={12}
          sm={4}
          md={3}
          className="sessionCardMultipleChoice"
          onClick={() => handleAreasOfInterest(6)}
        >
          {areasOfInterest[6] === true ? filled : empty}
          <div className="sessionCardMultipleChoiceText">
            Inheritance Planning
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item>Comments on areas of interest</Grid>
        <Grid item xs={12}>
          <textarea
            type="text"
            placeholder=""
            value={interestComments}
            className="sessionCardInput sessionCardAreaOfInterest"
            onChange={(event) => handleInterestComments(event.target.value)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SessionDataCardFurtherIntro;
