import React from 'react';

function AmmoniteCallback() {
	return (
<svg width="220" height="114" viewBox="0 0 220 114" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M52.0138 23.2807C54.404 23.2807 56.3416 21.3676 56.3416 19.0076C56.3416 16.6476 54.404 14.7344 52.0138 14.7344C49.6236 14.7344 47.686 16.6476 47.686 19.0076C47.686 21.3676 49.6236 23.2807 52.0138 23.2807Z" fill="#F2F2F2"/>
<path d="M66.9009 57.0366C66.9009 57.0366 60.1698 47.8319 70.8875 37.1275L66.9009 57.0366Z" fill="#F2F2F2"/>
<path d="M61.6842 58.0978C61.6842 58.0978 57.0837 47.6928 42.2974 51.3562L61.6842 58.0978Z" fill="#F2F2F2"/>
<path d="M149.271 92.7697C149.271 92.7697 150.646 75.8342 173.375 73.9051L149.271 92.7697Z" fill="#F2F2F2"/>
<path d="M142.285 88.9818C142.285 88.9818 147.267 72.7197 126.821 62.729L142.285 88.9818Z" fill="#F2F2F2"/>
<path d="M220 104.025H0V104.961H220V104.025Z" fill="#BDBDBD"/>
<path d="M102.824 16.8079C102.824 16.8079 100.248 14.2649 94.3246 14.7735C94.3246 14.7735 37.1474 8.92454 35.8596 19.351C34.5718 29.7774 34.3143 35.8807 37.6625 38.1694C37.6625 38.1694 48.6086 47.9601 42.9424 56.6064L39.8517 61.6925L161.16 60.6753C161.16 60.6753 162.448 42.1111 166.311 38.5509C170.175 34.9906 171.462 16.1722 166.311 15.6636C161.16 15.155 101.407 12.3576 102.824 16.8079Z" fill="#1981C7"/>
<path d="M31.8679 96.7864H21.3081V104.67H31.8679V96.7864Z" fill="#1981C7"/>
<path d="M183.825 96.7864H173.266V104.67H183.825V96.7864Z" fill="#1981C7"/>
<path d="M38.8216 37.5336C38.8216 37.5336 2.50637 45.9256 16.9294 61.6925L19.2474 63.9812L18.2172 99.0751H185.628L187.431 64.7441L189.491 60.6752C189.491 60.6752 202.322 45.4806 165.258 39.9813L158.585 56.6064L45.7756 55.8435C45.7756 55.8435 45.0029 37.025 38.8216 37.5336Z" fill="#309AE1"/>
<path d="M100.865 56.7336C100.865 56.7336 75.6522 39.0596 44.2305 56.8608L34.9585 70.0845L36.7614 81.0196C36.7614 81.0196 101.923 82.0368 102.695 75.1706C103.468 68.3044 100.865 56.7336 100.865 56.7336Z" fill="#309AE1"/>
<path d="M159.872 56.8607C159.872 56.8607 115.637 42.874 102.341 56.8607L103.983 76.9507C103.983 76.9507 168.629 81.7825 169.66 78.4765C170.69 75.1706 171.205 69.3216 171.205 69.3216L159.872 56.8607Z" fill="#309AE1"/>
<path opacity="0.1" d="M159.873 56.8607C159.873 56.8607 115.637 42.8739 102.341 56.8607L102.378 57.3088C102.334 57.1009 102.309 56.9878 102.309 56.9878C102.309 56.9878 75.6522 39.0594 44.2305 56.8607L34.9585 70.0845L36.7614 81.0195C36.7614 81.0195 99.8583 82.3448 103.912 76.0798L103.983 76.9507C103.983 76.9507 168.63 81.7824 169.66 78.4765C170.69 75.1705 171.205 69.3216 171.205 69.3216L159.873 56.8607Z" fill="black"/>
<path d="M39.8516 21.7669C52.3695 21.504 64.2366 19.9354 75.1366 16.4265C75.4017 28.8062 77.2044 40.3842 81.3179 50.7574C69.1318 48.5709 56.7638 50.7347 44.23 56.8607C46.4811 45.6698 44.5349 33.9054 39.8516 21.7669Z" fill="#BF802F"/>
<path d="M167.856 23.8012C155.339 23.5384 143.471 21.9697 132.571 18.4609C132.306 30.8405 130.504 42.4186 126.39 52.7918C138.576 50.6053 150.944 52.769 163.478 58.8951C161.227 47.7042 163.173 35.9397 167.856 23.8012Z" fill="#BF802F"/>
<path d="M87.4663 20.5524C88.1954 20.04 89.0002 19.6416 89.8521 19.3711C95.2539 17.3865 100.914 16.17 106.662 15.7579C111.661 15.408 117.078 15.8237 120.982 18.9254C121.59 19.3654 122.087 19.937 122.435 20.5964C122.926 21.6429 122.777 22.8593 122.618 24.002C122.583 24.4453 122.469 24.879 122.284 25.2841C121.758 26.2718 120.465 26.6178 119.341 26.4993C118.218 26.3809 117.164 25.9115 116.056 25.6939C115.37 25.5759 114.676 25.5062 113.98 25.4853L107.03 25.1138C102.709 24.8827 98.3299 24.6556 94.0905 25.5168C92.7855 25.7484 91.5245 26.1774 90.352 26.7888C89.3837 27.3256 88.7317 28.0958 87.8332 27.1044C86.2548 25.3626 85.3811 22.1612 87.4663 20.5524Z" fill="#69306D"/>
<path d="M98.2363 23.5871C98.2363 23.5871 107.584 19.4495 111.13 24.2236C114.676 28.9977 109.196 27.0881 109.196 27.0881L103.072 28.9977L96.3022 27.7246L98.2363 23.5871Z" fill="#F2F2F2"/>
<path d="M98.7197 20.5635C98.7197 20.5635 100.009 26.6107 99.0421 28.2021C98.075 29.7935 104.522 32.0214 104.522 32.0214L110.647 29.4752C110.647 29.4752 109.035 19.6086 110.647 17.699L98.7197 20.5635Z" fill="#FFB9B9"/>
<path opacity="0.1" d="M98.7197 20.5635C98.7197 20.5635 100.009 26.6107 99.0421 28.2021C98.075 29.7935 104.522 32.0214 104.522 32.0214L110.647 29.4752C110.647 29.4752 109.035 19.6086 110.647 17.699L98.7197 20.5635Z" fill="black"/>
<path d="M92.6796 93.2776C93.6019 95.0235 94.1996 96.9148 94.8098 98.7894C94.8188 98.8519 94.8411 98.9119 94.8753 98.9653C94.9094 99.0187 94.9546 99.0644 95.0078 99.0994C95.0611 99.1344 95.1212 99.1579 95.1843 99.1683C95.2474 99.1787 95.312 99.1759 95.374 99.1599L101.108 99.1536C101.815 99.1528 102.765 98.9284 102.757 98.2298C102.752 97.7389 102.244 97.4329 101.866 97.1143C100.816 96.23 100.543 94.772 100.108 93.479C99.2632 90.9883 97.6349 88.829 95.4605 87.3156C95.1665 87.0884 94.8283 86.9233 94.4669 86.8307C94.1035 86.7787 93.7332 86.7985 93.3776 86.8891C92.3711 87.0896 88.6125 87.7756 89.3225 89.2211C89.6282 89.8435 90.7612 90.4914 91.2362 91.0728C91.7898 91.759 92.2733 92.4975 92.6796 93.2776Z" fill="#69306D"/>
<path d="M112.617 86.7117C112.415 87.1313 112.19 87.5397 111.943 87.9352C111.113 89.2879 110.132 90.5497 109.359 91.9351C108.876 92.7992 108.47 93.7331 108.417 94.7184C108.364 95.7038 108.715 96.7525 109.511 97.349C110.343 97.9726 111.485 98.0015 112.519 97.8562C113.553 97.7109 114.582 97.4182 115.624 97.4952C115.273 95.5325 116.949 93.805 117.368 91.8553C117.486 91.3077 117.504 90.7398 117.661 90.2021C117.831 89.7104 118.049 89.2359 118.31 88.7853C119.145 87.18 119.735 85.4617 120.061 83.6861C120.101 83.4708 114.965 83.4355 114.483 83.5384C112.979 83.8594 113.199 85.4807 112.617 86.7117Z" fill="#69306D"/>
<path d="M73.0304 55.8758C71.5642 58.1414 71.902 61.1438 72.9318 63.6328C73.9616 66.1217 75.6007 68.322 76.7225 70.7719C77.4377 72.3338 77.9392 73.9899 78.7445 75.5083C79.7418 77.3887 81.1978 79.046 81.867 81.0631C82.2936 82.3489 82.3914 83.7581 83.0824 84.9265C83.5001 85.5509 83.9768 86.1348 84.5061 86.6704C85.9945 88.354 87.1119 90.3248 87.7875 92.4577C88.419 90.6001 90.3102 89.3821 92.2526 88.9804C94.1949 88.5787 96.2075 88.8516 98.1726 89.1256C97.0764 87.449 95.9697 85.751 95.3162 83.8625C94.6843 82.0361 94.4978 80.0929 94.0722 78.2092C93.1992 74.3461 91.3345 70.7831 89.4884 67.2698L98.9772 66.5267C100.526 66.373 102.083 66.316 103.639 66.356C106.088 66.47 108.488 67.0628 110.924 67.3368C113.36 67.6107 115.937 67.539 118.108 66.4139C113.957 72.2952 111.795 79.3219 111.931 86.4894C112.859 85.7795 113.936 85.2835 115.083 85.0377C116.23 84.7919 117.418 84.8025 118.561 85.0687C119.703 85.3349 120.771 85.85 121.685 86.5762C122.6 87.3025 123.339 88.2215 123.847 89.2661C127.566 84.2486 127.124 77.2636 130.071 71.7689C130.676 70.6391 131.419 69.5867 132.073 68.4832C133.366 66.3009 134.304 63.9347 135.237 61.5797C136.061 59.4982 136.891 57.1821 136.101 55.0878C135.773 54.2932 135.295 53.5674 134.692 52.949C132.992 51.0982 130.626 49.7292 128.096 49.6722C124.212 49.5847 121.004 52.4271 117.597 54.2702C113.584 56.4412 108.903 57.3041 104.339 56.985C99.6593 56.6579 95.1901 55.1454 90.7317 53.8134C88.5014 53.1162 86.2344 52.5387 83.9408 52.0838C82.0289 51.7297 80.2081 52.1219 78.324 52.6046C76.2727 53.1302 74.211 54.0516 73.0304 55.8758Z" fill="#0C3957"/>
<path d="M113.548 14.5162C113.546 17.0479 112.526 19.4754 110.713 21.2656C108.9 23.0558 106.442 24.0624 103.878 24.0645C103.301 24.0655 102.726 24.0143 102.16 23.9117C100.35 23.589 98.6707 22.7635 97.3193 21.5319C95.9679 20.3003 95.0002 18.7137 94.5298 16.9583C94.0593 15.2029 94.1056 13.3514 94.6632 11.621C95.2207 9.89064 96.2665 8.35314 97.6777 7.18885C99.089 6.02455 100.807 5.28175 102.631 5.04757C104.455 4.81339 106.308 5.09755 107.974 5.8667C109.64 6.63586 111.049 7.85813 112.036 9.39013C113.024 10.9221 113.548 12.7003 113.548 14.5162Z" fill="#FBBEBE"/>
<path d="M98.236 23.5871C98.236 23.5871 100.009 24.3828 100.654 25.0193C101.298 25.6558 103.232 29.1569 106.456 26.929C109.679 24.701 110.002 23.4279 110.969 23.4279C111.936 23.4279 113.225 25.3376 113.225 25.3376L122.09 51.5954C119.701 54.2581 116.932 56.5633 113.87 58.4384C108.712 61.6211 102.588 63.2125 99.6866 61.9394C96.7855 60.6663 91.3056 59.7115 90.3385 57.1653C89.3715 54.6191 87.7598 46.9804 87.7598 46.9804L92.2726 27.5655L98.236 23.5871Z" fill="#F2F2F2"/>
<path d="M96.9916 50.1435C97.6943 48.6447 97.7965 46.9482 97.856 45.2976C97.9507 43.6627 97.9068 42.023 97.7248 40.3954C97.4391 38.2663 96.7369 36.2152 96.2935 34.1127C95.9048 32.2687 95.7158 30.3891 95.7298 28.5056C95.7362 27.6462 95.7993 26.7414 96.2736 26.0207C96.6045 25.518 97.1221 25.1286 97.3288 24.5648C97.7343 23.4594 96.7325 22.3019 95.6347 21.8467C94.3287 21.3052 92.836 21.3416 91.4619 21.6813C90.0879 22.0211 88.8063 22.6462 87.5368 23.2668C86.6198 23.7151 85.6834 24.1772 84.9723 24.9037C83.9717 25.926 83.5317 27.35 83.1765 28.7277C82.8921 29.8305 82.6394 30.9804 82.8557 32.098C83.2071 33.913 84.7056 35.2781 85.5044 36.9491C86.432 38.8896 86.3695 41.1509 86.0006 43.2655C85.6316 45.3801 84.9767 47.4477 84.7747 49.5841C84.5727 51.7206 84.8827 54.0172 86.2384 55.6942C86.7908 56.3777 88.2894 57.9973 89.3007 57.8141C90.0952 57.6701 91.2471 55.9432 91.8871 55.3958C93.7361 53.8145 95.9298 52.4079 96.9916 50.1435Z" fill="#69306D"/>
<path d="M113.88 22.5491C113.909 24.3242 113.116 26.0115 112.184 27.5298C111.252 29.048 110.157 30.4839 109.471 32.1247C108.617 34.1785 108.443 36.4462 108.973 38.6037C109.17 39.3386 109.407 40.0623 109.684 40.7717L111.45 45.555C111.688 46.3071 112.032 47.0219 112.474 47.6781C112.873 48.1806 113.342 48.6256 113.866 49.0001C115.208 50.0698 116.751 50.865 118.407 51.3394C120.069 51.773 121.887 51.7458 123.457 51.055C123.628 44.5705 124.683 38.1383 126.594 31.9323C126.947 30.7867 127.333 29.5805 127.061 28.4136C126.756 27.1065 125.692 26.1252 124.681 25.2289C121.59 22.4881 118.191 19.6091 114.053 19.2396C111.658 19.0257 113.86 21.2657 113.88 22.5491Z" fill="#69306D"/>
<path d="M92.7263 59.5534C92.9715 60.0273 93.1241 60.5433 93.3666 61.0186C93.7243 61.6559 94.1667 62.2431 94.6818 62.7649C96.8371 65.1245 99.4717 67.4752 102.687 67.6176C103.006 67.647 103.328 67.607 103.63 67.5003C104.193 67.2694 104.525 66.6744 104.634 66.0828C104.862 64.8502 104.311 63.6204 103.759 62.492C102.564 60.0465 101.218 57.539 98.9792 55.9618C96.7722 54.4071 93.815 54.0295 91.2412 54.8124C90.0749 55.1671 88.1411 55.7866 89.6852 56.8371C90.8566 57.6342 92.0472 58.2409 92.7263 59.5534Z" fill="#FFB9B9"/>
<path d="M94.6606 98.0689C93.6996 99.3583 93.8923 101.202 93.041 102.564C92.3031 103.745 90.9125 104.364 89.9895 105.411C89.695 105.759 89.4325 106.132 89.205 106.525C88.3488 107.941 87.6529 109.756 88.4961 111.18C89.1744 112.325 90.6002 112.773 91.9079 113.078C93.5606 113.463 95.2778 113.772 96.9497 113.477C98.6215 113.182 100.255 112.172 100.852 110.602C100.969 110.207 101.108 109.819 101.269 109.439C102.175 107.678 105.017 107.659 105.945 105.908C106.594 104.683 105.997 103.202 105.401 101.951L103.579 98.1306C102.975 96.8627 100.525 97.5918 99.2472 97.288C97.5812 96.8918 95.8591 96.4552 94.6606 98.0689Z" fill="#0C3957"/>
<path d="M108.748 94.7867C107.976 95.3215 107.476 96.1607 107.101 97.0158C106.199 99.0691 105.89 101.32 105.589 103.538C105.469 104.427 105.349 105.336 105.537 106.214C105.925 108.025 107.588 109.359 109.377 109.913C111.167 110.467 113.086 110.382 114.959 110.291C115.561 110.262 116.186 110.226 116.714 109.939C117.655 109.427 118.023 108.222 117.862 107.172C117.7 106.123 117.12 105.189 116.551 104.288C115.983 103.387 115.402 102.454 115.239 101.405C114.965 99.6403 115.738 97.0497 114.64 95.4531C113.539 93.853 110.217 93.7704 108.748 94.7867Z" fill="#0C3957"/>
<path d="M113.305 9.54011C113.133 8.9691 112.754 8.48066 112.24 8.16804C112.266 7.55637 112.19 6.94458 112.015 6.35734C111.43 4.64786 110.042 4.74585 108.733 3.99005C108.165 3.66214 108.2 3.37232 107.905 2.83374C107.517 2.1277 106.937 1.54315 106.23 1.14652C105.39 0.618211 104.504 0.0741215 103.51 0.00725746C101.422 -0.133253 99.8215 1.8057 97.826 2.428C96.9465 2.70228 96.0013 2.71359 95.1171 2.97289C94.2329 3.23219 93.3508 3.85133 93.219 4.75272C93.1581 5.16887 93.2655 5.59577 93.2038 6.0118C93.0811 6.83867 92.3464 7.41778 91.8545 8.09789C90.7669 9.60186 90.8913 11.7265 91.7611 13.3629C91.9444 13.7015 92.1541 14.0255 92.3883 14.332C92.4608 15.1054 92.6858 15.8574 93.0505 16.5457C93.9203 18.1821 95.417 19.4049 96.9804 20.4208C96.8524 19.8781 96.7748 19.325 96.7485 18.7683C96.7265 18.5828 96.7509 18.3948 96.8197 18.2208C96.9938 17.8615 97.4539 17.7659 97.8047 17.5691C98.5313 17.1614 98.8162 16.2592 98.8372 15.4343C98.8582 14.6094 98.6812 13.7831 98.7687 12.9625C98.7721 12.7738 98.8156 12.5879 98.8962 12.4168C98.9769 12.2457 99.093 12.0932 99.237 11.9691C99.5051 11.818 99.8114 11.7459 100.12 11.7612C101.608 11.7013 103.164 11.6743 104.477 10.9792C104.925 10.742 105.341 10.4284 105.833 10.3018C106.949 10.0144 108.09 10.7867 108.733 11.7319C109.376 12.6771 109.697 13.8019 110.29 14.7783C110.883 15.7547 111.909 16.6255 113.056 16.5098C113.12 16.5099 113.184 16.4966 113.243 16.4707C113.302 16.4448 113.355 16.4068 113.398 16.3594C113.448 16.2716 113.47 16.1713 113.462 16.0711L113.526 11.741C113.579 11.0003 113.504 10.256 113.305 9.54011Z" fill="#0C3957"/>
<path d="M82.7007 32.1904C81.7781 33.5344 80.7142 34.8267 80.2071 36.3712C79.909 37.279 79.8164 38.2387 79.6006 39.1689C78.8818 42.2674 76.8051 45.0194 76.5964 48.1914C76.5232 48.8963 76.6281 49.608 76.9015 50.2631C77.4972 51.5425 78.9229 52.2168 80.2829 52.641C81.6428 53.0651 83.1033 53.3707 84.1888 54.2841C84.7353 54.8013 85.2309 55.3686 85.6689 55.9783C87.4399 58.1532 89.8224 59.7628 92.5171 60.6047C92.3042 59.1564 92.2043 57.4108 93.3768 56.5157C94.0115 56.0311 94.8595 55.9449 95.6588 55.8767L99.4357 55.5544C96.9172 53.1424 94.1412 51.0071 91.1564 49.1861C90.112 48.5491 88.9753 47.8771 88.4984 46.7602C88.2003 46.062 88.2044 45.2799 88.1973 44.5222C88.1713 41.7488 87.9335 38.9812 87.4858 36.243C87.1749 34.3418 86.7632 32.4575 86.2923 30.5886C86.1601 30.064 85.3788 27.7106 84.4172 28.2612C84.1907 28.3909 83.9787 29.8838 83.8465 30.1936C83.5316 30.8946 83.1479 31.5634 82.7007 32.1904Z" fill="#69306D"/>
<path opacity="0.1" d="M87.9278 36.2704C87.9704 38.5188 87.4867 40.7464 86.5144 42.7799C86.2614 43.2293 86.075 43.7122 85.9609 44.2138C85.7917 45.2839 86.4084 46.3198 87.1133 47.1495C88.5039 48.7813 90.3075 50.0203 92.3406 50.7402" fill="black"/>
<path d="M111.991 24.8652L112.219 24.9008C112.02 24.8696 111.841 24.7617 111.723 24.6007C111.604 24.4398 111.555 24.2389 111.587 24.0423L113.939 9.37017C113.971 9.17362 114.08 8.99747 114.243 8.88049C114.406 8.7635 114.609 8.71526 114.808 8.74637L114.581 8.71082C114.739 8.73556 114.891 8.79084 115.028 8.87352C115.164 8.95619 115.283 9.06463 115.377 9.19265C115.472 9.32067 115.539 9.46577 115.577 9.61965C115.614 9.77353 115.621 9.93317 115.596 10.0895L113.388 23.8633C113.363 24.0197 113.307 24.1696 113.223 24.3045C113.139 24.4394 113.029 24.5568 112.9 24.6498C112.77 24.7428 112.623 24.8097 112.467 24.8467C112.311 24.8837 112.15 24.8899 111.991 24.8652Z" fill="#BF802F"/>
<path d="M114.501 8.69866L114.846 8.75256L112.256 24.9069L111.911 24.853C111.853 24.8439 111.797 24.8236 111.747 24.7931C111.696 24.7627 111.653 24.7228 111.618 24.6757C111.583 24.6286 111.558 24.5752 111.545 24.5186C111.531 24.462 111.528 24.4032 111.538 24.3457L113.987 9.06731C113.996 9.00979 114.017 8.95463 114.048 8.90498C114.078 8.85532 114.119 8.81215 114.166 8.77792C114.214 8.74368 114.268 8.71907 114.326 8.70547C114.383 8.69187 114.442 8.68956 114.501 8.69866Z" fill="#F6AB4A"/>
<path d="M114.546 10.8385L114.479 10.8281C114.456 10.8245 114.434 10.8403 114.43 10.8636L114.232 12.0969C114.228 12.1202 114.244 12.142 114.268 12.1457L114.334 12.1561C114.358 12.1597 114.38 12.1439 114.384 12.1206L114.581 10.8873C114.585 10.864 114.569 10.8422 114.546 10.8385Z" fill="#464353"/>
<path d="M120.395 22.0431C120.751 21.1587 121.112 20.2441 121.088 19.2929C121.026 18.4308 120.762 17.5948 120.315 16.8517C119.771 15.8545 119.093 14.9351 118.298 14.1186C117.352 13.1468 115.986 12.2791 114.696 12.7201C113.671 13.0704 113.087 14.1233 112.709 15.1262C112.483 15.616 112.394 16.1563 112.45 16.6914C112.576 17.3819 113.116 17.9119 113.507 18.4975C114.121 19.4155 114.383 20.5124 114.635 21.5838L115.268 24.2709L116.353 28.8865C116.325 28.7649 118.37 26.8524 118.613 26.4453C119.395 25.1337 119.826 23.4589 120.395 22.0431Z" fill="#FBBEBE"/>
<path d="M126.202 27.1114L127.744 30.2786C128.487 31.5809 128.825 33.0704 128.716 34.5613L128.808 44.062C128.82 45.3316 128.83 46.6203 128.492 47.8452C128.395 48.2541 128.214 48.6388 127.96 48.9749C127.698 49.2646 127.39 49.5097 127.047 49.7004C124.128 51.5013 120.343 52.5248 117.259 51.0157C116.553 50.644 115.881 50.2111 115.252 49.7223C114.023 48.8235 112.776 47.9072 111.856 46.7004C110.894 45.4374 115.319 40.6321 114.672 39.1866C113.512 36.5971 114.154 30.4557 112.354 28.2516C112.092 27.9309 114.473 28.2197 114.463 27.8078C114.444 26.975 116.467 25.7711 117.247 25.4542C118.58 24.913 120.115 23.8085 120.338 22.4026C123.172 26.0727 115.213 37.1621 119.686 38.5032C119.756 38.5474 119.837 38.5721 119.92 38.5746C120.004 38.5771 120.086 38.5574 120.159 38.5174C120.232 38.4775 120.292 38.4189 120.334 38.3478C120.376 38.2768 120.397 38.1958 120.396 38.1137C120.65 37.0648 120.74 35.9847 120.925 34.9219C121.266 32.9867 121.922 31.1188 122.868 29.3909C123.398 28.422 125.113 24.8757 126.202 27.1114Z" fill="#69306D"/>
<path opacity="0.1" d="M121.42 34.3755C121.193 34.9789 121.399 35.6462 121.574 36.2664C122.082 38.0651 122.319 39.9277 122.277 41.7945C122.285 41.8272 122.284 41.8611 122.275 41.8933C122.265 41.9255 122.248 41.9549 122.224 41.9788C122.201 42.0027 122.171 42.0205 122.139 42.0304C122.107 42.0403 122.072 42.0422 122.039 42.0357C121.295 42.0807 120.443 42.0855 119.938 41.5439" fill="black"/>
</svg>

	);
}

export default AmmoniteCallback;
