import React from 'react';

function AmmonitePropertyIcon() {
	return (
		<svg width="78" height="74" viewBox="0 0 78 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M66.3651 31.3747L41.3076 7.07806C41.0054 6.78906 40.646 6.55968 40.2499 6.40315C39.8539 6.24661 39.4291 6.16602 39.0001 6.16602C38.571 6.16602 38.1462 6.24661 37.7502 6.40315C37.3542 6.55968 36.9947 6.78906 36.6926 7.07806L11.6351 31.4056C11.0287 31.9854 10.5499 32.6738 10.2263 33.4307C9.90269 34.1876 9.74082 34.998 9.75007 35.8147V61.653C9.74755 63.2314 10.3831 64.7507 11.5258 65.8979C12.6685 67.0451 14.2314 67.7329 15.8926 67.8197H62.1076C63.7688 67.7329 65.3316 67.0451 66.4743 65.8979C67.617 64.7507 68.2526 63.2314 68.2501 61.653V35.8147C68.2526 34.1588 67.5767 32.5667 66.3651 31.3747ZM32.5001 61.653V43.1531H45.5001V61.653H32.5001ZM61.7501 61.653H52.0001V40.0697C52.0001 39.252 51.6577 38.4677 51.0482 37.8895C50.4387 37.3112 49.612 36.9864 48.7501 36.9864H29.2501C28.3881 36.9864 27.5615 37.3112 26.952 37.8895C26.3425 38.4677 26.0001 39.252 26.0001 40.0697V61.653H16.2501V35.6914L39.0001 13.6456L61.7501 35.8147V61.653Z" fill="#247BA0"/>
        </svg>
	);
}

export default AmmonitePropertyIcon;
