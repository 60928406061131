import React from 'react';

function PropertyFocusButton({ colour }) {
	return (
<svg width="87" height="72" viewBox="0 0 87 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f)">
<path d="M4 36C4 18.3269 18.1478 4 35.6 4H51.4C68.8522 4 83 18.3269 83 36C83 53.6731 68.8522 68 51.4 68H35.6C18.1478 68 4 53.6731 4 36Z" fill={colour}/>
</g>
<path d="M6 35C6 18.4315 19.4315 5 36 5H51C67.5685 5 81 18.4315 81 35C81 51.5685 67.5685 65 51 65H36C19.4315 65 6 51.5685 6 35Z" fill="#247BA0"/>
<path d="M54.525 32.7197L44.8875 22.8697C44.7713 22.7526 44.633 22.6596 44.4807 22.5961C44.3284 22.5327 44.165 22.5 44 22.5C43.835 22.5 43.6716 22.5327 43.5193 22.5961C43.3669 22.6596 43.2287 22.7526 43.1125 22.8697L33.475 32.7322C33.2418 32.9673 33.0576 33.2464 32.9332 33.5533C32.8087 33.8601 32.7464 34.1886 32.75 34.5197V44.9947C32.749 45.6346 32.9935 46.2505 33.433 46.7156C33.8725 47.1807 34.4736 47.4595 35.1125 47.4947H52.8875C53.5264 47.4595 54.1275 47.1807 54.567 46.7156C55.0065 46.2505 55.251 45.6346 55.25 44.9947V34.5197C55.251 33.8484 54.991 33.203 54.525 32.7197ZM41.5 44.9947V37.4947H46.5V44.9947H41.5ZM52.75 44.9947H49V36.2447C49 35.9132 48.8683 35.5953 48.6339 35.3609C48.3995 35.1264 48.0815 34.9947 47.75 34.9947H40.25C39.9185 34.9947 39.6005 35.1264 39.3661 35.3609C39.1317 35.5953 39 35.9132 39 36.2447V44.9947H35.25V34.4697L44 25.5322L52.75 34.5197V44.9947Z" fill="white"/>
<defs>
<filter id="filter0_f" x="0" y="0" width="87" height="72" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur"/>
</filter>
</defs>
</svg>

	);
}

export default PropertyFocusButton;
