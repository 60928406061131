export const getRetirementGoalToday = (
  retirementSpending,
  statePension,
  couple,
  pensionYield
) => {
  let pairedMultiplier = 1;
  if (couple === true) {
    pairedMultiplier = 2;
  }
  let _newRetirementGoal =
    (retirementSpending - statePension * pairedMultiplier) / pensionYield;
  if (_newRetirementGoal < 0 || !_newRetirementGoal) {
    _newRetirementGoal = 0;
  }
  return Math.round(_newRetirementGoal);
};

export const getRetirementGoalTomorrow = (
  retirementGoalToday,
  cpi,
  yearsToRetirement
) => {
  let cpiPlusOne = 1 + cpi / 100;
  let retirementGoalValue = Math.round(
    retirementGoalToday * Math.pow(cpiPlusOne, yearsToRetirement)
  );
  Math.round(retirementGoalValue);
  if (retirementGoalValue < 0) {
    retirementGoalValue = 0;
  }
  return retirementGoalValue;
};
