// Core React dependencies
import React, { useState, useEffect, useContext } from 'react';

// Component imports
import AmmoniteIncrementor from '../../components/Incrementor/Incrementor';
import AmmoniteRoundSwitch from '../../components/RoundSwitch/RoundSwitch';

// Utilities
import AmmoniteContext from '../../Utils/contexts/AmmoniteContext';
import { crementGoalTimeHorizon, setGoalTodaysAmount, setGoalImportance } from '../../Utils/reducers/actions';

//Assets
import AmmoniteMortar from '../../Assets/SVG/mortar';
import AmmoniteHouse from '../../Assets/SVG/house';
import AmmoniteRetirement from '../../Assets/SVG/Illustrations/Retirement/Retirement';
import AmmoniteFamily from '../../Assets/SVG/Illustrations/Family/Family';
import AmmoniteLongTermInvestment from '../../Assets/SVG/Illustrations/LongTermInvestment/LongTermInvestment';
import AmmoniteCareFeesChip from '../../Assets/SVG/Illustrations/CareFees/CareFees';
import AmmoniteOther from '../../Assets/SVG/Illustrations/Other/Other';

//Styling
import './aboutGoalCard.css';

function AmmoniteAboutGoalCard({ index, goalName, goalType, goalTimeHorizon, goalImportance, amountRequiredToday }) {
	const context = useContext(AmmoniteContext);
	const dispatch = context.dispatch;


	const [showCurrency, setShowCurrency ] = useState(true);

	const timeHorizonHandler = (add) => {
		let _newIndex = parseInt(index)
		dispatch({ type: crementGoalTimeHorizon, payload: { 
			add: add, 
			index: _newIndex, 
			todaysGoalAmount: amountRequiredToday,
			cpi: context.state.cpi,
		} });
	};

	const handleTodaysMoneyChange = (value) => {
		let _newValue = parseInt(value, 10)
		let _newIndex = parseInt(index)
		dispatch({ type: setGoalTodaysAmount, payload: { 
			amount: _newValue, 
			index: _newIndex,
			cpi: context.state.cpi,
			goalTimeHorizon: goalTimeHorizon
		} });
	};

	const getAmountRequiredToday = () => {
		return amountRequiredToday.toString()
	}

	const getIllustration = () => {
		switch (goalType) {
			case 'Retirement pot':
				return <AmmoniteRetirement />;
			case 'Education':
				return <AmmoniteMortar />;
			case 'Property':
				return <AmmoniteHouse />;
			case 'Family':
				return <AmmoniteFamily />;
			case 'Long Term Investment':
				return <AmmoniteLongTermInvestment />;
			case 'Care Fees':
				return <AmmoniteCareFeesChip />;
			case 'Other':
				return <AmmoniteOther />;
			default:
				return <AmmoniteRetirement />;
		}
	};

	const getLineColour = () => {
		switch (goalType) {
			case 'Retirement pot':
				return '#F7AB4A';
			case 'Education':
				return '#69306D';
			case 'Property':
				return '#247BA0';
			case 'Family':
				return '#3F762C';
			case 'Long Term Investment':
				return '#373986';
			case 'Other':
				return '#F25F5C';
			default:
				return '#69306D';
		}
	};

	const getTimeHorizonValue = () => {
		return goalTimeHorizon + ' years';
	};

	const handleImportance = (value) => {
		let _newIndex = parseInt(index)
		dispatch({ type: setGoalImportance, payload: { index: _newIndex, value: value } });
	};

	return (
		<div className="aboutGoalCardContainer">
			<div className={`illustration ${index === 0 ? 'noGoalsIllustration' : ''}`}>{getIllustration()}</div>
			<div className={`centreShaded ${String(goalType).replace(/ /g, '')}`} />
				{goalName ? <div>
					<div className="goalInfoHeader">{goalName}</div>
				<div className="goalInfoType">{goalType}</div>
				<div className="goalDivider">
					<svg width="435" height="4" viewBox="0 0 435 4" fill="none" xmlns="http://www.w3.org/2000/svg">
						<line
							x1="2"
							y1="2"
							x2="433"
							y2="2"
							stroke={getLineColour()}
							strokeWidth="4"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="todaysMoney">Target pot size (in today's money)</div>
				{showCurrency && <div className="todaysMoneyInputCurrency">
					£
				</div>}
				<div className="todaysMoneyAmountText">
					<input
						type="number"
						name="fname"
						placeholder="Enter amount"
						value={getAmountRequiredToday()}
						className="todaysmoneyinput"
						onChange={(event) => handleTodaysMoneyChange(event.target.value)}
					/>
				</div>
				<div className="todaysMoney timeHorizon">Years to Goal</div>
				<div className="timeHorizonIncrementor">
					<AmmoniteIncrementor
						minValue={2}
						incrementorValue={goalTimeHorizon}
						incrementorText={getTimeHorizonValue()}
						increment={() => timeHorizonHandler(true)}
						decrement={() => timeHorizonHandler(false)}
					/>
				</div>
				<div className="importantHeader">How important is this goal?</div>
				<div className="importantButtons">
						<AmmoniteRoundSwitch
							colour="#3BB9C4"
							on={goalImportance === 0}
							switchText="Nice to have"
							switchHandler={() => handleImportance(0)}
						/>
						<AmmoniteRoundSwitch
							colour="#3BB9C4"
							on={goalImportance === 1}
							switchHandler={() => handleImportance(1)}
							switchText="Important"
						/>
						<AmmoniteRoundSwitch
							colour="#3BB9C4"
							on={goalImportance === 2}
							switchHandler={() => handleImportance(2)}
							switchText="Primary"
						/>
					</div>
				</div> : <div>
					<div className="noGoals">
						<div className="noGoalsHeader">You haven't selected any goals!</div>
						<div className="noGoalsText">Go back to the last page to select some goals, or click ‘next’ to establish your ‘Retirement Spending’ goal.</div>
					</div>
				</div>}
		</div>
	);
}

export default AmmoniteAboutGoalCard;
