//React
import React, { useEffect } from "react";

//external libraries

//components
import ChartWrapper from "../ChartWrapper/ChartWrapper";

import "./reportGraphic.css";

//assets

function AmmoniteReportGraphic({
  currentYear,
  yearsToRetirement,
  reportGoals,
}) {
  const prepareData = () => {
    let goals = reportGoals.filter(function (value) {
      return value.goalAmount > 1;
    });
    if (goals.length > 0) {
      goals[0].goalTimeHorizon = yearsToRetirement;
      return goals;
    }
  };
  useEffect(() => {}, []);

  return (
    <div className="graphicContainer">
      <div className="graphicHeader">Your goals roadmap</div>
      <div className="graphicIntro">
        Here’s your goals roadmap. It shows all your goals, and when you’re
        going to need to achieve them:{" "}
      </div>
      <div className="graphic">
        {reportGoals && <ChartWrapper data={prepareData()} />}
      </div>
    </div>
  );
}

export default AmmoniteReportGraphic;
