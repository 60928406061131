import React, { Fragment, useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";

import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";

import { setDirty } from "../../Utils/reducers/actions";

import "./button.css";

function AmmoniteButton({
  buttonText,
  fontWeight,
  fontSize,
  lineHeight,
  buttonLink,
  submit,
  reset,
  handlerFunction,
  color,
  borderColor,
  borderRadius,
  backgroundColor,
  width,
  height,
  icon,
  iconLeft,
  iconRight,
  disabled,
}) {
  const { push } = useHistory();
  const context = useContext(AmmoniteContext);
  const dispatch = context.dispatch;
  const [dirtyPage, setDirtyPage] = useState("false");
  const clickHandler = () => {
    if (context.state.clean === true) {
      dispatch({ type: setDirty });
    }

    if (submit) {
      submit();
    } else if (reset) {
      reset();
    } else if (handlerFunction) {
      handlerFunction();
    }
    setDirtyPage("true");
  };

  useEffect(() => {
    if (dirtyPage === "true") {
      push(buttonLink);
    }
  }, [dirtyPage]);

  return (
    <Fragment>
      {borderColor ? (
        <Button
          variant="outlined"
          onClick={clickHandler}
          style={{
            width: width,
            height: height,
            paddingRight: "18px",
            borderRadius: borderRadius ? borderRadius : "8px",
            borderColor: borderColor,
            borderWidth: "2px",
            backgroundColor: backgroundColor,
            // marginRight: '30px',
            textTransform: "none",
            fontFamily: "Brother 1816",
            fontStyle: "normal",
            fontWeight: fontWeight ? fontWeight : "normal",
            fontSize: fontSize ? fontSize : "18px",
            lineHeight: "25px",
            letterSpacing: "0.02em",
          }}
        >
          <Fragment>
            {iconLeft && icon}
            <span style={{ color: color }}>{buttonText}</span>
            {iconRight && icon}
          </Fragment>
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={clickHandler}
          color={color}
          disabled={disabled}
          style={{
            width: width,
            height: height,
            borderRadius: borderRadius ? borderRadius : "8px",
            // marginRight: '30px',
            textTransform: "none",
            fontFamily: "Brother 1816",
            fontStyle: "normal",
            fontWeight: fontWeight,
            fontSize: fontSize ? fontSize : "18px",
            lineHeight: lineHeight ? lineHeight : "25px",
            letterSpacing: "0.02em",
          }}
        >
          {iconLeft && icon}
          <span>{buttonText}</span>
          {iconRight && icon}
        </Button>
      )}
    </Fragment>
  );
}

export default AmmoniteButton;
