// Core React dependencies
import React, { useState, useEffect, useContext }  from 'react';
import { useHistory } from 'react-router';
import SuitabilityReportContext from '../../../Utils/contexts/suitabilityReportContext';
import { saveContextToFirebase } from '../../../Utils/data/saveSuitabilityContext'

import { 
    addChild,
    changeChild,
} from '../../../Utils/reducers/suitabilityReportActions';

import SuitabilityReportNavigation from './../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation';

// Component imports
import Child from '../../../containers/SuitabilityReport/Child/child';

// Styling
import './childrenDetails.css';
import styles from '../../../Styles/srbstyles.module.css'


function ChildrenDetails() {
    const { push } = useHistory();
    const { state, status, error, dispatch } = useContext(SuitabilityReportContext);

    const addChildHandler = () => {
        dispatch({ type: addChild })
    }

    const handleChildChange = (field, key, value) => { 
        dispatch({ type: changeChild, payload: {
            field: field,
            key: key,
            value: value
        } })
    }
    
    const exitHandler = () => {
        push('/suitabilitydashboard');
    }

    const backHandler = () => {
        push('/furthercustomerinformation');
    }

    const saveHandler = async () => {
        await saveContextToFirebase(state).then(() => console.log("SAVED"))
    }

    useEffect(() => {

    }, [])


	return <div className={styles.suitabilityPanel}>
        <div className={styles.pageTitleText}>Children details</div>
        <div className={styles.pageInfoText}>Add and delete children and edit child details here:</div>
        {!status !== "success"  && <div className="childPanelCols">
            <div className="childPanel">
                {state && state.customerInfo.children.length > 0 && state.customerInfo.children.slice(0, 3).map((child) => <Child key={child.id} 
                    id={child.id}
                    childAge={child.age} 
                    childName={child.name} 
                    financiallyDependent={child.financiallyDependent} 
                    handleChildChange={(field, key, value) => handleChildChange(field, key, value)}
                    className="childDetails"/>)}
            </div>
            <div className="childPanel">
                {state && state.customerInfo.children.slice(3, 6).map((child) => <Child key={child.id}
                    id={child.id}
                    childAge={child.age} 
                    childName={child.name} 
                    financiallyDependent={child.financiallyDependent} 
                    handleChildChange={(field, key, value) => handleChildChange(field, key, value)}
                    className="childDetails"/>)}
            </div>
            <div className="childPanel">
                {state && state.customerInfo.children.slice(6, 9).map((child) => <Child key={child.id}
                    id={child.id}
                    childAge={child.age} 
                    childName={child.name} 
                    financiallyDependent={child.financiallyDependent} 
                    handleChildChange={(field, key, value) => handleChildChange(field, key, value)}
                    className="childDetails"/>)}
            </div>
        </div>}
        <div className="addChild" onClick={addChildHandler}>
            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M19 1C9.05888 1 1 9.05888 1 19C1 28.9411 9.05888 37 19 37C28.9411 37 37 28.9411 37 19C37 9.05888 28.9411 1 19 1Z" fill="white" stroke="#3BB9C4" strokeWidth="2"/>
                <path d="M18 11.5C17.7239 11.5 17.5 11.7239 17.5 12V17.5H12C11.7239 17.5 11.5 17.7239 11.5 18V20C11.5 20.2761 11.7239 20.5 12 20.5H17.5V26C17.5 26.2761 17.7239 26.5 18 26.5H20C20.2761 26.5 20.5 26.2761 20.5 26V20.5H26C26.2761 20.5 26.5 20.2761 26.5 20V18C26.5 17.7239 26.2761 17.5 26 17.5H20.5V12C20.5 11.7239 20.2761 11.5 20 11.5H18Z" fill="#3BB9C4" stroke="#3BB9C4" stroke-linecap="round" strokeLinejoin="round"/>
            </svg>
            <div className="addChildLabel">Add Child</div>
        </div>
        <div className={styles.menuBar}>
            <div className={styles.menuChips}>
                <SuitabilityReportNavigation
                    showBack
                    showSave
                    handleExit={exitHandler}
                    handleBack={backHandler}
                    handleSave={saveHandler}
                />
            </div>
        </div>
    </div>;
}

export default ChildrenDetails;

