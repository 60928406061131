import React from 'react';

function AmmoniteProtectedIcon() {
	return (
		<svg width="77" height="75" viewBox="0 0 77 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M38.5 12.957L16.0417 25.2633V25.7164C16.0417 40.5289 24.2743 54.2445 37.5311 61.5102L38.5 62.0414L39.4689 61.5102C52.7258 54.2445 60.9583 40.5289 60.9583 25.7164V25.2633L38.5 12.957ZM38.5 68.282C37.4124 68.282 36.3247 68.0195 35.359 67.4883L34.3869 66.9539C19.112 58.5883 9.625 42.7852 9.625 25.7164V25.2633C9.625 23.0164 10.8762 20.9289 12.8911 19.8195L35.3558 7.51328C37.2969 6.45078 39.7063 6.44766 41.641 7.51016L64.1025 19.8164C66.1237 20.9289 67.375 23.0164 67.375 25.2633V25.7164C67.375 42.7852 57.888 58.5883 42.6131 66.9539L41.6442 67.4852C40.6752 68.0164 39.5876 68.282 38.5 68.282Z" fill="#69306D"/>
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="9" y="6" width="59" height="63">
            <path fillRule="evenodd" clipRule="evenodd" d="M38.5 12.957L16.0417 25.2633V25.7164C16.0417 40.5289 24.2743 54.2445 37.5311 61.5102L38.5 62.0414L39.4689 61.5102C52.7258 54.2445 60.9583 40.5289 60.9583 25.7164V25.2633L38.5 12.957ZM38.5 68.282C37.4124 68.282 36.3247 68.0195 35.359 67.4883L34.3869 66.9539C19.112 58.5883 9.625 42.7852 9.625 25.7164V25.2633C9.625 23.0164 10.8762 20.9289 12.8911 19.8195L35.3558 7.51328C37.2969 6.45078 39.7063 6.44766 41.641 7.51016L64.1025 19.8164C66.1237 20.9289 67.375 23.0164 67.375 25.2633V25.7164C67.375 42.7852 57.888 58.5883 42.6131 66.9539L41.6442 67.4852C40.6752 68.0164 39.5876 68.282 38.5 68.282Z" fill="white"/>
            </mask>
            <g mask="url(#mask0)">
            </g>
        </svg>
	);
}

export default AmmoniteProtectedIcon;
