export const providerParagraphs = {
  //Investment Solution
  "Vanguard LifeStrategy": [
    "Vanguard is one of the largest asset managers in the world with approximately $6.2 trillion in global assets under management. It was a pioneer in the creation of low cost passive index funds to retail investors. The Vanguard LifeStrategy funds range has proven to be very popular with customers over the past decade. In addtion to offering a low cost (0.22% per annum) investment solution, we value Vanguard's high quality strategic asset allocation, global diversification and continuous rebalancing.",
  ],
  "BlackRock MyMap": [
    "BlackRock is the largest asset management company worldwide with managed assets amounting to $7.43 trillion. The BlackRock MyMap funds are a range of multi-asset funds. They’re made up of a portfolio of passive ‘Exchange Traded Funds’ (ETFs), diversified over a wide range of geographies and asset classes. Due to its size, BlackRock can keep the costs extremely competitive, so you get global diversification at only 0.17% per year. In addition to offering a low cost investment solution, we value BlackRock's high quality strategic asset allocation, global diversification and continuous rebalancing.",
  ],
  "Tatton Global Core": [
    "Tatton Investment Management was launched in 2013 and today they rank among the fastest growing investment managers in the UK. Tatton are the largest model portfolio provider with £7.8 billion of assets under management, as at September 2020. They were awarded ‘Best Investment Product Provider 2017’ at the Wealth Adviser Awards, and hold a 5 star rating by Defaqto.",
    "As well as the benefits of experience and size, Tatton are also one of the lowest cost model portfolio providers in the market.  We've been impressed by their performance, and particularly recommend their Global Core range because of the wide global diversification and ability to actively spot market opportunities.",
  ],
  "EQ Future Leaders": [
    "EQ Investors is a London based, award winning asset management business focused on sustainable investing. EQ's Future Leaders global model portfolios are the first range of passive, multi-asset, sustainable portfolios available in the UK.  Future Leaders portfolios select the most ethically minded and sustainable companies based on published Environmental, Social and Governance (‘ESG’) data.  They also use thematic funds to provide an overweight to sustainable sectors (ie, clean energy, healthcare and green bonds).  All this means that Future Leaders portfolios have a lower carbon footprint than market benchmarks.  The EQ Future Leaders portfolios offer a low cost, sustainable (green) impact investment solution with a strong track record in ESG.",
  ],
  "BlackRock MyMap ESG": [
    "BlackRock is the largest asset management company worldwide with managed assets amounting to $7.43 trillion. The BlackRock MyMap funds are a range of multi-asset funds. They’re made up of a portfolio of passive ‘Exchange Traded Funds’ (ETFs), diversified over a wide range of geographies and asset classes. Due to its size, BlackRock can keep the fund costs extremely competitive at just 0.17% per annum. In addtion to offering a low cost investment solution, we value BlackRock's high quality strategic asset allocation, global diversification and continuous rebalancing. The BlackRock MyMap 5 ESG fund is designed around sustainable and ethical investing, focusing on issues of Environmental, Social and Governance.  The fund considers a broad spectrum of ESG themes when selecting its investments, for example; climate change, natural resources, pollution and waste, environmental opportunities, human capital, social opportunities, corporate governance and corporate behaviour. ",
  ],
  "Cazenove Discretionary Fund Management": [
    "Cazenove Capital’s dedicated Discretionary Fund Management team (DFM) are supported by the depth of investment resource of the Schroder Group, which is one of the largest wealth managers in the UK (managing £67.5 billion of assets worldwide). We work closely with Cazenove to tailor and manage a portfolio to suit your risk profile and financial life goals.",
    "Cazenove Capital has four risk categories below, which serve as starting points, and which are then tailored for each individual client.",
    "The categories are Cautious, Balanced, Growth and Aggressive.",
    "The manager selection team conduct in-depth research with fund managers, testing not only that their views are consistent with their own, but that the structure of their funds appropriately reflect their stated strategies.",
    "Portfolios are constantly reviewed and altered as appropriate to keep them aligned with developing market conditions and client objectives.",
    "Cazenove Capital can access a wide range of asset classes including ‘alternatives’ such as absolute return funds (including hedge funds) and structured products.  The DFM offering has consistently delivered above average performance over 3, 5 and 7 years and due to our relationship with Cazenove Capital our customers can gain access at a reduced cost.",
    "Based on their strong performance, excellent reporting and client-centric approach, we recommend Cazenove Capital DFM for your investment solution.",
  ],
  //Providers
  Aviva: [
    "We’ve looked at the whole of the market, and recommend Aviva to you because we think it has the best combination of value for money, clear reporting, customer friendly online portal, and financial strength.",
    "Clients can also hold a number of different tax wrappers with Aviva (e.g. pension, ISA, Investment Portfolio), and see them all in the same place. This makes it easier for us and you to plan around your goals, and see how you’re progressing.",
  ],
  "Curtis Banks": [
    "We’ve looked at the whole of the market, and recommend the Future SIPP (Self Invested Personal Pension) with Curtis Banks to you because we think it has the best combination of value for money, clear reporting, customer friendly online portal, and financial strength, while enabling you to invest in our recommended Dicretionary Fund Management solution (see below).   There are no set-up fees, and you will get online access to be able to view your pension online. Curtis Banks has years of experience administering self-invested personal pensions. They look after the pensions of over 76,300 clients with a value in excess of £28.6 billion.",
  ],
  Cazenove: [
    "You are able to hold ISAs and GIAs directly with Cazenove, investing in their Discretionary Fund Management solution.  This means that, where appropriate, we can easily move monies from a GIA into an ISA to utilise your ISA allowance, and within GIAs can manage the portfolio to utilise your CGT allowance on an ongoing basis (to reduce your overall CGT liability).  ",
  ],
};
