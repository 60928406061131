import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthConsumer } from '../../Utils/contexts/AuthContext.js';

const ProtectedRoute = ({ component: Component, ...rest }) => {

  
  return (
    <AuthConsumer>
  {({ currentUser }) => (    <Route {...rest} render={
          props => {
            if ({ currentUser }) {
              return <Component {...rest} {...props} />
            } else {
              return <Redirect to={
                {
                  pathname: '/unauthorized',
                  state: {
                    from: props.location
                  }
                }
              } />
            }
          }
        } />)}
      </AuthConsumer>
  )
}

export default ProtectedRoute; 

