// Core React dependencies
import React, { useState, useEffect, useContext } from 'react';

// Component imports
import Page from '../../containers/Page/page';
import AmmoniteContext from '../../Utils/contexts/AmmoniteContext';
import AmmoniteArrowButton from '../../Assets/SVG/arrow';
import AmmoniteAboutGoalCard from '../../containers/AboutGoalCard/AboutGoalCard';

// Actions
import { refreshContextFromCache, resetGoals } from '../../Utils/reducers/actions';

// Styling
import './furtherInfo.css';


function FurtherInfo() {
	const context = useContext(AmmoniteContext);
	const [ activeStep, setActiveStep ] = useState(context.state.goalsActiveStep);
	const [ maxSteps, setMaxSteps ] = useState(context.state.customerGoals.length);
	const [ newGoals, setNewGoals ] = useState(context.state.customerGoals);

	const dispatch = context.dispatch;
	let name = '';
	if (context.state.customerInfo.couple === true) {
		name =
			context.state.customerInfo.customers.customer1.customerFirstName +
			' and ' +
			context.state.customerInfo.customers.customer2.customerFirstName;
	} else {
		name = context.state.customerInfo.customers.customer1.customerFirstName;
	}

	const handleNext = () => {
		const _tempStep = parseInt(activeStep);
		if (_tempStep < maxSteps - 1) {
			let nextStep = _tempStep + 1;
			setActiveStep(nextStep);
		}
	};

	const handleBack = () => {
		const _tempStep = parseInt(activeStep);
		if (_tempStep > 1) {
			let nextStep = _tempStep - 1;
			setActiveStep(nextStep);
		}
	};

	const resetFurtherInfo = () => {
		dispatch({ type: resetGoals });
	};

	const checkAllGoalsValid = () => {
		let valid = false;
		if (context.state.customerGoals.length > 1) {
			for (let goal of context.state.customerGoals) {
				if (goal.goalType !== "Retirement pot" && goal.amountRequiredToday === 0 || goal.amountRequiredToday === '' || isNaN(goal.amountRequiredToday)) {
					valid = false;
					break;
				} else {
					valid = true;
				}
			}
		}else{valid = true}
		return valid;
	};

	useEffect(
		() => {
			checkAllGoalsValid();
			if (context.state.clean === true && localStorage.ammoniteContext) {
				dispatch({ type: refreshContextFromCache, payload: JSON.parse(localStorage.getItem('ammoniteContext')) });
				setActiveStep(1)
				setMaxSteps(JSON.parse(localStorage.getItem('ammoniteContext')).state.customerGoals.length)				
			}

		},
		[ activeStep, newGoals ]
	);

	return (
		<Page
			headerText={name + ', please tell us about your goals'}
			introText={' '}
			progress={0.5}
			startOver
			backLink="/chooseyourgoals"
			forwardLink="/yourretirementspending"
			reset={resetFurtherInfo}
			isValid={checkAllGoalsValid()}
		>
			<div className="aboutYourGoals">
				<div className="leftPanel">
					{activeStep !== 1 && <div className="leftArrow" onClick={() => handleBack()}>
						<AmmoniteArrowButton disabled={activeStep === 1}/>
					</div>}
				</div>
				<div className="centrePanel">
					<div className="goalInformation">
					{newGoals.length <=1 && (
							<AmmoniteAboutGoalCard
								index={0}
							/>
						)}
						{newGoals.length > 1 && (
							<AmmoniteAboutGoalCard
								index={activeStep}
								goalName={newGoals[activeStep].goalName}
								goalType={newGoals[activeStep].goalType}
								amountRequiredToday={newGoals[activeStep].amountRequiredToday}
								goalTimeHorizon={newGoals[activeStep].goalTimeHorizon}
								goalImportance={newGoals[activeStep].goalImportance}
							/>
						)}
					</div>
				</div>
				{newGoals.length > 1 && <div className="rightPanel">
					{activeStep !== maxSteps - 1 && <div className="rightArrow" onClick={() => handleNext()}>
						<AmmoniteArrowButton disabled={activeStep === maxSteps - 1}/>
					</div>}
				</div>}
			</div>
		</Page>
	);
}

export default FurtherInfo;
