import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import AmmonitePlusButton from '../../../Assets/SVG/plusButton';


// Component imports
import SuitabilityReportNavigation from '../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation';

import { saveContextToFirebase } from '../../../Utils/data/saveSuitabilityContext'

import SuitabilityReportContext from '../../../Utils/contexts/suitabilityReportContext';
import { 
    initialiseSBReport,

} from '../../../Utils/reducers/suitabilityReportActions';

import NewLOAAdviceCard from '../../../containers/NewLOAAdviceCard/newLOAAdviceCard';

// Styling
import './newLOAAdvice.css';
import styles from '../../../Styles/srbstyles.module.css'


function NewLOAAdvice() {
    const { push } = useHistory();
    const { state, status, error, dispatch } = useContext(SuitabilityReportContext);

    const exitHandler = () => {
        push('/suitabilitydashboard');
    }

    const saveHandler = async () => {
        await saveContextToFirebase(state).then(() => console.log("SAVED"))
    }

    useEffect(() => {
    
    }, [])

	return <div className={styles.suitabilityPanel}>
        <div className={styles.pageTitleText}>LOA Builder</div>
            {!status !== "success" ? <div className={styles.currentPension}>
                <NewLOAAdviceCard/>
            </div> : ""}
        <div className={styles.menuBar}>
            <div className={styles.menuChips}>
                <SuitabilityReportNavigation
                    showSave
                    handleExit={exitHandler}
                    handleSave={saveHandler}
                />
            </div>
        </div>
    </div>;
}

export default NewLOAAdvice;

