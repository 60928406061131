import React from 'react';

function AmmoniteHiFive({ back }) {
	return (

<svg width="318" height="232" viewBox="0 0 318 232" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.1" d="M184.665 30.2475C164.354 29.6355 145.01 23.971 126.539 17.7573C108.067 11.5435 89.7708 4.6739 69.9465 1.38502C57.1958 -0.730165 42.6123 -1.0283 32.3408 4.8873C22.4522 10.577 19.2574 20.4028 17.5408 29.5194C16.2479 36.3765 15.4884 43.5945 19.0284 50.0153C21.4887 54.4748 25.8509 58.2219 28.8699 62.4899C39.3736 77.3495 31.9485 95.6706 20.5692 110.191C15.2342 116.998 9.03617 123.488 4.91564 130.734C0.79512 137.98 -1.1098 146.278 2.49605 153.665C6.06738 160.99 14.5815 166.491 23.8048 170.361C42.537 178.207 64.602 180.469 86.1336 181.743C133.782 184.568 181.681 183.344 229.454 182.12C247.135 181.668 264.891 181.21 282.284 178.844C291.943 177.532 301.913 175.448 308.927 170.414C317.827 164.025 320.034 153.207 314.068 145.195C304.063 131.76 276.409 128.421 269.407 114.001C265.56 106.064 269.511 97.224 275.106 89.8617C287.107 74.07 307.22 60.2147 308.278 42.1603C309.006 29.7642 299.337 17.3493 284.386 11.4839C268.717 5.33293 246.985 6.10808 235.43 16.2854C223.52 26.7515 202.6 30.7841 184.665 30.2475Z" fill="#3BB9C4"/>
<path opacity="0.4" d="M75.8526 200.775C73.1851 200.505 70.3983 200.216 67.8721 200.922C65.3458 201.628 63.2526 203.69 64.0026 205.661C65.6784 210.054 75.5137 206.995 80.0485 209.998C81.6678 211.071 82.2986 212.76 83.5633 214.078C85.6094 216.218 89.1274 217.216 92.523 217.765C112.922 221.063 136.176 212.574 154.055 220.75C159.845 223.398 164.411 227.613 170.76 229.38C179.17 231.721 188.381 229.22 197.083 227.616C207.936 225.633 218.999 225.053 230 225.89C238.138 226.518 248.962 226.728 251.566 220.819C253.242 217.018 249.696 212.788 244.989 210.99C240.281 209.191 234.77 209.248 229.514 209.42C219.368 209.785 209.219 210.519 199.073 210.161C179.453 209.464 160.918 201.688 141.812 201.43C119.562 201.161 98.2064 203.028 75.8526 200.775Z" fill="#BDBDBD"/>
<path d="M205.522 63.2793H196.421V72.851H205.522V63.2793Z" fill="#2B9BA5"/>
<path d="M185.362 173.798C184.562 177.799 183.375 180.909 181.179 182.742C178.982 184.575 175.897 189.489 175.897 189.489C175.843 196.026 174.117 198.013 174.117 198.013L172.338 198.483H167.945C167.945 198.483 167.16 196.287 167.003 194.981C166.95 194.545 167.26 193.814 167.693 193.029C169.264 190.153 170.24 186.991 170.565 183.73L170.769 181.8C170.399 169.822 175.162 165.933 177.162 164.826C177.438 164.672 177.672 164.453 177.846 164.188C178.019 163.924 178.125 163.621 178.156 163.307L178.511 159.883L190.33 160.463C190.33 160.463 190.079 161.505 190.527 163.915C190.618 164.414 190.741 164.97 190.904 165.588C191.845 169.194 185.412 173.534 185.362 173.798Z" fill="#FBBEBE"/>
<path d="M205.403 165.525C204.998 167.278 204.438 168.99 203.73 170.644C203.115 172.122 202.774 173.7 202.726 175.301C202.726 175.354 202.726 175.408 202.726 175.461C202.573 180.205 201.589 184.885 199.817 189.288C198.081 193.632 195.781 199.283 195.091 200.473C193.942 202.459 194.62 205.808 194.62 205.808L187.967 205.337L188.083 194.143C187.389 186.15 189.944 178.766 191.328 175.43C191.887 174.095 192.206 172.673 192.269 171.228L192.583 163.802L192.68 161.606C192.68 161.606 204.443 159.933 205.434 162.6C205.707 163.307 205.641 164.358 205.403 165.525Z" fill="#FBBEBE"/>
<path opacity="0.1" d="M190.528 163.915L178.144 163.307L178.499 159.883L190.33 160.463C190.33 160.463 190.069 161.502 190.528 163.915Z" fill="black"/>
<path opacity="0.1" d="M205.403 165.525L192.58 163.802L192.677 161.606C192.677 161.606 204.439 159.933 205.431 162.6C205.707 163.307 205.641 164.358 205.403 165.525Z" fill="black"/>
<path d="M212.141 142.622L207.016 165.114L191.849 163.074V155.332L190.537 163.287L172.335 162.396L173.276 144.979C174.252 141.503 174.934 137.951 175.316 134.362C175.671 130.426 177.535 120.72 178.436 116.16C178.75 114.657 178.925 113.709 178.925 113.709C178.925 113.709 210.101 120.193 212.141 122.546C212.489 122.948 212.718 123.802 212.859 124.935C213.543 130.433 212.141 142.622 212.141 142.622Z" fill="#0C3957"/>
<path opacity="0.1" d="M175.737 144.087C175.737 144.087 188.007 137.654 190.998 139.013L175.737 144.087Z" fill="black"/>
<path opacity="0.1" d="M182.43 123.059C182.43 123.059 192.63 130.801 192.944 132.891L182.43 123.059Z" fill="black"/>
<path opacity="0.1" d="M179.66 149.734C179.66 149.734 187.035 149.421 189.388 151.304L179.66 149.734Z" fill="black"/>
<path opacity="0.1" d="M192.944 141.457C192.944 141.457 196.45 152.93 194.931 156.069C193.412 159.207 191.846 155.334 191.846 155.334L192.944 141.457Z" fill="black"/>
<path d="M188.02 201.262C188.02 201.262 184.568 201.786 185.195 210.152C185.823 218.519 186.765 219.988 188.02 220.091C189.275 220.195 195.395 221.714 195.081 218.626C194.767 215.538 194.453 208.69 194.871 207.642C195.288 206.594 196.01 203.835 194.428 203.154L194.086 204.767C194.086 204.767 190.738 201.679 188.751 203.929C188.666 203.381 188.528 202.842 188.34 202.319C188.142 201.83 187.709 201.576 188.02 201.262Z" fill="#0C3957"/>
<path d="M251.196 98.5836C251.196 98.5836 250.405 99.0261 249.244 99.5784C247.452 100.432 244.782 101.556 242.805 101.722C239.547 101.998 238.527 102.312 234.802 99.6443C231.943 97.5982 227.157 94.7455 225.073 93.5279L224.166 92.9912L224.075 92.9379V92.9002L225.805 88.1489L231.924 84.8945L233.443 86.1969L237.965 90.0726C237.965 90.0726 238.671 91.2087 239.811 91.1302C240.95 91.0518 247.029 91.5602 247.029 91.5602L248.177 91.7485L250.597 92.1502L251.196 98.5836Z" fill="#FBBEBE"/>
<path opacity="0.1" d="M251.196 98.5835C251.196 98.5835 250.405 99.026 249.244 99.5783C248.017 98.9507 246.492 97.7738 247.079 95.8375C247.609 94.0958 247.753 92.7369 248.19 91.7578L250.609 92.1595L251.196 98.5835Z" fill="black"/>
<path d="M250.374 90.1104C250.374 90.1104 257.2 88.1489 256.692 96.3869C256.183 104.625 250.923 100.115 250.923 100.115C250.923 100.115 246.451 98.9382 247.393 95.8377C248.334 92.7371 248.061 90.8573 250.374 90.1104Z" fill="#FBBEBE"/>
<path d="M179.606 63.4349L172.495 66.4225L172.385 66.4696L172.307 66.4319L171.864 66.2091C170.025 65.2928 163.705 62.1576 159.729 60.2967C155.021 58.0999 155.753 55.9031 155.753 55.9031L155.075 32.8903L153.87 28.7572C153.87 28.7572 153.019 14.7481 159.832 11.2364C166.645 7.72469 162.239 31.5283 162.239 31.5283L162.767 36.4742C163.107 39.6382 163.732 42.765 164.634 45.8168C164.928 46.8201 165.114 47.8523 165.186 48.8954C165.396 52.2408 166.966 52.018 166.966 52.018C169.444 52.4071 171.837 53.2203 174.039 54.4219C174.857 54.8537 175.64 55.3489 176.38 55.9031L179.606 63.4349Z" fill="#FBBEBE"/>
<path d="M204.923 74.4191L198.853 73.7914C198.853 73.7914 200.642 71.1302 201.43 68.5412C201.541 68.1862 201.63 67.8246 201.697 67.4585C201.879 66.4323 201.831 65.4814 201.364 64.7941C199.588 62.1799 211.93 58.5176 211.93 58.5176C211.78 59.9644 211.735 61.4202 211.795 62.8735C211.831 63.8848 211.936 64.8924 212.109 65.8893C212.483 67.9198 213.226 69.831 214.645 70.8101C218.521 73.4776 204.923 74.4191 204.923 74.4191Z" fill="#FBBEBE"/>
<path opacity="0.1" d="M204.923 74.4184L198.853 73.7908C198.853 73.7908 200.642 71.1295 201.43 68.5405C202.13 69.5353 202.371 69.5133 203.354 69.7111C203.354 69.7111 209.479 70.618 212.116 65.8887C212.489 67.9191 213.233 69.8303 214.651 70.8094C218.521 73.477 204.923 74.4184 204.923 74.4184Z" fill="black"/>
<path opacity="0.1" d="M225.086 93.5279C225.079 93.7144 225.087 93.9013 225.111 94.0865L224.17 92.9912L224.091 92.9002L225.82 88.1489L231.94 84.8945L233.459 86.1969C231.284 87.0285 225.183 89.6678 225.086 93.5279Z" fill="black"/>
<path opacity="0.1" d="M179.606 63.4365L172.495 66.4241H172.307L171.758 66.4618C171.758 66.4618 171.798 66.3708 171.864 66.2013C172.457 64.7609 175.21 57.7281 174.02 54.4141C174.838 54.8459 175.621 55.3411 176.361 55.8953L179.606 63.4365Z" fill="black"/>
<path opacity="0.1" d="M212.86 124.926C210.092 124.678 182.4 122.118 179.817 118.041C179.409 117.377 178.947 116.747 178.436 116.158C178.75 114.655 178.926 113.707 178.926 113.707C178.926 113.707 210.101 120.191 212.141 122.544C212.489 122.94 212.718 123.79 212.86 124.926Z" fill="black"/>
<path d="M203.354 70.0285C203.354 70.0285 209.545 70.9449 212.163 66.1339C212.163 66.1339 216.534 69.7241 219.148 70.0316C221.763 70.3392 233.898 85.6162 233.898 85.6162C233.898 85.6162 224.17 88.7544 224.797 93.7757L220.193 88.4406C220.193 88.4406 217.893 88.3339 215.907 94.7171C215.907 94.7171 213.5 98.3763 213.813 109.988C214.127 121.599 213.082 124.32 213.082 124.32C213.082 124.32 182.534 121.706 179.817 117.416C177.099 113.126 176.678 115.219 176.678 115.219C176.678 115.219 176.258 107.06 179.082 100.47C181.907 93.8792 181.279 74.9461 181.279 74.9461C181.279 74.9461 181.593 65.635 172.388 66.4729C172.388 66.4729 176.258 57.4755 174.482 54.0234C174.482 54.0234 179.776 55.4608 183.548 55.5047C185.863 55.5227 188.145 56.0588 190.226 57.0738C191.359 57.6293 192.404 58.3291 192.997 59.1639C194.774 61.6745 197.705 60.5259 197.705 60.5259C198.443 62.8233 199.356 65.0611 200.435 67.2198C200.557 67.4489 200.68 67.6748 200.805 67.8914C200.987 68.2052 201.176 68.519 201.364 68.7763C202.117 69.8528 202.33 69.8245 203.354 70.0285Z" fill="#2B9BA5"/>
<path opacity="0.1" d="M211.795 62.8637C211.462 63.3375 211.09 63.7836 210.685 64.1975C209.664 65.2448 208.444 66.0771 207.096 66.6452C205.749 67.2133 204.301 67.5057 202.839 67.5052C202.461 67.5054 202.082 67.4865 201.706 67.4487C201.888 66.4225 201.841 65.4716 201.373 64.7843C199.597 62.1702 211.94 58.5078 211.94 58.5078C211.787 59.9544 211.738 61.4102 211.795 62.8637Z" fill="black"/>
<path d="M213.813 56.2164C213.813 58.3888 213.169 60.5124 211.962 62.3187C210.755 64.125 209.04 65.5328 207.033 66.3642C205.026 67.1955 202.817 67.413 200.687 66.9892C198.556 66.5654 196.599 65.5193 195.063 63.9831C193.527 62.447 192.481 60.4899 192.057 58.3592C191.633 56.2286 191.85 54.0201 192.682 52.013C193.513 50.006 194.921 48.2905 196.727 47.0836C198.534 45.8767 200.657 45.2325 202.83 45.2325C204.274 45.2271 205.704 45.5075 207.039 46.0576C208.374 46.6077 209.587 47.4166 210.608 48.4376C211.629 49.4586 212.438 50.6716 212.988 52.0067C213.538 53.3417 213.819 54.7724 213.813 56.2164Z" fill="#FBBEBE"/>
<path opacity="0.1" d="M219.255 81.3231L219.481 88.6729C219.481 88.6729 222.76 86.0305 222.864 83.5199C222.967 81.0093 219.255 81.3231 219.255 81.3231Z" fill="black"/>
<path opacity="0.1" d="M192.787 88.6738C192.787 88.6738 201.417 100.521 206.438 101.723C211.459 102.925 194.67 92.515 192.787 88.6738Z" fill="black"/>
<path opacity="0.1" d="M201.941 89.9062C201.941 89.9062 206.752 95.3417 210.204 96.0196L201.941 89.9062Z" fill="black"/>
<path opacity="0.1" d="M184.995 103.604C184.995 103.604 193.782 112.234 198.803 112.758L184.995 103.604Z" fill="black"/>
<path opacity="0.1" d="M213.813 56.2177C213.815 59.089 212.688 61.8459 210.675 63.8938L209.47 63.1438C209.004 62.9011 208.602 62.5516 208.296 62.1238C207.898 61.4585 208.014 60.6238 208.045 59.8486C208.111 58.2073 207.659 56.4311 206.376 55.4048C204.295 53.7384 200.959 54.6611 198.844 53.026C198.335 52.5935 197.886 52.0942 197.51 51.5417L195.527 48.8836C195.408 48.7266 195.263 48.5697 195.109 48.3846C196.654 46.8661 198.612 45.838 200.739 45.4295C202.866 45.0211 205.066 45.2505 207.063 46.089C209.06 46.9274 210.764 48.3375 211.962 50.1419C213.16 51.9463 213.797 54.0645 213.795 56.2302L213.813 56.2177Z" fill="black"/>
<path d="M196.211 42.151C197.193 41.401 198.392 41.0056 199.496 40.4532C200.601 39.9009 201.577 39.1979 202.682 38.7272C203.786 38.2564 205.064 37.9803 206.209 38.3726C208.666 39.2168 209.595 42.65 212.122 43.2525C212.548 43.353 212.988 43.3624 213.418 43.4283C214.238 43.5839 215.017 43.9119 215.701 44.3907C216.385 44.8694 216.96 45.4881 217.388 46.2056C218.231 47.6536 218.665 49.3035 218.643 50.9789C218.678 51.9088 218.543 52.8372 218.244 53.7186C217.497 55.7679 215.624 57.1707 214.359 58.9438C213.162 60.6406 212.527 62.6699 212.542 64.7464L209.479 62.8415C209.012 62.5998 208.61 62.2501 208.306 61.8216C207.907 61.1563 208.023 60.3215 208.054 59.5463C208.12 57.905 207.668 56.1288 206.388 55.1026C204.304 53.4362 200.968 54.3588 198.856 52.7238C198.347 52.2913 197.898 51.792 197.523 51.2394L195.539 48.5813C195.109 48.0039 194.284 47.2852 194.096 46.5822C193.678 45.0696 195.106 43.0015 196.211 42.151Z" fill="#0C3957"/>
<path opacity="0.1" d="M209.821 62.4855C209.355 62.2438 208.952 61.8941 208.648 61.4655C208.249 60.8002 208.365 59.9655 208.397 59.1903C208.463 57.549 208.011 55.7728 206.727 54.7466C204.647 53.0801 201.311 54.0028 199.195 52.3678C198.686 51.9352 198.237 51.4359 197.862 50.8834L195.878 48.2253C195.445 47.6478 194.623 46.9292 194.435 46.2262C194.093 45.0148 194.959 43.4363 195.885 42.4258C194.846 43.3861 193.707 45.2094 194.093 46.5683C194.293 47.2712 195.106 47.9899 195.536 48.5673L197.52 51.2254C197.895 51.778 198.344 52.2773 198.853 52.7098C200.959 54.3449 204.295 53.4222 206.385 55.0886C207.666 56.1148 208.117 57.8911 208.052 59.5324C208.02 60.3075 207.904 61.1423 208.303 61.8076C208.607 62.2362 209.009 62.5859 209.476 62.8276L212.539 64.7325C212.539 64.5504 212.539 64.3684 212.558 64.1896L209.821 62.4855Z" fill="black"/>
<path d="M167.496 193.396C167.496 193.396 164.439 194.024 165.067 202.406C165.695 210.789 166.636 212.242 167.891 212.345C169.147 212.449 175.266 213.968 174.952 210.88C174.639 207.792 174.325 200.944 174.742 199.896C175.159 198.848 176.816 196.033 175.235 195.352L173.958 197.021C173.958 197.021 170.609 193.933 168.622 196.183C168.622 196.183 166.765 194.128 167.496 193.396Z" fill="#0C3957"/>
<path opacity="0.1" d="M169.429 198.596C170.091 198.91 170.709 199.292 171.368 199.606C172.017 199.946 172.742 200.117 173.474 200.102C173.785 200.096 174.086 199.992 174.334 199.804" fill="black"/>
<path opacity="0.1" d="M189.545 206.789C189.937 207.15 190.242 207.611 190.684 207.909C191.312 208.33 192.115 208.355 192.881 208.364C193.009 208.376 193.137 208.36 193.258 208.317C193.394 208.243 193.51 208.135 193.593 208.004L194.005 207.473" fill="black"/>
<path d="M137.974 67.6184C137.974 67.6184 136.562 54.2306 142.054 57.5258C147.546 60.8209 143.764 66.6769 143.764 66.6769L142.474 70.81L137.974 67.6184Z" fill="#DBBD95"/>
<path d="M146.761 24.8352L149.899 21.7534C149.899 21.7534 155.916 4.43968 161.878 8.88658C167.841 13.3335 154.321 24.5778 154.321 24.5778L150.737 28.7611L146.761 24.8352Z" fill="#DBBD95"/>
<path d="M100.836 93.3067L100.679 96.8623C100.679 96.8623 103.557 101.884 104.759 102.251C105.961 102.618 105.857 91.6309 105.857 91.6309L100.836 93.3067Z" fill="#3F762C"/>
<path opacity="0.1" d="M100.836 93.3067L100.679 96.8623C100.679 96.8623 103.557 101.884 104.759 102.251C105.961 102.618 105.857 91.6309 105.857 91.6309L100.836 93.3067Z" fill="black"/>
<path d="M105.647 89.4023L105.861 102.508H120.451L119.352 94.8723L105.647 89.4023Z" fill="#DBBD95"/>
<path d="M109.05 122.119L109.731 126.302L118.257 145.239C118.257 145.239 122.127 151.201 118.988 156.223C115.85 161.244 112.608 169.089 112.084 173.847C111.56 178.605 107.43 183.262 107.43 183.262L116.428 187.081C116.428 187.081 117.055 179.602 119.566 176.097C122.076 172.592 130.129 157.164 130.129 157.164C130.129 157.164 132.693 154.861 132.119 148.01C131.544 141.159 131.595 136.345 131.595 136.345L130.707 125.414L109.05 122.119Z" fill="#0C3957"/>
<path opacity="0.1" d="M109.05 122.119L109.731 126.302L118.257 145.239C118.257 145.239 122.127 151.201 118.988 156.223C115.85 161.244 112.608 169.089 112.084 173.847C111.56 178.605 107.43 183.262 107.43 183.262L116.428 187.081C116.428 187.081 117.055 179.602 119.566 176.097C122.076 172.592 130.129 157.164 130.129 157.164C130.129 157.164 132.693 154.861 132.119 148.01C131.544 141.159 131.595 136.345 131.595 136.345L130.707 125.414L109.05 122.119Z" fill="black"/>
<path d="M108.211 182.689C108.211 182.689 98.5861 185.617 101.778 190.432C104.969 195.246 115.326 204.397 119.509 204.083C123.692 203.769 122.019 200.681 122.019 200.681L118.514 197.229C118.514 197.229 117.886 189.907 118.15 187.764C118.414 185.621 115.169 184.626 114.748 185.881C114.328 187.136 108.211 182.689 108.211 182.689Z" fill="#BF802F"/>
<path d="M114.277 96.9375L106.648 97.7817C105.967 97.857 105.338 98.1855 104.887 98.7021C104.436 99.2188 104.196 99.886 104.213 100.572C104.295 103.622 104.348 108.474 103.921 109.359C103.294 110.667 101.36 119.979 109.727 126.305L121.078 129.914L102.876 131.223L103.454 141.319L106.278 139.75L140.799 141.319C140.799 141.319 150.581 140.324 143.937 129.707C137.293 119.09 123.174 108.681 123.174 108.681L123.018 100.415L121.969 98.0108L114.277 96.9375Z" fill="#0C3957"/>
<path opacity="0.1" d="M123.746 129.755C124.983 129.214 126.318 128.936 127.668 128.936C129.018 128.936 130.354 129.214 131.591 129.755H123.746Z" fill="black"/>
<path d="M103.767 131.168C103.767 131.168 92.5229 129.549 92.4695 131.692C92.4162 133.836 94.0387 154.705 99.427 153.293C104.815 151.881 102.512 144.977 102.512 144.977L106.541 141.107C106.541 141.107 106.959 138.964 104.291 139.171L103.767 131.168Z" fill="#BF802F"/>
<path d="M122.386 72.6418L126.83 77.7163C126.83 77.7163 128.767 80.1234 131.067 76.461C133.367 72.7987 137.657 66.2617 137.657 66.2617C137.657 66.2617 142.418 70.445 143.673 69.6102C144.929 68.7755 132.115 87.0307 132.115 87.0307C132.115 87.0307 128.035 93.0435 121.966 85.9323L120.19 85.0944L122.386 72.6418Z" fill="#3F762C"/>
<path opacity="0.1" d="M122.386 72.6418L126.83 77.7163C126.83 77.7163 128.767 80.1234 131.067 76.461C133.367 72.7987 137.657 66.2617 137.657 66.2617C137.657 66.2617 142.418 70.445 143.673 69.6102C144.929 68.7755 132.115 87.0307 132.115 87.0307C132.115 87.0307 128.035 93.0435 121.966 85.9323L120.19 85.0944L122.386 72.6418Z" fill="black"/>
<path d="M108.792 52.0176C113.905 52.0176 118.05 47.8728 118.05 42.7598C118.05 37.6468 113.905 33.502 108.792 33.502C103.679 33.502 99.5339 37.6468 99.5339 42.7598C99.5339 47.8728 103.679 52.0176 108.792 52.0176Z" fill="#DBBD95"/>
<path opacity="0.1" d="M108.789 47.7459C108.789 47.7459 100.833 51.5118 101.775 58.1021C102.716 64.6924 102.873 73.0088 102.873 73.0088C102.873 73.0088 106.485 78.3438 101.696 88.5431C101.696 88.5431 98.6364 93.7212 100.676 97.4871C100.676 97.4871 102.939 91.5245 108.789 95.918C114.638 100.312 124.056 102.351 124.056 102.351C124.056 102.351 119.192 81.0113 122.173 78.03C122.173 78.03 127.351 70.3413 122.644 65.477C117.937 60.6127 120.29 56.0622 120.29 56.0622L135.407 47.9561C135.407 47.9561 138.596 48.0063 142.886 41.3124C145.663 37.0347 148.721 32.946 152.04 29.0733C152.04 29.0733 148.795 24.209 145.764 23.8418C145.764 23.8418 144.558 25.0971 143.724 28.499C143.278 30.3035 140.25 33.3193 137.51 35.7515C135.564 37.4775 133.688 39.2694 131.814 41.0865C130.427 42.4202 127.339 44.3283 120.604 45.4079C109.52 47.1747 108.789 47.7459 108.789 47.7459Z" fill="black"/>
<path d="M108.789 47.115C108.789 47.115 100.833 50.8809 101.775 57.4712C102.716 64.0616 102.873 72.3779 102.873 72.3779C102.873 72.3779 106.485 77.7129 101.696 87.9123C101.696 87.9123 98.6364 93.0904 100.676 96.8563C100.676 96.8563 102.939 90.8936 108.789 95.2871C114.638 99.6807 124.056 101.721 124.056 101.721C124.056 101.721 119.192 80.3805 122.173 77.3991C122.173 77.3991 127.351 69.7104 122.644 64.8461C117.937 59.9818 120.29 55.4314 120.29 55.4314L135.407 47.3253C135.407 47.3253 138.596 47.3755 142.886 40.6816C145.663 36.4038 148.721 32.3151 152.04 28.4424C152.04 28.4424 148.795 23.5781 145.764 23.2109C145.764 23.2109 144.558 24.4662 143.724 27.8681C143.278 29.6726 140.25 32.6885 137.51 35.1206C135.564 36.8466 133.688 38.6386 131.814 40.4556C130.427 41.7894 127.339 43.6974 120.604 44.777C109.52 46.5438 108.789 47.115 108.789 47.115Z" fill="#3F762C"/>
<path opacity="0.1" d="M115.64 86.5547C115.64 86.5547 121.809 95.6556 121.966 97.2247C121.97 97.2247 121.028 87.4428 115.64 86.5547Z" fill="black"/>
<path opacity="0.1" d="M107.533 55.9043C107.533 55.9043 111.836 61.0824 118.053 56.7422C116.335 57.3256 114.516 57.5522 112.707 57.4081C110.898 57.2641 109.138 56.7523 107.533 55.9043Z" fill="black"/>
<path opacity="0.1" d="M110.869 33.0784C110.757 32.6081 110.523 32.1754 110.191 31.8231C108.773 30.0437 107.232 28.2173 105.12 27.3731C103.718 26.8493 102.185 26.7879 100.746 27.1978C99.3066 27.6078 98.0363 28.4676 97.1207 29.6514C96.3612 30.6745 95.9093 31.8921 95.2534 32.9874C94.042 35.0147 92.1685 36.5775 90.1537 37.814C88.9392 38.5578 87.6588 39.2011 86.5134 40.0453C82.3489 43.1114 80.4252 48.7791 81.4733 53.8536C81.9347 56.0755 82.9107 58.266 82.6471 60.5224C82.4431 62.2736 81.511 63.8427 80.5664 65.3302C79.6218 66.8177 78.6144 68.3241 78.2378 70.0376C77.8612 71.7511 78.2566 73.7815 79.6783 74.8203C80.2024 75.2031 80.8331 75.4479 81.2819 75.9061C81.5789 76.2628 81.8269 76.6575 82.0194 77.0798C82.767 78.4589 83.7879 79.6712 85.0197 80.6424C86.2516 81.6136 87.6685 82.3235 89.184 82.7287C90.5335 83.0833 92.3443 82.9546 92.837 81.6491C92.8935 81.4186 92.9789 81.1961 93.0912 80.9869C93.5368 80.3593 94.5191 80.7108 95.2095 81.0717C96.7812 81.8933 98.5032 82.3876 100.271 82.5247C100.973 82.6172 101.686 82.5319 102.346 82.2768C102.903 81.9939 103.382 81.5791 103.742 81.0685C105.393 78.9188 105.867 76.1101 106.149 73.4143C106.335 71.6601 106.463 69.8744 106.127 68.1421C105.594 65.3616 103.902 62.7631 104.153 59.945C104.42 56.9322 106.865 54.4687 107.238 51.4717C107.428 49.9025 107.036 48.3182 106.137 47.0185C105.805 46.6172 105.524 46.1766 105.299 45.7067C105.192 45.4691 105.158 45.2047 105.203 44.9479C105.248 44.6911 105.369 44.4537 105.55 44.2663C105.749 44.1263 105.969 44.0202 106.203 43.9524C108.221 43.1773 109.297 41.1563 109.441 39.0882C109.498 38.3036 109.441 37.5818 109.658 36.8161C110.003 35.6329 111.048 34.34 110.869 33.0784Z" fill="black"/>
<path d="M110.241 33.0784C110.129 32.6081 109.895 32.1754 109.564 31.8231C108.145 30.0437 106.604 28.2173 104.492 27.3731C103.09 26.8493 101.557 26.7879 100.118 27.1978C98.6787 27.6078 97.4083 28.4676 96.4927 29.6514C95.7333 30.6745 95.2814 31.8921 94.6255 32.9874C93.4141 35.0147 91.5406 36.5775 89.5258 37.814C88.3113 38.5578 87.0309 39.2011 85.8854 40.0453C81.721 43.1114 79.7972 48.7791 80.8454 53.8536C81.3067 56.0755 82.2827 58.266 82.0191 60.5224C81.8151 62.2736 80.8831 63.8427 79.9385 65.3302C78.9939 66.8177 77.9865 68.3241 77.6099 70.0376C77.2333 71.7511 77.6287 73.7815 79.0503 74.8203C79.5744 75.2031 80.2052 75.4479 80.654 75.9061C80.951 76.2628 81.199 76.6575 81.3915 77.0798C82.139 78.4589 83.1599 79.6712 84.3918 80.6424C85.6237 81.6136 87.0406 82.3235 88.5561 82.7287C89.9055 83.0833 91.7163 82.9546 92.209 81.6491C92.2656 81.4186 92.351 81.1961 92.4632 80.9869C92.9089 80.3593 93.8911 80.7108 94.5815 81.0717C96.1533 81.8933 97.8753 82.3876 99.6435 82.5247C100.345 82.6172 101.058 82.5319 101.718 82.2768C102.275 81.9939 102.754 81.5791 103.114 81.0685C104.765 78.9188 105.239 76.1101 105.521 73.4143C105.707 71.6601 105.835 69.8744 105.5 68.1421C104.966 65.3616 103.274 62.7631 103.526 59.945C103.792 56.9322 106.237 54.4687 106.61 51.4717C106.8 49.9025 106.408 48.3182 105.509 47.0185C105.177 46.6172 104.896 46.1766 104.671 45.7067C104.564 45.4691 104.531 45.2047 104.575 44.9479C104.62 44.6911 104.741 44.4537 104.922 44.2663C105.121 44.1263 105.341 44.0202 105.575 43.9524C107.593 43.1773 108.669 41.1563 108.813 39.0882C108.87 38.3036 108.813 37.5818 109.03 36.8161C109.375 35.6329 110.42 34.34 110.241 33.0784Z" fill="#0C3957"/>
<g opacity="0.1">
<path opacity="0.1" d="M91.5282 80.9712C91.4162 81.1808 91.3298 81.4032 91.2709 81.6334C90.979 82.4117 90.2164 82.7695 89.366 82.8573C90.5491 82.9766 91.8138 82.691 92.2124 81.6334C92.2712 81.4032 92.3576 81.1808 92.4697 80.9712C92.5487 80.856 92.657 80.7639 92.7835 80.7045C92.3065 80.5664 91.8169 80.5727 91.5282 80.9712Z" fill="black"/>
<path opacity="0.1" d="M110.257 33.0746C110.148 32.6032 109.914 32.1697 109.579 31.8193C108.161 30.04 106.62 28.2135 104.508 27.3693C103.59 27.0109 102.604 26.8629 101.621 26.9362C102.288 26.9782 102.945 27.1243 103.567 27.3693C105.679 28.2135 107.219 30.04 108.638 31.8193C108.972 32.1697 109.206 32.6032 109.316 33.0746C109.495 34.3299 108.453 35.6323 108.108 36.7935C107.879 37.5592 107.944 38.2779 107.891 39.0656C107.744 41.1305 106.67 43.1453 104.649 43.9299C104.416 43.9976 104.195 44.1037 103.997 44.2437C103.815 44.4317 103.695 44.6699 103.65 44.9273C103.606 45.1848 103.64 45.4495 103.749 45.6873C103.972 46.1571 104.252 46.5976 104.583 46.9991C105.484 48.2973 105.877 49.8806 105.688 51.4491C105.311 54.4524 102.864 56.9128 102.6 59.9224C102.349 62.7468 104.044 65.339 104.574 68.1164C104.907 69.8487 104.781 71.6343 104.596 73.3886C104.313 76.0844 103.843 78.8962 102.189 81.0428C101.83 81.5533 101.352 81.9682 100.795 82.251C100.47 82.3935 100.123 82.4795 99.7693 82.5052C100.436 82.5846 101.112 82.4973 101.737 82.251C102.294 81.9756 102.776 81.5684 103.14 81.0648C104.794 78.9182 105.264 76.1063 105.547 73.4106C105.732 71.6563 105.861 69.8706 105.525 68.1383C104.994 65.361 103.3 62.7594 103.551 59.9444C103.821 56.9285 106.262 54.4681 106.639 51.4711C106.828 49.9025 106.435 48.3192 105.534 47.021C105.203 46.6196 104.923 46.179 104.699 45.7092C104.591 45.4715 104.557 45.2067 104.601 44.9493C104.645 44.6919 104.766 44.4537 104.947 44.2657C105.146 44.1256 105.367 44.0196 105.6 43.9518C107.621 43.1735 108.694 41.1525 108.842 39.0875C108.895 38.2998 108.842 37.5812 109.059 36.8154C109.394 35.6323 110.436 34.3394 110.257 33.0746Z" fill="black"/>
</g>
<path opacity="0.1" d="M98.376 143.832C98.9138 144.419 99.6062 144.843 100.374 145.055C101.141 145.267 101.953 145.259 102.716 145.031" fill="black"/>
<path opacity="0.1" d="M118.485 197.496C118.186 197.738 117.837 197.913 117.463 198.007C117.089 198.101 116.699 198.113 116.32 198.042" fill="black"/>
<path opacity="0.4" d="M33.5895 207.237C38.257 207.237 42.0408 206.597 42.0408 205.809C42.0408 205.02 38.257 204.381 33.5895 204.381C28.922 204.381 25.1382 205.02 25.1382 205.809C25.1382 206.597 28.922 207.237 33.5895 207.237Z" fill="#BDBDBD"/>
<path opacity="0.4" d="M57.5538 220.493C62.2214 220.493 66.0052 219.853 66.0052 219.065C66.0052 218.276 62.2214 217.637 57.5538 217.637C52.8863 217.637 49.1025 218.276 49.1025 219.065C49.1025 219.853 52.8863 220.493 57.5538 220.493Z" fill="#BDBDBD"/>
<path opacity="0.4" d="M120.444 231.532C136.175 231.532 148.927 229.377 148.927 226.718C148.927 224.06 136.175 221.904 120.444 221.904C104.713 221.904 91.9612 224.06 91.9612 226.718C91.9612 229.377 104.713 231.532 120.444 231.532Z" fill="#7A7A7A"/>
</svg>

	);
}

export default AmmoniteHiFive;
