export const riskMatrix = {
    "YEARS TO GOAL": {
        1: {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0
        },
        2: {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0
        },
        3: {
            0: 1,
            1: 2,
            2: 5,
            3: 7,
            4: 8
        },
        4: {
            0: 1,
            1: 2,
            2: 5,
            3: 7,
            4: 8
        },
        5: {
            0: 1,
            1: 2,
            2: 5,
            3: 7,
            4: 8
        },
        6: {
            0: 1,
            1: 2,
            2: 5,
            3: 7,
            4: 8
        },
        7: {
            0: 1,
            1: 2,
            2: 5,
            3: 7,
            4: 8
        },
        8: {
            0: 1,
            1: 2,
            2: 5,
            3: 7,
            4: 8
        }, 
        9: {
            0: 1,
            1: 2,
            2: 5,
            3: 7,
            4: 8
        },
        10: {
            0: 1,
            1: 3,
            2: 6,
            3: 8,
            4: 9
        },
        11: {
            0: 1,
            1: 3,
            2: 6,
            3: 8,
            4: 9
        },
        12: {
            0: 1,
            1: 3,
            2: 6,
            3: 8,
            4: 9
        },
        13: {
            0: 1,
            1: 3,
            2: 6,
            3: 8,
            4: 9
        },
        14: {
            0: 1,
            1: 3,
            2: 6,
            3: 8,
            4: 9
        },
        15: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        16: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        17: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        18: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        19: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        20: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        21: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        22: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        23: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        24: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        25: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        26: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        27: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        28: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        29: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        30: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        31: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        32: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        33: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        34: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        35: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        36: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        37: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        38: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        39: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        40: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        41: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        42: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        43: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        44: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        45: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        46: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        47: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        48: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        49: {
            "YEARS TO GOAL": 49,
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        },
        50: {
            0: 2,
            1: 4,
            2: 7,
            3: 9,
            4: 10
        }
    }
}