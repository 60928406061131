import React from 'react';
import Button from '@material-ui/core/Button';

import './stepPicker.css';

function AmmoniteStepPicker({ currentStep, changeCurrentStep, skipIntroHandler }) {
	const clickHandler = () => {
		const newStep = currentStep + 1;
		changeCurrentStep(newStep);
	};

	const NextButton = (
		<Button
			variant="contained"
			onClick={() => clickHandler()}
			style={{
				width: '123px',
				height: '46px',
				borderRadius: '8px',
				fontFamily: 'Brother 1816',
				fontStyle: 'normal',
				fontWeight: 'bold',
				fontSize: '18px',
				lineHeight: '26px',
				letterSpacing: '0.02em',
				color: 'white',
				backgroundColor: '#BF802F',
				textTransform: 'none'
			}}
		>
			Next
		</Button>
	);

	const StartButton = (
		<Button
			variant="contained"
			onClick={() => skipIntroHandler()}
			style={{
				width: '192px',
				height: '46px',
				borderRadius: '8px',
				fontFamily: 'Brother 1816',
				fontStyle: 'normal',
				fontWeight: 'bold',
				fontSize: '18px',
				lineHeight: '26px',
				letterSpacing: '0.02em',
				color: 'white',
				backgroundColor: '#BF802F',
				textTransform: 'none'
			}}
		>
			Start Session
		</Button>
	);

	return <div className="stepProgressContainer">{currentStep !== 2 ? NextButton : StartButton}</div>;
}

export default AmmoniteStepPicker;
