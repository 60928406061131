import React from 'react';

function AmmoniteCardMargin({ colour, height }) {
	return (
		<svg width="15" height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="15" height={height} fill={colour} />
		</svg>
	);
}

export default AmmoniteCardMargin;
