import React, { useState, useEffect, useContext } from 'react';

import AmmoniteMenuCircleEmpty from '../../Assets/SVG/menuCircleEmpty';
import AmmoniteMenuCircleFilledTicked from '../../Assets/SVG/menuCircleFilledTicked';
import AmmoniteCheckbox from '../../components/Checkbox/Checkbox';
import AmmoniteTrashcan from '../../Assets/SVG/trashcan';
import AmmonitePlusButton from '../../Assets/SVG/plusButton';

import './investmentCard.css';
import styles from '../../Styles/srbstyles.module.css'
import SuitabilityReportContext from '../../Utils/contexts/suitabilityReportContext';
import { changeInvestment } from '../../Utils/reducers/suitabilityReportActions';


const InvestmentCard = ({ 
	investmentIndex,
    investment,
    handleDeleteInvestment,
    couple,
    customer1,
    customer2
}) => {

    const context = useContext(SuitabilityReportContext);
    const dispatch = context.dispatch;

    const filled = (
        <div className="circleSVG">
            < AmmoniteMenuCircleFilledTicked />
        </div>
    );

    const empty = (
        <div className="circleSVG">
            <AmmoniteMenuCircleEmpty colour="#3BB9C4" width="36px" height="36px" cx="18px" cy="18px" r="14px" />			
        </div>
    );

    const handleAmendInvestment = (event, index, field, value) => {
        if (event !== undefined) {event.preventDefault()};
        let _investments = context.state.investments;
        _investments[investmentIndex][field] = value;
        if (field === 'sell' && value === true) {
            _investments[investmentIndex]['transferISA'] = false
        }
        if (field === 'transferISA' && value === true) {
            _investments[investmentIndex]['sell'] = false
        }
        dispatch({ type: changeInvestment, payload: {investments: _investments} });
    }

	useEffect(() => {

	}, [])

	return (
		<div className={styles.pensionCard}>
            {investment && <div className={styles.pensionCol}>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={`${styles.pageMainText} ${styles.labelOwner}`}>Owner</label>
                    <div className={styles.ownerPension}>
                        <div className={styles.ownerPensionButton} onClick={(event) => handleAmendInvestment(event, investmentIndex, "owner", "customer1")}>
                            {investment.owner === "customer1" ? filled : empty}
                        </div>
                        <label className={styles.customerLabel}>{customer1}</label>
                    </div>
                    { couple && <div className={styles.ownerPension}>
                        <div className={styles.ownerPensionButton} onClick={(event) => handleAmendInvestment(event, investmentIndex, "owner", "customer2")}>
                            {investment.owner === "customer2" ? filled : empty}
                        </div>
                        <label className={styles.customerLabel}>{customer2}</label>
                    </div>}
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Policy Number</label>
                    <input
                        type="text"
                        placeholder=""
                        value={investment.policyNumber}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendInvestment(event, investmentIndex, "policyNumber", event.target.value)}                   
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Provider</label>
                    <input
                        type="text"
                        placeholder=""
                        value={investment.provider}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendInvestment(event, investmentIndex, "provider", event.target.value)}                   
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Plan Type</label>
                    <input
                        type="text"
                        placeholder=""
                        value={investment.planType}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendInvestment(event, investmentIndex, "planType", event.target.value)}                   
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Contributions</label>
                    <input
                        type="number"
                        placeholder={0}
                        value={investment.contributions}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendInvestment(event, investmentIndex, "contributions", event.target.value)}                   
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Charges</label>
                    <input
                        type="number"
                        placeholder={0}
                        value={investment.charges}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendInvestment(event, investmentIndex, "charges", event.target.value)}                   
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Investments</label>
                    <input
                        type="text"
                        placeholder=""
                        value={investment.investments}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendInvestment(event, investmentIndex, "investments", event.target.value)}                   
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Value</label>
                    <input
                        type="number"
                        placeholder={0}
                        value={investment.value}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendInvestment(event, investmentIndex, "value", event.target.value)}                   
                    />
                </div>
                <div className={`${styles.newPensionFieldContainer} ${styles.newPensionFieldContainerMargin}`}>
                    <label className={styles.pageMainText}>Interest Rate</label>
                    <input
                        type="number"
                        placeholder={0}
                        value={investment.interestRate}
                        className={`${styles.whyInput} ${styles.pensionFieldInput}`}
                        onChange={(event) => handleAmendInvestment(event, investmentIndex, "interestRate", event.target.value)}                   
                    />
                </div>
            </div>}
            {investment && <div className={styles.pensionCol}>
                <div className={styles.pensionTrashcan} onClick={(event) => handleDeleteInvestment(event)}>
                    <AmmoniteTrashcan colour="#7A7A7A"/>
                </div>
                <div className={[styles.financiallyDependent, "transferISA"].join(" ")}>
                    <div className={[styles.transferPensionText, "transferISAtext"].join(" ")}>Transfer ISA?</div>
                    <AmmoniteCheckbox
                        isBold
                        checked={investment.transferISA}
                        checkedHandler={(event) => handleAmendInvestment(event, investmentIndex, "transferISA", !investment.transferISA)}
                    />
                </div>
                <div className={[styles.financiallyDependent, "sell"].join(" ")}>
                    <div className={[styles.transferPensionText, "transferISAtext"].join(" ")}>Sell?</div>
                    <AmmoniteCheckbox
                        isBold
                        checked={investment.sell}
                        checkedHandler={(event) => handleAmendInvestment(event, investmentIndex, "sell", !investment.sell)}
                    />
                </div>
            </div>}
		</div>
	);
}

export default InvestmentCard;
