// Core React dependencies
// Core React dependencies
import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';

// Component imports
import SuitabilityReportNavigation from './../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation';

import Loader from 'react-loader-spinner';
import NewAdviceCard from '../../../containers/NewAdviceCard/newAdviceCard';
import AmmoniteArrowButton from '../../../Assets/SVG/arrow';

import firebase from '../../../Utils/firebase';
import { AmmoniteInitialiseSuitabilityContext } from '../../../Utils/data/initialiseSuitabilityContext';
import { saveContextToFirebase } from '../../../Utils/data/saveSuitabilityContext'

import AmmonitePlusButton from '../../../Assets/SVG/plusButton';

import SuitabilityReportContext from '../../../Utils/contexts/suitabilityReportContext';
import { 
    initialiseSBReport,
    deleteNewISAAdvice,
    addNewISAAdvice
} from '../../../Utils/reducers/suitabilityReportActions';

// Styling
import './newISAAdvice.css';
import styles from '../../../Styles/srbstyles.module.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


function NewISAAdvice() {
    const { push } = useHistory();
    const { state, status, error, dispatch } = useContext(SuitabilityReportContext);
    const [ currentISAAdviceIndex, setcurrentISAAdviceIndex ] = useState(0);

    const forwardISA = (event) => {
        event.preventDefault();
        let _newISAIndexforward = currentISAAdviceIndex + 1;
        if (_newISAIndexforward < state.newISAsAdvice.length) {
            setcurrentISAAdviceIndex(_newISAIndexforward)
        }
    }

    const backISA = (event) => {
        event.preventDefault();
        let _newISAIndexbackward = currentISAAdviceIndex - 1;
        if (_newISAIndexbackward >= 0) {
            setcurrentISAAdviceIndex(_newISAIndexbackward)
        }
    }

    const handleDeleteNewISA = (event) => {
        event.preventDefault();
        dispatch({ type: deleteNewISAAdvice, payload: {index: currentISAAdviceIndex} })
        saveHandler()
        backISA(event)
    }

    const exitHandler = () => {
        push('/suitabilityreportbuilder');
    }

    const handleAddISA = (event) => {
        event.preventDefault();
        dispatch({ type: addNewISAAdvice});
    }

    const saveHandler = async () => {
        await saveContextToFirebase(state).then(() => console.log("SAVED"))
    }

    useEffect(() => {

    }, [])

	return <div className={styles.suitabilityPanel}>
        {!status !== "success" && state.newISAsAdvice.length > 0 && currentISAAdviceIndex !== undefined && <div className={styles.pensionOfPensions}>ISA {currentISAAdviceIndex + 1} of {state.newISAsAdvice.length}</div>}
        <div className={styles.pageTitleText}>New ISA Advice</div>
            {!status !== "success" && state.newISAsAdvice.length > 0 && <div className={styles.prevPension} onClick={(event) => backISA(event)}>
                <AmmoniteArrowButton disabled={currentISAAdviceIndex === 0}/>
            </div>}
            {!status !== "success" && currentISAAdviceIndex !== undefined && state.newISAsAdvice.length > 0 ? <div className={styles.currentPension}>
                <NewAdviceCard
                    cardType="New ISA"
                    newAdviceIndex={currentISAAdviceIndex}
                    handleDeleteNewCard={handleDeleteNewISA}
                    newCard={state.newISAsAdvice[currentISAAdviceIndex]}
                    goals={state.newISAsAdvice[currentISAAdviceIndex].goalAllocations}
                    couple={state.customerInfo.couple}
                    customer1={state.customerInfo.customers.customer1.customerFirstName}
                    customer2={state.customerInfo.customers.customer2.customerFirstName}
                />
            </div> : ""}
            {status !== "success" && <div className="loaderAnimation"><Loader/></div>}
            {!status !== "success" && state.newISAsAdvice.length > 0 && <div className={styles.nextPension} onClick={(event) => forwardISA(event)}>
                <AmmoniteArrowButton disabled={currentISAAdviceIndex === state.newISAsAdvice.length - 1}/>
            </div>}
        

        <div className={styles.menuBar}>
        <div className={styles.addPension}>
            <div onClick={(event) => {handleAddISA(event)}}>
                <AmmonitePlusButton/>
            </div>
            <div className={styles.addPensionText}>Add New ISA</div>
            </div>
            <div className={styles.menuChips}>
                <SuitabilityReportNavigation
                    showSave
                    handleExit={exitHandler}
                    handleSave={saveHandler}
                />
            </div>
        </div>
    </div>;
}

export default NewISAAdvice;

