import React, { useState, useEffect } from 'react';
import './goalAllocate.css';

import RetirementAllocationButton from '../../Assets/SVG/GoalAllocationButtons/retirementAllocation';
import FamilyAllocationButton from '../../Assets/SVG/GoalAllocationButtons/familyAllocation';
import EducationAllocationButton from '../../Assets/SVG/GoalAllocationButtons/educationAllocation';
import CareFeesAllocationButton from '../../Assets/SVG/GoalAllocationButtons/careFeesAllocation';
import PropertyAllocationButton from '../../Assets/SVG/GoalAllocationButtons/propertyAllocation';
import InvestmentAllocationButton from '../../Assets/SVG/GoalAllocationButtons/investmentAllocation';
import OtherAllocationButton from '../../Assets/SVG/GoalAllocationButtons/otherAllocation';

import styles from '../../Styles/srbstyles.module.css'

function GoalAllocateBar({ goalAllocatePercentageValue, goalType, goalIndex, handleAllocateGoalPercentage }) {

    const getGoalAllocationBar = (goalComponent) => {
        return <div className="newPensionGoalAllocation">
                            <div className="goalAllocationButton">
                                {goalComponent}
                            </div>
                            <input
                                type="number"
                                value={(goalAllocatePercentageValue === undefined ? 0 : goalAllocatePercentageValue.toString())}
                                className={[styles.whyInput, "goalAllocationFieldInput"].join(" ")}
                                onChange={(event) => handleAllocateGoalPercentage(goalIndex, event.target.value)}
                                onBlur={(event) => isNaN(goalAllocatePercentageValue) ? handleAllocateGoalPercentage(goalIndex, 0) : goalAllocatePercentageValue}
                            /><div className="percentSign">%
                            </div>
                        </div>
    }

    const getGoalAllocateButton = () => {
        switch (goalType) {
            case 'Retirement pot':
                return getGoalAllocationBar(<RetirementAllocationButton/>);
            case 'Family':
                return getGoalAllocationBar(<FamilyAllocationButton/>);
            case 'Education':
                return getGoalAllocationBar(<EducationAllocationButton/>);
            case 'Care Fees':
                return getGoalAllocationBar(<CareFeesAllocationButton/>);
            case 'Property':
                return getGoalAllocationBar(<PropertyAllocationButton/>);
            case 'Long Term Investment':
                return getGoalAllocationBar(<InvestmentAllocationButton/>);
            case 'Other':
                return getGoalAllocationBar(<OtherAllocationButton/>);
            default:
                break;
        }
    }

    useEffect(() => {
 
    }, [])


	return <div className="goalAllocateBarContainer">
       {getGoalAllocateButton()}
    </div>;
}

export default GoalAllocateBar;