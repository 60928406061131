import React from 'react';

function AmmoniteFinancialPlan() {
	return (
<svg width="220" height="165" viewBox="0 0 220 165" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M67.9412 164.576C105.464 164.576 135.882 161.477 135.882 157.655C135.882 153.832 105.464 150.733 67.9412 150.733C30.4183 150.733 0 153.832 0 157.655C0 161.477 30.4183 164.576 67.9412 164.576Z" fill="#DEDEDE"/>
<path d="M84.7472 145.365V150.66L78.0972 151.084L78.5262 145.365H84.7472Z" fill="#69306D"/>
<path d="M48.2798 145.365V150.66L54.9298 151.084L54.5007 145.365H48.2798Z" fill="#69306D"/>
<path d="M51.7118 86.6942C51.7118 86.6942 48.2796 98.979 50.4247 108.722C50.4247 108.722 50.4247 112.958 51.2828 113.806C52.1409 114.653 52.3554 114.653 51.7118 115.076C51.0683 115.5 49.7812 116.559 50.4247 117.195C51.0683 117.83 51.2828 118.677 50.8538 118.889C50.4247 119.101 49.9957 119.524 50.4247 120.372C50.8538 121.219 48.2796 143.882 48.2796 143.882C48.2796 143.882 45.7054 146.636 48.2796 146.848C50.8538 147.06 55.1441 148.119 55.5731 146.424C56.0022 144.73 59.4344 120.795 59.4344 120.795C59.4344 120.795 59.8635 120.16 60.7215 119.101C61.5796 118.042 61.1506 117.618 61.1506 117.406C61.1506 117.195 65.6554 97.0728 65.6554 97.0728C65.6554 97.0728 72.3054 119.948 72.949 120.372C73.5925 120.795 73.807 120.795 73.807 121.431C73.807 122.066 73.378 122.49 73.807 123.125C74.0414 123.466 74.2563 123.82 74.4506 124.184C74.4506 124.184 75.5231 147.907 77.6683 147.907C79.8135 147.907 86.249 147.483 85.6054 145.577C84.9619 143.671 84.1038 144.094 84.1038 144.094C84.1038 144.094 85.6054 124.608 81.9586 118.889V114.229C81.9586 113.806 82.1732 113.594 81.9586 113.17C81.7441 112.747 82.6022 87.5414 78.5264 86.0587C74.4506 84.5761 51.7118 86.6942 51.7118 86.6942Z" fill="#0C3957"/>
<path d="M80.6716 149.178C80.6716 149.178 81.7442 147.483 83.2458 147.483C84.7474 147.483 85.82 149.178 85.82 149.178L89.6813 154.473C89.6813 154.473 95.2587 157.862 93.1136 159.768C90.9684 161.674 85.1764 160.615 85.1764 160.615C85.1764 160.615 80.4571 157.226 79.8135 157.015C79.17 156.803 77.4539 155.744 77.2393 155.532C77.0248 155.32 77.6684 147.695 78.3119 148.225C78.9555 148.754 79.3845 150.449 80.6716 149.178Z" fill="#0C3957"/>
<path d="M52.3554 149.178C52.3554 149.178 51.2828 147.483 49.7812 147.483C48.2796 147.483 47.207 149.178 47.207 149.178L43.3457 154.473C43.3457 154.473 37.7683 157.862 39.9135 159.768C42.0586 161.674 47.8506 160.615 47.8506 160.615C47.8506 160.615 52.5699 157.226 53.2135 157.015C53.857 156.803 55.5731 155.744 55.7877 155.532C56.0022 155.32 55.3586 147.695 54.7151 148.225C54.0715 148.754 53.6425 150.449 52.3554 149.178Z" fill="#0C3957"/>
<path d="M80.1891 129.691C88.0972 129.691 94.508 120.682 94.508 109.569C94.508 98.4565 88.0972 89.4477 80.1891 89.4477C72.2809 89.4477 65.8701 98.4565 65.8701 109.569C65.8701 120.682 72.2809 129.691 80.1891 129.691Z" fill="#0C3957"/>
<path d="M80.1891 129.691C88.0972 129.691 94.508 120.682 94.508 109.569C94.508 98.4565 88.0972 89.4477 80.1891 89.4477C72.2809 89.4477 65.8701 98.4565 65.8701 109.569C65.8701 120.682 72.2809 129.691 80.1891 129.691Z" fill="#A56411"/>
<path d="M82.1195 129.691C90.0276 129.691 96.4384 120.682 96.4384 109.569C96.4384 98.4565 90.0276 89.4477 82.1195 89.4477C74.2114 89.4477 67.8005 98.4565 67.8005 109.569C67.8005 120.682 74.2114 129.691 82.1195 129.691Z" fill="#BF802F"/>
<path d="M82.1199 126.827C88.9026 126.827 94.401 119.101 94.401 109.569C94.401 100.038 88.9026 92.3115 82.1199 92.3115C75.3373 92.3115 69.8389 100.038 69.8389 109.569C69.8389 119.101 75.3373 126.827 82.1199 126.827Z" fill="#F7AB4A"/>
<path d="M80.109 108.884V106.528C80.109 105.094 80.3909 103.985 80.9548 103.199C81.5283 102.4 82.3255 102 83.3462 102C84.1629 102 84.8628 102.2 85.4461 102.6V104.398C84.8434 103.957 84.1872 103.737 83.4775 103.737C82.8359 103.737 82.3352 103.992 81.9755 104.502C81.6255 105.011 81.4506 105.735 81.4506 106.672V108.884H84.5857V110.456H81.4506V112.792C81.4506 113.894 81.2756 114.769 80.9256 115.417H85.6502V117.133H78.8695C79.6958 115.755 80.109 114.315 80.109 112.812V110.456H78.8403V108.884H80.109Z" fill="#A56411"/>
<path d="M49.5666 60.43L48.7085 66.996C48.7085 66.996 45.2763 73.9857 46.1344 83.3052L45.9198 89.4476C45.9198 89.4476 43.7747 100.038 48.065 100.462C52.3553 100.885 49.5666 88.8122 49.5666 88.8122L51.9263 83.0934L54.3418 70.3718L55.5731 61.489L49.5666 60.43Z" fill="#FBBEBE"/>
<path d="M62.116 33.4245C62.116 33.4245 62.3305 38.9315 61.0434 40.2024C59.7563 41.4732 67.4789 45.9212 67.4789 45.9212L71.9837 38.9315C71.9837 38.9315 70.2676 35.9662 70.6966 33.4245H62.116Z" fill="#E6A7A7"/>
<path fillRule="evenodd" clipRule="evenodd" d="M61.8174 38.6407C62.4874 40.7911 63.9244 40.7128 63.9244 40.7128C70.1193 41.1351 71.1756 36.8288 71.1756 36.8288L82.0816 43.8797L77.5957 54.6472C77.5957 54.6472 80.3727 82.5159 79.7319 86.7384C79.091 90.9609 53.0301 88.4274 51.3212 87.794C50.0542 87.3244 52.1926 79.4267 53.3832 75.0298C53.7983 73.4966 54.0982 72.389 54.0982 72.1707C54.0982 71.3262 54.5254 69.8483 55.1663 67.9481C55.4877 66.9951 55.4867 64.8203 55.4058 62.8892C54.269 62.7091 52.7826 62.4133 51.5313 62.1642C50.3012 61.9194 49.2983 61.7198 49.078 61.7198C48.4372 61.7198 50.4665 46.8354 50.4665 46.8354L50.4669 46.8354L61.8174 38.6407Z" fill="#69306D"/>
<path d="M65.8701 36.4958C70.1351 36.4958 73.5927 33.0819 73.5927 28.8707C73.5927 24.6594 70.1351 21.2455 65.8701 21.2455C61.605 21.2455 58.1475 24.6594 58.1475 28.8707C58.1475 33.0819 61.605 36.4958 65.8701 36.4958Z" fill="#FBBEBE"/>
<path d="M75.5517 23.7357C75.5155 23.1801 75.2734 22.6571 74.8714 22.2665C75.0247 21.7147 75.0847 21.1417 75.0489 20.5706C74.8763 18.8925 73.5916 18.6962 72.5585 17.7382C72.1103 17.3225 72.2035 17.0657 72.0478 16.5142C71.8439 15.7911 71.4387 15.1391 70.8782 14.6322C70.2246 13.978 69.5318 13.2998 68.6407 13.0344C66.7682 12.4767 64.9004 13.9138 62.9509 14.07C62.0916 14.1388 61.2281 13.9546 60.3678 14.0089C59.5074 14.0631 58.573 14.4456 58.2626 15.2398C58.1193 15.6064 58.127 16.0174 57.9829 16.3838C57.6966 17.1119 56.9049 17.4883 56.3132 18.0067C55.0047 19.1531 54.6695 21.1145 55.1165 22.7844C55.212 23.1306 55.3346 23.469 55.4833 23.7964C55.3861 24.516 55.4323 25.2474 55.6192 25.9496C56.0662 27.6195 57.1717 29.0417 58.3817 30.2891C58.3796 29.7682 58.4257 29.2483 58.5193 28.7358C58.5383 28.5622 58.6003 28.396 58.6997 28.2516C58.9342 27.96 59.3736 27.9677 59.7347 27.8605C60.4828 27.6386 60.9329 26.8753 61.1262 26.1281C61.3195 25.3808 61.3326 24.5916 61.5856 23.862C61.6286 23.6908 61.7074 23.5303 61.817 23.391C61.9266 23.2517 62.0646 23.1366 62.222 23.0532C62.4982 22.9708 62.7925 22.9681 63.0702 23.0456C64.4392 23.2973 65.8621 23.5929 67.2052 23.2299C67.6634 23.106 68.109 22.906 68.5838 22.8919C69.6612 22.8598 70.5373 23.7982 70.9237 24.7917C71.3101 25.7852 71.3648 26.876 71.6993 27.8878C72.0337 28.8995 72.7839 29.9038 73.8536 30.0346C73.9124 30.048 73.9735 30.049 74.0328 30.0376C74.092 30.0261 74.1482 30.0024 74.1975 29.9681C74.2612 29.8983 74.3023 29.8114 74.3159 29.7184L75.2885 25.7865C75.4934 25.1226 75.5824 24.4291 75.5517 23.7357Z" fill="#0C3957"/>
<path d="M85.1766 58.1001V66.7842C85.1766 66.7842 85.3911 77.3746 81.7444 83.9407L79.8137 88.1769C79.8137 88.1769 75.9524 100.462 73.5927 96.6492C71.2331 92.8366 76.1669 86.4824 76.1669 86.4824L78.9591 70.3596L77.2395 59.5828L85.1766 58.1001Z" fill="#FBBEBE"/>
<path d="M207.772 11.2395H109.524V119.842H207.772V11.2395Z" fill="white"/>
<path d="M129.045 99.5086H128.616V100.779H129.045V99.5086Z" fill="#E6E6E6"/>
<path d="M127.543 93.1151H124.969V92.6915H127.543V93.1151ZM122.395 93.1151H119.821V92.6915H122.395V93.1151ZM117.247 93.1151H114.672V92.6915H117.247V93.1151ZM112.098 93.1151H109.524V92.6915H112.098V93.1151Z" fill="#BDBDBD"/>
<path d="M166.371 80.2341H165.942V81.5049H166.371V80.2341Z" fill="#E6E6E6"/>
<path d="M163.582 73.0326H161.008V72.6089H163.582V73.0326ZM158.434 73.0326H155.859V72.6089H158.434V73.0326ZM153.285 73.0326H150.711V72.6089H153.285V73.0326ZM148.137 73.0326H145.563V72.6089H148.137V73.0326ZM142.988 73.0326H140.414V72.6089H142.988V73.0326ZM137.84 73.0326H135.266V72.6089H137.84V73.0326ZM132.692 73.0326H130.117V72.6089H132.692V73.0326ZM127.543 73.0326H124.969V72.6089H127.543V73.0326ZM122.395 73.0326H119.821V72.6089H122.395V73.0326ZM117.247 73.0326H114.672V72.6089H117.247V73.0326ZM112.098 73.0326H109.524V72.6089H112.098V73.0326Z" fill="#BDBDBD"/>
<path d="M215.066 60.5359H212.492V60.1122H215.066V60.5359ZM209.917 60.5359H207.343V60.1122H209.917V60.5359ZM204.769 60.5359H202.195V60.1122H204.769V60.5359ZM199.621 60.5359H197.047V60.1122H199.621V60.5359ZM194.472 60.5359H191.898V60.1122H194.472V60.5359ZM189.324 60.5359H186.75V60.1122H189.324V60.5359ZM184.176 60.5359H181.601V60.1122H184.176V60.5359ZM179.027 60.5359H176.453V60.1122H179.027V60.5359ZM173.879 60.5359H171.305V60.1122H173.879V60.5359ZM168.73 60.5359H166.156V60.1122H168.73V60.5359ZM163.582 60.5359H161.008V60.1122H163.582V60.5359ZM158.434 60.5359H155.859V60.1122H158.434V60.5359ZM153.285 60.5359H150.711V60.1122H153.285V60.5359ZM148.137 60.5359H145.563V60.1122H148.137V60.5359ZM142.988 60.5359H140.414V60.1122H142.988V60.5359ZM137.84 60.5359H135.266V60.1122H137.84V60.5359ZM132.692 60.5359H130.117V60.1122H132.692V60.5359ZM127.543 60.5359H124.969V60.1122H127.543V60.5359ZM122.395 60.5359H119.821V60.1122H122.395V60.5359ZM117.247 60.5359H114.672V60.1122H117.247V60.5359ZM112.098 60.5359H109.524V60.1122H112.098V60.5359Z" fill="#BDBDBD"/>
<path d="M166.371 77.7114H165.942V75.1885H166.371V77.7114ZM166.371 72.6658H165.942V70.1432H166.371V72.6658ZM166.371 67.6203H165.942V65.0976H166.371V67.6203ZM166.371 62.575H165.942V60.0521H166.371V62.575ZM166.371 57.5294H165.942V55.0067H166.371V57.5294ZM166.371 52.484H165.942V49.9612H166.371V52.484ZM166.371 47.4385H165.942V44.9158H166.371V47.4385ZM166.371 42.393H165.942V39.8703H166.371V42.393ZM166.371 37.3476H165.942V34.8248H166.371V37.3476ZM166.371 32.3021H165.942V29.7794H166.371V32.3021ZM166.371 27.2567H165.942V24.7339H166.371V27.2567ZM166.371 22.2111H165.942V19.6885H166.371V22.2111ZM166.371 17.1657H165.942V14.6429H166.371V17.1657ZM166.371 12.1203H165.942V9.59751H166.371V12.1203ZM166.371 7.07484H165.942V4.55206H166.371V7.07484Z" fill="#BDBDBD"/>
<path d="M129.045 97.009H128.616V94.5095H129.045V97.009ZM129.045 92.01H128.616V89.5105H129.045V92.01ZM129.045 87.011H128.616V84.5117H129.045V87.011ZM129.045 82.0122H128.616V79.5127H129.045V82.0122ZM129.045 77.0133H128.616V74.5137H129.045V77.0133ZM129.045 72.0143H128.616V69.515H129.045V72.0143ZM129.045 67.0155H128.616V64.516H129.045V67.0155ZM129.045 62.0165H128.616V59.517H129.045V62.0165ZM129.045 57.0175H128.616V54.5182H129.045V57.0175ZM129.045 52.0187H128.616V49.5192H129.045V52.0187ZM129.045 47.0197H128.616V44.5203H129.045V47.0197ZM129.045 42.0208H128.616V39.5213H129.045V42.0208ZM129.045 37.0219H128.616V34.5224H129.045V37.0219ZM129.045 32.0229H128.616V29.5235H129.045V32.0229ZM129.045 27.024H128.616V24.5245H129.045V27.024ZM129.045 22.0251H128.616V19.5256H129.045V22.0251ZM129.045 17.0261H128.616V14.5267H129.045V17.0261ZM129.045 12.0272H128.616V9.5277H129.045V12.0272ZM129.045 7.02831H128.616V4.52881H129.045V7.02831Z" fill="#BDBDBD"/>
<path d="M77.9157 43.3519L82.0812 43.8797C82.0812 43.8797 87.1011 58.9753 85.8194 59.6087C84.5377 60.2421 76.2068 60.6643 76.2068 60.6643L77.9157 43.3519Z" fill="#69306D"/>
<path d="M109.524 119.842L129.045 100.568L137.947 109.358L166.156 81.5048L184.39 99.5085L217.211 67.1019V119.842H109.524Z" fill="#39B54A"/>
<path d="M166.371 0.758606H165.942V2.02948H166.371V0.758606Z" fill="#E6E6E6"/>
<path d="M129.045 0.758606H128.616V2.02948H129.045V0.758606Z" fill="#E6E6E6"/>
<path d="M217.426 120.054H109.31V0.594238H217.426V120.054ZM109.739 119.63H216.997V1.01785H109.739V119.63Z" fill="#0C3957"/>
<path d="M128.83 95.2724C130.371 95.2724 131.619 94.0396 131.619 92.5189C131.619 90.9982 130.371 89.7654 128.83 89.7654C127.29 89.7654 126.042 90.9982 126.042 92.5189C126.042 94.0396 127.29 95.2724 128.83 95.2724Z" fill="#0C3957"/>
<path d="M166.157 75.5742C167.697 75.5742 168.946 74.3415 168.946 72.8208C168.946 71.3001 167.697 70.0673 166.157 70.0673C164.617 70.0673 163.368 71.3001 163.368 72.8208C163.368 74.3415 164.617 75.5742 166.157 75.5742Z" fill="#0C3957"/>
<path d="M217.211 63.0775C218.751 63.0775 220 61.8447 220 60.324C220 58.8033 218.751 57.5706 217.211 57.5706C215.671 57.5706 214.422 58.8033 214.422 60.324C214.422 61.8447 215.671 63.0775 217.211 63.0775Z" fill="#0C3957"/>
<path d="M197.69 17.5389C196.442 17.8963 194.768 18.1725 193.529 18.0747L195.146 19.1353L119.05 74.7686L119.305 75.1091L195.402 19.4757L195.957 21.3126C196.224 20.1137 196.98 18.6135 197.69 17.5389Z" fill="#0C3957"/>
</svg>

	);
}

export default AmmoniteFinancialPlan;
