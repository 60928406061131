import React from 'react';

function AmmonitePensions() {
	return (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27 6H25.5V4.5C25.5 4.10218 25.342 3.72064 25.0607 3.43934C24.7794 3.15804 24.3978 3 24 3C23.6022 3 23.2206 3.15804 22.9393 3.43934C22.658 3.72064 22.5 4.10218 22.5 4.5V6H13.5V4.5C13.5 4.10218 13.342 3.72064 13.0607 3.43934C12.7794 3.15804 12.3978 3 12 3C11.6022 3 11.2206 3.15804 10.9393 3.43934C10.658 3.72064 10.5 4.10218 10.5 4.5V6H9C7.80653 6 6.66193 6.47411 5.81802 7.31802C4.97411 8.16193 4.5 9.30653 4.5 10.5V28.5C4.5 29.6935 4.97411 30.8381 5.81802 31.682C6.66193 32.5259 7.80653 33 9 33H27C28.1935 33 29.3381 32.5259 30.182 31.682C31.0259 30.8381 31.5 29.6935 31.5 28.5V10.5C31.5 9.30653 31.0259 8.16193 30.182 7.31802C29.3381 6.47411 28.1935 6 27 6V6ZM9 9H10.5V10.5C10.5 10.8978 10.658 11.2794 10.9393 11.5607C11.2206 11.842 11.6022 12 12 12C12.3978 12 12.7794 11.842 13.0607 11.5607C13.342 11.2794 13.5 10.8978 13.5 10.5V9H22.5V10.5C22.5 10.8978 22.658 11.2794 22.9393 11.5607C23.2206 11.842 23.6022 12 24 12C24.3978 12 24.7794 11.842 25.0607 11.5607C25.342 11.2794 25.5 10.8978 25.5 10.5V9H27C27.3978 9 27.7794 9.15804 28.0607 9.43934C28.342 9.72064 28.5 10.1022 28.5 10.5V16.5H7.5V10.5C7.5 10.1022 7.65804 9.72064 7.93934 9.43934C8.22064 9.15804 8.60218 9 9 9V9ZM27 30H9C8.60218 30 8.22064 29.842 7.93934 29.5607C7.65804 29.2794 7.5 28.8978 7.5 28.5V19.5H28.5V28.5C28.5 28.8978 28.342 29.2794 28.0607 29.5607C27.7794 29.842 27.3978 30 27 30Z" fill="#BF802F"/>
<path d="M12 25.5C12.8284 25.5 13.5 24.8284 13.5 24C13.5 23.1716 12.8284 22.5 12 22.5C11.1716 22.5 10.5 23.1716 10.5 24C10.5 24.8284 11.1716 25.5 12 25.5Z" fill="#BF802F"/>
<path d="M24 22.5H18C17.6022 22.5 17.2206 22.658 16.9393 22.9393C16.658 23.2206 16.5 23.6022 16.5 24C16.5 24.3978 16.658 24.7794 16.9393 25.0607C17.2206 25.342 17.6022 25.5 18 25.5H24C24.3978 25.5 24.7794 25.342 25.0607 25.0607C25.342 24.7794 25.5 24.3978 25.5 24C25.5 23.6022 25.342 23.2206 25.0607 22.9393C24.7794 22.658 24.3978 22.5 24 22.5Z" fill="#BF802F"/>
</svg>

	);
}

export default AmmonitePensions;
