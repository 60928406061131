import React, { useState } from "react";

import Pagination from "material-ui-flat-pagination";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2B9BA5",
    },
    secondary: {
      main: "#0C3957",
    },
  },
  typography: {
    fontFamily: "Brother 1816",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(MenuItem);

const useStyles = makeStyles(() => ({
  icon: {
    position: "relative",
    top: 0,
    left: -40,
    fill: "#3bb9c4",
    padding: 0,
    fontSize: "3em",
  },
  active: {
    backgroundColor: "transparent",
  },
}));

function AmmonitePagination({ limit, offset, total, clickHandle, disabled }) {
  const classes = useStyles();

  return (
    <div className="Pagination">
      <MuiThemeProvider theme={theme}>
        <StyledMenuItem classes={{ selected: classes.active }}>
          <Pagination
            limit={limit}
            offset={offset}
            total={total}
            onClick={clickHandle}
            size={"large"}
            disabled={disabled}
          />
        </StyledMenuItem>
      </MuiThemeProvider>
    </div>
  );
}

export default AmmonitePagination;
