// Core React dependencies
import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';

// Component imports
import SuitabilityReportNavigation from './../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation';

import Loader from 'react-loader-spinner';
import NewAdviceCard from '../../../containers/NewAdviceCard/newAdviceCard';
import AmmoniteArrowButton from '../../../Assets/SVG/arrow';

import { saveContextToFirebase } from '../../../Utils/data/saveSuitabilityContext'
import AmmonitePlusButton from '../../../Assets/SVG/plusButton';

import SuitabilityReportContext from '../../../Utils/contexts/suitabilityReportContext';
import { 
    deleteNewPensionAdvice,
    addNewPensionAdvice
} from '../../../Utils/reducers/suitabilityReportActions';

// Styling
import './newPensionAdvice.css';
import styles from '../../../Styles/srbstyles.module.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


function NewPensionAdvice() {
    const { push } = useHistory();
    const { state, status, error, dispatch } = useContext(SuitabilityReportContext);
    const [ currentPensionAdviceIndex, setcurrentPensionAdviceIndex ] = useState(0);

    const forwardPension = (event) => {
        event.preventDefault();
        let _newPensionIndexforward = currentPensionAdviceIndex + 1;
        if (_newPensionIndexforward < state.newPensionsAdvice.length) {
            setcurrentPensionAdviceIndex(_newPensionIndexforward)
        }
    }

    const backPension = (event) => {
        event.preventDefault();
        let _newPensionIndexbackward = currentPensionAdviceIndex - 1;
        if (_newPensionIndexbackward >= 0) {
            setcurrentPensionAdviceIndex(_newPensionIndexbackward)
        }
    }

    const exitHandler = () => {
        push('/suitabilityreportbuilder');
    }

    const handleAddPension = (event) => {
        event.preventDefault();
        dispatch({ type: addNewPensionAdvice});
        saveHandler()
    }

    const handleDeleteNewPension = (event) => {
        event.preventDefault();
        dispatch({ type: deleteNewPensionAdvice, payload: {index: currentPensionAdviceIndex} })
        saveHandler()
        backPension(event)
    }

    const saveHandler = async () => {
        await saveContextToFirebase(state).then(() => console.log("SAVED"))
    }

    useEffect(() => {

    }, [])

	return <div className={styles.suitabilityPanel}>
        {!status !== "success" && state.newPensionsAdvice.length > 0 && currentPensionAdviceIndex !== undefined && <div className={styles.pensionOfPensions}>Pension {currentPensionAdviceIndex + 1} of {state.newPensionsAdvice.length}</div>}
        
        <div className={styles.pageTitleText}>New Pension Advice</div>
            {state.newPensionsAdvice.length > 0 && <div className={styles.prevPension} onClick={(event) => backPension(event)}>
                <AmmoniteArrowButton disabled={currentPensionAdviceIndex === 0}/>
            </div>}
            {!status !== "success" && currentPensionAdviceIndex !== undefined && state.newPensionsAdvice.length > 0 ? <div className={styles.currentPension}>
                <NewAdviceCard
                    cardType="New Pension"
                    newAdviceIndex={currentPensionAdviceIndex}
                    handleDeleteNewCard={handleDeleteNewPension}
                    newCard={state.newPensionsAdvice[currentPensionAdviceIndex]}
                    goals={state.newPensionsAdvice[currentPensionAdviceIndex].goalAllocations}
                    couple={state.customerInfo.couple}
                    customer1={state.customerInfo.customers.customer1.customerFirstName}
                    customer2={state.customerInfo.customers.customer2.customerFirstName}
                />
            </div> : ""}
            {status !== "success" && <div className="loaderAnimation"><Loader/></div>}
            {!status !== "success" && state.newPensionsAdvice.length > 0 && <div className={styles.nextPension} onClick={(event) => forwardPension(event)}>
                <AmmoniteArrowButton disabled={currentPensionAdviceIndex === state.newPensionsAdvice.length - 1}/>
            </div>}
        

        <div className={styles.menuBar}>
            <div className={styles.addPension}>
                <div onClick={(event) => {handleAddPension(event)}}>
                    <AmmonitePlusButton/>
                </div>
                <div className={styles.addPensionText}>Add New Pension</div>
            </div>
            <div className={styles.menuChips}>
                <SuitabilityReportNavigation
                    showSave
                    handleExit={exitHandler}
                    handleSave={saveHandler}
                />
            </div>
        </div>
    </div>;
}

export default NewPensionAdvice;

