import React from 'react';

function AmmoniteFamilySummaryIcon({colour}) {
	let color = "#3F762C"
	if (colour) {
		color = colour
	}
	return (
		<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.5 16.5C14.6867 16.5 15.8467 16.1481 16.8334 15.4888C17.8201 14.8295 18.5892 13.8925 19.0433 12.7961C19.4974 11.6997 19.6162 10.4933 19.3847 9.32946C19.1532 8.16557 18.5818 7.09648 17.7426 6.25736C16.9035 5.41825 15.8344 4.8468 14.6705 4.61529C13.5067 4.38378 12.3003 4.5026 11.2039 4.95673C10.1075 5.41085 9.17047 6.17989 8.51118 7.16658C7.85189 8.15328 7.5 9.31331 7.5 10.5C7.5 12.0913 8.13214 13.6174 9.25736 14.7426C10.3826 15.8679 11.9087 16.5 13.5 16.5ZM13.5 7.5C14.0933 7.5 14.6734 7.67595 15.1667 8.00559C15.6601 8.33524 16.0446 8.80377 16.2716 9.35195C16.4987 9.90013 16.5581 10.5033 16.4424 11.0853C16.3266 11.6672 16.0409 12.2018 15.6213 12.6213C15.2018 13.0409 14.6672 13.3266 14.0853 13.4424C13.5033 13.5581 12.9001 13.4987 12.352 13.2716C11.8038 13.0446 11.3352 12.6601 11.0056 12.1667C10.6759 11.6734 10.5 11.0933 10.5 10.5C10.5 9.70435 10.8161 8.94129 11.3787 8.37868C11.9413 7.81607 12.7044 7.5 13.5 7.5Z"
				fill={color}
			/>
			<path
				d="M25.5 19.5C26.39 19.5 27.26 19.2361 28.0001 18.7416C28.7401 18.2471 29.3169 17.5443 29.6575 16.7221C29.9981 15.8998 30.0872 14.995 29.9135 14.1221C29.7399 13.2492 29.3113 12.4474 28.682 11.818C28.0526 11.1887 27.2508 10.7601 26.3779 10.5865C25.505 10.4128 24.6002 10.5019 23.7779 10.8425C22.9557 11.1831 22.2529 11.7599 21.7584 12.4999C21.2639 13.24 21 14.11 21 15C21 16.1935 21.4741 17.3381 22.318 18.182C23.1619 19.0259 24.3065 19.5 25.5 19.5ZM25.5 13.5C25.7967 13.5 26.0867 13.588 26.3334 13.7528C26.58 13.9176 26.7723 14.1519 26.8858 14.426C26.9993 14.7001 27.0291 15.0017 26.9712 15.2926C26.9133 15.5836 26.7704 15.8509 26.5607 16.0607C26.3509 16.2704 26.0836 16.4133 25.7926 16.4712C25.5017 16.5291 25.2001 16.4994 24.926 16.3858C24.6519 16.2723 24.4176 16.08 24.2528 15.8334C24.088 15.5867 24 15.2967 24 15C24 14.6022 24.158 14.2206 24.4393 13.9393C24.7206 13.658 25.1022 13.5 25.5 13.5Z"
				fill={color}
			/>
			<path
				d="M25.5 20.9999C23.8377 21.0017 22.2232 21.5558 20.91 22.5749C19.4408 21.1114 17.5711 20.1158 15.5367 19.7135C13.5024 19.3113 11.3945 19.5204 9.47884 20.3146C7.5632 21.1088 5.92562 22.4524 4.77261 24.1761C3.6196 25.8997 3.00281 27.9262 3 29.9999C3 30.3977 3.15804 30.7793 3.43934 31.0606C3.72064 31.3419 4.10218 31.4999 4.5 31.4999C4.89782 31.4999 5.27936 31.3419 5.56066 31.0606C5.84196 30.7793 6 30.3977 6 29.9999C6 28.0108 6.79018 26.1031 8.1967 24.6966C9.60322 23.2901 11.5109 22.4999 13.5 22.4999C15.4891 22.4999 17.3968 23.2901 18.8033 24.6966C20.2098 26.1031 21 28.0108 21 29.9999C21 30.3977 21.158 30.7793 21.4393 31.0606C21.7206 31.3419 22.1022 31.4999 22.5 31.4999C22.8978 31.4999 23.2794 31.3419 23.5607 31.0606C23.842 30.7793 24 30.3977 24 29.9999C24.0036 28.2425 23.5596 26.5132 22.71 24.9749C23.3729 24.4511 24.1698 24.1245 25.0097 24.0324C25.8495 23.9403 26.6983 24.0866 27.4589 24.4544C28.2196 24.8221 28.8613 25.3966 29.3106 26.1121C29.76 26.8275 29.9989 27.655 30 28.4999C30 28.8977 30.158 29.2793 30.4393 29.5606C30.7206 29.8419 31.1022 29.9999 31.5 29.9999C31.8978 29.9999 32.2794 29.8419 32.5607 29.5606C32.842 29.2793 33 28.8977 33 28.4999C33 26.5108 32.2098 24.6031 30.8033 23.1966C29.3968 21.7901 27.4891 20.9999 25.5 20.9999Z"
				fill={color}
			/>
		</svg>
	);
}

export default AmmoniteFamilySummaryIcon;
