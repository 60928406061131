//React
import React, {
  useRef,
  Fragment,
  useEffect,
  useState,
  useCallback,
} from "react";

//external libraries
import cloneDeep from "lodash/cloneDeep";

//components
import AmmoniteAdvisor from "../YourAdvisor/YourAdvisor";
import AmmoniteReportGoalCard from "../ReportGoalCard/reportGoalCard";
import AmmoniteFinancialPlanningCard from "../FinancialPlanningCard/financialPlanningCard";
import AmmoniteReportGraphic from "../reportGraphic/reportGraphic";
import AmmoniteCallbackCard from "../reportCallbackCard/reportCallbackCard";
import AmmoniteReportAvatar from "../../../components/reportComponents/reportAvatar/reportAvatar";
import AboutAmmonite from "../AboutAmmonite/aboutAmmonite";

//utils
import StatePensionAge from "../../../Utils/data/statePensionAge";

//assets
import carolinePhoto from "../../../Assets/Photos/caroline-avatar.png";
import robPhoto from "../../../Assets/Photos/robert-avatar.png";
import michaelPhoto from "../../../Assets/Photos/michael-avatar.png";
import carolineSignature from "../../../Assets/Signatures/SignatureCarolineDuff.jpg";
import robSignature from "../../../Assets/Signatures/SignatureRobHarradine.jpg";
import michaelSignature from "../../../Assets/Signatures/SignatureMichaelHughes.jpg";
import "./report.css";

const robAbout = (
  <div className="aboutContainer">
    <div className="aboutParagraph">
      I’m a chartered member of the Chartered Institute for Securities &
      Investments (CISI). I have over fifteen years investment and wealth
      management experience from companies like Bloomberg and Barclays Wealth.
    </div>
    <div className="aboutParagraph">
      I’m here to help clients get a grip on their finances and I enjoy setting
      them up to best achieve their goals. If you have any questions about this
      report, please let me know at{" "}
      <a href="mailto:robert@ammonitewealth.com ">robert@ammonitewealth.com</a>
    </div>
  </div>
);

const carolineAbout = (
  <div className="aboutContainer">
    <p className="aboutParagraph">
      I’m a Chartered Financial Planner with the Chartered Insurance Institute,
      with over ten years’ experience in the industry. I’ve worked with a wide
      range of private clients and am a technical expert in pensions advice.
    </p>

    <p className="aboutParagraph">
      I’m here to help clients get a grip on their finances and I enjoy setting
      them up to best achieve their goals. If you have any questions about this
      report, please let me know at{" "}
      <a href="mailto:caroline@ammonitewealth.com">
        caroline@ammonitewealth.com
      </a>
    </p>
  </div>
);

const michaelAbout = (
  <div className="aboutContainer">
    <p className="aboutParagraph">
      I’m a Financial Adviser with the Chartered Insurance Institute, with over
      five years’ experience in the industry and a keen interest in the
      investment markets.
    </p>

    <p className="aboutParagraph">
      I’m here to help you feel comfortable with your Pension, Investment and
      Personal Insurance needs. If you have any questions about this report,
      please let me know at{" "}
      <a href="mailto:michael@ammonitewealth.com">michael@ammonitewealth.com</a>
    </p>
  </div>
);

const robAvatar = <AmmoniteReportAvatar avatarPhoto={robPhoto} />;
const carolineAvatar = <AmmoniteReportAvatar avatarPhoto={carolinePhoto} />;
const michaelAvatar = <AmmoniteReportAvatar avatarPhoto={michaelPhoto} />;

function Report({ advisor, sessionData, reportIsLoaded }) {
  const reportRef = useRef();
  const advisorSignature = useRef();

  const [sigLoaded, setSigLoaded] = useState(false);

  const reportGoals = cloneDeep(sessionData.customerGoals);
  let currentYear = new Date().getFullYear();
  let yearsToRetirement =
    parseInt(sessionData.statePensionAge) -
    (
      Math.abs(
        new Date() -
          sessionData.customerInfo.customers.customer1.customerDoB.toDate()
      ) / 31536000000
    ).toFixed();
  if (yearsToRetirement < 0) {
    yearsToRetirement = 0;
  }

  let name = "";
  if (sessionData.customerInfo.couple === true) {
    name =
      sessionData.customerInfo.customers.customer1.customerFirstName +
      " and " +
      sessionData.customerInfo.customers.customer2.customerFirstName;
  } else {
    name = sessionData.customerInfo.customers.customer1.customerFirstName;
  }
  const _newGoals = cloneDeep(reportGoals);
  const goalSummaryCards = _newGoals
    .slice(1)
    .sort((a, b) => {
      return a.goalImportance - b.goalImportance;
    })
    .reverse();
  const signatureLoaded = () => {
    setSigLoaded(true);
  };

  const getAdvicePlanningCard = (adviceType) => {
    switch (adviceType) {
      case "unemployed":
        if (
          !sessionData.customerInfo.couple &&
          (sessionData.customerInfo.customers.customer1
            .customerWorkSituation === 2 ||
            sessionData.customerInfo.customers.customer2
              .customerWorkSituation === 2)
        ) {
          return (
            <AmmoniteFinancialPlanningCard
              planningName={name}
              financialPlanningCardType="unemployed"
            />
          );
        } else if (
          sessionData.customerInfo.customers.customer1.customerWorkSituation ===
          2
        ) {
          return (
            <AmmoniteFinancialPlanningCard
              planningName={
                sessionData.customerInfo.customers.customer1.customerFirstName
              }
              financialPlanningCardType="unemployed"
            />
          );
        } else if (
          sessionData.customerInfo.customers.customer2.customerWorkSituation ===
          2
        ) {
          return (
            <AmmoniteFinancialPlanningCard
              planningName={
                sessionData.customerInfo.customers.customer2.customerFirstName
              }
              financialPlanningCardType="unemployed"
            />
          );
        } else {
          break;
        }
      case "selfEmployed":
        if (
          !sessionData.customerInfo.couple &&
          (sessionData.customerInfo.customers.customer1
            .customerWorkSituation === 1 ||
            sessionData.customerInfo.customers.customer2
              .customerWorkSituation === 1)
        ) {
          return (
            <AmmoniteFinancialPlanningCard
              planningName={name}
              financialPlanningCardType="selfEmployed"
            />
          );
        } else if (
          sessionData.customerInfo.customers.customer1.customerWorkSituation ===
          1
        ) {
          return (
            <AmmoniteFinancialPlanningCard
              planningName={
                sessionData.customerInfo.customers.customer1.customerFirstName
              }
              financialPlanningCardType="selfEmployed"
            />
          );
        } else if (
          sessionData.customerInfo.customers.customer2.customerWorkSituation ===
          1
        ) {
          return (
            <AmmoniteFinancialPlanningCard
              planningName={
                sessionData.customerInfo.customers.customer2.customerFirstName
              }
              financialPlanningCardType="selfEmployed"
            />
          );
        } else {
          break;
        }
      case "employed":
        if (
          !sessionData.customerInfo.couple &&
          (sessionData.customerInfo.customers.customer1
            .customerWorkSituation === 0 ||
            sessionData.customerInfo.customers.customer2
              .customerWorkSituation === 0)
        ) {
          return (
            <AmmoniteFinancialPlanningCard
              planningName={name}
              financialPlanningCardType="employed"
            />
          );
        } else if (
          sessionData.customerInfo.customers.customer1.customerWorkSituation ===
          0
        ) {
          return (
            <AmmoniteFinancialPlanningCard
              planningName={
                sessionData.customerInfo.customers.customer1.customerFirstName
              }
              financialPlanningCardType="employed"
            />
          );
        } else if (
          sessionData.customerInfo.customers.customer2.customerWorkSituation ===
          0
        ) {
          return (
            <AmmoniteFinancialPlanningCard
              planningName={
                sessionData.customerInfo.customers.customer2.customerFirstName
              }
              financialPlanningCardType="employed"
            />
          );
        } else {
          break;
        }
    }
  };

  useEffect(() => {
    if (sigLoaded) {
      reportIsLoaded(sigLoaded);
    }
  }, [reportRef, sigLoaded]);

  return (
    <Fragment>
      {/* <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=0.7 maximum-scale=1.0"/> */}

      <div ref={reportRef} className="reportContainer">
        <h1 className="reportHeader">Your goals report</h1>
        <div className="reportIntro">
          {name}, thanks for sharing your goals with us. I’m {advisor}, and I am
          one of the qualified financial advisers at Ammonite. I help clients
          take the next step in the financial planning process. In this report
          you’ll find your roadmap to financial wellbeing, highlighting your
          goals and time frames. I’ve also added some tips, based on your
          personal circumstances, which I hope you’ll find helpful.{" "}
        </div>
        <AmmoniteAdvisor
          advisorAvatar={
            advisor === "Rob"
              ? robAvatar
              : advisor === "Michael"
              ? michaelAvatar
              : carolineAvatar
          }
          advisorName={
            advisor === "Rob"
              ? "Rob Harradine"
              : advisor === "Michael"
              ? "Michael Hughes"
              : "Caroline Duff"
          }
          advisorQualification={
            advisor === "Michael"
              ? "Financial Adviser DipPFS"
              : "Financial Adviser (FCA Authorised) "
          }
          advisorAbout={
            advisor === "Rob"
              ? robAbout
              : advisor === "Michael"
              ? michaelAbout
              : carolineAbout
          }
        />
        <AboutAmmonite />
        <div className="yourGoals">
          <h1>Your goals</h1>

          <div className="reportGoalIntro">
            {name}, in your financial coaching session, you established that
            your goals are:{" "}
          </div>
          <div className="reportGoalCardsRow">
            <AmmoniteReportGoalCard
              inflation={sessionData.cpi}
              goalType={sessionData.customerGoals[0].goalType}
              goalName={sessionData.customerGoals[0].goalName}
              amountRequiredToday={
                sessionData.customerGoals[0].amountRequiredToday
              }
              goalAmount={sessionData.customerGoals[0].goalAmount}
              goalTimeHorizon={parseInt(sessionData.statePensionAge)}
              realIndex={0}
              customer1DoB={
                sessionData.customerInfo.customers.customer1.customerDoB
              }
            />
            {goalSummaryCards &&
              goalSummaryCards
                .slice(0, 1)
                .map((item, index) => (
                  <AmmoniteReportGoalCard
                    key={index}
                    inflation={sessionData.cpi}
                    realIndex={item.realIndex}
                    goalType={item.goalType}
                    goalName={item.goalName}
                    amountRequiredToday={item.amountRequiredToday}
                    goalAmount={item.goalAmount}
                    goalTimeHorizon={item.goalTimeHorizon}
                    customer1DoB={
                      sessionData.customerInfo.customers.customer1.customerDoB
                    }
                  />
                ))}
          </div>

          {goalSummaryCards && (
            <div className="reportGoalCardsRow">
              {goalSummaryCards.slice(1, 3).map((item, index) => (
                <AmmoniteReportGoalCard
                  key={index}
                  inflation={sessionData.cpi}
                  realIndex={item.realIndex}
                  goalType={item.goalType}
                  goalName={item.goalName}
                  amountRequiredToday={item.amountRequiredToday}
                  goalAmount={item.goalAmount}
                  goalTimeHorizon={item.goalTimeHorizon}
                  customer1DoB={
                    sessionData.customerInfo.customers.customer1.customerDoB
                  }
                />
              ))}
            </div>
          )}
          {goalSummaryCards && (
            <div className="reportGoalCardsRow">
              {goalSummaryCards.slice(3, 5).map((item, index) => (
                <AmmoniteReportGoalCard
                  key={index}
                  inflation={sessionData.cpi}
                  realIndex={item.realIndex}
                  goalType={item.goalType}
                  goalName={item.goalName}
                  amountRequiredToday={item.amountRequiredToday}
                  goalAmount={item.goalAmount}
                  goalTimeHorizon={item.goalTimeHorizon}
                  customer1DoB={
                    sessionData.customerInfo.customers.customer1.customerDoB
                  }
                />
              ))}
            </div>
          )}
          <p style={{ marginLeft: "450px", marginTop: "-10px" }}>
            * after inflation
          </p>
        </div>
        <div className="reportGraphic">
          <AmmoniteReportGraphic
            currentYear={currentYear}
            yearsToRetirement={yearsToRetirement}
            reportGoals={reportGoals}
          />
        </div>
        <h1>Your financial planning</h1>
        {!sessionData.financialCheck.knowsStatePensionAge && (
          <AmmoniteFinancialPlanningCard
            planningName={name}
            financialPlanningCardType="statePensionAge"
            customer1Name={
              sessionData.customerInfo.customers.customer1.customerFirstName
            }
            customer2Name={
              sessionData.customerInfo.customers.customer2.customerFirstName
            }
            statePensionAge1={StatePensionAge(
              sessionData.customerInfo.customers.customer1.customerDoB
            )}
            statePensionAge2={
              sessionData.customerInfo.couple
                ? StatePensionAge(
                    sessionData.customerInfo.customers.customer2.customerDoB
                  )
                : ""
            }
          />
        )}
        {sessionData.financialCheck.prevPensions && (
          <AmmoniteFinancialPlanningCard
            planningName={name}
            financialPlanningCardType="pensions"
          />
        )}

        {getAdvicePlanningCard("unemployed")}
        {getAdvicePlanningCard("selfEmployed")}
        {getAdvicePlanningCard("employed")}

        {sessionData.financialCheck.lifeCover && (
          <AmmoniteFinancialPlanningCard
            planningName={name}
            financialPlanningCardType="lifeCover"
          />
        )}
        {!sessionData.financialCheck.lifeCover && (
          <AmmoniteFinancialPlanningCard
            planningName={name}
            financialPlanningCardType="noLifeCover"
          />
        )}
        {!sessionData.financialCheck.incomeProtection && (
          <AmmoniteFinancialPlanningCard
            planningName={name}
            financialPlanningCardType="noIncomeProtection"
          />
        )}
        {sessionData.financialCheck.hasMortgage && (
          <AmmoniteFinancialPlanningCard
            planningName={name}
            financialPlanningCardType="mortgage"
          />
        )}
        {!sessionData.financialCheck.will && (
          <AmmoniteFinancialPlanningCard
            planningName={name}
            financialPlanningCardType="noWill"
          />
        )}
        <h1>Investment strategy</h1>
        <div className="reportIntro">
          You’ve probably realised by now that if your money is not growing by
          at least inflation each year, then you are actually losing “purchasing
          power” every year. Interest rates on savings accounts are at historic
          lows, so you might be considering investing instead. We can give you
          advice about where to invest, to help you beat inflation and more,
          over the long term.
        </div>
        <AmmoniteFinancialPlanningCard
          planningName={name}
          financialPlanningCardType="investments"
        />
        {sessionData.financialCheck.environmentPriority && (
          <AmmoniteFinancialPlanningCard
            planningName={name}
            financialPlanningCardType="environmentalPriority"
          />
        )}
        <AmmoniteFinancialPlanningCard
          planningName={name}
          financialPlanningCardType="charges"
        />
        <h1>Thank you</h1>
        <div className="reportIntro">
          I hope you’ve found this report useful. We’ve only touched the tip of
          the iceberg in this summary document – so I hope to speak with you
          soon to establish your own comprehensive personal financial plan. A
          solid plan will give you the best chance of achieving your goals -
          alongside smart pension and investment advice, ongoing. By spending a
          little time now on financial advice, we know this will save you a
          fortune in the long run (in both time and money!)
        </div>
        {advisor === "Caroline" ? (
          <img
            ref={advisorSignature}
            className="reportSignature"
            src={carolineSignature}
            alt="signature"
            onLoad={signatureLoaded}
          />
        ) : advisor === "Rob" ? (
          <img
            className="reportSignature"
            src={robSignature}
            alt="signature"
            onLoad={signatureLoaded}
          />
        ) : (
          <img
            className="reportSignature"
            src={michaelSignature}
            alt="signature"
            onLoad={signatureLoaded}
          />
        )}
        <div className="reportIntro advisorSignature">
          {advisor === "Rob"
            ? "Rob Harradine"
            : advisor === "Caroline"
            ? "Caroline Duff"
            : "Michael Hughes"}
        </div>
        <h1>Your next steps</h1>
        <AmmoniteCallbackCard />
      </div>
    </Fragment>
  );
}

export default Report;
