// Core React dependencies
// Core React dependencies
import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';

// Component imports
import SuitabilityReportNavigation from './../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation';

import Loader from 'react-loader-spinner';
import NewAdviceCard from '../../../containers/NewAdviceCard/newAdviceCard';
import AmmoniteArrowButton from '../../../Assets/SVG/arrow';

import firebase from '../../../Utils/firebase';
import { AmmoniteInitialiseSuitabilityContext } from '../../../Utils/data/initialiseSuitabilityContext';
import { saveContextToFirebase } from '../../../Utils/data/saveSuitabilityContext'
import AmmonitePlusButton from '../../../Assets/SVG/plusButton';

import SuitabilityReportContext from '../../../Utils/contexts/suitabilityReportContext';
import { 
    initialiseSBReport,
    deleteNewCashAdvice,
    addNewCashAdvice
} from '../../../Utils/reducers/suitabilityReportActions';

// Styling
import './newCashAdvice.css';
import styles from '../../../Styles/srbstyles.module.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


function NewCashAdvice() {
    const { push } = useHistory();
    const { state, status, error, dispatch } = useContext(SuitabilityReportContext);
    const [ currentCashAdviceIndex, setcurrentCashAdviceIndex ] = useState(0);

    const forwardCash = (event) => {
        event.preventDefault();
        let _newCashIndexforward = currentCashAdviceIndex + 1;
        if (_newCashIndexforward < state.newCashAdvice.length) {
            setcurrentCashAdviceIndex(_newCashIndexforward)
        }
    }

    const backCash = (event) => {
        event.preventDefault();
        let _newCashIndexbackward = currentCashAdviceIndex - 1;
        if (_newCashIndexbackward >= 0) {
            setcurrentCashAdviceIndex(_newCashIndexbackward)
        }
    }

    const handleDeleteNewCash = (event) => {
        event.preventDefault();
        dispatch({ type: deleteNewCashAdvice, payload: {index: currentCashAdviceIndex} })
        saveHandler()
        backCash(event)
    }


    const exitHandler = () => {
        push('/suitabilityreportbuilder');
    }

    const handleAddCash = (event) => {
        event.preventDefault();
        dispatch({ type: addNewCashAdvice});
    }

    const saveHandler = async () => {
        await saveContextToFirebase(state).then(() => console.log("SAVED"))
    }

    useEffect(() => {

    }, [])

	return <div className={styles.suitabilityPanel}>
        {!status !== "success" && state.newCashAdvice.length > 0 && currentCashAdviceIndex !== undefined && <div className={styles.pensionOfPensions}>Cash {currentCashAdviceIndex + 1} of {state.newCashAdvice.length}</div>}

        <div className={styles.pageTitleText}>New Cash Advice</div>
            {!status !== "success" && state.newCashAdvice.length > 0 && <div className={styles.prevPension} onClick={(event) => backCash(event)}>
                <AmmoniteArrowButton disabled={currentCashAdviceIndex === 0}/>
            </div>}
            {!status !== "success" && currentCashAdviceIndex !== undefined && state.newCashAdvice.length > 0 ? <div className={styles.currentPension}>
                <NewAdviceCard
                    cardType="New Cash"
                    newAdviceIndex={currentCashAdviceIndex}
                    handleDeleteNewCard={handleDeleteNewCash}
                    newCard={state.newCashAdvice[currentCashAdviceIndex]}
                    goals={state.newCashAdvice[currentCashAdviceIndex].goalAllocations}
                    couple={state.customerInfo.couple}
                    customer1={state.customerInfo.customers.customer1.customerFirstName}
                    customer2={state.customerInfo.customers.customer2.customerFirstName}
                />
            </div> : ""}
            {status !== "success" && <div className="loaderAnimation"><Loader/></div>}
            {!status !== "success" && state.newCashAdvice.length > 0 && <div className={styles.nextPension} onClick={(event) => forwardCash(event)}>
                <AmmoniteArrowButton disabled={currentCashAdviceIndex === state.newCashAdvice.length - 1}/>
            </div>}
        

        <div className={styles.menuBar}>
        <div className={styles.addPension}>
                <div onClick={(event) => {handleAddCash(event)}}>
                    <AmmonitePlusButton/>
                </div>
                <div className={styles.addPensionText}>Add New Cash Account</div>
            </div>
            <div className={styles.menuChips}>
                <SuitabilityReportNavigation
                    showSave
                    handleExit={exitHandler}
                    handleSave={saveHandler}
                />
            </div>
        </div>
    </div>;
}

export default NewCashAdvice;

