import React from 'react';

function FamilyAllocationButton({ colour }) {
	return (
<svg width="70" height="30" viewBox="0 0 70 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<rect width="70" height="30" rx="15" fill="#3F762C"/>
<path d="M31.25 13.75C32.2389 13.75 33.2056 13.4568 34.0279 12.9073C34.8501 12.3579 35.491 11.577 35.8694 10.6634C36.2478 9.74979 36.3469 8.74445 36.1539 7.77455C35.961 6.80464 35.4848 5.91373 34.7855 5.21447C34.0863 4.51521 33.1954 4.039 32.2255 3.84608C31.2555 3.65315 30.2502 3.75217 29.3366 4.1306C28.423 4.50904 27.6421 5.1499 27.0927 5.97215C26.5432 6.7944 26.25 7.7611 26.25 8.75C26.25 10.0761 26.7768 11.3479 27.7145 12.2855C28.6521 13.2232 29.9239 13.75 31.25 13.75ZM31.25 6.25C31.7445 6.25 32.2278 6.39662 32.6389 6.67133C33.05 6.94603 33.3705 7.33648 33.5597 7.79329C33.7489 8.25011 33.7984 8.75277 33.702 9.23773C33.6055 9.72268 33.3674 10.1681 33.0178 10.5178C32.6681 10.8674 32.2227 11.1055 31.7377 11.202C31.2528 11.2984 30.7501 11.2489 30.2933 11.0597C29.8365 10.8705 29.446 10.55 29.1713 10.1389C28.8966 9.7278 28.75 9.24445 28.75 8.75C28.75 8.08696 29.0134 7.45108 29.4822 6.98223C29.9511 6.51339 30.587 6.25 31.25 6.25Z" fill="white"/>
<path d="M41.25 16.25C41.9917 16.25 42.7167 16.0301 43.3334 15.618C43.9501 15.206 44.4307 14.6203 44.7145 13.9351C44.9984 13.2498 45.0726 12.4958 44.9279 11.7684C44.7832 11.041 44.4261 10.3728 43.9016 9.84835C43.3772 9.3239 42.709 8.96675 41.9816 8.82206C41.2542 8.67736 40.5002 8.75162 39.8149 9.03545C39.1297 9.31928 38.544 9.79993 38.132 10.4166C37.7199 11.0333 37.5 11.7583 37.5 12.5C37.5 13.4946 37.8951 14.4484 38.5983 15.1517C39.3016 15.8549 40.2554 16.25 41.25 16.25ZM41.25 11.25C41.4972 11.25 41.7389 11.3233 41.9445 11.4607C42.15 11.598 42.3102 11.7932 42.4048 12.0216C42.4995 12.2501 42.5242 12.5014 42.476 12.7439C42.4277 12.9863 42.3087 13.2091 42.1339 13.3839C41.9591 13.5587 41.7363 13.6778 41.4939 13.726C41.2514 13.7742 41.0001 13.7495 40.7716 13.6549C40.5432 13.5602 40.348 13.4 40.2107 13.1945C40.0733 12.9889 40 12.7472 40 12.5C40 12.1685 40.1317 11.8505 40.3661 11.6161C40.6005 11.3817 40.9185 11.25 41.25 11.25Z" fill="white"/>
<path d="M41.25 17.4999C39.8648 17.5014 38.5193 17.9631 37.425 18.8124C36.2006 17.5928 34.6426 16.7631 32.9473 16.4279C31.252 16.0927 29.4954 16.267 27.899 16.9288C26.3027 17.5906 24.938 18.7103 23.9772 20.1467C23.0163 21.583 22.5023 23.2718 22.5 24.9999C22.5 25.3314 22.6317 25.6493 22.8661 25.8838C23.1005 26.1182 23.4185 26.2499 23.75 26.2499C24.0815 26.2499 24.3995 26.1182 24.6339 25.8838C24.8683 25.6493 25 25.3314 25 24.9999C25 23.3423 25.6585 21.7526 26.8306 20.5805C28.0027 19.4084 29.5924 18.7499 31.25 18.7499C32.9076 18.7499 34.4973 19.4084 35.6694 20.5805C36.8415 21.7526 37.5 23.3423 37.5 24.9999C37.5 25.3314 37.6317 25.6493 37.8661 25.8838C38.1005 26.1182 38.4185 26.2499 38.75 26.2499C39.0815 26.2499 39.3995 26.1182 39.6339 25.8838C39.8683 25.6493 40 25.3314 40 24.9999C40.003 23.5354 39.633 22.0943 38.925 20.8124C39.4774 20.3759 40.1415 20.1037 40.8414 20.027C41.5413 19.9503 42.2486 20.0721 42.8824 20.3786C43.5163 20.6851 44.0511 21.1638 44.4255 21.76C44.8 22.3562 44.9991 23.0458 45 23.7499C45 24.0814 45.1317 24.3993 45.3661 24.6338C45.6005 24.8682 45.9185 24.9999 46.25 24.9999C46.5815 24.9999 46.8995 24.8682 47.1339 24.6338C47.3683 24.3993 47.5 24.0814 47.5 23.7499C47.5 22.0923 46.8415 20.5026 45.6694 19.3305C44.4973 18.1584 42.9076 17.4999 41.25 17.4999Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="70" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>


	);
}

export default FamilyAllocationButton;
