import React from 'react';

function CareFeesFocusButton({ colour }) {
	return (
<svg width="87" height="72" viewBox="0 0 87 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f)">
<path d="M4 36C4 18.3269 18.1478 4 35.6 4H51.4C68.8522 4 83 18.3269 83 36C83 53.6731 68.8522 68 51.4 68H35.6C18.1478 68 4 53.6731 4 36Z" fill={colour}/>
</g>
<path d="M6 35C6 18.4315 19.4315 5 36 5H51C67.5685 5 81 18.4315 81 35C81 51.5685 67.5685 65 51 65H36C19.4315 65 6 51.5685 6 35Z" fill="#0C3957"/>
<path fillRule="evenodd" clipRule="evenodd" d="M36.8295 27C35.5335 27 34.3185 27.501 33.4095 28.4115C31.5225 30.3015 31.5225 33.378 33.411 35.271L43.9995 45.8775L54.5895 35.271C56.478 33.378 56.478 30.3015 54.5895 28.4115C52.7715 26.589 49.5675 26.592 47.7495 28.4115L45.0615 31.104C44.4975 31.6695 43.5015 31.6695 42.9375 31.104L40.2495 28.41C39.3405 27.501 38.127 27 36.8295 27ZM43.9996 49.5C43.6021 49.5 43.2196 49.3425 42.9391 49.059L31.2871 37.389C28.2331 34.329 28.2331 29.3505 31.2871 26.2905C32.7631 24.8145 34.7311 24 36.8296 24C38.9281 24 40.8976 24.8145 42.3721 26.2905L43.9996 27.921L45.6271 26.292C47.1031 24.8145 49.0711 24 51.1711 24C53.2681 24 55.2376 24.8145 56.7121 26.2905C59.7676 29.3505 59.7676 34.329 56.7136 37.389L45.0616 49.0605C44.7796 49.3425 44.3986 49.5 43.9996 49.5Z" fill="white"/>
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="28" y="24" width="32" height="26">
<path fillRule="evenodd" clipRule="evenodd" d="M36.8295 27C35.5335 27 34.3185 27.501 33.4095 28.4115C31.5225 30.3015 31.5225 33.378 33.411 35.271L43.9995 45.8775L54.5895 35.271C56.478 33.378 56.478 30.3015 54.5895 28.4115C52.7715 26.589 49.5675 26.592 47.7495 28.4115L45.0615 31.104C44.4975 31.6695 43.5015 31.6695 42.9375 31.104L40.2495 28.41C39.3405 27.501 38.127 27 36.8295 27ZM43.9996 49.5C43.6021 49.5 43.2196 49.3425 42.9391 49.059L31.2871 37.389C28.2331 34.329 28.2331 29.3505 31.2871 26.2905C32.7631 24.8145 34.7311 24 36.8296 24C38.9281 24 40.8976 24.8145 42.3721 26.2905L43.9996 27.921L45.6271 26.292C47.1031 24.8145 49.0711 24 51.1711 24C53.2681 24 55.2376 24.8145 56.7121 26.2905C59.7676 29.3505 59.7676 34.329 56.7136 37.389L45.0616 49.0605C44.7796 49.3425 44.3986 49.5 43.9996 49.5Z" fill="white"/>
</mask>
<g mask="url(#mask0)">
</g>
<defs>
<filter id="filter0_f" x="0" y="0" width="87" height="72" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur"/>
</filter>
</defs>
</svg>




	);
}

export default CareFeesFocusButton;
