import React from 'react';
import AmmoniteCross from '../../Assets/SVG/cross';

import './sortOut.css';

function AmmoniteSortOut({ sortOutItemText }) {
	return (
		<div className="sortOutContainer">
			<div className="sortOutCross">
				<AmmoniteCross />
			</div>
			<div className="sortOutText">{sortOutItemText}</div>
		</div>
	);
}

export default AmmoniteSortOut;
