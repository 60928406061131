export const AmmoniteInvestmentData = (
  startValue,
  cpi,
  newFees,
  investedGrowthRate,
  yearsToRetirement,
  netAnnualContributions
) => {
  let investmentData = [startValue];
  let investedNetGrowthRate = (investedGrowthRate - cpi - newFees) / 100;
  if (netAnnualContributions === undefined) {
    netAnnualContributions = 0;
  }
  for (let i = 0; i < yearsToRetirement; i++) {
    let investmentAa =
      startValue + netAnnualContributions / investedNetGrowthRate;
    let investmentBb =
      investmentAa * Math.pow(1 + investedNetGrowthRate, i + 1);
    let investmentValue =
      investmentBb - netAnnualContributions / investedNetGrowthRate;
    if (investmentValue > 0) {
      investmentData.push(Math.round(investmentValue * 100) / 100);
    } else {
      investmentData.push(0);
    }
  }
  return investmentData;
};
