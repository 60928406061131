import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";

import firebase from "../../../Utils/firebase";

import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";

import { annualSpending } from "../../../Utils/data/annualSpending";

import SuitabilityReportNavigation from "../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation";
import SessionDataCardIntro from "../SessionDataCardContents/SessionDataCardIntro";
import SessionDataCardFurtherIntro from "../SessionDataCardContents/SessionDataCardFurtherIntro";
import SessionDataCardGoals from "../SessionDataCardContents/SessionDataCardGoals";
import SessionDataCardAdditionalInfo from "../SessionDataCardContents/SessionDataCardAdditionalInfo";
import SessionDataCardSendReport from "../SessionDataCardContents/SessionDataCardSendReport";
import SendReportChip from "../../../Assets/SVG/Chips/sendReportChip";

import "./sessionDataCard.css";

function AmmoniteSessionDataCard({ sessionData, closeSessionView }) {
  const history = useHistory();
  const [recommend, setRecommend] = useState(
    sessionData.recommend !== undefined ? sessionData.recommend : 0
  );
  const [referralDetails, setReferralDetails] = useState(
    sessionData.referralDetails !== undefined ? sessionData.referralDetails : ""
  );
  const [customerSituation, setCustomerSituation] = useState(
    sessionData.customerSituation !== undefined
      ? sessionData.customerSituation
      : ""
  );
  const [likelihood, setLikelihood] = useState(
    sessionData.likelihood !== undefined ? sessionData.likelihood : ""
  );
  const [anticipatedAssets, setAnticipatedAssets] = useState(
    sessionData.anticipatedAssets !== undefined
      ? sessionData.anticipatedAssets
      : ""
  );
  const [areasOfInterest, setAreasOfInterest] = useState(
    Array.isArray(sessionData.areasOfInterest) &&
      sessionData.areasOfInterest.length === 7
      ? sessionData.areasOfInterest
      : [false, false, false, false, false, false, false]
  );
  const [interestComments, setInterestComments] = useState(
    sessionData.interestComments !== undefined
      ? sessionData.interestComments
      : ""
  );
  const [amendedGoals, setAmendedGoals] = useState(sessionData.customerGoals);
  const [customerEmail, setCustomerEmail] = useState(
    sessionData.sendTo ? sessionData.sendTo : sessionData.customerEmail
  );

  let name = "";
  if (sessionData.customerInfo.couple === true) {
    name = `${sessionData.customerInfo.customers.customer1.customerFirstName} ${sessionData.customerInfo.customers.customer1.customerLastName}
       and 
      ${sessionData.customerInfo.customers.customer2.customerFirstName} ${sessionData.customerInfo.customers.customer2.customerLastName}`;
  } else {
    name = `${sessionData.customerInfo.customers.customer1.customerFirstName} ${sessionData.customerInfo.customers.customer1.customerLastName}`;
  }

  const sessionDataRef = useRef();

  const [customerTelephone, setCustomerTelephone] = useState(
    sessionData.customerTelephone
  );
  const [adviser, setAdviser] = useState(sessionData.reportAdviser);
  const [dataValid, setDataValid] = useState(false);
  const [sessionDataActiveStep, setSessionDataActiveStep] = useState(0);

  const nextHandler = (event) => {
    event.preventDefault();
    setSessionDataActiveStep(sessionDataActiveStep + 1);
  };

  const backHandler = (event) => {
    event.preventDefault();
    setSessionDataActiveStep(sessionDataActiveStep - 1);
  };

  const saveHandler = (event) => {
    event.preventDefault();
    firebase
      .firestore()
      .collection("sessions")
      .doc(sessionData.id)
      .update({
        recommend: recommend,
        referralDetails: referralDetails,
        customerSituation: customerSituation,
        likelihood: likelihood,
        anticipatedAssets: anticipatedAssets,
        areasOfInterest: areasOfInterest,
        interestComments: interestComments,
        customerGoals: [...amendedGoals],
      });
    sessionData.recommend = recommend;
    sessionData.referralDetails = referralDetails;
    sessionData.customerSituation = customerSituation;
    sessionData.likelihood = likelihood;
    sessionData.anticipatedAssets = anticipatedAssets;
    sessionData.interestComments = interestComments;
    sessionData.areasOfInterest = areasOfInterest;
    sessionData.customerGoals = [...amendedGoals];
  };

  const exitHandler = (event) => {
    event.preventDefault();
    closeSessionView();
  };

  const getCardName = (cardType) => {
    switch (cardType) {
      case 2:
        return " - Goals";
      case 3:
        return " - Additional Info";
      case 4:
        return " - Send Goals Report";
      default:
        return "";
    }
  };

  const handleAdviserDropdown = (value) => {
    setAdviser(value);
  };

  const getDataValid = () => {
    if (
      customerEmail &&
      customerEmail.length > 5 &&
      customerEmail !== "" &&
      customerTelephone &&
      customerTelephone.length > 5 &&
      customerTelephone !== "" &&
      adviser &&
      adviser.length > 2 &&
      adviser !== "" &&
      adviser !== "none"
    ) {
      return setDataValid(false);
    }
    return setDataValid(true);
  };

  const confirmReportValid = () => {
    // sendEmail()
    history.push({
      pathname: "/reportViewer",
      customNameData: {
        sessionData: sessionData,
        adviser: adviser,
      },
    });
  };

  const handleRecommendation = (index) => {
    setRecommend(index);
  };

  const handleReferralDetails = (value) => {
    setReferralDetails(value);
  };

  const handleCustomerSituation = (value) => {
    setCustomerSituation(value);
  };

  const handleLikelihood = (index) => {
    setLikelihood(index);
  };

  const handleAnticipatedAssets = (index) => {
    setAnticipatedAssets(index);
  };

  const handleAreasOfInterest = (index) => {
    let _areasOfInterest = [...areasOfInterest];
    _areasOfInterest[index] = !areasOfInterest[index];
    setAreasOfInterest([..._areasOfInterest]);
  };

  const handleInterestComments = (value) => {
    setInterestComments(value);
  };

  const handleAmendGoalDetails = (event, field, index) => {
    let _amendedGoals = amendedGoals;
    _amendedGoals[index][field] = event.target.value;
    setAmendedGoals([..._amendedGoals]);
  };

  const handleChangeEmail = (value) => {
    setCustomerEmail(value);
    sessionData.sendTo = value;
  };

  useEffect(() => {
    getDataValid();
    if (customerEmail) {
      firebase.firestore().collection("sessions").doc(sessionData.id).update({
        sendTo: customerEmail,
      });
    }
    if (customerTelephone) {
      firebase.firestore().collection("sessions").doc(sessionData.id).update({
        customerTelephone: customerTelephone,
      });
    }
    if (adviser) {
      firebase.firestore().collection("sessions").doc(sessionData.id).update({
        reportAdviser: adviser,
      });
    }
  }, [
    customerEmail,
    customerTelephone,
    adviser,
    dataValid,
    recommend,
    referralDetails,
    customerSituation,
    likelihood,
    anticipatedAssets,
    areasOfInterest,
    interestComments,
    amendedGoals,
  ]);
  return (
    <div className="sessionCardMask">
      <Grid container ref={sessionDataRef} className="sessionCard">
        <Grid container className="sessionSummaryName">
          {name} {getCardName(sessionDataActiveStep)}
        </Grid>
        <Grid container className="sessionSummaryCardData">
          {sessionDataActiveStep === 0 && (
            <SessionDataCardIntro
              coachName={
                sessionData.coachName
                  ? sessionData.coachName
                  : "No coach data gathered"
              }
              sessionDate={
                sessionData.sessionDate.seconds
                  ? new Date(sessionData.sessionDate.seconds * 1000)
                      .toString()
                      .slice(4, 21)
                  : "-"
              }
              phoneNumber={
                sessionData.customerTelephone
                  ? sessionData.customerTelephone
                  : "-"
              }
              email={
                sessionData.customerEmail ? sessionData.customerEmail : "-"
              }
              recommend={recommend}
              referralDetails={referralDetails}
              customerSituation={customerSituation}
              handleRecommendation={handleRecommendation}
              handleReferralDetails={handleReferralDetails}
              handleCustomerSituation={handleCustomerSituation}
            />
          )}
          {sessionDataActiveStep === 1 && (
            <SessionDataCardFurtherIntro
              likelihood={likelihood}
              anticipatedAssets={anticipatedAssets}
              areasOfInterest={areasOfInterest}
              interestComments={interestComments}
              handleLikelihood={handleLikelihood}
              handleAnticipatedAssets={handleAnticipatedAssets}
              handleAreasOfInterest={handleAreasOfInterest}
              handleInterestComments={handleInterestComments}
            />
          )}
          {sessionDataActiveStep === 2 && (
            <SessionDataCardGoals
              amendedGoals={amendedGoals}
              handleAmendGoalDetails={handleAmendGoalDetails}
              customerDoB={
                sessionData.customerInfo.customers.customer1.customerDoB
              }
            />
          )}
          {sessionDataActiveStep === 3 && (
            <SessionDataCardAdditionalInfo
              customer1Name={`${sessionData.customerInfo.customers.customer1.customerFirstName} ${sessionData.customerInfo.customers.customer1.customerLastName}`}
              customer2Name={`${sessionData.customerInfo.customers.customer2.customerFirstName} ${sessionData.customerInfo.customers.customer2.customerLastName}`}
              customer1DOB={
                sessionData.customerInfo.customers.customer1.customerDoB
              }
              customer2DOB={
                sessionData.customerInfo.customers.customer2.customerDoB
              }
              customer1Employment={
                sessionData.customerInfo.customers.customer1
                  .customerWorkSituation
              }
              customer2Employment={
                sessionData.customerInfo.customers.customer2
                  .customerWorkSituation
              }
              children={sessionData.customerInfo.customers.hasChildren}
              mortgagePriority={
                sessionData.mortgageAPriority !== undefined
                  ? sessionData.mortgageAPriority
                  : false
              }
              pensionsPriority={
                sessionData.pensionsAPriority !== undefined
                  ? sessionData.pensionsAPriority
                  : false
              }
              retirementSpending={`£${annualSpending(
                sessionData.retirementSpending,
                sessionData.retirementComforts,
                sessionData.retirementLuxuries,
                sessionData.customerInfo.couple
              )} p.a.`}
              financialCheck={sessionData.financialCheck}
              couple={sessionData.customerInfo.couple}
            />
          )}
          {sessionDataActiveStep === 4 && (
            <SessionDataCardSendReport
              email={sessionData.customerEmail}
              adviser={adviser ? adviser : "None"}
              handleDropdownChange={handleAdviserDropdown}
              customerEmail={customerEmail}
              handleChangeEmail={handleChangeEmail}
            />
          )}
        </Grid>
        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-end"
          justify="flex-end"
          className="sessionSummaryNavigation"
        >
          <Grid item>
            <SuitabilityReportNavigation
              showBack={
                sessionDataActiveStep !== 0 && sessionDataActiveStep !== 4
              }
              showSave={sessionDataActiveStep !== 4}
              showNext={sessionDataActiveStep !== 4}
              handleExit={(event) => {
                exitHandler(event);
              }}
              handleBack={(event) => {
                backHandler(event);
              }}
              handleSave={saveHandler}
              handleNext={(event) => {
                nextHandler(event);
              }}
            />
          </Grid>
          {sessionDataActiveStep === 4 && (
            <Grid item className="sessionDataSendButton">
              <Button onClick={() => confirmReportValid()}>
                <SendReportChip />
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default AmmoniteSessionDataCard;
