// Core React dependencies
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useHistory } from "react-router";

// External libraries
import SearchIcon from "@material-ui/icons/Search";
import { useDebounce } from "@react-hook/debounce";

// Component imports
import CreateUser from "../../../Assets/SVG/Dashboard/CreateUser/CreateUser";
import AmmoniteSession from "../../../containers/Console/Session/Session";
import AmmoniteUser from "../../../containers/Console/User/User";
import AmmoniteSessionDataCard from "../../../containers/Console/SessionDataCard/SessionDataCard";
import NewAmmoniteLoginLogo from "../../../Assets/SVG/AmmoniteLoginLogo/NewAmmoniteLoginLogo";
import AmmoniteCreateUser from "../../../containers/Console/CreateUser/CreateUser";
import AmmoniteEditUser from "../../../containers/Console/EditUser/EditUser";
import AmmoniteButton from "../../../components/Button/button";
import Loader from "react-loader-spinner";
import AmmoniteDropdown from "../../../components/Dropdown/Dropdown";
import AmmonitePagination from "../../../components/Pagination/Pagination";

// Utilities
import firebase from "../../../Utils/firebase";
import { searchClient } from "../../../Utils/algolia";

// Actions

// Styling
import "./dashboard.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function usePreviousSearch(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

function useSessions(tabType) {
  //gets all of the user cards that appear on the dashboard
  const [sessions, setSessions] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [userUid, setUserUid] = useState("");

  const getCurrentUser = async () => {
    //gets current user and defines whether it is will have some access (coach/admin), or no access (customer/not signed in)
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        const staffRef = firebase.firestore().collection("users").doc(user.uid);
        let data = [];
        staffRef
          .get()
          .then((doc) => {
            data = doc.data();
            if (data) {
              setUserRole(data.role);
              setUserUid(user.uid);
              //need to pass data.role because userRole update is too slow
              loadSessions(user.uid, data.role);
            }
          })
          .catch((error) => {
            // a customer will have a doc in the users collection, but does not have access to it.
            setUserRole("no access role");
            console.log(error);
          });
      } else {
        //if no user signed in
        setUserRole("no access role");
      }
    });
  };

  const loadSessions = useCallback(
    async (userUid, _userRole) => {
      //if the user is an admin it obtains all the sessions, if coach, only the ones they created
      if (_userRole === "admin") {
        const sessionsSnapshot = firebase
          .firestore()
          .collection(tabType)
          .onSnapshot((snapshot) => {
            const newSessions = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setSessions(newSessions);
          });
        return () => sessionsSnapshot();
      } else if (_userRole === "coach") {
        const sessionsRef = firebase.firestore().collection("sessions");
        const snapshot = await sessionsRef
          .where("coachUid", "==", userUid)
          .get();
        if (snapshot.empty) {
          //coach has not created any sessions
          console.log("No matching documents.");
          return;
        }
        const newSessions = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSessions(newSessions);
      }
    },
    [sessions]
  );

  useEffect(() => {
    getCurrentUser();
  }, []);

  return [sessions, tabType === "users" ? userUid : userRole];
}

function AmmoniteDashboard() {
  const loading = useRef(true);
  const deleteUser = firebase.functions().httpsCallable("deleteUser");

  const { push } = useHistory();
  const searchIndex = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_SEARCH_INDEX
  );
  const surnameSort = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_SURNAME_SORT
  );
  const dateSortAsc = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_DATE_SORT_ASC
  );
  const dateSortDesc = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_DATE_SORT_DESC
  );
  const coachSort = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_COACH_NAME_SORT
  );
  const userSearchIndex = searchClient.initIndex(
    process.env.REACT_APP_ALGOLIA_USER_SEARCH_INDEX
  );
  const [users, setUsers] = useState(false);
  const [results, setResults] = useState();
  const [dateResultsDone, setDateResultsDone] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerId, setCustomerId] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteErrorOcurred, setDeleteErrorOcurred] = useState(false);
  const [search, setSearch] = useState("");
  const [userTypeShowing, setUserTypeShowing] = useState(1);
  const [sessionTypeShowing, setSessionTypeShowing] = useState(3);
  const [sessionFilter, setSessionFilter] = useState(1);
  const [docIDs, setDocIDs] = useDebounce([], 500); //useDebounce(initial state, wait time in ms is 100 by default)
  const hasSearchChanged = usePreviousSearch(search);
  const hasDropdownChanged = usePreviousSearch(userTypeShowing);
  const hasSessionsDropdownChanged = usePreviousSearch(sessionTypeShowing);
  const hasSessionFilterChanged = usePreviousSearch(sessionFilter);
  const [sessions, userRole] = useSessions("sessions");
  const [allUsers, userUid] = useSessions("users");
  const [selectedUser, setSelectedUser] = useState([]);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [filteredSessions, setFilteredSessions] = useState(sessions);
  const [filteredUsers, setFilteredUsers] = useState(allUsers);
  const [sessionData, setSessionData] = useState();
  const [showSessionData, setShowSessionData] = useState(false);
  const [haveTabsChanged, setHaveTabsChanged] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const hasPageChanged = usePreviousSearch(currentPage);
  const [usersFound, setUsersFound] = useState(0);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const userFilterOptions = [
    { value: 1, text: "All users" },
    { value: 2, text: "Customers" },
    { value: 3, text: "Coaches" },
    { value: 4, text: "Admins" },
  ];

  const sessionsOrderByOptions = [
    { value: 1, text: "Most relevant" },
    { value: 2, text: "Date (ascending)" },
    { value: 3, text: "Date (descending)" },
    { value: 4, text: "Surname" },
  ];

  if (userRole === "admin") {
    sessionsOrderByOptions.push({ value: 5, text: "Coaches" });
  }

  const sessionsFilterByOptions = [
    { value: 1, text: "None" },
    { value: 2, text: "Report sent" },
    { value: 3, text: "Report unsent" },
  ];

  const getDropDownSort = (sortType) => {
    switch (sortType) {
      case 1:
        return searchIndex;
      case 2:
        return dateSortAsc;
      case 3:
        return dateSortDesc;
      case 4:
        return surnameSort;
      case 5:
        return coachSort;
      default:
        return dateSortDesc;
    }
  };

  const getDropdownUserType = (sortType) => {
    switch (sortType) {
      case 1:
        return "*";
      case 2:
        return "indexData.userRole:customer";
      case 3:
        return "indexData.userRole:coach";
      case 4:
        return "indexData.userRole:admin";
      default:
        return "*";
    }
  };

  const getSessionAlgoliaFilter = (sortType) => {
    let roleFilter = "";
    if (userRole === "coach") {
      roleFilter = `indexData.coachUid:${userUid}`;
    }
    switch (sortType) {
      case 1:
        return roleFilter ? [roleFilter] : ["*"];
      case 2:
        return ["indexData.reportSent:true", roleFilter];
      case 3:
        return ["indexData.reportSent:false", roleFilter];
      default:
        return roleFilter ? [roleFilter] : ["*"];
    }
  };

  const handleDropdownChange = (value) => {
    setUserTypeShowing(value);
  };

  const handleSessionsDropdownChange = (value) => {
    setSessionTypeShowing(value);
  };

  const handleSessionFilterChange = (value) => {
    setSessionFilter(value);
  };

  const selectSession = (item) => {
    //gets selected session data when user presses "Goals Report", to show in the overview
    setShowSessionData(true);

    const _session = sessions.find((session) => session.id === item);
    setSessionData(_session);
  };

  const getSuitabilityReport = (id) => {
    //when user clicks on "SR Builder" defines the current session as of the selected customer,
    // so that SR data can be written/edited accordingly. Takes user to Document Library page.
    localStorage.setItem("currentSession", id);
    if (localStorage.getItem("currentSession") === id) {
      push("documentLibrary");
    }
  };

  const closeSessionView = () => {
    //used to close the overview session when user clicks outside it.
    setSessionData(null);
    setShowSessionData(false);
  };

  const getHits = useCallback(
    async (users, query, filterType, dropdownType, page) => {
      if (users === true) {
        //For the users tab applies selected user type filter
        await userSearchIndex
          .search(query, {
            facetFilters: [getDropdownUserType(filterType)],
            page: page,
          })
          .then((results) => {
            if (results.hits.length > 0) {
              setUsersFound(results.nbHits);
              setNumberOfPages(results.nbPages);
              setDateResultsDone(true);
              setNoRecordsFound(false);
              return setResults([...results.hits]);
            }
            return setNoRecordsFound(true);
          });
      } else {
        //For sessions tab gets searches for dates
        const datePattern =
          /(\d{1,2})[(\/|\s)](\d{1,2}|[a-z]{3,9})[(\/|\s)](\d{4})/i;
        const todayPattern = /today/i;

        if (datePattern.test(query) || todayPattern.test(query)) {
          let startString;
          let endString;
          let startStringSeconds;
          let endStringSeconds;

          let wordSearch =
            typeof query[0] === "number"
              ? " "
              : query.substring(0, query.search(/\d/));

          if (query.includes("-")) {
            startString = query.substring(
              query.search(/\d/),
              query.indexOf("-")
            );
            endString = query.substring(query.indexOf("-") + 1);
          } else {
            startString = endString = query.substring(query.search(/\d/));
          }

          const getStartDate = startString.match(datePattern);
          const getEndDate = endString.match(datePattern);

          if (getStartDate && getEndDate) {
            startStringSeconds =
              new Date(
                `${getStartDate[2]}/${getStartDate[1]}/${getStartDate[3]}`
              ).getTime() / 1000;
            endStringSeconds =
              new Date(
                `${getEndDate[2]}/${getEndDate[1]}/${getEndDate[3]}`
              ).getTime() / 1000;
          }
          if (
            (getStartDate &&
              getEndDate &&
              startStringSeconds &&
              endStringSeconds) ||
            todayPattern.test(query)
          ) {
            if (todayPattern.test(query)) {
              wordSearch = "";
              let todayDate = new Date();
              todayDate.setHours(0, 0, 0, 0);
              startStringSeconds = endStringSeconds = Math.trunc(
                todayDate.getTime() / 1000
              );
            }
            const dateFilter = `indexData.sessionDate:${startStringSeconds} TO ${
              endStringSeconds + 86400
            }`;
            await getDropDownSort(dropdownType)
              .search(wordSearch, {
                filters: dateFilter,
                page: page,
                facetFilters: getSessionAlgoliaFilter(filterType),
              })
              .then((results) => {
                if (results.hits.length > 0) {
                  setNumberOfPages(results.nbPages);
                  setDateResultsDone(true);
                  setNoRecordsFound(false);
                  return setResults([...results.hits]);
                }
                return setNoRecordsFound(true);
              });
          }
        } else {
          await getDropDownSort(dropdownType)
            .search(query, {
              page: page,
              facetFilters: getSessionAlgoliaFilter(filterType),
            })
            .then((results) => {
              if (results.hits.length > 0) {
                setNumberOfPages(results.nbPages);
                setDateResultsDone(true);
                setNoRecordsFound(false);
                return setResults([...results.hits]);
              }
              return setNoRecordsFound(true);
            });
        }
      }
    },
    [results, search]
  );

  const getFilteredUsers = (tabType) => {
    if (docIDs.length > 0) {
      if (tabType === "sessions") {
        setFilteredUsers([]);
        setFilteredSessions(
          docIDs
            .map((doc) => sessions.filter((user) => doc.includes(user.id)))
            .flat()
        );
      } else if (tabType === "users") {
        setFilteredSessions([]);
        setFilteredUsers(allUsers.filter((user) => docIDs.includes(user.id)));
      }
    }
    setDocIDs([]);
  };

  const deleteSessionDialog = (session, tabType) => {
    setShowDeletePrompt(true);
    let _customerName;
    switch (tabType) {
      case "sessionsTab":
        _customerName =
          session.customerInfo.customers.customer1.customerFirstName +
          " " +
          session.customerInfo.customers.customer1.customerLastName;
        if (session.customerInfo.couple) {
          _customerName +=
            " and " +
            session.customerInfo.customers.customer2.customerFirstName +
            " " +
            session.customerInfo.customers.customer2.customerLastName;
        }

        break;
      case "usersTab":
        _customerName = `${session.firstName} ${session.lastName}`;
        break;
    }

    setCustomerName(_customerName);
    setCustomerId(session.id);
  };

  const deleteSessionDoc = (id) => {
    setDeleteLoading(true);
    deleteUser({
      uid: id,
    }).then(
      (value) => {
        setDeleteLoading(false);
        setShowDeletePrompt(false);
      },
      (reason) => {
        setDeleteLoading(false);
        setShowDeletePrompt(false);
        setDeleteErrorOcurred(true);
      }
    );
  };

  const switchTab = (bool) => {
    loading.current = true;
    setUsers(bool);
    setHaveTabsChanged(true);
    setCurrentPage(0);
    setSearch("");
    if (bool) {
      setUserTypeShowing(1);
    } else {
      setSessionTypeShowing(3);
      setSessionFilter(1);
    }
  };

  const openCreateUserModal = () => {
    setShowCreateUser(true);
  };

  const closeCreateUser = (event) => {
    event.preventDefault();

    setShowCreateUser(false);
  };

  const searchIconStyle = useMemo(
    () => ({
      color: "#3BB9C4",
      position: "absolute",
      top: "28px",
      left: "100px",
    }),
    []
  );

  const editUserDialog = (user) => {
    setSelectedUser(user);
    setShowEditUser(true);
  };

  const closeEditUser = (event) => {
    setSearch(search + " ");
    event.preventDefault();
    setShowEditUser(false);
  };

  useEffect(() => {
    if (
      hasSearchChanged !== search ||
      haveTabsChanged ||
      dateResultsDone ||
      hasDropdownChanged !== userTypeShowing ||
      hasSessionsDropdownChanged !== sessionTypeShowing ||
      hasSessionFilterChanged !== sessionFilter ||
      hasPageChanged !== currentPage
    ) {
      if (hasPageChanged !== currentPage) {
        loading.current = true;
      }
      let _page = currentPage;
      if (
        hasDropdownChanged !== userTypeShowing ||
        hasSessionsDropdownChanged !== sessionTypeShowing ||
        hasSessionFilterChanged !== sessionFilter
      ) {
        _page = 0;
      }
      if (!dateResultsDone) {
        getHits(
          users,
          search,
          users ? userTypeShowing : sessionFilter,
          sessionTypeShowing,
          _page
        );
        if (hasPageChanged === currentPage) {
          setCurrentPage(0);
        }
      }
      let _docIDs = [];
      if (results && results.length > 0 && (search || dateResultsDone)) {
        for (let hit of results) {
          _docIDs.push(hit.objectID);
        }
      }
      Promise.resolve().then(() => {
        setDocIDs([..._docIDs]);
      });
      setHaveTabsChanged(false);
      setDateResultsDone(false);
    }

    if (
      sessions.length > 0 &&
      allUsers.length > 0 &&
      docIDs.length > 0 &&
      !haveTabsChanged
    ) {
      getFilteredUsers(users ? "users" : "sessions", docIDs);
      loading.current = false;
    }

    if (noRecordsFound) {
      setFilteredSessions([]);
      setFilteredUsers([]);
      setUsersFound(0);
    }
  }, [
    users,
    userTypeShowing,
    sessionTypeShowing,
    sessionFilter,
    haveTabsChanged,
    allUsers,
    showCreateUser,
    showEditUser,
    search,
    results,
    docIDs,
    sessions,
    sessionData,
    loading,
    currentPage,
  ]);

  return (
    <div className="dashboardContainer">
      <div className="logoContainer">
        <NewAmmoniteLoginLogo />
      </div>
      {(userRole === "coach" || userRole === "admin") && (
        <div>
          {userRole !== "coach" && (
            <div
              className={`selectUsers ${
                users === false ? "unSelectedTab" : ""
              }`}
              onClick={() => switchTab(true)}
            >
              Users
            </div>
          )}
          <div
            className={`selectUsers selectSessions ${
              users === true ? "unSelectedTab" : ""
            }`}
            onClick={() => switchTab(false)}
          >
            Sessions
          </div>
          <div className="pagination">
            <AmmonitePagination
              limit={1}
              offset={currentPage}
              total={numberOfPages}
              clickHandle={(e, offset) => setCurrentPage(offset)}
              disabled={loading.current}
            />
          </div>
          <div className=" searchLabel dashboardResultsLabel">
            max 50 results per page
          </div>
          <div className="searchBar">
            <div className="searchContainer">
              <div className="searchLabel">Search</div>
              <SearchIcon style={searchIconStyle} />
              <input
                key={users}
                type="text"
                name="fname"
                value={search}
                className="searchField searchName"
                onChange={(event) => setSearch(event.target.value)}
              />
            </div>
            {users && (
              <div className="usersDropdownDashboard">
                <AmmoniteDropdown
                  isDown={true}
                  options={userFilterOptions}
                  selected={userTypeShowing}
                  handleDropdownChange={(event) =>
                    handleDropdownChange(event.target.value)
                  }
                  disabled={loading.current}
                />
                <div className="searchLabel usersFoundLabel">
                  Users found: {usersFound}
                </div>
                <div
                  className="createUserButton"
                  onClick={() => openCreateUserModal()}
                >
                  <CreateUser />
                </div>
              </div>
            )}
            {!users && (
              <div className=" searchLabel sessionsDropdownDashboard">
                <div>Order By:</div>
                <AmmoniteDropdown
                  isDown={true}
                  options={sessionsOrderByOptions}
                  selected={sessionTypeShowing}
                  handleDropdownChange={(event) =>
                    handleSessionsDropdownChange(event.target.value)
                  }
                  disabled={loading.current}
                />
                <div>Filter By:</div>
                <AmmoniteDropdown
                  isDown={true}
                  options={sessionsFilterByOptions}
                  selected={sessionFilter}
                  handleDropdownChange={(event) =>
                    handleSessionFilterChange(event.target.value)
                  }
                  disabled={loading.current}
                />
              </div>
            )}
          </div>
          <div className="resultsContainer">
            {noRecordsFound &&
              filteredSessions.length === 0 &&
              filteredUsers.length === 0 && (
                <div className="searchLabel dashboardNoRecords">
                  * No records found
                </div>
              )}
            {loading.current === true && (
              <div className="dashboardLoaderAnimation">
                <Loader type="Grid" color="#3bb9c4" height={80} width={80} />
              </div>
            )}
            <div className="userSessionsContainer">
              {!users &&
                filteredSessions &&
                filteredSessions.map((session, index) => (
                  <AmmoniteSession
                    key={`session_${session.id}`}
                    index={index}
                    session={session}
                    userRole={userRole}
                    deleteSession={() =>
                      deleteSessionDialog(session, "sessionsTab")
                    }
                    selectSession={() => selectSession(session.id)}
                    createSuitabilityReport={() =>
                      getSuitabilityReport(session.id)
                    }
                  />
                ))}
            </div>
            {userRole !== "coach" && (
              <div className="userSessionsContainer">
                {users &&
                  filteredUsers &&
                  filteredUsers.map((user, index) => {
                    return (
                      <AmmoniteUser
                        key={`user_${user.id}`}
                        index={index}
                        user={user}
                        delUser={() => deleteSessionDialog(user, "usersTab")}
                        editUser={() => editUserDialog(user)}
                      />
                    );
                  })}
              </div>
            )}
          </div>
          {showSessionData && sessionData && (
            <AmmoniteSessionDataCard
              sessionData={sessionData}
              closeSessionView={closeSessionView}
            />
          )}
          {showCreateUser && (
            <AmmoniteCreateUser
              closeCreateUser={(event) => closeCreateUser(event)}
            />
          )}
          {showEditUser && (
            <AmmoniteEditUser
              user={selectedUser}
              closeEditUser={(event) => closeEditUser(event)}
            />
          )}
        </div>
      )}
      {userRole === "no access role" && (
        <div className="dashboardAccessDenied">
          <div className="dashboard404">404</div>
          <div className="dashboardAccessDeniedText">
            Access denied. <br></br> Please contact your administrator
          </div>
        </div>
      )}
      {showDeletePrompt && (
        <div className="blockInputPanel">
          {deleteLoading && (
            <div className="dashboardLoaderAnimation">
              <Loader type="Grid" color="#3bb9c4" height={80} width={80} />
            </div>
          )}
          {!deleteLoading && (
            <div className="deleteBox">
              <div className="confirmBoxBoldText emailCollisionText">
                This action is irreversible. <br></br> <br></br> Are you sure
                you want to delete the session for {customerName}?
              </div>
              <div className="dashboardDialogButtons">
                <div
                  className="dashboardDeleteButton"
                  onClick={() => deleteSessionDoc(customerId)}
                >
                  <AmmoniteButton
                    buttonText={"Ok"}
                    fontWeight="bold"
                    color="primary"
                    width={215}
                    height={46}
                  />
                </div>
                <div
                  className="dashboardDeleteButton"
                  onClick={() => setShowDeletePrompt(false)}
                >
                  <AmmoniteButton
                    buttonText={"Cancel"}
                    fontWeight="bold"
                    color="danger"
                    width={215}
                    height={46}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {deleteErrorOcurred && (
        <div className="blockInputPanel">
          {
            <div className="deleteBox">
              <div className="confirmBoxBoldText emailCollisionText">
                Oops, something went wrong. Please try again later or contact
                your administrator.
              </div>
              <div className="dashboardDialogButtons">
                <div
                  className="dashboardDeleteButton"
                  onClick={() => setDeleteErrorOcurred(false)}
                >
                  <AmmoniteButton
                    buttonText={"Ok"}
                    fontWeight="bold"
                    color="danger"
                    width={215}
                    height={46}
                  />
                </div>
              </div>
            </div>
          }
        </div>
      )}
    </div>
  );
}
export default AmmoniteDashboard;
