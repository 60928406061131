// Core React dependencies
import React from "react";

// Component imports
import AmmoniteCheckbox from "../../components/Checkbox/Checkbox";

// Assets
import AmmoniteCardMargin from "../../Assets/SVG/goalCardMargin";
import AmmoniteTrashcan from "../../Assets/SVG/trashcan";
import AmmonitePencilSVG from "../../Assets/SVG/pencil";

// Styling
import "./goalSummaryCard.css";

function AmmoniteSummaryGoalCard({
  inflationLinked,
  goalType,
  goalName,
  amountRequiredToday,
  goalAmount,
  goalTimeHorizon,
  deleteGoal,
  handleDelete,
  handleInflationLinked,
  editGoal,
  handleEdit,
  showSoftFacts,
  softFacts,
  suitabilityReportGoalIndex,
  handleAmendGoalDetails,
}) {
  const colourTypes = {
    "Retirement pot": "#BF802F",
    Property: " #247BA0",
    Family: " #3F762C",
    Education: " #69306D",
    "Long Term Investment": " #373986",
    "Care Fees": "#0C3957",
    Other: " #F25F5C",
  };

  const getLineColour = () => {
    switch (goalType) {
      case "Retirement pot":
        return "#F7AB4A";
      case "Education":
        return "#69306D";
      case "Property":
        return "#247BA0";
      case "Family":
        return "#3F762C";
      case "Long Term Investment":
        return "#373986";
      case "Care Fees":
        return "#0C3957";
      case "Other":
        return "#F25F5C";
      default:
        return "#69306D";
    }
  };

  const handleDeleteClick = () => {
    handleDelete();
  };

  const handleEditClick = () => {
    handleEdit();
  };

  const handleInflationLinking = () => {
    handleInflationLinked();
  };

  return (
    <div className="goalSummaryCardMainContainer">
      <div>
        <AmmoniteCardMargin colour={colourTypes[goalType]} height="268px" />
      </div>
      <div className="summaryCardBody">
        <div className="summaryCardHeader">
          <div className="summaryCardHeaderColumn">
            <div className="summaryCardHeaderBox">
              <div className="goalSummaryNameContainer">{goalName} </div>
              <div className="goalSummaryTypeContainer">{goalType}</div>
            </div>
            <div className={`goalSummaryDivider`}>
              <svg
                width="435"
                height="4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="2"
                  y1="2"
                  x2="337"
                  y2="2"
                  stroke={getLineColour()}
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>

        {!showSoftFacts && (
          <div className="summaryCardMiddle">
            <div className="summaryCardMiddleContainer">
              <div className="goalSummaryRequiredTodayLabel">
                In Today's money
              </div>
              <div className="goalSummaryRequiredTodayContainer">
                £{amountRequiredToday}
              </div>
            </div>
            <div className="summaryCardMiddleContainer">
              <div className="goalSummaryRequiredTodayLabel">
                In Tomorrow's money <p className="asterisk">*</p>
              </div>
              {inflationLinked ? (
                <div className="goalSummaryRequiredTodayContainer">
                  £{goalAmount}
                </div>
              ) : (
                <div className="goalSummaryRequiredTodayContainer">
                  £{amountRequiredToday}
                </div>
              )}
            </div>
          </div>
        )}
        {showSoftFacts && (
          <div className="summaryCardMiddle">
            <div className="summaryCardMiddleContainer goalDetailsMiddle">
              <div className="goalDetailsMiddleField">
                <div className="goalSummaryRequiredTodayLabel goalSummaryRequiredTodayLabelDetails">
                  Goal Amount
                </div>

                <div className="goalSummaryRequiredTodayContainer amountDetails">
                  £
                  <input
                    className="goalSummaryTypeContainer yearsDetails amountDetails"
                    type="number"
                    value={goalAmount}
                    onChange={(event) =>
                      handleAmendGoalDetails(
                        event,
                        "goalAmount",
                        suitabilityReportGoalIndex
                      )
                    }
                  />
                </div>
              </div>
              <div className="summaryCardEndLeft">
                <div className="summaryCardMiddleContainer goalDetailsMiddle">
                  <div className="goalDetailsMiddleField">
                    <div className="goalSummaryRequiredTodayLabel goalSummaryRequiredTodayLabelDetails">
                      Years to Goal
                    </div>

                    <input
                      aria-label="retirement-years-to-goal"
                      className="goalSummaryTypeContainer yearsDetails"
                      type="number"
                      value={goalTimeHorizon}
                      onChange={(event) =>
                        handleAmendGoalDetails(
                          event,
                          "goalTimeHorizon",
                          suitabilityReportGoalIndex
                        )
                      }
                    />
                    <div className=" yearsDetails yearsText">years</div>
                  </div>
                  <div className="goalDetailsMiddleField">
                    <textarea
                      className="goalDetailsTextArea"
                      value={softFacts}
                      placeholder="Insert notes"
                      onChange={(event) =>
                        handleAmendGoalDetails(
                          event,
                          "softFacts",
                          suitabilityReportGoalIndex
                        )
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="summaryCardEnd">
          {!showSoftFacts && (
            <div className="summaryCardEndLeft">
              <div className="goalSummaryRequiredTodayLabel">Years to Goal</div>
              <div className="goalSummaryTypeContainer">
                {goalTimeHorizon ? goalTimeHorizon + " years" : "0 years"}{" "}
              </div>
            </div>
          )}
          <div className="summaryCardEndRight">
            {deleteGoal && (
              <div className="summaryCardEndIcon">
                <div className="iconContainer" onClick={handleDeleteClick}>
                  <AmmoniteTrashcan colour="#7A7A7A" scale={1} />
                </div>
                <div className="iconLabel">Delete</div>
              </div>
            )}
            {editGoal && (
              <div className="summaryCardEndIcon">
                <div
                  className="iconContainer editIcon"
                  onClick={handleEditClick}
                >
                  <AmmonitePencilSVG colour="#3BB9C4" scale={0.7} />
                </div>
                <div className="iconLabel">Edit</div>
              </div>
            )}
            {goalType !== "Retirement pot" && !showSoftFacts && (
              <div className="summaryCardHeaderBoxInflationLinked">
                <p className="inflationLinkedLabel">Inflation-linked</p>
                <AmmoniteCheckbox
                  isBold
                  checked={inflationLinked}
                  checkedHandler={handleInflationLinking}
                  checkboxText
                  inlineText
                  scale
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AmmoniteSummaryGoalCard;
