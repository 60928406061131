// Core React dependencies
import React, { useState, useEffect, useRef, useCallback } from "react";

// Extra React dependencies
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";

// External libraries
import Button from "@material-ui/core/Button";
import emailjs from "emailjs-com";
import { useToasts } from "react-toast-notifications";

// Component imports
import AmmoniteUsername from "../../Assets/SVG/username";
import AmmonitePassword from "../../Assets/SVG/password";
import AmmoniteWarning2Icon from "../../Assets/SVG/Warning/warning2";
import AmmoniteShowIcon from "../../Assets/SVG/Show/show";
import NewAmmoniteLoginLogo from "../../Assets/SVG/AmmoniteLoginLogo/NewAmmoniteLoginLogo";
import { getEnvironmentURL } from "../../Utils/constants";

// Actions
import firebase from "../../Utils/firebase";

// Styling
import "./login.css";

function Login() {
  const history = useHistory();
  const [emailValid, setEmailValid] = useState(true);
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [displayForgottenPassword, setDisplayForgottenPassword] =
    useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [invalidResetEmail, setInvalidResetEmail] = useState(false);
  const getResetPasswordLink = firebase
    .functions()
    .httpsCallable("getResetPasswordLink");
  const { addToast } = useToasts();

  firebase
    .auth()
    .signOut()
    .then(function () {
      // Sign-out successful.
    })
    .catch(function (error) {
      // An error happened.
    });

  const validateEmail = (email) => {
    const re =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(String(email));
  };

  const forgotPassword = () => {
    setForgotPasswordModal(true);
    setInvalidResetEmail(false);
  };

  const loginHandler = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      //removes previous session

      if (validateEmail(email.value)) {
        try {
          await firebase
            .auth()
            .signInWithEmailAndPassword(email.value, password.value);
          firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((idTokenResult) => {
              if (
                !!idTokenResult.claims.admin ||
                !!idTokenResult.claims.coach
              ) {
                history.push("/chooseroute");
              } else if (!!idTokenResult.claims.customer) {
                history.push("/myReport");
              } else {
                history.push("/");
              }
            })
            .then(() => {
              localStorage.clear();
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (error) {
          if (error.code === "auth/wrong-password") {
            setDisplayForgottenPassword(true);
          } else {
            alert(error);
          }
        }
      } else {
        setEmailValid(false);
      }
    },
    [history]
  );

  const resetPassword = useCallback(async (event) => {
    event.preventDefault();
    const resetEmail = document.getElementsByName("resetEmail")[0].value;
    const emailValid = validateEmail(resetEmail);
    if (emailValid) {
      setInvalidResetEmail(false);
      let hostName = window.location.hostname;
      if (hostName !== "localhost") {
        hostName = getEnvironmentURL[process.env.REACT_APP_ENV];
      }
      await getResetPasswordLink({
        email: resetEmail,
        url: hostName,
      }).then((resetLink) => {
        const templateParams = {
          to: resetEmail,
          resetPasswordURL: resetLink.data,
        };
        if (resetLink) {
          addToast("Emailing reset password link", {
            appearance: "success2",
            autoDismiss: true,
          });
          emailjs
            .send(
              "outlook",
              "template_m2a4srx",
              templateParams,
              "user_qSZvdg7MBAMXbZIPsDdmJ"
            )
            .then(
              (result) => {
                addToast("Reset password email sent", {
                  appearance: "success2",
                  autoDismiss: true,
                });
              },
              (error) => {
                addToast("Error sending email", {
                  appearance: "error",
                  autoDismiss: false,
                });
                console.log(error.text);
              }
            );
          setForgotPasswordModal(false);
        }
      });
    } else {
      setInvalidResetEmail(true);
    }
  });

  useEffect(() => {}, [emailValid, hiddenPassword]);

  return (
    <div className="loginContainer">
      <div className="ammoniteLogoContainer">
        <NewAmmoniteLoginLogo />
      </div>
      <form className="loginCard" onSubmit={loginHandler}>
        <div className="loginHeader">Sign in to your account</div>
        <div className="unameContainer">
          <div className="unameLabelContainer">
            <div className="unameIcon">
              <AmmoniteUsername />
            </div>
            <div className="unameLabel">User name or Email address</div>
          </div>
          <input
            name="email"
            type="text"
            className={`loginInput email ${
              emailValid === false ? "redWarningBorder" : ""
            }`}
          />
          {!emailValid && (
            <div className="emailWarning">
              <AmmoniteWarning2Icon />
              <div className="emailWarningText">
                Enter a valid email address to login
              </div>
            </div>
          )}
        </div>
        <div className="pwordContainer">
          <div className="unameLabelContainer">
            <div className="pwordIcon">
              <AmmonitePassword />
            </div>
            <div className="pwordLabel">Password</div>
          </div>
          <input
            name="password"
            type={hiddenPassword ? "password" : "text"}
            className={`loginInput password ${
              displayForgottenPassword === true ? "redWarningBorder" : ""
            }`}
          />
          <div
            className="showIcon"
            onClick={() => setHiddenPassword(!hiddenPassword)}
          >
            <AmmoniteShowIcon />
          </div>
          {displayForgottenPassword && (
            <div className="passwordWarning">
              <AmmoniteWarning2Icon />
              <div className="emailWarningText">Invalid password</div>
            </div>
          )}
        </div>
        <div className="loginButtonContainer">
          <Button
            variant="contained"
            type="submit"
            style={{
              width: "123px",
              height: "46px",
              borderRadius: "8px",
              fontFamily: "Brother 1816",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "18px",
              lineHeight: "26px",
              letterSpacing: "0.02em",
              color: "#232323",
              backgroundColor: "#B1DECA",
              textTransform: "none",
            }}
          >
            Log in
          </Button>
        </div>
        {displayForgottenPassword && (
          <div className="loginForgotPassword" onClick={() => forgotPassword()}>
            Forgot password?
          </div>
        )}
      </form>
      <div className="loginInfoText">
        ammonite is authorised and regulated by the Financial Conduct Authority
        no. 843433
      </div>
      {forgotPasswordModal && (
        <div className="loginBlockInputPanel">
          <div className="forgottenPasswordContainer">
            <div
              className="x"
              onClick={(event) => setForgotPasswordModal(false)}
            ></div>
            <div className="createUserTitle">Forgot your Password?</div>
            <div className="forgotPasswordText">
              Enter your email address and we will send you instructions to
              reset your password.
            </div>
            <form className="forgotPasswordText" onSubmit={resetPassword}>
              <div className="forgotPasswordEmail">
                email:
                <input
                  name="resetEmail"
                  type="text"
                  className={`loginInput resetPasswordEmail ${
                    invalidResetEmail === true ? "redWarningBorder" : ""
                  }`}
                />
              </div>
              {invalidResetEmail && (
                <div className="emailWarning">
                  <AmmoniteWarning2Icon />
                  <div className="emailWarningText">
                    Enter a valid email address to reset password
                  </div>
                </div>
              )}
              <div className="resetPasswordButton">
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    position: "relative",
                    top: "70px",
                    alignSelf: "center",
                    width: "273px",
                    height: "46px",
                    borderRadius: "20px",
                    fontFamily: "Brother 1816",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "18px",
                    lineHeight: "26px",
                    letterSpacing: "0.02em",
                    color: "white",
                    backgroundColor: "#575A89",
                    textTransform: "none",
                  }}
                >
                  Reset Password
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(Login);
