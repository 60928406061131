import React, { useEffect, useState } from 'react';
import firebase from '../firebase';

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
	const [ currentUser, setCurrentUser ] = useState(null);

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => setCurrentUser(user));
	}, [currentUser]);

	return (
		<AuthContext.Provider
			value={{
				currentUser
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const AuthConsumer = AuthContext.Consumer
