import React from 'react';

function AmmoniteLetters() {
	return (
<svg width="209" height="164" viewBox="0 0 209 164" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="3" width="205" height="160">
<rect x="2" y="3" width="205" height="160" fill="#C4C4C4"/>
</mask>

<circle opacity="0.3" cx="104.5" cy="111.5" r="97.5" fill="#3BB9C4"/>

<path d="M95.0289 71.6589C95.0289 71.6589 115.851 72.6498 117.106 79.8332C118.36 87.0167 92.771 80.5764 92.771 80.5764L95.0289 71.6589Z" fill="#A0616A"/>
<path d="M62.1647 48.8702C62.1647 48.8702 65.6769 49.8611 68.4365 55.806C71.1961 61.7509 76.7153 67.2004 76.7153 67.2004L99.043 71.4114L95.2799 82.5581L73.9557 79.8333L60.6594 72.4022L62.1647 48.8702Z" fill="#69306D"/>
<path opacity="0.1" d="M62.1647 48.8702C62.1647 48.8702 65.6769 49.8611 68.4365 55.806C71.1961 61.7509 76.7153 67.2004 76.7153 67.2004L99.043 71.4114L95.2799 82.5581L73.9557 79.8333L60.6594 72.4022L62.1647 48.8702Z" fill="#69306D"/>
<path d="M29.0493 37.4756L31.3072 51.5948L46.8613 52.3379L45.1052 31.5306L29.0493 37.4756Z" fill="#FBBEBE"/>
<path d="M12.6174 131.728L11.8647 162.939H35.4468L38.959 150.553L41.7186 162.939H66.3042C66.3042 162.939 66.806 127.269 64.0464 127.021C61.2868 126.774 12.6174 131.728 12.6174 131.728Z" fill="#0C3957"/>
<path d="M34.0669 41.1913C41.5488 41.1913 47.6141 35.2026 47.6141 27.8151C47.6141 20.4277 41.5488 14.439 34.0669 14.439C26.585 14.439 20.5198 20.4277 20.5198 27.8151C20.5198 35.2026 26.585 41.1913 34.0669 41.1913Z" fill="#FBBEBE"/>
<path d="M11.2374 133.09L18.2618 98.9067L8.97949 53.5765L30.3575 45.656L40.8404 44.4114L46.1104 43.4401L49.8718 44.4114C49.8718 44.4114 64.9242 48.127 65.6768 50.8518C66.4294 53.5765 62.6663 75.8701 62.6663 75.8701L59.1541 94.2003L66.9312 127.145C66.9312 127.145 34.5686 138.044 11.7391 133.09H11.2374Z" fill="#69306D"/>
<path opacity="0.1" d="M20.645 66.0856V75.2507L50.246 75.2906L22.1503 72.2782L20.645 66.0856Z" fill="black"/>
<path opacity="0.1" d="M18.1028 91.3977L55.2656 86.1498L18.1028 96.5425V91.3977Z" fill="black"/>
<path opacity="0.1" d="M77.0915 67.3242L73.1074 81.4992L79.8512 67.7919L77.0915 67.3242Z" fill="black"/>
<path d="M49.0989 17.8826C48.9061 16.9646 48.3777 16.1488 47.6144 15.5911C47.7384 14.6353 47.7018 13.666 47.506 12.722C46.8181 9.96426 44.6293 9.93556 42.6784 8.57829C41.8321 7.98942 41.9261 7.53954 41.5351 6.65608C41.0228 5.49796 40.1917 4.50501 39.1362 3.79009C37.8905 2.85121 36.5737 1.88146 35.0247 1.64602C31.7696 1.15125 28.9979 3.98148 25.7847 4.69507C24.3684 5.00957 22.8847 4.90308 21.4632 5.19349C20.0416 5.4839 18.5747 6.33888 18.2465 7.73511C18.095 8.3797 18.2058 9.06327 18.053 9.70757C17.7491 10.9881 16.5189 11.7997 15.6559 12.8016C13.7475 15.0171 13.6561 18.3654 14.7995 21.0458C15.0412 21.6009 15.3264 22.1366 15.6523 22.648C15.6618 23.8704 15.9132 25.0792 16.3923 26.2065C17.5357 28.8869 19.7179 31.0012 22.0328 32.7999C21.9052 31.932 21.858 31.0544 21.8919 30.1781C21.8823 29.8843 21.946 29.5926 22.0774 29.3288C22.3988 28.7881 23.1332 28.6988 23.7099 28.4362C24.9043 27.8924 25.4727 26.515 25.6169 25.2242C25.761 23.9334 25.5948 22.6144 25.8427 21.3391C25.8735 21.0436 25.9667 20.7577 26.1162 20.5C26.2658 20.2423 26.4684 20.0183 26.711 19.8426C27.1518 19.641 27.6419 19.5682 28.1234 19.6328C30.466 19.7344 32.9089 19.8965 35.0618 18.9791C35.7962 18.6661 36.4916 18.229 37.2799 18.095C39.0686 17.7911 40.7531 19.1521 41.6342 20.7189C42.5153 22.2857 42.8665 24.0917 43.6654 25.7009C44.4643 27.3101 45.9545 28.8103 47.7692 28.7797C47.8705 28.7884 47.9726 28.7759 48.0686 28.743C48.1647 28.7101 48.2527 28.6576 48.3268 28.5888C48.4162 28.4577 48.4642 28.3032 48.4646 28.1451L49.1489 21.3631C49.3323 20.2085 49.3154 19.0316 49.0989 17.8826V17.8826Z" fill="#0C3957"/>
<path d="M154.862 63.8562H58.5271V163.186H154.862V63.8562Z" fill="white"/>
<path d="M139.81 85.6543H68.562V95.5625H139.81V85.6543Z" fill="#0C3957"/>
<path d="M127.471 111.911H80.8103V116.865H127.471V111.911Z" fill="#0C3957"/>
<path d="M127.561 120.581H80.9004V125.535H127.561V120.581Z" fill="#0C3957"/>
<path d="M104.321 129.251H80.9905V134.205H104.321V129.251Z" fill="#0C3957"/>
<path d="M141.064 154.269H130.277V159.223H141.064V154.269Z" fill="#0C3957"/>
<path d="M51.6279 76.3654C51.6279 76.3654 73.4539 73.1452 76.7152 77.8516C79.9766 82.5581 53.6349 85.0351 53.6349 85.0351L51.6279 76.3654Z" fill="#FBBEBE"/>
<path d="M11.4882 53.3289H9.91631C9.2698 53.327 8.64647 53.5666 8.17129 53.9994C6.67911 55.3674 3.52396 58.8977 2.20594 65.2187C0.44983 73.6407 -2.56065 89.9892 4.21293 91.2277C10.9865 92.4663 55.391 86.5213 55.391 86.5213L52.1297 75.1269L20.0179 75.3746C20.0179 75.3746 20.5197 69.9251 19.5162 66.4572C18.5127 62.9894 11.4882 53.3289 11.4882 53.3289Z" fill="#69306D"/>
<path d="M209 162.665H4.13818V163.354H209V162.665Z" fill="#7A7A7A"/>
</svg>

	);
}

export default AmmoniteLetters;
