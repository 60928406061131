import { getSetSBReportIDCurrentIndex } from "./getSetSBReportIDCurrentIndex";
import firebase from "../firebase";

export const saveContextToFirebase = async (currentState) => {
  const sessionRef = firebase
    .firestore()
    .collection("sessions")
    .doc(localStorage.getItem("currentSession"));
  let _thisContextIndex;
  let data = [];
  await sessionRef.get().then((doc) => {
    if (doc.exists) {
      data = doc.data().suitabilityReports;
      _thisContextIndex = getSetSBReportIDCurrentIndex(doc).currentIndex;
    } else {
      console.log("ERROR DOC DOES NOT EXIST");
    }
  });

  if (_thisContextIndex.currentIndex !== -1) {
    data[_thisContextIndex] = currentState;
  }

  if (_thisContextIndex.currentIndex !== -1) {
    data[_thisContextIndex] = currentState;
  }

  return sessionRef
    .update({
      suitabilityReports: data,
    })
    .then(function () {
      console.log("Document successfully updated!");
    })
    .catch(function (error) {
      // The document probably doesn't exist.
      console.error("Error updating document: ", error);
    });
};
