import React from 'react';

function AmmoniteMenuLine({ colour }) {
	return (
		<svg height="40" width="40">
			<line x1="22" y1="0" x2="22" y2="40" style={{ stroke: colour, strokeWidth: 4 }} />
		</svg>
	);
}

export default AmmoniteMenuLine;
