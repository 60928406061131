import React from "react";

function DashBoardLine({ sent, role = "" }) {
  let colour = "#3BB9C4";
  if (sent) {
    colour = "#BF802F";
  }
  if (role) {
    switch (role) {
      case "admin":
        colour = "#247BA0";
        break;
      case "coach":
        colour = "#69306D";
        break;
      case "customer":
        colour = "#3F762C";
        break;
      default:
        colour = "#3F762C";
    }
  }
  return (
    <svg
      width="1049"
      height="3"
      viewBox="0 0 1049 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="1.57666"
        x2="1048.35"
        y2="1.57666"
        stroke={colour}
        strokeWidth="2"
      />
    </svg>
  );
}

export default DashBoardLine;
