import React from 'react';

function AmmoniteHouse({ colour, width, height, cx, cy, r }) {
	return (
		<svg width="363" height="170" viewBox="0 0 363 170" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M334.994 153.717L335.949 160.726L329.259 161.364L330.215 152.762L334.994 153.717Z"
				fill="#FBBEBE"
			/>
			<path
				d="M331.489 159.452C331.489 159.452 329.896 157.859 332.126 157.859C333.558 157.889 334.968 158.214 336.268 158.815L337.861 164.55C337.861 164.55 344.392 169.806 337.064 169.169C329.737 168.532 329.896 169.01 329.896 169.01C329.896 169.01 327.666 168.373 327.348 167.736C327.029 167.098 328.941 157.859 328.941 157.859C328.941 157.859 329.896 160.408 331.489 159.452Z"
				fill="#0C3957"
			/>
			<path
				d="M313.967 153.717L313.011 160.726L319.702 161.364L318.746 152.762L313.967 153.717Z"
				fill="#FBBEBE"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M308.999 169C309 168.833 309 168.667 309 168.5C309 103.607 245.424 51 167 51C88.5756 51 25 103.607 25 168.5C25 168.667 25.0004 168.833 25.0013 169H308.999Z"
				fill="#373986"
				fill-opacity="0.2"
			/>
			<path
				d="M317.79 159.452C317.79 159.452 319.383 157.859 317.153 157.859C315.721 157.889 314.311 158.214 313.011 158.815L311.418 164.55C311.418 164.55 304.887 169.806 312.215 169.169C319.542 168.532 318.427 169.01 318.427 169.01C318.427 169.01 320.657 168.373 320.976 167.736C321.295 167.098 320.339 157.859 320.339 157.859C320.339 157.859 319.383 160.408 317.79 159.452Z"
				fill="#0C3957"
			/>
			<path
				d="M303.454 57.5049C303.454 57.5049 304.091 66.1067 304.091 66.4253C304.091 66.7439 299.631 88.7264 299.631 88.7264C299.631 88.7264 293.259 100.833 299.631 100.514C306.002 100.196 304.091 89.045 304.091 89.045L310.781 70.5669L307.914 57.5049H303.454Z"
				fill="#FBBEBE"
			/>
			<path
				d="M306.64 88.089L305.047 90.3191C305.047 90.3191 304.091 99.8767 305.047 103.063C306.003 106.248 307.914 120.903 307.914 121.859C307.914 122.815 308.233 123.452 307.914 123.771C307.596 124.089 306.64 124.089 307.277 124.726C307.914 125.364 307.914 127.275 307.914 127.275V128.868L312.056 152.443C312.056 152.443 309.507 155.311 311.419 155.311C313.33 155.311 320.339 155.311 320.02 152.125L319.383 151.806C319.383 151.806 318.427 130.78 318.746 129.824C319.065 128.868 318.746 126.957 318.746 126.957C318.746 126.957 318.109 126.001 318.746 125.682C319.383 125.364 319.383 122.815 319.383 122.815L322.25 103.381C322.25 103.381 324.799 116.443 326.392 117.718C327.985 118.992 327.666 122.178 327.666 122.178L328.941 150.213C328.941 150.213 327.666 153.399 328.941 154.036C330.215 154.674 335.631 156.904 335.95 153.718C336.268 150.532 337.861 122.815 337.861 121.541C337.861 120.266 337.543 119.629 337.861 118.673C338.18 117.718 337.543 117.718 337.861 117.718C338.18 117.718 337.224 116.762 337.224 116.125V100.514C337.224 100.514 337.543 97.328 337.861 96.6909C338.18 96.0537 337.543 95.4165 337.861 94.4608C338.18 93.505 337.224 90.3191 337.224 90.3191L322.888 86.4961L306.64 88.089Z"
				fill="#0C3957"
			/>
			<path d="M93.9969 56.5273H91.1606V168.559H93.9969V56.5273Z" fill="#3F3D56" />
			<path
				d="M120.172 70.8046C120.353 109.979 92.7913 141.865 92.7913 141.865C92.7913 141.865 64.9371 110.235 64.7565 71.06C64.5759 31.8852 92.1375 0 92.1375 0C92.1375 0 119.992 31.6298 120.172 70.8046Z"
				fill="#BF802F"
			/>
			<path d="M291.347 122.178H71.8413V168.691H291.347V122.178Z" fill="#F2F2F2" />
			<path d="M233.524 134.922H214.249V168.692H233.524V134.922Z" fill="#373986" />
			<path d="M117.718 132.691H80.4431V168.692H117.718V132.691Z" fill="#373986" />
			<path d="M202.143 131.416H132.213V168.691H202.143V131.416Z" fill="#373986" />
			<path d="M362.233 168.532H0V169.169H362.233V168.532Z" fill="#CFD2E3" />
			<path d="M202.302 164.072H132.213V164.709H202.302V164.072Z" fill="#F2F2F2" />
			<path d="M117.718 164.072H80.4431V164.709H117.718V164.072Z" fill="#F2F2F2" />
			<path d="M202.302 161.205H132.213V161.842H202.302V161.205Z" fill="#F2F2F2" />
			<path d="M117.718 161.205H80.4431V161.842H117.718V161.205Z" fill="#F2F2F2" />
			<path d="M202.302 158.338H132.213V158.975H202.302V158.338Z" fill="#F2F2F2" />
			<path d="M117.718 158.338H80.4431V158.975H117.718V158.338Z" fill="#F2F2F2" />
			<path d="M202.302 155.471H132.213V156.108H202.302V155.471Z" fill="#F2F2F2" />
			<path d="M117.718 155.471H80.4431V156.108H117.718V155.471Z" fill="#F2F2F2" />
			<path
				d="M230.975 155.471C231.679 155.471 232.249 154.9 232.249 154.196C232.249 153.492 231.679 152.922 230.975 152.922C230.271 152.922 229.701 153.492 229.701 154.196C229.701 154.9 230.271 155.471 230.975 155.471Z"
				fill="#F2F2F2"
			/>
			<path d="M202.302 152.604H132.213V153.241H202.302V152.604Z" fill="#F2F2F2" />
			<path d="M117.718 152.604H80.4431V153.241H117.718V152.604Z" fill="#F2F2F2" />
			<path d="M202.302 149.736H132.213V150.374H202.302V149.736Z" fill="#F2F2F2" />
			<path d="M117.718 149.736H80.4431V150.374H117.718V149.736Z" fill="#F2F2F2" />
			<path d="M276.374 148.78H266.976V157.86H276.374V148.78Z" fill="#373986" />
			<path d="M263.79 148.78H254.391V157.86H263.79V148.78Z" fill="#373986" />
			<path d="M202.302 146.868H132.213V147.505H202.302V146.868Z" fill="#F2F2F2" />
			<path d="M117.718 146.868H80.4431V147.505H117.718V146.868Z" fill="#F2F2F2" />
			<path d="M229.382 144.23H224.751V148.705H229.382V144.23Z" fill="#F2F2F2" />
			<path d="M223.182 144.23H218.551V148.705H223.182V144.23Z" fill="#F2F2F2" />
			<path d="M202.302 144.001H132.213V144.638H202.302V144.001Z" fill="#F2F2F2" />
			<path d="M117.718 144.001H80.4431V144.638H117.718V144.001Z" fill="#F2F2F2" />
			<path d="M202.302 141.134H132.213V141.771H202.302V141.134Z" fill="#F2F2F2" />
			<path d="M117.718 141.134H80.4431V141.771H117.718V141.134Z" fill="#F2F2F2" />
			<path d="M229.382 138.343H224.751V142.66H229.382V138.343Z" fill="#F2F2F2" />
			<path d="M223.182 138.343H218.551V142.66H223.182V138.343Z" fill="#F2F2F2" />
			<path d="M202.302 138.267H132.213V138.904H202.302V138.267Z" fill="#F2F2F2" />
			<path d="M117.718 138.267H80.4431V138.904H117.718V138.267Z" fill="#F2F2F2" />
			<path d="M276.374 136.833H266.976V145.594H276.374V136.833Z" fill="#373986" />
			<path d="M263.79 136.833H254.391V145.594H263.79V136.833Z" fill="#373986" />
			<path d="M202.302 135.399H132.213V136.037H202.302V135.399Z" fill="#F2F2F2" />
			<path d="M117.718 135.399H80.4431V136.037H117.718V135.399Z" fill="#F2F2F2" />
			<path d="M59.187 95.6387H57.3154V169.568H59.187V95.6387Z" fill="#3F762C" />
			<path d="M34.8555 95.6387H32.9839V169.568H34.8555V95.6387Z" fill="#3F762C" />
			<path d="M64.8325 127.276L88.7265 77.2578H279.56L297.401 127.276H64.8325Z" fill="#373986" />
			<path d="M212.975 80.4434H124.408V109.753H212.975V80.4434Z" fill="#F2F2F2" />
			<path d="M204.851 97.8135H197.496V104.919H204.851V97.8135Z" fill="#373986" />
			<path d="M195.003 97.8135H187.647V104.919H195.003V97.8135Z" fill="#373986" />
			<path d="M177.453 97.8135H170.098V104.919H177.453V97.8135Z" fill="#373986" />
			<path d="M167.604 97.8135H160.249V104.919H167.604V97.8135Z" fill="#373986" />
			<path d="M149.736 97.8135H142.38V104.919H149.736V97.8135Z" fill="#373986" />
			<path d="M139.887 97.8135H132.532V104.919H139.887V97.8135Z" fill="#373986" />
			<path d="M204.851 88.4629H197.496V95.3194H204.851V88.4629Z" fill="#373986" />
			<path d="M195.003 88.4629H187.647V95.3194H195.003V88.4629Z" fill="#373986" />
			<path d="M177.453 88.4629H170.098V95.3194H177.453V88.4629Z" fill="#373986" />
			<path d="M167.604 88.4629H160.249V95.3194H167.604V88.4629Z" fill="#373986" />
			<path d="M149.736 88.4629H142.38V95.3194H149.736V88.4629Z" fill="#373986" />
			<path d="M139.887 88.4629H132.532V95.3194H139.887V88.4629Z" fill="#373986" />
			<path
				d="M76.46 104.06C76.5791 129.912 58.3914 150.952 58.3914 150.952C58.3914 150.952 40.0106 130.08 39.8915 104.229C39.7723 78.3777 57.96 57.3369 57.96 57.3369C57.96 57.3369 76.3408 78.2092 76.46 104.06Z"
				fill="#BF802F"
			/>
			<path
				d="M52.1289 104.06C52.2481 129.912 34.0604 150.952 34.0604 150.952C34.0604 150.952 15.6795 130.08 15.5604 104.229C15.4413 78.3777 33.6289 57.3369 33.6289 57.3369C33.6289 57.3369 52.0098 78.2092 52.1289 104.06Z"
				fill="#BF802F"
			/>
			<path d="M130.143 56.5488L116.125 85.8588H221.258L212.975 56.5488H130.143Z" fill="#373986" />
			<path
				d="M279.806 102.611C294.058 102.611 305.611 91.0575 305.611 76.8055C305.611 62.5535 294.058 51 279.806 51C265.554 51 254 62.5535 254 76.8055C254 91.0575 265.554 102.611 279.806 102.611Z"
				fill="#BF802F"
			/>
			<path
				d="M280 99C292.15 99 302 89.1503 302 77C302 64.8497 292.15 55 280 55C267.85 55 258 64.8497 258 77C258 89.1503 267.85 99 280 99Z"
				fill="#F7AB4A"
			/>
			<path
				d="M274.794 76.4631V72.8811C274.794 70.7026 275.415 69.0164 276.657 67.8224C277.921 66.6075 279.677 66 281.925 66C283.724 66 285.266 66.3037 286.55 66.9112V69.6448C285.223 68.9745 283.777 68.6393 282.214 68.6393C280.801 68.6393 279.698 69.0269 278.906 69.8019C278.135 70.577 277.749 71.6767 277.749 73.1011V76.4631H284.655V78.8511H277.749V82.4016C277.749 84.0774 277.364 85.4076 276.593 86.3921H287V89H272.064C273.884 86.9053 274.794 84.7163 274.794 82.4331V78.8511H272V76.4631H274.794Z"
				fill="#A56411"
			/>
			<path
				d="M310.781 32.336C315.356 32.336 319.064 28.6275 319.064 24.0528C319.064 19.4781 315.356 15.7695 310.781 15.7695C306.206 15.7695 302.498 19.4781 302.498 24.0528C302.498 28.6275 306.206 32.336 310.781 32.336Z"
				fill="#FBBEBE"
			/>
			<path
				d="M307.914 30.1058C307.914 30.1058 309.188 36.4775 309.188 37.4333C309.188 38.389 314.604 41.2563 314.604 41.2563L320.657 33.6102C320.657 33.6102 316.834 28.1943 316.834 26.9199L307.914 30.1058Z"
				fill="#FBBEBE"
			/>
			<path
				d="M312.693 38.3896C312.693 38.3896 309.916 37.6506 309.074 36.4272C309.074 36.4272 300.268 45.0799 300.268 46.6729L307.277 59.0978C307.277 59.0978 307.277 63.558 307.914 63.8765C308.551 64.1951 307.914 63.8765 307.914 65.1509C307.914 66.4252 306.003 74.7085 306.64 75.9828C307.277 77.2572 307.914 76.3014 307.277 78.2129C306.64 80.1245 305.047 90.3192 305.047 90.3192C305.047 90.3192 310.781 88.4077 319.065 91.5936C327.348 94.7794 337.224 90.3192 337.224 90.3192C337.224 90.3192 334.675 84.5847 335.313 82.6732C335.95 80.7616 333.72 77.2572 333.72 77.2572L332.127 47.9472C332.127 47.9472 334.357 34.8852 332.764 34.248L329.578 32.9737L319.376 31.7197C319.376 31.7197 316.835 38.071 312.693 38.3896Z"
				fill="white"
			/>
			<path
				d="M303.454 44.4434L300.268 46.6735C300.268 46.6735 298.675 51.4523 300.268 53.6824C301.861 55.9125 302.498 59.0983 302.498 59.0983L308.87 58.4612L303.454 44.4434Z"
				fill="white"
			/>
			<path
				d="M327.029 47.3105C327.029 47.3105 325.184 52.0545 325.57 56.7483C325.64 57.5886 325.923 58.3973 326.392 59.0983L303.79 69.8286C303.79 69.8286 293.578 70.886 296.126 75.3462C298.675 79.8064 307.914 73.1161 307.914 73.1161C307.914 73.1161 332.127 69.6116 334.038 66.1072C335.95 62.6027 337.224 47.9477 337.224 47.9477L327.029 47.3105Z"
				fill="#FBBEBE"
			/>
			<path
				d="M327.985 34.248H332.764C332.764 34.248 336.268 34.8852 337.224 41.257C338.18 47.6287 339.135 49.2216 338.18 49.2216C337.224 49.2216 325.755 48.903 325.755 48.5845C325.755 48.2659 327.985 34.248 327.985 34.248Z"
				fill="white"
			/>
			<path
				d="M304.457 15.2999L303.02 14.7246C303.02 14.7246 306.025 11.4171 310.205 11.7047L309.029 10.4104C309.029 10.4104 311.903 9.26 314.515 12.2799C315.888 13.8674 317.477 15.7334 318.468 17.8355H320.006L319.364 19.2496L321.612 20.6637L319.305 20.4097C319.523 21.6305 319.449 22.8858 319.087 24.072L319.149 25.1897C319.149 25.1897 316.474 21.0521 316.474 20.4768V21.9149C316.474 21.9149 315.037 20.6206 315.037 19.7578L314.254 20.7645L313.862 19.1826L309.029 20.7645L309.813 19.4702L306.808 19.9016L307.984 18.3198C307.984 18.3198 304.588 20.1892 304.457 21.7711C304.327 23.353 302.629 25.3662 302.629 25.3662L301.845 23.9282C301.845 23.9282 300.669 17.4569 304.457 15.2999Z"
				fill="#0C3957"
			/>
		</svg>
	);
}

export default AmmoniteHouse;
