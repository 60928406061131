import React from 'react';

function OtherFocusButton({ colour }) {
	return (
<svg width="87" height="72" viewBox="0 0 87 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f)">
<path d="M4 36C4 18.3269 18.1478 4 35.6 4H51.4C68.8522 4 83 18.3269 83 36C83 53.6731 68.8522 68 51.4 68H35.6C18.1478 68 4 53.6731 4 36Z" fill={colour}/>
</g>
<path d="M6 35C6 18.4315 19.4315 5 36 5H51C67.5685 5 81 18.4315 81 35C81 51.5685 67.5685 65 51 65H36C19.4315 65 6 51.5685 6 35Z" fill="#F25F5C"/>
<path d="M54.5 30.5C53.5716 30.5026 52.6667 30.7923 51.9094 31.3294C51.1521 31.8665 50.5795 32.6247 50.27 33.5H30.5C30.1022 33.5 29.7206 33.6581 29.4393 33.9394C29.158 34.2207 29 34.6022 29 35C29 35.3978 29.158 35.7794 29.4393 36.0607C29.7206 36.342 30.1022 36.5 30.5 36.5H50.27C50.5452 37.2783 51.0292 37.966 51.669 38.4876C52.3088 39.0093 53.0798 39.3449 53.8975 39.4578C54.7153 39.5706 55.5484 39.4563 56.3056 39.1274C57.0627 38.7985 57.7148 38.2676 58.1905 37.5929C58.6662 36.9182 58.9471 36.1256 59.0025 35.302C59.0579 34.4783 58.8856 33.6553 58.5046 32.9229C58.1236 32.1906 57.5484 31.5772 56.8421 31.1499C56.1358 30.7225 55.3255 30.4977 54.5 30.5ZM54.5 36.5C54.2033 36.5 53.9133 36.412 53.6666 36.2472C53.42 36.0824 53.2277 35.8481 53.1142 35.574C53.0006 35.3 52.9709 34.9984 53.0288 34.7074C53.0867 34.4164 53.2296 34.1491 53.4393 33.9394C53.6491 33.7296 53.9164 33.5867 54.2074 33.5288C54.4983 33.471 54.7999 33.5007 55.074 33.6142C55.3481 33.7277 55.5824 33.92 55.7472 34.1667C55.912 34.4133 56 34.7033 56 35C56 35.3978 55.842 35.7794 55.5607 36.0607C55.2794 36.342 54.8978 36.5 54.5 36.5Z" fill="white"/>
<path d="M30.5 27.5001H32.27C32.5853 28.3688 33.1604 29.1194 33.9173 29.6498C34.6741 30.1803 35.5758 30.4648 36.5 30.4648C37.4242 30.4648 38.3259 30.1803 39.0828 29.6498C39.8396 29.1194 40.4147 28.3688 40.73 27.5001H57.5C57.8978 27.5001 58.2794 27.3421 58.5607 27.0608C58.842 26.7795 59 26.3979 59 26.0001C59 25.6023 58.842 25.2208 58.5607 24.9395C58.2794 24.6582 57.8978 24.5001 57.5 24.5001H40.73C40.4147 23.6314 39.8396 22.8808 39.0828 22.3504C38.3259 21.8199 37.4242 21.5354 36.5 21.5354C35.5758 21.5354 34.6741 21.8199 33.9173 22.3504C33.1604 22.8808 32.5853 23.6314 32.27 24.5001H30.5C30.1022 24.5001 29.7206 24.6582 29.4393 24.9395C29.158 25.2208 29 25.6023 29 26.0001C29 26.3979 29.158 26.7795 29.4393 27.0608C29.7206 27.3421 30.1022 27.5001 30.5 27.5001ZM36.5 24.5001C36.7967 24.5001 37.0867 24.5881 37.3334 24.7529C37.58 24.9177 37.7723 25.152 37.8858 25.4261C37.9994 25.7002 38.0291 26.0018 37.9712 26.2928C37.9133 26.5837 37.7704 26.851 37.5607 27.0608C37.3509 27.2705 37.0836 27.4134 36.7926 27.4713C36.5017 27.5291 36.2001 27.4994 35.926 27.3859C35.6519 27.2724 35.4176 27.0801 35.2528 26.8335C35.088 26.5868 35 26.2968 35 26.0001C35 25.6023 35.158 25.2208 35.4393 24.9395C35.7206 24.6582 36.1022 24.5001 36.5 24.5001Z" fill="white"/>
<path d="M57.5 42.5001H46.73C46.4147 41.6314 45.8396 40.8808 45.0828 40.3504C44.3259 39.8199 43.4242 39.5354 42.5 39.5354C41.5758 39.5354 40.6741 39.8199 39.9172 40.3504C39.1604 40.8808 38.5853 41.6314 38.27 42.5001H30.5C30.1022 42.5001 29.7206 42.6581 29.4393 42.9394C29.158 43.2207 29 43.6023 29 44.0001C29 44.3979 29.158 44.7795 29.4393 45.0608C29.7206 45.3421 30.1022 45.5001 30.5 45.5001H38.27C38.5853 46.3688 39.1604 47.1194 39.9172 47.6498C40.6741 48.1803 41.5758 48.4648 42.5 48.4648C43.4242 48.4648 44.3259 48.1803 45.0828 47.6498C45.8396 47.1194 46.4147 46.3688 46.73 45.5001H57.5C57.8978 45.5001 58.2794 45.3421 58.5607 45.0608C58.842 44.7795 59 44.3979 59 44.0001C59 43.6023 58.842 43.2207 58.5607 42.9394C58.2794 42.6581 57.8978 42.5001 57.5 42.5001ZM42.5 45.5001C42.2033 45.5001 41.9133 45.4121 41.6666 45.2473C41.42 45.0825 41.2277 44.8482 41.1142 44.5741C41.0006 44.3 40.9709 43.9984 41.0288 43.7075C41.0867 43.4165 41.2296 43.1492 41.4393 42.9394C41.6491 42.7297 41.9164 42.5868 42.2074 42.5289C42.4983 42.471 42.7999 42.5007 43.074 42.6143C43.3481 42.7278 43.5824 42.9201 43.7472 43.1667C43.912 43.4134 44 43.7034 44 44.0001C44 44.3979 43.842 44.7795 43.5607 45.0608C43.2794 45.3421 42.8978 45.5001 42.5 45.5001Z" fill="white"/>
<defs>
<filter id="filter0_f" x="0" y="0" width="87" height="72" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur"/>
</filter>
</defs>
</svg>




	);
}

export default OtherFocusButton;
