//React
import React, { useState, Fragment } from 'react';

//external libraries

//components
import AmmoniteMenuCircleEmpty from '../../Assets/SVG/menuCircleEmpty';
import AmmoniteMenuCircleFilledTicked from '../../Assets/SVG/menuCircleFilledTicked';
import './roundSwitch.css';

function AmmoniteRoundSwitch({ colour, switchText, on, switchHandler }) {
	const [ onSwitch, setOnSwitch ] = useState(true);

	const handleChange = () => {
		setOnSwitch(!onSwitch);
		switchHandler();
	};

	return (
		<div className="roundSwitchContainer" onClick={handleChange}>
			{on ? (
				<div className="roundSwitch">
					<AmmoniteMenuCircleFilledTicked width="36" height="36" cx="18" cy="18" r="16" />
				</div>
			) : (
				<div className="roundSwitch">
					<AmmoniteMenuCircleEmpty colour={colour} width="36" height="36" cx="18" cy="18" r="16" />
				</div>
			)}
			<div className={` switchText ${on === true ? 'isBold' : ''}`}>{switchText}</div>
		</div>
	);
}

export default AmmoniteRoundSwitch;
