import React from 'react';

function AmmoniteInvestments() {
	return (
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M26.7963 6.54009C26.7669 6.4237 26.7081 6.32072 26.6521 6.21539C26.6066 6.1265 26.5738 6.03307 26.5139 5.95595C26.4576 5.87972 26.3789 5.82557 26.3062 5.75985C26.2157 5.67588 26.1317 5.59047 26.0257 5.52875C26.0078 5.51738 25.9969 5.49932 25.9778 5.48994C25.908 5.45259 25.8324 5.45068 25.7599 5.42419C25.6481 5.38255 25.5376 5.33892 25.4176 5.32133C25.2999 5.30663 25.1903 5.32085 25.0745 5.32965C24.9939 5.3357 24.9159 5.31554 24.833 5.33407L16.7002 7.15051C15.8008 7.35141 15.2365 8.24251 15.437 9.14039C15.6375 10.0383 16.5274 10.6046 17.4268 10.4037L22.0235 9.37704L17.1691 18.5013L9.28217 15.8825C8.50114 15.6197 7.65045 15.9703 7.27415 16.7015L1.32122 28.2775C0.901092 29.0954 1.22305 30.1011 2.04095 30.5213C2.40341 30.7067 2.79862 30.7482 3.16622 30.6661C3.63304 30.5619 4.05065 30.2602 4.28624 29.8012L9.58688 19.4946L17.4541 22.1092C18.2268 22.3653 19.07 22.0267 19.4511 21.3097L24.5517 11.7206L25.3862 15.4569C25.5868 16.3548 26.4766 16.9211 27.3761 16.7202C28.2755 16.5193 28.8398 15.6282 28.6393 14.7303L26.8228 6.59737C26.8181 6.57622 26.8027 6.56087 26.7963 6.54009Z" fill="#373986"/>
</svg>






	);
}

export default AmmoniteInvestments;
