import React from 'react';

function AmmoniteEducationSummaryIcon({colour}) {
	let color = "#3F762C"
	if (colour) {
		color = colour
	}
	return (
		<svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M29.8504 8.80155V8.62714C29.7772 8.45609 29.7772 8.36889 29.6335 8.19784V8.11064L29.5603 8.02344C29.4871 7.93624 29.3434 7.85239 29.1998 7.76519L15.4292 0.128289C15.1419 -0.042763 14.7814 -0.042763 14.4912 0.128289L0.647819 7.76853C0.214065 8.02679 0 8.45609 0 8.96921C0 9.48572 0.287294 9.99886 0.647819 10.1699L5.4079 12.7457V20.9897C5.4079 24.4242 9.5173 27 14.9986 27C20.4798 27 24.5892 24.4242 24.5892 20.9897V12.7457L27.6903 11.0285V22.5321C27.6903 23.3036 28.1945 23.9073 28.8452 23.9073C29.493 23.9073 30 23.3069 30 22.5321V8.7944C29.8507 8.88496 29.8504 8.8854 29.8504 8.80155ZM14.9254 24.4274C10.4527 24.4274 7.64138 22.4519 7.64138 21.164V14.1241L14.4181 17.9006C14.5617 17.9878 14.7054 17.9878 14.9223 17.9878C15.0659 17.9878 15.2828 17.9878 15.4264 17.9006L22.2031 14.1241V21.164C22.206 22.4519 19.3223 24.4274 14.9254 24.4274ZM14.9254 15.1535L3.8227 9.05604L14.9254 2.95854L26.0281 9.05604L14.9254 15.1535Z"
				fill={color}
			/>
		</svg>
	);
}

export default AmmoniteEducationSummaryIcon;
