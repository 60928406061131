import React from 'react';

function AmmoniteEditContext() {
	return (
<svg width="76" height="54" viewBox="0 0 76 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="76" height="54" rx="27" fill="#247BA0"/>
<path d="M30.526 34V31.986H24.028V28.053H29.291V26.096H24.028V22.334H30.241V20.32H21.634V34H30.526ZM41.4927 20.32H39.1747V25.032C39.1747 25.545 39.2317 25.811 39.2317 25.811H39.1937C38.6807 24.994 37.5217 24.253 36.0587 24.253C33.6267 24.253 31.6887 26.191 31.6887 29.25C31.6887 32.271 33.6267 34.247 36.1157 34.247C37.5787 34.247 38.8707 33.354 39.3267 32.347H39.3647C39.3647 32.347 39.3077 32.613 39.3267 33.012L39.3647 34H41.4927V20.32ZM36.4957 26.229C37.7687 26.229 39.1177 27.198 39.1177 29.25C39.1177 31.302 37.7687 32.271 36.4957 32.271C34.9567 32.271 33.8927 31.017 33.8927 29.25C33.8927 27.464 34.9567 26.229 36.4957 26.229ZM44.5464 24.5V34H46.8644V24.5H44.5464ZM44.3564 21.764C44.3564 22.543 44.8884 23.056 45.7054 23.056C46.5034 23.056 47.0544 22.543 47.0544 21.764C47.0544 20.966 46.5034 20.453 45.7054 20.453C44.8884 20.453 44.3564 20.966 44.3564 21.764ZM48.6162 24.5V26.343H50.0602V30.542C50.0602 30.865 50.0792 31.188 50.0792 31.492C50.1932 33.544 51.6372 34.247 53.0812 34.247C54.1072 34.247 54.9242 34.038 55.3612 33.81V31.872C54.8862 32.081 54.2402 32.271 53.6512 32.271C52.9482 32.271 52.3402 31.872 52.3402 30.694V26.343H55.0382V24.5H52.3402V21.65H50.0982V24.5H48.6162Z" fill="white"/>
</svg>

	);
}

export default AmmoniteEditContext;