import React from 'react';

function AmmoniteFactRisk() {
	return (
<svg width="227" height="146" viewBox="0 0 227 146" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M195.616 145.292C182.171 145.292 171.273 144.487 171.273 143.493C171.273 142.5 182.171 141.695 195.616 141.695C209.061 141.695 219.959 142.5 219.959 143.493C219.959 144.487 209.061 145.292 195.616 145.292Z" fill="#DEDEDE"/>
<path d="M212.324 18.0317C212.324 18.0317 209.635 25.6854 211.289 26.8629C212.944 28.0403 205.704 37.6565 205.704 37.6565L197.016 35.3015L201.774 27.2554C201.774 27.2554 202.395 19.2092 202.395 18.0317C202.395 16.8543 212.324 18.0317 212.324 18.0317Z" fill="#FFB8B8"/>
<path opacity="0.1" d="M212.324 18.0317C212.324 18.0317 209.635 25.6854 211.289 26.8629C212.944 28.0403 205.704 37.6565 205.704 37.6565L197.016 35.3015L201.774 27.2554C201.774 27.2554 202.395 19.2092 202.395 18.0317C202.395 16.8543 212.324 18.0317 212.324 18.0317Z" fill="black"/>
<path d="M216.837 72.5882C216.837 72.5882 217.622 82.4005 216.837 87.503C216.052 92.6054 214.875 106.735 214.875 106.735C214.875 106.735 214.875 133.425 210.557 133.817C206.24 134.21 203.1 134.602 202.707 133.032C202.315 131.462 204.67 130.677 203.492 129.892C202.315 129.107 201.922 126.36 203.492 123.22C205.062 120.08 203.492 88.2879 203.492 88.2879L196.035 108.305C196.035 108.305 195.25 124.79 194.465 127.145C193.68 129.5 195.25 133.817 192.503 134.21C189.755 134.602 186.615 132.247 185.045 131.462C183.475 130.677 187.4 130.677 186.615 130.285C185.83 129.892 183.475 128.715 184.26 128.322C185.045 127.93 185.83 109.09 185.83 109.09C185.83 109.09 187.989 68.8595 190.736 67.2895C193.484 65.7195 195.25 68.4237 195.25 68.4237L216.837 72.5882Z" fill="#0C3957"/>
<path d="M209.773 132.247V135.387C209.773 135.387 213.305 143.923 209.773 143.923C206.24 143.923 203.493 144.807 203.493 143.63V133.032L209.773 132.247Z" fill="#0C3957"/>
<path d="M186.615 132.242V135.382C186.615 135.382 183.083 143.917 186.615 143.917C190.148 143.917 192.895 144.802 192.895 143.624V133.027L186.615 132.242Z" fill="#0C3957"/>
<path d="M206.829 23.7228C202.927 23.7228 199.764 20.5597 199.764 16.6579C199.764 12.7561 202.927 9.59302 206.829 9.59302C210.73 9.59302 213.894 12.7561 213.894 16.6579C213.894 20.5597 210.73 23.7228 206.829 23.7228Z" fill="#FFB8B8"/>
<path d="M208.987 29.8066C208.987 29.8066 207.025 35.6941 203.885 34.5166L200.745 33.3391L195.25 71.0185C195.25 71.0185 199.567 77.2984 205.847 73.3735C212.127 69.4486 208.987 29.8066 208.987 29.8066Z" fill="white"/>
<path d="M203.493 28.6293L201.727 24.9006C201.727 24.9006 191.326 29.8068 189.756 31.3768C188.186 32.9467 188.186 35.3017 188.186 35.3017L190.933 45.1141C190.933 45.1141 190.148 66.7013 190.148 67.4863C190.148 68.2712 187.401 72.5887 189.363 71.0187C191.326 69.4487 191.718 67.8788 193.288 70.2337C194.858 72.5887 198.39 75.3361 198.39 75.3361L203.493 28.6293Z" fill="#69306D"/>
<path d="M189.755 56.8885L190.933 67.8784C190.933 67.8784 197.998 74.1583 196.428 74.5508C194.858 74.9433 194.073 73.3733 194.073 73.3733C194.073 73.3733 191.325 76.1208 189.755 74.5508C188.186 72.9808 184.261 58.066 184.261 58.066L189.755 56.8885Z" fill="#FFB8B8"/>
<path d="M198.32 11.9796C198.353 11.4779 198.572 11.0057 198.935 10.653C198.796 10.1548 198.742 9.63739 198.774 9.12172C198.93 7.6066 200.09 7.42936 201.023 6.56434C201.428 6.18904 201.344 5.95715 201.484 5.45914C201.668 4.80633 202.034 4.21761 202.54 3.75992C203.13 3.16923 203.756 2.55689 204.561 2.31724C206.251 1.81364 207.938 3.1112 209.698 3.25227C210.474 3.31443 211.253 3.14809 212.03 3.19707C212.807 3.24605 213.651 3.59144 213.931 4.30847C214.06 4.6395 214.054 5.01063 214.184 5.34141C214.442 5.99883 215.157 6.33868 215.691 6.80676C216.873 7.84184 217.175 9.61281 216.772 11.1206C216.686 11.4332 216.575 11.7387 216.441 12.0344C216.528 12.6841 216.487 13.3446 216.318 13.9785C215.914 15.4863 214.916 16.7704 213.824 17.8967C213.825 17.4264 213.784 16.957 213.699 16.4942C213.682 16.3375 213.626 16.1874 213.536 16.0571C213.325 15.7938 212.928 15.8007 212.602 15.7039C211.926 15.5036 211.52 14.8144 211.345 14.1397C211.171 13.465 211.159 12.7524 210.931 12.0936C210.892 11.939 210.821 11.7941 210.722 11.6683C210.623 11.5426 210.498 11.4387 210.356 11.3633C210.107 11.2889 209.841 11.2865 209.59 11.3564C208.354 11.5838 207.069 11.8506 205.857 11.5229C205.443 11.411 205.041 11.2304 204.612 11.2177C203.639 11.1887 202.848 12.036 202.499 12.9331C202.15 13.8301 202.101 14.815 201.799 15.7285C201.497 16.6421 200.82 17.5489 199.854 17.6669C199.801 17.6791 199.745 17.68 199.692 17.6696C199.638 17.6593 199.588 17.6379 199.543 17.6069C199.486 17.5438 199.449 17.4654 199.436 17.3815L198.558 13.8312C198.373 13.2318 198.293 12.6057 198.32 11.9796V11.9796Z" fill="#0C3957"/>
<path d="M207.025 27.4514C207.025 27.4514 210.22 24.3203 211.37 24.5121C212.52 24.7039 214.09 27.4514 214.09 27.4514C214.09 27.4514 223.51 30.5913 223.117 33.7313C222.725 36.8712 218.408 52.1785 218.408 52.1785C218.408 52.1785 214.875 70.6257 218.015 72.5882C221.155 74.5507 203.1 79.6531 202.708 77.2981C202.315 74.9432 201.53 51.3935 202.708 47.8611C203.885 44.3286 207.025 27.4514 207.025 27.4514Z" fill="#69306D"/>
<path d="M219.585 60.4211L214.09 62.7761C214.09 62.7761 203.493 64.346 203.493 60.0286C203.493 55.7112 214.09 58.0661 214.09 58.0661L217.623 56.9366L219.585 60.4211Z" fill="#FFB8B8"/>
<path d="M193.288 33.7316H188.434C188.434 33.7316 185.045 48.6463 184.653 50.2163C184.26 51.7863 183.475 59.2437 183.868 59.2437C184.26 59.2437 192.11 57.6737 192.11 58.8512L193.288 33.7316Z" fill="#69306D"/>
<path d="M218.407 31.3765L223.117 32.5539L226.65 53.3561C226.65 53.3561 227.827 58.8511 225.865 59.2436C223.902 59.6361 218.407 62.776 218.407 62.776C218.407 62.776 217.622 56.8886 216.052 56.8886L219.977 53.7486L217.622 40.0113L218.407 31.3765Z" fill="#69306D"/>
<path d="M145.665 22.687C149.073 22.687 151.837 19.9236 151.837 16.5148C151.837 13.1059 149.073 10.3425 145.665 10.3425C142.256 10.3425 139.492 13.1059 139.492 16.5148C139.492 19.9236 142.256 22.687 145.665 22.687Z" stroke="#39B54A" stroke-miterlimit="10"/>
<path d="M157.807 5.73843L156.315 4.23829C156.242 4.16351 156.154 4.10396 156.058 4.06306C155.962 4.02217 155.858 4.00074 155.754 4.00002C155.649 3.9993 155.546 4.01929 155.449 4.05885C155.352 4.09841 155.264 4.15676 155.19 4.23051L145.822 13.5202L141.861 9.52775C141.788 9.45386 141.701 9.39504 141.605 9.35465C141.51 9.31426 141.407 9.2931 141.304 9.29238C141.2 9.29165 141.097 9.31137 141.001 9.35042C140.905 9.38946 140.817 9.44706 140.743 9.51993L139.235 11.0123C139.086 11.1609 139.001 11.3625 139 11.5732C138.999 11.7839 139.08 11.9866 139.228 12.1373L145.244 18.2003C145.392 18.3496 145.594 18.4342 145.805 18.4356C146.015 18.4371 146.218 18.3553 146.369 18.2081L157.799 6.86352C157.874 6.79037 157.934 6.70316 157.975 6.60688C158.015 6.51061 158.037 6.4072 158.038 6.3026C158.038 6.19801 158.018 6.0943 157.979 5.99747C157.939 5.90064 157.881 5.8126 157.807 5.73843Z" fill="#3F762C"/>
<path d="M105.63 13.4287H6.28523C2.81399 13.4287 0 16.2427 0 19.7139V19.8994C0 23.3707 2.81399 26.1846 6.28523 26.1846H105.63C109.101 26.1846 111.915 23.3707 111.915 19.8994V19.7139C111.915 16.2427 109.101 13.4287 105.63 13.4287Z" fill="#DEDEDE"/>
<path d="M105.77 35.5459H6.42512C2.95389 35.5459 0.139893 38.3599 0.139893 41.8311V42.0166C0.139893 45.4878 2.95389 48.3018 6.42512 48.3018H105.77C109.241 48.3018 112.055 45.4878 112.055 42.0166V41.8311C112.055 38.3599 109.241 35.5459 105.77 35.5459Z" fill="#DEDEDE"/>
<path d="M105.91 57.663H6.56526C3.09402 57.663 0.280029 60.477 0.280029 63.9482V64.1337C0.280029 67.6049 3.09402 70.4189 6.56526 70.4189H105.91C109.381 70.4189 112.195 67.6049 112.195 64.1337V63.9482C112.195 60.477 109.381 57.663 105.91 57.663Z" fill="#DEDEDE"/>
<path d="M106.049 79.7802H6.70491C3.23367 79.7802 0.419678 82.5941 0.419678 86.0654V86.2509C0.419678 89.7221 3.23367 92.5361 6.70491 92.5361H106.049C109.521 92.5361 112.335 89.7221 112.335 86.2509V86.0654C112.335 82.5941 109.521 79.7802 106.049 79.7802Z" fill="#DEDEDE"/>
<path d="M109.127 10.1368H9.78303C6.3118 10.1368 3.4978 12.9508 3.4978 16.4221V16.6075C3.4978 20.0788 6.3118 22.8928 9.78303 22.8928H109.127C112.599 22.8928 115.413 20.0788 115.413 16.6075V16.4221C115.413 12.9508 112.599 10.1368 109.127 10.1368Z" stroke="#0C3957" stroke-miterlimit="10"/>
<path d="M109.267 32.254H9.92293C6.45169 32.254 3.6377 35.068 3.6377 38.5393V38.7247C3.6377 42.196 6.45169 45.01 9.92293 45.01H109.267C112.739 45.01 115.553 42.196 115.553 38.7247V38.5393C115.553 35.068 112.739 32.254 109.267 32.254Z" stroke="#0C3957" stroke-miterlimit="10"/>
<path d="M109.407 54.3712H10.0626C6.59134 54.3712 3.77734 57.1852 3.77734 60.6564V60.8419C3.77734 64.3132 6.59134 67.1272 10.0626 67.1272H109.407C112.878 67.1272 115.692 64.3132 115.692 60.8419V60.6564C115.692 57.1852 112.878 54.3712 109.407 54.3712Z" stroke="#0C3957" stroke-miterlimit="10"/>
<path d="M109.547 76.4883H10.2022C6.73099 76.4883 3.91699 79.3023 3.91699 82.7735V82.959C3.91699 86.4302 6.73099 89.2442 10.2022 89.2442H109.547C113.018 89.2442 115.832 86.4302 115.832 82.959V82.7735C115.832 79.3023 113.018 76.4883 109.547 76.4883Z" stroke="#0C3957" stroke-miterlimit="10"/>
<path d="M145.665 44.9071C149.073 44.9071 151.837 42.1437 151.837 38.7348C151.837 35.326 149.073 32.5626 145.665 32.5626C142.256 32.5626 139.492 35.326 139.492 38.7348C139.492 42.1437 142.256 44.9071 145.665 44.9071Z" stroke="#39B54A" stroke-miterlimit="10"/>
<path d="M145.665 67.127C149.073 67.127 151.837 64.3636 151.837 60.9548C151.837 57.546 149.073 54.7826 145.665 54.7826C142.256 54.7826 139.492 57.546 139.492 60.9548C139.492 64.3636 142.256 67.127 145.665 67.127Z" stroke="#7A7A7A" stroke-miterlimit="10"/>
<path d="M145.665 89.3471C149.073 89.3471 151.837 86.5837 151.837 83.1749C151.837 79.7661 149.073 77.0027 145.665 77.0027C142.256 77.0027 139.492 79.7661 139.492 83.1749C139.492 86.5837 142.256 89.3471 145.665 89.3471Z" stroke="#39B54A" stroke-miterlimit="10"/>
<path d="M157.807 28.1641L156.315 26.6639C156.242 26.5892 156.154 26.5296 156.058 26.4887C155.962 26.4478 155.858 26.4264 155.754 26.4257C155.649 26.425 155.546 26.445 155.449 26.4845C155.352 26.5241 155.264 26.5824 155.19 26.6562L145.822 35.9459L141.861 31.9534C141.788 31.8795 141.701 31.8207 141.605 31.7803C141.51 31.7399 141.407 31.7188 141.304 31.718C141.2 31.7173 141.097 31.737 141.001 31.7761C140.905 31.8151 140.817 31.8727 140.743 31.9456L139.235 33.4379C139.086 33.5866 139.001 33.7882 139 33.9988C138.999 34.2095 139.08 34.4123 139.228 34.563L145.244 40.6259C145.392 40.7752 145.594 40.8598 145.805 40.8613C146.015 40.8627 146.218 40.781 146.369 40.6337L157.799 29.2892C157.874 29.216 157.934 29.1288 157.975 29.0325C158.015 28.9363 158.037 28.8329 158.038 28.7283C158.038 28.6237 158.018 28.52 157.979 28.4231C157.939 28.3263 157.881 28.2383 157.807 28.1641V28.1641Z" fill="#3F762C"/>
<path d="M157.807 73.0157L156.315 71.5155C156.242 71.4407 156.154 71.3812 156.058 71.3403C155.962 71.2994 155.858 71.278 155.754 71.2772C155.649 71.2765 155.546 71.2965 155.449 71.3361C155.352 71.3756 155.264 71.434 155.19 71.5077L145.822 80.7974L141.861 76.805C141.788 76.7311 141.701 76.6723 141.605 76.6319C141.51 76.5915 141.407 76.5703 141.304 76.5696C141.2 76.5689 141.097 76.5886 141.001 76.6276C140.905 76.6667 140.817 76.7243 140.743 76.7972L139.235 78.2895C139.086 78.4381 139.001 78.6397 139 78.8504C138.999 79.0611 139.08 79.2638 139.228 79.4146L145.244 85.4775C145.392 85.6268 145.594 85.7114 145.805 85.7128C146.015 85.7143 146.218 85.6325 146.369 85.4853L157.799 74.1407C157.874 74.0676 157.934 73.9804 157.974 73.8841C158.015 73.7878 158.037 73.6844 158.038 73.5798C158.038 73.4752 158.018 73.3715 157.979 73.2747C157.939 73.1779 157.881 73.0898 157.807 73.0157Z" fill="#3F762C"/>
</svg>



	);
}

export default AmmoniteFactRisk;
