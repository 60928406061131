import React, { useState } from 'react';
import AmmoniteTrashcan from '../../../Assets/SVG/trashcan';

import AmmoniteCheckbox from '../../../components/Checkbox/Checkbox';

import './child.css';
import styles from '../../../Styles/srbstyles.module.css'

function Child({
  id, 
  childName,
  childAge,
  financiallyDependent,
  handleChildChange
}) {

	return (
		<div className="childCardContainer">
            <div className="childTrash" onClick={() => handleChildChange("deleteChild", id, null)}>
                <AmmoniteTrashcan colour="#7A7A7A" scale={1} />
            </div>
			  <div className="childrenContainer">
                    <form  className="childFields" autocomplete="off">
                      <div>
                        <label className="childLabel">Name</label>
                        <input
                            type="text"
                            placeholder=""
                            value={childName}
                            className="nameInput"
                            onChange={(event) => handleChildChange("name", id, event.target.value)}
                        />
                      </div>
                      <div>
                        <label className="childLabel">Age</label>
                        <input
                            type="number"
                            placeholder=""
                            value={childAge}
                            className="nameInput"
                            onChange={(event) => handleChildChange("age", id, Number(event.target.value))}
                        />
                      </div>
                    </form>
                  <div className={styles.financiallyDependent}>
                    <AmmoniteCheckbox
                        isBold
                        checked={financiallyDependent}
                        checkedHandler={() => handleChildChange("financiallyDependent", id, null)}
                        checkboxText={'Financially dependent?'}
                    />
                  </div>
        </div>
		</div>
	);
}

export default Child;
