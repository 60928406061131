export const AmmonitePensionsData = (
  startValue,
  cpi,
  newFees,
  taxRate,
  pensionGrowthRate,
  yearsToRetirement,
  netAnnualContributions
) => {
  let pensionsData = [startValue];
  let pensionNetGrowthRate = (pensionGrowthRate - cpi - newFees) / 100;
  let pensionContributionTopUp = 1 / (1 - taxRate);
  if (netAnnualContributions === undefined) {
    netAnnualContributions = 0;
  }
  for (let i = 0; i < yearsToRetirement; i++) {
    let pensionsAa =
      startValue +
      (netAnnualContributions * pensionContributionTopUp) /
        pensionNetGrowthRate;
    let pensionsBb = pensionsAa * Math.pow(1 + pensionNetGrowthRate, i + 1);
    let pensionValue =
      pensionsBb -
      (netAnnualContributions * pensionContributionTopUp) /
        pensionNetGrowthRate;
    if (pensionValue > 0) {
      pensionsData.push(Math.round(pensionValue * 100) / 100);
    } else {
      pensionsData.push(0);
    }
  }
  return pensionsData;
};
