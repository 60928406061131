import React from 'react';

function AmmoniteEmployment() {
	return (
		<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M30 27C30 27.8265 29.3265 28.5 28.5 28.5H25.5V13.5H28.5C29.3265 13.5 30 14.1735 30 15V27ZM6 27V15C6 14.1735 6.6735 13.5 7.5 13.5H10.5V28.5H7.5C6.6735 28.5 6 27.8265 6 27V27ZM15 8.25C15 7.836 15.336 7.5 15.75 7.5H20.25C20.664 7.5 21 7.836 21 8.25V10.5H15V8.25ZM13.5 28.5H22.5V13.5H13.5V28.5ZM28.5 10.5H24V8.25C24 6.183 22.317 4.5 20.25 4.5H15.75C13.683 4.5 12 6.183 12 8.25V10.5H7.5C5.019 10.5 3 12.519 3 15V27C3 29.481 5.019 31.5 7.5 31.5H28.5C30.981 31.5 33 29.481 33 27V15C33 12.519 30.981 10.5 28.5 10.5V10.5Z" fill="#850944"/>
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="4" width="30" height="28">
<path fillRule="evenodd" clipRule="evenodd" d="M30 27C30 27.8265 29.3265 28.5 28.5 28.5H25.5V13.5H28.5C29.3265 13.5 30 14.1735 30 15V27ZM6 27V15C6 14.1735 6.6735 13.5 7.5 13.5H10.5V28.5H7.5C6.6735 28.5 6 27.8265 6 27V27ZM15 8.25C15 7.836 15.336 7.5 15.75 7.5H20.25C20.664 7.5 21 7.836 21 8.25V10.5H15V8.25ZM13.5 28.5H22.5V13.5H13.5V28.5ZM28.5 10.5H24V8.25C24 6.183 22.317 4.5 20.25 4.5H15.75C13.683 4.5 12 6.183 12 8.25V10.5H7.5C5.019 10.5 3 12.519 3 15V27C3 29.481 5.019 31.5 7.5 31.5H28.5C30.981 31.5 33 29.481 33 27V15C33 12.519 30.981 10.5 28.5 10.5V10.5Z" fill="white"/>
</mask>
<g mask="url(#mask0)">
</g>
</svg>

	);
}

export default AmmoniteEmployment;
