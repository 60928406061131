import React from 'react';

function AmmoniteConfirmation() {
	return (
<svg width="213" height="186" viewBox="0 0 213 186" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M122.387 111.393L97.7279 118.744L102.246 133.898L126.904 126.547L122.387 111.393Z" fill="url(#paint0_linear)"/>
<path d="M121.966 111.926L98.1426 119.028L102.359 133.172L126.182 126.07L121.966 111.926Z" fill="white"/>
<g opacity="0.6">
<path opacity="0.6" d="M100.179 122.014L103.21 121.11L102.542 118.867L99.5099 119.771L100.179 122.014Z" fill="#0C3957"/>
<path opacity="0.6" d="M103.966 125.635L115.604 122.166L115.387 121.439L103.749 124.908L103.966 125.635Z" fill="#0C3957"/>
<path opacity="0.6" d="M107.589 126.272L116.074 123.743L115.93 123.258L107.444 125.787L107.589 126.272Z" fill="#0C3957"/>
</g>
<path d="M160.815 75.4677L157.792 90.9891L183.049 95.9077L186.072 80.3862L160.815 75.4677Z" fill="url(#paint1_linear)"/>
<path d="M160.652 76.1489L157.848 90.613L182.21 95.3363L185.015 80.8721L160.652 76.1489Z" fill="white"/>
<g opacity="0.6">
<path opacity="0.6" d="M161.442 79.5191L164.548 80.1238L164.995 77.8262L161.89 77.2215L161.442 79.5191Z" fill="#0C3957"/>
<path opacity="0.6" d="M163.118 84.484L175.038 86.8053L175.183 86.0603L163.264 83.7391L163.118 84.484Z" fill="#0C3957"/>
<path opacity="0.6" d="M166.033 86.7289L174.724 88.4215L174.821 87.9249L166.13 86.2323L166.033 86.7289Z" fill="#0C3957"/>
</g>
<path d="M176.523 106.111L162.016 112.403L172.255 136.009L186.762 129.717L176.523 106.111Z" fill="url(#paint2_linear)"/>
<path d="M176.287 106.557L162.747 112.431L172.639 135.237L186.18 129.363L176.287 106.557Z" fill="white"/>
<g opacity="0.6">
<path opacity="0.6" d="M173.53 108.924L174.789 111.827L176.936 110.895L175.677 107.993L173.53 108.924Z" fill="#3BB9C4"/>
<path opacity="0.6" d="M170.385 113.118L175.217 124.258L175.913 123.956L171.081 112.816L170.385 113.118Z" fill="#3BB9C4"/>
<path opacity="0.6" d="M170.183 116.79L173.707 124.913L174.171 124.712L170.647 116.589L170.183 116.79Z" fill="#3BB9C4"/>
</g>
<path d="M106.064 36.8497L81.4051 44.2008L85.9227 59.3548L110.582 52.0037L106.064 36.8497Z" fill="url(#paint3_linear)"/>
<path d="M105.642 37.3852L81.8185 44.4872L86.0351 58.6314L109.858 51.5294L105.642 37.3852Z" fill="white"/>
<g opacity="0.6">
<path opacity="0.6" d="M83.8412 47.477L86.873 46.5732L86.2042 44.33L83.1725 45.2338L83.8412 47.477Z" fill="#F55F44"/>
<path opacity="0.6" d="M87.6312 51.0984L99.2686 47.6292L99.0517 46.9018L87.4143 50.3711L87.6312 51.0984Z" fill="#F55F44"/>
<path opacity="0.6" d="M91.2512 51.7352L99.7367 49.2056L99.5922 48.7207L91.1066 51.2503L91.2512 51.7352Z" fill="#F55F44"/>
</g>
<path d="M144.486 0.927127L141.463 16.4486L166.72 21.3672L169.743 5.84569L144.486 0.927127Z" fill="url(#paint4_linear)"/>
<path d="M144.721 1.37376L141.9 15.861L166.301 20.6129L169.122 6.12565L144.721 1.37376Z" fill="white"/>
<g opacity="0.6">
<path opacity="0.6" d="M145.112 4.98011L148.217 5.58484L148.664 3.28724L145.559 2.68251L145.112 4.98011Z" fill="#0C3957"/>
<path opacity="0.6" d="M146.788 9.9451L158.707 12.2664L158.853 11.5214L146.933 9.20013L146.788 9.9451Z" fill="#0C3957"/>
<path opacity="0.6" d="M149.704 12.19L158.395 13.8826L158.492 13.3859L149.8 11.6933L149.704 12.19Z" fill="#0C3957"/>
</g>
<path d="M160.19 31.5727L145.683 37.8655L155.923 61.4715L170.43 55.1787L160.19 31.5727Z" fill="url(#paint5_linear)"/>
<path d="M159.957 32.0194L146.416 37.8928L156.309 60.6989L169.849 54.8254L159.957 32.0194Z" fill="white"/>
<g opacity="0.6">
<path opacity="0.6" d="M157.201 34.3885L158.46 37.2908L160.607 36.3593L159.348 33.4569L157.201 34.3885Z" fill="#3BB9C4"/>
<path opacity="0.6" d="M154.054 38.5819L158.887 49.7224L159.583 49.4204L154.75 38.2799L154.054 38.5819Z" fill="#3BB9C4"/>
<path opacity="0.6" d="M153.853 42.2566L157.376 50.3799L157.84 50.1785L154.317 42.0552L153.853 42.2566Z" fill="#3BB9C4"/>
</g>
<path d="M36.7867 92.9469L12.1279 100.298L16.6455 115.452L41.3043 108.101L36.7867 92.9469Z" fill="url(#paint6_linear)"/>
<path d="M36.367 93.4823L12.5438 100.584L16.7604 114.729L40.5836 107.627L36.367 93.4823Z" fill="white"/>
<g opacity="0.6">
<path opacity="0.6" d="M14.5782 103.585L17.6099 102.681L16.9412 100.438L13.9095 101.342L14.5782 103.585Z" fill="#0C3957"/>
<path opacity="0.6" d="M18.3681 107.206L30.0055 103.737L29.7887 103.01L18.1513 106.479L18.3681 107.206Z" fill="#0C3957"/>
<path opacity="0.6" d="M21.9906 107.841L30.4762 105.311L30.3316 104.826L21.8461 107.356L21.9906 107.841Z" fill="#0C3957"/>
</g>
<path d="M75.2161 57.0293L72.1934 72.5508L97.4501 77.4694L100.473 61.9479L75.2161 57.0293Z" fill="url(#paint7_linear)"/>
<path d="M75.1807 57.6009L72.3764 72.0651L96.7385 76.7883L99.5428 62.3242L75.1807 57.6009Z" fill="white"/>
<g opacity="0.6">
<path opacity="0.6" d="M75.8397 61.0828L78.945 61.6875L79.3924 59.3899L76.2872 58.7852L75.8397 61.0828Z" fill="#F55F44"/>
<path opacity="0.6" d="M77.5184 66.0503L89.438 68.3716L89.5831 67.6266L77.6635 65.3054L77.5184 66.0503Z" fill="#F55F44"/>
<path opacity="0.6" d="M80.4328 68.2952L89.1242 69.9878L89.2209 69.4911L80.5296 67.7986L80.4328 68.2952Z" fill="#F55F44"/>
</g>
<path d="M90.9215 87.6723L76.4145 93.9651L86.6542 117.571L101.161 111.278L90.9215 87.6723Z" fill="url(#paint8_linear)"/>
<path d="M90.6882 88.119L77.1479 93.9924L87.0406 116.799L100.581 110.925L90.6882 88.119Z" fill="white"/>
<g opacity="0.6">
<path opacity="0.6" d="M87.9297 90.4881L89.1886 93.3904L91.3361 92.4589L90.0771 89.5566L87.9297 90.4881Z" fill="#3BB9C4"/>
<path opacity="0.6" d="M84.7857 94.679L89.6182 105.82L90.3145 105.518L85.482 94.377L84.7857 94.679Z" fill="#3BB9C4"/>
<path opacity="0.6" d="M84.5841 98.3537L88.1078 106.477L88.572 106.276L85.0483 98.1524L84.5841 98.3537Z" fill="#3BB9C4"/>
</g>
<path d="M39.688 48.4348L15.0291 55.7859L19.5467 70.9399L44.2056 63.5888L39.688 48.4348Z" fill="url(#paint9_linear)"/>
<path d="M39.7084 48.731L15.8435 55.9402L20.1237 70.1092L43.9886 62.9L39.7084 48.731Z" fill="white"/>
<g opacity="0.6">
<path opacity="0.6" d="M17.4721 59.0713L20.5039 58.1675L19.8352 55.9243L16.8034 56.8281L17.4721 59.0713Z" fill="#0C3957"/>
<path opacity="0.6" d="M21.2596 62.6927L32.897 59.2234L32.6802 58.496L21.0428 61.9653L21.2596 62.6927Z" fill="#0C3957"/>
<path opacity="0.6" d="M24.8821 63.3295L33.3677 60.7998L33.2231 60.3149L24.7375 62.8446L24.8821 63.3295Z" fill="#0C3957"/>
</g>
<path d="M77.9086 12.6988L74.9041 28.1956L100.121 33.0845L103.125 17.5877L77.9086 12.6988Z" fill="url(#paint10_linear)"/>
<path d="M78.3479 12.9637L75.5267 27.4509L99.9276 32.2028L102.749 17.7156L78.3479 12.9637Z" fill="white"/>
<g opacity="0.6">
<path opacity="0.6" d="M78.7408 16.5702L81.8461 17.1749L82.2935 14.8773L79.1883 14.2726L78.7408 16.5702Z" fill="#0C3957"/>
<path opacity="0.6" d="M80.417 21.5352L92.3366 23.8564L92.4817 23.1115L80.5621 20.7902L80.417 21.5352Z" fill="#0C3957"/>
<path opacity="0.6" d="M83.3302 23.7801L92.0216 25.4727L92.1183 24.976L83.4269 23.2834L83.3302 23.7801Z" fill="#F55F44"/>
</g>
<path d="M93.819 43.1627L79.312 49.4554L89.5517 73.0615L104.059 66.7687L93.819 43.1627Z" fill="url(#paint11_linear)"/>
<path d="M93.5845 43.6093L80.0441 49.4828L89.9368 72.2889L103.477 66.4154L93.5845 43.6093Z" fill="white"/>
<g opacity="0.6">
<path opacity="0.6" d="M90.83 45.9786L92.0889 48.8809L94.2363 47.9494L92.9774 45.047L90.83 45.9786Z" fill="#3BB9C4"/>
<path opacity="0.6" d="M87.6823 50.172L92.5148 61.3125L93.211 61.0105L88.3786 49.8699L87.6823 50.172Z" fill="#3BB9C4"/>
<path opacity="0.6" d="M87.4832 53.8417L91.0069 61.965L91.4711 61.7636L87.9473 53.6403L87.4832 53.8417Z" fill="#3BB9C4"/>
</g>
<path opacity="0.25" d="M15.6953 16.3889C16.4373 16.3889 17.0389 15.7873 17.0389 15.0453C17.0389 14.3033 16.4373 13.7018 15.6953 13.7018C14.9533 13.7018 14.3518 14.3033 14.3518 15.0453C14.3518 15.7873 14.9533 16.3889 15.6953 16.3889Z" fill="#3BB9C4"/>
<path opacity="0.25" d="M155.959 134.067C156.701 134.067 157.303 133.465 157.303 132.723C157.303 131.981 156.701 131.38 155.959 131.38C155.217 131.38 154.616 131.981 154.616 132.723C154.616 133.465 155.217 134.067 155.959 134.067Z" fill="#3BB9C4"/>
<path opacity="0.25" d="M37.2827 127.385C38.0247 127.385 38.6263 126.784 38.6263 126.042C38.6263 125.3 38.0247 124.698 37.2827 124.698C36.5407 124.698 35.9392 125.3 35.9392 126.042C35.9392 126.784 36.5407 127.385 37.2827 127.385Z" fill="#3BB9C4"/>
<path opacity="0.25" d="M168.45 108.685C169.192 108.685 169.793 108.084 169.793 107.342C169.793 106.6 169.192 105.998 168.45 105.998C167.708 105.998 167.106 106.6 167.106 107.342C167.106 108.084 167.708 108.685 168.45 108.685Z" fill="#3BB9C4"/>
<path opacity="0.25" d="M181.092 47.0548C181.834 47.0548 182.435 46.4532 182.435 45.7112C182.435 44.9692 181.834 44.3677 181.092 44.3677C180.35 44.3677 179.748 44.9692 179.748 45.7112C179.748 46.4532 180.35 47.0548 181.092 47.0548Z" fill="#3BB9C4"/>
<path opacity="0.25" d="M81.8993 1.19617H80.7056V0H80.2168V1.19617H79.0231V1.6825H80.2168V2.87622H80.7056V1.6825H81.8993V1.19617Z" fill="#3BB9C4"/>
<path opacity="0.25" d="M35.9316 19.5026H34.7379V18.3088H34.2491V19.5026H33.0554V19.9913H34.2491V21.1851H34.7379V19.9913H35.9316V19.5026Z" fill="#3BB9C4"/>
<path opacity="0.25" d="M17.233 87.7176H16.0393V86.5239H15.5505V87.7176H14.3568V88.2064H15.5505V89.4002H16.0393V88.2064H17.233V87.7176Z" fill="#3BB9C4"/>
<path opacity="0.25" d="M14.5484 39.0715H13.3522V37.8754H12.8659V39.0715H11.6697V39.5579H12.8659V40.754H13.3522V39.5579H14.5484V39.0715Z" fill="#3BB9C4"/>
<path opacity="0.25" d="M2.87622 72.4993H1.6825V71.3031H1.19617V72.4993H0V72.9856H1.19617V74.1793H1.6825V72.9856H2.87622V72.4993Z" fill="#3BB9C4"/>
<path opacity="0.25" d="M213 55.2612H211.806V54.0675H211.318V55.2612H210.124V55.75H211.318V56.9437H211.806V55.75H213V55.2612Z" fill="#3BB9C4"/>
<path opacity="0.25" d="M171.384 70.8033H170.191V69.6072H169.702V70.8033H168.508V71.2897H169.702V72.4858H170.191V71.2897H171.384V70.8033Z" fill="#3BB9C4"/>
<path opacity="0.25" d="M135.849 28.8887H134.652V27.6949H134.166V28.8887H132.97V29.3774H134.166V30.5712H134.652V29.3774H135.849V28.8887Z" fill="#3BB9C4"/>
<path d="M67.2755 97.2025C66.4159 99.9928 67.7054 103.291 70.223 104.76C71.2767 105.374 72.4925 105.686 73.5168 106.352C75.7396 107.799 76.6165 110.574 77.3386 113.126C78.1664 116.061 78.999 119.163 78.2401 122.116C77.7488 123.997 76.6558 125.663 76.0295 127.505C75.5063 129.047 75.3245 130.685 75.4965 132.304C75.5873 133.164 75.8035 134.058 76.3905 134.692C77.2404 135.608 78.6011 135.745 79.8292 135.966C82.4302 136.46 84.8771 137.564 86.9692 139.186C89.0613 140.808 90.739 142.904 91.8646 145.3" stroke="url(#paint12_linear)" strokeWidth="2.5501" stroke-miterlimit="10"/>
<path d="M67.5383 97.2025C66.6786 99.9928 67.9681 103.291 70.4857 104.76C71.5394 105.374 72.7553 105.686 73.7795 106.352C76.0024 107.799 76.8792 110.574 77.6013 113.126C78.4291 116.061 79.2617 119.163 78.5028 122.116C78.0115 123.997 76.9185 125.663 76.2922 127.505C75.7691 129.047 75.5872 130.685 75.7592 132.304C75.8501 133.164 76.0662 134.058 76.6533 134.692C77.5031 135.608 78.8638 135.745 80.0919 135.966C82.693 136.46 85.1399 137.564 87.2319 139.186C89.324 140.808 91.0017 142.904 92.1274 145.3" stroke="#0C3957" strokeWidth="1.5" stroke-miterlimit="10"/>
<path d="M100.076 76.3535C97.9563 79.301 95.7555 82.494 95.5738 86.1219C95.2962 91.7048 99.7493 96.2709 101.756 101.493C102.619 103.793 103.01 106.243 102.904 108.697C102.799 111.151 102.199 113.558 101.142 115.776C100.16 117.787 98.7816 119.784 98.9511 122.012C99.0542 123.373 99.7346 124.608 100.282 125.858C101.627 128.991 102.213 132.397 101.995 135.799C101.776 139.201 100.759 142.504 99.0247 145.439" stroke="url(#paint13_linear)" strokeWidth="2.5501" stroke-miterlimit="10"/>
<path d="M100.339 76.3535C98.219 79.301 96.0183 82.494 95.8365 86.1219C95.5589 91.7048 100.012 96.2709 102.019 101.493C102.882 103.792 103.273 106.242 103.168 108.697C103.063 111.151 102.464 113.558 101.407 115.776C100.425 117.787 99.0468 119.784 99.2162 122.012C99.3194 123.373 99.9998 124.608 100.548 125.858C101.892 128.991 102.478 132.397 102.26 135.799C102.041 139.201 101.024 142.504 99.2899 145.439" stroke="#0C3957" strokeWidth="1.5" stroke-miterlimit="10"/>
<path d="M129.835 102.945L130.859 111.296C130.994 112.407 131.105 113.617 130.515 114.563C129.95 115.46 128.892 115.887 128.059 116.55C125.357 118.724 125.603 122.899 126.526 126.247C127.45 129.595 128.806 133.191 127.509 136.411C126.973 137.74 126.035 138.867 125.32 140.095C124.123 142.228 123.648 144.69 123.967 147.115" stroke="url(#paint14_linear)" strokeWidth="2.5501" stroke-miterlimit="10"/>
<g opacity="0.7">
<path opacity="0.7" d="M156.416 68.5893C156.413 64.6806 155.485 60.8281 153.709 57.3464C151.932 53.8646 149.358 50.8524 146.195 48.5557C143.032 46.259 139.371 44.743 135.51 44.1314C131.65 43.5198 127.699 43.8301 123.982 45.0368C124.187 43.6443 124.292 42.2391 124.298 40.8317C124.338 34.3907 121.867 28.1875 117.41 23.538C112.952 18.8885 106.858 16.1583 100.421 15.9267C93.9843 15.695 87.7103 17.9801 82.9299 22.2972C78.1496 26.6143 75.2391 32.6238 74.8157 39.051C71.0974 37.842 67.1458 37.5299 63.2838 38.1402C59.4218 38.7505 55.759 40.2659 52.5947 42.5626C49.4305 44.8593 46.8545 47.8721 45.0773 51.3548C43.3001 54.8374 42.3721 58.6912 42.3692 62.6011C42.3692 75.0295 51.521 92.7068 63.4557 95.8385L65.3102 96.8996L64.3031 100.353H70.2717L68.9748 96.8947L70.8219 95.8385C80.0425 93.4191 87.6003 82.3171 90.5625 71.6596C92.1827 72.7402 93.9667 73.5522 95.8458 74.0642L97.7002 75.1253L96.6931 78.5787H102.662L101.365 75.1204L103.212 74.0642C104.582 73.6954 105.902 73.1592 107.142 72.4677C108.736 84.4171 117.212 99.0095 127.956 101.827L129.81 102.888L128.803 106.341H134.772L133.475 102.883L135.322 101.827C147.274 98.6951 156.416 81.0178 156.416 68.5893Z" fill="url(#paint15_linear)"/>
</g>
<path d="M71.2129 99.0868H65.4384L66.4479 95.6211H69.9136L71.2129 99.0868Z" fill="#F25F5C"/>
<path d="M92.1499 62.5578C92.1499 75.7943 81.4187 95.1885 68.1822 95.1885C54.9457 95.1885 44.2145 75.7844 44.2145 62.5578C44.2145 56.2011 46.7397 50.1049 51.2345 45.6101C55.7293 41.1152 61.8256 38.5901 68.1822 38.5901C74.5388 38.5901 80.6351 41.1152 85.1299 45.6101C89.6247 50.1049 92.1499 56.2011 92.1499 62.5578Z" fill="#F25F5C"/>
<path d="M69.9132 95.7645H66.4475L64.426 94.6101H71.9347L69.9132 95.7645Z" fill="#0C3957"/>
<path opacity="0.1" d="M59.5191 43.9319C59.5191 43.9319 49.4118 49.4191 47.9749 60.3885L59.5191 43.9319Z" fill="white"/>
<path d="M102.556 78.0161H96.7816L97.7911 74.5504H101.257L102.556 78.0161Z" fill="#39B54A"/>
<path d="M123.493 41.4846C123.493 54.7211 112.762 74.1154 99.5254 74.1154C86.2889 74.1154 75.5528 54.7236 75.5528 41.4846C75.5528 35.128 78.078 29.0317 82.5728 24.5369C87.0676 20.0421 93.1639 17.517 99.5205 17.517C105.877 17.517 111.973 20.0421 116.468 24.5369C120.963 29.0317 123.488 35.128 123.488 41.4846H123.493Z" fill="#39B54A"/>
<path d="M101.258 74.6963H97.792L95.7705 73.5394H103.279L101.258 74.6963Z" fill="#0C3957"/>
<path opacity="0.1" d="M90.8624 22.8612C90.8624 22.8612 80.7551 28.3484 79.3182 39.3178L90.8624 22.8612Z" fill="white"/>
<path d="M130.08 102.945L131.104 111.296C131.239 112.407 131.35 113.618 130.76 114.563C130.195 115.46 129.137 115.887 128.304 116.55C125.602 118.724 125.848 122.9 126.771 126.247C127.695 129.595 129.051 133.191 127.754 136.411C127.219 137.74 126.28 138.867 125.565 140.095C124.368 142.228 123.893 144.691 124.212 147.115" stroke="#0C3957" strokeWidth="1.5" stroke-miterlimit="10"/>
<path d="M133.636 104.879H127.859L128.871 101.416H132.335L133.636 104.879Z" fill="#3BB9C4"/>
<path d="M154.574 68.3505C154.574 81.587 143.843 100.981 130.606 100.981C117.37 100.981 106.639 81.5772 106.639 68.3505C106.639 61.9939 109.164 55.8976 113.659 51.4028C118.153 46.908 124.25 44.3828 130.606 44.3828C136.963 44.3828 143.059 46.908 147.554 51.4028C152.049 55.8976 154.574 61.9939 154.574 68.3505Z" fill="#3BB9C4"/>
<path d="M132.336 101.559H128.873L126.852 100.404H134.358L132.336 101.559Z" fill="#0C3957"/>
<path opacity="0.1" d="M121.941 49.7266C121.941 49.7266 111.833 55.2137 110.396 66.1832L121.941 49.7266Z" fill="white"/>
<path d="M138.817 141.222L72.5333 144.536L74.5701 185.272L140.853 181.958L138.817 141.222Z" fill="url(#paint16_linear)"/>
<path d="M129.39 142.304L81.4169 144.76C77.0044 144.986 73.6104 148.746 73.8362 153.158L74.9617 175.147C75.1875 179.559 78.9477 182.953 83.3602 182.727L131.333 180.272C135.746 180.046 139.14 176.286 138.914 171.873L137.788 149.885C137.562 145.472 133.802 142.079 129.39 142.304Z" fill="#EEEEEE"/>
<path d="M76.6416 153.948L84.7782 153.531L84.47 147.509L76.3334 147.926L76.6416 153.948Z" fill="#0C3957"/>
<path d="M83.9152 165.323L115.159 163.724L115.059 161.772L83.8152 163.371L83.9152 165.323Z" fill="#0C3957"/>
<path d="M92.591 169.121L115.374 167.955L115.308 166.653L92.5243 167.819L92.591 169.121Z" fill="#0C3957"/>
<defs>
<linearGradient id="paint0_linear" x1="114.575" y1="130.222" x2="110.057" y2="115.068" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint1_linear" x1="1294.61" y1="9006.73" x2="1611.27" y2="7380.7" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint2_linear" x1="-9083.69" y1="8085.5" x2="-7563.93" y2="7426.26" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint3_linear" x1="1707.06" y1="4560.74" x2="1416.22" y2="3585.12" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint4_linear" x1="814.334" y1="1361.64" x2="1130.99" y2="-264.39" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint5_linear" x1="-9516.53" y1="3036.28" x2="-7996.77" y2="2377.04" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint6_linear" x1="-5859.56" y1="9156.89" x2="-6150.45" y2="8181.12" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint7_linear" x1="-4115.29" y1="7749.34" x2="-3798.63" y2="6123.31" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint8_linear" x1="-17181.2" y1="8113.97" x2="-15661.4" y2="7454.74" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint9_linear" x1="-5088.68" y1="6070.54" x2="-5379.52" y2="5094.93" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint10_linear" x1="-3572.91" y1="3083.45" x2="-3258.16" y2="1460" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint11_linear" x1="-16200.3" y1="4900.42" x2="-14680.6" y2="4241.19" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint12_linear" x1="10958.9" y1="27384.3" x2="13523.4" y2="27384.3" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint13_linear" x1="4176.15" y1="36354.7" x2="4411.44" y2="36354.7" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint14_linear" x1="4876.07" y1="25841.2" x2="5095.35" y2="25841.2" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint15_linear" x1="65169.8" y1="45827.1" x2="65169.8" y2="12587.6" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
<linearGradient id="paint16_linear" x1="326.024" y1="32186.5" x2="-11.7814" y2="25430.4" gradientUnits="userSpaceOnUse">
<stop stop-color="#808080" stop-opacity="0.25"/>
<stop offset="0.54" stop-color="#808080" stop-opacity="0.12"/>
<stop offset="1" stop-color="#808080" stop-opacity="0.1"/>
</linearGradient>
</defs>
</svg>

	);
}

export default AmmoniteConfirmation;

