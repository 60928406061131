import { AmmonitePensionsData } from './pensionsData';
import { AmmoniteCashData } from './cashData';
import { AmmoniteInvestmentData } from './investmentData';

export const getChartsData = (
	startValue, 
	cpi, 
	fees,
	taxRate,
	cashGrowthRate,
	investedGrowthRate,
	pensionGrowthRate,
	currentYear,
	yearOfBirth,
	netAnnualContributions, 
	retirementAge,
	couple,
	retirementGoalLine
	) => {
	let yearsToRetirement = retirementAge - (currentYear - yearOfBirth)
	if (yearsToRetirement < 10) {
		yearsToRetirement = 10;
	}
	const chartCpi = cpi
	const chartFees = fees
	const data = {
		labels: getLabels(yearsToRetirement),
		datasets: [
			{
				label: 'Pensions',
				fill: true,
				lineTension: 0.1,
				backgroundColor: 'rgba(63, 118, 44, 0.1)',
				borderColor: '#3F762C',
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: 'rgba(75,192,192,1)',
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: 'rgba(75,192,192,1)',
				pointHoverBorderColor: 'rgba(220,220,220,1)',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: AmmonitePensionsData(
					startValue, 
					chartCpi,
					chartFees,
					taxRate,
					pensionGrowthRate,
					yearsToRetirement,
					netAnnualContributions
				)
			},
			{
				label: 'Investment',
				fill: true,
				lineTension: 0.1,
				backgroundColor: 'rgba(36, 123, 160, 0.3)',
				borderColor: '#247BA0',
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: 'rgba(244,39,39,0.4)',
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: 'rgba(244,39,39,0.4)',
				pointHoverBorderColor: 'rgba(244,39,39,0.4)',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: AmmoniteInvestmentData(
					startValue, 
					cpi,
					fees,
					investedGrowthRate,
					yearsToRetirement,
					netAnnualContributions
				)
			},
			{
				label: 'Cash',
				fill: true,
				lineTension: 0.1,
				backgroundColor: 'rgba(105, 48, 109, 0.5)',
				borderColor: '#69306D',
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: 'rgba(52,209,35,0.4)',
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: 'rgba(52,209,35,0.4)',
				pointHoverBorderColor: 'rgba(52,209,35,0.4)',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: AmmoniteCashData( 
					startValue, 
					cpi,
					cashGrowthRate,
					yearsToRetirement,
					netAnnualContributions
				)
			},
			{
				label: '£' + retirementGoalLine[0] + ' Retirement Goal',
				fill: false,
				lineTension: 0,
				backgroundColor: '#FFFFFF',
				borderColor: '#0C3957',
				borderCapStyle: 'butt',
				borderDash: [10],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: 'rgba(52,209,35,0.4)',
				pointBackgroundColor: '#0C3957',
				pointBorderWidth: 2,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: 'rgba(52,209,35,0.4)',
				pointHoverBorderColor: 'rgba(52,209,35,0.4)',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: retirementGoalLine
			}
		]
	};
	return data;
};

const getLabels = (yearsToRetirement) => {
	let labels = [];
	for (let i = 0; i < yearsToRetirement + 1; i++) {
		labels.push(i);
	}
	return labels;
};
