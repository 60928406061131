import React, { useReducer, useState, useEffect, useRef } from "react";
import {
  suitabilityReportReducer,
  REQUEST_STATUS,
} from "../reducers/suitabilityReportReducer";
import { AmmoniteInitialiseSuitabilityContext } from "../data/initialiseSuitabilityContext";
import { SBR_INITIAL_CONTEXT } from "../data/initialSBContext";
import { initialiseSBReport } from "../reducers/suitabilityReportActions";

function usePreviousSession(session, index) {
  const refSession = useRef();
  const refIndex = useRef();
  useEffect(() => {
    refSession.current = session;
    refIndex.current = index;
  });
  return [refSession.current, refIndex.current];
}

const useRequest = () => {
  const currentSession = localStorage.getItem("currentSession");
  const currentIndex = localStorage.getItem("currentIndex");
  const previousSession = usePreviousSession(currentSession, currentIndex);
  const [{ state, status, error }, dispatch] = useReducer(
    suitabilityReportReducer,
    {
      state: SBR_INITIAL_CONTEXT,
      status: REQUEST_STATUS.LOADING,
      error: null,
    }
  );

  const fetchData = async () => {
    if (currentSession) {
      const sbrcontext = await AmmoniteInitialiseSuitabilityContext(
        currentSession
      );
      if (
        sbrcontext !== undefined &&
        ((currentSession !== null && currentSession !== previousSession[0]) ||
          (currentSession === previousSession[0] &&
            currentIndex !== previousSession[1]))
      ) {
        dispatch({ type: initialiseSBReport, payload: { report: sbrcontext } });
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentSession, currentIndex]);

  const propsLocal = {
    state,
    status,
    error,
    dispatch,
  };

  return propsLocal;
};

export default useRequest;
