// Core React dependencies
import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';

import SuitabilityReportNavigation from './../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation';

// Component imports
import AmmoniteMenuCircleEmpty from '../../../Assets/SVG/menuCircleEmpty';
import AmmoniteMenuCircleFilledTicked from '../../../Assets/SVG/menuCircleFilledTicked';
import firebase from '../../../Utils/firebase';
import { AmmoniteInitialiseSuitabilityContext } from '../../../Utils/data/initialiseSuitabilityContext'
import { saveContextToFirebase } from '../../../Utils/data/saveSuitabilityContext'

import SuitabilityReportContext from '../../../Utils/contexts/suitabilityReportContext';
import { 
    initialiseSBReport,
    setNames,
    setAttitudeToRisk,
    setCapacityForLoss,
    setRiskComments
} from '../../../Utils/reducers/suitabilityReportActions';


// Styling
import './attitudeToRisk.css';
import styles from '../../../Styles/srbstyles.module.css'


function AttitudeToRisk() {
    const { push } = useHistory();
    const { state, status, error, dispatch } = useContext(SuitabilityReportContext);
    
    const exitHandler = () => {
        push('/suitabilitydashboard');
    }

    const saveHandler = async () => {
        await saveContextToFirebase(state).then(() => console.log("SAVED"))
    }

    const filled = (
        <div className="circleSVG">
            < AmmoniteMenuCircleFilledTicked />
        </div>
    );

    const empty = (
        <div className="circleSVG">
            <AmmoniteMenuCircleEmpty colour="#3BB9C4" width="36px" height="36px" cx="18px" cy="18px" r="14px" />			
        </div>
    );


    useEffect(() => {
   
    }, [])


	return <div className={styles.suitabilityPanel}>
        <div className={styles.pageTitleText}>Attitude to Risk</div>
        <div className={styles.pageInfoText}>Provide additional soft facts below about the client’s Attitude to Risk. Use the client’s own words.</div>
        <div className={styles.softFactsPanel}>
            <div className="clientAttitudeToRiskTitle">
                Client Attitude to Risk
            </div>
            {!status !== "success" && <div className="clientAttitudeToRiskButtons">
                <div className={styles.multipleChoiceValues}>
                        <div className={[styles.emptyCardButtonHandlerContainerSR, "riskContainer"].join(" ")}>
                            <div>
                                {state.clientAttitudeToRisk  !== 0 ? <div className={styles.buttonHandlerContainer} onClick={() => dispatch({ type: setAttitudeToRisk, payload: 0})}>
                                    {empty}			
                                </div> : <div className={styles.buttonHandlerContainer}>{filled}</div> }
                            </div>
                            <label className={styles.multipleChoiceButtonLabel}>{`Low`}</label>
                        </div>
                        <div className={[styles.emptyCardButtonHandlerContainerSR, "riskContainer"].join(" ")}>
                            <div>
                                {state.clientAttitudeToRisk  !== 1 ? <div className={styles.buttonHandlerContainer} onClick={() => dispatch({ type: setAttitudeToRisk, payload: 1})}>
                                {empty}				
                                </div> :
                                <div className={styles.buttonHandlerContainer}>{filled}</div> }
                            </div>
                            <label className={styles.multipleChoiceButtonLabel}>{`Low to Medium`}</label>
                        </div>
                        <div className={[styles.emptyCardButtonHandlerContainerSR, "riskContainer"].join(" ")}>
                            <div>
                                {state.clientAttitudeToRisk  !== 2 ? <div className={styles.buttonHandlerContainer} onClick={() => dispatch({ type: setAttitudeToRisk, payload: 2})}>
                                {empty}				
                                </div> :
                                <div className={styles.buttonHandlerContainer}>{filled}</div> }
                            </div>
                            <label className={styles.multipleChoiceButtonLabel}>{`Medium`}</label>
                        </div>
                        <div className={[styles.emptyCardButtonHandlerContainerSR, "riskContainer"].join(" ")}>
                            <div>
                                {state.clientAttitudeToRisk  !== 3 ? <div className={styles.buttonHandlerContainer} onClick={() => dispatch({ type: setAttitudeToRisk, payload: 3})}>
                                {empty}				
                                </div> : 
                                <div className={styles.buttonHandlerContainer}>{filled}</div> }
                            </div>
                            <label className={styles.multipleChoiceButtonLabel}>{`Medium to High`}</label>
                        </div>
                        <div className={[styles.emptyCardButtonHandlerContainerSR, "riskContainer"].join(" ")}>
                            <div>
                                {state.clientAttitudeToRisk  !== 4 ? <div className={styles.buttonHandlerContainer} onClick={() => dispatch({ type: setAttitudeToRisk, payload: 4})}>
                                {empty}				
                                </div> : 
                                <div className={styles.buttonHandlerContainer}>{filled}</div> }
                            </div>
                            <label className={styles.multipleChoiceButtonLabel}>{`High`}</label>
                        </div>
                    </div>
                </div>}
            <div className="clientAttitudeToRiskTitle">
                What was the client’s comment about their attitude to risk? 
            </div>
            <div>
                <textarea
                    type="text"
                    placeholder=""
                    value={state.clientAttitudeToRiskComment}
                    className={[styles.whyInput, "riskInput"].join(" ")}
                    onChange={(event) => dispatch({ type: setRiskComments, payload: {field: "clientAttitudeToRiskComment", value: event.target.value}})}
                />
            </div>
            <div className="clientAttitudeToRiskTitle">
                Client Capacity for Loss: 
            </div>
            {!status !== "success" && <div className="clientAttitudeToRiskButtons">
               
                <div className={styles.multipleChoiceValues}>
                <div className={[styles.emptyCardButtonHandlerContainerSR, "riskContainer"].join(" ")}>
                    <div>
                        {state.clientCapacityForLoss  !== 0 ? <div className={styles.buttonHandlerContainer} onClick={() => dispatch({ type: setCapacityForLoss, payload: 0})}>
                            {empty}			
                        </div> : <div className={styles.buttonHandlerContainer}>{filled}</div> }
                    </div>
                    <label className={styles.multipleChoiceButtonLabel}>{`Low`}</label>
                </div>
                <div className={[styles.emptyCardButtonHandlerContainerSR, "riskContainer"].join(" ")}>
                    <div>
                        {state.clientCapacityForLoss  !== 1 ? <div className={styles.buttonHandlerContainer} onClick={() => dispatch({ type: setCapacityForLoss, payload: 1})}>
                            {empty}			
                        </div> : <div className={styles.buttonHandlerContainer}>{filled}</div> }
                    </div>
                    <label className={styles.multipleChoiceButtonLabel}>{`Medium`}</label>
                </div>
                <div className={[styles.emptyCardButtonHandlerContainerSR, "riskContainer"].join(" ")}>
                    <div>
                        {state.clientCapacityForLoss  !== 2 ? <div className={styles.buttonHandlerContainer} onClick={() => dispatch({ type: setCapacityForLoss, payload: 2})}>
                            {empty}			
                        </div> : <div className={styles.buttonHandlerContainer}>{filled}</div> }
                    </div>
                    <label className={styles.multipleChoiceButtonLabel}>{`High`}</label>
                </div>
                </div>
            </div>}
            <div className="clientAttitudeToRiskTitle">
                What was the client’s comment about their capacity for loss? 
            </div>
            <div>
                <textarea
                    type="text"
                    placeholder=""
                    value={state.clientCapacityForLossComment}
                    className={[styles.whyInput, "riskInput"].join(" ")}
                    onChange={(event) => dispatch({ type: setRiskComments, payload: {field: "clientCapacityForLossComment", value: event.target.value}})}
                />
            </div>
            <div className="clientAttitudeToRiskTitle">
                Why is this Capacity for Loss rating appropriate?
            </div>
            <div>
                <textarea
                    type="text"
                    placeholder=""
                    value={state.whyRatingAppropriate}
                    className={[styles.whyInput, "riskInput"].join(" ")}
                    onChange={(event) => dispatch({ type: setRiskComments, payload: {field: "whyRatingAppropriate", value: event.target.value}})}
                />
            </div>
        </div> 

        <div className={styles.menuBar}>
            <div className={styles.menuChips}>
                <SuitabilityReportNavigation
                    showSave
                    handleExit={exitHandler}
                    handleSave={saveHandler}
                />
            </div>
        </div>
    </div>;
}

export default AttitudeToRisk;

