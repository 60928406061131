//React
import React from "react";

//React router dom
import AmmoniteCardMargin from "../../../Assets/SVG/goalCardMargin";

//external libraries

//components
import "./reportGoalCard.css";

//assets

function AmmoniteReporGoalCard({
  inflation,
  goalType,
  goalName,
  amountRequiredToday,
  goalAmount,
  customer1DoB,
  goalTimeHorizon,
  deleteGoal,
  handleDelete,
  realIndex,
}) {
  const colourTypes = {
    "Retirement pot": "#BF802F",
    Property: " #247BA0",
    Family: " #3F762C",
    Education: " #69306D",
    "Long Term Investment": " #373986",
    "Care Fees": "#0C3957",
    Other: " #F25F5C",
  };

  const getLineColour = () => {
    switch (goalType) {
      case "Retirement pot":
        return "#F7AB4A";
      case "Education":
        return "#69306D";
      case "Property":
        return "#247BA0";
      case "Family":
        return "#3F762C";
      case "Long Term Investment":
        return "#373986";
      case "Care Fees":
        return "#0C3957";
      case "Other":
        return "#F25F5C";
      default:
        return "#69306D";
    }
  };

  const yearsToRetirement = (timeHorizon) => {
    let _yearsToRetirement =
      timeHorizon -
      (Math.abs(new Date() - customer1DoB.toDate()) / 31536000000).toFixed();
    if (_yearsToRetirement < 0) {
      _yearsToRetirement = 0;
    }
    return _yearsToRetirement;
  };

  return (
    <div className="reportGoalCardContainer">
      <div>
        <AmmoniteCardMargin colour={colourTypes[goalType]} height="311px" />
      </div>
      <div className="reportGoalCardBody">
        <div className="reportGoalCardHeader">
          <div className="reportGoalNameContainer">{goalName}</div>
          <div className="reportGoalTypeContainer">{goalType}</div>
          <div className="reportGoalDivider">
            <svg
              width="218"
              height="2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="2"
                y1="2"
                x2="337"
                y2="2"
                stroke={getLineColour()}
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

        <div className="reportGoalCardMiddle">
          <div className="reportGoalMiddleContainer">
            <div className="reportGoalRequiredTodayLabel">In Today's money</div>
            <div className="reportGoalRequiredTodayContainer">
              £{amountRequiredToday}
            </div>
          </div>
        </div>
        <div className="reportGoalCardMiddle">
          <div className="reportGoalMiddleContainer">
            <div className="reportGoalRequiredTodayLabel">
              In Tomorrow's money*
            </div>
            <div className="reportGoalRequiredTodayContainer">
              £{goalAmount}
            </div>
          </div>
        </div>
        <div className="reportGoalCardEnd">
          <div className="reportGoalCardEndLeft">
            <div className="reportGoalRequiredTodayLabel">Years to Goal</div>
            {goalType === "Retirement pot" ? (
              <div className="reportGoalTypeContainer">
                {yearsToRetirement(goalTimeHorizon)} years
              </div>
            ) : (
              <div className="reportGoalTypeContainer">
                {goalTimeHorizon} years
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AmmoniteReporGoalCard;
