//React
import React, {useRef, useEffect} from 'react';

import AmmoniteNextStep from '../../../components/reportComponents/nextStep/nextStep'

import AmmoniteCallback from '../../../Assets/SVG/Illustrations/nextStepIllustrations/callBack/callBack'
import AmmoniteFinancialPlan from '../../../Assets/SVG/Illustrations/nextStepIllustrations/financialPlan/financialPlan'
import AmmonitePortal from '../../../Assets/SVG/Illustrations/nextStepIllustrations/portal/portal'
import AmmoniteFactRisk from '../../../Assets/SVG/Illustrations/nextStepIllustrations/factRisk/factRisk'
import AmmoniteLetters from '../../../Assets/SVG/Illustrations/nextStepIllustrations/lettersOfAuthority/lettersOfAuthority'
import AmmoniteExistingPensions from '../../../Assets/SVG/Illustrations/nextStepIllustrations/existingPensions/existingPensions'
import AmmoniteAssessGoals from '../../../Assets/SVG/Illustrations/nextStepIllustrations/assessGoals/assessGoals'
import AmmoniteImplementation from '../../../Assets/SVG/Illustrations/nextStepIllustrations/implementation/implementation'
import AmmoniteAnnualReviews from '../../../Assets/SVG/Illustrations/nextStepIllustrations/annualReviews/annualReviews'

//external libraries

//components
import './reportCallbackCard.css';

//assets


function AmmoniteCallbackCard() {
	const callbackRef = useRef()

	const financialPlanText = <div>
		<p>Your financial plan will include: </p>
		<div className="financialPlanPoint">
                <div className="bulletPoint">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5" cy="5" r="4" transform="rotate(-180 5 5)" fill="white" stroke="#BF802F" strokeWidth="2"/>
                    </svg>
                </div>
                Our recommendations for your existing pensions and investments</div>
		<div className="financialPlanPoint">
                <div className="bulletPoint">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5" cy="5" r="4" transform="rotate(-180 5 5)" fill="white" stroke="#BF802F" strokeWidth="2"/>
                    </svg>
                </div>
                A financial plan to help you reach your goals, like how much to contribute and where to invest</div>
		<div className="financialPlanPoint">
                <div className="bulletPoint">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="5" cy="5" r="4" transform="rotate(-180 5 5)" fill="white" stroke="#BF802F" strokeWidth="2"/>
                    </svg>
                </div>
                Suggestions for budgeting, mortgage, protection and Will</div>
	</div>

	useEffect(() => {
		if (callbackRef.current) {

		}

	}, [callbackRef])

	return (
		<div ref={callbackRef} className="callbackCardContainer">
			<div className="innerCallbackCardContainer">
			<AmmoniteNextStep 
				nextStepTitle="Callback with an advisor" 
				nextStepText="Arrange a callback with us using the link provided in our email." 
				nextStepIllustration={<AmmoniteCallback/>}
				textLeft
				line
				lineLength="210"/>
			<AmmoniteNextStep 
				nextStepTitle="Choose Ammonite Wealth to create your personal financial plan for just £100 " 
				// nextStepText="some text!" 
				nextStepIllustration={<AmmoniteFinancialPlan/>}
				line
				lineLength="215"
				/>
			<AmmoniteNextStep 
				nextStepTitle="We’ll send you a link to open your own Ammonite Wealth portal " 
				nextStepText="By having a portal, we can store and share information with you securely." 
				textLeft
				line
				lineLength="223"
				nextStepIllustration={<AmmonitePortal/>}/>
			<AmmoniteNextStep 
				nextStepTitle="Fact find and attitude to risk " 
				nextStepText="We’ll ask you to complete a ‘Fact Find’ and ‘Attitude to Risk’ questionnaire, so that we have all the information we need. It should take about 30 minutes to complete." 
				line
				lineLength="230"
				nextStepIllustration={<AmmoniteFactRisk/>}/>
			<AmmoniteNextStep 
				nextStepTitle="Letters of authority" 
				nextStepText="If you have existing pensions or investments that you would like advice on, we’ll ask you to sign letters that give us authority to get information from your current providers.  We charge £200 per pension/investment policy for our research and can usually take this fee directly from the policy (so it doesn’t have to come from your bank account). " 
				textLeft
				line
				lineLength="335"
				nextStepIllustration={<AmmoniteLetters/>}/>
			<AmmoniteNextStep 
				nextStepTitle="Ammonite will gather information from your existing pension and investment providers " 
				nextStepText="It often takes a few weeks to get all the information back from the providers." 
				line
				lineLength="280"
				nextStepIllustration={<AmmoniteExistingPensions/>}/>
			<AmmoniteNextStep 
				nextStepTitle="Together we’ll review and assess your goals" 
				nextStepText="We may call you to discuss your goals, your capacity to make contributions, your circumstances and your attitude to risk."
				textLeft
				line
				lineLength="220"
				nextStepIllustration={<AmmoniteAssessGoals/>}/>
			<AmmoniteNextStep 
				nextStepTitle="Financial Plan" 
				nextStepText={financialPlanText}
				line
				lineLength="380"
				nextStepIllustration={<AmmoniteFinancialPlan/>}/>
			<AmmoniteNextStep 
				nextStepTitle="Implementation" 
				nextStepText="We’ll implement our recommendations for you, with your say so (without you having to fill out lots of forms!)." 
				textLeft
				line
				lineLength="210"
				nextStepIllustration={<AmmoniteImplementation/>}/>
			<AmmoniteNextStep 
				nextStepTitle="Annual reviews" 
				nextStepText="It’s important to review your goals and situation annually with a qualified adviser.  This will help you keep on track to achieving your goals. " 
				nextStepIllustration={<AmmoniteAnnualReviews/>}/>
			</div>
		</div>
	);
}

export default AmmoniteCallbackCard;
