//React
import React, { useState, useContext } from "react";

import "./investmentAfterRecommendations.css";

function AmmoniteInvestmentAfterRecommendations({
  owner,
  wrapper,
  provider,
  investments,
  totalInitialAmount,
  monthlyContributions,
  goalsAttributed,
}) {
  return (
    <div className="investmentAfterRecommendationsContainer">
      {owner && (
        <div className="investmentField investmentAfterRecommendationsField">
          <div className="investmentTitle">Owner:</div>
          <div className="investmentItem investmentAfterRecommendationsItem">
            {owner}
          </div>
        </div>
      )}
      {wrapper && (
        <div className="investmentField investmentAfterRecommendationsField">
          <div className="investmentTitle">Wrapper:</div>
          <div className="investmentItem investmentAfterRecommendationsItem">
            {wrapper}
          </div>
        </div>
      )}
      {provider && (
        <div className="investmentField investmentAfterRecommendationsField">
          <div className="investmentTitle">Provider:</div>
          <div className="investmentItem investmentAfterRecommendationsItem">
            {provider}
          </div>
        </div>
      )}
      {investments && (
        <div className="investmentField">
          <div className="investmentTitle">Investment(s):</div>
          <div className="investmentItem investmentAfterRecommendationsItem">
            {investments}
          </div>
        </div>
      )}
      {
        <div className="investmentField investmentAfterRecommendationsField">
          <div className="investmentTitle">Total Initial Amount:</div>
          <div className="investmentItem investmentAfterRecommendationsItem">
            {totalInitialAmount ? "£" + totalInitialAmount : "-"}
          </div>
        </div>
      }
      {monthlyContributions !== 0 ? (
        <div className="investmentField investmentAfterRecommendationsField">
          <div className="investmentTitle">Monthly contributions:</div>
          <div className="investmentItem investmentAfterRecommendationsItem">
            £{monthlyContributions}
          </div>
        </div>
      ) : (
        <div className="investmentField investmentAfterRecommendationsField">
          <div className="investmentTitle">Monthly contributions:</div>
          <div className="investmentItem investmentAfterRecommendationsItem">
            {" "}
            -{" "}
          </div>
        </div>
      )}
      {goalsAttributed && (
        <div className="investmentField investmentAfterRecommendationsField">
          <div className="investmentTitle">Goals attributed:</div>
          <div className="investmentItem investmentAfterRecommendationsItem">
            {goalsAttributed ? goalsAttributed : "-"}
          </div>
        </div>
      )}
    </div>
  );
}

export default AmmoniteInvestmentAfterRecommendations;
