// Core React dependencies
import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';

import SuitabilityReportNavigation from './../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation';
import InvestmentCard from '../../../containers/InvestmentCard/investmentCard';
import Loader from 'react-loader-spinner';
import AmmoniteArrowButton from '../../../Assets/SVG/arrow';
import firebase from '../../../Utils/firebase';
import { AmmoniteInitialiseSuitabilityContext } from '../../../Utils/data/initialiseSuitabilityContext'
import { saveContextToFirebase } from '../../../Utils/data/saveSuitabilityContext'

import AmmonitePlusButton from '../../../Assets/SVG/plusButton';
import SuitabilityReportContext from '../../../Utils/contexts/suitabilityReportContext';
import { 
    initialiseSBReport,
    setNames,
    addInvestment,
    deleteInvestment
} from '../../../Utils/reducers/suitabilityReportActions';

// Styling
import './existingInvestmentDetails.css';
import styles from '../../../Styles/srbstyles.module.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


function ExistingInvestmentDetails() {
    const { push } = useHistory()
    const { state, status, error, dispatch } = useContext(SuitabilityReportContext);
    const [ currentInvestmentIndex, setCurrentInvestmentIndex ] = useState(0);

    const exitHandler = () => {
        push('/suitabilitydashboard');
    }

    const forwardInvestment = (event) => {
        event.preventDefault();
        let _newInvestmentIndex = currentInvestmentIndex + 1;
        if (_newInvestmentIndex < state.investments.length) {
            setCurrentInvestmentIndex(_newInvestmentIndex)
        }
    }

    const backInvestment = (event) => {
        event.preventDefault();
        let _newInvestmentIndex = currentInvestmentIndex - 1;
        if (_newInvestmentIndex >= 0) {
            setCurrentInvestmentIndex(_newInvestmentIndex)
        }
    }

    const handleDeleteInvestment = (event) => {
        event.preventDefault();
        dispatch({ type: deleteInvestment, payload: {index: currentInvestmentIndex} })
        saveHandler()
        backInvestment(event)
    }

    const handleAddInvestment = (event) => {
        event.preventDefault();
        dispatch({ type: addInvestment});
    }

    const saveHandler = async () => {
        await saveContextToFirebase(state).then(() => console.log("SAVED"))
    }

    useEffect(() => {

    }, [])


	return <div className={styles.suitabilityPanel}>
        {currentInvestmentIndex !== undefined && state.investments.length > 0 && <div className={[styles.pensionOfPensions, "investmentOf"].join(" ")}>Investment {currentInvestmentIndex + 1} of {state.investments.length}</div>}
        <div className={styles.pageTitleText}>Existing Investment Information</div>
            {state.investments.length > 0 && <div className={styles.prevPension} onClick={(event) => backInvestment(event)}>
                <AmmoniteArrowButton disabled={currentInvestmentIndex === 0}/>
            </div>}
            {!status !== "success" && currentInvestmentIndex !== undefined  && state.investments.length > 0  ? <div className={styles.currentPension}>
                <InvestmentCard
                    investmentIndex={currentInvestmentIndex}
                    investment={state.investments[currentInvestmentIndex]}
                    handleAddInvestment={(event) => handleAddInvestment(event)}
                    handleDeleteInvestment={handleDeleteInvestment}
                    couple={state.customerInfo.couple}
                    customer1={state.customerInfo.customers.customer1.customerFirstName}
                    customer2={state.customerInfo.customers.customer2.customerFirstName}
                />
            </div> : ''}
            {status !== "success" && <div className="loaderAnimation"><Loader/></div>}
            {state.investments.length > 0 && <div className={styles.nextPension} onClick={(event) => forwardInvestment(event)}>
                <AmmoniteArrowButton disabled={currentInvestmentIndex === state.investments.length - 1}/>
            </div>}
        

        <div className={styles.menuBar}>
            <div className={styles.addPension}>
                <div onClick={(event) => handleAddInvestment(event)}>
                    <AmmonitePlusButton/>
                </div>
                <div className={styles.addPensionText}>Add Investment</div>
            </div>
            <div className={styles.menuChips}>
                <SuitabilityReportNavigation
                    showSave
                    handleExit={exitHandler}
                    handleSave={saveHandler}
                />
            </div>
        </div>
    </div>;
}

export default ExistingInvestmentDetails;

