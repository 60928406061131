import React from 'react';

function OtherAllocationButton({ colour }) {
	return (
<svg width="70" height="30" viewBox="0 0 70 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<rect width="70" height="30" rx="15" fill="#F25F5C"/>
<path d="M43.75 11.25C42.9763 11.2522 42.2223 11.4936 41.5912 11.9412C40.9601 12.3888 40.4829 13.0206 40.225 13.75H23.75C23.4185 13.75 23.1005 13.8817 22.8661 14.1161C22.6317 14.3506 22.5 14.6685 22.5 15C22.5 15.3315 22.6317 15.6495 22.8661 15.8839C23.1005 16.1183 23.4185 16.25 23.75 16.25H40.225C40.4543 16.8986 40.8576 17.4716 41.3908 17.9064C41.924 18.3411 42.5665 18.6208 43.248 18.7148C43.9294 18.8088 44.6237 18.7136 45.2546 18.4395C45.8856 18.1654 46.429 17.723 46.8254 17.1608C47.2218 16.5985 47.4559 15.938 47.5021 15.2516C47.5482 14.5652 47.4047 13.8794 47.0872 13.2691C46.7696 12.6588 46.2903 12.1477 45.7017 11.7915C45.1132 11.4354 44.4379 11.2481 43.75 11.25V11.25ZM43.75 16.25C43.5028 16.25 43.2611 16.1767 43.0555 16.0394C42.85 15.902 42.6898 15.7068 42.5952 15.4784C42.5005 15.25 42.4758 14.9986 42.524 14.7562C42.5722 14.5137 42.6913 14.2909 42.8661 14.1161C43.0409 13.9413 43.2637 13.8223 43.5061 13.774C43.7486 13.7258 43.9999 13.7506 44.2284 13.8452C44.4568 13.9398 44.652 14.1 44.7893 14.3056C44.9267 14.5111 45 14.7528 45 15C45 15.3315 44.8683 15.6495 44.6339 15.8839C44.3995 16.1183 44.0815 16.25 43.75 16.25Z" fill="white"/>
<path d="M23.75 8.74989H25.225C25.4878 9.47383 25.967 10.0993 26.5977 10.5413C27.2284 10.9834 27.9799 11.2205 28.75 11.2205C29.5201 11.2205 30.2716 10.9834 30.9023 10.5413C31.533 10.0993 32.0122 9.47383 32.275 8.74989H46.25C46.5815 8.74989 46.8995 8.6182 47.1339 8.38378C47.3683 8.14936 47.5 7.83141 47.5 7.49989C47.5 7.16837 47.3683 6.85043 47.1339 6.61601C46.8995 6.38159 46.5815 6.24989 46.25 6.24989H32.275C32.0122 5.52595 31.533 4.90047 30.9023 4.45844C30.2716 4.01642 29.5201 3.7793 28.75 3.7793C27.9799 3.7793 27.2284 4.01642 26.5977 4.45844C25.967 4.90047 25.4878 5.52595 25.225 6.24989H23.75C23.4185 6.24989 23.1005 6.38159 22.8661 6.61601C22.6317 6.85043 22.5 7.16837 22.5 7.49989C22.5 7.83141 22.6317 8.14936 22.8661 8.38378C23.1005 8.6182 23.4185 8.74989 23.75 8.74989V8.74989ZM28.75 6.24989C28.9972 6.24989 29.2389 6.3232 29.4445 6.46056C29.65 6.59791 29.8102 6.79313 29.9048 7.02154C29.9995 7.24995 30.0242 7.50128 29.976 7.74376C29.9278 7.98623 29.8087 8.20896 29.6339 8.38378C29.4591 8.55859 29.2363 8.67764 28.9939 8.72587C28.7514 8.77411 28.5001 8.74935 28.2716 8.65474C28.0432 8.56013 27.848 8.39992 27.7107 8.19436C27.5733 7.98879 27.5 7.74712 27.5 7.49989C27.5 7.16837 27.6317 6.85043 27.8661 6.61601C28.1005 6.38159 28.4185 6.24989 28.75 6.24989V6.24989Z" fill="white"/>
<path d="M46.25 21.2499H37.275C37.0122 20.526 36.533 19.9005 35.9023 19.4584C35.2716 19.0164 34.5201 18.7793 33.75 18.7793C32.9799 18.7793 32.2284 19.0164 31.5977 19.4584C30.967 19.9005 30.4878 20.526 30.225 21.2499H23.75C23.4185 21.2499 23.1005 21.3816 22.8661 21.616C22.6317 21.8504 22.5 22.1684 22.5 22.4999C22.5 22.8314 22.6317 23.1494 22.8661 23.3838C23.1005 23.6182 23.4185 23.7499 23.75 23.7499H30.225C30.4878 24.4738 30.967 25.0993 31.5977 25.5413C32.2284 25.9834 32.9799 26.2205 33.75 26.2205C34.5201 26.2205 35.2716 25.9834 35.9023 25.5413C36.533 25.0993 37.0122 24.4738 37.275 23.7499H46.25C46.5815 23.7499 46.8995 23.6182 47.1339 23.3838C47.3683 23.1494 47.5 22.8314 47.5 22.4999C47.5 22.1684 47.3683 21.8504 47.1339 21.616C46.8995 21.3816 46.5815 21.2499 46.25 21.2499ZM33.75 23.7499C33.5028 23.7499 33.2611 23.6766 33.0555 23.5392C32.85 23.4019 32.6898 23.2067 32.5952 22.9782C32.5005 22.7498 32.4758 22.4985 32.524 22.256C32.5722 22.0136 32.6913 21.7908 32.8661 21.616C33.0409 21.4412 33.2637 21.3221 33.5061 21.2739C33.7486 21.2257 33.9999 21.2504 34.2284 21.345C34.4568 21.4397 34.652 21.5999 34.7893 21.8054C34.9267 22.011 35 22.2527 35 22.4999C35 22.8314 34.8683 23.1494 34.6339 23.3838C34.3995 23.6182 34.0815 23.7499 33.75 23.7499Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="70" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>

	);
}

export default OtherAllocationButton;
