// Core React dependencies
import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
// import {Prompt} from "react-router-dom"

import SuitabilityReportNavigation from './../../../containers/SuitabilityReport/SuitabilityReportNavigation/suitabilityReportNavigation'
import { saveContextToFirebase } from '../../../Utils/data/saveSuitabilityContext'

import SuitabilityReportContext from '../../../Utils/contexts/suitabilityReportContext';
import { 
    changeAdditionalInfo,
} from '../../../Utils/reducers/suitabilityReportActions';

// Component imports


// Styling
import './additionalCustomerInfo.css';
import styles from '../../../Styles/srbstyles.module.css'


function AdditionalCustomerInformation() {
    const { push } = useHistory();
    const { state, status, error, dispatch } = useContext(SuitabilityReportContext);

    const handleChange = (type, value) => {        
        dispatch({ type: changeAdditionalInfo, payload: {
            item: type,
            value: value
        }})
    };
    
    const exitHandler = () => {
        push('/suitabilitydashboard');
    }

    const saveHandler = async () => {
        await saveContextToFirebase(state).then(() => console.log("SAVED"))
    }

    const nextHandler = () => {
        push('/furthercustomerinformation');
    }

    useEffect(() => {
   
    }, [])

	return <div className={styles.suitabilityPanel}>
        <div className={styles.pageTitleText}>Additional Customer Information</div>
        <div className={styles.pageInfoText}>Provide additional soft facts below about the customer’s overall situation. Write as if you are writing to the client.</div>
        {!status !== "success" && <div className={styles.softFactsPanel}>
            <div className={styles.why}>Why does the customer seek financial advice?</div>
                <input
                    type="text"
                    placeholder=""
                    value={state.additionalCustomerInformation.why}
                    className={styles.whyInput}
                    onChange={(event) => handleChange("why", event.target.value)}
                />
            <div className={styles.why}>What is the customer’s investment experience?</div>
                <input
                    type="text"
                    placeholder=""
                    value={state.additionalCustomerInformation.what}
                    className={styles.whyInput}
                    onChange={(event) => handleChange("what", event.target.value)}
                />
            <div className={styles.why}>Other notes</div>
                <textarea
                    type="text"
                    placeholder=""
                    value={state.additionalCustomerInformation.notes}
                    className={`${styles.whyInput} ${styles.notesInput}`}
                    onChange={(event) => handleChange("notes", event.target.value)}
                />
        </div>}
        <div className={styles.menuBar}>
            <div className={styles.menuChips}>
                <SuitabilityReportNavigation
                    showSave
                    showNext
                    handleExit={exitHandler}
                    handleSave={saveHandler}
                    handleNext={nextHandler}
                />
            </div>
        </div>
    </div>;
}

export default AdditionalCustomerInformation;

