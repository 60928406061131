//React
import React, { useRef, useEffect } from "react";

//external libraries

//components
import AmmoniteEmployment from "../../../Assets/SVG/ReportIcons/Employment/employment";
import AmmonitePensions from "../../../Assets/SVG/ReportIcons/Pensions/pensions";
import AmmoniteChildren from "../../../Assets/SVG/ReportIcons/Children/children";
import AmmoniteLifeCover from "../../../Assets/SVG/ReportIcons/LifeCover/lifeCover";
import AmmoniteMortgage from "../../../Assets/SVG/ReportIcons/Mortgage/mortgage";
import AmmoniteWill from "../../../Assets/SVG/ReportIcons/Will/will";
import AmmoniteEnvironment from "../../../Assets/SVG/ReportIcons/Environment/environment";
import AmmoniteStatePensionAge from "../../../Assets/SVG/ReportIcons/StatePensionAge/statePensionAge";
import AmmoniteInvestments from "../../../Assets/SVG/ReportIcons/Investments/investments";
import AmmoniteCharges from "../../../Assets/SVG/ReportIcons/Charges/charges";
import "./financialPlanningCard.css";

//assets

function AmmoniteFinancialPlanningCard({
  planningName,
  financialPlanningCardType,
  customer1Name,
  customer2Name,
  statePensionAge1,
  statePensionAge2,
}) {
  const financialPlanningRef = useRef();

  useEffect(() => {}, [financialPlanningRef]);

  const unemployedParagraphs = (
    <div className="financialPlanningParagraph">
      <p>
        Did you know you can put £2,880 into a personal pension each year and
        that automatically turns into £3,600 thanks to the government tax
        relief?{" "}
      </p>
      <br></br>
      <p>
        That’s £720 free money into your pension, from the government. Over 10
        years your pension pot could well be on the way to about £50,000 if you
        keep contributing £2,880 every year (and with some decent investment
        growth on the pot of say 6% p.a). You can also put money into a pension
        for someone else (or they can put in money for you!).{" "}
      </p>
    </div>
  );

  const employedParagraphs = (
    <div className="financialPlanningParagraph">
      <p>
        Did you know that the government want to incentivise you to save now for
        your future retirement and give you free money (tax relief) on each
        contribution you put into a pension?{" "}
      </p>
      <br></br>
      <p>
        So, for example, if you are a basic rate taxpayer, for each £80 personal
        contribution you put into a pension it automatically becomes £100
        (that’s a 25% gain straight away on your cash).
      </p>
      <br></br>
      <p>
        If you are a higher or additional rate taxpayer then the uplift is even
        more, but the tax relief has to be claimed back via your annual tax
        return or an adjustment in your HMRC tax code. Millions of pounds are
        lost out by hard working taxpayers across the UK, because they are not
        claiming the tax back each year. We can help with that.
      </p>
      <br></br>
      <p>
        You can make personal contributions, on top of the contributions your
        employer might be making for you, to help boost your retirement savings,
        and we can help you set up a personal pension. People often have several
        employers throughout their career, so it’s helpful to have your own
        personal pension to consolidate into, for administrative ease.
      </p>
    </div>
  );

  const selfEmployedParagraphs = (
    <div className="financialPlanningParagraph">
      <p>Ultimately the buck stops with you!</p>
      <br></br>
      <p>
        Did you know you can pay into a pension as either an employee or
        employer, depending on how a pension is set up?  This could mean that
        you could reduce your corporation tax and national insurance
        contributions with your pension contributions, each year.  Ammonite can
        help you look into this smart planning method.
      </p>
    </div>
  );

  const pensionsParagraphs = (
    <div className="financialPlanningParagraph">
      <p>
        It’s really important to check performance, fees and how flexible your
        pensions will be when you want to retire. Flexi-access pension rules
        were introduced in April 2015 and this was a game changer for UK
        pensions. Your old pensions may not benefit from the features now found
        in flexi-access pensions (like being able to withdraw your pension in
        flexible amounts when you want). We can look at your old pensions for
        you, and make sure that they’re in the best place possible and invested
        right, to give you the best chance of achieving your retirement goals.
      </p>
      <br></br>
      <p>
        Also, are your pensions in trust with named beneficiaries to ensure they
        don’t get hit by IHT (Inheritance Tax, currently at 40%) on death?  This
        could make a big difference to your family.  We can check this for you
        too.
      </p>
    </div>
  );

  const childrenParagraphs = (
    <div className="financialPlanningParagraph">
      <p>
        We tend to insure our phone, bike, pets, home but what about us? We
        don’t want to get morbid but bad things can happen and it’s worth taking
        a moment to consider what that might look like for your family if you
        snuffed it. Life insurance is surprisingly cheap and can give you piece
        of mind knowing your loved ones are protected.
      </p>
      <br></br>
      <p>
        Have a budget in mind as to what you feel you can afford to pay each
        month (ongoing) and whilst we would not advocate cutting your pet
        insurance, perhaps replacing something more trivial in your monthly
        expenses for life cover instead? We can help you consider your monthly
        budgeting and point you in the right direction for the most competitive
        and comprehensive insurance.
      </p>
    </div>
  );

  const lifeCoverParagraphs = (
    <div className="financialPlanningParagraph">
      <p>
        Do you know if your life insurance policy is in trust? If not, then it
        might be subject to inheritance tax on death. We can help you find out,
        and make sure that your family is best protected in the event of your
        death.{" "}
      </p>
    </div>
  );

  const noLifeCoverParagraphs = (
    <div className="financialPlanningParagraph">
      <p>
        We tend to insure our phone, bike, pets, home but what about us? We
        don’t want to get morbid but bad things can happen and it’s worth taking
        a moment to consider what that might look like for your family, if you
        died unexpectedly. Life insurance can be relatively cheap and can give
        you peace of mind knowing your loved ones are protected.{" "}
      </p>
      <br></br>
      <p>
        Have a budget in mind as to what you feel you can afford to pay each
        month (ongoing).  Would you be able to replace something more trivial in
        your monthly expenses for life cover instead? We can help you consider
        your monthly budgeting and point you in the right direction for the most
        competitive and comprehensive insurance. 
      </p>
    </div>
  );

  const noIncomeProtectionParagraphs = (
    <div className="financialPlanningParagraph">
      <p>
        Nobody wants to worry about how they'll pay the bills if they're sick or
        injured and can't work. Income Protection plans can help you rest a bit
        easier, safe in the knowledge that you'll still get an income if you're
        off work because of illness, injury or a terminal illness. Insurance
        providers vary but most cover up to 65% of the first £15,000 of your
        pre-tax earnings, plus up to 55% of the remainder, up to £200,000 a
        year.{" "}
      </p>
    </div>
  );

  const mortgageParagraphs = (
    <div className="financialPlanningParagraph">
      <p>
        A bit like pensions and investments, it’s important to speak to an
        expert when arranging your mortgage. Even if it’s simple, some brokers
        can find deals “not on the high street” which may save you money each
        month for years to come. Please let us know if you’d like us to connect
        you to an expert broker at mortgages@ammonitewealth.com{" "}
      </p>
    </div>
  );

  const noWillParagraphs = (
    <div className="financialPlanningParagraph">
      <p>
        Dying “intestate” (with no will) means your loved ones have little
        control over your estate, once you are gone. This can cause them expense
        and frustration, as they sort out your belongings. We have a quick and
        inexpensive solution to this.{" "}
      </p>
      <br></br>
      <p>It feels good once this job is off the “to-do” list!</p>
    </div>
  );

  const environmentParagraphs = (
    <div className="financialPlanningParagraph">
      <p>
        The world is going green! We know you’re interested in environmental
        investing, and you might be surprised at the wide range of stocks and
        bonds now available to the environmentally friendly investor. ESG is the
        term commonly used (Environmental, Social & Governance) for this type of
        investing and we really enjoy helping customers convert to green.{" "}
      </p>
    </div>
  );

  const statePensionAgeParagraphs = (
    <div className="financialPlanningParagraph">
      <p>
        {customer1Name}, your state pension age is {statePensionAge1}.
        {customer2Name
          ? ` ${customer2Name}, your state pension age is ${statePensionAge2}.`
          : ""}
      </p>
      <br></br>
      <p>
        As well as your state pension age, it’s important to know whether you’re
        on track for the full new state pension. If you go to
        www.gov.uk/check-state-pension you can find out if you are on target to
        reach the required 35 years’ worth of National Insurance contributions.
        You can plug gaps in your National Insurance history (if you have any)
        and it’s worth knowing how the state pension will sit alongside your
        other pensions and investments when formulating your retirement plan,
        for those non-working years. We can help with that.{" "}
      </p>
    </div>
  );

  const investmentParagraphs = (
    <div className="financialPlanningParagraph">
      <p>
        There are lots of investment funds available to customers, but there are
        a few things you should consider before jumping in or making changes to
        existing investments:
      </p>
      <br></br>
      <div className="investmentSubHeading">Diversification is key</div>
      <p>
        So basically not “all your eggs in one basket”. We feel it’s important
        to spread investment risk, and we invest clients globally in assets like
        bonds, stocks and shares.
      </p>
      <br></br>
      <div className="investmentSubHeading">
        Don’t pay more than you need to
      </div>
      <p>
        Investing has become more efficient over the past decade, and ETFs
        (Exchange Traded Funds) offer a very low-cost way to gaining exposure to
        global bonds and stock markets. Manager skill and benefiting from
        outperformance over the long term can come down to picking the right
        countries and sectors across the world. Passive investing (ETFs) puts a
        big emphasis on this and is a fraction of the cost of active investing
        (in fact it’s at least half the ongoing cost to the investor). We can
        recommend some of the best passive funds in the world, to help grow your
        money.
      </p>
      <br></br>
      <div className="investmentSubHeading">Invest in line with your goals</div>
      <p>
        We think it’s important to treat each goal differently when you’re
        investing. It’s unlikely you would want to take the same amount of
        investment risk on a “university pot” for a child/grandchild as you
        would for a sports car you are aiming for when you’re 65. We can help
        you choose the right investment for each of your goals, and make sure
        that the whole portfolio is in line with your overall attitude to risk.
        We know that your goals might change as your situation does. We’ll help
        you review your goals and attitude to risk every year, so that you can
        always feel like you’ve got the right financial plan.{" "}
      </p>
    </div>
  );

  const chargesParagraphs = (
    <div className="financialPlanningParagraph">
      <p>
        What are the fees if I’m using wrappers like an ISA and a Pension for
        tax-free growth and investments recommended by a qualified financial
        adviser, who can also help me plan for my goals and comfortable
        retirement?{" "}
      </p>
      <br></br>
      <p>
        To be invested for the long run and benefit from ISA and Pension tax
        relief, there are ongoing cost to the product providers each year. There
        are lots of providers of ISAs, Pensions and Investments, and we take
        great care in providing our clients with high quality yet low cost. It’s
        part of the reason why clients of Ammonite Wealth pay for our ongoing
        advice alongside helpful reporting, quality checks on providers and most
        importantly ensuring you are on track to reaching your goals. We believe
        there is no point paying for what you don’t need, and that’s why our
        total overall ongoing costs are comparatively low. We think the most
        value for clients is focusing on their goals and giving advice on how
        they can reach them, with quality products and a personal ongoing
        service. That way you get the best value possible, with the security
        that you’ve got someone helping to achieve your goals.{" "}
      </p>
      <br></br>
      <div className="investmentSubHeading">Keeping costs to a minimum </div>
      <p>
        Keeping costs to a minimum for our clients is important to us. Based on
        our research we feel we are approximately 50% lower cost than a
        traditional financial planning firm. Lower costs are made possible by
        our investment in technology, centralised ongoing servicing and use of
        ETFs.{" "}
      </p>
    </div>
  );

  const financialPlanningTypeMap = {
    unemployed: {
      logo: <AmmoniteEmployment />,
      text: planningName + " you're not currently earning",
      colour: "#850944",
      paragraphs: unemployedParagraphs,
    },
    employed: {
      logo: <AmmoniteEmployment />,
      text: planningName + ", you're employed",
      colour: "#850944",
      paragraphs: employedParagraphs,
    },
    selfEmployed: {
      logo: <AmmoniteEmployment />,
      text: planningName + ", you're self-employed",
      colour: "#850944",
      paragraphs: selfEmployedParagraphs,
    },
    pensions: {
      logo: <AmmonitePensions />,
      text: planningName + ", your existing pensions",
      colour: "#BF802F",
      paragraphs: pensionsParagraphs,
    },
    // children: {
    //     logo: <AmmoniteChildren/>,
    //     text: "Knowing that you have children",
    //     colour: '#373986',
    //     paragraphs: childrenParagraphs
    // },
    lifeCover: {
      logo: <AmmoniteLifeCover />,
      text: planningName + ", you have life cover",
      colour: "#2B9BA5",
      paragraphs: lifeCoverParagraphs,
    },
    noLifeCover: {
      logo: <AmmoniteLifeCover />,
      text: "Protecting your family",
      colour: "#2B9BA5",
      paragraphs: noLifeCoverParagraphs,
    },
    noIncomeProtection: {
      logo: <AmmoniteLifeCover />,
      text: planningName + ", you don’t have any income protection",
      colour: "#2B9BA5",
      paragraphs: noIncomeProtectionParagraphs,
    },
    mortgage: {
      logo: <AmmoniteMortgage />,
      text: "Mortgage matters ",
      colour: "#247BA0",
      paragraphs: mortgageParagraphs,
    },
    noWill: {
      logo: <AmmoniteWill />,
      text: "Your will",
      colour: "#F25F5C",
      paragraphs: noWillParagraphs,
    },
    environmentalPriority: {
      logo: <AmmoniteEnvironment />,
      text: "Environmental investing",
      colour: "#3F762C",
      paragraphs: environmentParagraphs,
    },
    statePensionAge: {
      logo: <AmmoniteStatePensionAge />,
      text: "State pension - are you on track?",
      colour: "#045896",
      paragraphs: statePensionAgeParagraphs,
    },
    investments: {
      logo: <AmmoniteInvestments />,
      text: "But what investments?",
      colour: "#373986",
      paragraphs: investmentParagraphs,
    },
    charges: {
      logo: <AmmoniteCharges />,
      text: "And what are the charges?",
      colour: "#39B54A",
      paragraphs: chargesParagraphs,
    },
  };

  return (
    <div ref={financialPlanningRef} className="financialPlanningCardContainer">
      <div className="financialPlanningCardHeader">
        <div className="financialPlanningCardLogo">
          {financialPlanningTypeMap[financialPlanningCardType]["logo"]}
        </div>
        <div
          className="financialPlanningCardHeaderText"
          style={{
            color:
              financialPlanningTypeMap[financialPlanningCardType]["colour"],
          }}
        >
          {financialPlanningTypeMap[financialPlanningCardType]["text"]}
        </div>
      </div>
      <div className="financialPlanningCardText">
        {financialPlanningTypeMap[financialPlanningCardType]["paragraphs"]}
      </div>
    </div>
  );
}

export default AmmoniteFinancialPlanningCard;
