import React from 'react';

function PropertyAllocationButton({ colour }) {
	return (
<svg width="70" height="30" viewBox="0 0 70 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<rect width="70" height="30" rx="15" fill="#247BA0"/>
<path d="M45.525 12.7197L35.8875 2.86975C35.7713 2.75259 35.633 2.65959 35.4807 2.59613C35.3284 2.53267 35.165 2.5 35 2.5C34.835 2.5 34.6716 2.53267 34.5193 2.59613C34.3669 2.65959 34.2287 2.75259 34.1125 2.86975L24.475 12.7322C24.2418 12.9673 24.0576 13.2464 23.9331 13.5533C23.8087 13.8601 23.7464 14.1886 23.75 14.5197V24.9947C23.749 25.6346 23.9935 26.2505 24.433 26.7156C24.8725 27.1807 25.4736 27.4595 26.1125 27.4947H43.8875C44.5264 27.4595 45.1275 27.1807 45.567 26.7156C46.0065 26.2505 46.251 25.6346 46.25 24.9947V14.5197C46.251 13.8484 45.991 13.203 45.525 12.7197V12.7197ZM32.5 24.9947V17.4947H37.5V24.9947H32.5ZM43.75 24.9947H40V16.2447C40 15.9132 39.8683 15.5953 39.6339 15.3609C39.3994 15.1264 39.0815 14.9947 38.75 14.9947H31.25C30.9185 14.9947 30.6005 15.1264 30.3661 15.3609C30.1317 15.5953 30 15.9132 30 16.2447V24.9947H26.25V14.4697L35 5.53225L43.75 14.5197V24.9947Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="70" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>



	);
}

export default PropertyAllocationButton;
