// Core React dependencies
import React from "react";

// Component imports
import Button from "@material-ui/core/Button";

// Styling
import "./suitabilityReportNavigation.css";

function SuitabilityReportNavigation({
  showBack,
  showSave,
  showNext,
  handleExit,
  handleSave,
  handleBack,
  handleNext,
}) {
  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleExit}
        style={{
          width: "120px",
          height: "45px",
          margin: "15px",
          borderRadius: "8px",
          border: "2px solid #7A7A7A",
          boxSizing: "border-box",
          borderRadius: "8px",
          color: "#FFFFFFF",
          textTransform: "none",
          fontFamily: "Brother 1816",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "18px",
          lineHeight: "25px",
          letterSpacing: "0.02em",
        }}
      >
        <span>Exit</span>
      </Button>
      {showSave && (
        <Button
          variant="contained"
          onClick={handleSave}
          style={{
            width: "120px",
            height: "45px",
            borderRadius: "8px",
            margin: "15px",
            background: "#3BB9C4",
            color: "#ffffff",
            textTransform: "none",
            fontFamily: "Brother 1816",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "18px",
            lineHeight: "25px",
            letterSpacing: "0.02em",
          }}
        >
          <span>Save</span>
        </Button>
      )}
      {showBack && (
        <Button
          variant="contained"
          onClick={handleBack}
          style={{
            width: "120px",
            height: "45px",
            borderRadius: "8px",
            margin: "15px",
            background: "#7A7A7A",
            color: "#ffffff",
            textTransform: "none",
            fontFamily: "Brother 1816",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "18px",
            lineHeight: "25px",
            letterSpacing: "0.02em",
          }}
        >
          <span className="nextButton">
            <p>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13 6L3.135 6L6.768 1.64C7.122 1.216 7.064 0.585 6.64 0.232C6.215 -0.122 5.585 -0.0640001 5.231 0.36L0.231001 6.36C0.192001 6.407 0.173001 6.462 0.144001 6.514C0.120001 6.556 0.0909996 6.592 0.073 6.638C0.0279999 6.753 0.00100136 6.874 0.00100136 6.996L0 7L0.00100136 7.004C0.00100136 7.126 0.0279999 7.247 0.073 7.362C0.0909996 7.408 0.120001 7.444 0.144001 7.486C0.173001 7.538 0.192001 7.593 0.231001 7.64L5.231 13.64C5.43 13.877 5.714 14 6 14C6.226 14 6.453 13.924 6.64 13.768C7.064 13.415 7.122 12.784 6.768 12.36L3.135 8L13 8C13.552 8 14 7.552 14 7C14 6.448 13.552 6 13 6Z"
                  fill="white"
                />
              </svg>
            </p>
            <p className="backPara">Back</p>
          </span>
        </Button>
      )}
      {showNext && (
        <Button
          data-testid="next-page-card"
          variant="contained"
          onClick={handleNext}
          style={{
            width: "120px",
            height: "45px",
            borderRadius: "8px",
            margin: "15px",
            background: "#BF802F",
            color: "#ffffff",
            textTransform: "none",
            fontFamily: "Brother 1816",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "18px",
            lineHeight: "25px",
            letterSpacing: "0.02em",
          }}
        >
          <span className="nextButton">
            <p className="nextPara">Next</p>
            <p>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1 8H10.865L7.232 12.36C6.878 12.784 6.936 13.415 7.36 13.768C7.785 14.122 8.415 14.064 8.769 13.64L13.769 7.64C13.808 7.593 13.827 7.538 13.856 7.486C13.88 7.444 13.909 7.408 13.927 7.362C13.972 7.247 13.999 7.126 13.999 7.004L14 7L13.999 6.996C13.999 6.874 13.972 6.753 13.927 6.638C13.909 6.592 13.88 6.556 13.856 6.514C13.827 6.462 13.808 6.407 13.769 6.36L8.769 0.36C8.57 0.123 8.286 0 8 0C7.774 0 7.547 0.076 7.36 0.232C6.936 0.585 6.878 1.216 7.232 1.64L10.865 6H1C0.448 6 0 6.448 0 7C0 7.552 0.448 8 1 8Z"
                  fill="white"
                />
              </svg>
            </p>
          </span>
        </Button>
      )}
    </div>
  );
}

export default SuitabilityReportNavigation;
