import React from 'react';

function AmmoniteAnnualReviews() {
	return (
<svg width="211" height="174" viewBox="0 0 211 174" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M203 14.916H12.7922C8.37396 14.916 4.79224 18.4977 4.79224 22.916V133.703C4.79224 138.121 8.37397 141.703 12.7922 141.703H203C207.418 141.703 211 138.121 211 133.703V22.916C211 18.4977 207.418 14.916 203 14.916Z" fill="#DEDEDE"/>
<path d="M48.3871 39.3413H26.6201C22.2018 39.3413 18.6201 42.923 18.6201 47.3413V55.5797C18.6201 59.998 22.2018 63.5797 26.6201 63.5797H48.3871C52.8054 63.5797 56.3871 59.998 56.3871 55.5797V47.3413C56.3871 42.923 52.8054 39.3413 48.3871 39.3413Z" fill="white"/>
<path d="M95.3153 39.3413H73.5483C69.1301 39.3413 65.5483 42.923 65.5483 47.3413V55.5797C65.5483 59.998 69.1301 63.5797 73.5483 63.5797H95.3153C99.7336 63.5797 103.315 59.998 103.315 55.5797V47.3413C103.315 42.923 99.7336 39.3413 95.3153 39.3413Z" fill="white"/>
<path d="M142.244 39.3413H120.477C116.059 39.3413 112.477 42.923 112.477 47.3413V55.5797C112.477 59.998 116.059 63.5797 120.477 63.5797H142.244C146.662 63.5797 150.244 59.998 150.244 55.5797V47.3413C150.244 42.923 146.662 39.3413 142.244 39.3413Z" fill="white"/>
<path d="M167.405 40.3413H189.172C193.038 40.3413 196.172 43.4753 196.172 47.3413V55.5797C196.172 59.4457 193.038 62.5797 189.172 62.5797H167.405C163.539 62.5797 160.405 59.4457 160.405 55.5797V47.3413C160.405 43.4753 163.539 40.3413 167.405 40.3413Z" fill="white" stroke="#39B54A" strokeWidth="2"/>
<path d="M48.3871 73.6482H26.6201C22.2018 73.6482 18.6201 77.2299 18.6201 81.6482V89.8866C18.6201 94.3049 22.2018 97.8866 26.6201 97.8866H48.3871C52.8054 97.8866 56.3871 94.3049 56.3871 89.8866V81.6482C56.3871 77.2299 52.8054 73.6482 48.3871 73.6482Z" fill="white"/>
<path d="M95.3153 73.6482H73.5483C69.1301 73.6482 65.5483 77.2299 65.5483 81.6482V89.8866C65.5483 94.3049 69.1301 97.8866 73.5483 97.8866H95.3153C99.7336 97.8866 103.315 94.3049 103.315 89.8866V81.6482C103.315 77.2299 99.7336 73.6482 95.3153 73.6482Z" fill="white"/>
<path d="M142.244 73.6482H120.477C116.059 73.6482 112.477 77.2299 112.477 81.6482V89.8866C112.477 94.3049 116.059 97.8866 120.477 97.8866H142.244C146.662 97.8866 150.244 94.3049 150.244 89.8866V81.6482C150.244 77.2299 146.662 73.6482 142.244 73.6482Z" fill="white"/>
<path d="M189.172 73.6482H167.405C162.987 73.6482 159.405 77.2299 159.405 81.6482V89.8866C159.405 94.3049 162.987 97.8866 167.405 97.8866H189.172C193.59 97.8866 197.172 94.3049 197.172 89.8866V81.6482C197.172 77.2299 193.59 73.6482 189.172 73.6482Z" fill="white"/>
<path d="M48.3871 107.955H26.6201C22.2018 107.955 18.6201 111.537 18.6201 115.955V124.194C18.6201 128.612 22.2018 132.194 26.6201 132.194H48.3871C52.8054 132.194 56.3871 128.612 56.3871 124.194V115.955C56.3871 111.537 52.8054 107.955 48.3871 107.955Z" fill="white"/>
<path d="M95.3153 107.955H73.5483C69.1301 107.955 65.5483 111.537 65.5483 115.955V124.194C65.5483 128.612 69.1301 132.194 73.5483 132.194H95.3153C99.7336 132.194 103.315 128.612 103.315 124.194V115.955C103.315 111.537 99.7336 107.955 95.3153 107.955Z" fill="white"/>
<path d="M142.244 107.955H120.477C116.059 107.955 112.477 111.537 112.477 115.955V124.194C112.477 128.612 116.059 132.194 120.477 132.194H142.244C146.662 132.194 150.244 128.612 150.244 124.194V115.955C150.244 111.537 146.662 107.955 142.244 107.955Z" fill="white"/>
<path d="M189.172 107.955H167.405C162.987 107.955 159.405 111.537 159.405 115.955V124.194C159.405 128.612 162.987 132.194 167.405 132.194H189.172C193.59 132.194 197.172 128.612 197.172 124.194V115.955C197.172 111.537 193.59 107.955 189.172 107.955Z" fill="white"/>
<path d="M58.7993 29.832C61.9281 29.832 64.4644 27.3277 64.4644 24.2385C64.4644 21.1493 61.9281 18.645 58.7993 18.645C55.6706 18.645 53.1343 21.1493 53.1343 24.2385C53.1343 27.3277 55.6706 29.832 58.7993 29.832Z" fill="white"/>
<path d="M152.461 29.832C155.59 29.832 158.126 27.3277 158.126 24.2385C158.126 21.1493 155.59 18.645 152.461 18.645C149.332 18.645 146.796 21.1493 146.796 24.2385C146.796 27.3277 149.332 29.832 152.461 29.832Z" fill="white"/>
<path d="M61.065 23.464V2.26602C61.065 1.01453 60.0505 0 58.799 0C57.5475 0 56.533 1.01453 56.533 2.26602V23.464C56.533 24.7155 57.5475 25.7301 58.799 25.7301C60.0505 25.7301 61.065 24.7155 61.065 23.464Z" fill="#BF802F"/>
<path d="M154.727 23.464V2.26602C154.727 1.01453 153.713 0 152.461 0C151.21 0 150.195 1.01453 150.195 2.26602V23.464C150.195 24.7155 151.21 25.7301 152.461 25.7301C153.713 25.7301 154.727 24.7155 154.727 23.464Z" fill="#BF802F"/>
<path d="M186.829 51.4605C186.827 53.4856 186.085 55.4419 184.739 56.9701C183.394 58.4983 181.535 59.4956 179.504 59.7789C177.473 60.0621 175.407 59.6123 173.685 58.512C171.963 57.4117 170.702 55.7348 170.132 53.7895C169.562 51.8442 169.722 49.7612 170.583 47.9231C171.443 46.085 172.947 44.6154 174.817 43.7843C176.688 42.9532 178.799 42.8164 180.763 43.399C182.727 43.9817 184.413 45.2447 185.509 46.9559C186.372 48.304 186.829 49.8659 186.829 51.4605V51.4605Z" fill="#3F762C"/>
<path d="M185.509 46.9559L177.102 55.2553C176.574 54.4476 173.63 50.6939 173.63 50.6939C174.007 50.3175 174.41 49.9666 174.835 49.6437L177.276 52.8573L184.525 45.7002C184.892 46.0871 185.222 46.5076 185.509 46.9559V46.9559Z" fill="white"/>
<path d="M27.4156 44.4033L33.3182 38.2838C33.3182 38.2838 39.8111 28.6675 44.5332 31.2901C49.2553 33.9127 37.7452 40.0322 37.7452 40.0322L31.8425 49.6486L27.4156 44.4033Z" fill="#FBBEBE"/>
<path d="M8.82248 163.005C8.82248 163.005 4.39551 171.456 7.64195 172.039C10.8884 172.622 26.8255 173.496 27.1206 172.622C27.4157 171.747 29.7768 168.833 28.006 167.668C26.2352 166.502 19.1521 165.628 19.1521 165.628L16.791 163.297L8.82248 163.005Z" fill="#0C3957"/>
<path d="M6.46131 80.5379C6.46131 80.5379 -2.98288 92.194 4.69053 104.142L6.46131 124.249C6.46131 124.249 3.21487 151.932 7.3467 161.257L7.93697 164.462H18.2665L15.6104 139.984C15.6104 139.984 15.9055 137.07 16.7909 136.487C17.6763 135.905 32.1377 104.433 32.1377 104.433C32.1377 104.433 29.7766 100.062 30.662 99.1878C31.5474 98.3135 29.7766 80.8293 28.8913 80.2465C28.0059 79.6637 6.46131 80.5379 6.46131 80.5379Z" fill="#0C3957"/>
<path d="M23.2836 160.383L24.4641 167.085L12.954 166.502L14.4297 159.217L23.2836 160.383Z" fill="#69306D"/>
<path d="M17.5843 41.4892C12.6944 41.4892 8.73039 37.5753 8.73039 32.7472C8.73039 27.9191 12.6944 24.0051 17.5843 24.0051C22.4742 24.0051 26.4382 27.9191 26.4382 32.7472C26.4382 37.5753 22.4742 41.4892 17.5843 41.4892Z" fill="#FBBEBE"/>
<path d="M13.249 34.2041L12.3637 44.6946L21.8079 49.0657C21.8079 49.0657 20.1846 39.3037 21.3652 36.681L13.249 34.2041Z" fill="#FBBEBE"/>
<path d="M22.9886 48.4833L22.1032 44.9865L10.0028 40.6155C10.0028 40.6155 8.82228 40.6155 9.11741 42.6553C9.41254 44.6951 9.41254 44.4037 9.41254 44.4037C9.41254 44.4037 5.28071 46.1521 4.98557 49.0661C4.69044 51.9802 -0.0316522 52.8544 0.263479 57.2254C0.55861 61.5964 2.03426 63.9277 2.03426 63.9277C2.03426 63.9277 0.55861 67.4245 2.91966 69.4643C2.91966 69.4643 5.28071 73.5439 4.10018 76.4579C2.91966 79.372 1.444 84.3258 4.69044 83.4516C7.93688 82.5774 30.3668 81.1204 30.3668 81.1204C30.3668 81.1204 33.6133 69.1729 30.0717 64.8019C30.0717 64.8019 30.0717 62.1792 27.4155 56.934C24.7593 51.6888 22.9886 48.4833 22.9886 48.4833Z" fill="#69306D"/>
<path d="M7.34655 81.4116C7.34655 81.4116 11.1833 97.439 15.9053 99.1875C15.9053 99.1875 16.7907 110.844 21.808 110.261C26.8252 109.678 21.808 99.1875 21.808 99.1875L16.2005 79.0803L7.34655 81.4116Z" fill="#FBBEBE"/>
<path d="M22.3982 49.3573C22.3982 49.3573 26.8252 42.3636 28.0057 43.5292C29.1863 44.6949 35.0889 48.1917 34.7938 50.5229C34.4986 52.8541 31.8424 52.8541 31.8424 52.8541L25.6447 57.5166L22.3982 49.3573Z" fill="#69306D"/>
<path d="M28.5959 101.519L32.1375 104.032L34.4985 128.911C34.4985 128.911 25.9397 162.714 23.5787 163.005C21.2177 163.297 11.4784 163.297 11.7735 162.422C12.0686 161.548 13.5443 158.051 13.5443 157.177C13.5443 156.303 16.6223 149.312 16.6223 149.312L20.6274 132.99L19.742 120.169L28.5959 101.519Z" fill="#0C3957"/>
<path d="M24.1691 164.754C24.1691 164.754 11.7736 160.965 11.1833 161.839C10.5931 162.714 6.65593 171.525 12.1661 172.51C17.6762 173.496 37.45 175.244 37.45 172.621C37.45 169.999 36.8597 168.25 35.0889 168.25C33.3181 168.25 25.3496 164.462 24.7593 163.588C24.1691 162.714 24.1691 164.754 24.1691 164.754Z" fill="#0C3957"/>
<path d="M14.3623 52.1969C13.2198 51.414 11.8467 51.0272 10.4579 51.0969C9.06901 51.1666 7.74272 51.6889 6.68651 52.5821C4.65249 54.2887 2.46436 56.7339 2.62457 59.265C2.9197 63.9275 4.69049 83.1602 7.05153 83.4516C9.41258 83.743 17.9714 81.4117 17.9714 79.9547C17.9714 78.4977 15.9055 76.7493 15.9055 76.7493L18.4139 61.889C18.7302 60.0874 18.5309 58.2343 17.8385 56.5385C17.1461 54.8426 15.9881 53.3712 14.4947 52.2899C14.4509 52.2588 14.4068 52.2278 14.3623 52.1969Z" fill="#69306D"/>
<path d="M28.4055 27.1194C28.3839 26.4961 28.1306 25.9025 27.6939 25.4519C27.8843 24.8392 27.9709 24.1996 27.9503 23.559C27.814 21.6745 26.3824 21.4122 25.2583 20.3053C24.7707 19.8251 24.8837 19.5406 24.7282 18.9179C24.5245 18.1017 24.093 17.3583 23.4826 16.7722C22.7731 16.0181 22.0205 15.2358 21.0319 14.9091C18.9544 14.2226 16.8143 15.7696 14.6264 15.8799C13.662 15.9285 12.7015 15.6936 11.7364 15.7258C10.7714 15.758 9.71212 16.1553 9.33762 17.0341C9.16473 17.4398 9.15931 17.9003 8.98559 18.3057C8.64024 19.1114 7.7411 19.5065 7.061 20.0673C5.55704 21.3074 5.11501 23.4923 5.55866 25.3768C5.65381 25.7676 5.77961 26.1505 5.93493 26.522C5.80164 27.3244 5.82847 28.1449 6.01392 28.9372C6.45757 30.8217 7.64688 32.4505 8.95918 33.8872C8.97455 33.3041 9.0438 32.7235 9.16605 32.1528C9.19329 31.9591 9.26831 31.775 9.38453 31.6167C9.65699 31.298 10.1486 31.3211 10.5566 31.2131C11.4017 30.9895 11.9316 30.1498 12.1734 29.3196C12.4152 28.4894 12.4568 27.6062 12.7649 26.7977C12.8188 26.6074 12.9125 26.4304 13.0399 26.2781C13.1674 26.1258 13.3258 26.0015 13.5049 25.9133C13.8169 25.8302 14.1465 25.837 14.4547 25.9329C15.9789 26.2602 17.5619 26.6382 19.078 26.2763C19.5952 26.1529 20.1009 25.9437 20.633 25.9436C21.8404 25.9434 22.7893 27.023 23.1882 28.1482C23.587 29.2734 23.6111 30.4965 23.9512 31.6403C24.2913 32.7841 25.097 33.9334 26.2902 34.1153C26.3556 34.1323 26.4239 34.1354 26.4906 34.1246C26.5574 34.1137 26.6211 34.089 26.6775 34.0522C26.7511 33.9762 26.8002 33.8803 26.8185 33.7766L28.0411 29.4066C28.2931 28.6702 28.4164 27.8967 28.4055 27.1194V27.1194Z" fill="#0C3957"/>
</svg>

	);
}

export default AmmoniteAnnualReviews;
